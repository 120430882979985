import { Header } from '../components';
import DialogFilter from '../components/DialogFilter';
import { DialogForm } from '../components/DialogForm';
import { List } from '../components/List';
import { useDialog } from '../hooks';

const ListUsersPage = () => {
  const { form } = useDialog();

  return (
    <>
      <Header />
      <List />
      <DialogForm form={form} />
      <DialogFilter />
    </>
  );
};

export { ListUsersPage };
