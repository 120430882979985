import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { AuthService, ISignInPayload, ISignInResponse } from '../services';

const useSignInRequest = (
  options?: UseMutationOptions<ISignInResponse, IApiAxiosError, ISignInPayload>,
) => {
  return useMutation('singin', AuthService.signIn, options);
};

export { useSignInRequest };
