import { useEffect, useState } from 'react';

import { useDebounce } from 'hooks';

export const useSearchDebounce = (
  setSearch: (search: string) => void,
  debounceDelay?: number,
) => {
  const [search, setSearchText] = useState<string>('');

  const handleChangeSearch = (value: string) => {
    setSearchText(value);
  };

  const debounced = useDebounce(search, debounceDelay);

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return { search, handleChangeSearch };
};
