import { ControllerRenderProps } from 'react-hook-form';

import { OptionsValues } from 'utils/helper';

import { ISystemValueMappingInput } from '../../../../models/systemValueMapping/types';

export const useVariableAutocomplete = () => {
  const handleChange = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<ISystemValueMappingInput, 'title'>,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;
    field.onChange(value);
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  return { handleChange, isOptionEqualToValue };
};
