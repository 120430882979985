import { useQuery } from 'react-query';

import { TypeProcessAct } from 'models';

import { useTabs } from 'modules/boards/tabs/hooks';

import { OptionsValuesString } from 'utils/helper';

import ActServices from '../services/services';

interface ListAllProps {
  type?: TypeProcessAct;
  enabled?: boolean;
}

export const useListAllActs = ({ type, enabled }: ListAllProps) => {
  const { currentTabInfos } = useTabs();
  const { data, isLoading, refetch } = useQuery(
    ['listAllActs'],
    () => ActServices.listAll(currentTabInfos?.typeOp ?? type),
    { enabled: enabled ?? true, keepPreviousData: true },
  );

  const actOptions: OptionsValuesString[] =
    data?.map(act => ({ value: `${act?.id}`, label: `${act?.name}` })) ?? [];

  return {
    actOptions,
    dataAllActs: data,
    isLoading,
    refetch,
  };
};
