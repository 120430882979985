import { useEffect, useState } from 'react';

import { useDebounce } from 'hooks';

export const useKMethodSearch = (
  debounceDelay: number,
  setSearch: (search: string) => void,
) => {
  const [searchInput, setSearchInput] = useState('');

  const handleChangeSearch = (value: string) => {
    setSearchInput(value);
  };

  const debounced = useDebounce(searchInput, debounceDelay);

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return { handleChangeSearch };
};
