import { Icon } from 'components';

import { NextWrapper } from './styles';

interface NextProps {
  open?: boolean;
  onClick: () => void;
}

export function Next({ open = true, onClick }: NextProps) {
  return (
    <NextWrapper
      key="next"
      open={open}
      icon={<Icon>chevron_right</Icon>}
      onClick={onClick}
    />
  );
}
