import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ICompanyBigData, IPagination } from 'models';

import { StorageService } from '../services';
import { useStorageStore } from '../store/store';
import { useStorageFilter } from './useStorageFilter';

export const useStorage = () => {
  const { limit, page, perPage, search, setLimit, setPage } = useStorageStore();
  const { filterParams, filterBy, currentFilter } = useStorageFilter();

  const { data: response, isLoading, refetch } = useQuery(
    ['storage', page, search, filterBy, currentFilter],
    () =>
      StorageService.getBigData({
        page,
        perPage,
        search,
        ...filterParams,
      }) as Promise<IPagination<ICompanyBigData>>,
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  useEffect(() => {
    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
  };
};
