import { Box } from '@mui/material';

import { ButtonContained, TableContainer } from 'components';

import { useSaveRevisionQuestion } from 'modules/dynamicForm/formResume';

import {
  FormActions,
  FormAnswerHeader,
  FormContainer,
  FormStepper,
} from '../components';
import useFormAnswerStore from '../store/store';

const FormAnswerPage = () => {
  const { isRevisionQuestion } = useFormAnswerStore();
  const { handleSaveRevisionQuestion } = useSaveRevisionQuestion();
  const heightWindow = window.innerHeight;
  const heightContainer = heightWindow > 900 ? '82' : '78%';

  return (
    <Box height={'100%'}>
      <FormAnswerHeader />

      <TableContainer
        style={{
          height: heightContainer,
        }}
      >
        <Box overflow={'auto'}>
          {isRevisionQuestion ? null : <FormStepper />}

          <FormContainer />
        </Box>

        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          position={'absolute'}
          width={'92%'}
          height={'5%'}
          bottom={10}
        >
          {isRevisionQuestion ? (
            <Box display="flex" alignItems="center" justifyContent="end">
              <ButtonContained
                label="Confirmar"
                color="navy"
                onClick={() => handleSaveRevisionQuestion()}
              />
            </Box>
          ) : (
            <FormActions />
          )}
        </Box>
      </TableContainer>
    </Box>
  );
};

export { FormAnswerPage };
