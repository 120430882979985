import { DocumentsCustomerTable } from 'modules/boards';

import { useDashboardCreditor } from '../hooks';

const DashboardDocumentsCreditor = () => {
  const { data } = useDashboardCreditor();

  return <DocumentsCustomerTable enterpriseId={data?.enterprise.id || ''} />;
};

export { DashboardDocumentsCreditor };
