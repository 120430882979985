import { LoadingButton } from '@mui/lab';
import { Paper, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import themes from 'themes';

import { Box, PasswordTextField } from 'components';

import { UsePasswordSetting } from '../Enterprise/hooks/usePasswordSetting';
import { PasswordInfoRequired } from './';

export interface PasswordSettingFormProps {
  form: UsePasswordSetting['formPassword'];
  isSigningUp?: boolean;
}

export const FormPasswordSetting = ({
  form,
  isSigningUp,
}: PasswordSettingFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  const history = useHistory();

  return (
    <Paper
      elevation={16}
      sx={{
        marginY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2em',
        backgroundColor: '#f0f0f0',
        borderRadius: '11px',
        padding: '3em',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
        width: 532,
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        style={{ gap: '1em' }}
      >
        <Typography
          fontFamily={themes.fonts.body}
          variant="h4"
          fontSize={23}
          textAlign="center"
          fontWeight={700}
          color={themes.colors.blue}
        >
          Defina a sua senha
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          fontFamily={themes.fonts.body}
        >
          Digite a nova senha
        </Typography>
      </Box>
      <Box as="form">
        <Grid container spacing={2} padding={2} paddingTop={0}>
          <Grid item xs={12}>
            <PasswordTextField
              name="password"
              label="Senha"
              control={control}
              error={errors.password}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordTextField
              name="confirmPassword"
              label="Confirmação de senha"
              control={control}
              error={errors.confirmPassword}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <PasswordInfoRequired />
        </Grid>

        <Box
          padding="1em"
          display="flex"
          style={{
            gap: '2em',
          }}
          width={'100%'}
        >
          <LoadingButton
            variant="contained"
            onClick={() => history.push('/')}
            fullWidth
            sx={{
              backgroundColor: 'white',
              color: 'black',
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,

              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            Cancelar
          </LoadingButton>

          <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isSigningUp}
            disabled={!isValid}
            fullWidth
            sx={{
              backgroundColor: themes.colors.text.primary,
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,
            }}
          >
            Cadastrar
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
};
