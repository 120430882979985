import { TextField } from '@mui/material';
import { useState } from 'react';

import {
  Box,
  ButtonContained,
  ButtonOutlined,
  Dialog,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';

import { OptionsValuesString } from 'utils/helper';

import { useOperationActions } from '../hooks';

export const CreateActDialog = () => {
  const [actValue, setActValue] = useState<OptionsValuesString>();

  const {
    actsOptions,
    openCreateActDialog,
    handleCloseCreateActDialog,
    handleNewAct,
    selectedOperations,
  } = useOperationActions({ enabledListAllActs: true });

  const {
    form: { handleSubmitForm },
  } = useTabAct();

  return (
    <Dialog.Root open={openCreateActDialog} onClose={handleCloseCreateActDialog}>
      <Dialog.Header title={'Criar Ato'} />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyItems="center"
          width={500}
          padding={3}
        >
          <AutocompleteWrapper
            fullWidth
            multiple={false}
            options={actsOptions}
            noOptionsText="Nenhum ato encontrado"
            renderInput={params => (
              <TextField {...params} label="Selecione o Ato" />
            )}
            value={actValue}
            onChange={(_, value) => setActValue(value as OptionsValuesString)}
          />
        </Box>
      </Dialog.Content>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseCreateActDialog} />
        <ButtonContained
          label="Criar"
          onClick={async () => {
            const newAct = await handleNewAct(actValue as OptionsValuesString);
            handleSubmitForm(
              selectedOperations.map(op => op.enterprise.id),
              newAct,
            );
          }}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
