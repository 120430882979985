import ExcelJS from 'exceljs';

import {
  DataDebtsTableItem,
  getDebitsTableItem,
  processAbcData,
  processData,
} from '../components';
import {
  AbcCurveData,
  BalanceExcel,
  BillingMonthly,
  TitleKey,
  abcCurveTitles,
  assetsHighlight,
  billingMonthlyTitles,
  debtsTitles,
  dreHighlight,
  indexesAndIndicatorsHighlight,
  monthShortNames,
  passivesHighlight,
} from '../services';
import { useDashboardEnterprise } from './useDashboardEnterprise';

const getLastThreeYears = (data: BillingMonthly[] | undefined) => {
  if (!data || data.length === 0) return [];

  const maxDate = new Date(
    Math.max(...data.map(item => new Date(item.date).getTime())),
  );

  const maxYear = maxDate.getFullYear();

  return [String(maxYear - 2), String(maxYear - 1), String(maxYear)];
};

const getTotalDebts = (data: DataDebtsTableItem[]) => {
  const totalSums = data.reduce(
    (acc, item) => {
      acc.totalUntil180dSum += item.until180dSum;
      acc.totalBetween181and360dSum += item.between181and360dSum;
      acc.totalGreaterThan360dSum += item.greaterThan360dSum;
      acc.totalSum += item.totalSum;
      return acc;
    },
    {
      totalUntil180dSum: 0,
      totalBetween181and360dSum: 0,
      totalGreaterThan360dSum: 0,
      totalSum: 0,
    },
  );

  return totalSums;
};

const getCurrencyPtBr = (value: number | string) => {
  if (value === 0) return '';

  const newValue = value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return newValue;
};

const getPercent = (value: number | string) => {
  if (value === '') return '';

  const valuePercent = Number(value) / 100;

  const newValue = valuePercent.toLocaleString('pt-br', {
    style: 'percent',
    maximumFractionDigits: 2,
  });
  return newValue;
};

const titleCell: ExcelJS.FillPattern = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FF0070C0' }, // Azul
};

const applyHeaders = (sheet: ExcelJS.Worksheet, headers: string[]) => {
  sheet.columns = headers.map(header => ({
    header,
    key: header,
  }));
};

// Aplicando a cor de fundo azul para a primeira linha (headers)
const applyStyle = (sheet: ExcelJS.Worksheet) => {
  sheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
    cell.fill = titleCell;
    cell.font = { bold: true, color: { argb: 'FFFFFF' } };
  });
};

const applyRowBalanceTables = (
  data: BalanceExcel | null,
  sheet: ExcelJS.Worksheet,
) => {
  data?.dates.forEach((date, index) => {
    const rowTable = {};

    rowTable['Data'] = date;

    data?.data.forEach(active => {
      rowTable[active.name] = getCurrencyPtBr(active.values[index] ?? '0');
    });

    sheet.addRow(rowTable);
  });
};

const applyRowAbcCurveTables = (
  data: AbcCurveData,
  sheet: ExcelJS.Worksheet,
  isCustomer: boolean,
) => {
  data.dataProcessed.forEach((abc, index) => {
    const rowAbcCustomer = {};
    const dataTypeCurve = data.dataTypeCurve[index];
    const lengthAbcCustomer = data.dataProcessed.length;
    const lastItem = index + 1;

    let nameEnterprise = 'Nome do Fornecedor';
    let numberEnterprise = 'Número de fornecedores';

    if (isCustomer) {
      nameEnterprise = 'Nome do Cliente';
      numberEnterprise = 'Número de clientes';
    }

    rowAbcCustomer[nameEnterprise] = abc.clientName;
    rowAbcCustomer[numberEnterprise] = dataTypeCurve?.type ?? '';
    rowAbcCustomer['ABC'] = `${dataTypeCurve?.numberClient ?? ''}`;

    abcCurveTitles.forEach(header => {
      if (header.title.includes('Percentual')) {
        rowAbcCustomer[header.title] = getPercent(abc[header.key] ?? '');
        return;
      }
      if (header.title.includes('exercício')) {
        rowAbcCustomer[header.title] = getCurrencyPtBr(abc[header.key] ?? '0');
        return;
      }
      rowAbcCustomer[header.title] = abc[header.key] ?? '';
    });

    sheet.addRow(rowAbcCustomer);

    if (lastItem === lengthAbcCustomer) {
      const rowCustomer = {
        'Nome do Cliente': 'TOTAL',
        'Valor Acumulado do último exercício R$': getCurrencyPtBr(
          data.totalValueLastExAcc ?? '0',
        ),
      };

      const rowSupplier = {
        'Nome do Fornecedor': 'TOTAL',
        'Valor Acumulado do último exercício R$': getCurrencyPtBr(
          data.totalValueLastExAcc ?? '0',
        ),
      };

      if (isCustomer) sheet.addRow(rowCustomer);
      else sheet.addRow(rowSupplier);

      sheet.mergeCells('F1:G1');
      sheet.getCell('F1').value = 'Número de clientes ABC';
    }
  });
};

export const useDownloadExcelDash = () => {
  const isCustomer = true;
  const { data } = useDashboardEnterprise();

  const actives = data?.actives ?? null;
  const passives = data?.passives ?? null;
  const demonstrations = data?.demonstrations ?? null;
  const indexsIndicators = data?.indexesAndIndicators ?? null;
  const billingMonthly = data?.billingMonthly ?? [];
  const debts = data?.debts ?? [];
  const dataCustomers = data?.fiscalNotes?.verticalAnalysis?.billings ?? [];
  const dataSuppliers = data?.fiscalNotes?.verticalAnalysis?.expenses ?? [];

  // processing Datas
  const billingsMonthly = processData(billingMonthly);
  const yearsBills = getLastThreeYears(billingMonthly);
  const debtsItems = getDebitsTableItem(debts ?? []);
  const debtsTotal = getTotalDebts(debtsItems);
  const abcCustomers = processAbcData(dataCustomers ?? []);
  const abcSuppliers = processAbcData(dataSuppliers ?? []);

  const yearMap = {
    nfValueN2: yearsBills[0],
    nfValueN2Acc: yearsBills[0],
    nfValueN1: yearsBills[1],
    nfValueN1Acc: yearsBills[1],
    nfValueN: yearsBills[2],
    nfValueNAcc: yearsBills[2],
  };

  const updatedBillingMonthlyTitles = (data: TitleKey[], yearsBills: string[]) => {
    if (!yearsBills || yearsBills.length === 0) return data;

    const titles = data.map(item => {
      const yearKey = Object.keys(yearMap).find(k => item.key.includes(k));
      if (!yearKey || yearKey === undefined) return item;

      const itemKey = yearMap[yearKey];

      if (item.title.includes('acumulado')) {
        item.title = item.title.replace(/N(-\d)?/, itemKey);
        return item;
      } else {
        item.title = item.title.replace(/Ano N(-\d)?/, itemKey);
      }

      return item;
    });

    return titles;
  };

  // GETTING HEADERS
  const headersAssets = ['Data', ...assetsHighlight.map(asset => asset.name)];
  const headersPassives = ['Data', ...passivesHighlight.map(asset => asset.name)];
  const headersDemos = ['Data', ...dreHighlight.map(asset => asset.name)];
  const headersIndexsInd = [
    'Data',
    ...indexesAndIndicatorsHighlight.map(asset => asset.name),
  ];
  const headersBillingMonthly = updatedBillingMonthlyTitles(
    billingMonthlyTitles,
    yearsBills,
  ).map(title => title.title);
  const headersDebts = debtsTitles.map(title => title.title);
  const headersAbcCustomers = [
    'Nome do Cliente',
    ...abcCurveTitles.map(title => title.title),
    'Número de clientes',
    'ABC',
  ];
  const headersAbcSuppliers = [
    'Nome do Fornecedor',
    ...abcCurveTitles.map(title => title.title),
    'Número de fornecedores',
    'ABC',
  ];

  const handleDownloadExcelDash = async () => {
    // Criando uma nova planilha Excel
    const workbook = new ExcelJS.Workbook();

    const assetsSheet = workbook.addWorksheet('Balanço - Ativos');
    const passivesSheet = workbook.addWorksheet('Balanço - Passivos');
    const demonstrationsSheet = workbook.addWorksheet('DRE');
    const indexsIndSheet = workbook.addWorksheet('Índices e Indicadores');
    const billingMonthlySheet = workbook.addWorksheet('Faturamento Mensal');
    const debtsSheet = workbook.addWorksheet('Endividamento');
    const abcCustomersSheet = workbook.addWorksheet('Curva ABC - Clientes');
    const abcSuppliersSheet = workbook.addWorksheet('Curva ABC - Fornecedores');

    // Configurando relacao key - header pra cada table
    applyHeaders(assetsSheet, headersAssets);
    applyHeaders(passivesSheet, headersPassives);
    applyHeaders(demonstrationsSheet, headersDemos);
    applyHeaders(indexsIndSheet, headersIndexsInd);
    applyHeaders(billingMonthlySheet, headersBillingMonthly);
    applyHeaders(debtsSheet, headersDebts);
    applyHeaders(abcCustomersSheet, headersAbcCustomers);
    applyHeaders(abcSuppliersSheet, headersAbcSuppliers);

    // aplicando estilo
    applyStyle(assetsSheet);
    applyStyle(passivesSheet);
    applyStyle(demonstrationsSheet);
    applyStyle(indexsIndSheet);
    applyStyle(billingMonthlySheet);
    applyStyle(debtsSheet);
    applyStyle(abcCustomersSheet);
    applyStyle(abcSuppliersSheet);

    // apply rows balance
    applyRowBalanceTables(actives, assetsSheet);
    applyRowBalanceTables(passives, passivesSheet);
    applyRowBalanceTables(demonstrations, demonstrationsSheet);
    applyRowBalanceTables(indexsIndicators, indexsIndSheet);

    // allocating bill Data
    billingsMonthly.forEach(bill => {
      const rowBill = {};
      const monthIndex = monthShortNames.indexOf(bill.month);
      const monthLong = new Date(2024, monthIndex, 1).toLocaleString('pt-BR', {
        month: 'long',
      });

      rowBill['Mês'] = `${monthLong.charAt(0).toUpperCase() + monthLong.slice(1)}`;

      updatedBillingMonthlyTitles(billingMonthlyTitles, yearsBills).forEach(
        header => {
          if (header.key !== 'month') {
            rowBill[header.title] = getCurrencyPtBr(bill[header.key] ?? '0');
          }
        },
      );

      billingMonthlySheet.addRow(rowBill);
    });

    // allocating Debts Data
    debtsItems.forEach((debt, index) => {
      const rowDebt = {};
      const debtsItemsLength = debtsItems.length;
      const lastItem = index + 1;

      rowDebt['Ordem da Dívida'] = `${index + 1}º`;
      rowDebt['Instituição Financeira'] = `${debt.institutionName}`;

      debtsTitles.forEach(header => {
        const exception = ['orderDebt', 'institutionName'];
        if (!exception.includes(header.key)) {
          rowDebt[header.title] = getCurrencyPtBr(debt[header.key] ?? '0');
        }
      });

      debtsSheet.addRow(rowDebt);

      if (lastItem === debtsItemsLength) {
        debtsSheet.addRow({
          'Ordem da Dívida': 'TOTAL',
          'Saldo devedor atual': getCurrencyPtBr(debtsTotal.totalSum),
          'VCTO até 6 meses': getCurrencyPtBr(debtsTotal.totalUntil180dSum),
          'VCTO 6 meses a 12 meses': getCurrencyPtBr(
            debtsTotal.totalBetween181and360dSum,
          ),
          'VCTO acima de 12 meses': getCurrencyPtBr(
            debtsTotal.totalGreaterThan360dSum,
          ),
        });
      }
    });

    // allocating abc customers Data
    applyRowAbcCurveTables(abcCustomers, abcCustomersSheet, isCustomer);
    applyRowAbcCurveTables(abcSuppliers, abcSuppliersSheet, !isCustomer);

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_dashboard.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcelDash,
  };
};
