import { AxiosError } from 'axios';

import { IApiError } from '../types';

export const getApiError = (error: AxiosError | unknown) => {
  const errorBody = (error as AxiosError)?.response?.data;

  if (!errorBody) return errorBody;

  return errorBody as IApiError;
};
