import { useQuery } from 'react-query';

import { DocumentQueryKey } from '../services';
import DocumentService from '../services/services';

export const useActCustomerDocumentsRequest = (id: string) => {
  const { data, ...query } = useQuery(
    [DocumentQueryKey.CUSTOMER_DOCS_LIST],
    () => DocumentService.getCustomerDocuments({ id }),
    { keepPreviousData: true },
  );

  return {
    data,
    ...query,
  };
};
