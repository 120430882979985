import { Close } from '@mui/icons-material';
import { ChipProps as MuiChipProps } from '@mui/material';
import { Colors } from 'themes';

import { ChipOutlinedWrapper } from './styles';

type ChipProps = Omit<MuiChipProps, 'color' | 'variant'> & {
  color?: Colors;
};

export function ChipOutlined({
  color = 'blue',
  deleteIcon = <Close />,
  ...props
}: ChipProps) {
  return (
    <ChipOutlinedWrapper
      customColor={color}
      variant="outlined"
      deleteIcon={deleteIcon}
      {...props}
    />
  );
}
