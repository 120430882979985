import { IForm, IFormQuestion } from 'models';
import { IPagination } from 'models/pagination';

import api from 'config/api';

import {
  IExpressionKeysResponse,
  IFilterFormParams,
  IFormPayload,
  IFormQuestionCreateRequest,
  IUpdateFormQuestionRequest,
  IUpdateFormRequest,
} from './types';

export class FormService {
  static async list(params?: IFilterFormParams) {
    const { data } = await api.get<IPagination<IForm>>(`/forms`, {
      params,
    });
    return data;
  }

  static async listAll(params?: { active?: boolean }) {
    const { data } = await api.get<IForm[]>(`/forms/list-all`, { params });
    return data;
  }

  static async create(form: IFormPayload) {
    const { data } = await api.post<IForm>(`/forms`, form);
    return data;
  }

  static async update({ id, form }: IUpdateFormRequest) {
    const { data } = await api.put<IForm>(`/forms/${id}`, form);
    return data;
  }

  static async getFormQuestions(idForm: string) {
    const { data } = await api.get<IFormQuestion[]>(`/forms/${idForm}/questions`);
    return data;
  }

  static async createFormQuestion({
    formId,
    formQuestion,
  }: IFormQuestionCreateRequest) {
    const { data } = await api.post<IFormQuestion>(
      `/forms/${formId}/form-question`,
      formQuestion,
    );
    return data;
  }

  static async updateFormQuestion({
    formId,
    formQuestionId,
    formQuestion,
  }: IUpdateFormQuestionRequest) {
    const { data } = await api.put<IFormQuestion>(
      `/forms/${formId}/form-question/${formQuestionId}`,
      formQuestion,
    );
    return data;
  }

  static async duplicateForm(formId: string) {
    const { data } = await api.post<IForm>(`/forms/duplicate/${formId}`);

    return data;
  }

  static async getExpressionKeys(formId: string) {
    const { data } = await api.get<IExpressionKeysResponse[]>(
      `/forms/expression-keys/${formId}`,
    );

    return data;
  }

  static async getOnlyExpressionKeys() {
    const { data } = await api.get<IExpressionKeysResponse[]>(
      '/forms/expression-keys',
    );

    return data;
  }
}
