import { Box, Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import { HeaderWrapper } from './styles';

export function Header() {
  const { user } = useAuth();

  return (
    <HeaderWrapper as="header">
      <Box>
        <Typography as="span" variant="body2" color="gray-500">
          <strong>E-mail: </strong>
          {user?.email}
        </Typography>
      </Box>
    </HeaderWrapper>
  );
}
