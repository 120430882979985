import { useContext } from 'react';
import { RoutesProps } from 'routes';

import { Link, Icon } from 'components';

import { PrivateLayoutContext } from 'layouts/PrivateLayout/Context';

import { useMenuItemActive } from '../../hooks';
import { MenuItemWrapper } from './styles';

interface MenuItemProps {
  route: RoutesProps;
}

export function MenuItem({ route }: MenuItemProps) {
  const { active } = useMenuItemActive(route.path);
  const { sidebarBroken, setSidebarToggled } = useContext(PrivateLayoutContext);

  return (
    <MenuItemWrapper
      component={
        <Link to={route.path} className="link">
          {route.name}
        </Link>
      }
      icon={<Icon fontSize="h6">{route.icon}</Icon>}
      active={active}
      onClick={() => sidebarBroken && setSidebarToggled(prev => !prev)}
    >
      {route.name}
    </MenuItemWrapper>
  );
}
