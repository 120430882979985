import { Box, Divider, Typography as MuiTypography } from '@mui/material';

import { FormAnswerStatus } from 'models';

import { EFormAnswerType } from 'modules/dynamicForm/formAnswer';
import DialogConfirmActions from 'modules/dynamicForm/formAnswer/components/DialogConfirmActions';

import { useFormResume } from '../../hooks';
import { QuestionOption } from './QuestionOption';

export const ResumeContainer = () => {
  const {
    formAnswers,
    openModalCancel,
    openModalFinishLater,
    openModalSendForm,
    type,
    getQuestionRevision,
    handleFinishLater,
    handleSendForm,
    setOpenModalSendForm,
    setOpenModalCancel,
    setOpenModalFinishLater,
    handleCancelForm,
  } = useFormResume();

  const formAnswereds = formAnswers?.filter(
    answer => answer.status === FormAnswerStatus.ANSWERED,
  );

  const formDiscardeds = formAnswers?.filter(
    answer => answer.status === FormAnswerStatus.DISCARDED,
  );

  const messageSendForm =
    type === EFormAnswerType.simulation
      ? 'Tem certeza que deseja finalizar a simulação do formulário?'
      : 'Tem certeza que deseja finalizar o formulário?';

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      maxHeight={'75%'}
      overflow={'auto'}
      mt={2}
      mb={5}
    >
      <DialogConfirmActions
        title={'Cancelar'}
        informationCard={
          'Tem certeza que deseja cancelar? Todo progresso será perdido.'
        }
        showDialog={openModalCancel}
        setShowDialog={setOpenModalCancel}
        handleAction={handleCancelForm}
      />

      <DialogConfirmActions
        title={'Finalizar em outro momento'}
        informationCard={'Tem certeza que deseja finalizar em outro momento?'}
        showDialog={openModalFinishLater}
        setShowDialog={setOpenModalFinishLater}
        handleAction={handleFinishLater}
      />

      <DialogConfirmActions
        title={
          type === EFormAnswerType.simulation
            ? 'Finalizar Simulação'
            : 'Enviar Formulário'
        }
        informationCard={messageSendForm}
        showDialog={openModalSendForm}
        setShowDialog={setOpenModalSendForm}
        handleAction={handleSendForm}
      />

      <Box minWidth={1150} width={'95%'}>
        <Box display={'flex'} flexDirection={'column'} ml={10}>
          <MuiTypography fontWeight={'bold'} fontSize={16}>
            {'Perguntas da trilha de respostas'}
          </MuiTypography>
          <MuiTypography fontSize={16} mt={1}>
            {
              'Essas são as perguntas que fazem parte da sua trilha final de respostas'
            }
          </MuiTypography>
          <Box mt={3}>
            <Divider />
          </Box>
          {formAnswereds.map((answer, index) => (
            <QuestionOption
              key={index}
              number={index + 1}
              title={answer.formQuestion.question.title}
              id={answer?.id ?? ''}
              openRevisionQuestion={getQuestionRevision}
              type={'trail'}
            />
          ))}
        </Box>

        <Box display={'flex'} flexDirection={'column'} ml={10} mt={4}>
          <MuiTypography fontWeight={'bold'} fontSize={16}>
            {'Perguntas isoladas'}
          </MuiTypography>

          <MuiTypography fontSize={16}>
            {
              'Essas são as perguntas que, por alteração em alguma resposta anterior, não fazem mais parte da sua trilha final de respostas'
            }
          </MuiTypography>
          <Box mt={3}>
            <Divider />
          </Box>
          {formDiscardeds.map((answer, index) => (
            <QuestionOption
              key={index}
              number={index + 1}
              title={answer.formQuestion.question.title}
              id={answer?.id ?? ''}
              openRevisionQuestion={getQuestionRevision}
              type={'discarded'}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
