import { Card } from '@mui/material';
import { Link } from 'react-router-dom';

import { Box, Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import {
  EmptyDashboard,
  OperationStatusTable,
  useDashboardCreditor,
  useDashboardEnterprise,
} from 'modules/dashboard';
import { IndexesAndIndicatorsTable } from 'modules/dashboard/components/tables/IndexesAndIndicatorsTable';

export const ChartsContainer = () => {
  const { user } = useAuth();
  const { operationsNoData } = useDashboardCreditor();

  const { indexesAndIndicatorsNoData, data, isLoading } = useDashboardEnterprise();

  const noData = indexesAndIndicatorsNoData;

  const isEnterprise = !user?.enterprise?.isFinancing;

  if ((!isEnterprise && operationsNoData) || (isEnterprise && noData)) {
    return <EmptyDashboard />;
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      marginTop={25}
    >
      <Box width={'98%'} mb={5} pr={4}>
        <Card elevation={3}>
          <Box display="flex" flexDirection="column">
            <Box display={'flex'} justifyContent={'center'} mt={6}>
              <Typography variant="h4">Indicadores</Typography>
            </Box>
            <Typography
              as={Link}
              to="/dashboard"
              style={{
                textDecoration: 'none',
                border: '1px solid',
                borderRadius: '5px',
                alignSelf: 'self-end',
                padding: '3px 10px',
                marginTop: '1em',
                marginRight: '1em',
              }}
            >
              Ver mais
            </Typography>
            <Box display={'flex'} justifyContent={'center'} mb={6}>
              <Box width={'90%'}>
                {isEnterprise ? (
                  <>
                    {/* <Box marginTop={30}>
                      <VetoesAndAlertsTable
                        data={vetoesAndAlertsData}
                        noData={noData}
                        isLoading={isLoading}
                      />
                    </Box> */}
                    <Box marginTop={30}>
                      <IndexesAndIndicatorsTable
                        data={data?.indexesAndIndicators}
                        noData={indexesAndIndicatorsNoData}
                        isLoading={isLoading}
                      />
                    </Box>
                  </>
                ) : (
                  <Box marginTop={30}>
                    <OperationStatusTable />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
