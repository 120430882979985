import {
  Divider,
  Grid,
  MenuItem,
  Typography,
  InputBaseComponentProps,
} from '@mui/material';
import { ElementType } from 'react';

import {
  Dialog,
  Box,
  ButtonContained,
  ButtonOutlined,
  Switch,
  TextField,
  Select,
  TextMask,
  SectionButtons,
} from 'components';
import { useSearchArrayDebounce, useSearchDebounce, useMaskInputs } from 'hooks';

import DialogConfirmActions from 'modules/dynamicForm/formAnswer/components/DialogConfirmActions';

import { OptionsValues } from 'utils/helper';

import { UseConfigAct, useConfigAct } from '../../hooks';
import ConfigActsAutocomplete from './ConfigActsAutocomplete';

export interface DialogFormProps {
  form: UseConfigAct['form'];
  isCreatingAct?: boolean;
}

export const ConficAct = ({ form, isCreatingAct }: DialogFormProps) => {
  const { DATE_MASK } = useMaskInputs();

  const {
    USER_TYPE_HANDLE_ACT,
    USER_TYPES,
    ACTS_TYPES,
    CLIENT_STATUS,
    OPERATION_STATUS,
    PRODUCTS_CLIENT,
    OPERATIONS_TYPES,
    formOptions,
    notificationDeadlines,
    user,
    linkRouteLogin,
    variables,
    currentAct,
    openConfigActsDialog,
    openModalOut,
    actOptions,
    setSearchActType,
    setPermissionUserAnswer,
    setPermissionUserView,
    setPermissionClientView,
    setPermissionClientIssue,
    setSearchTriggerForm,
    setPresentDatabaseInformations,
    setNotifyAfter,
    setNotifyBefore,
    setProductClient,
    setOperation,
    setOpenModalOut,
    handleCloseDialog,
  } = useConfigAct();

  const triggerFormSwitch = form.watch('triggerForm');
  const showInfoSwitch = form.watch('showInfo');
  const showDbInfoSwitch = form.watch('showDbInfo');
  const generateActSwitch = form.watch('generateAct');
  const productClientSwitch = form.watch('defineProductClient');
  const operationSwitch = form.watch('defineOp');
  const showScheduleLinkSwitch = form.watch('showScheduleLink');
  const showDeadlineSwitch = form.watch('showDeadline');
  const triggerNotificationSwitch = form.watch('triggerNotification');
  const changeStatusClientSwitch = form.watch('changeStatusClient');
  const changeStatusOpSwitch = form.watch('changeStatusOp');
  const sendEmailSwitch = form.watch('sendEmail');

  const { handleChangeSearch: handleChangeSearchActType } = useSearchDebounce(
    setSearchActType,
  );

  const {
    handleChangeSearch: handleChangePermissionUserAnswer,
  } = useSearchArrayDebounce(setPermissionUserAnswer);

  const {
    handleChangeSearch: handleChangePermissionUserView,
  } = useSearchArrayDebounce(setPermissionUserView);

  const {
    handleChangeSearch: handleChangePermissionClientView,
  } = useSearchArrayDebounce(setPermissionClientView);

  const {
    handleChangeSearch: handleChangePermissionClientIssue,
  } = useSearchArrayDebounce(setPermissionClientIssue);

  const { handleChangeSearch: handleChangeSearchTriggerForm } = useSearchDebounce(
    setSearchTriggerForm,
  );

  const {
    handleChangeSearch: handleChangePresentDatabaseInformations,
  } = useSearchArrayDebounce(setPresentDatabaseInformations);

  const { handleChangeSearch: handleChangeNotifyBefore } = useSearchArrayDebounce(
    setNotifyBefore,
  );

  const { handleChangeSearch: handleChangeNotifyAfter } = useSearchArrayDebounce(
    setNotifyAfter,
  );

  const {
    handleChangeSearch: handleChangeSearchProductClient,
  } = useSearchArrayDebounce(setProductClient);

  const { handleChangeSearch: handleChangeSearchOperation } = useSearchDebounce(
    setOperation,
  );

  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  return (
    <Dialog.Root open={openConfigActsDialog} onClose={() => handleCloseDialog()}>
      <Dialog.Header
        title={currentAct?.id ? 'Edição de ato' : 'Configuração de ato'}
      />
      <DialogConfirmActions
        title={'Sair'}
        informationCard={'Você tem certeza que deseja sair sem salvar?'}
        showDialog={openModalOut}
        setShowDialog={setOpenModalOut}
        handleAction={handleCloseDialog}
      />
      <Box overflow={'auto'}>
        <Box marginTop={5} paddingLeft={20} width={'100%'}>
          <Switch name="active" label="Ativo" control={control} />
        </Box>

        <Box as="form" display={'flex'} flexDirection={'row'} maxWidth={1100}>
          <Grid
            container
            maxWidth={550}
            minWidth={550}
            pt={4}
            pl={6}
            pr={6}
            pb={4}
            spacing={2}
            mb={3}
            alignItems={'start'}
            alignContent={'flex-start'}
          >
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nome"
                control={control}
                type="text"
                error={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descrição"
                control={control}
                type="text"
                error={errors.description}
              />
            </Grid>

            <Grid item xs={12}>
              <ConfigActsAutocomplete
                fullWidth
                control={control}
                name="type"
                label={'Selecione o tipo de ato'}
                stringValue={true}
                options={ACTS_TYPES}
                error={errors.type}
                onInputChange={value => handleChangeSearchActType(value)}
                isOptionEqualToValue={(option, value) =>
                  (option as OptionsValues).value === value
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ConfigActsAutocomplete
                fullWidth
                control={control}
                name="clientViewAndAnswer"
                label={'Tipo de cliente que pode ver/responder o ato'}
                multiple={true}
                stringValue={true}
                options={USER_TYPES.filter(
                  userType =>
                    !form.watch('clientViewAndAnswer').includes(userType.label),
                )}
                error={errors.clientViewAndAnswer}
                onInputChange={value => {
                  handleChangePermissionClientView(value);
                }}
                isOptionEqualToValue={(option, value) => {
                  return (option as OptionsValues).value === value;
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <ConfigActsAutocomplete
                fullWidth
                control={control}
                name="clientEmit"
                label={'Tipo de cliente que pode emitir o ato'}
                multiple={true}
                stringValue={true}
                options={USER_TYPES.filter(
                  userType => !form.watch('clientEmit').includes(userType.label),
                )}
                error={errors.clientEmit}
                onInputChange={async value => {
                  handleChangePermissionClientIssue(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  (option as OptionsValues).value === value
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ConfigActsAutocomplete
                fullWidth
                control={control}
                name="userViewAndAnswer"
                label={'Tipo de usuário que pode ver/responder o ato'}
                multiple={true}
                stringValue={true}
                options={USER_TYPE_HANDLE_ACT.filter(
                  userType =>
                    !form.watch('userViewAndAnswer').includes(userType.label),
                )}
                error={errors.userViewAndAnswer}
                onInputChange={value => handleChangePermissionUserAnswer(value)}
                isOptionEqualToValue={(option, value) =>
                  (option as OptionsValues).value === value
                }
              />
            </Grid>

            <Grid item xs={12}>
              <ConfigActsAutocomplete
                fullWidth
                control={control}
                name="userEmit"
                label={'Tipo de usuário que pode emitir o ato'}
                multiple={true}
                stringValue={true}
                options={USER_TYPE_HANDLE_ACT.filter(
                  userType => !form.watch('userEmit').includes(userType.label),
                )}
                error={errors.userEmit}
                onInputChange={value => handleChangePermissionUserView(value)}
                isOptionEqualToValue={(option, value) =>
                  (option as OptionsValues).value === value
                }
              />
            </Grid>

            <Box marginTop={5} marginLeft={5} width={'100%'}>
              <Switch
                name="triggerForm"
                label="Disparar formulário"
                control={control}
              />

              {!!triggerFormSwitch && (
                <Grid item xs={12}>
                  <ConfigActsAutocomplete
                    fullWidth
                    control={control}
                    name="formId"
                    label={'Selecione formulário pré-definido (opcional)'}
                    stringValue={true}
                    options={formOptions}
                    error={errors.formId}
                    onInputChange={value => handleChangeSearchTriggerForm(value)}
                    isOptionEqualToValue={(option, value) =>
                      (option as OptionsValues).value === value
                    }
                  />
                </Grid>
              )}
            </Box>

            <Box marginLeft={5} width={'100%'}>
              <Switch
                name="showInfo"
                label="Apresentar informações"
                control={control}
              />

              {showInfoSwitch && (
                <TextField
                  control={control}
                  name="info"
                  label="Informações pré-definidas para apresentar (opcional)"
                  multiline
                  rows={4}
                  fullWidth
                  maxRows={Infinity}
                  type={'text'}
                  error={errors.info}
                />
              )}
            </Box>

            <Box marginLeft={5} width={'100%'}>
              <Switch
                name="showDbInfo"
                label="Apresentar informações da base de dados"
                control={control}
              />

              {!!showDbInfoSwitch && (
                <Box mt={5}>
                  <Select
                    name="clientTypeInfo"
                    control={control}
                    label="Tipo de cliente cujas informações serão apresentadas (opcional)"
                  >
                    {USER_TYPES.map(userType => (
                      <MenuItem key={userType.value} value={userType.label}>
                        {userType.label}
                      </MenuItem>
                    ))}
                  </Select>

                  <ConfigActsAutocomplete
                    style={{ marginTop: 15 }}
                    fullWidth
                    control={control}
                    name="dbInfo"
                    label={
                      'Informações para apresentar da base de dados (opcional)'
                    }
                    multiple={true}
                    stringValue={true}
                    options={variables.filter(
                      variable => !form.watch('dbInfo')?.includes(variable.label),
                    )}
                    error={errors.dbInfo}
                    onInputChange={value =>
                      handleChangePresentDatabaseInformations(value)
                    }
                    isOptionEqualToValue={(option, value) =>
                      (option as OptionsValues).value === value
                    }
                  />
                </Box>
              )}

              <Box width={'100%'}>
                <Switch
                  name="textInputAnswer"
                  label="Habilitar input de texto - resposta"
                  control={control}
                />
              </Box>
            </Box>
          </Grid>

          <Box mt={5} mb={5} minWidth={5}>
            <Divider orientation="vertical" />
          </Box>
          {/*  A PARTIR DAAQUI SIDE PAGE */}
          <Grid
            container
            maxWidth={550}
            minWidth={550}
            pt={3}
            pl={6}
            pr={6}
            spacing={2}
          >
            <Box marginTop={5} marginLeft={5} width={'100%'}>
              <Switch name="generateAct" label="Gerar Ato" control={control} />

              {!!generateActSwitch && (
                <ConfigActsAutocomplete
                  fullWidth
                  control={control}
                  name="actId"
                  label={'Selecione o ato'}
                  stringValue={true}
                  options={actOptions}
                  error={errors.actId}
                  onInputChange={value =>
                    handleChangePresentDatabaseInformations(value)
                  }
                  isOptionEqualToValue={(option, value) =>
                    (option as OptionsValues).value === value
                  }
                />
              )}

              <Switch
                name="defineProductClient"
                label="Definir produto para cliente"
                control={control}
              />

              {!!productClientSwitch && (
                <ConfigActsAutocomplete
                  fullWidth
                  control={control}
                  name="productClient"
                  label={'Selecione o produto (opcional)'}
                  multiple={true}
                  stringValue={true}
                  options={PRODUCTS_CLIENT}
                  error={errors.productClient}
                  onInputChange={value => handleChangeSearchProductClient(value)}
                  isOptionEqualToValue={(option, value) =>
                    (option as OptionsValues).value === value
                  }
                />
              )}

              <Box width={'100%'}>
                <Switch
                  name="createOp"
                  label="Criar operação entre instituição financeira e empresa"
                  control={control}
                />
              </Box>

              <Switch name="defineOp" label="Definir operação" control={control} />

              {!!operationSwitch && (
                <ConfigActsAutocomplete
                  fullWidth
                  control={control}
                  name="operation"
                  label={'Selecione a operação (opcional)'}
                  stringValue={true}
                  options={OPERATIONS_TYPES}
                  error={errors.type}
                  onInputChange={value => handleChangeSearchOperation(value)}
                  isOptionEqualToValue={(option, value) =>
                    (option as OptionsValues).value === value
                  }
                />
              )}

              <Switch
                name="defineValueOp"
                label="Definir valor da operação"
                control={control}
              />

              <Switch
                name="showScheduleLink"
                label="Apresentar link agenda"
                control={control}
              />
              {!!showScheduleLinkSwitch && (
                <TextField
                  control={control}
                  name="scheduleLink"
                  label="Link agenda pré-definido (opcional)"
                  type="text"
                />
              )}

              <Switch
                name="showDeadline"
                label="Apresentar Prazo"
                control={control}
              />
              {!!showDeadlineSwitch && (
                <TextField
                  name="deadline"
                  type="text"
                  control={control}
                  error={errors.deadline}
                  label="Prazo pré-definido (opcional)"
                  inputProps={{
                    mask: DATE_MASK.DEFAULT,
                  }}
                  InputProps={{
                    inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
                  }}
                  fullWidth
                />
              )}

              <Switch
                name="triggerNotification"
                label="Disparar notificação"
                control={control}
              />

              {!!triggerNotificationSwitch && (
                <>
                  <ConfigActsAutocomplete
                    fullWidth
                    control={control}
                    name="notifyBefore"
                    label={'Notificações - dias antes do prazo'}
                    multiple={true}
                    stringValue={true}
                    options={notificationDeadlines.filter(
                      deadline =>
                        !form.watch('notifyBefore')?.includes(deadline.label),
                    )}
                    error={errors.notifyBefore}
                    onInputChange={value => handleChangeNotifyBefore(value)}
                    isOptionEqualToValue={(option, value) =>
                      (option as OptionsValues).value === value
                    }
                  />

                  <ConfigActsAutocomplete
                    style={{ marginTop: 10 }}
                    fullWidth
                    control={control}
                    name="notifyAfter"
                    label={'Notificações - dias depois do prazo'}
                    multiple={true}
                    stringValue={true}
                    options={notificationDeadlines.filter(
                      deadline =>
                        !form.watch('notifyAfter')?.includes(deadline.label),
                    )}
                    error={errors.notifyAfter}
                    onInputChange={value => handleChangeNotifyAfter(value)}
                    isOptionEqualToValue={(option, value) =>
                      (option as OptionsValues).value === value
                    }
                  />
                </>
              )}

              <Switch
                name="changeStatusClient"
                label="Mudar status do cliente ao completar ato"
                control={control}
              />

              {!!changeStatusClientSwitch && (
                <Select
                  name="newStatusClient"
                  control={control}
                  label="Selecione status destino do cliente"
                >
                  {CLIENT_STATUS.map(userType => (
                    <MenuItem key={userType.value} value={userType.label}>
                      {userType.label}
                    </MenuItem>
                  ))}
                </Select>
              )}

              <Switch
                name="changeStatusOp"
                label="Mudar status da operação ao completar ato"
                control={control}
              />

              {!!changeStatusOpSwitch && (
                <Select
                  name="newStatusOp"
                  control={control}
                  label="Selecione status destino da operação"
                >
                  {OPERATION_STATUS.map(userType => (
                    <MenuItem key={userType.value} value={userType.label}>
                      {userType.label}
                    </MenuItem>
                  ))}
                </Select>
              )}

              <Switch
                name="allowCharge"
                label="Permitir cobrança"
                control={control}
              />

              <Switch
                name="triggerDocSign"
                label="Disparar documento para assinatura digital"
                control={control}
              />

              <Switch name="allowAttach" label="Permitir anexo" control={control} />

              <Switch
                name="allowAnswerAttach"
                label="Permitir anexo - Resposta"
                control={control}
              />

              <Switch name="sendEmail" label="Enviar email" control={control} />

              {!!sendEmailSwitch && (
                <>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Box width={'15%'} display={'flex'} alignItems={'center'}>
                      <Typography color={'black'}>ExFin -</Typography>
                    </Box>
                    <TextField
                      control={control}
                      name="emailTitle"
                      label="Título Email pré-definido (opcional)"
                      type="text"
                      error={errors.emailTitle}
                    />
                  </Box>
                  <Box mt={5} mb={5}>
                    <Typography color={'black'} fontStyle={'italic'}>
                      {`Ola, ${user.user?.name} `}
                    </Typography>
                    <Box mt={4}>
                      <TextField
                        control={control}
                        name="emailBody"
                        label="Corpo Email pré-definido (opcional)"
                        multiline
                        rows={4}
                        fullWidth
                        maxRows={Infinity}
                        type={'text'}
                        error={errors.emailBody}
                      />
                    </Box>
                    <Typography color={'black'} fontStyle={'italic'}>
                      <br />
                      Fique atento ao seu email para possíveis contatos e
                      solicitações realizados pela equipe ExFin.
                      <br /> <br />
                      Para acessar a plataforma, clique{' '}
                      <a
                        href={linkRouteLogin}
                        target="_blank"
                        style={{ color: '#1f1d1c' }}
                        rel="noreferrer"
                      >
                        aqui
                      </a>
                      .<br /> Se necessário, entre em contato com um de nossos
                      consultores clicando{' '}
                      <a href="#" target="_blank" style={{ color: '#1f1d1c' }}>
                        aqui
                      </a>
                      . <br /> <br />© ExFin
                    </Typography>
                  </Box>
                </>
              )}

              <Switch
                name="negotiationEnd"
                label="Encerramento de negociação"
                control={control}
              />

              <Switch
                name="closureAct"
                label="Ato de encerramento"
                control={control}
              />
            </Box>
          </Grid>
        </Box>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Sair" onClick={() => setOpenModalOut(true)} />
        <ButtonContained
          label="Salvar"
          onClick={() => handleSubmitForm()}
          disabled={!isValid}
          loading={isCreatingAct}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
