import ExcelJS from 'exceljs';

import { inactiveClients } from 'models';

import { useScrRequest } from './useScrRequest';

export const useDownloadExcel = () => {
  const { data: response } = useScrRequest({
    limitPagination: true,
  });

  const dataSrc = response?.data;

  const getDatePtBr = (date: string | Date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('pt-BR');
  };

  const getCurrencyPtBr = (value: number) => {
    if (value === 0) return '';

    const newValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return newValue;
  };

  const handleDownloadExcel = async () => {
    const titleCell: ExcelJS.FillPattern = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0070C0' }, // Azul
    };

    // Criando uma nova planilha Excel
    const workbook = new ExcelJS.Workbook();
    const tableWorksheet = workbook.addWorksheet('Dados - Tabela SCR');
    const consultsWorksheet = workbook.addWorksheet('Consultas - SCR');
    const scrMethodKWorksheet = workbook.addWorksheet('Consultas - SCR Método K');
    const indebtednessesWorksheet = workbook.addWorksheet('Dados de Endividamento');

    const headersTable = [
      'CNPJ',
      'NOME FANTASIA',
      'ENDIVIDAMENTO BANCÁRIO (SALDO)',
      'STATUS',
      'ATIVO/INATIVO',
      'DATA ULTIMA CONSULTA',
    ];

    const headersConsultSCR = [
      'CNPJ',
      'NOME FANTASIA',
      'CONSULTA SCR - DATA',
      'CONSULTA SCR - QUANTIDADE OPERAÇÕES',
      'CONSULTA SCR - QUANTIDADE INSTITUIÇÕES',
      'CONSULTA SCR - QUANTIDADE OPERAÇÃO SUB JUDICE',
      'CONSULTA SCR - TOTAL RESPONSABILIDADE SUB JUDICE',
      'CONSULTA SCR - QUANTIDADE OPERAÇÕES COM DISCORDÂNCIA',
      'CONSULTA SCR - TOTAL RESPONSABILIDADE COM DISCORDÂNCIA',
      'CONSULTA SCR - OBRIGAÇÃO ASSUMIDA',
      'CONSULTA SCR - RISCO INDIRETO DO FORNECEDOR',
      'CONSULTA SCR - PORCENTAGEM DE DOCS PROCESSADOS',
      'CONSULTA SCR - PORCENTAGEM DE VOLUME PROCESSADO',
      'CONSULTA SCR - INFO ENCONTRADAS SEM ERROS',
      'CONSULTA SCR - DATA INICIAL DE RELACIONAMENTO',
    ];

    const headersScrMethodK = [
      'CNPJ',
      'NOME FANTASIA',
      'CONSULTA SCR METODO K - DATA DA CONSULTA',
      'CONSULTA SCR METODO K - QTD DE INSTITUICOES',
      'CONSULTA SCR METODO K - QUANTIDADE DE OPERAÇÕES',
      'CONSULTA SCR METODO K - VOLUME VENCIDO',
      'CONSULTA SCR METODO K (Resumo) - CÓDIGO DO PRODUTO',
      'CONSULTA SCR METODO K (Resumo) - NOME DO PRODUTO',
      'CONSULTA SCR METODO K (Resumo) - TOTAL MENOS DE 90 DIAS',
      'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 91 E 180 DIAS',
      'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 181 E 360 DIAS',
      'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 361 E 720 DIAS',
      'CONSULTA SCR METODO K (Resumo) - TOTAL ACIMA DE 720 DIAS',
      'CONSULTA SCR METODO K (Resumo) - TOTAL',
    ];

    const headersIndebtednesses = [
      'CNPJ',
      'NOME FANTASIA',
      'IDENTIFICADOR DOCUMENTO ANÁLISE',
      'VALOR TOTAL',
      'DADOS DE ENDIVIDAMENTO - GARANTIA',
      'DADOS DE ENDIVIDAMENTO - NOME DA INSTITUIÇÃO',
      'DADOS DE ENDIVIDAMENTO - TAXA',
      'DADOS DE ENDIVIDAMENTO - VALOR',
    ];

    // Configurando relacao key - header pra cada table
    tableWorksheet.columns = headersTable.map(header => ({
      header,
      key: header,
    }));

    consultsWorksheet.columns = headersConsultSCR.map(header => ({
      header,
      key: header,
    }));

    scrMethodKWorksheet.columns = headersScrMethodK.map(header => ({
      header,
      key: header,
    }));

    indebtednessesWorksheet.columns = headersIndebtednesses.map(header => ({
      header,
      key: header,
    }));

    // Aplicando a cor de fundo azul para a primeira linha (headers)
    tableWorksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
      cell.fill = titleCell;
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
    });

    consultsWorksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
      cell.fill = titleCell;
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
    });

    scrMethodKWorksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
      cell.fill = titleCell;
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
    });

    indebtednessesWorksheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
      cell.fill = titleCell;
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
    });

    // Preenchendo os dados
    dataSrc?.forEach(scr => {
      const rowTable = {};

      // PREENCHENDO DADOS TABLE
      rowTable['CNPJ'] = scr.document;
      rowTable['NOME FANTASIA'] = scr.enterprise.fantasyName;
      rowTable['ENDIVIDAMENTO BANCÁRIO (SALDO)'] = getCurrencyPtBr(
        scr.documentOcrIndebtedness?.total ?? 0,
      );
      rowTable['STATUS'] = scr.enterprise?.status;
      rowTable['ATIVO/INATIVO'] = inactiveClients.includes(scr.enterprise?.status)
        ? 'Inativo'
        : 'Ativo';
      rowTable['DATA ULTIMA CONSULTA'] = getDatePtBr(scr.lastConsultDate);

      tableWorksheet.addRow(rowTable);

      // //PREENCHENDADO DADOS CONSULTAS SCR
      const consults = scr?.scr?.scrInformation?.scr;

      consults?.map(consult => {
        const rowConsults = {};

        rowConsults['NOME FANTASIA'] = scr.enterprise.fantasyName;
        rowConsults['CNPJ'] = scr.document;
        rowConsults['CONSULTA SCR - DATA'] = consult.consultDate;
        rowConsults[
          'CONSULTA SCR - QUANTIDADE OPERAÇÕES'
        ] = consult.countOperation.toString();
        rowConsults[
          'CONSULTA SCR - QUANTIDADE INSTITUIÇÕES'
        ] = consult.countInstitution.toString();
        rowConsults[
          'CONSULTA SCR - QUANTIDADE OPERAÇÃO SUB JUDICE'
        ] = consult.countOperationSubJudice.toString();
        rowConsults[
          'CONSULTA SCR - TOTAL RESPONSABILIDADE SUB JUDICE'
        ] = getCurrencyPtBr(consult.responsibilityTotalSubJudice);
        rowConsults[
          'CONSULTA SCR - QUANTIDADE OPERAÇÕES COM DISCORDÂNCIA'
        ] = consult.countOperationDisagreement.toString();
        rowConsults[
          'CONSULTA SCR - TOTAL RESPONSABILIDADE COM DISCORDÂNCIA'
        ] = getCurrencyPtBr(consult.responsibilityTotalDisagreement);
        rowConsults['CONSULTA SCR - OBRIGAÇÃO ASSUMIDA'] = getCurrencyPtBr(
          consult.assumedObligation,
        );
        rowConsults[
          'CONSULTA SCR - RISCO INDIRETO DO FORNECEDOR'
        ] = consult.vendorIndirectRisk.toString();
        rowConsults[
          'CONSULTA SCR - PORCENTAGEM DE DOCS PROCESSADOS'
        ] = consult.percentDocumentProcessed.toLocaleString('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
        });
        rowConsults[
          'CONSULTA SCR - PORCENTAGEM DE VOLUME PROCESSADO'
        ] = consult.percentVolumeProcessed.toLocaleString('pt-br', {
          style: 'percent',
          maximumFractionDigits: 2,
        });
        rowConsults['CONSULTA SCR - INFO ENCONTRADAS SEM ERROS'] = consult.find
          ? 'Sim'
          : 'Não';
        rowConsults['CONSULTA SCR - DATA INICIAL DE RELACIONAMENTO'] = getDatePtBr(
          consult.startRelationshipDate,
        );
        consultsWorksheet.addRow(rowConsults);
      });

      // //PREENCHENDADO DADOS CONSULTAS SCR MÉTODO K E RESUMO
      const scrMethodsK = scr?.scr?.scrMethodK;

      scrMethodsK?.map(scrMK => {
        const rowMethodsK = {};
        const summary = scrMK.sumaries;

        if (!summary || summary.length === 0) {
          rowMethodsK['CNPJ'] = scr.document;
          rowMethodsK['NOME FANTASIA'] = scr.enterprise.fantasyName;
          rowMethodsK['CONSULTA SCR METODO K - DATA DA CONSULTA'] = getDatePtBr(
            scrMK.consultDate,
          );
          rowMethodsK[
            'CONSULTA SCR METODO K - QTD DE INSTITUICOES'
          ] = scrMK.amountInstitutions.toString();
          rowMethodsK[
            'CONSULTA SCR METODO K - QUANTIDADE DE OPERAÇÕES'
          ] = scrMK.amountOperations.toString();
          rowMethodsK['CONSULTA SCR METODO K - VOLUME VENCIDO'] = getCurrencyPtBr(
            scrMK.expiredVolume,
          );
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - CÓDIGO DO PRODUTO'] = '';
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - NOME DO PRODUTO'] = '';
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - TOTAL MENOS DE 90 DIAS'] =
            '';
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 91 E 180 DIAS'
          ] = '';
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 181 E 360 DIAS'
          ] = '';
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 361 E 720 DIAS'
          ] = '';
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - TOTAL ACIMA DE 720 DIAS'] =
            '';
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - TOTAL'] = '';

          scrMethodKWorksheet.addRow(rowMethodsK);
          return;
        }

        summary?.map(summ => {
          rowMethodsK['CNPJ'] = scr.document;
          rowMethodsK['NOME FANTASIA'] = scr.enterprise.fantasyName;
          rowMethodsK['CONSULTA SCR METODO K - DATA DA CONSULTA'] = getDatePtBr(
            scrMK.consultDate,
          );
          rowMethodsK[
            'CONSULTA SCR METODO K - QTD DE INSTITUICOES'
          ] = scrMK.amountInstitutions.toString();
          rowMethodsK[
            'CONSULTA SCR METODO K - QUANTIDADE DE OPERAÇÕES'
          ] = scrMK.amountOperations.toString();
          rowMethodsK['CONSULTA SCR METODO K - VOLUME VENCIDO'] = getCurrencyPtBr(
            scrMK.expiredVolume,
          );
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - CÓDIGO DO PRODUTO'] =
            summ.productCode;
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - NOME DO PRODUTO'] =
            summ.productName;
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL MENOS DE 90 DIAS'
          ] = getCurrencyPtBr(summ.totalLessThan90d);
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 91 E 180 DIAS'
          ] = getCurrencyPtBr(summ.totalBetween91and180d);
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 181 E 360 DIAS'
          ] = getCurrencyPtBr(summ.totalBetween181and360d);
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ENTRE 361 E 720 DIAS'
          ] = getCurrencyPtBr(summ.totalBetween361and720d);
          rowMethodsK[
            'CONSULTA SCR METODO K (Resumo) - TOTAL ACIMA DE 720 DIAS'
          ] = getCurrencyPtBr(summ.totalGreaterThan720d);
          rowMethodsK['CONSULTA SCR METODO K (Resumo) - TOTAL'] = getCurrencyPtBr(
            summ.total,
          );

          scrMethodKWorksheet.addRow(rowMethodsK);
        });
      });

      // //PREENCHENDADO DADOS DE ENDIVIDAMENTO
      const indebtednesses = scr.documentOcrIndebtedness?.indebtednesses;

      // caso nao tenha nenhum dado de endividamento;
      if (!indebtednesses || indebtednesses.length === 0) {
        const indebt = scr.documentOcrIndebtedness;

        if (!indebt?.documentId && !indebt?.total) return;
        const rowIndebts = {};

        rowIndebts['CNPJ'] = scr.document;
        rowIndebts['NOME FANTASIA'] = scr.enterprise.fantasyName;
        rowIndebts['IDENTIFICADOR DOCUMENTO ANÁLISE'] =
          scr.documentOcrIndebtedness?.documentId;
        rowIndebts['VALOR TOTAL'] = getCurrencyPtBr(
          scr.documentOcrIndebtedness?.total ?? 0,
        );
        rowIndebts['DADOS DE ENDIVIDAMENTO - GARANTIA'] = '';
        rowIndebts['DADOS DE ENDIVIDAMENTO - NOME DA INSTITUIÇÃO'] = '';
        rowIndebts['DADOS DE ENDIVIDAMENTO - TAXA'] = '';
        rowIndebts['DADOS DE ENDIVIDAMENTO - VALOR'] = '';

        indebtednessesWorksheet.addRow(rowIndebts);
      }

      indebtednesses?.map(indebt => {
        const rowIndebts = {};

        rowIndebts['CNPJ'] = scr.document;
        rowIndebts['NOME FANTASIA'] = scr.enterprise.fantasyName;
        rowIndebts['IDENTIFICADOR DOCUMENTO ANÁLISE'] =
          scr.documentOcrIndebtedness?.documentId;
        rowIndebts['VALOR TOTAL'] = getCurrencyPtBr(
          scr.documentOcrIndebtedness?.total ?? 0,
        );
        rowIndebts['DADOS DE ENDIVIDAMENTO - GARANTIA'] = indebt.guarantee;
        rowIndebts['DADOS DE ENDIVIDAMENTO - NOME DA INSTITUIÇÃO'] =
          indebt.institution;
        rowIndebts['DADOS DE ENDIVIDAMENTO - TAXA'] = indebt.tax.toLocaleString(
          'pt-br',
          {
            style: 'percent',
            maximumFractionDigits: 2,
          },
        );
        rowIndebts['DADOS DE ENDIVIDAMENTO - VALOR'] = getCurrencyPtBr(
          indebt.value ?? 0,
        );

        indebtednessesWorksheet.addRow(rowIndebts);
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_scr.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcel,
  };
};
