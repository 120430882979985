import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IQuestion, QuestionType, QuestionTypeSendApi } from 'models';

import { IQuestionAttachmentPayload } from 'modules/dynamicForm/questionAttachment';

import { IQuestionPayload } from '../services';
import { useQuestionStore } from '../store/store';
import {
  IVariableForm,
  useCreateQuestionAttachmentRequest,
  useFindQuestionAttachmentRequest,
  useFindVariables,
  useUpdateQuestionAttachmentRequest,
  useUpdateQuestionRequest,
  useVariableForm,
} from './';
import { useCreateQuestionRequest } from './useCreateQuestion';

export const useQuestionDialog = () => {
  const {
    openFormDialog,
    dataQuestion,
    currentQuestion,
    questionAttachment,
    setOpenFormDialog,
    setCurrentQuestion,
    setQuestionAttachments,
  } = useQuestionStore();

  const { variables } = useFindVariables();
  const form = useVariableForm();
  const queryClient = useQueryClient();

  useEffect(() => {
    const formVariable: IVariableForm = {
      title: currentQuestion?.systemValueMapping?.title ?? '',
    };
    form.reset(formVariable);
  }, [currentQuestion]);

  const handleOpenFormDialog = async (data: IQuestion) => {
    if (data.id) {
      setCurrentQuestion({
        ...data,
        type: QuestionType[data.type],
      });

      await requestFindAttachmentQuestions.mutate({ idQuestion: data.id });
    }

    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setCurrentQuestion(undefined);
    setQuestionAttachments([]);
    setOpenFormDialog(false);
  };

  const requestCreateQuestionAttachment = useCreateQuestionAttachmentRequest({
    onError: () => {
      toast.error('Houve um erro ao salvar o anexo');
    },
  });

  const requestUpdateQuestionAttachment = useUpdateQuestionAttachmentRequest({
    onError: () => {
      toast.error('Houve um erro ao salvar a edição do anexo');
    },
  });

  const requestCreateQuestion = useCreateQuestionRequest({
    onSuccess: data => {
      toast.success('Pergunta criada com sucesso');
      queryClient.invalidateQueries('questions');
      questionAttachment.map(att => {
        requestCreateQuestionAttachment.mutate({
          question: data.id,
          file: att.file.id,
        });
      });

      handleCloseFormDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar criar a Pergunta');
    },
  });

  const requestUpdateQuestion = useUpdateQuestionRequest({
    onSuccess: data => {
      toast.success('Pergunta alterada com sucesso');
      queryClient.invalidateQueries('questions');
      handleCloseFormDialog();

      const updateAttachments: IQuestionAttachmentPayload[] = [];
      questionAttachment.map(att => {
        updateAttachments.push({
          question: data.id,
          file: att.file.id,
        });
      });
      requestUpdateQuestionAttachment.mutate({
        id: data.id,
        attachment: updateAttachments,
      });
    },
    onError: () => {
      toast.error('Houve um erro ao tentar alterar a Pergunta');
    },
  });

  const requestFindAttachmentQuestions = useFindQuestionAttachmentRequest({
    onSuccess: data => {
      setQuestionAttachments(data);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar buscar os anexos da pergunta');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const variable = variables.find(variable => variable.label === data.title);

    const completeQuestion: IQuestionPayload = {
      ...dataQuestion,
      type: QuestionTypeSendApi[dataQuestion.type],
      systemValueMapping: variable?.value ?? null,
    };

    if (currentQuestion?.id) {
      requestUpdateQuestion.mutate({
        id: currentQuestion.id,
        question: completeQuestion,
      });
      handleCloseFormDialog();
      return;
    }

    requestCreateQuestion.mutate(completeQuestion);
  });

  return {
    openFormDialog,
    variables,
    handleOpenFormDialog,
    handleCloseFormDialog,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseQuestionDialog = ReturnType<typeof useQuestionDialog>;
