import api from 'config/api';

import {
  IEmailActPayload,
  IEmailDeadlineActPayload,
  IEmailDefinePasswordPayload,
  IEmailDefinePasswordResponse,
  ISendEmailValidationCodePayload,
  ISendEmailValidationCodeResponse,
  IValidateCodePayload,
  IValidateCodeResponse,
} from './types';

export class EmailService {
  static async sendEmailValidationCode(payload: ISendEmailValidationCodePayload) {
    const { data } = await api.post<ISendEmailValidationCodeResponse>(
      '/email/send-validation-code',
      payload,
    );
    return data;
  }

  static async validateCode(payload: IValidateCodePayload) {
    const { data } = await api.post<IValidateCodeResponse>(
      '/email/validate-code',
      payload,
    );
    return data;
  }

  static async definePassword(payload: IEmailDefinePasswordPayload) {
    const { data } = await api.post<IEmailDefinePasswordResponse>(
      '/email/define-password',
      payload,
    );
    return data;
  }

  static async deadlineAct(payload: IEmailDeadlineActPayload) {
    const { data } = await api.post<boolean>('/email/deadline-act', payload);
    return data;
  }

  static async actEmail(payload: IEmailActPayload) {
    const { data } = await api.post<boolean>('/email/act-email', payload);
    return data;
  }
}
