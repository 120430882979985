import {
  Grid,
  Typography as MuiTypography,
  MenuItem,
  Link,
  InputBaseComponentProps,
} from '@mui/material';
import { ElementType } from 'react';

import {
  Attachments,
  Box,
  ButtonOutlined,
  ButtonContained,
  Dialog,
  NoAttachment,
  TextField,
  Select,
  CurrencyField,
  TextMask,
  SectionButtons,
} from 'components';
import { Dropzone } from 'components/Upload/Dialog';
import {
  useSearchArrayDebounce,
  useSearchDebounce,
  useMaskInputs,
  useDownloadFile,
} from 'hooks';
import { FormStatus, IFileStorage } from 'models';

import { ActStatusPayload } from 'modules/backoffice/acts';
import ConfigActsAutocomplete from 'modules/backoffice/acts/components/ConfigAct/ConfigActsAutocomplete';

import { OptionsValues, OptionsValuesString } from 'utils/helper';

import { UseCreateActForm, useGetStatusAct, useTabAct } from '../../hooks';
import { ScreenCalled, TypeScreenFormProcessAct } from '../../service';
import { useTabActsStore } from '../../store/store';

export interface DialogFormProps {
  form: UseCreateActForm['form'];
  type: TypeScreenFormProcessAct;
  multipleEnterprises?: string[];
  typeScreenCalled: ScreenCalled;
  handleCloseDialog?: () => void;
}

export const CreateProcessAct = ({
  form,
  type,
  multipleEnterprises,
  typeScreenCalled,
  handleCloseDialog,
}: DialogFormProps) => {
  const { DATE_MASK } = useMaskInputs();

  const {
    control,
    formState: { isValid, errors },
    handleSubmitForm,
    handleEditForm,
    isLoadingCreate,
    isLoadingUpdating,
  } = form;

  const {
    openDropzone,
    screenCalled,
    setOpenModalOut,
    setOpenDropzone,
    setSearchFormId,
    setSearchDbInfo,
    setProductClient,
    setOperation,
    setCreateOpValues,
    setMotiveEnd,
  } = useTabActsStore();

  const { setScheduleLinkOpened, setChargeLinkOpened } = useGetStatusAct();

  const {
    act,
    attachmentsAct,
    attachmentsAnswer,
    formOptions,
    creditorsOptions,
    user,
    variables,
    USER_TYPES,
    PRODUCTS_CLIENT,
    OPERATIONS_TYPES,
    CLOSING_OPERATIONS_TYPES,
    variablesValues,
    openCreateActDialog,
    currentProcessAct,
    handleSaveAttachmentsAct,
    handleRemoveAttachmentAct,
    handleSaveAttachmentsAnswer,
    handleRemoveAttachmentAnswer,
    pushFormPage,
  } = useTabAct();

  const { handleChangeSearch: handleChangeSearchTriggerForm } = useSearchDebounce(
    setSearchFormId,
  );
  const { handleChangeSearch: handleChangeSearchDbInfo } = useSearchArrayDebounce(
    setSearchDbInfo,
  );
  const { handleChangeSearch: handleChangeProductClient } = useSearchArrayDebounce(
    setProductClient,
  );
  const { handleChangeSearch: handleChangeSearchOperation } = useSearchDebounce(
    setOperation,
  );
  const { handleChangeSearch: handleChangeCreateOPValues } = useSearchArrayDebounce(
    setCreateOpValues,
  );
  const { handleChangeSearch: handleChangeMotiveEnd } = useSearchDebounce(
    setMotiveEnd,
  );
  const renderAttachmentsList =
    act.configs?.allowAttach && attachmentsAct.length > 0 && type === 'edit';
  const renderAnswerInput = act.configs?.textInputAnswer && type === 'edit';

  const isClosed = currentProcessAct?.status === ActStatusPayload.Fechado;

  const handleDownload = (file: IFileStorage) => {
    const { downloadFile } = useDownloadFile(file);
    downloadFile();
  };

  const getDataForm = () => {
    const hasForm = act.configs?.formId && act.configs.formId?.length > 0;
    const renderButtonAnswerForm = type === 'edit';
    const formCompleted =
      currentProcessAct?.formRecord?.status === FormStatus.COMPLETED;

    return (
      <>
        {hasForm && (
          <>
            <MuiTypography
              fontSize={18}
            >{`Formulário - ${act.configs?.formId}`}</MuiTypography>
            {renderButtonAnswerForm && (
              <Box mt={2}>
                <ButtonContained
                  label={`${
                    formCompleted ? 'Visualizar Formulário' : 'Responder Formulário'
                  }`}
                  onClick={() => pushFormPage()}
                />
              </Box>
            )}
          </>
        )}
        {!hasForm && (
          <ConfigActsAutocomplete
            fullWidth
            control={control}
            name="formId"
            label={'Selecione formulário'}
            stringValue={true}
            options={formOptions}
            error={errors.formId}
            onInputChange={value => handleChangeSearchTriggerForm(value)}
            isOptionEqualToValue={(option, value) =>
              (option as OptionsValues).value === value
            }
          />
        )}
      </>
    );
  };

  const getDataInfo = () => {
    const hasInfo = act.configs?.info && act.configs.info?.length > 0;

    return (
      <>
        {hasInfo && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Informações para apresentar:
            </MuiTypography>
            <MuiTypography>{act.configs?.info}</MuiTypography>
          </>
        )}
        {!hasInfo && (
          <TextField
            control={control}
            name="info"
            label="Input de texto"
            multiline
            rows={4}
            fullWidth
            maxRows={Infinity}
            type={'text'}
            error={errors.info}
          />
        )}
      </>
    );
  };

  const getDbInfo = () => {
    const hasDbInfo = act.configs?.dbInfo && act.configs.dbInfo?.length > 0;

    const optionsVariable: OptionsValuesString[] = [];

    variablesValues.map(varValue => {
      varValue.value?.map(value => {
        let valueField = value;
        const isDate = varValue.type === 'Date';
        if (isDate) valueField = new Date(value).toLocaleDateString('pt-br');

        optionsVariable.push({
          label: varValue.name,
          value: valueField,
        });
      });
    });

    return (
      <>
        {hasDbInfo && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              {'Informações para apresentar (base de dados):'}
            </MuiTypography>
            {optionsVariable.map(varValue => (
              <MuiTypography key={`${varValue.label}-${varValue.value}`}>
                {`Coluna ${varValue.label}: ${varValue.value} `}
              </MuiTypography>
            ))}
          </>
        )}
        {!hasDbInfo && (
          <Box mt={6}>
            <Select
              name="clientTypeInfo"
              control={control}
              label="Tipo de cliente cujas informações serão apresentadas"
            >
              {USER_TYPES.map(userType => (
                <MenuItem key={userType.value} value={userType.label}>
                  {userType.label}
                </MenuItem>
              ))}
            </Select>
            <ConfigActsAutocomplete
              style={{ marginTop: 15 }}
              fullWidth
              control={control}
              name="dbInfo"
              label={'Informações para apresentar da base de dados'}
              multiple={true}
              stringValue={true}
              options={variables}
              error={errors.dbInfo}
              onInputChange={value => handleChangeSearchDbInfo(value)}
              isOptionEqualToValue={(option, value) =>
                (option as OptionsValues).value === value
              }
            />
          </Box>
        )}
      </>
    );
  };

  const getDataProductClient = () => {
    const hasProductClient =
      act.configs?.productClient && act.configs.productClient?.length > 0;

    return (
      <>
        {hasProductClient && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Produto definido para o cliente:
            </MuiTypography>
            {act.configs?.productClient?.map(pc => (
              <MuiTypography key={pc}>{pc}</MuiTypography>
            ))}
          </>
        )}
        {!hasProductClient && (
          <ConfigActsAutocomplete
            fullWidth
            control={control}
            name="productClient"
            label={'Selecione o produto'}
            multiple={true}
            stringValue={true}
            options={PRODUCTS_CLIENT}
            error={errors.productClient}
            onInputChange={value => handleChangeProductClient(value)}
            isOptionEqualToValue={(option, value) =>
              (option as OptionsValues).value === value
            }
          />
        )}
      </>
    );
  };

  const getDataCreateOpValues = () => {
    return (
      <>
        {type === 'edit' && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Instituições Financeiras Selecionadas:
            </MuiTypography>
            {act.configs?.createOpValues?.map((createOp, index) => (
              <MuiTypography key={index}>{createOp}</MuiTypography>
            ))}
          </>
        )}
        {type === 'create' && (
          <ConfigActsAutocomplete
            fullWidth
            control={control}
            name="createOpValues"
            label={'Selecionar instituições financeiras'}
            multiple={true}
            stringValue={true}
            options={creditorsOptions}
            error={errors.createOpValues}
            onInputChange={value => handleChangeCreateOPValues(value)}
            isOptionEqualToValue={(option, value) =>
              (option as OptionsValues).value === value
            }
          />
        )}
      </>
    );
  };

  const getDataOperation = () => {
    const hasOperation = act.configs?.operation && act.configs.operation.length > 0;

    return (
      <>
        {hasOperation && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Operação definida:
            </MuiTypography>
            <MuiTypography>{act.configs?.operation}</MuiTypography>
          </>
        )}
        {!hasOperation && (
          <ConfigActsAutocomplete
            fullWidth
            control={control}
            name="operation"
            label={'Selecione a operação'}
            stringValue={true}
            options={OPERATIONS_TYPES}
            error={errors.operation}
            onInputChange={value => handleChangeSearchOperation(value)}
            isOptionEqualToValue={(option, value) =>
              (option as OptionsValues).value === value
            }
          />
        )}
      </>
    );
  };

  const getDataOpValue = () => {
    const valNumber = act?.configs?.valueOp ?? '';
    const value = Number(valNumber);

    return (
      <>
        {type === 'edit' && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Valor da operação:
            </MuiTypography>

            <MuiTypography>
              {value?.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })}
            </MuiTypography>
          </>
        )}
        {type === 'create' && (
          <CurrencyField
            control={control}
            name="valueOp"
            label="Valor da operação"
            error={errors.valueOp}
          />
        )}
      </>
    );
  };

  const getDataScheduleLink = () => {
    const hansScheduleLink =
      act.configs?.scheduleLink && act.configs.scheduleLink.length > 0;

    return (
      <>
        {hansScheduleLink && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Link agenda:
            </MuiTypography>
            <MuiTypography>
              <Link
                href={act.configs?.scheduleLink ?? ''}
                target="_blank"
                underline="hover"
                onClick={() => setScheduleLinkOpened(true)}
              >
                {act.configs?.scheduleLink}
              </Link>
            </MuiTypography>
          </>
        )}
        {!hansScheduleLink && (
          <TextField
            control={control}
            name="scheduleLink"
            label="Link agenda"
            type="text"
          />
        )}
      </>
    );
  };

  const getDataDeadline = () => {
    const hasDeadline = act.configs?.deadline && act.configs.deadline.length > 0;

    return (
      <>
        {hasDeadline && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Prazo:
            </MuiTypography>
            <MuiTypography>{act.configs?.deadline}</MuiTypography>
          </>
        )}
        {!hasDeadline && (
          <TextField
            name="deadline"
            type="text"
            control={control}
            error={errors.deadline}
            label="Prazo"
            inputProps={{
              mask: DATE_MASK.DEFAULT,
            }}
            InputProps={{
              inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
            }}
            fullWidth
          />
        )}
      </>
    );
  };

  const getDataChargeLink = () => {
    return (
      <>
        {type === 'edit' && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Link cobrança:
            </MuiTypography>
            <MuiTypography>
              <Link
                href={act.configs?.chargeLink ?? ''}
                target="_blank"
                underline="hover"
                onClick={() => setChargeLinkOpened(true)}
              >
                {act.configs?.chargeLink ?? '---'}
              </Link>
            </MuiTypography>
          </>
        )}
        {type === 'create' && (
          <TextField
            control={control}
            name="chargeLink"
            label="Link cobrança"
            fullWidth
            type={'text'}
            error={errors.chargeLink}
          />
        )}
      </>
    );
  };

  const getDataEmail = () => {
    const hasEmail = act.configs?.emailTitle && act.configs.emailTitle.length > 0;

    return (
      <>
        {hasEmail && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Título Email (pré-definido):
            </MuiTypography>
            <MuiTypography>Exfin - {act.configs?.emailTitle}</MuiTypography>
            <MuiTypography fontSize={18} fontWeight={'bold'} mt={3}>
              Corpo Email (pré-definido):
            </MuiTypography>
            <MuiTypography color={'black'} fontStyle={'italic'}>
              {`Ola, ${user.user?.name} `}
            </MuiTypography>
            <MuiTypography fontStyle={'italic'} mt={2}>
              {act.configs?.emailBody}
            </MuiTypography>
            {emailBody()}
          </>
        )}
        {!hasEmail && (
          <>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Box width={'15%'} display={'flex'} alignItems={'center'}>
                <MuiTypography color={'black'}>ExFin -</MuiTypography>
              </Box>
              <TextField
                control={control}
                name="emailTitle"
                label="Título do email"
                type="text"
                error={errors.emailTitle}
              />
            </Box>
            <Box mt={5} mb={5}>
              <MuiTypography color={'black'} fontStyle={'italic'}>
                {`Ola, ${user.user?.name} `}
              </MuiTypography>
              <Box mt={4}>
                <TextField
                  control={control}
                  name="emailBody"
                  label="Corpo  do email"
                  multiline
                  rows={4}
                  fullWidth
                  maxRows={Infinity}
                  type={'text'}
                  error={errors.emailBody}
                />
              </Box>
              {emailBody()}
            </Box>
          </>
        )}
      </>
    );
  };

  const emailBody = () => {
    return (
      <MuiTypography color={'black'} fontStyle={'italic'}>
        <br />
        Fique atento ao seu email para possíveis contatos e solicitações realizados
        pela equipe ExFin.
        <br /> <br />
        Para acessar a plataforma, clique{' '}
        <a
          href={`${process.env.REACT_APP_URL}`}
          target="_blank"
          style={{ color: '#1f1d1c' }}
          rel="noreferrer"
        >
          aqui
        </a>
        .<br /> Se necessário, entre em contato com um de nossos consultores
        clicando{' '}
        <a href="#" target="_blank" style={{ color: '#1f1d1c' }}>
          aqui
        </a>
        . <br /> © ExFin
      </MuiTypography>
    );
  };

  const renderAttachments = (
    attachments: IFileStorage[],
    handleRemove: (id: string) => void,
  ) => {
    const atts = attachments;
    return (
      <>
        <ButtonContained
          color={'gray-700'}
          label="Anexar Arquivos"
          onClick={() => setOpenDropzone(true)}
        />
        {attachments?.length === 0 ? (
          <NoAttachment customColors={'white'} colorText={'gray-500'} />
        ) : (
          atts?.map(attachment => (
            <Attachments
              key={attachment.id}
              file={attachment}
              removeFile={() => handleRemove(attachment.id)}
            />
          ))
        )}
      </>
    );
  };

  const getDataMotiveEnd = () => {
    return (
      <>
        {type === 'edit' && (
          <>
            <MuiTypography fontSize={18} fontWeight={'bold'}>
              Motivo do Encerramento
            </MuiTypography>
            <MuiTypography>{act?.configs?.motiveEnd}</MuiTypography>
          </>
        )}
        {type === 'create' && (
          <ConfigActsAutocomplete
            fullWidth
            control={control}
            name="motiveEnd"
            label={'Selecione o motivo de encerramento'}
            stringValue={true}
            options={CLOSING_OPERATIONS_TYPES}
            error={errors.motiveEnd}
            onInputChange={value => handleChangeMotiveEnd(value)}
            isOptionEqualToValue={(option, value) =>
              (option as OptionsValues).value === value
            }
          />
        )}
      </>
    );
  };

  const checkScreenCalled = () => {
    if (!act || Object.keys(act).length === 0) return false;
    return typeScreenCalled === screenCalled;
  };

  return (
    <>
      {checkScreenCalled() && (
        <Dialog.Root
          open={openCreateActDialog}
          onClose={() => handleCloseDialog && handleCloseDialog()}
          backdropColor="rgba(0, 0, 0, 0.2)"
          blurLevel={1.5}
        >
          <Dialog.Header title={`Ato`} />

          <Dropzone
            open={openDropzone}
            onClose={() => setOpenDropzone(false)}
            onSave={
              type === 'edit'
                ? handleSaveAttachmentsAnswer
                : handleSaveAttachmentsAct
            }
          />

          <Box as="form" maxWidth={1100} minWidth={1070} overflow={'auto'}>
            <Grid
              container
              pt={4}
              pl={6}
              pr={6}
              spacing={2}
              mb={3}
              display={'flex'}
              justifyContent={'center'}
            >
              <Box
                as="form"
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-around'}
                width={'95%'}
                mt={4}
              >
                <Box>
                  <MuiTypography fontSize={20} fontWeight={'bold'}>
                    Nome
                  </MuiTypography>
                  <MuiTypography fontSize={16}>{act.name}</MuiTypography>
                </Box>

                <Box>
                  <MuiTypography fontSize={20} fontWeight={'bold'}>
                    Descrição
                  </MuiTypography>
                  <MuiTypography fontSize={16}>
                    {act?.description?.length > 0 ? act.description : '---'}
                  </MuiTypography>
                </Box>
              </Box>

              <Box mt={8} minWidth={600} maxWidth={600}>
                {act.configs?.triggerForm && getDataForm()}

                <Box mt={5}>{act.configs?.showInfo && getDataInfo()}</Box>
                <Box mt={5}>{act.configs?.showDbInfo && getDbInfo()}</Box>
                <Box mt={5}>
                  {act.configs?.defineProductClient && getDataProductClient()}
                </Box>
                <Box mt={6}>{act.configs?.createOp && getDataCreateOpValues()}</Box>
                <Box mt={5}>{act.configs?.defineOp && getDataOperation()}</Box>
                <Box mt={5}>{act.configs?.defineValueOp && getDataOpValue()}</Box>
                <Box mt={5}>
                  {act.configs?.showScheduleLink && getDataScheduleLink()}
                </Box>
                <Box mt={5}>{act.configs?.showDeadline && getDataDeadline()}</Box>
                <Box mt={6}>{act.configs?.allowCharge && getDataChargeLink()}</Box>
                <Box mt={6}>
                  {renderAttachmentsList && (
                    <>
                      <MuiTypography fontSize={20} fontWeight={'bold'}>
                        Anexos
                      </MuiTypography>
                      {attachmentsAct.map(att => (
                        <Box mt={2} key={att.id}>
                          <Link
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleDownload(att)}
                          >
                            {att.fileName}
                          </Link>
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
                <Box mt={6}>
                  <>
                    {!!act.configs?.allowAttach &&
                      type === 'create' &&
                      renderAttachments(attachmentsAct, handleRemoveAttachmentAct)}
                    {!!act.configs?.allowAnswerAttach &&
                      type === 'edit' &&
                      renderAttachments(
                        attachmentsAnswer,
                        handleRemoveAttachmentAnswer,
                      )}
                  </>
                </Box>
                <Box mt={6}>{act.configs?.sendEmail && getDataEmail()}</Box>
                <Box mt={6}>
                  {act.configs?.negotiationEnd && getDataMotiveEnd()}
                </Box>
                <Box mt={6}>
                  {renderAnswerInput && (
                    <TextField
                      control={control}
                      name="answer"
                      label="Resposta"
                      multiline
                      rows={4}
                      fullWidth
                      maxRows={Infinity}
                      type={'text'}
                      error={errors.answer}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
            <SectionButtons>
              <ButtonOutlined label="Sair" onClick={() => setOpenModalOut(true)} />
              <ButtonContained
                label="Salvar"
                onClick={() =>
                  type === 'edit' || currentProcessAct?.isChild
                    ? handleEditForm()
                    : handleSubmitForm(multipleEnterprises)
                }
                disabled={
                  isLoadingCreate || isLoadingUpdating || isClosed || !isValid
                }
                loading={isLoadingCreate || isLoadingUpdating}
              />
            </SectionButtons>
          </Box>
        </Dialog.Root>
      )}
    </>
  );
};
