import { Content } from './Content';
import { Footer } from './Footer';
import { Header } from './Header';
import { Root } from './Root';

export const Dialog = {
  Root,
  Header,
  Content,
  Footer,
};
