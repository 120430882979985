import { Box, CircularProgress } from '@mui/material';

import { ButtonContained, Dialog, SectionButtons, Typography } from 'components';

import { CreateProcessAct } from 'modules/boards/tabs';
import {
  UseCreateActForm,
  useTabAct,
} from 'modules/boards/tabs/components/tabActs/hooks';

import { ListPendingIssuesResponse } from '../services';
import { PendingIssueItem } from './PendingIssueItem';

export const CustomerPendingIssueDialog = ({
  pendingIssues,
  isLoading,
  openDialog,
  handleCloseDialog,
  form,
}: {
  pendingIssues: ListPendingIssuesResponse;
  isLoading: boolean;
  openDialog: boolean;
  handleCloseDialog: () => void;
  form: UseCreateActForm['form'];
}) => {
  const { typeScreenForm } = useTabAct();

  return (
    <>
      <CreateProcessAct
        form={form}
        type={typeScreenForm}
        typeScreenCalled="pending"
      />

      <Dialog.Root open={openDialog} onClose={handleCloseDialog}>
        <Dialog.Header title={'Pendências'} />

        <Dialog.Content>
          {isLoading ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress size={22} />
            </Box>
          ) : (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              {pendingIssues?.length ? (
                pendingIssues.map(pendingIssue => (
                  <PendingIssueItem
                    key={pendingIssue.id}
                    pendingIssue={pendingIssue}
                  />
                ))
              ) : (
                <Typography>Nenhuma pendência encontrada.</Typography>
              )}
            </Box>
          )}
        </Dialog.Content>

        <SectionButtons>
          <ButtonContained
            boxShadow="0 5px 5px rgba(0, 0, 0, 0.5)"
            color="blue"
            label="Fechar"
            onClick={handleCloseDialog}
          />
        </SectionButtons>
      </Dialog.Root>
    </>
  );
};
