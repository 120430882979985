import { Card, Table, TableCell } from '@mui/material';
import html2canvas from 'html2canvas';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import themes from 'themes';

import { Box, TableBody, TableContainer, TableRow, Typography } from 'components';
import { Indebtedness } from 'models';

import { useDashboardStore } from 'modules/dashboard/store/store';

import { capitalizeWords } from 'utils/capitalizeWords';
import { isEven } from 'utils/isEven';

export interface DataDebtsTableItem {
  until180dSum: number;
  between181and360dSum: number;
  greaterThan360dSum: number;
  totalSum: number;
  institutionName: string;
}

interface DebtsProps {
  data: Indebtedness[];
  isLoading: boolean;
  noData: boolean;
}

export const getDebitsTableItem = (data: Indebtedness[]) => {
  const dataItem: DataDebtsTableItem[] = [];

  data?.map(debt => {
    dataItem.push({
      until180dSum: debt.untilSixMonths,
      between181and360dSum: debt.betweenSixAndTwelveMonths,
      greaterThan360dSum: debt.moreThanTwelveMonths,
      totalSum: debt.totalCurrentDebts,
      institutionName: capitalizeWords(debt.institution),
    });
  });
  dataItem.sort((a, b) => b.totalSum - a.totalSum);

  return dataItem;
};

const DebtsTable = ({ data, isLoading, noData }: DebtsProps) => {
  const { setTablesImagesUrl } = useDashboardStore();
  const tableRef = useRef<HTMLDivElement>(null);

  const captureComponent = useCallback(async (ref, key) => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current);
      const imageData = canvas.toDataURL('image/png');

      setTablesImagesUrl(key, imageData, canvas.width, canvas.height);
    }
  }, []);

  useEffect(() => {
    const captureTable = async () => {
      await captureComponent(tableRef, 'debts');
    };

    if (tableRef) {
      captureTable();
    }
  }, []);

  const totalCell = true;
  const isMoney = true;
  const emptyString = '---';
  const sizeRow = 40;
  const headerRow = 55;
  const heightWindow = window.innerHeight;
  const widthTable = heightWindow > 900 ? '97%' : '95.8%';

  const dataTable = getDebitsTableItem(data ?? []);

  const totalSums = dataTable.reduce(
    (acc, item) => {
      acc.totalUntil180dSum += item.until180dSum;
      acc.totalBetween181and360dSum += item.between181and360dSum;
      acc.totalGreaterThan360dSum += item.greaterThan360dSum;
      acc.totalSum += item.totalSum;
      return acc;
    },
    {
      totalUntil180dSum: 0,
      totalBetween181and360dSum: 0,
      totalGreaterThan360dSum: 0,
      totalSum: 0,
    },
  );

  const renderHead = (name: string, width: string) => {
    return (
      <TableCell
        align="center"
        style={{ padding: 0, borderRight: `1px solid ${themes.colors.white}` }} //border: `1px solid ${themes.colors.black}`
        sx={{
          height: headerRow,
          maxHeight: headerRow,
          maxWidth: width,
          width: width,
        }}
      >
        <Typography fontWeight={700} color={themes.colors.white}>
          {name}
        </Typography>
      </TableCell>
    );
  };

  const renderHeadJoined = (name: string) => {
    return (
      <Box
        display={'flex'}
        justifyContent={'center'}
        borderTop={`1px solid ${themes.colors.white}`}
        //borderLeft={isFirstCell ? null : `1px solid ${themes.colors.white}`}
        width={'50%'}
        padding={0}
      >
        <Typography
          color={themes.colors.white}
          fontWeight={700}
          style={{
            minWidth: '50%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const fieldMoney = (value: number, totalCell: boolean) => {
    return (
      <Typography
        fontWeight={totalCell ? 700 : 500}
        fontSize={15}
        color={themes.colors.dashboard.table.text.black}
      >
        {value === 0 || undefined
          ? '---'
          : value.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            })}
      </Typography>
    );
  };

  const renderTableCell = (
    value: string | number,
    isMoney: boolean,
    totalCell: boolean,
  ) => {
    return (
      <TableCell
        align="center"
        sx={{ height: sizeRow, maxHeight: sizeRow, padding: 0 }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          padding={0}
          //borderLeft={firstCell ? `1px solid ${themes.colors.black}` : null}
          //borderRight={`1px solid ${themes.colors.white}`}
          //borderBottom={`1px solid ${themes.colors.black}`}
          minHeight={sizeRow}
        >
          <Typography
            fontWeight={totalCell ? 700 : 500}
            color={themes.colors.dashboard.table.text.black}
          >
            {isMoney ? fieldMoney(Number(value), totalCell) : value}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  const renderTableCellJoined = (
    valueUntil180d: number,
    valueBetween180d360d: number,
    valueGreater360d: number,
    totalCell: boolean,
  ) => {
    return (
      <TableCell
        align="center"
        style={{
          //borderTop: `1px solid ${themes.colors.black}`,
          padding: 0,
        }}
        sx={{ height: sizeRow, maxHeight: sizeRow }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          //borderRight={`1px solid ${themes.colors.black}`}
          // borderBottom={`1px solid ${themes.colors.black}`}
        >
          <Box
            //borderRight={`1px solid ${themes.colors.black}`}
            width={'50%'}
            minHeight={sizeRow}
            alignContent={'center'}
          >
            {fieldMoney(valueUntil180d, totalCell)}
          </Box>
          <Box
            //borderRight={`1px solid ${themes.colors.black}`}
            width={'50%'}
            minHeight={sizeRow}
            alignContent={'center'}
          >
            {fieldMoney(valueBetween180d360d, totalCell)}
          </Box>
          <Box width={'50%'} minHeight={sizeRow} alignContent={'center'}>
            {fieldMoney(valueGreater360d, totalCell)}
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Card style={{ width: widthTable, padding: 25, margin: 0 }}>
      <Typography
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        color={themes.colors.dashboard.table.header.main}
      >
        Endividamento
      </Typography>
      <div ref={tableRef}>
        <TableContainer sx={{ padding: 0, margin: 0, mt: 2 }}>
          <Table>
            <TableRow
              hover={false}
              sx={{
                height: headerRow,
                maxHeight: headerRow,
                padding: 0,
                backgroundColor: themes.colors.dashboard.table.header.main,
              }}
            >
              {renderHead('Ordem da Dívida', '2%')}
              {renderHead('Instituição Financeira', '8%')}
              {renderHead('Saldo devedor Atual', '6%')}
              <TableCell
                align="center"
                style={{
                  width: '12%',
                  borderLeft: `1px solid ${themes.colors.white}`,
                }} // border: `1px solid ${themes.colors.black}`
                sx={{
                  height: headerRow,
                  maxHeight: headerRow,
                  padding: 0,
                  margin: 0,
                }}
              >
                <Typography color={themes.colors.white} fontWeight={700}>
                  Saldo devedor
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  width={'100%'}
                  padding={0}
                  justifyContent={'center'}
                  minHeight={28}
                >
                  {renderHeadJoined('VCTO até 6 meses')}
                  {renderHeadJoined('VCTO 6 meses a 12 meses')}
                  {renderHeadJoined('VCTO acima de 12 meses')}
                </Box>
              </TableCell>
            </TableRow>

            <TableBody
              columns={6}
              isLoading={isLoading}
              empty={noData}
              emptyMessage="Nenhuma informação do Endividamento"
            >
              {dataTable.map((debt, index) => (
                <Fragment key={`${debt.totalSum} - ${debt.between181and360dSum}`}>
                  <TableRow
                    hover={false}
                    sx={{
                      backgroundColor: isEven(index)
                        ? '#FFF'
                        : `${themes.colors.dashboard.table.body.tertiary}`,
                      height: sizeRow,
                      maxHeight: sizeRow,
                      padding: 0,
                    }}
                  >
                    {renderTableCell(`${index + 1}º`, !isMoney, !totalCell)}
                    {renderTableCell(
                      debt.institutionName ?? emptyString,
                      !isMoney,
                      !totalCell,
                    )}
                    {renderTableCell(debt.totalSum, isMoney, !totalCell)}
                    {renderTableCellJoined(
                      debt.until180dSum,
                      debt.between181and360dSum,
                      debt.greaterThan360dSum,
                      !totalCell,
                    )}
                  </TableRow>
                </Fragment>
              ))}
              <TableRow
                hover={false}
                sx={{
                  backgroundColor: isEven(dataTable.length)
                    ? '#FFF'
                    : `${themes.colors.dashboard.table.body.tertiary}`,
                }}
              >
                {renderTableCell('TOTAL', !isMoney, totalCell)}
                {renderTableCell(emptyString, !isMoney, totalCell)}
                {renderTableCell(totalSums.totalSum, isMoney, totalCell)}
                {renderTableCellJoined(
                  totalSums.totalUntil180dSum,
                  totalSums.totalBetween181and360dSum,
                  totalSums.totalGreaterThan360dSum,
                  totalCell,
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
};

export { DebtsTable };
