import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { variant } from 'styled-system';

import Typography, { Props as TypographyProps } from 'components/Typography';

type LinkTypographyProps = Omit<LinkProps, 'to'> &
  Omit<TypographyProps, 'color' | 'textAlign' | 'variant'>;

export interface Props extends LinkTypographyProps {
  to?: LinkProps['to'];
  onClick?: LinkProps['onClick'];
  className?: string;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

const TypographyAsLink = styled(Typography)<Props>``;

const Link = ({
  className,
  children,
  to,
  onClick,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
}: Props) => {
  if (to) {
    return (
      <TypographyAsLink
        as={RouterLink}
        className={className}
        to={to}
        onClick={onClick}
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
      >
        {children}
      </TypographyAsLink>
    );
  }

  return (
    <TypographyAsLink
      as="a"
      href="#"
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        if (onClick) {
          onClick(e);
        }
      }}
      className={className}
      fontFamily={fontFamily}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
    >
      {children}
    </TypographyAsLink>
  );
};

const StyledLink = styled(Link)`
  text-decoration: none;
  transition: color 400ms ease-in-out;
  ${({ to }) =>
    !to
      ? css`
          cursor: pointer;
        `
      : ''}

  ${props =>
    variant({
      variants: {
        primary: {
          color: props.theme.colors.text.primary,
          '&:hover': {
            color: props.theme.colors.text.primary,
          },
        },
      },
    })(props)}
`;

StyledLink.defaultProps = {
  variant: 'primary',
};

export default StyledLink;
