import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IQuestionAutocompleteInput } from '../services';

export const useQuestionAutocompleteForm = () => {
  const userSchema = z.object({
    title: z.string().optional(),
  });

  const defaultValues = {
    title: '' || null,
  };

  return useForm<IQuestionAutocompleteInput>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userSchema),
  });
};
