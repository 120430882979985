import { Box, Grid, Typography } from '@mui/material';

import { Dialog } from 'components';
import { ICompanyBigData, Type } from 'models';

import { BigDataAddress } from './BigDataAddress';
import { BigDataAffiliates } from './BigDataAffiliates';
import { BigDataAircrafts } from './BigDataAircrafts';
import { BigDataAnpFuelStation } from './BigDataAnpFuelStation';
import { BigDataCnae } from './BigDataCnae';
import { BigDataItem } from './BigDataItem';
import { BigDataJudicialProcess } from './BigDataJudicialProcess';
import BigDataPartners from './BigDataPartners';
import { BigDataPersonPartners } from './BigDataPersonPartners';
import { BigDataPhysicalProduction } from './BigDataPhysicalProduction';

interface Props {
  storage: ICompanyBigData;

  open: boolean;
  setOpen: (open: boolean) => void;
}

export const BigDataModal = ({ storage, open, setOpen }: Props) => {
  return (
    <Dialog.Root open={open} onClose={() => setOpen(false)}>
      <Dialog.Header title="Big Data" />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          gap={8}
          alignItems="center"
          paddingX={5}
        >
          <Grid container spacing={2}>
            <BigDataItem title="CNPJ" value={storage.cnpj} />
            <BigDataItem title="Razão Social" value={storage.socialReason} />
            <BigDataItem title="Nome Fantasia" value={storage.fantasyName} />
            <BigDataItem
              title="Código Jurídico da Empresa"
              value={storage.legalNatureCode}
            />
            <BigDataItem
              title="Descrição Jurídica da Empresa"
              value={storage.legalNatureDescription}
            />
            <BigDataItem
              title="Data de Abertura"
              value={new Date(storage.openingDate).toLocaleDateString('pt-BR')}
            />
            <BigDataItem
              title="Capital Social"
              value={storage.socialCapital?.toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
                minimumFractionDigits: 2,
              })}
            />
            <BigDataItem title="Email Financeiro" value={storage.rfEmail} />
            <BigDataItem
              title="Telefones de Contato"
              value={storage?.phones?.toString().replaceAll(',', ', ')}
            />

            <BigDataItem
              title="Nível de Atividade"
              value={storage.bigData.company.activityLevel}
            />

            <BigDataItem
              title="Idade da Empresa"
              value={storage.bigData.company.age?.toString()}
            />

            <BigDataItem
              title="Data da Última Consulta ao BNDES"
              value={
                storage.bigData.company.bndesLastConsultDate &&
                new Date(
                  storage.bigData.company.bndesLastConsultDate,
                ).toLocaleDateString('pt-BR')
              }
            />
            <BigDataItem
              title="Propensão de fechamento da empresa"
              value={storage.bigData.company.companyClosingPropensity}
            />
            <BigDataItem
              title="Tamanho da Empresa"
              value={storage.bigData.company.companySize}
            />
            <BigDataItem
              title="Situação individual COVID-19"
              value={storage.bigData.company.covid19Individual}
            />
            <BigDataItem
              title="Segmento COVID-19"
              value={storage.bigData.company.covid19Segment}
            />
            <BigDataItem
              title="Data de criação da empresa"
              value={new Date(storage.bigData.company.created).toLocaleDateString(
                'pt-BR',
              )}
            />

            <BigDataItem
              title="Data de situação de registro"
              value={new Date(
                storage.bigData.company.dateRegistrationSituation,
              ).toLocaleDateString('pt-BR')}
            />

            <BigDataItem
              title="Situação especial da empresa"
              value={storage.bigData.company.specialSituation}
            />

            <BigDataItem
              title="Data de situação especial"
              value={
                storage.bigData.company.dateSpecialSituation &&
                new Date(
                  storage.bigData.company.dateSpecialSituation,
                ).toLocaleDateString('pt-BR')
              }
            />

            <BigDataItem
              title="Propensão de entrega"
              value={storage.bigData.company.deliveryPropensity}
            />

            <BigDataItem
              title="Propensão de ecommerce"
              value={storage.bigData.company.ecommercePropensity?.toString()}
            />
            <BigDataItem
              title="Número de analistas"
              value={storage.bigData.company.employeeAnalystCount?.toString()}
            />
            <BigDataItem
              title="Número de colaboradores de base"
              value={storage.bigData.company.employeeBaseCount?.toString()}
            />
            <BigDataItem
              title="Número de compradores"
              value={storage.bigData.company.employeeBuyerCount?.toString()}
            />
            <BigDataItem
              title="Número de médicos"
              value={storage.bigData.company.employeeDoctorCount?.toString()}
            />
            <BigDataItem
              title="Número de engenheiros"
              value={storage.bigData.company.employeeEngineerCount?.toString()}
            />
            <BigDataItem
              title="Número de advogados"
              value={storage.bigData.company.employeeLawyerCount?.toString()}
            />
            <BigDataItem
              title="Número de gerentes"
              value={storage.bigData.company.employeeManagerCount?.toString()}
            />
            <BigDataItem
              title="Outras contagens"
              value={storage.bigData.company.employeeOtherCount?.toString()}
            />
            <BigDataItem
              title="Número de PDVs"
              value={storage.bigData.company.employeePdvCount?.toString()}
            />
            <BigDataItem
              title="Número de vendedores"
              value={storage.bigData.company.employeeSellerCount?.toString()}
            />
            <BigDataItem
              title="Número de supervisores"
              value={storage.bigData.company.employeeSupervisorCount?.toString()}
            />
            <BigDataItem
              title="Número de professores"
              value={storage.bigData.company.employeeTeacherCount?.toString()}
            />

            <BigDataItem
              title="Valor estimado de faturamento"
              value={storage.bigData.company.estimatedBilling}
            />
            <BigDataItem
              title="Grupo de faturamento estimado"
              value={storage.bigData.company.estimatedBillingGroup}
            />

            <BigDataItem
              title="Nome da franquia"
              value={storage.bigData.company.franchiseName}
            />

            <BigDataItem
              title="Faturamento Bruto da empresa"
              value={storage.bigData.company.grossBilling?.toLocaleString('pt-BR', {
                currency: 'BRL',
                style: 'currency',
                minimumFractionDigits: 2,
              })}
            />

            <BigDataItem
              title="Grupo Multinacional"
              value={storage.bigData.company.groupMultinational ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Contato de Contador Disponível"
              value={storage.bigData.company.hasAccountantContact ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Quadro de Sócios e Administradores Divergente"
              value={storage.bigData.company.hasDivergentQSA ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Indicativo de Franquia"
              value={storage.bigData.company.hasFranchiseIndicative ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Data da Última Consulta nos Dados do IBGE"
              value={new Date(
                storage.bigData.company.ibgeLastConsultDate,
              ).toLocaleDateString('pt-BR')}
            />

            <BigDataItem
              title="Data e hora da última atualização dos dados da empresa"
              value={new Date(
                storage.bigData.company.lastUpdate,
              ).toLocaleDateString('pt-BR')}
            />

            <BigDataItem
              title="Empresa Matriz"
              value={storage.bigData.company.matrix ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Empresa Multinacional"
              value={storage.bigData.company.multinational ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="País de Origem"
              value={storage.bigData.company.originCountry}
            />

            <BigDataItem
              title="Passivel de IISS"
              value={storage.bigData.company.passiveIISS ? 'Sim' : 'Não'}
            />

            <BigDataItem
              title="Quantidade de filiais ativas"
              value={storage.bigData.company.quantityActiveBranches?.toString()}
            />
            <BigDataItem
              title="Quantidade de Colaboradores"
              value={storage.bigData.company.quantityEmployee?.toString()}
            />
            <BigDataItem
              title="Quantidade de filiais ativas"
              value={storage.bigData.company.quantityExEmployee?.toString()}
            />

            <BigDataItem
              title="Motivo da situação de registro da empresa"
              value={storage.bigData.company.registrationSituationReason}
            />
            <BigDataItem
              title="Capacidade de trabalho remoto"
              value={storage.bigData.company.remoteWorkingCapacity}
            />

            <BigDataItem
              title="Data e hora da última consulta ao SIDRA"
              value={storage.bigData.company.sidraLastConsultDate}
            />
          </Grid>

          <BigDataAddress address={storage.address} />

          <BigDataCnae cnae={storage.mainCnae} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Outros CNAEs da Empresa
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {storage.cnaes.map(cnae => (
                <Grid item xs={12} lg={6} key={cnae.id}>
                  <BigDataItem title="Código" value={cnae.code} />
                  <BigDataItem title="Descrição" value={cnae.description} />
                  <BigDataItem
                    title="Atividade Principal"
                    value={cnae.businessActivity}
                  />
                  <BigDataItem title="Setor" value={cnae.sector} />
                  <BigDataPhysicalProduction
                    physicalProductions={cnae.physicalProductions}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <BigDataJudicialProcess judicialProcess={storage.judicialProcess} />

          <BigDataPartners partners={storage.partners} />

          <BigDataPersonPartners personPartners={storage.personPartners} />

          <BigDataAffiliates affiliates={storage.bigData.company.affiliates} />

          <BigDataAircrafts aircrafts={storage.bigData.company.aircraft} />

          <BigDataAnpFuelStation
            anpFuelStation={storage.bigData.company.anpFuelStation}
          />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Pontos de Abastecimento
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {storage.bigData.company.anpSupplyPoints?.map(
                (supplyPoint, index) => (
                  <Grid item xs={12} lg={6} key={index}>
                    <BigDataItem
                      title="Município"
                      value={supplyPoint.municipality}
                    />
                    <BigDataItem
                      title="Capacidade total dos tanques"
                      value={supplyPoint.totalCapacityTanks?.toString()}
                    />
                    <BigDataItem
                      title="Numero total de tanques"
                      value={supplyPoint.totalCapacityTanks?.toString()}
                    />
                    <BigDataItem title="UF" value={supplyPoint.uf} />
                  </Grid>
                ),
              )}
            </Grid>
          </Grid>

          {storage.bigData.company.antt && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  ANTT
                </Typography>
              </Grid>

              <BigDataItem
                title="Categoria"
                value={storage.bigData.company.antt.category}
              />
              <BigDataItem
                title="Data de emissão"
                value={storage.bigData.company.antt.emissionDate}
              />
              <BigDataItem
                title="Data de expiração"
                value={storage.bigData.company.antt.expirationDate}
              />
              <BigDataItem
                title="Município"
                value={storage.bigData.company.antt.municipality}
              />
              <BigDataItem
                title="Número RNTRC"
                value={storage.bigData.company.antt.rntrcNumber}
              />
              <BigDataItem
                title="Situação"
                value={storage.bigData.company.antt.situation}
              />
              <BigDataItem title="UF" value={storage.bigData.company.antt.uf} />
            </Grid>
          )}

          {storage.bigData.company.anvisa && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  ANVISA
                </Typography>
              </Grid>

              <BigDataItem
                title="Representantes Legais"
                value={storage.bigData.company.anvisa.legalRepresentatives
                  ?.toString()
                  .replaceAll(',', ', ')}
              />

              <Grid container spacing={2}>
                {storage.bigData.company.anvisa.processes?.map((process, index) => (
                  <Grid item xs={12} lg={6} key={index}>
                    <BigDataItem
                      title="Data de Autorização"
                      value={
                        process.authorizationDate &&
                        new Date(process.authorizationDate).toLocaleDateString(
                          'pt-BR',
                        )
                      }
                    />
                    <BigDataItem
                      title="Processo de Autorização"
                      value={process.authorizationProcess}
                    />
                    <BigDataItem
                      title="Descrição do Processo"
                      value={process.description}
                    />
                    <BigDataItem
                      title="Número do Processo"
                      value={process.processNumber}
                    />
                  </Grid>
                ))}
              </Grid>

              <BigDataItem
                title="Representantes Técnicos"
                value={storage.bigData.company.anvisa.technicalRepresentatives
                  ?.toString()
                  .replaceAll(',', ', ')}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Operações Bancárias
              </Typography>
            </Grid>

            {storage.bigData.company.bankOperations.map((bankOperation, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem
                  title="Período de Amortização"
                  value={bankOperation.amortizationPeriod?.toString()}
                />
                <BigDataItem
                  title="Data de Contratação"
                  value={
                    bankOperation.contractedDate &&
                    new Date(bankOperation.contractedDate).toLocaleDateString(
                      'pt-BR',
                    )
                  }
                />
                <BigDataItem
                  title="Valor Contratado"
                  value={bankOperation.contractedValue?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
                <BigDataItem
                  title="Agente Financeiro"
                  value={bankOperation.financialAgent}
                />
                <BigDataItem
                  title="Custo Financeiro"
                  value={bankOperation.financialCost}
                />
                <BigDataItem
                  title="Período de Carência"
                  value={bankOperation.gracePeriod?.toString()}
                />
                <BigDataItem
                  title="Produto Financeiro"
                  value={bankOperation.product}
                />
                <BigDataItem
                  title="Status da Operação"
                  value={bankOperation.status}
                />
                <BigDataItem
                  title="Taxa de Juros"
                  value={bankOperation.tax?.toString()}
                />
                <BigDataItem title="Tipo de operação" value={bankOperation.type} />
                <BigDataItem title="UF" value={bankOperation.uf} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Beneficiários
              </Typography>
            </Grid>
            {storage.bigData.company.beneficiaries?.map((beneficiary, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Documento" value={beneficiary.document} />

                <BigDataItem title="Nome" value={beneficiary.name} />

                <BigDataItem title="Nota" value={beneficiary.grade?.toString()} />

                <BigDataItem
                  title="Nota QSA"
                  value={beneficiary.gradeQsa?.toString()}
                />

                <BigDataItem
                  title="Participação"
                  value={beneficiary.participation?.toString()}
                />

                <BigDataItem
                  title="Participação QSA"
                  value={beneficiary.participationQsa?.toString()}
                />

                <BigDataItem
                  title="Falecido"
                  value={beneficiary.dead ? 'Sim' : 'Não'}
                />
              </Grid>
            ))}
          </Grid>

          {storage.bigData.company.cadastur && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  CADASTUR
                </Typography>
              </Grid>

              <BigDataItem
                title="Número do Certificado"
                value={storage.bigData.company.cadastur.certificateNumber}
              />
              <BigDataItem
                title="Lista de idiomas"
                value={storage.bigData.company.cadastur.idioms
                  ?.toString()
                  .replaceAll(',', ', ')}
              />
              <BigDataItem
                title="Número de Veículos"
                value={storage.bigData.company.cadastur.numberOfVehicles?.toString()}
              />
              <BigDataItem
                title="Cadastrado"
                value={storage.bigData.company.cadastur.registered ? 'Sim' : 'Não'}
              />
              <BigDataItem
                title="Lista de Segmentos"
                value={storage.bigData.company.cadastur.segments
                  ?.toString()
                  .replaceAll(',', ', ')}
              />
              <BigDataItem
                title="Lista de Serviços"
                value={storage.bigData.company.cadastur.services
                  ?.toString()
                  .replaceAll(',', ', ')}
              />
              <BigDataItem
                title="Site do Estabelecimento"
                value={storage.bigData.company.cadastur.site}
              />
              <BigDataItem
                title="Situação do CADASTUR"
                value={storage.bigData.company.cadastur.situation}
              />
              <BigDataItem title="UF" value={storage.bigData.company.cadastur.uf} />
            </Grid>
          )}

          {storage.bigData.company.cafir && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  CAFIR
                </Typography>
              </Grid>

              {storage.bigData.company.cafir.propertiesRural?.map(property => (
                <Grid item xs={12} lg={6} key={property.nirf}>
                  <BigDataItem title="Área" value={property.area?.toString()} />
                  <BigDataItem title="Condomínio" value={property.condominium} />
                  <BigDataItem title="Município" value={property.municipality} />
                  <BigDataItem title="Nome" value={property.name} />
                  <BigDataItem title="Número NIRF" value={property.nirf} />
                </Grid>
              ))}
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CEIS
              </Typography>
            </Grid>

            {storage.bigData.company.ceis?.map(cei => (
              <Grid item xs={12} lg={6} key={cei.processNumber}>
                <BigDataItem
                  title="Data de Término da Sanção"
                  value={
                    cei.endSanctionDate &&
                    new Date(cei.endSanctionDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Data de Informação"
                  value={
                    cei.informationDate &&
                    new Date(cei.informationDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Entidade Informante"
                  value={cei.informationEntity}
                />

                <BigDataItem
                  title="Data de Início da Sanção"
                  value={
                    cei.initSanctionDate &&
                    new Date(cei.initSanctionDate).toLocaleDateString('pt-BR')
                  }
                />

                <BigDataItem
                  title="Fundamentação Legal"
                  value={cei.legalSubstantiation}
                />
                <BigDataItem
                  title="Órgão Complementar"
                  value={cei.organComplement}
                />
                <BigDataItem title="Número do Processo" value={cei.processNumber} />
                <BigDataItem title="Sanção" value={cei.sanction} />
                <BigDataItem
                  title="Entidade Sancionadora"
                  value={cei.sanctioningEntity}
                />
                <BigDataItem
                  title="UF da Entidade Sancionadora"
                  value={cei.ufSanctioningEntity}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CEPIMS
              </Typography>
            </Grid>
            {storage.bigData.company.cepims?.map(cepim => (
              <Grid item xs={12} lg={6} key={cepim.contract}>
                <BigDataItem title="Número Contrato" value={cepim.contract} />
                <BigDataItem
                  title="Data de término do contrato"
                  value={
                    cepim.endContractDate &&
                    new Date(cepim.endContractDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Nome da entidade concedente"
                  value={cepim.grantorEntity}
                />
                <BigDataItem title="Tipo de impedimento" value={cepim.impediment} />
                <BigDataItem
                  title="Valor Liberado"
                  value={cepim.valueReleased?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CFCs
              </Typography>
            </Grid>

            {storage.bigData.company.cfcs?.map(cfc => (
              <Grid item xs={12} lg={6} key={cfc.record}>
                <BigDataItem title="CRC" value={cfc.crc} />
                <BigDataItem title="Número Registro" value={cfc.record} />
                <BigDataItem title="Situação" value={cfc.situation} />
                <BigDataItem title="Tipo" value={cfc.type} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CNEPs
              </Typography>
            </Grid>
            {storage.bigData.company.cneps?.map(cnep => (
              <Grid item xs={12} lg={6} key={cnep.processNumber}>
                <BigDataItem
                  title="Data de término da sanção"
                  value={
                    cnep.endSanctionDate &&
                    new Date(cnep.endSanctionDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Data de início da sanção"
                  value={
                    cnep.initSanctionDate &&
                    new Date(cnep.initSanctionDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Valor da Penalidade"
                  value={cnep.penaltyValue?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
                <BigDataItem
                  title="Número do Processo"
                  value={cnep.processNumber}
                />
                <BigDataItem title="Sanção" value={cnep.sanction} />
                <BigDataItem
                  title="Entidade Sancionadora"
                  value={cnep.sanctioningEntity}
                />
                <BigDataItem
                  title="UF da Entidade Sancionadora"
                  value={cnep.ufSanctioningEntity}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CNJ-CNIAS
              </Typography>
            </Grid>
            {storage.bigData.company.cnjCnias?.map(cnjCnias => (
              <Grid item xs={12} lg={6} key={cnjCnias.processNumber}>
                <BigDataItem
                  title="Descrição da Entidade"
                  value={cnjCnias.descriptionEntity}
                />
                <BigDataItem
                  title="Número do processo"
                  value={cnjCnias.processNumber}
                />
                <BigDataItem
                  title="Data de registro"
                  value={
                    cnjCnias.registrationDate &&
                    new Date(cnjCnias.registrationDate).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem
                  title="Problemas relacionados"
                  value={cnjCnias.relatedIssues?.toString().replaceAll(',', ', ')}
                />
                <BigDataItem title="Esfera da Entidade" value={cnjCnias.sphere} />
                <BigDataItem title="UF" value={cnjCnias.uf} />
                <BigDataItem
                  title="Valor relacionado"
                  value={cnjCnias.value?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Empresas Relacionadas
              </Typography>
            </Grid>

            {storage.bigData.company.companiesRelated?.map(company => (
              <Grid item xs={12} lg={6} key={company.cnpj}>
                <BigDataItem title="CNPJ" value={company.cnpj} />
                <BigDataItem
                  title="Descrição do CNAE"
                  value={company.descriptionCnae}
                />
                <BigDataItem title="Município" value={company.municipality} />
                <BigDataItem
                  title="Data de Abertura"
                  value={new Date(company.openingDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem title="Razão Social" value={company.socialReason} />
                <BigDataItem title="UF" value={company.uf} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Cursos EMec
              </Typography>
            </Grid>
            {storage.bigData.company.companyEmecCourses?.map(course => (
              <Grid item xs={12} lg={6} key={course.codeLes}>
                <BigDataItem
                  title="Organização Acadêmica"
                  value={course.academicOrganization}
                />
                <BigDataItem
                  title="Categoria Administrativa"
                  value={course.administrativeCategory}
                />
                <BigDataItem title="Código LES" value={course.codeLes} />
                <BigDataItem title="Descrição LES" value={course.descriptionLes} />
                <BigDataItem title="Município" value={course.municipality} />
                <BigDataItem title="Situação" value={course.situation} />
                <BigDataItem title="UF" value={course.uf} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Debêntures CRI/CRA
              </Typography>
            </Grid>

            {storage.bigData.company.criCraDebentures?.map(debenture => (
              <Grid item xs={12} lg={6} key={debenture.id}>
                <BigDataItem title="Código" value={debenture.code} />
                <BigDataItem
                  title="Devedor/Emissor"
                  value={debenture.debtorIssuer}
                />
                <BigDataItem
                  title="Data de Vencimento"
                  value={new Date(debenture.dueDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem
                  title="Valor Indicativo"
                  value={debenture.indicativeValue?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
                <BigDataItem
                  title="Setor de Seguro"
                  value={debenture.insuranceSector}
                />
                <BigDataItem
                  title="Data de Emissão"
                  value={new Date(debenture.issueDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem
                  title="PU Par"
                  value={debenture.puParDebenture?.toString()}
                />
                <BigDataItem title="Remuneração" value={debenture.remuneration} />
                <BigDataItem
                  title="Série de Emissão"
                  value={debenture.seriesIssue}
                />
                <BigDataItem
                  title="Volume da Série na Data de Emissão"
                  value={debenture.seriesVolumeOnIssueDate.toString()}
                />
                <BigDataItem title="Tipo" value={debenture.type} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                CR/SEF
              </Typography>
            </Grid>

            {storage.bigData.company.crsfns?.map(crsfns => (
              <Grid item xs={12} lg={6} key={crsfns.processNumber}>
                <BigDataItem title="Número Acordado" value={crsfns.agreedNumber} />
                <BigDataItem title="Parte Envolvida" value={crsfns.part} />
                <BigDataItem
                  title="Número do Processo"
                  value={crsfns.processNumber}
                />
                <BigDataItem
                  title="Número do Recurso"
                  value={crsfns.resourceNumber}
                />
                <BigDataItem title="Tipo de Recurso" value={crsfns.resourceType} />
              </Grid>
            ))}
          </Grid>

          {storage.bigData.company.cvm && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  CVM
                </Typography>
              </Grid>

              <BigDataItem
                title="Ano de Balanço"
                value={storage.bigData.company.cvm.balanceYear?.toString()}
              />
              <BigDataItem
                title="Lucro Bruto"
                value={storage.bigData.company.cvm.grossProfit?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
              <BigDataItem
                title="Receita Bruta"
                value={storage.bigData.company.cvm.grossRevenue?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
              <BigDataItem
                title="Ativos Líquidos"
                value={storage.bigData.company.cvm.liquidAssets?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
              <BigDataItem
                title="Faturamento Líquido"
                value={storage.bigData.company.cvm.liquidBilling?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
              <BigDataItem
                title="Lucro Líquido"
                value={storage.bigData.company.cvm.liquidProfit?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
              <BigDataItem
                title="Ativos Totais"
                value={storage.bigData.company.cvm.totalActive?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Dados de Registro na CVM
              </Typography>
            </Grid>

            {storage.bigData.company.cvmRegistrationData?.map(
              cvmRegistrationData => (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={
                    cvmRegistrationData.code + cvmRegistrationData.registrationDate
                  }
                >
                  <BigDataItem
                    title="Setor de Atividade"
                    value={cvmRegistrationData.activitySector}
                  />
                  <BigDataItem
                    title="Código da Empresa"
                    value={cvmRegistrationData.code}
                  />
                  <BigDataItem
                    title="Ativos Líquidos"
                    value={cvmRegistrationData.liquidAssets?.toLocaleString(
                      'pt-BR',
                      {
                        currency: 'BRL',
                        style: 'currency',
                        minimumFractionDigits: 2,
                      },
                    )}
                  />
                  <BigDataItem
                    title="Data de Registro"
                    value={new Date(
                      cvmRegistrationData.registrationDate,
                    ).toLocaleDateString('pt-BR')}
                  />
                  <BigDataItem
                    title="Tipo de Registro"
                    value={cvmRegistrationData.registrationType}
                  />
                  <BigDataItem
                    title="Nome do Responsável"
                    value={cvmRegistrationData.responsibleName}
                  />
                  <BigDataItem
                    title="Tipo de Responsável"
                    value={cvmRegistrationData.responsibleType}
                  />
                  <BigDataItem
                    title="Data da Situação"
                    value={new Date(
                      cvmRegistrationData.situationDate,
                    ).toLocaleDateString('pt-BR')}
                  />
                  <BigDataItem
                    title="Registro da Situação"
                    value={cvmRegistrationData.situationRecord}
                  />
                </Grid>
              ),
            )}
          </Grid>

          {storage.bigData.company.debitMte && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Débitos MTE
                </Typography>
              </Grid>

              <BigDataItem
                title="Tipo de certificado"
                value={storage.bigData.company.debitMte.certificateType}
              />
              <BigDataItem
                title="Código da empresa"
                value={storage.bigData.company.debitMte.code}
              />
              <BigDataItem
                title="Situação do débito"
                value={storage.bigData.company.debitMte.debitSituation}
              />
              <BigDataItem
                title="Data de emissão"
                value={new Date(
                  storage.bigData.company.debitMte.emissionDate,
                ).toLocaleDateString('pt-BR')}
              />

              {storage.bigData.company.debitMte.processes.map(process => (
                <Grid item xs={12} lg={6} key={process.number}>
                  <BigDataItem
                    title="Capitulação da Infração no Processo"
                    value={process.infringementCapitulation}
                  />
                  <BigDataItem
                    title="Categoria da Infração no Processo"
                    value={process.infringementCategory}
                  />
                  <BigDataItem title="Número do Processo" value={process.number} />
                  <BigDataItem
                    title="Situação do processo"
                    value={process.situation}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          {storage.bigData.company.debitPgfnDau && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Débitos PGFN/DAU
                </Typography>
              </Grid>

              {storage.bigData.company.debitPgfnDau.debitPgfns.map(debt => (
                <Grid item xs={12} lg={6} key={debt.inscriptionNumber}>
                  <BigDataItem title="Natureza do Débito" value={debt.nature} />
                  <BigDataItem
                    title="Número de Inscrição"
                    value={debt.inscriptionNumber}
                  />
                  <BigDataItem
                    title="Valor do Débito"
                    value={debt.debit?.toLocaleString('pt-BR', {
                      currency: 'BRL',
                      style: 'currency',
                      minimumFractionDigits: 2,
                    })}
                  />
                </Grid>
              ))}

              <BigDataItem
                title="Total de Débitos"
                value={storage.bigData.company.debitPgfnDau.totalDebits?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Documentos
              </Typography>
            </Grid>

            {storage.bigData.company.documents?.map(document => (
              <Grid item xs={12} lg={6} key={document.url}>
                <BigDataItem
                  title="Data de Criação"
                  value={
                    document.created &&
                    new Date(document.created).toLocaleDateString('pt-BR')
                  }
                />
                <BigDataItem title="A extensão" value={document.ext} />
                <BigDataItem title="Nome" value={document.name} />
                <BigDataItem title="Tipo" value={Type[document.type]} />
                <BigDataItem title="Url" value={document.url} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Domínios
              </Typography>
            </Grid>

            {storage.bigData.company.domains?.map((domain, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem
                  title="Data de Criação"
                  value={new Date(domain.createdDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem
                  title="Data de Expiração"
                  value={new Date(domain.expirationDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Data de Modificação"
                  value={new Date(domain.modificationDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem title="Nome" value={domain.name} />
                <BigDataItem title="Responsável" value={domain.responsible} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Crescimento de Colaboradores
              </Typography>
            </Grid>

            {storage.bigData.company.employeeGrowths?.map(
              (employeeGrowth, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <BigDataItem
                    title="Crescimento de Colaboradores"
                    value={employeeGrowth.employeeGrowth?.toString()}
                  />
                  <BigDataItem
                    title="Taxa de Crescimento de Colaboradores"
                    value={employeeGrowth.growth?.toString()}
                  />
                  <BigDataItem
                    title="Ano de Crescimento"
                    value={employeeGrowth.year?.toString()}
                  />
                </Grid>
              ),
            )}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Colaboradores
              </Typography>
            </Grid>

            {storage.bigData.company.employees?.map(employee => (
              <Grid item xs={12} lg={6} key={employee.cpf}>
                <BigDataItem
                  title="Data de Admissão"
                  value={new Date(employee.admissionDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Data de Nascimento"
                  value={new Date(employee.birthDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem title="CPF" value={employee.cpf} />
                <BigDataItem title="Nome" value={employee.name} />
                <BigDataItem
                  title="Ano de Desligamento"
                  value={employee.resignationYear?.toString()}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Licensas Ambientais
              </Typography>
            </Grid>

            {storage.bigData.company.environmentalLicenses?.map(
              environmentalLicense => (
                <Grid item xs={12} lg={6} key={environmentalLicense.processNumber}>
                  <BigDataItem
                    title="Descrição da tipologia da licença"
                    value={environmentalLicense.descriptionTypology}
                  />
                  <BigDataItem
                    title="Data de emissão"
                    value={new Date(
                      environmentalLicense.emitData,
                    ).toLocaleDateString('pt-BR')}
                  />
                  <BigDataItem
                    title="Município"
                    value={environmentalLicense.municipality}
                  />
                  <BigDataItem
                    title="Número do processo"
                    value={environmentalLicense.processNumber}
                  />
                  <BigDataItem
                    title="Situação"
                    value={environmentalLicense.situation}
                  />
                  <BigDataItem
                    title="Tipo de licença"
                    value={environmentalLicense.type}
                  />
                  <BigDataItem
                    title="Numero Tipologia da licença"
                    value={environmentalLicense.typologyNumber}
                  />
                  <BigDataItem title="UF" value={environmentalLicense.uf} />
                  <BigDataItem
                    title="Data de atualização"
                    value={new Date(
                      environmentalLicense.updateData,
                    ).toLocaleDateString('pt-BR')}
                  />
                </Grid>
              ),
            )}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Ex-Colaboradores
              </Typography>
            </Grid>

            {storage.bigData.company.exEmployees?.map(employee => (
              <Grid item xs={12} lg={6} key={employee.cpf}>
                <BigDataItem
                  title="Data de Admissão"
                  value={new Date(employee.admissionDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Data de Nascimento"
                  value={new Date(employee.birthDate).toLocaleDateString('pt-BR')}
                />
                <BigDataItem title="CPF" value={employee.cpf} />
                <BigDataItem title="Nome" value={employee.name} />
                <BigDataItem
                  title="Ano de Desligamento"
                  value={employee.resignationYear?.toString()}
                />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Exportações
              </Typography>
            </Grid>

            {storage.bigData.company.exports?.map((exportation, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Ano" value={exportation.year?.toString()} />
                <BigDataItem title="Valor" value={exportation.value} />
              </Grid>
            ))}
          </Grid>

          {storage.bigData.company.financialActivity && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Atividade Financeira
                </Typography>
              </Grid>

              <BigDataItem
                title="Data de Habilitação"
                value={new Date(
                  storage.bigData.company.financialActivity.enablementDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Número de Habilitação"
                value={storage.bigData.company.financialActivity.enablementNumber.toString()}
              />
              <BigDataItem
                title="Situação da Habilitação"
                value={
                  storage.bigData.company.financialActivity.enablementSituation
                }
              />
              <BigDataItem
                title="Data de Consulta"
                value={new Date(
                  storage.bigData.company.financialActivity.queryDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Segmento"
                value={storage.bigData.company.financialActivity.segment}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Indicadores Financeiros
              </Typography>
            </Grid>

            {storage.bigData.company.financialIndicators?.map(
              (financialIndicator, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <BigDataItem
                    title="Indicador de Aumento"
                    value={financialIndicator.increase?.toString()}
                  />
                  <BigDataItem
                    title="Margem"
                    value={financialIndicator.margin.toString()}
                  />
                </Grid>
              ),
            )}
          </Grid>

          {storage.bigData.company.foreignCommerce && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Comércio Exterior
                </Typography>
              </Grid>

              <BigDataItem
                title="Operações Autorizadas"
                value={storage.bigData.company.foreignCommerce.authorizedOperations}
              />
              <BigDataItem
                title="Comércio Exterior Habilitado"
                value={
                  storage.bigData.company.foreignCommerce.enabled ? 'Sim' : 'Não'
                }
              />
              <BigDataItem
                title="Situação de Comércio Exterior Habilitado"
                value={storage.bigData.company.foreignCommerce.enabledSituation}
              />
              <BigDataItem
                title="Modalidade"
                value={storage.bigData.company.foreignCommerce.modality}
              />
              <BigDataItem
                title="Data de Situação"
                value={new Date(
                  storage.bigData.company.foreignCommerce.situationDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Submodalidade"
                value={storage.bigData.company.foreignCommerce.submodality}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Estabelecimentos de Saúde
              </Typography>
            </Grid>

            {storage.bigData.company.healthEstablishments?.map(
              (healthEstablishment, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <BigDataItem
                    title="Data da última atualização"
                    value={new Date(
                      healthEstablishment.lastUpdate,
                    ).toLocaleDateString('pt-BR')}
                  />
                  <BigDataItem
                    title="Quantidade de leitos"
                    value={healthEstablishment.quantityBeds?.toString()}
                  />
                  <BigDataItem
                    title="Quantidade de profissionais"
                    value={healthEstablishment.quantityProfessionals?.toString()}
                  />
                  <BigDataItem
                    title="Tipo de unidade"
                    value={healthEstablishment.unitType}
                  />
                </Grid>
              ),
            )}
          </Grid>

          {storage.bigData.company.heavyVehicleInfo && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Informações de Veículos Pesados
                </Typography>
              </Grid>

              <BigDataItem
                title="Até 1 tonelada"
                value={storage.bigData.company.heavyVehicleInfo.upto1.toString()}
              />
              <BigDataItem
                title="Acima de 10 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.over10.toString()}
              />
              <BigDataItem
                title="Entre 2 e 5 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.between2And5.toString()}
              />
              <BigDataItem
                title="Entre 5 e 10 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.between5And10.toString()}
              />
              <BigDataItem
                title="Grupo de Veículos Até 1 tonelada"
                value={storage.bigData.company.heavyVehicleInfo.groupUpTo1.toString()}
              />
              <BigDataItem
                title="Grupo de Veículos Acima de 10 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.groupOver10.toString()}
              />
              <BigDataItem
                title="Grupo de Veículos Entre 2 e 5 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.groupBetween2And5.toString()}
              />
              <BigDataItem
                title="Grupo de Veículos Entre 5 e 10 toneladas"
                value={storage.bigData.company.heavyVehicleInfo.groupBetween5And10.toString()}
              />
              <BigDataItem
                title="Total de Veículos"
                value={storage.bigData.company.heavyVehicleInfo.heavyVehicles.toString()}
              />
              <BigDataItem
                title="Grupo de Número de Veículos Pesados"
                value={storage.bigData.company.heavyVehicleInfo.heavyVehiclesGroup.toString()}
              />

              {storage.bigData.company.heavyVehicleInfo.vehicles.map(vehicle => (
                <Grid item xs={12} lg={6} key={vehicle.renavam}>
                  <BigDataItem
                    title="Registro ANTT"
                    value={vehicle.antt ? 'Sim' : 'Não'}
                  />
                  <BigDataItem title="Placa" value={vehicle.carPlate} />
                  <BigDataItem title="Combustivel" value={vehicle.fuel} />
                  <BigDataItem title="Modelo" value={vehicle.model} />
                  <BigDataItem
                    title="Ano de Fabricação"
                    value={vehicle.productionYear.toString()}
                  />
                  <BigDataItem title="Renavam" value={vehicle.renavam} />
                  <BigDataItem title="Tipo" value={vehicle.type} />
                  <BigDataItem title="UF" value={vehicle.uf} />
                </Grid>
              ))}
            </Grid>
          )}

          {storage.bigData.company.ibamaCnd && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  IBAMA CND
                </Typography>
              </Grid>

              <BigDataItem
                title="Número do Certificado de Regularidade"
                value={storage.bigData.company.ibamaCnd.certificateNumber}
              />
              <BigDataItem
                title="Data de Emissão"
                value={new Date(
                  storage.bigData.company.ibamaCnd.emitDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Situação"
                value={storage.bigData.company.ibamaCnd.situation}
              />
              <BigDataItem
                title="Data de Validade"
                value={new Date(
                  storage.bigData.company.ibamaCnd.validDate,
                ).toLocaleDateString('pt-BR')}
              />
            </Grid>
          )}

          {storage.bigData.company.ibge && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  IBGE
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Estatísticas Econômicas
                  </Typography>
                </Grid>
                <BigDataItem
                  title="IDHM"
                  value={storage.bigData.company.ibge.economicStatistics?.idhm?.toString()}
                />
                <BigDataItem
                  title="Ano do IDHM"
                  value={storage.bigData.company.ibge.economicStatistics?.idhmYear?.toString()}
                />
                <BigDataItem
                  title="Percentual de Fontes de Receita"
                  value={storage.bigData.company.ibge.economicStatistics?.percentageRevenueSources?.toString()}
                />
                <BigDataItem
                  title="Ano do Percentual de Fontes de Receita"
                  value={storage.bigData.company.ibge.economicStatistics?.percentageRevenueSourcesYear?.toString()}
                />
                <BigDataItem
                  title="PIB"
                  value={storage.bigData.company.ibge.economicStatistics?.pib?.toString()}
                />
                <BigDataItem
                  title="Ano do PIB"
                  value={storage.bigData.company.ibge.economicStatistics?.pibYear?.toString()}
                />
                <BigDataItem
                  title="Total de Despedas"
                  value={storage.bigData.company.ibge.economicStatistics?.totalExpenses?.toString()}
                />
                <BigDataItem
                  title="Ano do Total de Despesas"
                  value={storage.bigData.company.ibge.economicStatistics?.totalExpensesYear?.toString()}
                />
                <BigDataItem
                  title="Total de Receitas"
                  value={storage.bigData.company.ibge.economicStatistics?.totalRevenue?.toString()}
                />
                <BigDataItem
                  title="Ano do Total de Receitas"
                  value={storage.bigData.company.ibge.economicStatistics?.totalRevenueYear?.toString()}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Estatísticas Geográficas
                  </Typography>
                </Grid>

                <BigDataItem
                  title="Densidade Demográfica"
                  value={storage.bigData.company.ibge.geographicStatistics?.demographicDensity?.toString()}
                />
                <BigDataItem
                  title="Ano da Densidade Demográfica"
                  value={storage.bigData.company.ibge.geographicStatistics?.demographicDensityYear?.toString()}
                />
                <BigDataItem
                  title="População Estimada"
                  value={storage.bigData.company.ibge.geographicStatistics?.estimatedPopulation?.toString()}
                />
                <BigDataItem
                  title="Ano da População Estimada"
                  value={storage.bigData.company.ibge.geographicStatistics?.estimatedPopulationYear?.toString()}
                />
                <BigDataItem
                  title="População Estimada no Último Censo"
                  value={storage.bigData.company.ibge.geographicStatistics?.estimatedPopulationLastCensus?.toString()}
                />
                <BigDataItem
                  title="Ano da População Estimada no Último Censo"
                  value={storage.bigData.company.ibge.geographicStatistics?.estimatedPopulationLastCensusYear?.toString()}
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Estatísticas de Perfomance de Trabalho
                  </Typography>
                </Grid>

                <BigDataItem
                  title="Salário Médio"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.averageSalary?.toString()}
                />
                <BigDataItem
                  title="Ano do Salário Médio"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.averageSalaryYear?.toString()}
                />
                <BigDataItem
                  title="Pessoas Ocupadas"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.busyPeople?.toString()}
                />
                <BigDataItem
                  title="Ano das Pessoas Ocupadas"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.busyPeopleYear?.toString()}
                />
                <BigDataItem
                  title="População Ocupada"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.occupiedPopulation?.toString()}
                />
                <BigDataItem
                  title="Ano da População Ocupada"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.occupiedPopulationYear?.toString()}
                />
                <BigDataItem
                  title="Renda Mensal Nominal por Pessoa"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.populationIncomeMonthlyNominal?.toString()}
                />
                <BigDataItem
                  title="Ano da Renda Mensal Nominal por Pessoa"
                  value={storage.bigData.company.ibge.workPerformanceStatistics?.populationIncomeMonthlyNominalYear?.toString()}
                />
              </Grid>
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Importações
              </Typography>
            </Grid>

            {storage.bigData.company.imports?.map((importation, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Ano" value={importation.year?.toString()} />
                <BigDataItem title="Valor" value={importation.value} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                INPI Marcas
              </Typography>
            </Grid>

            {storage.bigData.company.inpiBrands?.map(inpiBrand => (
              <Grid item xs={12} lg={6} key={inpiBrand.processNumber}>
                <BigDataItem title="Marca Registrada" value={inpiBrand.brand} />
                <BigDataItem
                  title="Classe da Marca Registrada"
                  value={inpiBrand.classBrand}
                />
                <BigDataItem
                  title="Data do Depósito"
                  value={new Date(inpiBrand.depositDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Número do Processo"
                  value={inpiBrand.processNumber}
                />
                <BigDataItem title="Situação" value={inpiBrand.situation} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                INPI Patentes
              </Typography>
            </Grid>

            {storage.bigData.company.inpiPatents?.map(inpiPatent => (
              <Grid item xs={12} lg={6} key={inpiPatent.processNumber}>
                <BigDataItem
                  title="Data de Concessão"
                  value={new Date(inpiPatent.concessionDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Data de Depósito"
                  value={new Date(inpiPatent.depositDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem title="Depositante" value={inpiPatent.depositor} />
                <BigDataItem
                  title="Inventores"
                  value={inpiPatent.inventors?.toString().replaceAll(',', ', ')}
                />
                <BigDataItem
                  title="Número do Processo"
                  value={inpiPatent.processNumber}
                />
                <BigDataItem title="Procurador" value={inpiPatent.procurator} />
                <BigDataItem
                  title="Data de Publicação"
                  value={new Date(inpiPatent.publicationDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem title="Título" value={inpiPatent.title} />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                INPI Softwares
              </Typography>
            </Grid>

            {storage.bigData.company.inpiSoftwares?.map(inpiSoftware => (
              <Grid item xs={12} lg={6} key={inpiSoftware.processNumber}>
                <BigDataItem
                  title="Autores"
                  value={inpiSoftware.authors?.toString().replaceAll(',', ', ')}
                />
                <BigDataItem
                  title="Data de Depósito"
                  value={new Date(inpiSoftware.depositDate).toLocaleDateString(
                    'pt-BR',
                  )}
                />
                <BigDataItem
                  title="Número do Processo"
                  value={inpiSoftware.processNumber}
                />
                <BigDataItem title="Procurador" value={inpiSoftware.procurator} />
                <BigDataItem title="Título" value={inpiSoftware.title} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Lista Internacional
              </Typography>
            </Grid>

            {storage.bigData.company.internationalLists?.map(
              (internationalList, index) => (
                <Grid item xs={12} lg={6} key={index}>
                  <BigDataItem
                    title="Nome da Lista Relacionada"
                    value={internationalList.name}
                  />
                  <BigDataItem
                    title="Data de Consulta"
                    value={new Date(internationalList.queryDate).toLocaleDateString(
                      'pt-BR',
                    )}
                  />
                </Grid>
              ),
            )}
          </Grid>
          {storage.bigData.company.managementContract && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Gerenciamento de Contratos
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Contratos
                  </Typography>
                </Grid>
                {storage.bigData.company.managementContract.contracts?.map(
                  contract => (
                    <Grid item xs={12} lg={6} key={contract.contractNumber}>
                      <BigDataItem
                        title="Número do Contrato"
                        value={contract.contractNumber}
                      />
                      <BigDataItem
                        title="Data de Início"
                        value={
                          contract.initDate &&
                          new Date(contract.initDate).toLocaleDateString('pt-BR')
                        }
                      />
                      <BigDataItem
                        title="Data de Término"
                        value={
                          contract.endDate &&
                          new Date(contract.endDate).toLocaleDateString('pt-BR')
                        }
                      />
                      <BigDataItem
                        title="Valor do Contrato"
                        value={contract.finalValue?.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        })}
                      />

                      <BigDataItem
                        title="Meses de validade"
                        value={contract.monthsValidity?.toString()}
                      />
                      <BigDataItem
                        title="Órgão Responsável"
                        value={contract.organ}
                      />
                      <BigDataItem title="Esfera" value={contract.sphere} />
                      <BigDataItem title="UF" value={contract.uf} />
                    </Grid>
                  ),
                )}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Modalidade de Contratos
                  </Typography>
                </Grid>
                {storage.bigData.company.managementContract.modalityContracts?.map(
                  (contractModality, index) => (
                    <Grid item xs={12} lg={6} key={index}>
                      <BigDataItem
                        title="Quantidade"
                        value={contractModality.quantity?.toString()}
                      />
                      <BigDataItem
                        title="Tipo"
                        value={contractModality.type?.toString()}
                      />
                    </Grid>
                  ),
                )}
              </Grid>

              <BigDataItem
                title="Quantidade Total"
                value={storage.bigData.company.managementContract.quantity?.toString()}
              />
              <BigDataItem
                title="Valor Total"
                value={storage.bigData.company.managementContract.totalValue?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />
            </Grid>
          )}

          {storage.bigData.company.matrixInfo && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Informações da Matriz
                </Typography>
              </Grid>

              <BigDataItem
                title="CNPJ"
                value={storage.bigData.company.matrixInfo.cnpj}
              />
              <BigDataItem
                title="Município"
                value={storage.bigData.company.matrixInfo.municipality}
              />
              <BigDataItem
                title="Data de Abertura"
                value={new Date(
                  storage.bigData.company.matrixInfo.openingDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Razão Social"
                value={storage.bigData.company.matrixInfo.socialReason}
              />
              <BigDataItem
                title="UF"
                value={storage.bigData.company.matrixInfo.uf}
              />
            </Grid>
          )}

          {storage.bigData.company.openCapital && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Capital Aberto
                </Typography>
              </Grid>

              <BigDataItem
                title="Complemento do logradouro do diretor de investimentos"
                value={
                  storage.bigData.company.openCapital
                    .complementLogradouroInvestmentDirector
                }
              />
              <BigDataItem
                title="E-mail do diretor de investimentos"
                value={storage.bigData.company.openCapital.emailInvestmentDirector}
              />
              <BigDataItem
                title="Logradouro do diretor de investimentos"
                value={
                  storage.bigData.company.openCapital.logradouroInvestmentDirector
                }
              />
              <BigDataItem
                title="Atividade principal da empresa"
                value={storage.bigData.company.openCapital.mainActivity}
              />
              <BigDataItem
                title="Município do diretor de investimentos"
                value={
                  storage.bigData.company.openCapital.municipalityInvestmentDirector
                }
              />

              <BigDataItem
                title={'Nome do diretor de investimentos'}
                value={storage.bigData.company.openCapital.nameInvestmentDirector}
              />

              <BigDataItem
                title="Código de negociação da empresa"
                value={storage.bigData.company.openCapital.negotiationCode}
              />

              <BigDataItem
                title="Número do diretor de investimentos"
                value={storage.bigData.company.openCapital.numberInvestmentDirector}
              />

              <BigDataItem
                title="Número de telefone do diretor de investimentos"
                value={storage.bigData.company.openCapital.phoneInvestmentDirector}
              />

              <BigDataItem
                title="Classificação Setorial"
                value={storage.bigData.company.openCapital.sectorialClassification}
              />

              <BigDataItem
                title="Site da empresa"
                value={storage.bigData.company.openCapital.site}
              />

              <BigDataItem
                title="Código do pregão"
                value={storage.bigData.company.openCapital.tradingFloorCode}
              />

              <BigDataItem
                title="UF do diretor de investimentos"
                value={storage.bigData.company.openCapital.ufInvestmentDirector}
              />

              <BigDataItem
                title="CEP do diretor de investimentos"
                value={storage.bigData.company.openCapital.zipInvestmentDirector}
              />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Posições em Ações em Aberto
                  </Typography>
                </Grid>

                {storage.bigData.company.openCapital.openCapitalActionPositions?.map(
                  openCapitalActionPosition => (
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      key={openCapitalActionPosition.document}
                    >
                      <BigDataItem
                        title="Documento"
                        value={openCapitalActionPosition.document}
                      />
                      <BigDataItem
                        title="Valor das ações em aberto do tipo ordinaras"
                        value={openCapitalActionPosition.orderActionsValue?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Valor das ações em aberto do tipo preferenciais"
                        value={openCapitalActionPosition.preferentialActionsValue?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Valor total das ações em aberto"
                        value={openCapitalActionPosition.totalValue?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                    </Grid>
                  ),
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Balanço Patrimonial
                  </Typography>
                </Grid>

                {storage.bigData.company.openCapital.openCapitalPatrimonialBalances?.map(
                  (openCapitalPatrimonialBalance, index) => (
                    <Grid item xs={12} lg={6} key={index}>
                      <BigDataItem
                        title="Total de ativos"
                        value={openCapitalPatrimonialBalance.activeTotal?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Valor dos ativos"
                        value={openCapitalPatrimonialBalance.activeTotal?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Patrimônio Líquido"
                        value={openCapitalPatrimonialBalance.liquidPatrimony?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />

                      <BigDataItem
                        title="Patrimônio Líquido Controlado"
                        value={openCapitalPatrimonialBalance.liquidPatrimonyController?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />

                      <BigDataItem
                        title="Ano do Balanço Patrimonial"
                        value={openCapitalPatrimonialBalance.year?.toString()}
                      />
                    </Grid>
                  ),
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Penalidades
                  </Typography>
                </Grid>

                {storage.bigData.company.openCapital.openCapitalPenalties?.map(
                  (penalty, index) => (
                    <Grid item xs={12} lg={6} key={index}>
                      <BigDataItem
                        title="Data de Criação Penalidade"
                        value={
                          penalty.crated &&
                          new Date(penalty.crated).toLocaleDateString('pt-BR')
                        }
                      />
                      <BigDataItem
                        title="Razão da Penalidade"
                        value={penalty.reason}
                      />
                    </Grid>
                  ),
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Demonstração dos Resultados
                  </Typography>
                </Grid>

                {storage.bigData.company.openCapital.openCapitalResultDemonstrations?.map(
                  (openCapitalResultDemonstration, index) => (
                    <Grid item xs={12} lg={6} key={index}>
                      <BigDataItem
                        title="Período de benefício em capital aberto"
                        value={openCapitalResultDemonstration.benefitPeriod?.toString()}
                      />
                      <BigDataItem
                        title="Período de benefício controlador em capital aberto"
                        value={openCapitalResultDemonstration.benefitPeriodController?.toString()}
                      />
                      <BigDataItem
                        title="Receita de vendas em capital aberto"
                        value={openCapitalResultDemonstration.recipeSale?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Resultado bruto em capital aberto"
                        value={openCapitalResultDemonstration.resultBrute?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Resultado financeiro em capital aberto"
                        value={openCapitalResultDemonstration.resultFinancial?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Resultado de operações líquidas em capital aberto"
                        value={openCapitalResultDemonstration.resultLiquidOperations?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Resultado de equivalência patrimonial em capital aberto"
                        value={openCapitalResultDemonstration.resultPatrimonialEquivalence?.toLocaleString(
                          'pt-BR',
                          {
                            currency: 'BRL',
                            style: 'currency',
                            minimumFractionDigits: 2,
                          },
                        )}
                      />
                      <BigDataItem
                        title="Ano do demonstrativo de resultados em capital aberto"
                        value={openCapitalResultDemonstration.year?.toString()}
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          )}

          {storage.bigData.company.pat && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  PAT
                </Typography>
              </Grid>

              <BigDataItem
                title="Número de funcionários beneficiados"
                value={storage.bigData.company.pat.benefitedEmployees?.toString()}
              />
              <BigDataItem
                title="Data do Exercício"
                value={new Date(
                  storage.bigData.company.pat.exerciseDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Inscrição"
                value={storage.bigData.company.pat.inscription}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Refeições Fornecidas
                  </Typography>
                </Grid>

                {storage.bigData.company.pat.mealProvides?.map((meal, index) => (
                  <Grid item xs={12} lg={6} key={index}>
                    <BigDataItem
                      title="Quantidade de refeições fornecidas"
                      value={meal.quantity?.toString()}
                    />
                    <BigDataItem title="Tipo de refeição" value={meal.type} />
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Modalidades
                  </Typography>
                </Grid>

                {storage.bigData.company.pat.modalities?.map((modality, index) => (
                  <Grid item xs={12} lg={6} key={index}>
                    <BigDataItem
                      title="Número de funcionários beneficiados pela modalidade"
                      value={modality.benefitedEmployees?.toString()}
                    />
                    <BigDataItem title="Modalidade" value={modality.mode} />
                    <BigDataItem
                      title="Número de funcionários que recebem mais de 5 salários mínimos na modalidade PAT"
                      value={modality.over5Sm?.toString()}
                    />
                    <BigDataItem
                      title="CNPJ do fornecedor na modalidade PAT"
                      value={modality.providerCnpj}
                    />
                    <BigDataItem
                      title="Razão Social do fornecedor na modalidade PAT"
                      value={modality.providerSocialReason}
                    />
                    <BigDataItem
                      title="Número de funcionários que recebem até 5 salários mínimos na modalidade PAT"
                      value={modality.to5Sm?.toString()}
                    />
                  </Grid>
                ))}
              </Grid>

              <BigDataItem
                title="Situação de registro"
                value={storage.bigData.company.pat.registrationSituation}
              />
              <BigDataItem
                title="CPF do responsável"
                value={storage.bigData.company.pat.responsibleCpf}
              />
              <BigDataItem
                title="E-mail do responsável"
                value={storage.bigData.company.pat.responsibleEmail}
              />
              <BigDataItem
                title="Nome do responsável"
                value={storage.bigData.company.pat.responsibleName}
              />
            </Grid>
          )}

          {storage.bigData.company.procon && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Procon
                </Typography>
              </Grid>

              <BigDataItem
                title="Valor total da penalidade de grupo do Procon"
                value={storage.bigData.company.procon.groupPenaltyValue}
              />
              <BigDataItem
                title="Nome do Procon"
                value={storage.bigData.company.procon.name}
              />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Grupos do Procon
                  </Typography>
                </Grid>

                {storage.bigData.company.procon.proconGroups?.map(proconGroup => (
                  <Grid item xs={12} lg={6} key={proconGroup.cnpj}>
                    <BigDataItem
                      title="CNPJ do grupo do Procon"
                      value={proconGroup.cnpj}
                    />
                    <BigDataItem
                      title="Valor total da penalidade do grupo do Procon"
                      value={proconGroup.totalPenaltyValue?.toLocaleString(
                        'pt-BR',
                        {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        },
                      )}
                    />
                  </Grid>
                ))}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Processos do Procon
                  </Typography>
                </Grid>

                {storage.bigData.company.procon.proconProcesses?.map(
                  proconProcess => (
                    <Grid item xs={12} lg={6} key={proconProcess.processNumber}>
                      <BigDataItem
                        title="Valor da penalidade do processo do Procon"
                        value={proconProcess.penaltyValue?.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        })}
                      />
                      <BigDataItem
                        title="Número do processo do Procon"
                        value={proconProcess.processNumber}
                      />
                      <BigDataItem
                        title="Status do processo do Procon"
                        value={proconProcess.status}
                      />
                    </Grid>
                  ),
                )}
              </Grid>

              <BigDataItem
                title="Valor total das penalidades do Procon"
                value={storage.bigData.company.procon.totalPenaltyValue?.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              />

              <BigDataItem
                title="Data de Atualização"
                value={new Date(
                  storage.bigData.company.procon.updateDate,
                ).toLocaleDateString('pt-BR')}
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Propriedades
              </Typography>
            </Grid>

            {storage.bigData.company.properties?.map(property => (
              <Grid item xs={12} lg={6} key={property.registryNumber}>
                <BigDataItem
                  title="Dados do Imóvel"
                  value={property.buildingData}
                />
                <BigDataItem
                  title="Área Construída"
                  value={property.builtArea.toString()}
                />
                <BigDataItem title="Complemento" value={property.complement} />
                <BigDataItem
                  title="Vaor de avaliação"
                  value={property.evaluationValue?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
                <BigDataItem
                  title="Área do terreno"
                  value={property.groundArea.toString()}
                />
                <BigDataItem title="Município" value={property.municipality} />
                <BigDataItem title="Bairro" value={property.neighborhood} />
                <BigDataItem
                  title="Número do bairro"
                  value={property.neighborhoodNumber}
                />
                <BigDataItem title="UF do registro" value={property.registerUf} />
                <BigDataItem
                  title="Número do registro"
                  value={property.registryNumber}
                />
                <BigDataItem title="Rua" value={property.street} />
                <BigDataItem title="UF" value={property.uf} />
                <BigDataItem title="CEP" value={property.zip} />
              </Grid>
            ))}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Escolas
              </Typography>
            </Grid>

            {storage.bigData.company.schools?.map((school, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Categoria" value={school.category} />
                <BigDataItem
                  title="Status de funcionamento"
                  value={school.functioning}
                />
                <BigDataItem title="Link do site" value={school.link} />
                <BigDataItem title="Nome" value={school.name} />
                <BigDataItem
                  title="Total de matrículas"
                  value={school.totalEnrollment.toString()}
                />
              </Grid>
            ))}
          </Grid>

          {storage.bigData.company.simpleNational && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Simples Nacional
                </Typography>
              </Grid>
              <BigDataItem
                title="SIMEI"
                value={storage.bigData.company.simpleNational.simei ? 'Sim' : 'Não'}
              />
              <BigDataItem
                title="Data de adesão SIMEI"
                value={
                  storage.bigData.company.simpleNational.simeiDate &&
                  new Date(
                    storage.bigData.company.simpleNational.simeiDate,
                  ).toLocaleDateString('pt-BR')
                }
              />

              <BigDataItem
                title="Simples"
                value={
                  storage.bigData.company.simpleNational.simple ? 'Sim' : 'Não'
                }
              />
              <BigDataItem
                title="Data de adesão Simples"
                value={
                  storage.bigData.company.simpleNational.simpleDate &&
                  new Date(
                    storage.bigData.company.simpleNational.simpleDate,
                  ).toLocaleDateString('pt-BR')
                }
              />
              <BigDataItem
                title="Simples Iregular"
                value={
                  storage.bigData.company.simpleNational.simpleIrregular
                    ? 'Sim'
                    : 'Não'
                }
              />
            </Grid>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Inscrição SINTEGRA
              </Typography>
            </Grid>

            {storage.bigData.company.sintegraInscriptions?.map(
              sintegraInscription => (
                <Grid
                  item
                  xs={12}
                  lg={6}
                  key={sintegraInscription.registrationNumber}
                >
                  <BigDataItem
                    title="Número da Inscrição"
                    value={sintegraInscription.registrationNumber}
                  />
                  <BigDataItem
                    title="Situação da Inscrição"
                    value={sintegraInscription.registrationSituation}
                  />
                  <BigDataItem
                    title="Data da Situação da Inscrição"
                    value={
                      sintegraInscription.registrationSituationDate &&
                      new Date(
                        sintegraInscription.registrationSituationDate,
                      ).toLocaleDateString('pt-BR')
                    }
                  />
                  <BigDataItem title="Regime" value={sintegraInscription.regime} />
                  <BigDataItem title="E-mail" value={sintegraInscription.email} />
                  <BigDataItem title="Telefone" value={sintegraInscription.phone} />
                  <BigDataItem title="UF" value={sintegraInscription.uf} />
                </Grid>
              ),
            )}
          </Grid>

          {storage.bigData.company.suframa && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  SUFRAMA
                </Typography>
              </Grid>

              <BigDataItem
                title="Data de Expiração"
                value={new Date(
                  storage.bigData.company.suframa.expirationDate,
                ).toLocaleDateString('pt-BR')}
              />
              <BigDataItem
                title="Percentual de ICMS"
                value={storage.bigData.company.suframa.icms}
              />
              <BigDataItem
                title="Percentual IPI"
                value={storage.bigData.company.suframa.ipi}
              />
              <BigDataItem
                title="Percentual PIS/COFINS"
                value={storage.bigData.company.suframa.pisCofins}
              />
              <BigDataItem
                title="Número do Registro"
                value={storage.bigData.company.suframa.registrationNumber}
              />
              <BigDataItem
                title="Situação do Registro"
                value={storage.bigData.company.suframa.registrationSituation}
              />
            </Grid>
          )}

          {storage.bigData.company.taxHealth && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography fontWeight={700} fontSize={20}>
                  Saúde fiscal da empresa
                </Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    CNDS
                  </Typography>
                </Grid>

                {storage.bigData.company.taxHealth.cnds?.map(cnd => (
                  <Grid item xs={12} lg={6} key={cnd.certificateNumber}>
                    <BigDataItem
                      title="Número do Certificado"
                      value={cnd.certificateNumber}
                    />
                    <BigDataItem
                      title="Data de Criação"
                      value={
                        cnd?.document?.created &&
                        new Date(cnd.document.created).toLocaleDateString('pt-BR')
                      }
                    />
                    <BigDataItem title="A extensão" value={cnd?.document?.ext} />
                    <BigDataItem title="Nome" value={cnd?.document?.name} />
                    <BigDataItem title="Tipo" value={Type[cnd?.document?.type]} />
                    <BigDataItem title="Url" value={cnd?.document?.url} />
                    <BigDataItem title="Nome do emissor" value={cnd?.emitterName} />
                    <BigDataItem
                      title="Data de emissão"
                      value={
                        cnd?.emissionDate &&
                        new Date(cnd.emissionDate).toLocaleDateString('pt-BR')
                      }
                    />
                    <BigDataItem
                      title="Data de expiração"
                      value={
                        cnd.expirationDate &&
                        new Date(cnd.expirationDate).toLocaleDateString('pt-BR')
                      }
                    />
                    <BigDataItem title="Situação" value={cnd.situation} />
                  </Grid>
                ))}
              </Grid>

              <BigDataItem
                title="Informações relacionadas à saúde fiscal da empresa"
                value={storage.bigData.company.taxHealth.taxHealth}
              />
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography fontWeight={700} fontSize={20}>
                Endereços MTEs
              </Typography>
            </Grid>

            {storage.bigData.company.workMtes?.map((workMte, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Complemento" value={workMte.complement} />
                <BigDataItem
                  title="Ano da ação fiscal do Ministério do Trabalho"
                  value={workMte.fiscalActionYear?.toString()}
                />
                <BigDataItem
                  title="Município onde ocorreu ação fiscal"
                  value={workMte.municipality}
                />
                <BigDataItem
                  title="Bairro onde ocorreu ação fiscal"
                  value={workMte.neighborhood}
                />
                <BigDataItem
                  title="Data de decisão de procedência da ação fiscal"
                  value={
                    workMte.provenanceDecisionDate &&
                    new Date(workMte.provenanceDecisionDate).toLocaleDateString(
                      'pt-BR',
                    )
                  }
                />
                <BigDataItem
                  title="Quantidade de trabalhadores envolvidos na ação fiscal"
                  value={workMte.quantityWorkers?.toString()}
                />
                <BigDataItem title="UF" value={workMte.uf} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};
