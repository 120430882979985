import Delta from 'quill-delta';
import { useState } from 'react';
import ReactQuill from 'react-quill';

import { IQuestionForm, IConditionQuestion } from '../services';
import { useFormStore } from '../store/store';
import { useGetFormsQuestion } from './useGetFormQuestions';

export const useCardConditionQuestionTypesInput = () => {
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();
  const [expression, setExpression] = useState<Delta>({} as Delta);

  function handleOpenDropdownCondition(
    identifierQuestion: number,
    identifierCondition: number,
  ) {
    const indexQuestion = identifierQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const conditionDropdown =
      questionsForm[indexQuestion]?.conditions[indexCondition]?.openDropdown;

    if (conditionDropdown === undefined || conditionDropdown == false) {
      questionsForm[indexQuestion].conditions[indexCondition].openDropdown = true;
      setQuestionsForm(questionsForm);
      return;
    }
    questionsForm[indexQuestion].conditions[indexCondition].openDropdown = false;
    setQuestionsForm(questionsForm);
  }

  function handleDeleteCardCondition(
    identifierQuestion: number,
    identifierCondition: number,
  ) {
    const indexQuestion = identifierQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const idQuestionChanged =
      questionsForm[indexQuestion].conditions[indexCondition].conditionTypeInput
        .nextQuestion;

    const questionChildExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged,
    );

    if (questionChildExcluded)
      handleDeleteSpecificChildQuestion(questionChildExcluded);

    questionsForm[indexQuestion].conditions[
      indexCondition
    ] = {} as IConditionQuestion;

    questionsForm[indexQuestion].conditions[
      indexCondition
    ].identifierCondition = -1; //deletado

    setQuestionsForm(questionsForm);
  }

  const handleExpressionChange = (
    deltaExpression: ReactQuill.Value,
    question: IQuestionForm,
    identifierCondition: number,
  ) => {
    setExpression((deltaExpression as unknown) as Delta);

    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    questionsForm[indexQuestion].conditions[
      indexCondition
    ].conditionTypeInput.deltaExpression = deltaExpression;

    setQuestionsForm(questionsForm);
  };

  return {
    expression,
    setExpression,
    handleOpenDropdownCondition,
    handleDeleteCardCondition,
    handleExpressionChange,
  };
};
