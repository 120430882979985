import { CloudUpload } from '@mui/icons-material';
import { useState } from 'react';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { ButtonOutlined, SectionButtons, Typography } from 'components';
import { Dialog } from 'components/Dialog';
import { IFileStorage } from 'models';

import api from 'config/api';

import { DropzoneInfo, DropzoneWrapper } from './styles';

interface Props extends DropzoneProps {
  open: boolean;
  onClose: () => void;
  onSave: (files: IFileStorage[]) => void;
}

export const Dropzone = ({ open, onClose, onSave, ...rest }: Props) => {
  const [loading, setLoading] = useState(false);

  const upload = async acceptedFiles => {
    const files: IFileStorage[] = [];
    for await (const file of acceptedFiles) {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await api.post<IFileStorage>('/fileStorage', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      files.push(data);
    }
    return files;
  };

  const { getInputProps, getRootProps } = useDropzone({
    ...rest,
    async onDrop(acceptedFiles) {
      if (acceptedFiles.length) {
        setLoading(true);
        await upload(acceptedFiles)
          .then(response => {
            toast.success('Arquivo anexado com sucesso!');
            onSave(response);
          })
          .catch(() => toast.error('Erro ao anexar arquivo!'))
          .finally(() => {
            setLoading(false);
            onClose();
          });
      }
    },
  });

  return (
    <Dialog.Root open={open} onClose={onClose} backdropColor="rgba(0, 0, 0, 0.2)">
      <Dialog.Header title="Anexar arquivos" />
      <Dialog.Content>
        <DropzoneWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          <DropzoneInfo>
            <Typography
              variant="h5"
              color="black"
              style={{ fontWeight: 'normal', textTransform: 'uppercase' }}
            >
              Arraste e solte um arquivo aqui ou clique
            </Typography>
            <CloudUpload fontSize="large" />
          </DropzoneInfo>
        </DropzoneWrapper>
      </Dialog.Content>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" loading={loading} onClick={onClose} />
      </SectionButtons>
    </Dialog.Root>
  );
};
