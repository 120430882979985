import { Grid } from '@mui/material';
import themes from 'themes';

import Typography from 'components/Typography';

const MessageFilter = () => {
  return (
    <Grid item xs={12} pb={2}>
      <Typography color={themes.colors.black}>
        Selecione um filtro e o seu valor. Você pode selecionar diferentes filtros.
      </Typography>
    </Grid>
  );
};

export default MessageFilter;
