const ignoreWords = ['do', 'da', 'de'];

export const capitalizeWords = (str: string) => {
  return str
    .split(' ')
    .map(word => {
      // Se a palavra está na lista de palavras para ignorar, mantém como está
      if (ignoreWords.includes(word)) {
        return word;
      }
      // Capitaliza a primeira letra e mantém o resto da palavra em minúsculas
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};
