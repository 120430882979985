import { stringify } from 'qs';

import { IFilterDatabasePayload } from 'components/FilterDatabase/filterDatabase.types';
import { IPagination } from 'models';
import { CompanyScr } from 'models/enterprise/scr.types';

import api from 'config/api';

export class ScrService {
  static async getScrData({
    page,
    perPage,
    search,
    type,
    active,
    status,
    manager,
    product,
  }: IFilterDatabasePayload) {
    const params = stringify({
      page,
      perPage,
      search,
      type,
      active,
      status,
      manager,
      product,
    });

    const response = await api.get<IPagination<CompanyScr>>(
      `/enterprise/scr?${params}`,
    );

    return response.data;
  }
}
