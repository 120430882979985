import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { Box, ButtonOutlined, ClearFilters, Icon } from 'components';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useConsultantNotifications } from 'modules/notifications/hooks';

import { useCustomerSearch, useInternalRegister } from '../hooks';
import { useCustomerFilterForm } from '../hooks/useCustomerFilterForm';
import { useCustomerManagementStore } from '../store/store';

const SearchContainer = () => {
  const { setSearch, setOpenFilterDialog } = useCustomerManagementStore();
  const { isFiltering, clearFilter } = useCustomerFilterForm();
  const { handleOpenNotificationsDialog } = useConsultantNotifications();

  const { openInternalRegisterDialog } = useInternalRegister();

  const { handleChangeSearch } = useCustomerSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title="Empresas"
            icon={<Icon>group_add</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />
          <PrivateLayout.Content.Header.Actions>
            <IconButton onClick={handleOpenNotificationsDialog}>
              <Icon>notifications_active</Icon>
            </IconButton>
            <Box position="relative">
              <IconButton onClick={() => setOpenFilterDialog(true)}>
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={clearFilter} />}
            </Box>
            <ButtonOutlined
              color="white"
              label={'Adicionar cliente'}
              icon={<Add />}
              loading={false}
              onClick={() => openInternalRegisterDialog()}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { SearchContainer };
