import { IEnterprise } from './enterpise.types';

export enum ESerasaType {
  company = 'Empresa',
  partner = 'Sócio',
}

export interface ICompanySerasa {
  name: string;
  document: string;
  pefin: CompanyPefinTO;
  refin: CompanyRefinTO;
  overdueDebts: CompanyOverdueDebtsTO;
  protests: CompanyProtestTO;
  serasa: KleverSerasa;
  check: CompanyCheckTO;
  pefinAmount: number;
  refinAmount: number;
  checkAmount: number;
  overdueDebtsAmount: number;
  enterprise: IEnterprise;
}

export interface IPersonSerasa {
  name: string;
  document: string;
  pefin: PersonPefinTO;
  refin: PersonRefinTO;
  check: PersonCheckTO;
  overdueDebts: OverdueDebtsTO;
  protests: PersonProtestTO;
  serasa: KleverSerasaPerson;
  pefinAmount: number;
  refinAmount: number;
  overdueDebtsAmount: number;
  enterprise: IEnterprise;
}

export interface KleverSerasa {
  created: string;
  facts: CompanyFactTO;
  negativeData: CompanyNegativeDataTO;
  optionalFeatures: CompanyOptionalFeaturesTO;
  registration: CompanyRegistrationTO;
  score: CompanyScoreTO;
}

export interface CompanyFactTO {
  bankrupts: CompanyBankruptsTO;
  judgementFilings: CompanyJudgementFilingsTO;
}

export interface CompanyBankruptsTO {
  response: CompanyResponseBankruptsTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseBankruptsTO {
  city?: string;
  eventDate?: string;
  eventType?: string;
  origin?: string;
  state?: string;
}

export interface CompanySummaryTO {
  balance: number;
  count: number;
}

export interface CompanyJudgementFilingsTO {
  response: CompanyResponseJudgementFilingTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseJudgementFilingTO {
  amount: number;
  city: string;
  civilCourt: string;
  distributor: string;
  legalNature: string;
  legalNatureId: string;
  occurrenceDate: string;
  state: string;
}

export interface CompanyNegativeDataTO {
  check: CompanyCheckTO;
  overdueDebts: CompanyOverdueDebtsTO;
  pefin: CompanyPefinTO;
  protests: CompanyProtestTO;
  refin: CompanyRefinTO;
}

export interface CompanyCheckTO {
  response: CompanyResponseCheckTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseCheckTO {
  alinea?: number;
  bankAgencyId?: number;
  bankId?: number;
  bankName?: string;
  checkCount?: number;
  checkNumber?: string;
  city?: string;
  federalUnit?: string;
  occurrenceDate?: string;
}

export interface CompanyOverdueDebtsTO {
  response: CompanyResponseOverdueDebtsTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseOverdueDebtsTO {
  amount: number;
  city: string;
  contractId: string;
  creditorName: string;
  federalUnit: string;
  legalNature: string;
  legalNatureId: string;
  occurrenceDate: string;
  principal: boolean;
}

export interface CompanyPefinTO {
  response: CompanyResponsePefinTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponsePefinTO {
  amount: number;
  contractId: string;
  creditorName: string;
  federalUnit: string;
  legalNature: string;
  legalNatureId: string;
  occurrenceDate: string;
  principal: boolean;
}

export interface CompanyProtestTO {
  response: CompanyResponseProtestTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseProtestTO {
  amount: number;
  city: string;
  federalUnit: string;
  occurrenceDate: string;
  officeName: string;
  officeNumber: string;
}

export interface CompanyRefinTO {
  response: CompanyResponseRefinTO[];
  summary: CompanySummaryTO;
}

export interface CompanyResponseRefinTO {
  amount: number;
  contractId: string;
  creditorName: string;
  federalUnit: string;
  legalNature: string;
  legalNatureId: string;
  occurrenceDate: string;
  principal: boolean;
}

export interface CompanyOptionalFeaturesTO {
  decisionLimitCredit: CompanyDecisionLimitCreditTO;
  director: CompanyDirectorResponseTO;
  inquiryCompanyResponse: CompanyInquiryResponseTO;
  partner: CompanyPartnerResponseTO;
  qsaCompleteReport: CompanyQsaCompleteReportTO;
  scores: CompanyCreditLimitTO;
  spcAnnotations: CompanySpcAnotationResponseTO;
  spcMarketInquiries: CompanySpcMarketInquiryTO;
}

export interface CompanyDecisionLimitCreditTO {
  code: number;
  data: CompanyDecisionLimitCreditDataTO;
  message: string;
}

export interface CompanyDecisionLimitCreditDataTO {
  analyzedCriteria: CompanyDecisionLimitCreditAnalyzedCriteriaTO;
  companyName: string;
  creationDate: string;
  document: string;
  proposalNumber: string;
  recommendationSaleType: string;
  recommendationType: string;
  recommendedLimitValue: number;
  riskLevelCode: number;
  saleType: string;
  saleTypeCode: number;
  transactionValue: number;
}

export interface CompanyDecisionLimitCreditAnalyzedCriteriaTO {
  documentActive: boolean;
  foundationTime: string;
  noRestrictiveActive: boolean;
  statusRegistration: boolean;
}

export interface CompanyDirectorResponseTO {
  DirectorResponse: CompanyOptionalFeaturesResponseTOCompanyDirectorTO;
}

export interface CompanyOptionalFeaturesResponseTOCompanyDirectorTO {
  results: CompanyDirectorTO[];
}

export interface CompanyDirectorTO {
  documentId: string;
  hasNegative: boolean;
  name: string;
  role: string;
}

export interface CompanyInquiryResponseTO {
  inquiryCompanyResponse: CompanyOptionalFeaturesResponseTOCompanyCompanyInquiryTO;
  quantity: CompanyCompanyInquiryQuantityTO;
}

export interface CompanyOptionalFeaturesResponseTOCompanyCompanyInquiryTO {
  results: CompanyCompanyInquiryTO[];
}

export interface CompanyCompanyInquiryTO {
  companyDocumentId: string;
  companyName: string;
  daysQuantity: number;
  occurrenceDate: string;
}

export interface CompanyCompanyInquiryQuantityTO {
  actual: number;
  bankActual: number;
  historical: CompanyCompanyInquiryQuantityHistoricalTO;
}

export interface CompanyCompanyInquiryQuantityHistoricalTO {
  bankOccurrences: number;
  inquiryDate: string;
  occurrences: number;
}

export interface CompanyPartnerResponseTO {
  PartnerResponse: CompanyOptionalFeaturesResponseTOCompanyPartnerTO;
}

export interface CompanyOptionalFeaturesResponseTOCompanyPartnerTO {
  results: CompanyPartnerTO[];
}

export interface CompanyPartnerTO {
  documentId: string;
  hasNegative: boolean;
  inconsistent: boolean;
  name: string;
  participationPercentage: number;
}

export interface CompanyQsaCompleteReportTO {
  directors: CompanyDirectorMoreCompleteReportTO[];
  infoMessages: string;
  partners: CompanyPartnerMoreCompleteReportTO[];
  shareCapital: CompanyShareCapitalTO;
}

export interface CompanyDirectorMoreCompleteReportTO {
  address: CompanyMoreCompleteReportAddressTO;
  birthDate: string;
  debts: CompanyMoreCompleteReportDebtTO[];
  document: string;
  documentBranch: string;
  documentDigit: string;
  documentSequence: string;
  endDateTerm: string;
  entryDate: string;
  idNumber: string;
  kindPerson: string;
  maritalStatus: string;
  name: string;
  nationality: string;
  office: string;
  phone: CompanyMoreCompleteReportPhoneTO;
  relationship: string;
  restrictionIndicator: boolean;
  startDateTerm: string;
  updateDate: string;
}

export interface CompanyMoreCompleteReportAddressTO {
  addressLine: string;
  city: string;
  country: string;
  countryCode: string;
  district: string;
  state: string;
  zipCode: string;
}

export interface CompanyMoreCompleteReportDebtTO {
  debtType: string;
  summary: CompanySummaryTO;
}

export interface CompanyMoreCompleteReportPhoneTO {
  areaCode: string;
  phoneNumber: string;
}

export interface CompanyPartnerMoreCompleteReportTO {
  address: CompanyMoreCompleteReportAddressTO;
  debts: CompanyMoreCompleteReportDebtTO[];
  document: string;
  documentBranch: string;
  documentDigit: string;
  documentSequence: string;
  entryDate: string;
  foundationDate: string;
  idNumber: string;
  kindPerson: string;
  name: string;
  nationality: string;
  percentageCapital: number;
  percentageVotingCapital: number;
  phone: CompanyMoreCompleteReportPhoneTO;
  relationship: string;
  restrictionIndicator: boolean;
}

export interface CompanyShareCapitalTO {
  capitalValue: number;
  control: string;
  nature: string;
  origin: string;
  realizedCapitalValue: number;
  updateDate: string;
}

export interface CompanyCreditLimitTO {
  scoreResponse: CompanyCreditLimitScoreResponseTO[];
}

export interface CompanyCreditLimitScoreResponseTO {
  defaultRate?: string;
  message?: string;
  score?: number;
  scoreModel?: string;
  scoreParam?: CompanyCreditLimitScoreParamTO[];
}

export interface CompanyCreditLimitScoreParamTO {
  key?: string;
  value?: number;
}

export interface CompanySpcAnotationResponseTO {
  spcAnnotations: CompanySpcAnotationTO[];
  summary: CompanySummaryTO;
}

export interface CompanySpcAnotationTO {
  amount?: number;
  city?: string;
  contractId?: string;
  creditorName?: string;
  federalUnit?: string;
  inclusionDate?: string;
  occurrenceDate?: string;
  origin?: string;
  principal?: boolean;
}

export interface CompanySpcMarketInquiryTO {
  spcMarketInquiriesResponseList: CompanyCompanyInquiryTO[];
  spcMarketInquirySummary: CompanySpcMarketInquirySummaryTO[];
}

export interface CompanySpcMarketInquirySummaryTO {
  inquiryQuantity?: CompanySpcMarketInquirySummaryQuantityTO;
}

export interface CompanySpcMarketInquirySummaryQuantityTO {
  actual: number;
  creditInquiriesQuantity: CompanyCreditInquiriesQuantityTO;
}

export interface CompanyCreditInquiriesQuantityTO {
  firstOccurrence: string;
  occurrences: number;
}

export interface CompanyRegistrationTO {
  address: CompanyAddressTO;
  companyDocument: string;
  companyName: string;
  foundationDate: string;
  statusRegistration: string;
}

export interface CompanyAddressTO {
  city: string;
  state: string;
}

export interface CompanyScoreTO {
  defaultRate: string;
  message: string;
  score: number;
}

export interface KleverSerasaPerson {
  created: string;
  facts: PersonFactTO;
  negativeData: PersonNegativeDataTO;
  optionalFeatures: OptionalFeaturesTO;
  registration: PersonRegistrationTO;
  score: ScoreTO;
}

export interface PersonFactTO {
  inquiry: InquiryTO;
  stolenDocuments: StolenDocumentsTO;
}

export interface InquiryTO {
  inquiryResponse: ResponseTO[];
  summary: SummaryTO;
}

export interface OptionalFeaturesTO {
  attributes: AttributesTO;
  bankrupts: BankruptsTO;
  creditLimitAndDecision: CreditTO;
  judgementFilings: JudgementFilingsTO;
  overdueDebts: OverdueDebtsTO;
  partner: PartnerTO;
  paymentsHistory: PaymentsHistoryTO;
  spcAnnotations: SpcAnnotationsTO;
  spcInquiries: SpcInquiriesTO;
}

export interface ResponseTO {
  occurrenceDate?: string;
  segmentDescription?: string;
}

export interface SummaryTO {
  count: number;
}

export interface StolenDocumentsTO {
  stolenDocumentsResponse: ResponseTO[];
  summary: SummaryTO;
}

export interface PersonNegativeDataTO {
  check: PersonCheckTO;
  pefin: PersonPefinTO;
  protest: PersonProtestTO;
  refin: PersonRefinTO;
}

export interface PersonCheckTO {
  response: PersonCheckInnerResponseTO[];
  summary: SummaryTO;
}

export interface PersonCheckInnerResponseTO {
  alinea?: number;
  bankAgencyId?: number;
  bankId?: number;
  bankName?: string;
  checkCount?: number;
  checkNumber?: string;
  city?: string;
  federalUnit?: string;
  legalSquare?: string;
  occurrenceDate?: string;
}

export interface PersonPefinTO {
  response: PersonPefinInnerResponseTO[];
  summary: SummaryTO;
}

export interface PersonPefinInnerResponseTO {
  amount?: number;
  availableDate?: string;
  caseNumber?: string;
  city?: string;
  civilCourt?: string;
  contractId?: string;
  creditorBusinessId?: string;
  creditorName?: string;
  distributor?: string;
  federalUnit?: string;
  flagSubJudice?: boolean;
  inclusionDate?: string;
  legalNature?: string;
  legalNatureId?: string;
  legalSquare?: string;
  occurrenceDate?: string;
  principal?: boolean;
  publicAgency?: boolean;
}

export interface PersonProtestTO {
  response: PersonProtestsInnerResponseTO[];
  summary: SummaryTO;
}

export interface PersonProtestsInnerResponseTO {
  amount?: number;
  city?: string;
  federalUnit?: string;
  occurrenceDate?: string;
  officeName?: string;
  officeNumber?: string;
}

export interface PersonRefinTO {
  response: PersonRefinInnerResponseTO[];
  summary: SummaryTO;
}

export interface PersonRefinInnerResponseTO {
  amount?: number;
  availableDate?: string;
  caseNumber?: string;
  city?: string;
  civilCourt?: string;
  contractId?: string;
  creditorBusinessId?: string;
  creditorName?: string;
  distributor?: string;
  federalUnit?: string;
  flagSubJudice?: boolean;
  inclusionDate?: string;
  legalNature?: string;
  legalNatureId?: string;
  legalSquare?: string;
  occurrenceDate?: string;
  principal?: boolean;
  publicAgency?: boolean;
}

export interface AttributesTO {
  affordability: AttributeTO;
  creditCardOnTimePaymentIndex: AttributeTO;
  creditCardUsageTrend: AttributeTO;
  estimatedIncome: AttributeTO;
  incomeCommitment: AttributeTO;
  onTimePaymentsIndex: AttributeTO;
}

export interface AttributeTO {
  attributeModel: string;
  codeMessage: number;
  message: string;
  scoring: number;
}

export interface BankruptsTO {
  response: BankruptsInnerResponseTO[];
  summary: SummaryWithOcurrenceBoundariesTO;
}

export interface BankruptsInnerResponseTO {
  caseNumber?: string;
  city?: string;
  companyDocumentId?: string;
  companyName?: string;
  inclusionDate?: string;
  legalNature?: string;
  occurrenceDate?: string;
  positionCompany?: string;
  state?: string;
}

export interface SummaryWithOcurrenceBoundariesTO {
  balance: number;
  count: number;
  firstOccurrence: string;
  lastOccurrence: string;
}

export interface CreditTO {
  query: QueryTO;
}

export interface QueryTO {
  code: number;
  data: QueryDataTO;
  message: string;
}

export interface QueryDataTO {
  analyzedCriteria: AnalyzedCriteriaTO;
  billing: boolean;
  consumerName: string;
  creationDate: string;
  document: string;
  proposalNumber: string;
  recommendationSaleType: string;
  recommendationType: string;
  recommendedLimitValue: number;
  riskLevelCode: number;
  saleType: string;
  saleTypeCode: number;
  transactionValue: number;
}

export interface AnalyzedCriteriaTO {
  documentActive: boolean;
  lowIncomeCommitment: boolean;
  noProtests: boolean;
  noRestriction: boolean;
}

export interface JudgementFilingsTO {
  response: JudgementFilingsInnerResponseTO[];
  summary: SummaryWithOcurrenceBoundariesTO;
}

export interface JudgementFilingsInnerResponseTO {
  amount?: number;
  availableDate?: string;
  caseNumber?: string;
  city?: string;
  civilCourt?: string;
  distributor?: string;
  flagSubJudice?: boolean;
  inclusionDate?: string;
  legalNature?: string;
  legalNatureId?: string;
  legalSquare?: string;
  occurrenceDate?: string;
  principal?: boolean;
  state?: string;
}

export interface OverdueDebtsTO {
  collectionRecordsResponse: OverdueDebtsInnerResponseTO[];
  summary: SummaryWithOcurrenceBoundariesTO;
}

export interface OverdueDebtsInnerResponseTO {
  amount?: number;
  city?: string;
  contractId?: string;
  creditorBusinessId?: string;
  creditorName?: string;
  federalUnit?: string;
  flagSubJudice?: boolean;
  legalNature?: string;
  legalNatureId?: string;
  occurrenceDate?: string;
  principal?: boolean;
  publicAgency?: boolean;
}

export interface PartnerTO {
  partnerShipResponse: PartnerInnerResponseTO[];
  summary: SummaryTO;
}

export interface PartnerInnerResponseTO {
  businessDocument?: string;
  companyName?: string;
  companyState?: string;
  companyStatus?: string;
  companyStatusCode?: string;
  companyStatusDate?: string;
  participationInitialDate?: string;
  participationPercentage?: number;
  updateDate?: string;
}

export interface PaymentsHistoryTO {
  response: PaymentsHistoryInnerResponseTO[];
}

export interface PaymentsHistoryInnerResponseTO {
  finalDayRange?: number;
  initialDayRange?: number;
  range?: string;
  score?: string;
}

export interface SpcAnnotationsTO {
  spcNegative: NegativeTO;
}

export interface NegativeTO {
  spcNegativeResponse: NegativeResponseTO;
}

export interface NegativeResponseTO {
  amount: number;
  city: string;
  contractId: string;
  creditorCity: string;
  creditorFederalUnit: string;
  creditorName: string;
  federalUnit: string;
  occurrenceDate: string;
  summary: SummaryWithOcurrenceBoundariesTO;
}

export interface SpcInquiriesTO {
  creditInquiriesQuantity: CreditInquiriesQuantityTO;
  spcInquires: InquiriesTO;
  spcInquirySummary: InquirySummaryTO;
  summary: SummaryTO;
}

export interface CreditInquiriesQuantityTO {
  inquiryDate: string;
  occurrences: number;
}

export interface InquiriesTO {
  spcInquiriesResponse: ResponseTO[];
}

export interface InquirySummaryTO {
  spcInquiryQuantity: QuantityTO;
}

export interface QuantityTO {
  actual: number;
}

export interface PersonRegistrationTO {
  address: AddressTO;
  birthDate: string;
  consumerName: string;
  documentNumber: string;
  motherName: string;
  phone: PhoneTo;
  statusDate: string;
  statusRegistration: string;
}

export interface AddressTO {
  address1: string;
  address2: string;
  borderMunicipality: string;
  buildingType: string;
  codeCountry: string;
  codeMunicipality: string;
  collectiveBuilding: boolean;
  country: string;
  deliveryRestriction: boolean;
  formattedAddress: string;
  latestAddress: boolean;
  latitude: number;
  longitude: number;
  microRegion: string;
  mregion: string;
  municipality: string;
  neighborhood: string;
  number: string;
  originalNeighborhood: string;
  precision: string;
  region: string;
  registryUf: string;
  residentialAddress: boolean;
  rfPhones: string[];
  uf: string;
  zip: string;
}

export interface PhoneTo {
  areaCode: number;
  phoneNumber: number;
  regionCode: number;
}

export interface ScoreTO {
  codeMessage: number;
  defaultRate: string;
  message: string;
  range: string;
  score: number;
  scoreModel: string;
}
