import { Add } from '@mui/icons-material';
import { TextField, Grid } from '@mui/material';

import { Typography, Box, ButtonContained } from 'components';
import { IAnswerOption } from 'models';

interface AnswerOptionsProps {
  option: string;
  isLast: boolean;
  answerOptions: IAnswerOption[];
  addAnswer: () => void;
  setAnswerOptionValue: (answer: string, index: number) => void;
}

export const AnswerOptions = ({
  option,
  isLast,
  answerOptions,
  addAnswer,
  setAnswerOptionValue,
}: AnswerOptionsProps) => {
  const getValueOption = answerOptions[Number(option) - 1]?.value;

  return (
    <Grid item xs={12} gap={1} display={'flex'} flexDirection={'column'}>
      <Typography>Opção {option}</Typography>
      <Box display={'flex'} mb={20}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="description"
            label="Descrição"
            type="text"
            value={getValueOption}
            onChange={e => setAnswerOptionValue(e.target.value, Number(option))}
          />
        </Grid>
        {isLast && (
          <Grid item xs={6} display={'flex'} alignItems={'center'} pl={2}>
            <ButtonContained
              icon={<Add />}
              label="Mais opções"
              onClick={() => addAnswer()}
            ></ButtonContained>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};
