import { Edit } from '@mui/icons-material';
import { TableCell, Icon } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { IUser } from 'models';

import { useDialog } from '../hooks';

export const Item = (value: IUser) => {
  const { handleOpenFormDialog } = useDialog();

  return (
    <>
      <TableRow key={value.id} onClick={() => handleOpenFormDialog({ ...value })}>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.surname ? `${value.name} ${value.surname}` : value.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.email}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.role.name}
          </Typography>
        </TableCell>
        <TableCell width={'60px'}>
          {!!value.active ? (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
            >
              Ativo
            </TypographyActive>
          ) : (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
              backgroundColor={'#f2665e'}
            >
              Inativo
            </TypographyActive>
          )}
        </TableCell>
        <TableCell align="center">
          <Icon>
            <Typography>
              <Edit />
            </Typography>
          </Icon>
        </TableCell>
      </TableRow>
    </>
  );
};
