import { Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { useDashboardStore } from 'modules/dashboard/store/store';

import { increaseDistanceLegendPlugin } from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export interface AbcCurveDataItem {
  abcCurveA: number;
  abcCurveB: number;
  abcCurveC: number;
}

interface AbcCurveSupplierChartProps {
  data: AbcCurveDataItem;
  title: string;
  type: 'supplier' | 'customer';
}

const AbcCurveChart = ({ data, title, type }: AbcCurveSupplierChartProps) => {
  const { setChartsImagesUrl } = useDashboardStore();

  const [imagesSet, setImagesSet] = useState({
    abcCurveCustomer: false,
    abcCurveSupplier: false,
  });

  const abcCurveData = Object.values(data);
  const heightWindow = window.innerHeight;
  const widthChart = heightWindow > 900 ? '97.4%' : '96.5%';

  return (
    <Card
      elevation={2}
      style={{
        width: widthChart,
        height: 400,
        padding: 16,
        paddingRight: 20,
        paddingLeft: 20,
        marginTop: 8,
        marginLeft: 1,
      }}
    >
      <Chart
        type="bar"
        data={{
          labels: ['A', 'B', 'C'],
          datasets: [
            {
              label: 'A',
              data: abcCurveData,
              backgroundColor: [
                'rgb(2, 97, 173)',
                'rgb(81, 81, 81)',
                'rgb(153, 153, 153)',
              ],
              barThickness: 'flex',
              maxBarThickness: 170,
            },
            {
              label: 'B',
              data: null,
              backgroundColor: ['rgb(81, 81, 81)'],
            },
            {
              label: 'C',
              data: null,
              backgroundColor: ['rgb(153, 153, 153)'],
            },
          ],
        }}
        plugins={[
          increaseDistanceLegendPlugin(30),
          {
            id: 'apply_data_inside_bar',
            beforeDraw: function (chartInstance) {
              const ctx = chartInstance.ctx;
              // render the value of the chart above the bar
              ctx.font = `20px ${ChartJS.defaults.font.family}`;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              chartInstance.data.datasets.forEach(function (dataset, i) {
                if (chartInstance.isDatasetVisible(i)) {
                  const meta = chartInstance.getDatasetMeta(i);
                  meta.data.forEach(function (bar, index) {
                    const data = dataset.data[index];
                    ctx.fillText(String(data), bar.x, bar.y + -5);
                  });
                }
              });
            },
          },
        ]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          skipNull: true,
          plugins: {
            legend: {
              display: true,
              position: 'top',
              labels: {
                color: themes.colors.dashboard.table.text.black,
                font: {
                  weight: 'bold',
                },
              },
            },
            title: {
              display: true,
              text: title,
              font: {
                size: 24,
              },
              color: themes.colors.dashboard.table.text.black,
              align: 'start',
            },
            datalabels: {
              color: '#fff',
              font: {
                weight: 'bold',
                size: 20,
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1,
                callback: function (value) {
                  if (Number.isInteger(value)) {
                    return value; // Show only integer values
                  }
                },
              },
              grid: {
                drawBorder: false,
                drawOnChartArea: true,
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
          animation: {
            onComplete: animation => {
              if (type === 'customer' && !imagesSet.abcCurveCustomer) {
                setChartsImagesUrl(
                  'abcCurveCustomer',
                  animation.chart.toBase64Image(),
                  animation.chart.width,
                  animation.chart.height,
                );
                setImagesSet(prevState => ({
                  ...prevState,
                  abcCurveCustomer: true,
                }));
              }
              if (type === 'supplier' && !imagesSet.abcCurveSupplier) {
                setChartsImagesUrl(
                  'abcCurveSupplier',
                  animation.chart.toBase64Image(),
                  animation.chart.width,
                  animation.chart.height,
                );
                setImagesSet(prevState => ({
                  ...prevState,
                  abcCurveSupplier: true,
                }));
              }
            },
          },
        }}
      />
    </Card>
  );
};

export { AbcCurveChart };
