import { useCallback, useMemo } from 'react';

import { OptionsValues } from 'utils/helper';

import { IUserFilter, IUserFilterBy, userFilterByOptions } from '../services';
import { useUserStore } from '../store/store';
import { useFindRolesTypesOptions, useFindActiveOptions } from '../utils';

export const useFilterForm = () => {
  const {
    currentFilter,
    setCurrentFilter,
    setSearchOption,
    filterBy,
    setFilterBy,
  } = useUserStore();

  const isFiltering = currentFilter.length > 0;

  const handleChangeFilter = (value: OptionsValues[]) => {
    const addedFilter = value[value.length - 1];
    const newValues = [
      ...currentFilter,
      {
        filterBy: filterBy.value,
        ...addedFilter,
      },
    ];
    setCurrentFilter(newValues);
  };

  const handleRemoveFilter = (option: IUserFilter) => {
    const newFilters = currentFilter.filter(item => item !== option);

    setCurrentFilter(newFilters);
  };

  const resetFilter = useCallback(() => {
    setCurrentFilter([]);
  }, []);

  const resetSearchOption = useCallback(() => setSearchOption(''), []);

  const { rolesTypesOptions } = useFindRolesTypesOptions();
  const { activeOptions } = useFindActiveOptions();

  const getOptions = () => {
    switch (filterBy?.value) {
      case IUserFilterBy.ROLES_TYPE:
        return rolesTypesOptions;
      case IUserFilterBy.ACTIVE_INATIVE:
        return activeOptions;
      default:
        return [];
    }
  };

  const options = getOptions();

  const isOptionEqualToValue = (option, filter) => {
    return option.value === filter.value && option.label === filter.label;
  };

  const generateFilterParams = useMemo(() => {
    const filterParams = currentFilter.reduce((acc, item) => {
      if (!acc[item.filterBy]) {
        acc[item.filterBy] = [];
      }

      if (item.filterBy === 'rolesTypes') {
        acc[item.filterBy].push(item.label);
      } else {
        acc[item.filterBy].push(item.value);
      }

      return acc;
    }, {});

    return filterParams;
  }, [currentFilter]);

  const filterOptions = userFilterByOptions.filter(
    option => option.label !== 'Tipo de Usuário',
  );

  return {
    filterBy,
    isFiltering,
    options,
    handleChangeFilter,
    currentFilter,
    handleRemoveFilter,
    resetSearchOption,
    resetFilter,
    isOptionEqualToValue,
    setFilterBy,
    filterParams: generateFilterParams,
    filterOptions,
  };
};
