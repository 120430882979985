import { IFormAnswerAttachment } from 'models';

import api from 'config/api';

import {
  IFormAnswerPayload,
  IFormAnswerResponse,
  IFormAnswerUpdatePayload,
  IGetNextQuestionPayload,
  ITriggerDocSignPayload,
} from './types';

class FormAnswerServices {
  static async submitAnswer(body: IFormAnswerPayload) {
    const { data } = await api.post<IFormAnswerResponse>(
      `/form/form-answer`,
      body,
      {
        params: {
          enterpriseId: body.enterpriseId,
          formRecordId: body?.formRecordId,
        },
      },
    );

    return data;
  }

  static async updateAnswer({ id, body }: IFormAnswerUpdatePayload) {
    const { data } = await api.put<IFormAnswerResponse>(
      `/form/form-answer/${id}`,
      body,
      {
        params: {
          enterpriseId: body.enterpriseId,
          formRecordId: body?.formRecordId,
        },
      },
    );

    return data;
  }

  static async getNextQuestion(params: IGetNextQuestionPayload) {
    const { data } = await api.post<boolean>(
      `/form/form-answer/get-next-question`,
      {
        params,
      },
    );

    return data;
  }

  static async updateRevisionQuestion({ id, body }: IFormAnswerUpdatePayload) {
    const { data } = await api.put<IFormAnswerResponse>(
      `/form/form-answer/revision-question/${id}`,
      body,
      {
        params: {
          enterpriseId: body.enterpriseId,
          formRecordId: body?.formRecordId,
        },
      },
    );

    return data;
  }

  static async getFormAnswerAttachments(idFormAnswer: string) {
    const { data } = await api.get<IFormAnswerAttachment[]>(
      `/form/form-answer/attachments/${idFormAnswer}`,
    );

    return data;
  }

  static async triggerDocSign(body: ITriggerDocSignPayload) {
    const { data } = await api.post<void>(
      `/form/form-answer/trigger-doc-sign`,
      body,
    );

    return data;
  }

  static async finishForm(formRecordId: string) {
    await api.post<void>(`/form/form-answer/finish-form/${formRecordId}`);
  }
}

export default FormAnswerServices;
