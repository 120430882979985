import { Grid, Typography } from '@mui/material';

import { IPersonSerasa } from 'models';

import { SerasaModalItem } from './SerasaModalItem';
import {
  Check,
  OverdueDebtsSummary,
  PersonAttribute,
  PersonPefinRefin,
} from './serasa';

interface Props {
  serasa: IPersonSerasa;
}

const SerasaPersonModal = ({ serasa }: Props) => {
  const serasaData = serasa.serasa;

  return (
    <Grid container spacing={4} gap={8}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Informações sobre dados cadastrais
          </Typography>
        </Grid>
        <SerasaModalItem title="Documento" value={serasa.document} />
        <SerasaModalItem title="Nome" value={serasa.name} />
        <SerasaModalItem
          title="Data de nascimento"
          value={new Date(serasaData.registration.birthDate).toLocaleDateString(
            'pt-br',
          )}
        />
        <SerasaModalItem
          title="Nome da mãe"
          value={serasaData.registration.motherName}
        />
        <SerasaModalItem
          title="Situação"
          value={serasaData.registration.statusRegistration}
        />
        <SerasaModalItem
          title="Data de Status"
          value={new Date(serasaData.registration.statusDate).toLocaleDateString(
            'pt-br',
          )}
        />
        <SerasaModalItem
          title="Endereço 1"
          value={serasaData.registration.address.address1}
        />
        <SerasaModalItem
          title="Endereço 2"
          value={serasaData.registration.address.address2}
        />
        <SerasaModalItem
          title="Município fronteiriço"
          value={serasaData.registration.address.borderMunicipality}
        />
        <SerasaModalItem
          title="Tipo de residência"
          value={serasaData.registration.address.buildingType}
        />
        <SerasaModalItem
          title="Código do país"
          value={serasaData.registration.address.codeCountry}
        />
        <SerasaModalItem
          title="Código do município"
          value={serasaData.registration.address.codeMunicipality}
        />
        <SerasaModalItem
          title="Edifício coletivo"
          value={serasaData.registration.address.collectiveBuilding ? 'Sim' : 'Não'}
        />
        <SerasaModalItem
          title="País"
          value={serasaData.registration.address.country}
        />
        <SerasaModalItem
          title="Restrição de entrega"
          value={
            serasaData.registration.address.deliveryRestriction ? 'Sim' : 'Não'
          }
        />
        <SerasaModalItem
          title="Endereço formatado"
          value={serasaData.registration.address.formattedAddress}
        />
        <SerasaModalItem
          title="Último endereço"
          value={serasaData.registration.address.latestAddress ? 'Sim' : 'Não'}
        />
        <SerasaModalItem
          title="Latitude"
          value={serasaData.registration.address.latitude.toString()}
        />
        <SerasaModalItem
          title="Longitude"
          value={serasaData.registration.address.longitude.toString()}
        />
        <SerasaModalItem
          title="Microregião"
          value={serasaData.registration.address.microRegion}
        />
        <SerasaModalItem
          title="Macrorregião"
          value={serasaData.registration.address.mregion}
        />
        <SerasaModalItem
          title="Município"
          value={serasaData.registration.address.municipality}
        />
        <SerasaModalItem
          title="Bairro"
          value={serasaData.registration.address.neighborhood}
        />
        <SerasaModalItem
          title="Número"
          value={serasaData.registration.address.number}
        />
        <SerasaModalItem
          title="Bairro original"
          value={serasaData.registration.address.originalNeighborhood}
        />
        <SerasaModalItem
          title="Precisão"
          value={serasaData.registration.address.precision}
        />
        <SerasaModalItem
          title="Região"
          value={serasaData.registration.address.region}
        />
        <SerasaModalItem
          title="Estado registrado"
          value={serasaData.registration.address.registryUf}
        />
        <SerasaModalItem
          title="Endereço residente"
          value={serasaData.registration.address.residentialAddress ? 'Sim' : 'Não'}
        />
        <SerasaModalItem
          title="Telefones"
          value={serasaData.registration.address.rfPhones
            .toString()
            .replaceAll(',', ', ')}
        />
        <SerasaModalItem
          title="Estado"
          value={serasaData.registration.address.uf}
        />
        <SerasaModalItem title="CEP" value={serasaData.registration.address.zip} />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Pendência Financeira - PEFIN (Resumo)
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade"
          value={serasa.pefin.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasa.pefin.summary.balance.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              /> */}
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Pendência Financeira - PEFIN
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasa.pefin.response.map(pefin => (
            <PersonPefinRefin
              key={`${pefin.contractId}-${pefin.legalNature}-${pefin.creditorName}`}
              data={pefin}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Restrições Financeiras - REFIN (Resumo)
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.refin.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasa.refin.summary.balance.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              /> */}
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Restrições Financeiras - REFIN
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasa.refin.response.map(refin => (
            <PersonPefinRefin
              key={`${refin.contractId}-${refin.legalNature}-${refin.creditorName}`}
              data={refin}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <OverdueDebtsSummary overdueDebtsSummary={serasa.overdueDebts.summary} />

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Dívidas Vencidas (Convem)
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa.overdueDebts.collectionRecordsResponse.map(debt => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${debt.contractId}-${debt.legalNature}-${debt.creditorName}`}
            >
              <SerasaModalItem
                title="Valor"
                value={debt.amount?.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem title="Cidade" value={debt.city} />
              <SerasaModalItem title="Id do Contrato" value={debt.contractId} />
              <SerasaModalItem
                title="Identificador instituição financeira"
                value={debt.creditorBusinessId}
              />
              <SerasaModalItem
                title="Nome da instituição financeira"
                value={debt.creditorName}
              />
              <SerasaModalItem
                title="Unidade Federativa"
                value={debt.federalUnit}
              />
              <SerasaModalItem
                title="Subjudice"
                value={debt.flagSubJudice ? 'Sim' : 'Não'}
              />
              <SerasaModalItem title="Natureza Jurídica" value={debt.legalNature} />
              <SerasaModalItem
                title="Id Natureza Jurídica"
                value={debt.legalNatureId}
              />
              <SerasaModalItem
                title="Data da Ocorrência"
                value={
                  debt.occurrenceDate &&
                  new Date(debt.occurrenceDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem
                title="Principal"
                value={debt.principal ? 'Sim' : 'Não'}
              />
              <SerasaModalItem
                title="Agencia publica"
                value={debt.publicAgency ? 'Sim' : 'Nao'}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Protestos - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.protests.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasa.protests.summary.balance.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              /> */}

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Protestos
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa.protests.response.map(protest => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${protest.city}-${protest.federalUnit}-${protest.occurrenceDate}-${protest.amount}`}
            >
              <SerasaModalItem
                title="Valor"
                value={protest.amount?.toLocaleString('pt-br', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem title="Cidade" value={protest.city} />
              <SerasaModalItem
                title="Unidade Federativa"
                value={protest.federalUnit}
              />
              <SerasaModalItem title="Nome escritório" value={protest.officeName} />
              <SerasaModalItem
                title="Número escritório"
                value={protest.officeNumber}
              />
              <SerasaModalItem
                title="Data de ocorrência"
                value={
                  protest.occurrenceDate &&
                  new Date(protest.occurrenceDate).toLocaleDateString('pt-br')
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Cheques Sem Fundo (CCF) - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.check.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasa.check.summary.balance.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              /> */}

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Cheques Sem Fundo (CCF)
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa.check.response.map(check => (
            <Check
              key={`${check.alinea}-${check.bankId}-${check.bankAgencyId}-${check.checkNumber}`}
              check={check}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Inquéritos - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasaData.facts.inquiry.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasaData.facts.inquiry.summary.balance.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              /> */}
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Inquéritos
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.facts.inquiry.inquiryResponse.map(inquiry => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${inquiry.occurrenceDate}-${inquiry.segmentDescription}`}
            >
              <SerasaModalItem
                title="Descrição Segmento"
                value={inquiry.segmentDescription}
              />

              <SerasaModalItem
                title="Data da ocorrência"
                value={
                  inquiry.occurrenceDate &&
                  new Date(inquiry.occurrenceDate).toLocaleDateString('pt-br')
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Documentos Roubados - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasaData.facts.stolenDocuments.summary.count.toString()}
        />
        {/* <SerasaModalItem
                title="Valor"
                value={serasaData.facts.stolenDocuments.summary.balance.toLocaleString(
                  'pt-BR',
                  {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  },
                )}
              /> */}
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Documentos Roubados
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.facts.stolenDocuments.stolenDocumentsResponse.map(
            document => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${document.occurrenceDate}-${document.segmentDescription}`}
              >
                <SerasaModalItem
                  title="Descrição Segmento"
                  value={document.segmentDescription}
                />

                <SerasaModalItem
                  title="Data da ocorrência"
                  value={
                    document.occurrenceDate &&
                    new Date(document.occurrenceDate).toLocaleDateString('pt-br')
                  }
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Anotações SPC - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade total"
          value={serasaData.optionalFeatures.spcAnnotations.spcNegative.spcNegativeResponse.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor total"
          value={serasaData.optionalFeatures.spcAnnotations.spcNegative.spcNegativeResponse.summary.balance.toLocaleString(
            'pt-BR',
            { currency: 'BRL', style: 'currency', minimumFractionDigits: 2 },
          )}
        />
        <SerasaModalItem
          title="Primeira ocorrência"
          value={
            serasaData.optionalFeatures.spcAnnotations.spcNegative
              .spcNegativeResponse.summary.firstOccurrence
          }
        />
        <SerasaModalItem
          title="Última ocorrência"
          value={
            serasaData.optionalFeatures.spcAnnotations.spcNegative
              .spcNegativeResponse.summary.lastOccurrence
          }
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Anotações SPC
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <SerasaModalItem
            title="Valor da anotação"
            value={serasaData.optionalFeatures.spcAnnotations.spcNegative.spcNegativeResponse.amount?.toLocaleString(
              'pt-BR',
              {
                currency: 'BRL',
                style: 'currency',
                minimumFractionDigits: 2,
              },
            )}
          />
          <SerasaModalItem
            title="Cidade"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.city
            }
          />
          <SerasaModalItem
            title="Identificador do contrato"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.contractId
            }
          />
          <SerasaModalItem
            title="Cidade da instituição financeira"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.creditorCity
            }
          />
          <SerasaModalItem
            title="Estado da instituição financeira"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.creditorFederalUnit
            }
          />
          <SerasaModalItem
            title="Nome da instituição financeira"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.creditorName
            }
          />
          <SerasaModalItem
            title="Estado"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.federalUnit
            }
          />
          <SerasaModalItem
            title="Data de ocorrência"
            value={
              serasaData.optionalFeatures.spcAnnotations.spcNegative
                .spcNegativeResponse.occurrenceDate &&
              new Date(
                serasaData.optionalFeatures.spcAnnotations.spcNegative.spcNegativeResponse.occurrenceDate,
              ).toLocaleDateString('pt-br')
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Atributos
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            Acessibilidade
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.affordability}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            Índice de pagamento dentro do prazo com cartão de crédito
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.creditCardOnTimePaymentIndex}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            Tendência de uso de cartão de crédito
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.creditCardUsageTrend}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            Renda estimada
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.estimatedIncome}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            Compromisso de Renda
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.incomeCommitment}
          />
        </Grid>
        <Grid container spacing={2}>
          <Typography fontWeight={700} fontSize={20}>
            índice de pagamentos em dia
          </Typography>
          <PersonAttribute
            {...serasaData.optionalFeatures.attributes.onTimePaymentsIndex}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Falências - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasaData.optionalFeatures.bankrupts.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasaData.optionalFeatures.bankrupts.summary.balance.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <SerasaModalItem
          title="Primeira ocorrência"
          value={new Date(
            serasaData.optionalFeatures.bankrupts.summary.firstOccurrence,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Última ocorrência"
          value={new Date(
            serasaData.optionalFeatures.bankrupts.summary.lastOccurrence,
          ).toLocaleDateString('pt-br')}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Falências
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.optionalFeatures.bankrupts.response.map(bankrupt => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${bankrupt.caseNumber}-${bankrupt.occurrenceDate}-${bankrupt.companyDocumentId}`}
            >
              <SerasaModalItem title="Estado" value={bankrupt.state} />
              <SerasaModalItem title="Cidade" value={bankrupt.city} />
              <SerasaModalItem
                title="Número do processo"
                value={bankrupt.caseNumber}
              />
              <SerasaModalItem
                title="Identificador documento da empresa"
                value={bankrupt.companyDocumentId}
              />
              <SerasaModalItem
                title="Nome da empresa"
                value={bankrupt.companyName}
              />
              <SerasaModalItem
                title="Data de inclusão"
                value={
                  bankrupt.inclusionDate &&
                  new Date(bankrupt.inclusionDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem
                title="Natureza Jurídica"
                value={bankrupt.legalNature}
              />
              <SerasaModalItem
                title="Data de ocorrência"
                value={
                  bankrupt.occurrenceDate &&
                  new Date(bankrupt.occurrenceDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem
                title="Posição empresa"
                value={bankrupt.positionCompany}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Limite de crédito e decisão
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Código"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.code.toString()}
        />
        <SerasaModalItem
          title="Mensagem"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.message}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={20}>
              Criterios Analizados
            </Typography>
          </Grid>
          <SerasaModalItem
            title="Documento ativo"
            value={
              serasaData.optionalFeatures.creditLimitAndDecision.query.data
                .analyzedCriteria.documentActive
                ? 'Sim'
                : 'Não'
            }
          />
          <SerasaModalItem
            title="Compromisso de baixa renda"
            value={
              serasaData.optionalFeatures.creditLimitAndDecision.query.data
                .analyzedCriteria.lowIncomeCommitment
                ? 'Sim'
                : 'Não'
            }
          />
          <SerasaModalItem
            title="Sem reclamação"
            value={
              serasaData.optionalFeatures.creditLimitAndDecision.query.data
                .analyzedCriteria.noProtests
                ? 'Sim'
                : 'Não'
            }
          />
          <SerasaModalItem
            title="Sem restrição"
            value={
              serasaData.optionalFeatures.creditLimitAndDecision.query.data
                .analyzedCriteria.noRestriction
                ? 'Sim'
                : 'Não'
            }
          />
        </Grid>
        <SerasaModalItem
          title="Cobrança"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data.billing
              ? 'Sim'
              : 'Não'
          }
        />
        <SerasaModalItem
          title="Nome do consumidor"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data
              .consumerName
          }
        />
        <SerasaModalItem
          title="Data de criação"
          value={new Date(
            serasaData.optionalFeatures.creditLimitAndDecision.query.data.creationDate,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Documento"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data.document
          }
        />
        <SerasaModalItem
          title="Numero da proposta"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data
              .proposalNumber
          }
        />
        <SerasaModalItem
          title="Tipo de recomendação de venda"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data
              .recommendationSaleType
          }
        />
        <SerasaModalItem
          title="Tipo de recomendação"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data
              .recommendationType
          }
        />
        <SerasaModalItem
          title="Valor limite recomendado"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.data.recommendedLimitValue.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <SerasaModalItem
          title="Código do nivel de risco"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.data.riskLevelCode.toString()}
        />
        <SerasaModalItem
          title="Tipo de venda"
          value={
            serasaData.optionalFeatures.creditLimitAndDecision.query.data.saleType
          }
        />
        <SerasaModalItem
          title="Código do tipo de venda"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.data.saleTypeCode.toString()}
        />
        <SerasaModalItem
          title="Valor da transação"
          value={serasaData.optionalFeatures.creditLimitAndDecision.query.data.transactionValue.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Arquivos de julgamento - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade"
          value={serasaData.optionalFeatures.judgementFilings.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasaData.optionalFeatures.judgementFilings.summary.balance.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <SerasaModalItem
          title="Primeira ocorrência"
          value={new Date(
            serasaData.optionalFeatures.judgementFilings.summary.firstOccurrence,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Última ocorrência"
          value={new Date(
            serasaData.optionalFeatures.judgementFilings.summary.lastOccurrence,
          ).toLocaleDateString('pt-br')}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Arquivos de julgamento
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.optionalFeatures.judgementFilings.response.map(judgment => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${judgment.occurrenceDate}-${judgment.legalNatureId}`}
            >
              <SerasaModalItem
                title="Valor"
                value={judgment.amount?.toLocaleString('pt-BR', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem
                title="Data disponível"
                value={
                  judgment.availableDate &&
                  new Date(judgment.availableDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem title="Número do caso" value={judgment.caseNumber} />
              <SerasaModalItem title="Cidade" value={judgment.city} />
              <SerasaModalItem title="Unidade Federativa" value={judgment.state} />
              <SerasaModalItem title="Tribunal civil" value={judgment.civilCourt} />
              <SerasaModalItem title="Distribuidor" value={judgment.distributor} />
              <SerasaModalItem
                title="Subjudice"
                value={judgment.flagSubJudice ? 'Sim' : 'Não'}
              />
              <SerasaModalItem
                title="Data de inclusão"
                value={
                  judgment.inclusionDate &&
                  new Date(judgment.inclusionDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem title="Código legal" value={judgment.legalNature} />
              <SerasaModalItem
                title="Identificador código legal"
                value={judgment.legalNatureId}
              />
              <SerasaModalItem title="Praça legal" value={judgment.legalSquare} />
              <SerasaModalItem
                title="Data de ocorrência"
                value={
                  judgment.occurrenceDate &&
                  new Date(judgment.occurrenceDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem
                title="Principal"
                value={judgment.principal ? 'Sim' : 'Não'}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Sócio - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade"
          value={serasaData.optionalFeatures.partner.summary.count.toString()}
        />

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Sócio
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.optionalFeatures.partner.partnerShipResponse.map(partner => (
            <Grid item xs={12} lg={6} key={partner.businessDocument}>
              <SerasaModalItem title="Documento" value={partner.businessDocument} />
              <SerasaModalItem
                title="Nome da empresa"
                value={partner.companyName}
              />
              <SerasaModalItem
                title="Estado da empresa"
                value={partner.companyState}
              />
              <SerasaModalItem
                title="Status da empresa"
                value={partner.companyStatus}
              />
              <SerasaModalItem
                title="Código status da empresa"
                value={partner.companyStatusCode}
              />
              <SerasaModalItem
                title="Data status da empresa"
                value={
                  partner.companyStatusDate &&
                  new Date(partner.companyStatusDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem
                title="Data inicio da sociedade"
                value={
                  partner.participationInitialDate &&
                  new Date(partner.participationInitialDate).toLocaleDateString(
                    'pt-br',
                  )
                }
              />
              <SerasaModalItem
                title="Porcentagem de participação"
                value={partner.participationPercentage?.toLocaleString('pt-BR', {
                  style: 'percent',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem
                title="Data atualização"
                value={
                  partner.updateDate &&
                  new Date(partner.updateDate).toLocaleDateString('pt-BR')
                }
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Histórico de pagamentos
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData.optionalFeatures.paymentsHistory.response.map(payment => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${payment.score}-${payment.range}-${payment.initialDayRange}-${payment.finalDayRange}`}
            >
              <SerasaModalItem title="Score" value={payment.score?.toString()} />
              <SerasaModalItem title="Range" value={payment.range} />
              <SerasaModalItem
                title="Dia inicial do range"
                value={payment.initialDayRange?.toString()}
              />
              <SerasaModalItem
                title="Dia final do range"
                value={payment.finalDayRange?.toString()}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Inquéritos - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade"
          value={serasaData.optionalFeatures.spcInquiries.summary.count.toString()}
        />
        <SerasaModalItem
          title="Quantidade atual"
          value={serasaData.optionalFeatures.spcInquiries.spcInquirySummary.spcInquiryQuantity.actual.toString()}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={18}>
              Quantidade de consultas de crédito
            </Typography>
          </Grid>
          <SerasaModalItem
            title="Data inquerito"
            value={new Date(
              serasaData.optionalFeatures.spcInquiries.creditInquiriesQuantity.inquiryDate,
            ).toLocaleDateString('pt-br')}
          />
          <SerasaModalItem
            title="Ocorrências"
            value={serasaData.optionalFeatures.spcInquiries.creditInquiriesQuantity.occurrences.toString()}
          />
        </Grid>
        <Grid container spacing={2}>
          {serasaData.optionalFeatures.spcInquiries.spcInquires.spcInquiriesResponse.map(
            inquiry => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${inquiry.occurrenceDate}-${inquiry.segmentDescription}`}
              >
                <SerasaModalItem
                  title="Descrição Segmento"
                  value={inquiry.segmentDescription}
                />
                <SerasaModalItem
                  title="Data da ocorrência"
                  value={
                    inquiry.occurrenceDate &&
                    new Date(inquiry.occurrenceDate).toLocaleDateString('pt-br')
                  }
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Serasa Score 2.0
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Pontuação do score"
          value={serasaData.score.score.toString()}
        />
        <SerasaModalItem
          title="Modelo do score"
          value={serasaData.score.scoreModel}
        />
        <SerasaModalItem title="Range" value={serasaData.score.range} />
        <SerasaModalItem
          title="Probabilidade de não pagamento"
          value={serasaData.score.defaultRate}
        />
        <SerasaModalItem
          title="Codigo da mensagem"
          value={serasaData.score.codeMessage.toString()}
        />
        <SerasaModalItem title="Mensagem" value={serasaData.score.message} />
      </Grid>
    </Grid>
  );
};

export { SerasaPersonModal };
