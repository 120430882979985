import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';

import { NotificationsQueryKey } from '../services';
import { useNotificationStore } from '../store/store';
import { useListNotificationsRequest } from './useGetConsultantNotificationsRequest';
import { useReadAllNotificationsRequest } from './useReadAllNotificationsRequest';

const useConsultantNotifications = () => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();

  const {
    page,
    setPage,
    setOpenNotificationsDialog,
    openNotificationsDialog,
  } = useNotificationStore();

  const {
    notifications,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
  } = useListNotificationsRequest(openNotificationsDialog);
  const readAllNotificationsRequest = useReadAllNotificationsRequest();

  const handleOpenNotificationsDialog = () => {
    setOpenNotificationsDialog(true);
  };

  const handleReadAllNotifications = () => {
    readAllNotificationsRequest.mutate();
    queryClient.invalidateQueries([NotificationsQueryKey.LIST_BY_CONSULTANT]);
  };

  const handleCloseNotificationsDialog = async () => {
    const hasUnreadItems = notifications.some(({ read }) => !read);
    if (hasUnreadItems) handleReadAllNotifications();

    setOpenNotificationsDialog(false);
  };

  useEffect(() => {
    if (inView) {
      setPage(page < 2 ? page + 1 : page);
      fetchNextPage();
    }
  }, [inView]);

  return {
    inView,
    notifications,
    isFetching,
    isLoading,
    isFetchingNextPage,
    openNotificationsDialog,
    handleOpenNotificationsDialog,
    handleCloseNotificationsDialog,
    ref,
  };
};

export { useConsultantNotifications };
