import { Grid, Box, TextField } from '@mui/material';

import {
  ButtonContained,
  ButtonOutlined,
  Dialog,
  ChipContained,
  ContainerFilters,
  MessageFilter,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { useFilterDatabase } from 'hooks';

import { OptionsValues } from 'utils/helper';
import { limitSomeDescription } from 'utils/limitSomeDescription';

import {
  IDatabaseFilter,
  IDatabaseFilterByOption,
  databaseFilterByOptions,
  pageFilter,
} from './filterDatabase.types';

interface DialogDatabaseProps {
  open: boolean;
  title: string;
  isFiltering: boolean;
  filterBy: IDatabaseFilterByOption;
  currentFilter: IDatabaseFilter[];
  pageFilter: pageFilter;
  setOpenFilterDialog: (open: boolean) => void;
  setFilterBy: (filterBy: IDatabaseFilterByOption) => void;
  resetFilter: () => void;
  handleRemoveFilter: (option: IDatabaseFilter) => void;
  clearSearchOption: () => void;
  handleChangesFilter: (value: OptionsValues[]) => void;
  setSearchOption: (search: string) => void;
  handleChangeSearch: (value: string) => void;
  setCurrentFilter: (filters: IDatabaseFilter[]) => void;
}

const DatabaseDialogFilter = ({
  open,
  title,
  isFiltering,
  filterBy,
  currentFilter,
  pageFilter,
  setOpenFilterDialog,
  setFilterBy,
  resetFilter,
  handleRemoveFilter,
  clearSearchOption,
  handleChangesFilter,
  handleChangeSearch,
  setCurrentFilter,
  setSearchOption,
}: DialogDatabaseProps) => {
  const { optionsFilter, isOptionEqualToValue } = useFilterDatabase({
    currentFilter,
    filterBy,
    openFilterDialog: open,
    setCurrentFilter,
    setOpenFilterDialog,
    setSearchOption,
    typeFilter: 'none',
  });

  const options =
    pageFilter === 'formAnswer'
      ? databaseFilterByOptions
      : databaseFilterByOptions.filter(op => op.label !== 'Pergunta');

  return (
    <Dialog.Root open={open} onClose={() => setOpenFilterDialog(false)}>
      <Dialog.Header title={title} />
      <Box width={650} alignItems={'center'}>
        <Dialog.Content>
          <ContainerFilters>
            <Grid container width={650} p={4} pb={3} pt={2} spacing={2}>
              <MessageFilter />
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={options}
                  renderInput={params => (
                    <TextField {...params} label="Filtrar por" />
                  )}
                  value={filterBy}
                  onChange={(e, value) =>
                    setFilterBy(value as IDatabaseFilterByOption)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={optionsFilter}
                  multiple={true}
                  loadingText="Buscando opções..."
                  noOptionsText="Nenhuma opção foi encontrada"
                  renderTags={() => null}
                  onClose={clearSearchOption}
                  filterSelectedOptions
                  onReset={resetFilter}
                  value={currentFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione uma opção"
                      onChange={e => handleChangeSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => {
                    handleChangesFilter(value as OptionsValues[]);
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  disableClearable
                />
              </Grid>
            </Grid>
          </ContainerFilters>
          <Grid>
            <Box
              marginLeft={3}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
              maxHeight={240}
              maxWidth={570}
              overflow={'auto'}
              pb={1}
            >
              {currentFilter.map((option, index) => (
                <ChipContained
                  key={`${option.label}-${index}`}
                  label={limitSomeDescription(option.label, 60)}
                  onDelete={() => handleRemoveFilter(option)}
                />
              ))}
            </Box>
          </Grid>
        </Dialog.Content>
      </Box>
      <SectionButtons>
        <ButtonOutlined
          label="Limpar filtros"
          onClick={resetFilter}
          disabled={!isFiltering}
        />
        <ButtonContained
          label="Fechar"
          onClick={() => setOpenFilterDialog(false)}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};

export { DatabaseDialogFilter };
