import { IEnterprise } from './enterpise.types';

export interface CompanyScr {
  document: string;
  lastConsultDate: string;
  scrTotalProducts: ScrTotalProducts[];
  scr: KleverScrUpdateInformation;
  documentOcrIndebtedness: KleverIndebtednessDocumentOcr | null;
  enterprise: IEnterprise;
}

export interface ScrTotalProducts extends ScrSummaryTO {
  amountInstitutions: number;
  amountOperations: number;
  consultDate: string;
  expiredVolume: number;
}

export interface KleverScrBacenInformation {
  cnpj: string;
  created: string;
  id: string;
  lastConsultDate: string;
  scr: ScrInformationTO[];
}

export interface ScrInformationTO {
  created: string;
  document: string;
  id: string;
  lastConsultDate: string;
  scr: ScrTO[];
}

export interface ScrTO {
  assumedObligation: number;
  consultDate: string;
  countInstitution: number;
  countOperation: number;
  countOperationDisagreement: number;
  countOperationSubJudice: number;
  created: null | string;
  find: boolean;
  operations: ResumeOperationTO[];
  percentDocumentProcessed: number;
  percentVolumeProcessed: number;
  responsibilityTotalDisagreement: number;
  responsibilityTotalSubJudice: number;
  startRelationshipDate: string;
  vendorIndirectRisk: number;
}

export interface ResumeOperationTO {
  earnings: EarningTO[];
  exchangeVariation: string;
  modality: string;
}

export interface EarningTO {
  code: string;
  value: number;
}

export interface KleverScrUpdateInformation {
  scrInformation: ScrInformationTO;
  scrMethodK: ScrMonthlySummaryTO[];
}

export interface ScrMonthlySummaryTO {
  amountInstitutions: number;
  amountOperations: number;
  consultDate: string;
  expiredVolume: number;
  sumaries: ScrSummaryTO[];
}

export interface Indebtedness {
  institution: string;
  totalCurrentDebts: number;
  untilSixMonths: number;
  betweenSixAndTwelveMonths: number;
  moreThanTwelveMonths: number;
}

export interface ScrSummaryTO {
  productCode: string;
  productName: string;
  total: number;
  totalBetween181and360d: number;
  totalBetween361and720d: number;
  totalBetween91and180d: number;
  totalGreaterThan720d: number;
  totalLessThan90d: number;
}

export interface KleverScrAuthorizationList {
  cancelledReason: null;
  cnpj: string;
  consultScr: boolean;
  cpf: null | string;
  email: string;
  id: number;
  name: string;
  state: string;
}

export interface KleverScrAuthorizationsStatePayload {
  id: string;
  state: State;
}

export enum State {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Progress = 'PROGRESS',
  Rejected = 'REJECTED',
  RevisionPending = 'REVISION_PENDING',
}

export interface KleverScrUpdateInformationPayload {
  amountOfMonthsDesired: string;
  document: string;
}

export interface KleverIndebtednessDocumentOcr {
  cnpj: string;
  created: string;
  documentDate: string;
  documentId: string;
  id: string;
  indebtednesses: KleverIndebtednessTO[];
  organizationId: string;
  total: number;
}

export interface KleverIndebtednessTO {
  guarantee: string;
  id: string;
  institution: string;
  tax: number;
  value: number;
}
