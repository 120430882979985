import { useQuery } from 'react-query';

import DashboardService from '../services/services';

export const useDashboardCreditorsRequest = () => {
  const { data, isLoading, refetch } = useQuery(
    ['dashboard_creditors_data'],
    () => DashboardService.getDashboardDataCreditors(),
    { keepPreviousData: true },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
