import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IPagination } from 'models';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { IDocumentResponse } from '../services';
import DocumentService from '../services/services';
import { useDocumentStore } from '../store/store';

export const useDocument = () => {
  const { limit, page, perPage, search, setPage, setLimit } = useDocumentStore();

  const { data: response, isLoading, refetch } = useQuery(
    ['document', page, search],
    () =>
      DocumentService.getDocuments({
        page,
        perPage,
        search,
      }) as Promise<IPagination<IDocumentResponse>>,
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;

  const showPagination = !noData || page !== DEFAULT_PAGINATION.page;

  useEffect(() => {
    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
  };
};
