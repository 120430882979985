import { IUser, Operation, OperationDestineStatus } from 'models';

export type IOperationFilterValues =
  | 'credor'
  | 'empresa'
  | 'responsavel'
  | 'ultimoAto'
  | 'ativo'
  | 'inativo';

export interface IOperationAppliedFilter {
  label: string;
  value: IOperationFilterValues;
}

export enum OperationQueryKey {
  UPDATE_STATUS = 'UPDATE_STATUS',
  INFINITE_OPERATIONS = 'INFINITE_OPERATIONS',
  LIST_OPTIONS_COMPANY_OPERATION_FILTER = 'LIST_OPTIONS_COMPANY_OPERATION_FILTER',
}

export enum IOperationFilterValue {
  'CREDITOR' = 'creditor',
  'ENTERPRISE' = 'enterprise',
  'RESPONSIBLE' = 'responsible',
  'OPERATION_TYPE' = 'operationType',
  'LAST_ACT' = 'lastAct',
  'STATUS' = 'status',
  'ACTIVE_INACTIVE' = 'active',
}

export enum IOperationFilterLabel {
  'CREDITOR' = 'Instituição financeira',
  'ENTERPRISE' = 'Empresa',
  'RESPONSIBLE' = 'Responsável',
  'OPERATION_TYPE' = 'Tipo de operação',
  'LAST_ACT' = 'Último ato',
  'STATUS' = 'Status',
  'ACTIVE_INACTIVE' = 'Ativo/Inativo',
}

export const inactiveOperations = [
  OperationDestineStatus.OPERATION_FINALIZED_COMPLETED,
  OperationDestineStatus.OPERATION_FINALIZED_MISSED,
];

export interface IOperationsFilter {
  label: string;
  value: number;
  filterBy: IOperationFilterValue;
}

export interface IOperationFilterOption {
  value: IOperationFilterValue;
  label: IOperationFilterLabel;
}

export const DEFAULT_OPERATIONS_FILTER = {
  value: IOperationFilterValue.CREDITOR,
  label: IOperationFilterLabel.CREDITOR,
};

export const DEFAULT_OPERATIONS_FILTER_CREDITOR = {
  value: IOperationFilterValue.ENTERPRISE,
  label: IOperationFilterLabel.ENTERPRISE,
};

export type OperationType = 'createAct' | 'changeStats';

export interface IOperationPayload {
  search?: string;
  page?: number;
  perPage?: number;
  active?: boolean[];
  enterpriseType?: string[];
}

export interface CustomerBigData {
  fantasyName: string;
  socialReason: string;
}

export interface IOperationResponse {
  id: string;
  enterprise: {
    id: string;
    fantasyName: string;
    isFinancing: boolean;
    bigData?: CustomerBigData;
  };
  creditor?: {
    id: string;
    fantasyName: string;
    bigData?: CustomerBigData;
  };
  responsible: IUser;
  type?: Operation;
  createdAt: Date;
  value?: number;
  acts: { act: { name: string } }[];
  updatedAt: Date;
  status: OperationDestineStatus;
}

export interface IUpdateOperationStatusPayload {
  operationIds: string[];
  newStatus: OperationDestineStatus;
}
