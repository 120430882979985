import { Grid, Typography } from '@mui/material';

import { KleverAddress } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  address?: KleverAddress;
}

export const BigDataAddress = ({ address }: Props) => {
  if (!address) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Endereço
        </Typography>
      </Grid>
      <BigDataItem title="Endereço 1" value={address.address1} />
      <BigDataItem title="Endereço 2" value={address.address1} />
      <BigDataItem
        title="Município de Fronteira"
        value={address.borderMunicipality}
      />
      <BigDataItem title="Tipode de Edificação" value={address.buildingType} />
      <BigDataItem title="Código do País" value={address.codeCountry} />
      <BigDataItem title="Código do Município" value={address.codeMunicipality} />
      <BigDataItem
        title="Edifício Coletivo"
        value={address.collectiveBuilding ? 'Sim' : 'Não'}
      />
      <BigDataItem title="País" value={address.country} />
      <BigDataItem
        title="Restrição de Entrega"
        value={address.deliveryRestriction ? 'Sim' : 'Não'}
      />
      <BigDataItem title="Endereço Formatado" value={address.formattedAddress} />
      <BigDataItem
        title="Último Endereço"
        value={address.latestAddress ? 'Sim' : 'Não'}
      />
      <BigDataItem title="Latitude" value={address.latitude?.toString()} />
      <BigDataItem title="Longitude" value={address.longitude?.toString()} />
      <BigDataItem title="Nome da Micro Região" value={address.microRegion} />
      <BigDataItem
        title="Nome da Micro Região Geográfica"
        value={address.mregion}
      />
      <BigDataItem title="Nome do Município" value={address.municipality} />
      <BigDataItem title="Nome do Bairro" value={address.neighborhood} />
      <BigDataItem title="Número" value={address.number} />
      <BigDataItem
        title="Nome do Bairro Original"
        value={address.originalNeighborhood}
      />
      <BigDataItem title="Nível de Precisão" value={address.precision} />
      <BigDataItem title="Nome da Região" value={address.region} />
      <BigDataItem title="UF" value={address.registryUf} />
      <BigDataItem
        title="Endereço Residencial"
        value={address.residentialAddress ? 'Sim' : 'Não'}
      />
      <BigDataItem
        title="Telefones Residenciais"
        value={address?.rfPhones?.toString()?.replaceAll(',', ', ')}
      />
      <BigDataItem title="UF" value={address.uf} />
      <BigDataItem title="CEP" value={address.zip} />
    </Grid>
  );
};
