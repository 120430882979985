import { CircularProgress } from '@mui/material';
import themes from 'themes';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Typography from 'components/Typography';

interface PropsOptionButtonNavBar {
  title: string;
  subTitle: string;
  icon: string;
  loading?: boolean;
  onClick?: () => void;
}

function OptionsButtonNavBar({
  title,
  subTitle,
  icon,
  loading = false,
  onClick,
}: PropsOptionButtonNavBar) {
  return (
    <Box
      height={112}
      backgroundColor={'#f0f2f2'}
      display={'flex'}
      flexDirection={'row'}
      padding={16}
      borderRadius={10}
      width={'100%'}
      opacity={'100%'}
      style={{
        gap: 12,
        cursor: loading ? 'not-allowed' : 'pointer',
        boxShadow: '0px 3px 5px #adb4b8',
        border: '1.5px solid #737373',
      }}
      onClick={() => !loading && onClick?.()}
      onMouseOver={e => {
        e.currentTarget.style.boxShadow = '0px 6px 12px #adb4b8';
      }}
      onMouseOut={e => {
        e.currentTarget.style.boxShadow = '0px 3px 5px #adb4b8';
      }}
    >
      <Box
        minWidth={80}
        height={80}
        borderRadius={10}
        backgroundColor={themes.colors.blue}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        {loading ? (
          <CircularProgress style={{ color: 'white' }} size={30} />
        ) : (
          <Icon fontSize="h2" color={'gray-100'}>
            {icon}
          </Icon>
        )}
      </Box>

      <Box display={'flex'} alignItems={'center'}>
        <Box minHeight={19.5}>
          <Typography
            fontWeight={700}
            fontSize={18}
            color={themes.colors.dashboard.table.text.black}
          >
            {title}
          </Typography>
          <Typography
            fontSize={14}
            color={themes.colors.dashboard.table.text.black}
          >
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default OptionsButtonNavBar;
