import { Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import { UserProfileWrapper, UserProfileAvatar } from './styles';

export function UserProfile() {
  const { user } = useAuth();

  return (
    <UserProfileWrapper as="section">
      <Typography color="white" textAlign="center" as="span">
        {user?.name}
      </Typography>

      <Typography color="gray-300" fontSize="m" textAlign="center" as="span">
        {user?.userType}
      </Typography>

      <UserProfileAvatar>
        <Typography color="white" fontSize="h5" as="span">
          {user?.name[0] ?? 'U'}
        </Typography>
      </UserProfileAvatar>
    </UserProfileWrapper>
  );
}
