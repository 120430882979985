import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { cpfValidation } from 'utils/helper';
import { validateCNPJ } from 'utils/validateCNPJ';

import { ICustomerRegisterPayload } from '../services';
import { useCustomerManagementStore } from '../store/store';

export const internalRegisterSchema = z
  .object({
    cnpj: z
      .string()
      .trim()
      .nonempty('CNPJ é obrigatório')
      .length(18, 'O CNPJ deve conter 14 dígitos')
      .refine(cnpj => validateCNPJ(cnpj), 'CNPJ inválido'),
    fantasyName: z.string().trim().nonempty('Nome fantasia é obrigatório'),
    name: z.string().trim().nonempty('Nome é obrigatório'),
    surname: z.string().trim().nonempty('Sobrenome é obrigatório'),
    cpf: z
      .string()
      .trim()
      .nonempty('CPF é obrigatório')
      .length(14, 'O CPF deve conter 11 dígitos')
      .refine(cpf => {
        return cpfValidation(cpf);
      }, 'CPF inválido'),
    birthDate: z.string().trim().nonempty('Data de nascimento é obrigatória'),
    email: z
      .string()
      .trim()
      .nonempty('E-mail é obrigatório')
      .email('E-mail inválido'),
    confirmEmail: z
      .string()
      .trim()
      .nonempty('Confirmação de E-mail é obrigatório')
      .email('E-mail inválido'),
    telephone: z.string().trim(),
    linkedin: z.string().trim(),
    site: z.string().trim().url('URL inválida').optional().or(z.literal('')),
    role: z.string().trim().nonempty('Selecione um cargo'),
    type: z.string().trim().nonempty('Selecione um tipo de usuário'),
    primaryEnterprise: z.string().trim().optional(),
  })
  .refine(({ email, confirmEmail }) => email === confirmEmail, {
    message: 'Confirmação de e-mail incorreta',
    path: ['confirmEmail'],
  });

export const useInternalRegisterForm = () => {
  const { customerData } = useCustomerManagementStore();

  const defaultValues = {
    birthDate: customerData.birthDate ?? '',
    cnpj: customerData.cnpj ?? '',
    fantasyName: customerData.fantasyName ?? '',
    confirmEmail: customerData.confirmEmail ?? '',
    cpf: customerData.cpf ?? '',
    email: customerData.email ?? '',
    linkedin: customerData.linkedin ?? '',
    name: customerData.name ?? '',
    role: customerData.role ?? '',
    site: customerData.site ?? '',
    surname: customerData.surname ?? '',
    telephone: customerData.telephone ?? '',
    type: customerData.type ?? '',
    primaryEnterprise: customerData.primaryEnterprise ?? '',
  };

  return useForm<ICustomerRegisterPayload>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(internalRegisterSchema),
  });
};
