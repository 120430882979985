import { AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const AccordionHeaderWrapper = styled(AccordionSummary)`
  && {
    padding: ${({ theme }) => theme.space[5]}px;

    .Mui-expanded {
      margin: 0;
    }
  }
`;
