import { FormStatus } from 'models';

import { useTabActsStore } from '../store/store';
import { ICreateAct } from './useCreateProcessActForm';

export const useGetStatusAct = () => {
  const {
    currentAct,
    currentProcessAct,
    attachmentsAnswer,
    scheduleLinkOpened,
    chargeLinkOpened,
    setScheduleLinkOpened,
    setChargeLinkOpened,
  } = useTabActsStore();

  const checkStatusByAnswers = (data: ICreateAct) => {
    const conf = currentAct.configs;

    if (currentProcessAct?.isChild && !currentProcessAct?.isConfigured)
      return false;

    const checkAnswer =
      conf?.textInputAnswer && (!data?.answer || data.answer.length === 0);

    const checkAnswerAttach =
      conf?.allowAnswerAttach && attachmentsAnswer.length === 0;

    const checkScheduleLink = conf?.showScheduleLink && !scheduleLinkOpened;

    const checkChargeLink = conf?.chargeLink && !chargeLinkOpened;

    if (conf?.triggerForm) {
      const formRecord = currentProcessAct.formRecord;

      if (formRecord.status === FormStatus.DRAFT) return false;
    }

    if (checkAnswer) return false;

    if (checkAnswerAttach) return false;

    if (checkScheduleLink) return false;

    if (checkChargeLink) return false;

    if (conf?.triggerDocSign) return false;

    // se chegou ate aqui é pq todas as condições pra fechar o ato estão válildas, retorna true;
    return true;
  };

  const getStatusActOnCreation = () => {
    const conf = currentAct.configs;

    if (conf?.triggerForm) return false;

    if (conf?.textInputAnswer) return false;

    if (conf?.defineProductClient) return false;

    if (conf?.createOp) return false;

    if (conf?.defineOp) return false;

    if (conf?.defineValueOp) return false;

    if (conf?.showScheduleLink) return false;

    if (conf?.allowCharge) return false;

    if (conf?.triggerDocSign) return false;

    if (conf?.allowAnswerAttach) return false;

    if (conf?.sendEmail) return false;

    // se chegou ate aqui é pq nao possui uma condição pra iniciar o ato como aberto, retorn true;
    return true;
  };

  return {
    checkStatusByAnswers,
    getStatusActOnCreation,
    setScheduleLinkOpened,
    setChargeLinkOpened,
  };
};
