import { useCustomerManagementStore } from '../store/store';

export const useCustomerDialog = () => {
  const {
    setOpenFormDialog,
    setHasSecondCnpj,
    openFormDialog,
  } = useCustomerManagementStore();

  const handleOpenFormDialog = async () => {
    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setOpenFormDialog(false);
    setHasSecondCnpj(false);
  };

  return {
    openFormDialog,
    handleOpenFormDialog,
    handleCloseFormDialog,
  };
};
