import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IAct } from 'models';

import { useActsStore } from '../store/store';
import { useUpdateActRequest } from './useUpdateActRequest';

export const useActItem = () => {
  const [valueCheckBox, setValueCheckBox] = useState(false);
  const {
    list,
    listSetInativeActs,
    openConfirmInactivesDialog,
    setListInativeActs,
    setOpenConfirmInactivesDialog,
  } = useActsStore();
  const queryClient = useQueryClient();

  const requestUpdateAct = useUpdateActRequest({
    onSuccess: () => {
      queryClient.invalidateQueries('acts');
    },
    onError: () => {
      toast.error('Houve um erro ao desativar o ato');
    },
  });

  const handleInactiveAct = (id: string) => {
    const actAlredySetted = listSetInativeActs.find(idAct => idAct === id);

    if (actAlredySetted) {
      const newList = listSetInativeActs.filter(id => id !== actAlredySetted);
      setListInativeActs(newList);
    } else {
      setListInativeActs([...listSetInativeActs, id]);
    }

    setValueCheckBox(!valueCheckBox);
  };

  const handleUpdateActs = () => {
    listSetInativeActs.map(id => {
      const actInactive = list.find(act => act.id === id);

      if (actInactive) {
        const act: IAct = {
          ...actInactive,
          active: false,
        };

        requestUpdateAct.mutate({
          actId: actInactive.id ?? '',
          configActId: actInactive.configs?.id ?? '',
          body: act,
        });
      }
    });

    setOpenConfirmInactivesDialog(false);
  };

  return {
    valueCheckBox,
    openConfirmInactivesDialog,
    handleInactiveAct,
    handleUpdateActs,
    setValueCheckBox,
    setOpenConfirmInactivesDialog,
  };
};

export type UseActItem = ReturnType<typeof useActItem>;
