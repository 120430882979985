import {
  Languages,
  UserTypes,
  IRolesTypes,
  UserPositions,
  RolesTypes,
} from 'models';
import { IPaginationParams } from 'models/pagination';
import { IUser } from 'models/user';

import { IOrdination } from 'utils/ordination';

export enum UserQueryKey {
  LIST_ALL = 'LIST_ALL',
  LIST_ALL_CREDITORS = 'LIST_ALL_CREDITORS',
  LIST_ALL_ENTERPRISES = 'LIST_ALL_ENTERPRISES',
  LIST_ALL_CONSULTANTS = 'LIST_ALL_CONSULTANTS',
}

export interface IUserPayload {
  name: string;
  surname: string;
  cpf: string;
  email: string;
  language: Languages;
  active: boolean;
  position: UserPositions;
  linkedin?: string;
  birthDate: Date | string;
  userType: UserTypes;
  role: string;
  enterprise?: string;
}

export interface IUserEdit extends IUserPayload {
  id?: string;
  password?: string;
}

export interface IUserUpdateRequest {
  id: string;
  user: IUserEdit;
}

export interface IUserFilters {
  search?: string;
  page?: number;
  perPage?: number;
  userType?: boolean;
  rolesTypes?: IRolesTypes[];
  active?: boolean;
  users?: number[];
}

export enum IUserFilterBy {
  'USERS_TYPE' = 'usersTypes',
  'ROLES_TYPE' = 'rolesTypes',
  'ACTIVE_INATIVE' = 'active',
}
export enum IUserFilterByLabel {
  'USERS_TYPES' = 'Tipo de Usuário',
  'ROLES_TYPES' = 'Perfil',
  'ACTIVE_INATIVE' = 'Ativo/Inativo',
}

export interface IUserFilter {
  label: string;
  value: number;
  filterBy: IUserFilterBy;
}

export interface IUserFilterByOption {
  value: IUserFilterBy;
  label: IUserFilterByLabel;
}

export const DEFAULT_USER_FILTER_BY = {
  value: IUserFilterBy.ROLES_TYPE,
  label: IUserFilterByLabel.ROLES_TYPES,
};

export const userFilterByOptions: IUserFilterByOption[] = [
  {
    value: IUserFilterBy.USERS_TYPE,
    label: IUserFilterByLabel.USERS_TYPES,
  },
  {
    value: IUserFilterBy.ROLES_TYPE,
    label: IUserFilterByLabel.ROLES_TYPES,
  },
  {
    value: IUserFilterBy.ACTIVE_INATIVE,
    label: IUserFilterByLabel.ACTIVE_INATIVE,
  },
];

export interface IFilterUserParams extends IPaginationParams, IOrdination<IUser> {
  search?: string;
  userTypes?: UserTypes[];
  rolesTypes?: RolesTypes[];
  isFinancing?: boolean;
  enterpriseId?: string;
  active?: boolean;
}

export interface IUserValidateParams {
  cnpj: string;
  cpf: string;
  email: string;
}

export interface IFindUserBranchesParams {
  email?: string;
  branchName?: string;
}

export enum UserTypesPayload {
  'Empresa' = 'ENTERPRISE',
  'Credor' = 'CREDITOR',
  'Instituição Financeira' = 'CREDITOR',
  'Consultor' = 'CONSULTANT',
}
