export const useMaskInputs = () => {
  const CNPJ_MASK = {
    DEFAULT: '00.000.000/0000-00',
  } as const;

  const DATE_MASK = {
    DEFAULT: '00/00/0000',
  } as const;

  const TELEPHONE_MASK = {
    DEFAULT: '(00) 0000-0000',
    ONE_MORE_DIGIT: '(00) 00000-0000',
  } as const;

  const CPF_MASK = {
    DEFAULT: '000.000.000-00',
  } as const;

  return {
    CNPJ_MASK,
    CPF_MASK,
    DATE_MASK,
    TELEPHONE_MASK,
  };
};

export type UseMaskInputs = ReturnType<typeof useMaskInputs>;
