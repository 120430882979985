/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  AutocompleteProps as MUIAutocompleteProps,
  TextField,
} from '@mui/material';
import {
  FieldError,
  FieldPath,
  FieldValues,
  Merge,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { OptionsValues } from 'utils/helper';

import { AutocompleteWrapper } from './styles';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: Merge<FieldError, FieldError[] | undefined>;
  label: string;
  stringValue?: boolean;
  options: OptionsValues[];
  onInputChange?: (value: string) => void;
  multiple?: boolean;
};

type AutocompleteProps = Omit<
  MUIAutocompleteProps<any, boolean, boolean, boolean>,
  'options' | 'renderInput' | 'onChange' | 'value'
>;

const Autocomplete = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  defaultValue,
  label,
  error,
  options,
  stringValue = false,
  onInputChange,
  multiple = false,
  ...rest
}: Omit<AutocompleteProps, 'error'> & Props<TFieldValues, TName>) => {
  const {
    field: { onChange, value, ...props },
  } = useController({ control, name, defaultValue });

  return (
    <AutocompleteWrapper
      options={options}
      value={value}
      freeSolo={false}
      fullWidth
      multiple={multiple}
      filterSelectedOptions
      onChange={(_, data: any) => {
        if (multiple) {
          onChange((data || []).map((item: any) => item.value || item));
          return;
        }
        if (stringValue) {
          // guarda o valor do label <OptionsValues>
          onChange(data?.label || '');
          return;
        }
        onChange(data?.value || '');
      }}
      getOptionLabel={(option: any) => {
        if (typeof option === 'number') {
          return options.find(item => item.value === option)?.label || '';
        }
        return option?.label || option;
      }}
      isOptionEqualToValue={(option: any, value: any) => {
        if (multiple) {
          return option.value === value;
        }
        if (stringValue) {
          return option.label === value;
        }
        return option.value === value.value;
      }}
      noOptionsText="Nenhum resultado encontrado"
      defaultValue={defaultValue}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={Boolean(!!error)}
          helperText={error && <>{error.message}</>}
          onChange={e => {
            if (onInputChange) {
              onInputChange(e.target.value);
            }
          }}
        />
      )}
      {...props}
      {...rest}
    />
  );
};

export default Autocomplete;
