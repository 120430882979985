import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { IAnswerOption, QuestionType } from 'models';

import { IQuestionPayload } from '../services';
import { useQuestionStore } from '../store/store';

export const useAnswerStep = () => {
  const [checkUpload, setCheckUpload] = useState(false);
  const [numbersAnswers, setNumbersAnswers] = useState(1);
  const [answerOptions, setAnswerOptions] = useState<IAnswerOption[]>([]);

  const { currentQuestion, dataQuestion, setDataQuestion } = useQuestionStore();

  useEffect(() => {
    if (
      currentQuestion?.answerOptions &&
      currentQuestion?.answerOptions.length > 0
    ) {
      setNumbersAnswers(currentQuestion?.answerOptions.length);
      setAnswerOptions(currentQuestion?.answerOptions);
    }

    setCheckUpload(currentQuestion?.acceptAttachment);
  }, [currentQuestion]);

  const handleChangeCheckUpload = () => {
    setCheckUpload(!checkUpload);
  };

  const setAnswersOptionsValue = (
    answer: string | number | boolean,
    index: number,
  ) => {
    answerOptions[index - 1] = { label: String(index), value: answer };
    setAnswerOptions([...answerOptions]);
  };

  const createOptionAnswer = () => {
    const checkCurrentQuestionAlreadyAnswered =
      answerOptions[numbersAnswers - 1] === undefined;

    if (checkCurrentQuestionAlreadyAnswered) {
      toast.info('Preencha a opção atual antes de gerar uma nova!');
      return;
    }
    setNumbersAnswers(numbersAnswers + 1);
  };

  const handleComplementDataQuestion = () => {
    let answer: IAnswerOption[];

    if (dataQuestion.type === QuestionType.MULTIPLE_CHOICE) {
      answer = [...answerOptions];
    } else {
      answer = [];
    }

    const acceptAttachment =
      dataQuestion?.type === QuestionType.UPLOAD
        ? true
        : checkUpload
        ? checkUpload
        : false;

    const complementedDataQuestion: IQuestionPayload = {
      ...dataQuestion,
      acceptAttachment,
      answerOptions: answer,
    };

    setDataQuestion(complementedDataQuestion);
  };

  return {
    checkUpload,
    numbersAnswers,
    dataQuestion,
    answerOptions,
    handleChangeCheckUpload,
    createOptionAnswer,
    setAnswersOptionsValue,
    handleComplementDataQuestion,
  };
};

export type UseAnswerStep = ReturnType<typeof useAnswerStep>;
