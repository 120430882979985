import { Divider, Grid, Typography } from '@mui/material';

import { ICompanyKMethod } from 'models';

import { KMethodItem } from './KMethodItem';

interface KMethodProps {
  storage: ICompanyKMethod;
}

const KMethod = ({ storage }: KMethodProps) => {
  return (
    <>
      <Grid xs={12} marginTop={2}>
        <Divider />

        <Typography fontWeight={700} fontSize={20} marginTop={1}>
          MÉTODO K
        </Typography>
      </Grid>

      <Grid container>
        <KMethodItem title="Total" value={storage.kMethod.total} />
        <KMethodItem title="Saldos" value={String(storage.kMethod.balances)} />
        <KMethodItem title="Registro" value={String(storage.kMethod.register)} />
        <KMethodItem title="Força" value={String(storage.kMethod.strength)} />
        <KMethodItem
          title="Habitualidade"
          value={String(storage.kMethod.habituality)}
        />
        <KMethodItem
          title="Demonstrativos"
          value={String(storage.kMethod.demonstrations)}
        />
        <KMethodItem
          title="Peso (registro)"
          value={String(storage.kMethod.registerWeight)}
        />
        <KMethodItem
          title="Peso (robustez)"
          value={String(storage.kMethod.robustnessWeight)}
        />
        <KMethodItem
          title="Peso (habitualidade)"
          value={String(storage.kMethod.habitualityWeight)}
        />
        <KMethodItem
          title="Quantidade de Funcionários"
          value={String(storage.kMethod.quantityEmployee)}
        />
      </Grid>

      <Grid xs={12} marginTop={1}>
        <Typography
          fontWeight={700}
          fontSize={20}
          color={'grey'}
          textTransform={'uppercase'}
        >
          Escala
        </Typography>
      </Grid>

      <Grid container>
        <KMethodItem title="Escala" value={storage.kMethod.scale.scale} />
        <KMethodItem title="Rating" value={storage.kMethod.scale.rating} />
        <KMethodItem
          title="Ação de operação"
          value={storage.kMethod.scale.operationAction}
        />
      </Grid>

      <Grid xs={12} marginTop={1}>
        <Typography
          fontWeight={700}
          fontSize={20}
          color={'grey'}
          textTransform={'uppercase'}
        >
          Operação Indicativa
        </Typography>
      </Grid>

      <Grid container>
        <KMethodItem
          title="Prazo"
          value={storage.kMethod.indicativeOperation.term}
        />
        <KMethodItem
          title="Volume"
          value={storage.kMethod.indicativeOperation.volume}
        />
        <KMethodItem
          title="Período de carência"
          value={storage.kMethod.indicativeOperation.gracePeriod}
        />
        <KMethodItem
          title="Breakeven Bruto"
          value={storage.kMethod.indicativeOperation.grossBreakeven}
        />
        <KMethodItem
          title="Breakeven Líquido"
          value={storage.kMethod.indicativeOperation.liquidBreakeven}
        />
        <KMethodItem
          title="Tipo de garantia"
          value={storage.kMethod.indicativeOperation.typeOfGuarantee}
        />
      </Grid>

      <Typography fontWeight={700}>Operações:</Typography>
      <Grid container>
        {Object.entries(storage.kMethod.indicativeOperation.operations).map(op => {
          if (typeof op[1] !== 'object') {
            return <KMethodItem key={op[1]} title={op[0]} value={String(op[1])} />;
          }

          return (
            <Grid container key={op[1]}>
              <Typography
                key={op[1]}
                fontWeight={700}
                fontSize={20}
                color="grey"
                textTransform={'uppercase'}
                marginTop={1}
              >
                {op[0]}
              </Typography>

              <Grid container>
                {Object.entries(op[1]).map(i => {
                  return (
                    <>
                      {typeof i[1] !== 'object' && (
                        <KMethodItem title={i[0]} value={String(i[1])} />
                      )}

                      {i[1] && (
                        <>
                          <Typography fontWeight={700}>{i[0]}</Typography>
                          {Object.entries(i[1] as { [s: string]: unknown }).map(
                            ([key, value]) => (
                              <KMethodItem
                                key={key}
                                title={String(key)}
                                value={String(value)}
                              />
                            ),
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export { KMethod };
