import { AxiosError } from 'axios';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IValidateParams, UserPositions, UserTypes } from 'models';

import { ApiUnauthorizedErrorsTitles, IApiError } from 'config/api/types';

import { IUserValidateParams, UserService } from 'modules/user';

import { enumToArray } from 'utils/enumToArray';

import { IEnterpriseValidateParams, SignUpEnterpriseService } from '../services';
import { useSignUpEnterpriseStore } from '../store/store';
import {
  useEnterpriseSignUpFindRoles,
  useSignUpEnterpriseForm,
  useSignUpEnterpriseRequest,
  useCheckExistDataRequest,
} from './';

interface UseSignUpEnterpriseProps {
  isFinancing?: boolean;
}

export const useSignUpEnterprise = ({ isFinancing }: UseSignUpEnterpriseProps) => {
  const history = useHistory();

  const form = useSignUpEnterpriseForm();
  const {
    acceptTerms,
    labelEmailButton,
    disableEmailButton,
    setAcceptTerms,
    setLabelEmailButton,
    setDisableEmailButton,
    setEnterprisePayload,
    enterprisePayload,
    clearSignupEnterpriseStore,
    isSigningUp,
    setIsSigningUp,
  } = useSignUpEnterpriseStore();

  const USER_POSITIONS = enumToArray(UserPositions);
  const ROLES_TYPES = useEnterpriseSignUpFindRoles();

  const valueFields = form.control._formValues;

  const validateUsedCNPJ = async (
    value: string,
    key: keyof IEnterpriseValidateParams,
  ): Promise<boolean> => {
    const params: IValidateParams<IEnterpriseValidateParams> = {
      value: value,
      key: key,
    };

    if (key === 'cnpj' && value.length < 18) return true;

    return await SignUpEnterpriseService.validate(params);
  };

  const validateUsedEmail = async (
    value: string,
    key: keyof IUserValidateParams,
  ): Promise<boolean> => {
    const params: IValidateParams<IUserValidateParams> = {
      value: value,
      key: key,
    };

    if (key === 'email' && !value.includes('@')) return true;

    return await UserService.validate(params);
  };

  useEffect(() => {
    form.formState.isValid
      ? setDisableEmailButton(false)
      : setDisableEmailButton(true);

    valueFields.confirmEmail.length > 0 && valueFields.email.length > 0
      ? setLabelEmailButton('VALIDAR EMAIL')
      : setLabelEmailButton('PREENCHER CAMPO EMAIL');
  }, [valueFields.email, valueFields.confirmEmail, form.formState.isValid]);

  const request = useCheckExistDataRequest({
    onSuccess: () => {
      signupRequest.mutate(enterprisePayload);

      clearSignupEnterpriseStore();
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.detail === 'This cnpj already used') {
        toast.error('Este CNPJ já possui um cadastro!');
      }

      if (error.response?.data.detail === 'This email already used') {
        toast.error('Este E-mail já possui um cadastro!');
      }
    },
  });

  const signupRequest = useSignUpEnterpriseRequest({
    onSuccess: () => {
      toast.success('Estamos validando o seu cadastro! Fique atento ao seu email.');
      history.push('/');
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === 'Invalid credentials') {
        toast.error('Email já utilizado!');
        return;
      }

      const detail = error.response?.data?.detail;

      const validationBigData =
        typeof detail !== 'string' &&
        detail?.error[0]?.title === ApiUnauthorizedErrorsTitles.INVALID_BIG_DATA;

      if (validationBigData) {
        toast.error('Não foi possível realizar o cadastro. Verifique seu email.');
        return;
      }

      toast.error('Houve um erro ao tentar finalizar o cadastro');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    setIsSigningUp(true);

    const isCnpjValid = await validateUsedCNPJ(data.cnpj, 'cnpj');
    if (!isCnpjValid) {
      toast.error('Este CNPJ já possui um cadastro!');
      return;
    }

    const isEmailValid = await validateUsedEmail(data.email, 'email');
    if (!isEmailValid) {
      toast.error('Este E-mail já possui um cadastro!');
      return;
    }

    const birthDate = moment(data.birthDate, 'DD/MM/YYYY HH:mm');

    const cnpjTreated = data.cnpj.replace(/[.\-/]/g, '');

    setEnterprisePayload({
      cnpj: cnpjTreated,
      name: data.name,
      fantasyName: data.fantasyName,
      surname: data.surname,
      cpf: data.cpf,
      birthDate: birthDate.toDate(),
      email: data.email,
      telephone: data.telephone,
      site: data.site,
      position: data.position,
      userType: UserTypes.ENTERPRISE,
      isFinancing: isFinancing,
    });

    request.mutate({ cnpj: cnpjTreated, cpf: data.cpf, email: data.email });
  });

  const handleCancelRegister = () => {
    history.push('/');
    setAcceptTerms(false);
  };

  return {
    acceptTerms,
    disableEmailButton,
    labelEmailButton,
    request,
    ROLES_TYPES,
    USER_POSITIONS,
    isSigningUp,
    setAcceptTerms,
    form: {
      handleSubmitForm,
      ...form,
    },
    handleCancelRegister,
  };
};

export type UseSignUpEnterprise = ReturnType<typeof useSignUpEnterprise>;
