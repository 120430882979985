import { stringify } from 'qs';

import api from 'config/api';

import { IListPendingIssuesPayload, ListPendingIssuesResponse } from './types';

export class PendingIssuesService {
  static async listForCustomers({ operationId }: IListPendingIssuesPayload) {
    const params = stringify({ operationId });

    const { data } = await api.get<ListPendingIssuesResponse>(
      `/pending-issue?${params}`,
    );

    return data;
  }

  static async listForConsultants({ enterpriseId }: IListPendingIssuesPayload) {
    const params = stringify({ enterpriseId });

    const { data } = await api.get<ListPendingIssuesResponse>(
      `/pending-issue/consultant?${params}`,
    );

    return data;
  }
}
