import { AxiosError } from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { UserTypes } from 'models';

import { IApiError } from 'config/api/types';
import { setTokens } from 'config/auth';
import { useAuth } from 'config/auth/hooks';

import { useLogout } from 'modules/SignOut';

import { signInErrorFeedback } from 'utils/errorFeedback';
import { OptionsValuesString } from 'utils/helper';

import { AuthService, ISelectedEnterprise } from '../services';
import { useSignInStore } from '../store/store';
import { useSignInForm } from './useSignInForm';
import { useSignInRequest } from './useSignInRequest';

export const useConsultantSignIn = () => {
  const {
    setStep,
    setSignInData,
    clearSignInData,
    signInData,
    step,
  } = useSignInStore();

  const { signIn } = useAuth();
  const { handleSignOut } = useLogout();

  const history = useHistory();

  const form = useSignInForm();

  const [enterprise, setEnterprise] = useState<ISelectedEnterprise>();
  const [enterprisesOptions, setEnterprisesOptions] = useState<
    OptionsValuesString[]
  >([]);

  const request = useSignInRequest({
    onSuccess: async data => {
      if (step == 2) {
        setTokens(data.accessToken, data.refreshToken);
        signIn(data);

        clearSignInData();
        return;
      }

      setTokens(data.accessToken, data.refreshToken);

      const options = await AuthService.enterprisesList();

      const enterpriseList: OptionsValuesString[] =
        options?.map(enterprise => ({
          label: enterprise.fantasyName,
          value: enterprise.id,
        })) || [];

      setEnterprisesOptions(enterpriseList);

      setStep();
    },
    onError: (error: AxiosError<IApiError>) => {
      signInErrorFeedback(error);
    },
  });

  const handleConsultantAccess = () => {
    history.push('/consultant');

    return;
  };

  const handleCancel = async () => {
    await handleSignOut();
    await clearSignInData();

    if (step === 1) {
      history.push('/');
    }
  };

  const handleSubmitForm = (origin: UserTypes) => {
    return form.handleSubmit(data => {
      setSignInData(data);

      request.mutate({
        email: data.email,
        password: data.password,
        origin,
      });
    });
  };

  const handleConsultantEnterprise = () => {
    request.mutate({
      ...signInData,
      enterpriseId: enterprise?.value,
    });
  };

  return {
    handleCancel,
    handleConsultantAccess,
    handleConsultantEnterprise,
    request,

    enterprise,
    setEnterprise,

    enterprises: {
      data: enterprisesOptions,
      isLoading: false,
    },

    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseConsultantSignIn = ReturnType<typeof useConsultantSignIn>;
