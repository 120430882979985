export enum SystemValueType {
  RELATIONSHIP = 'Relationship',
  NUMBER = 'Number',
  STRING = 'String',
  BOOLEAN = 'Boolean',
}

export interface ISystemValueMapping {
  id: string;
  title: string;
  table: string;
  column: string;
  valueType: SystemValueType;
}

export interface IGetValueVariableResponse {
  id: string;
  name: string;
  type: string | number | boolean | Date;
  value: string[] | number[] | boolean[];
}

export interface ISystemValueMappingInput {
  title: string | null;
}
