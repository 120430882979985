import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IAct } from 'models';
import { IPagination } from 'models/pagination/types';

import ActServices from '../services/services';
import { useActsStore } from '../store/store';

export const useFindActs = () => {
  const {
    page,
    perPage,
    setPage,
    setList,
    setLimit,
    limit,
    search,
  } = useActsStore();

  const { data, isLoading, refetch } = useQuery(
    ['acts', page, search],
    () =>
      ActServices.list({
        page,
        perPage,
        active: true,
        search: search,
      }) as Promise<IPagination<IAct>>,
    { keepPreviousData: true },
  );
  const noData = !data?.data || data.data.length === 0;
  const showPagination = !noData && data?.totalPages && data.totalPages > 1;
  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
      setList(data?.data);
    }
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
    noData,
    showPagination,
  };
};
