import { useMutation, UseMutationOptions } from 'react-query';

import { IUser } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUserEdit, UserService } from '../services';

const useCreateInternalUserRequest = (
  options?: UseMutationOptions<IUser, IApiAxiosError, IUserEdit>,
) => {
  return useMutation('createInternalUser', UserService.create, options);
};

export { useCreateInternalUserRequest };
