import { LoadingButton } from '@mui/lab';
import { Grid, TextField } from '@mui/material';
import { UseMutationResult } from 'react-query';
import themes from 'themes';

import { Box } from 'components';
import CustomPopper from 'components/Autocomplete/popper';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { IApiAxiosError } from 'config/api/types';

import { OptionsValuesString } from 'utils/helper';

import { ISelectedEnterprise, ISignInPayload, ISignInResponse } from '../services';

interface EnterpriseSelectProps {
  handleCancel: () => void;
  handleEnter: () => void;
  options: {
    data: OptionsValuesString[];
    isLoading: boolean;
  };
  enterprise: ISelectedEnterprise | undefined;
  setEnterprise: React.Dispatch<
    React.SetStateAction<ISelectedEnterprise | undefined>
  >;
  request: UseMutationResult<
    ISignInResponse,
    IApiAxiosError,
    ISignInPayload,
    unknown
  >;
}

function EnterpriseSelect({
  request,
  handleCancel,
  handleEnter,
  options,
  enterprise,
  setEnterprise,
}: EnterpriseSelectProps) {
  return (
    <Box as="form" width={'100%'}>
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <AutocompleteWrapper
            fullWidth
            PopperComponent={CustomPopper}
            ListboxProps={{
              style: {
                maxHeight: '200px',
                overflow: 'auto',
              },
            }}
            loading={options.isLoading}
            options={options.data}
            noOptionsText="Nenhuma empresa encontrada"
            value={enterprise}
            renderInput={params => (
              <TextField
                {...params}
                label="Selecione a empresa"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    zIndex: 0,
                    '& input': {
                      zIndex: 1,
                    },
                    '& fieldset': {
                      backgroundColor: '#e0e0e0',
                      border: 'none',
                      borderRadius: '8px',
                    },
                    '& .MuiAutocomplete-endAdornment': {
                      zIndex: 1,
                    },
                  },
                }}
              />
            )}
            onChange={(_, value) => setEnterprise(value as ISelectedEnterprise)}
          />
        </Grid>
      </Grid>
      <Box
        padding="1em"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          gap: '2em',
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={handleCancel}
          loading={request.isLoading}
          fullWidth
          sx={{
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,

            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          Cancelar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={handleEnter}
          loading={request.isLoading}
          fullWidth
          sx={{
            backgroundColor: themes.colors.text.primary,
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,
          }}
        >
          Entrar
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default EnterpriseSelect;
