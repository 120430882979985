import { Box, Grid, TextField } from '@mui/material';

import {
  ButtonContained,
  ButtonOutlined,
  ChipContained,
  ContainerFilters,
  Dialog,
  MessageFilter,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { OptionsValues } from 'utils/helper';

import { useOperationFilterForm } from '../hooks';
import { IOperationFilterOption } from '../services';

const OperationDialogFilter = () => {
  const {
    operationsFilterOptions,
    filterBy,
    setFilterBy,
    resetFilters,
    filterData,
    currentFilter,
    clearSearchOption,
    isFiltering,
    handleFilterChange,
    handleRemoveFilter,
    isOptionEqualToValue,
    openFilterDialog,
    setOpenFilterDialog,
    handleChangeSearch,
  } = useOperationFilterForm();

  return (
    <Dialog.Root open={openFilterDialog} onClose={() => setOpenFilterDialog(false)}>
      <Dialog.Header title="Filtros de operações" />
      <Box width={650} alignItems={'center'}>
        <Dialog.Content>
          <ContainerFilters>
            <Grid container maxWidth={650} p={4} pb={3} pt={2} spacing={2}>
              <MessageFilter />
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={operationsFilterOptions}
                  renderInput={params => (
                    <TextField {...params} label="Filtrar por" />
                  )}
                  value={filterBy}
                  onChange={(e, value) =>
                    setFilterBy(value as IOperationFilterOption)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={filterData}
                  multiple={true}
                  loadingText="Buscando opções..."
                  noOptionsText="Nenhuma opção foi encontrada"
                  renderTags={() => null}
                  onClose={clearSearchOption}
                  filterSelectedOptions
                  onReset={resetFilters}
                  value={currentFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione uma opção"
                      onChange={e => handleChangeSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => {
                    handleFilterChange(value as OptionsValues[]);
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  disableClearable
                />
              </Grid>
            </Grid>
          </ContainerFilters>

          <Grid>
            <Box
              marginLeft={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
              maxHeight={240}
              maxWidth={555}
              overflow={'auto'}
              pb={1}
            >
              {currentFilter.map((option, index) => (
                <ChipContained
                  key={`${option.label}-${index}`}
                  label={option.label}
                  onDelete={() => handleRemoveFilter(option)}
                />
              ))}
            </Box>
          </Grid>
        </Dialog.Content>
      </Box>
      <SectionButtons>
        <ButtonOutlined
          label="Limpar filtros"
          onClick={resetFilters}
          disabled={!isFiltering}
        />
        <ButtonContained
          label="Fechar"
          onClick={() => setOpenFilterDialog(false)}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};

export { OperationDialogFilter };
