import { Scrollbar, Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import { ChartsContainer, NavContainer } from '../components';

function HomePage() {
  const { user } = useAuth();

  return (
    <Scrollbar
      flex={1}
      display="flex"
      flexDirection="column"
      style={{
        gap: '2em',
      }}
      overflow={'auto'}
      maxHeight={'87vh'}
    >
      <Typography fontSize={30}>Olá, {user?.name}</Typography>
      <NavContainer />
      <ChartsContainer />
    </Scrollbar>
  );
}

export { HomePage };
