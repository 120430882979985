// import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from 'config/auth/hooks';

import { UserTypeHandleActPayload } from 'modules/backoffice/acts';
import { UserTypesPayload } from 'modules/user';

import { ProcessActQueryKeys, TabsService } from '../services';
import { useFilterActs } from './useFilterActs';
import { useTabs } from './useTabs';

export const useFilterRequest = (enabled: boolean) => {
  const { filterBy, currentFilter, filterParams } = useFilterActs();
  const { currentTabInfos } = useTabs();
  const { user } = useAuth();

  const { data: response, isLoading, refetch } = useQuery(
    [ProcessActQueryKeys.FILTER_PROCESS_ACTS, filterBy, currentFilter],
    () =>
      TabsService.getProcessActs({
        type: currentTabInfos.typeOp,
        enterpriseId: currentTabInfos.enterpriseId,
        operationId: currentTabInfos?.operationId,
        userType: UserTypesPayload[user?.userType ?? ''],
        userRole: UserTypeHandleActPayload[user?.role.name ?? ''],
        ...filterParams,
      }),
    {
      enabled,
      keepPreviousData: true,
    },
  );

  return {
    data: response,
    isLoading,
    refetch,
    noData: !response || response.length === 0,
  };
};
