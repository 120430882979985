import { useMutation, UseMutationOptions } from 'react-query';

import { IUser } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { DefinePasswordService, IDefinePasswordRequestPayload } from '../services';

const useDefinePasswordRequest = (
  options?: UseMutationOptions<
    IUser,
    IApiAxiosError,
    IDefinePasswordRequestPayload
  >,
) => {
  return useMutation('setPassword', DefinePasswordService.setPassword, options);
};

export { useDefinePasswordRequest };
