import { create } from 'zustand';

import { IAct, IFileStorage, IGetValueVariableResponse, IProcessAct } from 'models';

import { OptionsValuesString } from 'utils/helper';

import { ScreenCalled, TypeScreenFormProcessAct } from '../service';

type State = {
  openCreateActDialog: boolean;
  typeScreenForm: TypeScreenFormProcessAct;
  openModalOut: boolean;
  openDropzone: boolean;

  currentAct: IAct;
  currentProcessAct: IProcessAct;
  searchFormId: string;
  searchDbInfo: string[];
  productClient: string[];
  createOpValues: string[];
  operation: string;
  motiveEnd: string;

  attachmentsAct: IFileStorage[];
  attachmentsAnswer: IFileStorage[];

  variablesValues: IGetValueVariableResponse[];

  opEnterpriseOptions: OptionsValuesString[];

  actsOptions: OptionsValuesString[];
  processActs: IProcessAct[];
  processActsOptionsFilter: IProcessAct[];

  renderNewActOption: boolean;

  scheduleLinkOpened: boolean;
  chargeLinkOpened: boolean;

  screenCalled: ScreenCalled;
};

const initialState: State = {
  openCreateActDialog: false,
  typeScreenForm: 'create',
  openModalOut: false,
  openDropzone: false,

  currentAct: {} as IAct,
  currentProcessAct: {} as IProcessAct,
  searchFormId: '',
  searchDbInfo: [],
  productClient: [],
  createOpValues: [],
  operation: '',
  motiveEnd: '',

  attachmentsAct: [],
  attachmentsAnswer: [],

  variablesValues: [],

  opEnterpriseOptions: [],

  actsOptions: [],
  processActs: [],
  processActsOptionsFilter: [],

  renderNewActOption: false,

  scheduleLinkOpened: false,
  chargeLinkOpened: false,
  screenCalled: 'acts',
};

interface Actions {
  setOpenCreateActDialog: (openCreateActDialog: boolean) => void;
  setTypeScreenForm: (typeScreenForm: TypeScreenFormProcessAct) => void;
  setOpenModalOut: (openModalOut: boolean) => void;
  setOpenDropzone: (openDropzone: boolean) => void;

  setCurrentAct: (currentAct?: IAct | undefined) => void;
  setCurrentProcessAct: (currentProcessAct?: IProcessAct | undefined) => void;
  setSearchFormId: (searchFormId: string) => void;
  setSearchDbInfo: (searchDbInfo: string[]) => void;
  setProductClient: (productClient: string[]) => void;
  setCreateOpValues: (createOpValues: string[]) => void;
  setOperation: (operation: string) => void;
  setMotiveEnd: (motiveEnd: string) => void;

  setAttachmentsAct: (attachments: IFileStorage[]) => void;
  setAttachmentsAnswer: (attachmentsAnswer: IFileStorage[]) => void;

  setVariablesValues: (variablesValues: IGetValueVariableResponse[]) => void;

  setOpEnterprisesOptions: (ent: OptionsValuesString[]) => void;

  setActsOptions: (actsOptions: OptionsValuesString[]) => void;

  setProcessActs: (processActs: IProcessAct[]) => void;
  setProcessActsOptionsFilter: (processActsOptionsFilter: IProcessAct[]) => void;

  setRenderActOption: (renderNewActOption: boolean) => void;

  setScheduleLinkOpened: (scheduleLinkOpened: boolean) => void;
  setChargeLinkOpened: (chargeLinkOpened: boolean) => void;
  setScreenCalled: (screenCalled: ScreenCalled) => void;

  clearTabActsStore: () => void;
}

export const useTabActsStore = create<State & Actions>(set => ({
  ...initialState,

  setOpenCreateActDialog: openCreateActDialog => set({ openCreateActDialog }),
  setTypeScreenForm: typeScreenForm => set({ typeScreenForm }),
  setOpenModalOut: openModalOut => set({ openModalOut }),
  setOpenDropzone: openDropzone => set({ openDropzone }),

  setCurrentAct: currentAct => set({ currentAct }),
  setCurrentProcessAct: currentProcessAct => set({ currentProcessAct }),
  setSearchFormId: searchFormId => set({ searchFormId }),
  setSearchDbInfo: searchDbInfo => set({ searchDbInfo }),
  setProductClient: productClient => set({ productClient }),
  setCreateOpValues: createOpValues => set({ createOpValues }),
  setOperation: operation => set({ operation }),
  setMotiveEnd: motiveEnd => set({ motiveEnd }),

  setAttachmentsAct: attachmentsAct => set({ attachmentsAct }),
  setAttachmentsAnswer: attachmentsAnswer => set({ attachmentsAnswer }),

  setVariablesValues: variablesValues => set({ variablesValues }),

  setOpEnterprisesOptions: ent => set({ opEnterpriseOptions: ent }),

  setActsOptions: actsOptions => set({ actsOptions }),
  setProcessActs: processActs => set({ processActs }),
  setProcessActsOptionsFilter: processActsOptionsFilter =>
    set({ processActsOptionsFilter }),

  setRenderActOption: renderNewActOption => set({ renderNewActOption }),

  setScheduleLinkOpened: scheduleLinkOpened => set({ scheduleLinkOpened }),
  setChargeLinkOpened: chargeLinkOpened => set({ chargeLinkOpened }),
  setScreenCalled: screenCalled => set({ screenCalled }),

  clearTabActsStore: () => {
    set(state => ({ ...initialState, processActs: [...state.processActs] }));
  },
}));
