import { useHistory } from 'react-router-dom';

import { UserTypes } from 'models';

import { isAuthenticated } from 'config/auth';
import { useAuth, useSignOut } from 'config/auth/hooks';

import { useSignOutStore } from '../store/store';

const useLogout = () => {
  const { showDialog, setShowDialog } = useSignOutStore();
  const history = useHistory();
  const { user } = useAuth();
  const { signOut } = useSignOut();

  async function handleSignOut() {
    setShowDialog(false);

    if (isAuthenticated()) {
      await signOut();
    }

    if (
      user?.userType === UserTypes.ENTERPRISE ||
      user?.userType === UserTypes.CREDITOR
    ) {
      history.push('/');
    } else if (user?.userType === UserTypes.CONSULTANT) {
      history.push('/consultant');
    }
  }

  return {
    showDialog,
    setShowDialog,
    handleSignOut,
  };
};

export { useLogout };
