import { Box, Logo } from 'components';
import { UserTypes } from 'models';

import SignIn from '../components/SignIn';

export function SignInCreditorPage() {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <Logo />
      <SignIn title={UserTypes.CREDITOR} />
    </Box>
  );
}
