import { ReactNode } from 'react';

import { MenuWrapper } from './styles';

interface MenuProps {
  children: ReactNode;
}

export function Menu({ children }: MenuProps) {
  return <MenuWrapper>{children}</MenuWrapper>;
}
