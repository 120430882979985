import { useMutation, UseMutationOptions } from 'react-query';

import { IFormQuestion } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { FormService } from '../services/services';

const useGetFormQuestionRequest = (
  options?: UseMutationOptions<IFormQuestion[], IApiAxiosError, string>,
) => {
  return useMutation('getFormQuestions', FormService.getFormQuestions, options);
};

export { useGetFormQuestionRequest };
