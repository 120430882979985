import { create } from 'zustand';

import { IUserEdit } from 'modules/user/services';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { IConsultantFilter, IConsultantFilterBy } from '../services';

interface Store {
  // list filters
  search: string;
  setSearch: (search: string) => void;

  // pagination
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  limit: boolean;
  setLimit: (limit: boolean) => void;

  // filter Dialog
  openFilterDialog: boolean;
  setOpenFilterDialog: (open: boolean) => void;
  searchOption: string;
  setSearchOption: (searchCountry: string) => void;
  currentFilter: IConsultantFilter[];
  setCurrentFilter: (filters: IConsultantFilter[]) => void;
  filterBy: IConsultantFilterBy;
  setFilterBy: (filterBy: IConsultantFilterBy) => void;

  // form dialog
  openFormDialog: boolean;
  setOpenFormDialog: (open: boolean) => void;
  currentConsultant: IUserEdit;
  setCurrentConsultant: (user?: IUserEdit | undefined) => void;
}

export const useConsultantsStore = create<Store>(set => ({
  page: DEFAULT_PAGINATION.page,
  setPage: page => set({ page }),
  perPage: DEFAULT_PAGINATION.perPage,
  setPerPage: perPage => set({ perPage }),
  limit: false,
  setLimit: limit => set({ limit }),

  search: '',
  setSearch: search => set({ search }),

  openFilterDialog: false,
  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  searchOption: '',
  setSearchOption: searchOption => set({ searchOption }),
  currentFilter: [],
  setCurrentFilter: currentFilter => set({ currentFilter }),
  filterBy: 'Ativo/Inativo',
  setFilterBy: filterBy => set({ filterBy }),

  openFormDialog: false,
  setOpenFormDialog: openFormDialog => set({ openFormDialog }),
  currentConsultant: {} as IUserEdit,
  setCurrentConsultant: currentConsultant => set({ currentConsultant }),
}));
