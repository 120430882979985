import { create } from 'zustand';

import { IForm } from 'models';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { IFormFilter, IFormFilterBy, IQuestionForm } from '../services';

interface Store {
  // list filters
  search: string;
  setSearch: (search: string) => void;

  // list filters
  renderButtonSimulate: boolean;
  setRenderButtonSimulate: (renderButtonSimulate: boolean) => void;

  // pagination
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  limit: boolean;
  setLimit: (limit: boolean) => void;
  list: IForm[];
  setList: (list: IForm[]) => void;

  // filter Dialog
  openFilterDialog: boolean;
  setOpenFilterDialog: (open: boolean) => void;
  searchOption: string;
  setSearchOption: (searchCountry: string) => void;
  currentFilter: IFormFilter[];
  setCurrentFilter: (filters: IFormFilter[]) => void;
  filterBy: IFormFilterBy;
  setFilterBy: (filterBy: IFormFilterBy) => void;

  // form ConfigForm
  currentForm: IForm;
  setCurrentForm: (question?: IForm | undefined) => void;
  questionsForm: IQuestionForm[];
  setQuestionsForm: (questionsForm: IQuestionForm[]) => void;
  originalsQuestionsForm: IQuestionForm[]; // vai servir apenas pra comparar quais questões foram alteradas
  setOriginalsQuestionsForm: (originalsQuestionsForm: IQuestionForm[]) => void;
  searchQuestion: string;
  setSearchQuestion: (searchQuestion: string) => void;

  // Dialog Confirm Duplicate
  idFormDuplicate: string;
  setIdFormDuplicate: (idFormDuplicate: string) => void;
  openConfirmDuplicateDialog: boolean;
  setOpenConfirmDuplicateDialog: (open: boolean) => void;

  // Dialog Confirm Inactives (armazenando ids' pra inativar)
  listSetInativeForms: string[];
  setListSetInativeForms: (listSetInativeForms: string[]) => void;
  openConfirmInactivesDialog: boolean;
  setOpenConfirmInactivesDialog: (open: boolean) => void;
}

export const useFormStore = create<Store>(set => ({
  page: DEFAULT_PAGINATION.page,
  setPage: page => set({ page }),
  perPage: DEFAULT_PAGINATION.perPage,
  setPerPage: perPage => set({ perPage }),
  limit: false,
  setLimit: limit => set({ limit }),
  list: [] as IForm[],
  setList: list => set({ list }),

  search: '',
  setSearch: search => set({ search }),

  renderButtonSimulate: false,
  setRenderButtonSimulate: renderButtonSimulate => set({ renderButtonSimulate }),

  openFilterDialog: false,
  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  searchOption: '',
  setSearchOption: searchOption => set({ searchOption }),
  currentFilter: [],
  setCurrentFilter: currentFilter => set({ currentFilter }),
  filterBy: 'Ativo/Inativo',
  setFilterBy: filterBy => set({ filterBy }),

  currentForm: {} as IForm,
  setCurrentForm: currentForm => set({ currentForm }),
  questionsForm: [],
  setQuestionsForm: questionsForm => set({ questionsForm }),
  originalsQuestionsForm: [],
  setOriginalsQuestionsForm: originalsQuestionsForm =>
    set({ originalsQuestionsForm }),
  searchQuestion: '',
  setSearchQuestion: searchQuestion => set({ searchQuestion }),

  idFormDuplicate: '',
  setIdFormDuplicate: (idFormDuplicate: string) => set({ idFormDuplicate }),
  openConfirmDuplicateDialog: false,
  setOpenConfirmDuplicateDialog: openConfirmDuplicateDialog =>
    set({ openConfirmDuplicateDialog }),

  listSetInativeForms: [],
  setListSetInativeForms: (listSetInativeForms: string[]) =>
    set({ listSetInativeForms }),
  openConfirmInactivesDialog: false,
  setOpenConfirmInactivesDialog: openConfirmInactivesDialog =>
    set({ openConfirmInactivesDialog }),
}));
