import { Grid, Typography } from '@mui/material';

import { Dialog, ButtonContained, ButtonOutlined } from 'components';

interface ConfirmInactiveDialogProps {
  showDialog: boolean;
  textInfo: string;
  setShowDialog: (showDialog: boolean) => void;
  handleUpdate: () => void;
}

export default function DialogConfirmInactives({
  showDialog,
  textInfo,
  setShowDialog,
  handleUpdate,
}: ConfirmInactiveDialogProps) {
  return (
    <Dialog.Root open={showDialog} onClose={() => setShowDialog(false)}>
      <Dialog.Header title={'Desativar'} />
      <Typography textAlign={'center'} mt={4}>
        {textInfo}
      </Typography>
      <Grid
        container
        width={600}
        pt={3}
        pl={5}
        pr={5}
        spacing={2}
        textAlign={'center'}
      >
        <Grid item xs={9} alignContent={'center'}>
          <Dialog.Content>
            <Dialog.Footer>
              <ButtonOutlined
                label="Cancelar"
                onClick={() => setShowDialog(false)}
              />
              <ButtonContained label="Confirmar" onClick={() => handleUpdate()} />
            </Dialog.Footer>
          </Dialog.Content>
        </Grid>
      </Grid>
    </Dialog.Root>
  );
}
