import styled from 'styled-components';

import Box from 'components/Box';

const UserProfileWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.colors['gray-700']};
  padding-top: ${({ theme }) => theme.space[6]}px;
  margin-bottom: ${({ theme }) => theme.space[9]}px;
`;

const UserProfileAvatar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 88px;
  border-radius: 100%;
  margin-top: ${({ theme }) => theme.space[1]}px;
  margin-bottom: -44px;
  background-color: ${({ theme }) => theme.colors['gray-300']};
`;

export { UserProfileWrapper, UserProfileAvatar };
