import { IconButton, TableCell } from '@mui/material';
import { useState } from 'react';
import themes from 'themes';

import { Icon, TableRow, Typography, TypographyActive } from 'components';
import { inactiveClients } from 'models';
import { CompanyScr } from 'models/enterprise/scr.types';

import { limitSomeDescription } from 'utils/limitSomeDescription';
import { renderCnpjMask } from 'utils/renderCNPJMask';

import { ScrModal } from './ScrModal';
import ScrModalData from './ScrModalData';

const { colors } = themes;

const ScrItem = (scr: CompanyScr) => {
  const [open, setOpen] = useState(false);

  const inactive = inactiveClients.includes(scr.enterprise?.status);
  const companyName =
    scr.enterprise?.bigData?.fantasyName ||
    scr.enterprise?.bigData?.socialReason ||
    '---';

  return (
    <>
      <ScrModal open={open} setOpen={setOpen}>
        <ScrModalData scr={scr} />
      </ScrModal>
      <TableRow>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {renderCnpjMask(scr.document)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {companyName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {scr.documentOcrIndebtedness?.total?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            }) ?? '---'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {limitSomeDescription(scr.enterprise?.status, 45)}
          </Typography>
        </TableCell>
        <TableCell>
          <TypographyActive
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
            backgroundColor={inactive ? colors.error : colors.green}
          >
            {inactive ? 'Inativo' : 'Ativo'}
          </TypographyActive>
        </TableCell>
        <TableCell
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(prev => !prev)}
          >
            <Icon color="gray-300">open_in_new_icon</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export { ScrItem };
