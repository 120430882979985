import { useFilterDatabase } from 'hooks';

import { useStorageStore } from '../store/store';

export const useStorageFilter = () => {
  const {
    currentFilter,
    filterBy,
    openFilterDialog,
    setCurrentFilter,
    setOpenFilterDialog,
    setSearchOption,
    setFilterBy,
  } = useStorageStore();

  // utiliza o hook "componente" dos filtros database
  const {
    isFiltering,
    filterParams,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    handleChangesFilter,
    handleRemoveFilter,
    resetFilter,
    clearSearchOption,
  } = useFilterDatabase({
    currentFilter,
    filterBy,
    openFilterDialog,
    setCurrentFilter,
    setOpenFilterDialog,
    setSearchOption,
    typeFilter: 'storage',
  });

  return {
    currentFilter,
    isFiltering,
    filterBy,
    open: openFilterDialog,
    filterParams,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    setOpenFilterDialog,
    setSearchOption,
    setFilterBy,
    resetFilter,
    handleRemoveFilter,
    clearSearchOption,
    handleChangesFilter,
    setCurrentFilter,
  };
};
