import { Popover } from '@mui/material';
import styled from 'styled-components';

const RootWrapper = styled(Popover)`
  & .MuiPaper-root {
    width: 300px;
    border: 1px solid ${({ theme }) => theme.colors['gray-100']};
  }
`;

export { RootWrapper };
