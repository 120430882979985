import breakpoints from './breakpoints';

export type Colors =
  | 'black'
  | 'dark'
  | 'white'
  | 'blue'
  | 'blue-dark'
  | 'navy'
  | 'gray-100'
  | 'gray-300'
  | 'gray-500'
  | 'gray-700'
  | 'error'
  | 'text.primary'
  | 'success';

export default {
  name: 'default',
  colors: {
    black: '#000e1a',
    dark: '#1a1a1a',
    white: '#fff',
    blue: '#0261ad',
    'blue-dark': '#002B6A',
    navy: '#0261ad',
    'gray-100': '#e0e0e0',
    'gray-300': '#bdbdbd',
    'gray-500': '#546e7a',
    'gray-700': '#343A48',
    error: '#f44336',
    green: '#4e9a51',

    text: {
      primary: '#0261ad',
    },
    dashboard: {
      table: {
        header: {
          main: '#515151',
          secondary: '#0261ad',
        },
        body: {
          secondary: '#C4C5D7',
          tertiary: '#e7e9f2',
          quarternary: '#c4c5d7',
        },
        text: {
          black: '#000000',
        },
      },
      graphs: {
        'gray-medium': '#999999',
      },
    },
  },
  fonts: {
    body: '"Inter", sans-serif',
  },
  fontSizes: {
    xs: 10,
    s: 12,
    m: 14,
    l: 16,
    h6: 18,
    h5: 24,
    h4: 30,
    h3: 32,
    h2: 36,
    h1: 48,
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  lineHeights: {
    s: 1,
    m: 1.1,
    l: 1.2,
    xl: 1.3,
    xxl: 1.4,
    xxxl: 1.5,
  },
  space: [0, 2, 4, 8, 12, 16, 24, 32, 40, 48],
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
    md: `@media screen and (min-width: ${breakpoints[1]})`,
    lg: `@media screen and (min-width: ${breakpoints[2]})`,
    xl: `@media screen and (min-width: ${breakpoints[3]})`,
  },
};
