import { Box, Step, StepButton, Stepper } from '@mui/material';

import { useQuestionDialog, useQuestionsSteppper } from '../../hooks';
import { AnswerStep } from './AnswerStep';
import { DataStep } from './DataStep';
import { QuestionStepForm } from './QuestionStep';

export default function StepperDialog() {
  const {
    formQuestion,
    steps,
    activeStep,
    completed,
    handleStep,
    handleComplete,
  } = useQuestionsSteppper();

  const { form } = useQuestionDialog();
  return (
    <Box
      sx={{ width: '100%' }}
      minWidth={750}
      maxWidth={750}
      minHeight={350}
      position={'relative'}
      padding={0}
    >
      <Box display={'flex'} justifyContent={'center'} padding={0} pt={4}>
        <Box minWidth={500} padding={0}>
          <Stepper nonLinear activeStep={activeStep}>
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepButton color="inherit" onClick={handleStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      {activeStep === 0 && (
        <QuestionStepForm form={formQuestion} handleComplete={handleComplete} />
      )}

      {activeStep === 1 && <AnswerStep handleComplete={handleComplete} />}

      {activeStep === 2 && <DataStep form={form} />}
    </Box>
  );
}
