import { toast } from 'react-toastify';

import { useForgotPasswordStore } from '../store/store';
import { useForgotPasswordCodeForm } from './useForgotPasswordCodeForm';
import { useForgotPasswordCodeRequest } from './useForgotPasswordCodeRequest';

export const useForgotPasswordCode = () => {
  const form = useForgotPasswordCodeForm();
  const {
    setCodeValidated,
    setForgotPasswordPayload,
    forgotPasswordPayload,
    setStep,
  } = useForgotPasswordStore();

  const request = useForgotPasswordCodeRequest({
    onSuccess: () => {
      setCodeValidated(true);
      setStep();
    },
    onError: () => {
      toast.error('Código inválido. Verifique e tente novamente.');
    },
  });

  const handleSubmitForm = form.handleSubmit(data => {
    request.mutate({
      code: data.code,
    });

    setForgotPasswordPayload({
      ...forgotPasswordPayload,
      code: data.code,
    });
  });

  return {
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseForgotPasswordCode = ReturnType<typeof useForgotPasswordCode>;
