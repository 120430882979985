import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const LoaderWrapper = styled(CircularProgress)`
  && {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    color: ${props => props.theme.colors['gray-300']};
  }
`;

export { LoaderWrapper };
