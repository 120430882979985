import { IconButton, TableCell } from '@mui/material';
import { useState } from 'react';
import themes from 'themes';

import { Icon, TableRow, Typography, TypographyActive } from 'components';
import { ICompanyKMethod, inactiveClients } from 'models';

import { renderCnpjMask } from 'utils/renderCNPJMask';

import { KMethodModal } from './KMethodModal';

const { colors } = themes;

const KMethodListItem = (storage: ICompanyKMethod) => {
  const [open, setOpen] = useState(false);

  const inactive = inactiveClients.includes(storage?.enterprise?.status);
  const emptyString = '---';
  const companyName =
    storage?.enterprise?.bigData?.fantasyName ||
    storage?.enterprise?.bigData?.socialReason;
  const alerts = storage.vetoesAndAlerts?.alerts.reduce(
    (acc, curr) => acc + (curr?.score ?? 0),
    0,
  );

  return (
    <>
      <KMethodModal
        storage={storage}
        open={open}
        closeDialog={() => setOpen(false)}
      />
      <TableRow key={storage.cnpj}>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {renderCnpjMask(storage.cnpj)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {companyName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.kScale}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {alerts ?? emptyString}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.enterprise.status || emptyString}
          </Typography>
        </TableCell>
        <TableCell width={'60px'}>
          <TypographyActive
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
            backgroundColor={inactive ? colors.error : colors.green}
          >
            {inactive ? 'Inativo' : 'Ativo'}
          </TypographyActive>
        </TableCell>
        <TableCell
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(true)}
          >
            <Icon color="gray-300">open_in_new_icon</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export { KMethodListItem };
