import { IConditionTypeInput, IVariable } from '../services';
import { useFormStore } from '../store/store';

export const useCardQuestion = () => {
  const { questionsForm, setQuestionsForm } = useFormStore();

  function handleAddCondition(identifierQuestion: number) {
    const indexQuestionForm = identifierQuestion - 1;

    const conditions = questionsForm[indexQuestionForm]?.conditions;
    if (!conditions) {
      questionsForm[indexQuestionForm].conditions = [
        {
          identifierCondition: 1,
          condition: '',
          conditionTypeInput: { identifierInput: 1 } as IConditionTypeInput,
          variables: [{ identifierVariable: 1 }] as IVariable[],
          openDropdown: true,
          showOptionFalse: false,
        },
      ];
      setQuestionsForm(questionsForm);
      return;
    }
    const lengthConditions = questionsForm[indexQuestionForm]?.conditions.length;

    questionsForm[indexQuestionForm].conditions = [
      ...questionsForm[indexQuestionForm].conditions,
      {
        identifierCondition: lengthConditions + 1,
        condition: '',
        conditionTypeInput: {
          identifierInput: lengthConditions + 1,
        } as IConditionTypeInput,
        variables: [{ identifierVariable: 1 }] as IVariable[],
        openDropdown: true,
        showOptionFalse: false,
      },
    ];
    setQuestionsForm(questionsForm);
    return;
  }

  function handleChangeSwitchIsFirstQuestion(identifierQuestion: number) {
    const indexQuestionForm = identifierQuestion - 1;
    const currentStateSwitch = questionsForm[indexQuestionForm]?.isFirstQuestion;

    questionsForm[indexQuestionForm].isFirstQuestion = !currentStateSwitch;
    setQuestionsForm(questionsForm);
  }

  function handleChangeSwitchIsLastQuestion(identifierQuestion: number) {
    const indexQuestionForm = identifierQuestion - 1;
    const currentStateSwitch = questionsForm[indexQuestionForm]?.isLastQuestion;

    questionsForm[indexQuestionForm].isLastQuestion = !currentStateSwitch;
    setQuestionsForm(questionsForm);
  }

  return {
    handleAddCondition,
    handleChangeSwitchIsFirstQuestion,
    handleChangeSwitchIsLastQuestion,
  };
};

export type UseCardConditionMultipleChoice = ReturnType<typeof useCardQuestion>;
