import { Next } from './Next';
import { Previous } from './Previous';
import { Root } from './Root';

export interface PaginationInterface {
  onSetStep: (value: number) => void;
  step: number;
  limit: boolean;
}

function Pagination({ onSetStep, step, limit }: PaginationInterface) {
  return (
    <Root step={step}>
      <Next onClick={() => !limit && onSetStep(step + 1)} />
      <Previous onClick={() => step > 0 && onSetStep(step - 1)} />
    </Root>
  );
}

export default Pagination;
