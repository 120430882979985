import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  IUpdateOperationStatusPayload,
  OperationQueryKey,
  OperationService,
} from '../services';

const useUpdateOpStatusRequest = (
  options?: UseMutationOptions<
    unknown,
    IApiAxiosError,
    IUpdateOperationStatusPayload
  >,
) => {
  return useMutation(
    OperationQueryKey.UPDATE_STATUS,
    OperationService.updateOperationStatus,
    options,
  );
};

export { useUpdateOpStatusRequest };
