import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as Sentry from '@sentry/react';
import App from 'App';
import 'index.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import * as serviceWorker from 'serviceWorker';
import { ThemeProvider } from 'styled-components';
import theme from 'themes';

import { AuthProvider } from 'config/auth/Context';

import { PrivateLayoutProvider } from 'layouts/PrivateLayout/Context';

import GlobalStyle from 'themes/global-style';

import queryClient from './config/queryClient';

if (
  process.env.REACT_APP_SENTRY_DSN !== '' &&
  process.env.NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', process.env.REACT_APP_API_URL],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

moment().locale('pt-br');

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ToastContainer hideProgressBar />
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="pt-br">
            <PrivateLayoutProvider>
              <GlobalStyle />
              <App />
            </PrivateLayoutProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.unregister();
