import { IPagination } from 'models/pagination';
import { IUser } from 'models/user';
import { IValidateParams } from 'models/validate';

import api from '../../../config/api';
import {
  IFilterUserParams,
  IUserPayload,
  IUserUpdateRequest,
  IUserValidateParams,
} from './types';

export class UserService {
  static async list(params?: IFilterUserParams) {
    const { data } = await api.get<IPagination<IUser>>(`/configs-user`, {
      params,
    });
    return data;
  }

  static async listConsultants(params?: IFilterUserParams) {
    const { data } = await api.get<IPagination<IUser>>(
      `/configs-user/consultants`,
      {
        params,
      },
    );
    return data;
  }

  static async listAllConsultants() {
    const { data } = await api.get<IUser[]>(`/configs-user/list-consultants`);
    return data;
  }

  static async get(id: string) {
    const { data } = await api.get<IUser>(`/configs-user/${id}`);
    return data;
  }

  static async create(user: IUserPayload) {
    const { data } = await api.post<IUser>(`/configs-user`, user);
    return data;
  }

  static async createConsultant(user: IUserPayload) {
    const { data } = await api.post<IUser>(`/configs-user/consultant`, user);
    return data;
  }

  static async update({ id, user }: IUserUpdateRequest) {
    const { data } = await api.put<IUser>(`/configs-user/${id}`, user);
    return data;
  }

  static async updateConsultant({ id, user }: IUserUpdateRequest) {
    const { data } = await api.put<IUser>(`/configs-user/consultant/${id}`, user);
    return data;
  }

  static async delete(id: string) {
    const { data } = await api.delete(`/configs-user/${id}`);
    return data;
  }

  static async validate({ value, key, id }: IValidateParams<IUserValidateParams>) {
    const { data } = await api.get<boolean>(`/configs-user/validate`, {
      params: {
        value,
        id,
        key,
      },
    });

    return data;
  }
}

export default UserService;
