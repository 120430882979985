import { Grid, Checkbox } from '@mui/material';

import {
  Box,
  Typography,
  ButtonOutlined,
  ButtonContained,
  SectionButtons,
} from 'components';
import { QuestionType } from 'models';

import { useAnswerStep, useQuestionDialog } from '../../hooks';
import { AnswerOptions } from './AnswerOptions';

export interface AnswerStepProps {
  handleComplete: (submitForm: () => void) => void;
}

export const AnswerStep = ({ handleComplete }: AnswerStepProps) => {
  const { handleCloseFormDialog } = useQuestionDialog();
  const {
    checkUpload,
    numbersAnswers,
    dataQuestion,
    answerOptions,
    createOptionAnswer,
    handleChangeCheckUpload,
    setAnswersOptionsValue,
    handleComplementDataQuestion,
  } = useAnswerStep();

  return (
    <>
      <Box as="form">
        <Grid container p={4} pb={0} minHeight={350}>
          <Grid item xs={12} display={'flex'} alignItems={'center'} maxHeight={100}>
            <Checkbox
              checked={
                dataQuestion.type === QuestionType.UPLOAD ? true : checkUpload
              }
              onChange={() => handleChangeCheckUpload()}
              disabled={dataQuestion.type === QuestionType.UPLOAD ? true : false}
            />
            <Typography>Anexo de documento?</Typography>
          </Grid>
          <Box
            mb={20}
            width={'100%'}
            minHeight={250}
            maxHeight={300}
            overflow={'auto'}
          >
            {dataQuestion.type === QuestionType.MULTIPLE_CHOICE &&
              Array.from({ length: numbersAnswers }, (_, index) => (
                <AnswerOptions
                  key={index}
                  option={`${index + 1}`}
                  isLast={index === numbersAnswers - 1}
                  addAnswer={createOptionAnswer}
                  answerOptions={answerOptions}
                  setAnswerOptionValue={setAnswersOptionsValue}
                />
              ))}
          </Box>
        </Grid>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseFormDialog} />
        <ButtonContained
          label="Confirmar"
          onClick={() => handleComplete(handleComplementDataQuestion)}
        />
      </SectionButtons>
    </>
  );
};
