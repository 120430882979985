import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { IActPayload, IUpdateActRequest } from '../services';
import ActServices from '../services/services';

const useUpdateActRequest = (
  options?: UseMutationOptions<IActPayload, IApiAxiosError, IUpdateActRequest>,
) => {
  return useMutation('updateActRequest', ActServices.update, options);
};

export { useUpdateActRequest };
