import { IconButton, TableCell } from '@mui/material';
import { useState } from 'react';
import themes from 'themes';

import { Icon, TableRow, Typography, TypographyActive } from 'components';
import {
  ESerasaType,
  ICompanySerasa,
  IPersonSerasa,
  inactiveClients,
} from 'models';

import { limitSomeDescription } from 'utils/limitSomeDescription';
import { renderCnpjMask } from 'utils/renderCNPJMask';
import { renderCpfMask } from 'utils/renderCPFMask';

import { ISerasaResponse } from '../services';
import { SerasaCompanyModal } from './SerasaCompanyModal';
import { SerasaModal } from './SerasaModal';
import { SerasaPersonModal } from './SerasaPersonModal';

const { colors } = themes;

const SerasaItem = (serasa: ISerasaResponse) => {
  const [openCompany, setOpenCompany] = useState(false);
  const [openPartner, setOpenPartner] = useState(false);

  const companyName =
    serasa?.enterprise?.bigData?.fantasyName ||
    serasa?.enterprise?.bigData?.socialReason;
  const inactive = inactiveClients.includes(serasa?.enterprise?.status);

  const serasaBalance =
    serasa.type === 'company'
      ? serasa.protests.summary?.balance
      : serasa.protests?.response?.reduce(
          (acc, curr) => acc + (curr.amount ?? 0),
          0,
        );

  const openDebts =
    serasa.type === 'company'
      ? serasa.checkAmount + serasa.pefinAmount + serasa.refinAmount
      : serasa.pefinAmount + serasa.refinAmount;

  const handleOpen = (dataType: 'company' | 'partner') => {
    if (dataType === 'company') {
      setOpenCompany(prev => !prev);

      return;
    }

    setOpenPartner(prev => !prev);
  };
  return (
    <>
      {serasa.type === 'company' && (
        <SerasaModal open={openCompany} setOpen={val => setOpenCompany(val)}>
          <SerasaCompanyModal serasa={serasa as ICompanySerasa} />
        </SerasaModal>
      )}
      {serasa.type === 'partner' && (
        <SerasaModal open={openPartner} setOpen={val => setOpenPartner(val)}>
          <SerasaPersonModal serasa={serasa as IPersonSerasa} />
        </SerasaModal>
      )}
      <TableRow key={serasa.document}>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {ESerasaType[serasa.type]}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {serasa.type === 'company'
              ? renderCnpjMask(serasa.document)
              : renderCpfMask(serasa.document)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {companyName}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {openDebts.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {serasaBalance?.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {limitSomeDescription(serasa.enterprise?.status, 45)}
          </Typography>
        </TableCell>
        <TableCell>
          <TypographyActive
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
            backgroundColor={inactive ? colors.error : colors.green}
          >
            {inactive ? 'Inativo' : 'Ativo'}
          </TypographyActive>
        </TableCell>
        <TableCell
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpen(serasa.type)}
          >
            <Icon color="gray-300">open_in_new_icon</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export { SerasaItem };
