import { Grid } from '@mui/material';

import { Typography } from 'components';
import { AffiliateElement } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  affiliates: AffiliateElement[];
}

export const BigDataAffiliates = ({ affiliates }: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Afiliados
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {affiliates?.map(affiliate => (
          <Grid item xs={12} lg={6} key={affiliate.cnpj}>
            <BigDataItem title="CNPJ" value={affiliate.cnpj} />
            <BigDataItem title="Município" value={affiliate.municipality} />
            <BigDataItem
              title="Data de Abertura"
              value={
                affiliate.openingDate &&
                new Date(affiliate.openingDate).toLocaleDateString('pt-BR')
              }
            />
            <BigDataItem
              title="Situação de Registro"
              value={affiliate.registrationSituation}
            />
            <BigDataItem title="Razão Social" value={affiliate.socialReason} />
            <BigDataItem title="UF" value={affiliate.uf} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
