import { ReactNode } from 'react';

import { RootWrapper } from './styles';

interface RootProps {
  children: ReactNode;
  step: number;
}

export function Root({ children, step }: RootProps) {
  return (
    <RootWrapper ariaLabel="pagination" icon={step + 1}>
      {children}
    </RootWrapper>
  );
}
