import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { memo } from 'react';

import { useDashboardStore } from '../store/store';

const style = StyleSheet.create({
  page: {
    padding: 10,
  },
  card: {
    margin: 10,
    padding: 10,
    borderRadius: 4,
    border: '1px solid #E2E2E2',
  },
  title: {
    marginBottom: 10,
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 1.5,
    fontWeight: 'bold',
    color: '#515151',
  },
  inlineCharts: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    gap: 10,
  },
  pizzaChartCard: {
    marginHorizontal: 'auto',
  },
});

const DashboardPDF = memo(() => {
  const {
    chartsImagesUrl,
    tablesImagesUrl,
    generalDataImageUrls,
  } = useDashboardStore();

  DashboardPDF.displayName = 'DashboardPDF';

  const renderData = (url: string) => {
    if (url.length > 0) return true;
    return false;
  };

  return (
    <Document>
      <Page size="A4" style={style.page}>
        <View style={{ margin: 10 }} wrap={false}>
          <Image src={generalDataImageUrls.companyData.url} />
        </View>
        {/* <View style={style.card} wrap={false}>
          <Text style={style.title}>Indicadores</Text>
          <Image src={tablesImagesUrl.vetoesAndAlerts.url} />
        </View> */}
        {renderData(tablesImagesUrl.currentAssets.url) && (
          <View style={style.card} wrap={false}>
            <Text style={style.title}>Balanço - Ativos</Text>
            <Image src={tablesImagesUrl.currentAssets.url} />
          </View>
        )}

        {renderData(tablesImagesUrl.currentPassives.url) && (
          <View style={style.card} wrap={false}>
            <Text style={style.title}>Balanço - Passivos</Text>
            <Image src={tablesImagesUrl.currentPassives.url} />
          </View>
        )}

        {renderData(tablesImagesUrl.dre.url) && (
          <>
            <View style={style.card} wrap={false}>
              <Text style={style.title}>DRE</Text>
              <Image src={tablesImagesUrl.dre.url} />
            </View>
            <View style={style.card} wrap={false}>
              <Image src={chartsImagesUrl.dre.url} />
            </View>
          </>
        )}
        {renderData(tablesImagesUrl.indexesAndIndicators.url) && (
          <View style={style.card} wrap={false}>
            <Text style={style.title}>Índice e Indicadores</Text>
            <Image src={tablesImagesUrl.indexesAndIndicators.url} />
          </View>
        )}

        {renderData(tablesImagesUrl.monthlyBilling.url) && (
          <>
            <View style={style.card} wrap={false}>
              <Text style={style.title}>Faturamento Mensal</Text>
              <Image src={tablesImagesUrl.monthlyBilling.url} />
            </View>
            <View style={style.card} wrap={false}>
              <Image src={chartsImagesUrl.monthlyBilling.url} />
            </View>
          </>
        )}

        {renderData(tablesImagesUrl.debts.url) && (
          <>
            <View style={style.card} wrap={false}>
              <Text style={style.title}>Endividamento</Text>
              <Image src={tablesImagesUrl.debts.url} />
            </View>
            <View style={style.card} wrap={false}>
              <View style={style.pizzaChartCard}>
                <Image src={chartsImagesUrl.debts.url} style={{ maxWidth: 300 }} />
              </View>
            </View>
            <View style={style.card} wrap={false}>
              <Image src={chartsImagesUrl.debtsVCTO.url} />
            </View>
          </>
        )}

        {tablesImagesUrl.abcCurveCustomer.url.length > 0 && (
          <>
            <View style={style.card} wrap={false}>
              <Text style={style.title}>Curva ABC - Clientes</Text>
              <Image src={tablesImagesUrl.abcCurveCustomer.url} />
            </View>
            <View style={style.card} wrap={false}>
              <Image src={chartsImagesUrl.abcCurveCustomer.url} />
            </View>
            <View style={style.card} wrap={false}>
              <Text style={style.title}>Curva ABC - Fornecedores</Text>
              <Image src={tablesImagesUrl.abcCurveSupplier.url} />
            </View>
            <View style={style.card} wrap={false}>
              <Image src={chartsImagesUrl.abcCurveSupplier.url} />
            </View>
          </>
        )}
      </Page>
    </Document>
  );
});

export default DashboardPDF;
