import styled from 'styled-components';

import { Box } from 'components';

const RootWrapper = styled(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export { RootWrapper };
