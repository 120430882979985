import { ReactNode } from 'react';

import { ToolbarWrapper } from './styles';

interface ToolbarProps {
  children: ReactNode;
}

export function Toolbar({ children }: ToolbarProps) {
  return <ToolbarWrapper>{children}</ToolbarWrapper>;
}
