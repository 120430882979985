import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { IQuestionInput } from 'models';

import { OptionsValues } from 'utils/helper';

import { useGetFormsQuestion, useQuestionAutocompleteForm } from '.';
import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { useListActiveQuestions } from './useListActiveQuestions';

export const useFalseExternQuestionAutocomplete = () => {
  const [search, setSearch] = useState('');
  const { questionsOptions, dataAllQuestions } = useListActiveQuestions();
  const form = useQuestionAutocompleteForm();
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();

  const handleChange = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;

    field.onChange(value);

    // pergunta que foi selecionada para a questão do formulário
    const questionSelected = dataAllQuestions?.find(
      question => question.id === `${selectedOption?.value}`,
    );

    const indexQuestion = question.numberQuestion - 1;

    const questionChanged =
      questionSelected?.id !== questionsForm[indexQuestion].nextQuestionFalse;

    if (!questionChanged) return;

    const idQuestionChanged = questionsForm[indexQuestion].nextQuestionFalse;

    questionsForm[indexQuestion].nextQuestionFalse = '';
    setQuestionsForm(questionsForm);

    const questionExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged && qF.numberQuestion !== -1,
    );

    if (questionExcluded) handleDeleteSpecificChildQuestion(questionExcluded);

    if (questionSelected) {
      questionsForm[indexQuestion].nextQuestionFalse = questionSelected?.id;
      setQuestionsForm(questionsForm);
    }
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  return {
    search,
    allQuestions: questionsOptions,
    formFalseQuestionAutocomplete: {
      ...form,
    },
    setSearch,
    handleChange,
    isOptionEqualToValue,
  };
};
