import { Grid } from '@mui/material';

import { Typography } from 'components';
import { KleverAircraft } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  aircrafts: KleverAircraft[];
}

export const BigDataAircrafts = ({ aircrafts }: Props) => {
  if (!aircrafts?.length) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Aeronaves
        </Typography>
      </Grid>
      <Grid container spacing={3}>
        {aircrafts?.map(aircraft => (
          <Grid item xs={12} lg={6} key={aircraft.registration}>
            <BigDataItem title="Fabricante" value={aircraft.maker} />
            <BigDataItem title="Modelo" value={aircraft.model} />
            <BigDataItem title="Nome do Operador" value={aircraft.operatorName} />
            <BigDataItem title="Nome do Proprietário" value={aircraft.ownerName} />
            <BigDataItem
              title="Ano de Fabricação"
              value={aircraft.productionYear?.toString()}
            />
            <BigDataItem
              title="Registro da Aeronave"
              value={aircraft.registration}
            />
            <BigDataItem title="Situação" value={aircraft.situation} />
            <BigDataItem title="Status RAB" value={aircraft.statusRAB} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
