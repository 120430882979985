import { Sidebar } from 'react-pro-sidebar';
import styled from 'styled-components';

interface SidebarProps {
  broken: boolean;
}

const RootWrapper = styled(Sidebar)<SidebarProps>`
  height: 100vh !important;
  width: 220px !important;
  min-width: 220px !important;
  border: none !important;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.12);
  z-index: 100;
  left: ${props =>
    props.broken && props.toggled ? 0 : props.broken ? -220 : 0}px !important;

  .ps-sidebar-container {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export { RootWrapper };
