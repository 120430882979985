import { TypeProcessAct, UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';

import { usePendingIssuesStore } from '../store/store';
import { useListPendingIssuesRequest } from './useListPendingIssuesRequest';

interface UsePendingIssuesProps {
  type?: TypeProcessAct;
  id?: string;
}

const usePendingIssues = ({ type, id }: UsePendingIssuesProps) => {
  const { form } = useTabAct();
  const { user } = useAuth();

  const {
    setOpenPendingIssuesDialog,
    setOpenTabPendingIssuesDialog,
    openPendingIssuesDialog,
    openTabPendingIssuesDialog,
  } = usePendingIssuesStore();

  const fromOperationModal = type === TypeProcessAct.OPERATION;
  const fromCustomerModal = type === TypeProcessAct.CLIENT;

  const enableForCustomersModal =
    fromCustomerModal && user?.userType === UserTypes.CONSULTANT && !!id;

  const enableForOperationsModal = fromOperationModal && !!id;
  const modalIsOpen = openPendingIssuesDialog || openTabPendingIssuesDialog;
  const hasPermission = enableForCustomersModal || enableForOperationsModal;
  const isGeneral = !fromCustomerModal && !fromOperationModal;

  const { isLoading, data } = useListPendingIssuesRequest({
    enabled: modalIsOpen && (hasPermission || isGeneral),
    fromCustomerModal,
    ...(fromOperationModal ? { operationId: id } : {}),
    ...(fromCustomerModal ? { enterpriseId: id } : {}),
  });

  const handleOpenDialog = ({ isTab = false }: { isTab?: boolean }) => {
    isTab ? setOpenTabPendingIssuesDialog(true) : setOpenPendingIssuesDialog(true);
  };

  const handleCloseDialog = ({ isTab = false }: { isTab?: boolean }) => {
    isTab
      ? setOpenTabPendingIssuesDialog(false)
      : setOpenPendingIssuesDialog(false);
  };

  return {
    handleOpenDialog,
    handleCloseDialog,
    openPendingIssuesDialog,
    openTabPendingIssuesDialog,
    pendingIssues: data,
    isLoading: isLoading,
    form,
  };
};

export { usePendingIssues };
