import { ReactNode } from 'react';
import { Colors } from 'themes';

import { Loader } from '../Loader';
import { OutlinedWrapper } from './styles';

export interface OutlinedProps {
  onClick?: () => void;
  label?: string;
  icon?: ReactNode;
  color?: Colors;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | undefined;
}

function Outlined({
  onClick,
  label,
  icon,
  color = 'blue',
  disabled,
  loading = false,
  fullWidth,
  type,
}: OutlinedProps) {
  return (
    <OutlinedWrapper
      onClick={onClick}
      startIcon={icon}
      customColor={color}
      disabled={loading ? true : disabled}
      fullWidth={fullWidth}
      loading={loading}
      variant="outlined"
      type={type}
    >
      {loading && <Loader />}
      <span>{label}</span>
    </OutlinedWrapper>
  );
}

export default Outlined;
