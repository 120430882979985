import { stringify } from 'qs';

import { IEnterprise, IPagination, MainCustomers, UserTypes } from 'models';

import api from 'config/api';

import {
  ICustomerPayload,
  ICustomerRegisterPayload,
  ICustomerRegisterResponse,
  IUpdateCustomerStatusPayload,
} from './types';

export class CustomerService {
  static async registerCustomer(payload: ICustomerRegisterPayload) {
    const { data } = await api.post<ICustomerRegisterResponse>(
      '/enterprise/internal',
      {
        ...payload,
        type:
          payload.type === (UserTypes.CREDITOR as UserTypes)
            ? 'Credor'
            : payload.type,
        isFinancing: payload.type === (UserTypes.CREDITOR as UserTypes),
      },
    ); // TODO - Definir retorno na API e rota

    return data;
  }

  static async updateCustomer(payload: ICustomerRegisterPayload) {
    const { data } = await api.put<ICustomerRegisterResponse>(
      '/enterprise/internal',
      {
        ...payload,
        type:
          payload.type === (UserTypes.CREDITOR as UserTypes)
            ? 'Credor'
            : payload.type,
        isFinancing: payload.type === (UserTypes.CREDITOR as UserTypes),
      },
    );

    return data;
  }

  static async updateCustomerStatus({
    customerIds,
    newStatus,
  }: IUpdateCustomerStatusPayload) {
    const { data } = await api.put<{
      affected: number;
      emptySerasa: string[];
    }>('/enterprise/status', {
      customerIds,
      newStatus,
    });

    return data;
  }

  static async getCustomers({
    search,
    activeInactive,
    enterpriseType,
    customerStatus,
    product,
    responsible,
    primaryEnterprise,
    page,
    perPage,
  }: ICustomerPayload) {
    const params = stringify({
      search,
      activeInactive,
      enterpriseType,
      customerStatus,
      responsible,
      primaryEnterprise,
      product,
      page,
      perPage,
    });

    const { data } = await api.get<IPagination<IEnterprise> | IEnterprise[]>(
      `/enterprise?${params}`,
    );

    return data;
  }

  static async getMainCustomers() {
    const { data } = await api.get<MainCustomers[]>(`/enterprise/main`);

    return data;
  }

  static async listByCustomerType({ isFinancing }: { isFinancing: boolean }) {
    const { data } = await api.get<IEnterprise[]>(`/enterprise/by-type`, {
      params: { isFinancing },
    });
    return data;
  }
}
