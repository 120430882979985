import { useMutation, UseMutationOptions } from 'react-query';

import { IEnterprise } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { SignUpEnterpriseService, ISignUpEnterprisePayload } from '../services';

const useSignUpEnterpriseRequest = (
  options?: UseMutationOptions<
    IEnterprise,
    IApiAxiosError,
    ISignUpEnterprisePayload
  >,
) => {
  return useMutation('signup', SignUpEnterpriseService.signUpEnterprise, options);
};

export { useSignUpEnterpriseRequest };
