import { FormControl, Switch as MuiSwitch } from '@mui/material';
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  label: string;
  checked?: boolean;
  disabled?: boolean;
};

export const Switch = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  control,
  name,
  label,
  checked,
  disabled,
}: Props<TFieldValues, TName>) => {
  const {
    field: { onChange, value, ...props },
  } = useController({ control, name });

  return (
    <FormControl
      fullWidth
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >
      <MuiSwitch
        onChange={onChange}
        defaultChecked={value}
        value={value}
        disabled={disabled}
        checked={checked}
        {...props}
      />
      <label>{label}</label>
    </FormControl>
  );
};
