import { ScrDialogFilter, ScrSearchContainer, ScrTable } from '../components';

const kleverScrPage = () => {
  return (
    <>
      <ScrDialogFilter />
      <ScrSearchContainer />
      <ScrTable />
    </>
  );
};

export { kleverScrPage };
