import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  FormStatus,
  IFormAnswer,
  IFormQuestion,
  IQuestionAttachmentList,
} from 'models';

import api from 'config/api';
import { useAuth } from 'config/auth/hooks';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';
import {
  EFormAnswerType,
  ITriggerDocSignPayload, //useTriggerDocSignRequest,
} from 'modules/dynamicForm/formAnswer';
import { useTriggerDocSignRequest } from 'modules/dynamicForm/formAnswer/hooks/useTriggerDocSignRequest';
import useFormAnswerStore from 'modules/dynamicForm/formAnswer/store/store';
import { useFindQuestionAttachmentRequest } from 'modules/dynamicForm/question/hooks';

import useFormResumeStore from '../store/store';
import { useSendFormRequest } from './useSendFormRequest';

interface RouteParams {
  id: string;
  type: string;
}

export const useFormResume = () => {
  const { id, type } = useParams<RouteParams>();
  const { user } = useAuth();
  const history = useHistory();
  const {
    formAnswers,
    openModalCancel,
    openModalFinishLater,
    openModalSendForm,
    isSendingDocSign,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setOpenModalSendForm,
    setFormAnswers,
    setRevisionQuestion,
    setIsSendingDocSign,
  } = useFormResumeStore();

  const {
    formRecordId,
    setformQuestionAnswereds,
    setCurrentAnswer,
    setCurrentQuestionAttachments,
    setQuestionAnswerAttachments,
    setCurrentQuestion,
    setFormRecordId,
    setIsRevisionQuestion,
  } = useFormAnswerStore();

  const { currentProcessAct } = useTabAct();

  useEffect(() => {
    getAllFormAnswers(id);
  }, [id]);

  const getAllFormAnswers = async (id: string) => {
    let idFormRecord = currentProcessAct?.formRecord?.id;
    if (formRecordId && formRecordId.length > 0) idFormRecord = formRecordId;

    const { data } = await api.get('/form/form-answer/find-all-answers', {
      params: {
        formId: id,
        enterpriseId: user?.enterprise?.id,
        formRecordId: idFormRecord ?? '',
      },
    });

    setFormAnswers(data);
  };

  const clearCurrenQuestionData = () => {
    setIsRevisionQuestion(false);
    setCurrentQuestion({} as IFormQuestion);
    setCurrentAnswer('');
    setRevisionQuestion({} as IFormAnswer);
    setCurrentQuestionAttachments([]);
    setQuestionAnswerAttachments([]);
    setOpenModalCancel(false);
    setOpenModalFinishLater(false);
    setOpenModalSendForm(false);
    setIsSendingDocSign(false);
    setFormRecordId('');
  };

  const requestFindAttachmentQuestions = useFindQuestionAttachmentRequest({
    onSuccess: data => {
      setCurrentQuestionAttachments(data);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar buscar os anexos da pergunta');
    },
  });

  const requestTriggerDocSign = useTriggerDocSignRequest({
    onSuccess: () => {
      setIsSendingDocSign(false);
    },
    onError: () => {
      toast.error('Houve um erro ao enviar documento para assinatura');
      setIsSendingDocSign(false);
    },
  });

  const sendFormRequest = useSendFormRequest();

  const getQuestionRevision = async (questionId: string) => {
    const formAnswerSelected = formAnswers.find(answer => answer.id === questionId);

    if (!formAnswerSelected) return;

    requestFindAttachmentQuestions.mutate({
      idQuestion: formAnswerSelected.formQuestion.question.id,
    });

    setCurrentAnswer(`${formAnswerSelected.answerValue}`);

    setCurrentQuestion(formAnswerSelected.formQuestion);

    setIsRevisionQuestion(true);

    setRevisionQuestion(formAnswerSelected);

    const answerAttachments: IQuestionAttachmentList[] = [];

    formAnswerSelected.attachments?.map(att => {
      answerAttachments.push({ id: att?.id, file: att.file });
    });

    setQuestionAnswerAttachments(answerAttachments);

    history.push(`/form-answer/${id}/${type}`);
  };

  const handleCancelForm = async () => {
    const idFormRecord =
      type === EFormAnswerType.simulation
        ? formRecordId ?? ''
        : currentProcessAct?.formRecord?.id ?? formRecordId ?? '';

    const deleted = await api.delete<boolean>(`/form/form-answer/delete`, {
      params: {
        formId: id,
        formRecordId: idFormRecord,
        enterpriseId: user?.enterprise?.id,
      },
    });
    if (deleted) {
      setformQuestionAnswereds([]);

      if (type === EFormAnswerType.answer) {
        setOpenModalCancel(false);
        alert('Vai redirecionar pra pendências (ainda nao implementado)');
        history.push('/home');
        return;
      }

      history.push('/config-form/?edit=true');
      return;
    }

    toast.error('Erro ao tenta excluir questões respondidas');
  };

  const handleFinishLater = async () => {
    history.push('/home');
    setOpenModalFinishLater(false);
  };

  const handleSendForm = async () => {
    // TODO  atualizar as informações na base de dados;
    const isTriggerDocSign = currentProcessAct?.act?.configs?.triggerDocSign;
    if (isTriggerDocSign) {
      setOpenModalSendForm(false);
      setIsSendingDocSign(true);

      const triggerDocPayload: ITriggerDocSignPayload = {
        formRecordId: currentProcessAct?.formRecord?.id ?? '',
        formId: id,
        enterpriseId: currentProcessAct?.enterprise?.id ?? '',
        actId: currentProcessAct?.act?.id ?? '',
        processActId: currentProcessAct?.id ?? '',
      };

      await requestTriggerDocSign.mutateAsync(triggerDocPayload);
    }

    if (type !== EFormAnswerType.simulation) {
      await sendFormRequest.mutateAsync(currentProcessAct?.formRecord?.id ?? '');
    }

    clearCurrenQuestionData();
    if (type === EFormAnswerType.simulation) {
      history.push(`/config-form/?edit=true`);
      return;
    }
    history.push(`/home`);
  };

  const getRenderButtonsOptions = () => {
    if (type === EFormAnswerType.simulation) return false;

    if (currentProcessAct?.formRecord?.status === FormStatus.COMPLETED) return true;

    if (formRecordId) return true;

    return false;
  };

  return {
    formAnswers,
    openModalCancel,
    openModalFinishLater,
    openModalSendForm,
    renderButtonsOptions: getRenderButtonsOptions(),
    loadingTriggerDocSign: isSendingDocSign,
    type,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setOpenModalSendForm,
    handleSendForm,
    setFormAnswers,
    getQuestionRevision,
    handleFinishLater,
    handleCancelForm,
  };
};
