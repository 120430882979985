import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IUser, RolesTypes, UserTypes } from 'models';
import { IPagination } from 'models/pagination/types';

import { useAuth } from 'config/auth/hooks';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { UserQueryKey, UserService } from '../services';
import { useUserStore } from '../store/store';
import { useFilterForm } from './useFilterForm';

export const useFindUser = () => {
  const {
    page,
    perPage,
    setPage,
    setLimit,
    limit,
    search,
    currentFilter,
  } = useUserStore();

  const { filterParams } = useFilterForm();

  const { user } = useAuth();

  const enterpriseId = user?.enterprise?.id;
  const isEnabled = !!(enterpriseId && enterpriseId.length > 0);

  const { data, isLoading, refetch } = useQuery(
    [UserQueryKey.LIST_ALL, page, search, currentFilter],
    () =>
      UserService.list({
        page,
        perPage,
        search,
        enterpriseId: enterpriseId,
        userTypes: [UserTypes.CREDITOR, UserTypes.ENTERPRISE],
        rolesTypes: [RolesTypes.ADMIN, RolesTypes.COLABORATOR],
        ...filterParams,
      }) as Promise<IPagination<IUser>>,
    {
      enabled: isEnabled,
      keepPreviousData: true,
    },
  );
  const noData = !data?.data || data.data.length === 0;
  const showPagination = !noData || page !== DEFAULT_PAGINATION.page;
  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
    }
  }, [data]);
  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
    noData,
    showPagination,
  };
};
