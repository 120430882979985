import { ClientDestineStatus, ProductClient } from 'models';

export type IFilterValues =
  | 'tipo'
  | 'responsavel'
  | 'status'
  | 'produto'
  | 'ativo'
  | 'inativo'
  | 'questão';

export type pageFilter =
  | 'formAnswer'
  | 'scr'
  | 'serasa'
  | 'storage'
  | 'kmethod'
  | 'none';

export interface IAppliedFilter {
  label: string;
  value: IFilterValues;
}

export enum IDatabaseFilterBy {
  'TYPE' = 'type',
  'MANAGER' = 'manager',
  'STATUS' = 'status',
  'PRODUCT' = 'product',
  'ACTIVE_INATIVE' = 'active',
  'QUESTION' = 'question',
}

export enum IDatabaseFilterByLabel {
  'TYPE' = 'Tipo',
  'MANAGER' = 'Reponsável',
  'STATUS' = 'Status',
  'PRODUCT' = 'Produto',
  'ACTIVE_INATIVE' = 'Ativo/Inativo',
  'QUESTION' = 'Pergunta',
}

export interface IDatabaseFilter {
  label: string;
  value: number;
  filterBy: IDatabaseFilterBy;
}

export interface IDatabaseFilterByOption {
  value: IDatabaseFilterBy;
  label: IDatabaseFilterByLabel;
}

export const DEFAULT_DATABASE_FILTER_BY = {
  value: IDatabaseFilterBy.TYPE,
  label: IDatabaseFilterByLabel.TYPE,
};

export const databaseFilterByOptions: IDatabaseFilterByOption[] = [
  {
    value: IDatabaseFilterBy.TYPE,
    label: IDatabaseFilterByLabel.TYPE,
  },
  {
    value: IDatabaseFilterBy.MANAGER,
    label: IDatabaseFilterByLabel.MANAGER,
  },
  {
    value: IDatabaseFilterBy.PRODUCT,
    label: IDatabaseFilterByLabel.PRODUCT,
  },
  {
    value: IDatabaseFilterBy.STATUS,
    label: IDatabaseFilterByLabel.STATUS,
  },
  {
    value: IDatabaseFilterBy.ACTIVE_INATIVE,
    label: IDatabaseFilterByLabel.ACTIVE_INATIVE,
  },
  {
    value: IDatabaseFilterBy.QUESTION,
    label: IDatabaseFilterByLabel.QUESTION,
  },
];

export interface IFilterDatabasePayload {
  search?: string;
  page?: number;
  perPage?: number;
  type?: string;
  manager?: string;
  status?: ClientDestineStatus;
  product?: ProductClient;
  active?: boolean;
  question?: string;
}
