import { Card, Table, TableCell } from '@mui/material';
import html2canvas from 'html2canvas';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import themes from 'themes';

import { Box, TableBody, TableContainer, TableRow, Typography } from 'components';

import { useDashboardStore } from 'modules/dashboard/store/store';

import { isEven } from 'utils/isEven';
import { limitSomeDescription } from 'utils/limitSomeDescription';

export type typeAbcCurveTable = 'customer' | 'supplier';

export interface DataTableAbcCurveItem {
  clientName: string;
  valueLastExAcc: number;
  partPercentTotalValue: number;
  accPartPercentTotalValue: number;
  abcClassification: string;
}

export interface TypeCurveItem {
  type: string;
  numberClient: number;
}

interface AbcCurveTableComponentProps {
  data: DataTableAbcCurveItem[];
  dataTypeCurve: TypeCurveItem[];
  typeTable: typeAbcCurveTable;
  total: number;
  isLoading: boolean;
  noData: boolean;
  title: string;
}

const abcCurveMapping = {
  'Curva ABC de Clientes': 'abcCurveCustomer',
  'Curva ABC de Fornecedores': 'abcCurveSupplier',
};

const AbcCurveTableComponent = ({
  typeTable,
  data,
  dataTypeCurve,
  total,
  isLoading,
  noData,
  title,
}: AbcCurveTableComponentProps) => {
  const { setTablesImagesUrl } = useDashboardStore();
  const tableRef = useRef<HTMLDivElement>(null);

  const captureComponent = useCallback(async (ref, key) => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current);
      const imageData = canvas.toDataURL('image/png');

      setTablesImagesUrl(key, imageData, canvas.width, canvas.height);
    }
  }, []);

  useEffect(() => {
    const captureTable = async () => {
      await captureComponent(tableRef, abcCurveMapping[title]);
    };

    if (tableRef) {
      captureTable();
    }
  }, []);

  const emptyString = '---';
  const nameFirstCell = typeTable === 'supplier' ? 'Fornecedor' : 'Cliente';
  const isFirstCell = true;
  const isTotalCell = true;
  const sizeRow = 45;
  const heightWindow = window.innerHeight;
  const widthTable = heightWindow > 900 ? '97%' : '95.8%';

  const transformPercent = (value: string | number) => {
    const valuePercent = Number(value) / 100;
    return valuePercent.toLocaleString('pt-BR', {
      style: 'percent',
      minimumFractionDigits: 2,
    });
  };

  const transformMoney = (value: string | number) => {
    return value.toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2,
    });
  };

  const renderHead = (name: string, width: string, isFirstCell: boolean) => {
    const splitted = name.split('ABC');
    return (
      <TableCell
        align="center"
        style={{
          borderLeft: isFirstCell ? undefined : `1px solid ${themes.colors.white}`, //border: `1px solid ${themes.colors.black}`,
          paddingTop: 3,
          paddingBottom: 3,
          paddingLeft: 5,
          paddingRight: 5,
        }}
        sx={{ height: sizeRow, maxHeight: sizeRow }}
        width={width}
      >
        <Typography color={themes.colors.white} fontWeight={700}>
          {splitted[0]}
          {splitted.length > 1 && (
            <>
              <br />
              {'ABC'}
            </>
          )}
        </Typography>
      </TableCell>
    );
  };

  const renderField = (
    value: string | number,
    firstCell: boolean,
    totalCell: boolean,
  ) => {
    return (
      <Typography
        fontWeight={totalCell ? 700 : 500}
        color={themes.colors.dashboard.table.text.black}
        fontSize={firstCell ? 15 : 16}
      >
        {value}
      </Typography>
    );
  };

  const renderTableCell = (
    value: string | number | null,
    firstCell: boolean,
    totalCell: boolean,
  ) => {
    const noValue = !value || value === null;

    return (
      <TableCell
        align="center"
        style={{
          //borderTop: `1px solid ${themes.colors.black}`,
          padding: 0,
        }}
        sx={{ height: sizeRow, maxHeight: sizeRow, minWidth: '100%' }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          padding={0}
          paddingLeft={3}
          paddingRight={3}
          //borderLeft={firstCell ? `1px solid ${themes.colors.black}` : null}
          //borderRight={`1px solid ${themes.colors.black}`}
          //borderBottom={`1px solid ${themes.colors.black}`}
          minHeight={sizeRow}
        >
          {noValue ? emptyString : renderField(`${value}`, firstCell, totalCell)}
        </Box>
      </TableCell>
    );
  };

  const renderTableCellJoined = (
    typeCurve: string,
    value: number,
    totalCell: boolean,
  ) => {
    const noValue = !typeCurve || !value;

    return (
      <TableCell
        align="center"
        style={{
          // borderTop: `1px solid ${themes.colors.black}`,
          padding: 0,
        }}
        sx={{ height: sizeRow, maxHeight: sizeRow }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          // borderRight={`1px solid ${themes.colors.black}`}
          // borderBottom={`1px solid ${themes.colors.black}`}
        >
          <Box
            //borderRight={`1px solid ${themes.colors.black}`}
            width={'50%'}
            minHeight={sizeRow}
            alignContent={'center'}
          >
            {noValue
              ? emptyString
              : renderField(typeCurve, !isFirstCell, totalCell)}
          </Box>
          <Box width={'50%'} minHeight={sizeRow} alignContent={'center'}>
            {noValue ? emptyString : renderField(value, !isFirstCell, totalCell)}
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Card style={{ width: widthTable, padding: 25, margin: 0 }}>
      <Typography
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        color={themes.colors.dashboard.table.header.main}
      >
        {title}
      </Typography>
      <div ref={tableRef}>
        <TableContainer sx={{ padding: 0, margin: 0, mt: 2 }}>
          <Table>
            <TableRow
              style={{ borderCollapse: 'collapse' }}
              sx={{
                backgroundColor: themes.colors.dashboard.table.header.main,
                height: sizeRow,
                maxHeight: sizeRow,
                padding: 0,
              }}
              hover={false}
            >
              {renderHead(`Nome do ${nameFirstCell}`, '21%', isFirstCell)}
              {renderHead(
                'Valor Acumulado do último exercício R$',
                '17%',
                !isFirstCell,
              )}
              {renderHead(
                'Percentual de participação sobre o valor total',
                '19%',
                !isFirstCell,
              )}
              {renderHead(
                'Percentual acumulado de participação sobre o valor total',
                '19%',
                !isFirstCell,
              )}
              {renderHead('Classificação ABC', '8%', !isFirstCell)}
              {renderHead('Número de clientes ABC', '13%', !isFirstCell)}
            </TableRow>

            <TableBody
              columns={6}
              isLoading={isLoading}
              empty={noData}
              emptyMessage="Nenhuma informação Curva ABC"
            >
              {data.map((item, index) => (
                <Fragment key={`${item.clientName} - ${item.valueLastExAcc}`}>
                  <TableRow
                    hover={false}
                    sx={{
                      backgroundColor: isEven(index)
                        ? '#FFF'
                        : `${themes.colors.dashboard.table.body.tertiary}`,
                      height: sizeRow,
                      maxHeight: sizeRow,
                      padding: 0,
                    }}
                  >
                    {renderTableCell(
                      limitSomeDescription(item.clientName, 40),
                      isFirstCell,
                      !isTotalCell,
                    )}
                    {renderTableCell(
                      transformMoney(item.valueLastExAcc),
                      !isFirstCell,
                      !isTotalCell,
                    )}
                    {renderTableCell(
                      transformPercent(item.partPercentTotalValue),
                      !isFirstCell,
                      !isTotalCell,
                    )}
                    {renderTableCell(
                      transformPercent(item.accPartPercentTotalValue),
                      !isFirstCell,
                      !isTotalCell,
                    )}
                    {renderTableCell(
                      item.abcClassification,
                      !isFirstCell,
                      !isTotalCell,
                    )}
                    {renderTableCellJoined(
                      dataTypeCurve[index]?.type,
                      dataTypeCurve[index]?.numberClient,
                      !isTotalCell,
                    )}
                  </TableRow>
                </Fragment>
              ))}
              <TableRow
                hover={false}
                sx={{
                  backgroundColor: isEven(data.length)
                    ? '#FFF'
                    : `${themes.colors.dashboard.table.body.tertiary}`,
                }}
              >
                {renderTableCell('TOTAL', isFirstCell, isTotalCell)}
                {renderTableCell(transformMoney(total), !isFirstCell, isTotalCell)}
                {renderTableCell(null, !isFirstCell, !isTotalCell)}
                {renderTableCell(null, !isFirstCell, !isTotalCell)}
                {renderTableCell(null, !isFirstCell, !isTotalCell)}
                {renderTableCell(null, !isFirstCell, !isTotalCell)}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
};

export { AbcCurveTableComponent };
