import {
  Checkbox,
  FormControlLabel,
  Grid,
  InputBaseComponentProps,
  MenuItem,
  Typography,
} from '@mui/material';
import { ElementType } from 'react';

import {
  Box,
  ButtonContained,
  SectionButtons,
  Select,
  TextField,
  TextMask,
} from 'components';
import { useMaskInputs } from 'hooks';

import { UseInternalRegister, useInternalRegister } from '../hooks';

export interface CustomerDialogFormProps {
  form: UseInternalRegister['form'];
  isLoading?: boolean;
}

export const InternalRegisterForm = ({
  form,
  isLoading,
}: CustomerDialogFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmitForm,
  } = form;

  const {
    USER_ROLES,
    COMMON_USER_TYPES,
    hasSecondCnpj,
    setHasSecondCnpj,
  } = useInternalRegister();
  const { TELEPHONE_MASK, CNPJ_MASK, CPF_MASK } = useMaskInputs();

  return (
    <>
      <Box as="form" maxWidth={500} padding={4}>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.cnpj}
              name="cnpj"
              label="CNPJ"
              type="text"
              inputProps={{ mask: CNPJ_MASK.DEFAULT }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
            />
          </Grid>

          <Grid item xs={12} height={45} alignContent={'center'}>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={hasSecondCnpj}
                  onChange={() => {
                    setHasSecondCnpj(!hasSecondCnpj);
                  }}
                />
              }
              sx={{ mr: 0 }}
              label={<Typography>CNPJ Secundário? </Typography>}
            />
          </Grid>

          {hasSecondCnpj && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                control={control}
                error={errors.primaryEnterprise}
                name="primaryEnterprise"
                label="CNPJ (Empresa Principal)"
                type="text"
                inputProps={{ mask: CNPJ_MASK.DEFAULT }}
                InputProps={{
                  inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              name="fantasyName"
              label="Nome Fantasia"
              control={control}
              type="text"
              error={errors.fantasyName}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              control={control}
              error={errors.name}
              name="name"
              label="Nome"
              type="text"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              fullWidth
              control={control}
              error={errors.surname}
              name="surname"
              label="Sobrenome"
              type="text"
            />
          </Grid>
          <Grid item xs={12} lg={8}>
            <TextField
              fullWidth
              control={control}
              error={errors.cpf}
              name="cpf"
              label="CPF"
              type="text"
              inputProps={{ mask: CPF_MASK.DEFAULT }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <TextField
              fullWidth
              control={control}
              error={errors.birthDate}
              name="birthDate"
              label="Data de nascimento"
              type="date"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.linkedin}
              name="linkedin"
              label="Linkedin"
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.email}
              name="email"
              label="E-mail"
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.confirmEmail}
              name="confirmEmail"
              label="Confirmação de Email"
              type="text"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.telephone}
              name="telephone"
              label="Telefone"
              type="text"
              inputProps={{ mask: TELEPHONE_MASK.ONE_MORE_DIGIT }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              control={control}
              error={errors.site}
              name="site"
              label="Site"
              type="text"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Select name="role" control={control} label="Cargo" error={errors.role}>
              {USER_ROLES.map(role => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Select
              name="type"
              control={control}
              label="Tipo de cliente"
              error={errors.type}
            >
              {COMMON_USER_TYPES.map(type => (
                <MenuItem key={type.value} value={type.label}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <SectionButtons padding={2}>
        <ButtonContained
          label="CADASTRAR"
          fullWidth
          onClick={handleSubmitForm}
          loading={isLoading}
          disabled={isLoading}
        />
      </SectionButtons>
    </>
  );
};
