import { AppBar } from '@mui/material';
import styled from 'styled-components';

const HeaderWrapper = styled(AppBar)`
  && {
    background-color: ${({ theme }) => theme.colors['gray-700']};
  }
`;

export { HeaderWrapper };
