import { useMutation, UseMutationOptions } from 'react-query';

import { IForm } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateFormRequest } from '../services';
import { FormService } from '../services/services';

const useUpdateFormRequest = (
  options?: UseMutationOptions<IForm, IApiAxiosError, IUpdateFormRequest>,
) => {
  return useMutation('udpateForm', FormService.update, options);
};

export { useUpdateFormRequest };
