import { AxiosError } from 'axios';

type IApiErrorTypes =
  | 'CreateException'
  | 'UpdateException'
  | 'DeleteException'
  | 'NotFound'
  | 'Unauthorized'
  | 'Internal';

export enum ApiUnauthorizedErrorsTitles {
  INVALID_CREDENTIALS = 'Invalid credentials',
  ACCESS_TOKEN_NOT_PROVIDED = 'Access token not provided',
  INVALID_ACCESS_TOKEN = 'Invalid access token',
  ACCESS_TOKEN_EXPIRED = 'Access token expired',
  INVALID_REFRESH_TOKEN = 'Invalid refresh token',
  REFRESH_TOKEN_EXPIRED = 'Refresh token expired',
  INVALID_PERMISSION = 'Invalid permission',
  BAD_REQUEST = 'Bad request',
  INVALID_BIG_DATA = 'Invalid big data',
}

export interface Error {
  error: IApiError[];
  statusCode: number;
}

export interface IApiError {
  type: IApiErrorTypes;
  title: string | ApiUnauthorizedErrorsTitles;
  detail: string | Error;
}

export type IApiAxiosError = AxiosError<IApiError>;
