import { ApiUnauthorizedErrorsTitles } from '../types';

export const isTokenError = (errorTitle: string) => {
  return (
    errorTitle === ApiUnauthorizedErrorsTitles.ACCESS_TOKEN_NOT_PROVIDED ||
    errorTitle === ApiUnauthorizedErrorsTitles.INVALID_ACCESS_TOKEN ||
    errorTitle === ApiUnauthorizedErrorsTitles.ACCESS_TOKEN_EXPIRED ||
    errorTitle === ApiUnauthorizedErrorsTitles.INVALID_REFRESH_TOKEN ||
    errorTitle === ApiUnauthorizedErrorsTitles.REFRESH_TOKEN_EXPIRED
  );
};
