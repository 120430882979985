import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { IQuestionInput } from 'models';

import { OptionsValues } from 'utils/helper';

import { useQuestionAutocompleteForm } from '.';
import { useGetFormsQuestion } from '.';
import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { useListActiveQuestions } from './useListActiveQuestions';

export const useTrueAutocompleteVariableMultipleChoice = () => {
  const [search, setSearch] = useState('');
  const { questionsOptions, dataAllQuestions } = useListActiveQuestions();
  const form = useQuestionAutocompleteForm(); //pode ser o usado o msm em todos
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();

  const handleChange = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
    identifierCondition?: number,
    identifierVariable?: number,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;

    field.onChange(value);

    // pergunta que foi selecionada para a questão do formulário
    const questionSelected = dataAllQuestions?.find(
      question => question.id === `${selectedOption?.value}`,
    );

    if (!identifierCondition || !identifierVariable) return;

    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;
    const indexVariable = identifierVariable - 1;

    const currentQuestion =
      questionsForm[indexQuestion].conditions[indexCondition].variables[
        indexVariable
      ];

    const questionChanged = questionSelected?.id !== currentQuestion.nextQuestion;

    if (questionChanged) {
      const idQuestionChanged =
        questionsForm[indexQuestion].conditions[indexCondition].variables[
          indexVariable
        ].nextQuestion;

      const questionChildExcluded = questionsForm.find(
        qF => qF?.question?.id === idQuestionChanged && qF.numberQuestion !== -1,
      );

      if (questionChildExcluded) {
        handleDeleteSpecificChildQuestion(questionChildExcluded);
      }

      if (questionSelected) {
        questionsForm[indexQuestion].conditions[indexCondition].variables[
          indexVariable
        ].nextQuestion = questionSelected.id;

        questionsForm[indexQuestion].conditions[indexCondition].variables[
          indexVariable
        ].nextQuestionLabel = questionSelected.title;

        setQuestionsForm(questionsForm);
      } else {
        questionsForm[indexQuestion].conditions[indexCondition].variables[
          indexVariable
        ].nextQuestion = '';

        questionsForm[indexQuestion].conditions[indexCondition].variables[
          indexVariable
        ].nextQuestionLabel = '';

        setQuestionsForm(questionsForm);
      }
    }
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  return {
    search,
    allQuestions: questionsOptions,
    formNexQuestionAutocomplete: {
      ...form,
    },
    setSearch,
    handleChange,
    isOptionEqualToValue,
  };
};
