import { useMutation, UseMutationOptions } from 'react-query';

import { IUser } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { UserService, IUserUpdateRequest } from 'modules/user/services';

const useUpdateConsultantRequest = (
  options?: UseMutationOptions<IUser, IApiAxiosError, IUserUpdateRequest>,
) => {
  return useMutation('udpateConsultant', UserService.updateConsultant, options);
};

export { useUpdateConsultantRequest };
