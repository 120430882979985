import { useCallback, useMemo } from 'react';

import { TypeProcessAct, UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { useListAllActs } from 'modules/backoffice/acts';
import { useListCreditors, useListEnterprises } from 'modules/boards';
import { useListAllConsultants } from 'modules/user/hooks';

import { OptionsValues, OptionsValuesString } from 'utils/helper';

import {
  IOperationFilterOption,
  IOperationFilterLabel,
  IOperationFilterValue,
  IOperationsFilter,
  DEFAULT_OPERATIONS_FILTER_CREDITOR,
} from '../services';
import { useOperationStore } from '../store/store';
import {
  operationTypeOptions,
  statusOptions,
  useFindActiveOptions,
} from '../utils';
import { useListOptionsCompanysOperationFilter } from './useListOptionsCompanyFilter';
import { useOperationSearch } from './useOperationSearch';

export const useOperationFilterForm = () => {
  const {
    filterBy,
    setFilterBy,
    setSearchFilterOption,
    currentFilter,
    setCurrentFilter,
    openFilterDialog,
    setOpenFilterDialog,
  } = useOperationStore();

  const { user } = useAuth();
  const userIsConsultant = user?.userType === UserTypes.CONSULTANT;
  const listConsultantsOptions = openFilterDialog && userIsConsultant;
  const listCompanysOptions = openFilterDialog && !userIsConsultant;

  const { activeOptions } = useFindActiveOptions();
  const { consultantsOptions } = useListAllConsultants(openFilterDialog);
  const { creditorOptions } = useListCreditors(listConsultantsOptions);
  const { enterpriseOptions } = useListEnterprises(listConsultantsOptions);
  const {
    creditorFilterOptions,
    enterpriseFilterOptions,
  } = useListOptionsCompanysOperationFilter(listCompanysOptions);

  const { actOptions } = useListAllActs({
    type: TypeProcessAct.OPERATION,
    enabled: openFilterDialog,
  });

  const isFiltering = currentFilter.length > 0;

  const clearSearchOption = useCallback(() => setSearchFilterOption(''), []);

  const changeInitialFilter = () => {
    if (user?.userType === UserTypes.CREDITOR) {
      setFilterBy(DEFAULT_OPERATIONS_FILTER_CREDITOR);
    }
  };

  const resetFilters = useCallback(() => {
    setCurrentFilter([]);
  }, []);

  const resetSearchFilterOption = useCallback(() => {
    setSearchFilterOption('');
  }, []);

  const handleFilterChange = (value: OptionsValues[]) => {
    const addedFilter = value[value.length - 1];
    const newValues = [
      ...currentFilter,
      { filterBy: filterBy.value, ...addedFilter },
    ];
    setCurrentFilter(newValues);
  };

  const handleRemoveFilter = (option: IOperationsFilter) => {
    const newFilters = currentFilter.filter(item => item !== option);

    setCurrentFilter(newFilters);
  };

  const isOptionEqualToValue = (option, filter) => {
    return option.value === filter.value && option.label === filter.label;
  };

  const generateFilterParams = useMemo(() => {
    const filterParams = currentFilter.reduce((acc, item) => {
      if (!acc[item.filterBy]) {
        acc[item.filterBy] = [];
      }

      acc[item.filterBy].push(item.value);

      return acc;
    }, {});

    return filterParams;
  }, [currentFilter]);

  const removeDuplicateItems = (array: OptionsValuesString[]) => {
    const seenLabels = new Set();
    return array.filter(
      item => !seenLabels.has(item.label) && seenLabels.add(item.label),
    );
  };

  const getOptions = () => {
    let creditorsOptions = creditorOptions;
    let enterprisesOptions = enterpriseOptions;

    if (user?.userType === UserTypes.ENTERPRISE) {
      creditorsOptions = creditorFilterOptions;
    }

    if (user?.userType === UserTypes.CREDITOR) {
      enterprisesOptions = enterpriseFilterOptions;
    }

    const optionsList = {
      [IOperationFilterValue.CREDITOR]: removeDuplicateItems(creditorsOptions),
      [IOperationFilterValue.ENTERPRISE]: removeDuplicateItems(enterprisesOptions),
      [IOperationFilterValue.STATUS]: statusOptions,
      [IOperationFilterValue.ACTIVE_INACTIVE]: activeOptions,
      [IOperationFilterValue.RESPONSIBLE]: consultantsOptions,
      [IOperationFilterValue.OPERATION_TYPE]: operationTypeOptions,
      [IOperationFilterValue.LAST_ACT]: actOptions,
    };

    return optionsList[filterBy?.value] ?? [];
  };

  const getFilterOptions = () => {
    const userType = user?.userType;
    // Crie uma lista de chaves a serem removidas com base no tipo de usuário
    const keysToRemove: (keyof typeof IOperationFilterLabel)[] = [];

    if (userType === UserTypes.CREDITOR) {
      keysToRemove.push('CREDITOR');
    } else if (userType === UserTypes.ENTERPRISE) {
      keysToRemove.push('ENTERPRISE');
    }

    // Filtra as opções removendo as chaves especificadas
    const operationsFilterOptions: IOperationFilterOption[] = Object.keys(
      IOperationFilterLabel,
    )
      .filter(
        key => !keysToRemove.includes(key as keyof typeof IOperationFilterLabel),
      )
      .map(label => ({
        value: IOperationFilterValue[label as keyof typeof IOperationFilterLabel],
        label: IOperationFilterLabel[label as keyof typeof IOperationFilterLabel],
      }));

    return operationsFilterOptions;
  };

  const operationsFilterOptions = getFilterOptions();

  const { handleChangeSearch } = useOperationSearch(500, setSearchFilterOption);

  return {
    filterData: getOptions(),
    filterBy,
    currentFilter,
    operationsFilterOptions,
    isFiltering,
    openFilterDialog,
    setOpenFilterDialog,
    setSearchFilterOption,
    setFilterBy,
    resetFilters,
    resetSearchFilterOption,
    handleFilterChange,
    handleRemoveFilter,
    isOptionEqualToValue,
    clearSearchOption,
    handleChangeSearch,
    changeInitialFilter,
    generateFilterParams,
    filterParams: generateFilterParams,
    actOptions,
  };
};
