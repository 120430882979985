import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import FormAnswerServices from 'modules/dynamicForm/formAnswer/services/services';

const useSendFormRequest = (
  options?: UseMutationOptions<void, IApiAxiosError, string>,
) => {
  return useMutation('sendFormRequest', FormAnswerServices.finishForm, options);
};

export { useSendFormRequest };
