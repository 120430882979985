//import DialogFilter from '../components/DialogFilter';
import { DialogQuestionForm, ListQuestion, HeaderQuestion } from '../components';

//import { List } from '../components/List';
//import { useQuestionDialog } from '../hooks';

const ListQuestionsPage = () => {
  //const { form } = useQuestionDialog();

  return (
    <>
      <HeaderQuestion />
      <DialogQuestionForm />
      <ListQuestion />
      {/* // // <List />
      {/* <DialogFilter /> } */}
    </>
  );
};

export { ListQuestionsPage };
