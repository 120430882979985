import { IProcessActAttachment } from 'models';

import api from 'config/api';

import { IActDocumentsPayload } from './types';

class DocumentService {
  static async getOperationDocuments({ id }: IActDocumentsPayload) {
    const { data } = await api.get<IProcessActAttachment[]>(
      `/process-act/operation/${id}`,
    );

    return data;
  }

  static async getCustomerDocuments({ id }: IActDocumentsPayload) {
    const { data } = await api.get<IProcessActAttachment[]>(
      `/process-act/customer/${id}`,
    );

    return data;
  }
}

export default DocumentService;
