import { Divider, Grid, Typography } from '@mui/material';

import { ICompanyKMethod } from 'models';

import { KMethodItem } from './KMethodItem';

interface AnalysisProps {
  storage: ICompanyKMethod;
}

const Analysis = ({ storage }: AnalysisProps) => {
  return (
    <>
      <Grid xs={12} marginTop={1}>
        <Divider />
        <Typography fontWeight={700} fontSize={20} marginTop={1}>
          ANÁLISE
        </Typography>
      </Grid>

      {storage?.kMethod?.analysis?.map((item, index) => (
        <Grid container key={item.type} marginTop={1}>
          {index > 0 && (
            <Grid xs={12} marginBottom={1}>
              <Divider />
            </Grid>
          )}

          <KMethodItem title="Tipo" value={item.type} />
          <KMethodItem title="Total" value={item.total} />

          <Grid xs={12}>
            <Typography
              fontWeight={700}
              color="grey"
              textTransform={'uppercase'}
              fontSize={20}
              marginTop={1}
            >
              Operações
            </Typography>
          </Grid>

          {Object.entries(item.operations).map(op => {
            return typeof op[1] === 'object' ? (
              <Grid container>
                <Typography
                  fontWeight={700}
                  fontSize={20}
                  marginTop={1}
                  color="grey"
                >
                  {op[0]}
                </Typography>
                <Grid container>
                  {Object.entries(op[1]).map(([key, value]) => (
                    <KMethodItem
                      key={`${key}-${value}`}
                      title={String(key)}
                      value={String(value)}
                    />
                  ))}
                </Grid>
              </Grid>
            ) : (
              <KMethodItem title={op[0]} value={String(op[1])} />
            );
          })}
        </Grid>
      ))}
    </>
  );
};

export { Analysis };
