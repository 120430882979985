import {
  InputBaseComponentProps,
  FilledInputProps,
  InputProps,
  TextField as MuiTextField,
  OutlinedInputProps,
  InputLabelProps,
  SxProps,
  Theme,
} from '@mui/material';
import {
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: FieldError;
  label: string;
  type: string;
  required?: boolean;
  inputProps?: InputBaseComponentProps;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>;
  InputLabelProps?: InputLabelProps;
  rows?: number | string;
  maxRows?: number | string;
  multiline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
};

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  error,
  name,
  label,
  disabled,
  control,
  type,
  defaultValue,
  inputProps,
  InputProps,
  rows,
  maxRows,
  fullWidth = true,
  ...rest
}: Props<TFieldValues, TName>) {
  const {
    field: { onChange, value, ref, ...props },
  } = useController({ control, name, defaultValue });

  return (
    <MuiTextField
      id={name}
      label={label}
      disabled={disabled}
      onChange={onChange}
      value={value}
      fullWidth={fullWidth}
      error={Boolean(!!error)}
      ref={ref}
      helperText={error && <>{error.message}</>}
      type={type}
      inputProps={inputProps}
      InputProps={InputProps}
      rows={rows}
      maxRows={maxRows}
      {...rest}
      {...props}
    />
  );
}
