import { useMutation } from 'react-query';

import { removeTokens } from '..';
import { AuthService } from '../../../modules/signIn/services';
import { useUser } from './useUser';

export const useSignOut = () => {
  const { removeUser } = useUser();
  const mutation = useMutation('auth/sign-out', AuthService.signOut);

  const signOut = async () => {
    await mutation.mutateAsync();
    removeTokens();
    removeUser();
  };

  return { signOut };
};
