import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import themes from 'themes';

import { Box } from 'components';

import SignInButton from './SignInButton';

function SignInOptions() {
  const history = useHistory();

  const handleLoginRedirect = (route: string) => {
    history.push(route);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '8em',
      }}
      marginY="auto"
    >
      <SignInButton onClick={() => handleLoginRedirect('empresa')}>
        <Typography
          fontFamily={themes.fonts.body}
          variant="h4"
          fontSize={23}
          textAlign="center"
          fontWeight={700}
          color={themes.colors.blue}
          style={{
            maxWidth: '200px',
          }}
        >
          Portal de Empresas
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          fontFamily={themes.fonts.body}
        >
          Conecte-se com empresas em busca de financiamento de forma ágil e com
          suporte especializado!
        </Typography>
      </SignInButton>

      <SignInButton onClick={() => handleLoginRedirect('credor')}>
        <Typography
          fontFamily={themes.fonts.body}
          variant="h4"
          fontSize={23}
          textAlign="center"
          fontWeight={700}
          color={themes.colors.blue}
        >
          Portal de Instituições Financeiras
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          fontFamily={themes.fonts.body}
        >
          Gerencie suas operações de crédito com suporte especializado e
          inteligência <br /> de dados.
        </Typography>
      </SignInButton>
    </Box>
  );
}

export default SignInOptions;
