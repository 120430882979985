import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IForm } from 'models';
import { IPagination } from 'models/pagination/types';

import { FormService } from '../services/services';
import { useFormStore } from '../store/store';

export const useFindForms = () => {
  const {
    page,
    perPage,
    setPage,
    setList,
    setLimit,
    setCurrentForm,
    limit,
    search,
    currentFilter,
  } = useFormStore();

  const { data, isLoading, refetch } = useQuery(
    ['forms', page, search, currentFilter],
    () =>
      FormService.list({
        page,
        perPage,
        search: search,
      }) as Promise<IPagination<IForm>>,
    { keepPreviousData: true },
  );
  const noData = !data?.data || data.data.length === 0;
  const showPagination = !noData && data?.totalPages && data.totalPages > 1;

  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
      setList(data?.data);
    }
  }, [data]);

  useEffect(() => {
    setCurrentForm({} as IForm);
  }, []);

  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
    noData,
    showPagination,
  };
};
