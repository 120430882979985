import { useMemo } from 'react';

import { UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

const { CREDITOR, CONSULTANT, ENTERPRISE } = UserTypes;

export const useUserRole = () => {
  const { user } = useAuth();

  const isConsultant = useMemo(() => user?.role.name === UserTypes.CONSULTANT, []);

  const showEnterprise = useMemo(
    () => [CREDITOR, CONSULTANT].includes(user?.userType as UserTypes),
    [],
  );

  const showCreditor = useMemo(
    () => [ENTERPRISE, CONSULTANT].includes(user?.userType as UserTypes),
    [],
  );

  return {
    showEnterprise,
    showCreditor,
    isConsultant,
  };
};
