import styled from 'styled-components';

import Box from 'components/Box';

//todo: implemente css animation
const Item = styled(Box)`
  padding: ${props => props.theme.space[5]}px;
  margin: 0px;
  border-bottom: 1px solid ${props => props.theme.colors['gray-100']};
  background-color: ${props => props.theme.colors['white']};
  height: auto;
  :hover {
    background-color: ${props => props.theme.colors['gray-100']};
    cursor: pointer;
  }

  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
`;

export { Item };
