import { IFormAnswer, IFormAnswerAttachment, IFormQuestion } from 'models';

export interface IFormAnswereds {
  formQuestionId: string;
  answer: string;
  formAnswerId: string;
  formRecordId?: string;
}

export interface IFormAnswerPayload {
  questionId: string;
  formRecordId?: string;
  answerValue: unknown;
  formId: string;
  enterpriseId: string;
  answerAttachment: string[];
  actId: string;
  processActId: string;
  type: EFormAnswerType;
}

export interface IFormAnswerUpdatePayload {
  id: string;
  body: IFormAnswerPayload;
}

export interface ITriggerDocSignPayload {
  formRecordId?: string;
  formId: string;
  enterpriseId: string;
  actId: string;
  processActId: string;
}

export interface IFormAnswerResponse {
  formAnswer: IFormAnswer;
  nextQuestion: IFormQuestion | null;
  formRecordId?: string;
}

export interface IFinishLaterResponse {
  formQuestion: IFormQuestion;
  answer: {
    formQuestionId: string;
    answer: string;
    formAnswerId: string;
  };
  attachments: IFormAnswerAttachment[];
}

export interface IGetNextQuestionPayload {
  enterpriseId: string;
  formRecordId: string;
  expression: string;
}

export enum EFormAnswerType {
  answer = 'answer',
  simulation = 'simulation',
}
