import { Divider, Typography as MuiTypography, IconButton } from '@mui/material';

import { Box, Icon } from 'components';

interface IQuestionOptionProps {
  title: string;
  number: number;
  id: string;
  openRevisionQuestion: (id: string) => void;
  type: 'trail' | 'discarded';
}

export const QuestionOption = ({
  title,
  number,
  id,
  type,
  openRevisionQuestion,
}: IQuestionOptionProps) => {
  const isTrail = type === 'trail';
  return (
    <Box mt={6}>
      <Box
        ml={5}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        paddingLeft={5}
        paddingRight={5}
      >
        {isTrail ? (
          <MuiTypography>
            {number}
            {'. '}
            {title}
          </MuiTypography>
        ) : (
          <MuiTypography style={{ textDecoration: 'line-through' }}>
            {title}
          </MuiTypography>
        )}

        <IconButton onClick={() => openRevisionQuestion(id)}>
          <Icon fontSize={'h6'} color="black">
            open_in_new
          </Icon>
        </IconButton>
      </Box>
      <Box mt={5}>
        <Divider />
      </Box>
    </Box>
  );
};
