import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { validateDate } from 'utils/validateDate';

import { useTabActsStore } from '../store/store';

export interface ICreateAct {
  formId: string | null;
  answer: string | null;
  info: string | null;
  clientTypeInfo: string | null;
  dbInfo: string[] | null;
  productClient: string[] | null;
  createOpValues: string[] | null;
  operation: string | null;
  valueOp: string | null;
  scheduleLink: string | null;
  deadline: string | null;
  chargeLink: string | null;
  emailTitle: string | null;
  emailBody: string | null;
  motiveEnd: string | null;
}

export const defaultValuesActs = {
  formId: '',
  answer: '',
  info: '',
  clientTypeInfo: '',
  dbInfo: [],
  productClient: [],
  createOpValues: [],
  operation: '',
  valueOp: '',
  scheduleLink: '',
  deadline: '',
  chargeLink: '',
  emailTitle: '',
  emailBody: '',
  motiveEnd: '',
};

const permissionActArrayTypes = z.string();

export const useCreateProcessActForm = () => {
  const { currentAct, typeScreenForm } = useTabActsStore();

  const conf = currentAct.configs;
  const conficActSchema = z.object({
    formId:
      !conf?.triggerForm ||
      (conf?.formId && conf.formId.length > 0) ||
      typeScreenForm === 'edit'
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Selecione o formulário'),

    answer:
      !conf?.textInputAnswer || typeScreenForm === 'create'
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Preencha a resposta'),

    info:
      !conf?.showInfo || (conf?.info && conf.info.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Preencha o campo de apresentar informações'),

    clientTypeInfo:
      !conf?.showDbInfo || (conf?.dbInfo && conf.dbInfo.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Selecione o tipo de cliente'),

    dbInfo:
      !conf?.showDbInfo || (conf?.dbInfo && conf.dbInfo.length > 0)
        ? z.array(permissionActArrayTypes).optional()
        : z.array(permissionActArrayTypes).nonempty('Selecione um informação'),

    productClient:
      !conf?.defineProductClient ||
      (conf?.productClient && conf.productClient.length > 0)
        ? z.array(permissionActArrayTypes).optional()
        : z.array(permissionActArrayTypes).nonempty('Selecione um produto'),

    createOpValues:
      !conf?.createOp || (conf?.createOpValues && conf.createOpValues.length > 0)
        ? z.array(permissionActArrayTypes).optional()
        : z
            .array(permissionActArrayTypes)
            .nonempty('Selecione uma instituição financeira'),

    operation:
      !conf?.defineOp || (conf?.operation && conf.operation.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Selecione a operação'),

    valueOp:
      !conf?.defineValueOp || (conf?.valueOp && conf.valueOp.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Insira o valor da operação'),

    scheduleLink:
      !conf?.showScheduleLink ||
      (conf?.scheduleLink && conf.scheduleLink.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Insira o link da agenda'),

    deadline:
      !conf?.showDeadline || (conf?.deadline && conf.deadline.length > 0)
        ? z
            .string()
            .trim()
            .optional()
            .refine(deadline => {
              if (deadline) return validateDate(deadline);
              return true;
            }, 'Data inválida')
        : z
            .string()
            .trim()
            .nonempty('Insira o prazo')
            .refine(deadline => {
              if (deadline) return validateDate(deadline);
              return true;
            }, 'Data inválida'),
    chargeLink:
      !conf?.allowCharge || (conf?.chargeLink && conf.chargeLink.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Insira o link da cobrança'),

    emailTitle:
      !conf?.sendEmail || (conf?.emailTitle && conf.emailTitle.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Insira o título do email'),

    emailBody:
      !conf?.sendEmail || (conf?.emailBody && conf.emailBody.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Insira o corpo do email'),

    motiveEnd:
      !conf?.negotiationEnd || (conf?.motiveEnd && conf?.motiveEnd.length > 0)
        ? z.string().trim().optional()
        : z.string().trim().nonempty('Selecione o motivo do encerramento'),
  });

  return useForm<ICreateAct>({
    defaultValues: defaultValuesActs,
    mode: 'onBlur',
    resolver: zodResolver(conficActSchema),
  });
};
