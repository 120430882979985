import { Dispatch, SetStateAction, createContext, useState } from 'react';

interface PrivateLayoutProps {
  sidebarToggled: boolean;
  setSidebarToggled: Dispatch<SetStateAction<boolean>>;
  sidebarBroken: boolean;
  setSidebarBroken: Dispatch<SetStateAction<boolean>>;
}

export const PrivateLayoutContext = createContext<PrivateLayoutProps>(
  {} as PrivateLayoutProps,
);

export function PrivateLayoutProvider({ children }) {
  const [sidebarToggled, setSidebarToggled] = useState<boolean>(false);
  const [sidebarBroken, setSidebarBroken] = useState<boolean>(false);

  return (
    <PrivateLayoutContext.Provider
      value={{
        sidebarToggled,
        setSidebarToggled,
        sidebarBroken,
        setSidebarBroken,
      }}
    >
      {children}
    </PrivateLayoutContext.Provider>
  );
}
