/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  AutocompleteProps as MUIAutocompleteProps,
  TextField,
} from '@mui/material';
import {
  FieldError,
  FieldPath,
  FieldValues,
  Merge,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { OptionsValues, OptionsValuesString } from 'utils/helper';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: Merge<FieldError, FieldError[] | undefined>;
  label: string;
  stringValue?: boolean;
  options: OptionsValuesString[] | OptionsValues[]; //OptionsValues[]
  onInputChange?: (value: string) => void;
  multiple?: boolean;
  disabled?: boolean;
};

type AutocompleteProps = Omit<
  MUIAutocompleteProps<any, boolean, boolean, boolean>,
  'options' | 'renderInput' | 'onChange' | 'value'
>;

const ConfigActsAutocomplete = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  name,
  control,
  defaultValue,
  label,
  error,
  options,
  stringValue = false,
  onInputChange,
  disabled,
  multiple = false,
  ...rest
}: Omit<AutocompleteProps, 'error'> & Props<TFieldValues, TName>) => {
  const {
    field: { onChange, value, ...props },
  } = useController({ control, name, defaultValue });

  return (
    <AutocompleteWrapper
      options={options}
      value={value}
      freeSolo={false}
      fullWidth
      multiple={multiple}
      filterSelectedOptions
      onChange={(_, data: any) => {
        if (multiple) {
          if (stringValue) {
            const item = (data || []).map((item: any) => {
              return item?.label || item;
            });
            onChange(item);
            return;
          }
          onChange((data || []).map((item: any) => item?.value || item));
          return;
        }
        if (stringValue) {
          // guarda o valor do label <OptionsValues>
          onChange(data?.label || data || '');
          return;
        }
        onChange(data?.value || data || '');
      }}
      getOptionLabel={(option: any) => {
        return option?.label || option || '';
      }}
      isOptionEqualToValue={(option: any, value: any) => {
        if (multiple) {
          return option.value === value;
        }
        if (stringValue) {
          return option.label === value;
        }
        return option.value === value.value;
      }}
      noOptionsText="Nenhum resultado encontrado"
      defaultValue={defaultValue}
      disabled={disabled}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          error={Boolean(!!error)}
          helperText={error && <>{error.message}</>}
          onChange={e => {
            if (onInputChange) {
              onInputChange(e.target.value);
            }
          }}
        />
      )}
      {...props}
      {...rest}
    />
  );
};

export default ConfigActsAutocomplete;
