import { Icon } from 'components';

import { PreviousWrapper } from './styles';

interface PreviousProps {
  open?: boolean;
  onClick: () => void;
}

export function Previous({ open = true, onClick }: PreviousProps) {
  return (
    <PreviousWrapper
      key="prev"
      open={open}
      icon={<Icon>chevron_left</Icon>}
      onClick={onClick}
    />
  );
}
