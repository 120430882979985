import { Box } from '@mui/material';

import { useDashboardCreditor } from '../hooks';
import { EmptyDashboard } from './EmptyDashboard';
import { OperationStatusChart } from './charts';
import { OperationStatusTable } from './tables';

const DashboardCreditor = () => {
  const { operationsNoData } = useDashboardCreditor();

  if (operationsNoData) {
    return <EmptyDashboard />;
  }

  return (
    <Box>
      <Box marginTop={3}>
        <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
          <OperationStatusTable />
          <OperationStatusChart />
        </Box>
      </Box>
    </Box>
  );
};

export { DashboardCreditor };
