import { create } from 'zustand';

import { DEFAULT_PAGINATION } from 'utils/helper';

type State = {
  search: string;

  page: number;
  perPage: number;
  limit: boolean;
};

interface Actions {
  setSearch: (searchTerm: string) => void;

  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;

  clearDocumentStore: () => void;
}

const initialState: State = {
  search: '',

  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,
};

const useDocumentStore = create<State & Actions>()(set => ({
  ...initialState,
  setSearch: search => set({ search }),

  setPage: page => set({ page }),
  setPerPage: perPage => ({ perPage }),
  setLimit: limit => set({ limit }),

  clearDocumentStore: () => set(initialState),
}));

export { useDocumentStore };
