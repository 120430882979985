import { IFormQuestion, IFormRecord, IRole, IUser } from 'models';

export interface IForm {
  id: string;
  title: string;
  caption: string;
  questions: IFormQuestion[];
  records: IFormRecord[];
  active: boolean;
  user: IUser;
  userPermission: IRole;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
}

export enum UserPermissionForm {
  'ADMIN' = 'Administrador',
  'COLLABORATOR' = 'Colaborador',
}
