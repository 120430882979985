import { Dialog, Box } from 'components';

import { useQuestionDialog } from '../../hooks';
import { useQuestionStore } from '../../store/store';
import StepperDialog from '../Stepper/StepperDialog';

export const DialogQuestionForm = () => {
  const { openFormDialog, handleCloseFormDialog } = useQuestionDialog();
  const { currentQuestion } = useQuestionStore();

  return (
    <Dialog.Root open={openFormDialog} onClose={handleCloseFormDialog}>
      <Box as="form">
        <Dialog.Header
          title={currentQuestion?.id ? 'Editar Pergunta' : 'Nova Pergunta'}
        />
        <StepperDialog />
      </Box>
    </Dialog.Root>
  );
};
