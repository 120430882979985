import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IEnterprise, TypeProcessAct } from 'models';

import { CustomerQueryKey } from 'modules/boards/customerManagement';
import { IOperationResponse, OperationQueryKey } from 'modules/boards/operations';
import { useListAllConsultants } from 'modules/user/hooks';

import { useUpdateResponsibleActRequest } from '../components/tabActs/hooks';
import { typeChangeResponsible, useTabsStore } from '../store/store';
import {
  useUpdateResponsibleClientRequest,
  useUpdateResponsibleOperationRequest,
} from './';

export const useTabs = () => {
  const queryClient = useQueryClient();
  const {
    openNegotiationModal,
    valueTab,
    currentTabInfos,
    openChangeResponsibleModal,
    openSupportExFinModal,
    newResponsible,
    typeChangeResponsible,
    idChangeResponsible,
    setValueTab,
    setOpenNegotiationModal,
    setOpenSupportExFinModal,
    setOpenChangeResponsibleModal,
    setTypeChangeResponsible,
    setNewResponsible,
    setIdChangeResponsible,
    setCurrentTabInfos,
    clearTabsStore,
  } = useTabsStore();
  const { consultantsOptions } = useListAllConsultants(openChangeResponsibleModal);

  const handleOpenNegotiationDialog = async (
    currenTypeOp: TypeProcessAct,
    customer?: IEnterprise,
    operation?: IOperationResponse,
  ) => {
    if (currenTypeOp === TypeProcessAct.CLIENT) {
      setCurrentTabInfos({
        typeOp: TypeProcessAct.CLIENT,
        creditorName:
          customer?.bigData?.fantasyName || customer?.bigData?.socialReason || '',
        responsibleOperation: customer?.responsible?.name ?? '',
        responsibleId: customer?.responsible?.id,
        enterpriseId: customer?.id ?? '',
        customerIsFinancing: customer?.isFinancing ?? false,
      });
    }

    if (currenTypeOp === TypeProcessAct.OPERATION) {
      setCurrentTabInfos({
        typeOp: currenTypeOp,
        enterpriseName:
          operation?.enterprise?.bigData?.fantasyName ||
          operation?.enterprise?.bigData?.socialReason ||
          '',
        creditorName:
          operation?.creditor?.bigData?.fantasyName ||
          operation?.creditor?.bigData?.socialReason ||
          '',
        responsibleOperation: operation?.responsible?.name ?? '',
        responsibleId: operation?.responsible?.id,
        enterpriseId: operation?.enterprise?.id ?? '',
        creditorId: operation?.creditor?.id ?? '',
        operationId: operation?.id ?? '',
        customerIsFinancing: operation?.enterprise?.isFinancing ?? false,
      });
    }

    setOpenNegotiationModal(true);
  };

  const handleCloseDialog = () => {
    setOpenNegotiationModal(false);
    clearTabsStore();
  };

  const handleChangeTab = (event: unknown, newValue: number) => {
    setValueTab(newValue);
  };

  function getProps(index: number) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleOpenChangeResponsibleModal = (
    responsible: string,
    type: typeChangeResponsible,
    id: string,
  ) => {
    // apenas pra mostrar o responsavel atual no combobox qdo abrir a primeira vez;
    setNewResponsible({ label: responsible, value: responsible });
    setOpenChangeResponsibleModal(true);
    setTypeChangeResponsible(type);
    setIdChangeResponsible(id);
  };

  const updateResponsibleAct = useUpdateResponsibleActRequest({
    onSuccess: () => {
      toast.success('Responsável pelo ato editado com sucesso!');
      queryClient.invalidateQueries('filterProcessActs');
      setOpenChangeResponsibleModal(false);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o responsável pelo ato');
    },
  });

  const setNewResponsibleName = () => {
    const newTabInfo = currentTabInfos;
    newTabInfo.responsibleOperation = newResponsible.label;
    setCurrentTabInfos(newTabInfo);
  };

  const updateResponsibleOperation = useUpdateResponsibleOperationRequest({
    onSuccess: () => {
      toast.success('Responsável pela operação editado com sucesso!');
      queryClient.invalidateQueries(OperationQueryKey.INFINITE_OPERATIONS);
      setOpenChangeResponsibleModal(false);
      setNewResponsibleName(); // apenas pra ja setar o novo responsavel sem atualizar pagina
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o responsável pela operação');
    },
  });

  const updateResponsibleClient = useUpdateResponsibleClientRequest({
    onSuccess: () => {
      toast.success('Responsável pela cliente editado com sucesso!');
      queryClient.invalidateQueries(CustomerQueryKey.LIST_CUSTOMERS);
      setOpenChangeResponsibleModal(false);
      setNewResponsibleName(); // apenas pra ja setar o novo responsavel sem atualizar pagina
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o responsável pelo cliente');
    },
  });

  const handleChangeResponsible = async (type: typeChangeResponsible) => {
    if (type === 'act') {
      updateResponsibleAct.mutate({
        id: idChangeResponsible,
        newResponsible: newResponsible.value,
      });
    }

    if (type === 'operation') {
      updateResponsibleOperation.mutate({
        id: idChangeResponsible,
        newResponsible: newResponsible.value,
      });
    }

    if (type === 'client') {
      updateResponsibleClient.mutate({
        id: idChangeResponsible,
        newResponsible: newResponsible.value,
      });
    }
  };

  return {
    openNegotiationModal,
    valueTab,
    currentTabInfos,
    openChangeResponsibleModal,
    openSupportExFinModal,
    newResponsible,
    consultantsOptions,
    typeChangeResponsible,
    getProps,
    handleOpenNegotiationDialog,
    handleChangeResponsible,
    setNewResponsible,
    handleOpenChangeResponsibleModal,
    setOpenChangeResponsibleModal,
    setOpenSupportExFinModal,
    handleCloseDialog,
    handleChangeTab,
  };
};
