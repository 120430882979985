import { useMutation, UseMutationOptions } from 'react-query';

import { Operation } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateResponsible } from '../components';
import { TabsService } from '../services';

const useUpdateResponsibleOperationRequest = (
  options?: UseMutationOptions<Operation, IApiAxiosError, IUpdateResponsible>,
) => {
  return useMutation(
    'updateResponsibleOperation',
    TabsService.updateResponsibleOperation,
    options,
  );
};

export { useUpdateResponsibleOperationRequest };
