import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { EmailService } from 'modules/email/services';
import {
  IValidateCodePayload,
  IValidateCodeResponse,
} from 'modules/email/services';

const useValidateCodeRequest = (
  options?: UseMutationOptions<
    IValidateCodeResponse,
    IApiAxiosError,
    IValidateCodePayload
  >,
) => {
  return useMutation('validateCode', EmailService.validateCode, options);
};

export { useValidateCodeRequest };
