import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IForgotPasswordCodePayload } from '../services';

export const forgotPasswordCodeSchema = z.object({
  code: z.string().trim().nonempty('Código é obrigatório'),
});

const defaultValues = {
  code: '',
};

export const useForgotPasswordCodeForm = () => {
  return useForm<IForgotPasswordCodePayload>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(forgotPasswordCodeSchema),
  });
};
