import { create } from 'zustand';

import { IFormAnswer } from 'models';

type State = {
  openModalCancel: boolean;
  openModalFinishLater: boolean;
  openModalSendForm: boolean;
  formAnswers: IFormAnswer[];
  revisionQuestion: IFormAnswer;
  isSendingDocSign: boolean;
};

interface Actions {
  setOpenModalCancel: (openModalCancel: boolean) => void;
  setOpenModalFinishLater: (openModal: boolean) => void;
  setOpenModalSendForm: (openModal: boolean) => void;
  setFormAnswers: (formAnswers: IFormAnswer[]) => void;
  setRevisionQuestion: (revisionQuestion: IFormAnswer) => void;
  setIsSendingDocSign: (isSendingDocSign: boolean) => void;
}

const initialState: State = {
  openModalCancel: false,
  openModalFinishLater: false,
  openModalSendForm: false,
  formAnswers: [],
  revisionQuestion: {} as IFormAnswer,
  isSendingDocSign: false,
};

const useFormResumeStore = create<State & Actions>()(set => ({
  ...initialState,
  setOpenModalCancel: openModalCancel => set({ openModalCancel }),
  setOpenModalFinishLater: openModalFinishLater => set({ openModalFinishLater }),
  setOpenModalSendForm: openModalSendForm => set({ openModalSendForm }),
  setFormAnswers: formAnswers => set({ formAnswers }),
  setRevisionQuestion: revisionQuestion => set({ revisionQuestion }),
  setIsSendingDocSign: isSendingDocSign => set({ isSendingDocSign }),
}));

export default useFormResumeStore;
