import { Grid, Typography } from '@mui/material';

import { ICompanyKMethod } from 'models';

import { KMethodItem } from './KMethodItem';

interface CompanyInfoProps {
  storage: ICompanyKMethod;
}

const CompanyInfo = ({ storage }: CompanyInfoProps) => {
  const companyName =
    storage.enterprise?.bigData?.fantasyName ||
    storage.enterprise?.bigData?.socialReason;

  return (
    <>
      <KMethodItem title="CNPJ" value={storage.cnpj} />
      <KMethodItem title="Nome fantasia" value={companyName} />
      <KMethodItem title="Régua K" value={String(storage.kScale)} />
      <KMethodItem title="UF" value={storage.kMethod.uf} />
      <KMethodItem
        title="Data de abertura"
        value={new Date(storage.kMethod.openingDate).toLocaleDateString('pt-BR')}
      />

      <Grid xs={12} marginTop={2}>
        <Typography
          fontWeight={700}
          fontSize={20}
          color={'grey'}
          textTransform={'uppercase'}
        >
          CNAE
        </Typography>
      </Grid>

      <Grid container>
        <KMethodItem title="Código" value={storage.kMethod.cnae.code} />
        <KMethodItem title="Setor" value={storage.kMethod.cnae.sector} />
        <KMethodItem title="Descrição" value={storage.kMethod.cnae.description} />
        <KMethodItem
          title="Atividade comercial"
          value={storage.kMethod.cnae.businessActivity}
        />
      </Grid>
    </>
  );
};

export { CompanyInfo };
