import {
  StorageDialogFilter,
  StorageSearchContainer,
  StorageTable,
} from '../components';

const StoragePage = () => {
  return (
    <>
      <StorageSearchContainer />
      <StorageDialogFilter />
      <StorageTable />
    </>
  );
};

export { StoragePage };
