import { toast } from 'react-toastify';

import { EmailService } from 'modules/email';

import { useForgotPasswordStore } from '../store/store';
import { useForgotPasswordEmailForm } from './useForgotPasswordEmailForm';
import { useForgotPasswordEmailRequest } from './useForgotPasswordEmailRequest';

export const useForgotPasswordEmail = () => {
  const form = useForgotPasswordEmailForm();
  const {
    setEmailValidated,
    setStep,
    forgotPasswordPayload,
    setForgotPasswordPayload,
  } = useForgotPasswordStore();

  const request = useForgotPasswordEmailRequest({
    onSuccess: data => {
      setForgotPasswordPayload({
        ...forgotPasswordPayload,
        name: data.payload?.name,
      });

      EmailService.sendEmailValidationCode({
        email: forgotPasswordPayload.email,
        name: data.payload?.name,
        isRecover: true,
      });

      toast.success('Código de validação enviado para seu email.');

      setStep();
      setEmailValidated(true);
    },
    onError: () => {
      toast.error('Email não cadastrado');
    },
  });

  const handleSubmitForm = form.handleSubmit(data => {
    setForgotPasswordPayload({
      ...forgotPasswordPayload,
      email: data.email,
    });

    request.mutate({
      email: data.email,
    });
  });

  return {
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseForgotPasswordEmail = ReturnType<typeof useForgotPasswordEmail>;
