import { Download } from '@mui/icons-material';

import { Loader } from 'components';
import { ContainedWrapper } from 'components/Buttons/Contained/styles';

interface DialogDatabaseProps {
  loading: boolean;
  handleDownload: () => void;
}

const ButtonDownloadExcel = ({ loading, handleDownload }: DialogDatabaseProps) => {
  return (
    <ContainedWrapper
      onClick={handleDownload}
      startIcon={<Download />}
      customColor={'white'}
      fullWidth={true}
      loading={loading}
      variant="contained"
      type="button"
      customTextColor={'blue'}
    >
      {loading && <Loader />}
      <span>Baixar Excel</span>
    </ContainedWrapper>
  );
};
export { ButtonDownloadExcel };
