import { Box, Grid, Typography } from '@mui/material';

interface Props {
  title: string;
  value?: string | number | null;
}

export const KMethodItem = ({ title, value }: Props) => {
  return (
    <Grid item xs={12} lg={6}>
      <Box display="flex" gap={2}>
        <Typography textTransform="capitalize" fontWeight={700}>
          {title}:{' '}
        </Typography>
        {<Typography>{value ?? `---`}</Typography>}
      </Box>
    </Grid>
  );
};
