export const validateCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  let weight = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj[i]) * weight;
    weight--;
    if (weight === 1) {
      weight = 9;
    }
  }
  const firstDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  // Calcula o segundo dígito verificador
  sum = 0;
  weight = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj[i]) * weight;
    weight--;
    if (weight === 1) {
      weight = 9;
    }
  }
  const secondDigit = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  // Verifica se os dígitos verificadores são iguais aos fornecidos
  if (parseInt(cnpj[12]) === firstDigit && parseInt(cnpj[13]) === secondDigit) {
    return true;
  } else {
    return false;
  }
};
