import { Icon, Divider } from '@mui/material';

import { Box, Typography } from 'components';

import { useCardConditionQuestionTypesInput } from 'modules/dynamicForm/form/hooks';
import { IQuestionForm } from 'modules/dynamicForm/form/services';

import { DetailsCardConditionText } from './DetailsCardConditionText';

interface CardConditionTextProps {
  question: IQuestionForm;
  identifierCondition: number;
}

export const CardConditionText = ({
  question,
  identifierCondition,
}: CardConditionTextProps) => {
  const openDropdown = question?.conditions[identifierCondition - 1]?.openDropdown;
  const conditionsUntilThisCondition = question?.conditions.slice(
    0,
    identifierCondition,
  );

  const numberConditionsExludeUntilThisCondition = conditionsUntilThisCondition?.filter(
    question => question.identifierCondition === -1,
  );

  const numberRenderCondition = //calculando numero da question de acordo com as que ja foram excluidas
    identifierCondition - numberConditionsExludeUntilThisCondition?.length;

  const {
    handleOpenDropdownCondition,
    handleDeleteCardCondition,
  } = useCardConditionQuestionTypesInput();

  return (
    <Box
      border="1px solid gray"
      borderRadius="5px"
      marginTop="1.5em"
      display="flex"
      flexDirection="column"
      paddingX="1.5em"
      paddingY="1em"
      width={'100%'}
    >
      <Box width={'100%'}>
        <Box
          style={{ display: 'flex', float: 'right' }}
          onClick={() =>
            handleDeleteCardCondition(question.numberQuestion, identifierCondition)
          }
          width={40}
        >
          <Icon style={{ cursor: 'pointer' }} fontSize="medium">
            delete
          </Icon>
        </Box>
        <Box
          style={{ display: 'flex', float: 'right' }}
          onClick={() =>
            handleOpenDropdownCondition(
              question.numberQuestion,
              identifierCondition,
            )
          }
          width={40}
        >
          <Icon style={{ cursor: 'pointer' }} fontSize="medium">
            {openDropdown ? 'expand_more' : 'expand_less'}
          </Icon>
        </Box>
        <Typography>Condição {numberRenderCondition}</Typography>
      </Box>
      <Box mt={5} mb={5}>
        <Divider />
      </Box>

      {openDropdown && (
        <DetailsCardConditionText
          question={question}
          identifierCondition={identifierCondition}
        />
      )}
    </Box>
  );
};
