import styled from 'styled-components';

import { Box } from 'components';

const TitleWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.space[5]}px;
`;

export { TitleWrapper };
