import styled from 'styled-components';

import { Box } from 'components';

const ContentWrapper = styled(Box)`
  flex: 1;
  display: flex;
  padding: ${({ theme }) => theme.space[4]}px;
  background-color: ${({ theme }) => theme.colors['gray-100']};
`;

export { ContentWrapper };
