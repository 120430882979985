import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { List, ListItem, ListItemText } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';

export const PasswordInfoRequired = () => {
  return (
    <List style={{ padding: 0, marginTop: 15 }}>
      <ListItem style={{ padding: '2px 0', marginLeft: 25 }}>
        <ListItemIcon style={{ minWidth: 'auto', marginRight: 10 }}>
          <FiberManualRecordIcon style={{ fontSize: 8 }} />
        </ListItemIcon>
        <ListItemText
          secondary="Use pelo menos 8 caracteres."
          style={{ margin: 0 }}
        />
      </ListItem>
      <ListItem style={{ padding: '2px 0', marginLeft: 25 }}>
        <ListItemIcon style={{ minWidth: 'auto', marginRight: 10 }}>
          <FiberManualRecordIcon style={{ fontSize: 8 }} />
        </ListItemIcon>
        <ListItemText secondary="Use um número no mínimo." style={{ margin: 0 }} />
      </ListItem>
      <ListItem style={{ padding: '2px 0', marginLeft: 25 }}>
        <ListItemIcon style={{ minWidth: 'auto', marginRight: 10 }}>
          <FiberManualRecordIcon style={{ fontSize: 8 }} />
        </ListItemIcon>
        <ListItemText
          secondary="Use um caracter especial (!, @, #, $, %, etc.)."
          style={{ margin: 0 }}
        />
      </ListItem>
      <ListItem style={{ padding: '2px 0', marginLeft: 25 }}>
        <ListItemIcon style={{ minWidth: 'auto', marginRight: 10 }}>
          <FiberManualRecordIcon style={{ fontSize: 8 }} />
        </ListItemIcon>
        <ListItemText
          secondary="Use uma letra maiúscula no mínimo."
          style={{ margin: 0 }}
        />
      </ListItem>
    </List>
  );
};
