import { Table, TableCell } from '@mui/material';

import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useActOperationDocuments } from '../hooks';
import { DocumentsItem } from './DocumentsItem';

interface Props {
  operationId: string;
}

const DocumentsOperationTable = ({ operationId }: Props) => {
  const { data, isLoading, noData } = useActOperationDocuments(operationId);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle3">Nome</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Data de Upload</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Atos</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Usuário</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Status</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Baixar</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          columns={6}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhum documento encontrado"
        >
          {data?.map(document => (
            <DocumentsItem
              key={document.id}
              document={document}
              origin="operations"
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsOperationTable;
