import { Card, Table, TableCell } from '@mui/material';
import html2canvas from 'html2canvas';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import themes from 'themes';

import { TableBody, TableContainer, TableRow, Typography } from 'components';

import { Balance, MapHighlight } from 'modules/dashboard/services';
import { useDashboardStore } from 'modules/dashboard/store/store';

interface Props {
  title?: string;
  titleSession: string;
  total?: string;
  current?: string;
  data: Balance | null;
  isLoading: boolean;
  noData: boolean;
  mapping: MapHighlight[];
}

function checkIsMapped(value: string | number, mapping: MapHighlight[]) {
  const isMapped = mapping.find(val => val.name === value);
  if (isMapped) return true;
  return false;
}

function getColor(value: string | number, mapping: MapHighlight[]) {
  const color = mapping.find(val => val.name === value)?.color;
  if (!color) return themes.colors.dashboard.table.text.black;

  const returnWhite = color === themes.colors.dashboard.table.header.secondary;

  if (returnWhite) return '#FFFF';

  return themes.colors.dashboard.table.text.black;
}

function getValueMoney(value: string | number) {
  return value.toLocaleString('pt-BR', {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: 2,
  });
}

const titleSessionMapping = {
  'Balanço - Ativos': 'currentAssets',
  'Balanço - Passivos': 'currentPassives',
  DRE: 'dre',
  'Índices e Indicadores': 'indexesAndIndicators',
};

const DashboardTableContainer = ({
  title,
  data,
  isLoading,
  noData,
  mapping,
  titleSession,
}: Props) => {
  const sizeRow = 40;
  const headerRow = 50;
  const heightWindow = window.innerHeight;
  const widthTable = heightWindow > 900 ? '97%' : '95.8%';

  const { setTablesImagesUrl } = useDashboardStore();
  const tableRef = useRef<HTMLDivElement>(null);

  const captureComponent = useCallback(async (ref, key) => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current);
      const imageData = canvas.toDataURL('image/png');

      setTablesImagesUrl(key, imageData, canvas.width, canvas.height);
    }
  }, []);

  useEffect(() => {
    const captureTable = async () => {
      await captureComponent(tableRef, titleSessionMapping[titleSession]);
    };

    if (tableRef) {
      captureTable();
    }
  }, []);

  return (
    <Card style={{ width: widthTable, padding: 25, margin: 0 }}>
      <Typography
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        color={themes.colors.dashboard.table.header.main}
      >
        {titleSession ?? ''}
      </Typography>
      <div ref={tableRef}>
        <TableContainer sx={{ padding: 0, margin: 0, mt: 2 }}>
          <Table>
            <TableRow
              sx={{
                backgroundColor: themes.colors.dashboard.table.header.main,
                maxHeight: headerRow,
                height: headerRow,
                padding: 0,
              }}
              hover={false}
            >
              <TableCell
                align="left"
                sx={{
                  maxHeight: headerRow,
                  height: headerRow,
                  padding: 0,
                  paddingLeft: 2,
                }}
              >
                <Typography color="#FFF" fontWeight={700}>
                  {title ?? ''}
                </Typography>
              </TableCell>
              {data?.dates.map((date, index) => (
                <TableCell
                  align="center"
                  key={`${date}-${index}`}
                  sx={{
                    maxHeight: headerRow,
                    height: headerRow,
                    padding: 0,
                    borderLeft:
                      date?.length > 0
                        ? `1px solid ${themes.colors.white}`
                        : undefined,
                  }}
                >
                  <Typography color="#FFF" fontWeight={700}>
                    {date}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>

            <TableBody
              columns={data?.maxColumns ?? 1}
              isLoading={isLoading}
              empty={noData}
              emptyMessage="Nenhuma informação"
            >
              {data?.data.map((asset, index) => (
                <Fragment key={`${asset.name}-${index}`}>
                  <TableRow
                    hover={false}
                    sx={{
                      backgroundColor:
                        mapping.find(val => val.name === asset.name)?.color ?? '',
                      height: sizeRow,
                      maxHeight: sizeRow,
                      padding: 0,
                      paddingLeft: 2,
                    }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        height: sizeRow,
                        maxHeight: sizeRow,
                        padding: 0,
                        paddingLeft: 2,
                      }}
                    >
                      <Typography
                        fontWeight={checkIsMapped(asset.name, mapping) ? 700 : 400}
                        color={getColor(asset.name, mapping)}
                      >
                        {asset.name}
                      </Typography>
                    </TableCell>
                    {asset.values.map((value, index) => (
                      <TableCell
                        align="center"
                        key={`${asset.name}-${index}`}
                        sx={{
                          height: sizeRow,
                          maxHeight: sizeRow,
                          padding: 0,
                        }}
                      >
                        <Typography
                          fontWeight={
                            checkIsMapped(asset.name, mapping) ? 700 : 400
                          }
                          color={getColor(asset.name, mapping)}
                        >
                          {getValueMoney(value)}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
};

export { DashboardTableContainer };
