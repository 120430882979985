import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IDynamicForm } from '../services';

export const useDynamicFormForm = () => {
  const userSchema = z.object({
    title: z.string().trim().nonempty('Preencha o nome do formulário'),
    caption: z.string().optional(),
    userPermission: z
      .string()
      .trim()
      .nonempty('Selecione o tipo de usuário q pode responder'),
  });

  const defaultValues = {
    title: '',
    caption: '',
    userPermission: '',
  };

  return useForm<IDynamicForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userSchema),
  });
};
