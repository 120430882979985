import { useMutation, UseMutationOptions } from 'react-query';

import { IUser } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUserUpdateRequest, UserService } from '../services';

const useUpdateInternalUserRequest = (
  options?: UseMutationOptions<IUser, IApiAxiosError, IUserUpdateRequest>,
) => {
  return useMutation('udpateInternalUser', UserService.update, options);
};

export { useUpdateInternalUserRequest };
