import styled from 'styled-components';

import { Box } from 'components';

const List = styled(Box)`
  margin: 0px;
  max-height: 250px;
  padding: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-100']};
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${({ theme }) => theme.colors['gray-100']};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors['gray-300']};
    border-radius: 12px;
  }
`;

const ListItem = styled(Box)`
  padding: ${({ theme }) => theme.space[3]}px ${({ theme }) => theme.space[5]}px;
  transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  :hover {
    background-color: ${props => props.theme.colors['gray-100']};
    cursor: pointer;
  }
`;

const EmptyListWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-100']};
  padding: ${({ theme }) => theme.space[5]}px;
`;

export { List, ListItem, EmptyListWrapper };
