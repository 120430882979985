import { Edit } from '@mui/icons-material';
import { TableCell, Icon, Checkbox } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { IQuestion, QuestionType } from 'models';

import { useQuestionDialog, useQuestionItem } from '../hooks';

export const ItemQuestion = (value: IQuestion) => {
  const { handleOpenFormDialog } = useQuestionDialog();
  const { valueCheckBox, handleInactiveQuestion } = useQuestionItem();

  const { colors, fontSizes } = themes;
  const emptyString = '---';

  return (
    <>
      <TableRow key={value.id} style={{ cursor: 'default' }}>
        <TableCell width={500}>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value?.title ?? emptyString}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {QuestionType[value.type]}
          </Typography>
        </TableCell>
        <TableCell width="60px">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TypographyActive
              style={{ width: '40px', fontSize: `${fontSizes.s}px` }}
              textAlign="center"
              backgroundColor={value.active ? colors.green : colors.error}
            >
              {value.active ? 'Ativo' : 'Inativo'}
            </TypographyActive>
          </div>
        </TableCell>
        <TableCell align="center" padding="checkbox">
          <Icon>
            <Typography
              onClick={() => handleOpenFormDialog({ ...value })}
              style={{ cursor: 'pointer' }}
            >
              <Edit />
            </Typography>
          </Icon>
        </TableCell>
        <TableCell width={50} align="center" padding="checkbox">
          <Typography>
            <Checkbox
              checked={valueCheckBox}
              disabled={!value.active}
              size={'medium'}
              onChange={() => handleInactiveQuestion(value.id)}
            />
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
