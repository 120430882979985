import { Icon } from 'components';

import { PrivateLayout } from 'layouts/PrivateLayout';

import useFormAnswerStore from '../store/store';

const FormAnswerHeader = () => {
  const { isRevisionQuestion } = useFormAnswerStore();

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Title
          title={isRevisionQuestion ? 'Revisão de Questão' : 'Formulário'}
          icon={<Icon>live_help</Icon>}
        />
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { FormAnswerHeader };
