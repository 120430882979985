export enum Modules {
  CONFIGS = 'configs',
  PRODUCTS = 'products',
  ROLES = 'roles',
}

export enum RolesTypes {
  'ADMIN' = 'Administrador',
  'COLABORATOR' = 'Colaborador',
  'CONSULTANT' = 'Consultor',
}

export enum RolesTypesEnum {
  'Administrador' = 'Administrador',
  'Colaborador' = 'Colaborador',
  'Consultor' = 'Consultor',
}

export type IRolesTypes = keyof typeof RolesTypes;

export interface IPermissionsItem {
  view: boolean;
  edit: boolean;
  remove: boolean;
  show_clients_board: boolean;
}

export interface IPermissions {
  name: string;
  description: string;
}

export interface IModule {
  name: string;
  initials: string;
  key: Modules;
}

export type IModuleWithPermissions = IModule & IPermissionsItem;

export interface IRole {
  id: string;
  name: string;
  active: boolean;
  permissions: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
