import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IAct, TypeProcessAct } from 'models';

import { useAuth } from 'config/auth/hooks';

import { UserTypeHandleActPayload } from 'modules/backoffice/acts';
import { UserTypesPayload } from 'modules/user';

import { OptionsValuesString } from 'utils/helper';

import { useTabActsStore } from '../components';
import { TabsService } from '../services';
import { useTabs } from './useTabs';

export const useListActsAllowedsRequest = (
  enabled: boolean,
  isOperation?: boolean,
) => {
  const { setActsOptions } = useTabActsStore();
  const { currentTabInfos } = useTabs();
  const { user } = useAuth();

  const { data: response, isLoading, refetch } = useQuery(
    ['listActsAlloweds'],
    () =>
      TabsService.listActsAlloweds({
        userType: UserTypesPayload[user?.userType ?? ''],
        userRole: UserTypeHandleActPayload[user?.role.name ?? ''],
        isFinancing: `${currentTabInfos?.customerIsFinancing}`,
        type: isOperation ? TypeProcessAct.OPERATION : currentTabInfos.typeOp,
      }) as Promise<IAct[]>,
    { enabled, keepPreviousData: true },
  );

  const noData = !response || response.length === 0;
  const listActs: OptionsValuesString[] = [];

  useEffect(() => {
    response?.map(act => {
      listActs.push({ label: act.name, value: act?.id ?? '' });
    });
    setActsOptions(listActs);
  }, [response]);

  return {
    actOptions: listActs,
    dataAllActsAlloweds: response,
    isLoading,
    refetch,
    noData,
  };
};
