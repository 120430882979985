import ExcelJS from 'exceljs';
import { useQuery } from 'react-query';

import {
  ICompanyKMethod,
  IPagination,
  KleverBalanceInfoTO,
  inactiveClients,
} from 'models';

import { KMethodService } from '../services';

export const useDownloadExcelKmethod = () => {
  const unlimited = 100000000;

  const { data: response } = useQuery(
    ['KMethod - excel'],
    () =>
      (KMethodService.getKMethodData({
        page: 0,
        perPage: unlimited,
      }) as unknown) as Promise<IPagination<ICompanyKMethod>>,
    { keepPreviousData: true },
  );

  const dataKmethod = response?.data;

  const getDatePtBr = (date: string | Date) => {
    if (!date) return '';

    const newDate = new Date(date);
    return newDate.toLocaleDateString('pt-BR');
  };

  const getCurrencyPtBr = (value: number | string) => {
    if (value === 0) return '';

    const newValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
    return newValue;
  };

  const titleCell: ExcelJS.FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF0070C0' }, // Azul
  };

  const handleDownloadExcel = async () => {
    // Criando uma nova planilha Excel
    const workbook = new ExcelJS.Workbook();

    const tableWorksheet = workbook.addWorksheet('Dados - Tabela K Method');
    const kMethodSheet = workbook.addWorksheet('MÉTODO K');
    const balancesSheet = workbook.addWorksheet('MÉTODO K - Saldos');
    const demonstrationsSheet = workbook.addWorksheet('MÉTODO K - Demonstrativos');
    const operationSheet = workbook.addWorksheet('MÉTODO K - Operação Indicativa');
    const analysisSheet = workbook.addWorksheet('MÉTODO K - Análise - Operações');
    const vetoesAlertsSheet = workbook.addWorksheet('MÉTODO K - Vetos e Alertas');
    const spedActivesSheet = workbook.addWorksheet('Sped Balance - Ativos');
    const spedPassivesSheet = workbook.addWorksheet('Sped Balance - Passivos');
    const spedDemonstrationsSheet = workbook.addWorksheet(
      'Sped Balance - Demonstrativos',
    );
    const spedIndexaAndIndicatorsSheet = workbook.addWorksheet(
      'Sped Balance - Índices e Indicadores',
    );

    const headersTable = [
      'CNPJ',
      'NOME FANTASIA',
      'RÉGUA K',
      'ALERTAS',
      'STATUS',
      'ESTADO',
      'DATA DE ABERTURA',
      'CNAE - CÓDIGO',
      'CNAE - SETOR',
      'CNAE - DESCRIÇÃO',
      'CNAE - ATIVIDADE COMERCIAL',
    ];

    const headersKMethod = [
      'CNPJ',
      'NOME FANTASIA',
      'TOTAL',
      'REGISTRO',
      'FORÇA',
      'HABITUALIDADE',
      'PESO (REGISTRO)',
      'PESO (ROBUSTEZ)',
      'PESO (HABITUALIDADE)',
      'QUANTIDADE DE FUNCIONÁRIOS',
      'ESCALA - ESCALA',
      'ESCALA - RATING',
      'ESCALA - AÇÃO DE OPERAÇÃO',
    ];

    const headersBalancesAndDemos = [
      'CNPJ',
      'NOME FANTASIA',
      'DESCRIÇÃO',
      'DATA FINAL',
      'VALOR FINAL',
    ];

    const headersIndicateOperators = [
      'CNPJ',
      'NOME FANTASIA',
      'PRAZO',
      'VOLUME',
      'PERÍODO DE CARÊNCIA',
      'BREAKEVEN BRUTO',
      'BREAKEVEN LÍQUIDO',
      'TIPO DE GARANTIA',
      'OPERATION - MAGNA88',
      'OPERATION - QUIB',
      'OPERATION - UT_45',
    ];

    const headersAnalysis = [
      'CNPJ',
      'NOME FANTASIA',
      'TIPO',
      'TOTAL',
      'OPERATION - ex_50',
      'OPERATION - exercitation7c',
      'OPERATION - id_a',
    ];

    const headersVetoesAlerts = [
      'CNPJ',
      'NOME FANTASIA',
      //'ID',
      'NÍVEL',
      'SCORE',
      //'TIPO DE RISCO',
      'DESCRIÇÃO',
    ];

    const headersSpedBalance = [
      'CNPJ',
      'NOME FANTASIA',
      'CÓDIGO',
      'NOME',
      'PERCENTUAL HA',
      'PERCENTUAL VA',
      'CÓDIGO RAÍZ',
      'PLANILHA',
      'TIPO',
      'VALOR',
    ];

    // Configurando relacao key - header pra cada table
    const applyHeaders = (sheet: ExcelJS.Worksheet, headers: string[]) => {
      sheet.columns = headers.map(header => ({
        header,
        key: header,
      }));
    };

    applyHeaders(tableWorksheet, headersTable);
    applyHeaders(kMethodSheet, headersKMethod);
    applyHeaders(balancesSheet, headersBalancesAndDemos);
    applyHeaders(demonstrationsSheet, headersBalancesAndDemos);
    applyHeaders(operationSheet, headersIndicateOperators);
    applyHeaders(analysisSheet, headersAnalysis);
    applyHeaders(vetoesAlertsSheet, headersVetoesAlerts);
    applyHeaders(spedActivesSheet, headersSpedBalance);
    applyHeaders(spedPassivesSheet, headersSpedBalance);
    applyHeaders(spedDemonstrationsSheet, headersSpedBalance);
    applyHeaders(spedIndexaAndIndicatorsSheet, headersSpedBalance);

    // Aplicando a cor de fundo azul para a primeira linha (headers)
    const applyStyle = (sheet: ExcelJS.Worksheet) => {
      sheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
        cell.fill = titleCell;
        cell.font = { bold: true, color: { argb: 'FFFFFF' } };
      });
    };

    applyStyle(tableWorksheet);
    applyStyle(kMethodSheet);
    applyStyle(balancesSheet);
    applyStyle(demonstrationsSheet);
    applyStyle(operationSheet);
    applyStyle(analysisSheet);
    applyStyle(vetoesAlertsSheet);
    applyStyle(spedActivesSheet);
    applyStyle(spedPassivesSheet);
    applyStyle(spedDemonstrationsSheet);
    applyStyle(spedIndexaAndIndicatorsSheet);

    // COMEÇA A ATRIBUIR OS DADOS PRA CADA TABLE
    dataKmethod?.forEach(async KM => {
      const rowTable = {};

      const active = inactiveClients.includes(KM?.enterprise?.status)
        ? 'Inativo'
        : 'Ativo';

      const alerts = KM.vetoesAndAlerts?.alerts.reduce(
        (acc, curr) => acc + (curr?.score ?? 0),
        0,
      );
      const cnae = KM.kMethod.cnae;

      // PREENCHENDO DADOS TABLE
      rowTable['CNPJ'] = KM.cnpj;
      rowTable['NOME FANTASIA'] = KM.fantasyName;
      rowTable['RÉGUA K'] = KM.kScale;
      rowTable['ALERTAS'] = alerts;
      rowTable['STATUS'] = active;
      rowTable['ESTADO'] = KM.kMethod.uf;
      rowTable['DATA DE ABERTURA'] = getDatePtBr(KM.kMethod.openingDate);
      rowTable['CNAE - CÓDIGO'] = cnae?.code;
      rowTable['CNAE - SETOR'] = cnae?.sector;
      rowTable['CNAE - DESCRIÇÃO'] = cnae?.description;
      rowTable['CNAE - ATIVIDADE COMERCIAL'] = cnae?.businessActivity;
      tableWorksheet.addRow(rowTable);

      // PREENCHENDO DADOS K METHOD
      const rowKmethod = {};

      if (KM.kMethod?.cnpj) {
        rowKmethod['CNPJ'] = KM.kMethod.cnpj;
        rowKmethod['NOME FANTASIA'] = KM.kMethod.fantasyName;
        rowKmethod['TOTAL'] = KM.kMethod.total;
        rowKmethod['REGISTRO'] = KM.kMethod?.register?.toString() ?? '';
        rowKmethod['FORÇA'] = KM.kMethod?.strength?.toString() ?? '';
        rowKmethod['HABITUALIDADE'] = KM.kMethod?.habituality?.toString() ?? '';
        rowKmethod['PESO (REGISTRO)'] =
          KM.kMethod?.registerWeight?.toString() ?? '';
        rowKmethod['PESO (ROBUSTEZ)'] =
          KM.kMethod?.robustnessWeight?.toString() ?? '';
        rowKmethod['PESO (HABITUALIDADE)'] =
          KM.kMethod?.habitualityWeight?.toString() ?? '';
        rowKmethod['QUANTIDADE DE FUNCIONÁRIOS'] =
          KM.kMethod?.quantityEmployee?.toString() ?? '';
        rowKmethod['ESCALA - ESCALA'] = KM.kMethod.scale?.scale ?? '';
        rowKmethod['ESCALA - RATING'] = KM.kMethod.scale?.rating ?? '';
        rowKmethod['ESCALA - AÇÃO DE OPERAÇÃO'] =
          KM.kMethod.scale?.operationAction ?? '';

        kMethodSheet.addRow(rowKmethod);
      }

      // PREENCHENDO DADOS K METHOD - BALANÇOS
      const balances = KM.kMethod.balances;
      const rowBalances = {};

      balances?.map(bal => {
        rowBalances['CNPJ'] = KM.kMethod.cnpj;
        rowBalances['NOME FANTASIA'] = KM.kMethod.fantasyName;
        rowBalances['DESCRIÇÃO'] = bal.description;
        rowBalances['DATA FINAL'] = getDatePtBr(bal.endDate);
        rowBalances['VALOR FINAL'] = getCurrencyPtBr(bal.endValue ?? '0');

        balancesSheet.addRow(rowBalances);
      });

      // PREENCHENDO DADOS K METHOD - DEMONSTRATIVOS
      const demonstrations = KM.kMethod.demonstrations;
      const rowDemos = {};

      demonstrations?.map(demo => {
        rowDemos['CNPJ'] = KM.kMethod.cnpj;
        rowDemos['NOME FANTASIA'] = KM.kMethod.fantasyName;
        rowDemos['DESCRIÇÃO'] = demo.description;
        rowDemos['DATA FINAL'] = getDatePtBr(demo.endDate);
        rowDemos['VALOR FINAL'] = getCurrencyPtBr(demo.endValue ?? '0');

        demonstrationsSheet.addRow(rowDemos);
      });

      // PREENCHENDO DADOS K METHOD - OPERAÇÕES INDICATIVAS
      const rowKOperations = {};
      const indicativeOp = KM.kMethod.indicativeOperation;

      if (indicativeOp) {
        rowKOperations['CNPJ'] = KM.kMethod.cnpj;
        rowKOperations['NOME FANTASIA'] = KM.kMethod.fantasyName;
        rowKOperations['PRAZO'] = indicativeOp?.term;
        rowKOperations['VOLUME'] = indicativeOp?.volume;
        rowKOperations['PERÍODO DE CARÊNCIA'] = indicativeOp?.gracePeriod;
        rowKOperations['BREAKEVEN BRUTO'] = indicativeOp?.grossBreakeven;
        rowKOperations['BREAKEVEN LÍQUIDO'] = indicativeOp?.liquidBreakeven;
        rowKOperations['TIPO DE GARANTIA'] = indicativeOp?.typeOfGuarantee;
        rowKOperations['OPERATION - MAGNA88'] = indicativeOp?.operations.magna88;
        rowKOperations['OPERATION - QUIB'] = indicativeOp?.operations.quib;
        rowKOperations['OPERATION - UT_45'] = indicativeOp?.operations.Ut_45;

        operationSheet.addRow(rowKOperations);
      }

      // PREENCHENDO DADOS KMETHDO ANALISES - OPERAÇÕES
      const rowAnalysis = {};
      const analysis = KM.kMethod.analysis;

      if (analysis && analysis.length > 0) {
        analysis.map(analise => {
          rowAnalysis['CNPJ'] = KM.kMethod.cnpj;
          rowAnalysis['NOME FANTASIA'] = KM.kMethod.fantasyName;
          rowAnalysis['TIPO'] = analise?.type;
          rowAnalysis['TOTAL'] = analise?.total;
          rowAnalysis['OPERATION - ex_50'] = analise.operations?.ex_50;
          rowAnalysis['OPERATION - exercitation7c'] =
            analise.operations?.exercitation7c;
          rowAnalysis['OPERATION - id_a'] = analise.operations?.id_a;

          analysisSheet.addRow(rowAnalysis);
        });
      }

      // PREENCHENDO DADOS KMETHDO ANALISES - OPERAÇÕES
      const rowVetoesAlerts = {};
      const vetoesAlerts = KM?.vetoesAndAlerts?.alerts;

      if (vetoesAlerts && vetoesAlerts.length > 0) {
        vetoesAlerts?.map(vtal => {
          rowVetoesAlerts['CNPJ'] = KM?.vetoesAndAlerts?.cnpj;
          rowVetoesAlerts['NOME FANTASIA'] = KM.fantasyName;
          //rowVetoesAlerts['ID'] = vtal?.level?.id;
          rowVetoesAlerts['NÍVEL'] = vtal?.level;
          rowVetoesAlerts['SCORE'] = vtal?.score;
          // rowVetoesAlerts['TIPO DE RISCO'] = vtal.typeOfRisk;
          rowVetoesAlerts['DESCRIÇÃO'] = vtal?.description;

          vetoesAlertsSheet.addRow(rowVetoesAlerts);
        });
      }

      // PREENCHENDO DADOS KMETHDO SPED BALANCE - ATIVOS
      const generateRowSpedBalance = (
        data: KleverBalanceInfoTO[],
        sheet: ExcelJS.Worksheet,
      ) => {
        if (data && data.length > 0) {
          const rowSped = {};
          data.map(item => {
            let sheetExcel = '';
            if (item?.sheet !== undefined) {
              sheetExcel = item.sheet ? 'Sim' : 'Não';
            }
            rowSped['CNPJ'] = KM.cnpj;
            rowSped['NOME FANTASIA'] = KM.fantasyName;
            rowSped['CÓDIGO'] = item.code;
            rowSped['NOME'] = item.name;
            rowSped['PERCENTUAL HA'] = item?.percentHA?.toString();
            rowSped['PERCENTUAL VA'] = item?.percentVA?.toString();
            rowSped['CÓDIGO RAÍZ'] = item.rootCode;
            rowSped['PLANILHA'] = sheetExcel;
            rowSped['TIPO'] = item.type;
            rowSped['VALOR'] = getCurrencyPtBr(item.value ?? 0);

            sheet.addRow(rowSped);
          });
        }
      };

      // PREENCHENDO DADOS KMETHDO SPED BALANCE - ATIVOS/PASSIVOS/DEMONSTRATIONS/INDICATORS
      const actives = KM?.actives;
      generateRowSpedBalance(actives, spedActivesSheet);

      const passives = KM?.passives;
      generateRowSpedBalance(passives, spedPassivesSheet);

      const spedDemonstrations = KM?.demonstrations;
      generateRowSpedBalance(spedDemonstrations, spedDemonstrationsSheet);

      const indexIndicatores = KM?.indexesAndIndicators;
      generateRowSpedBalance(indexIndicatores, spedIndexaAndIndicatorsSheet);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_KMethod.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcel,
  };
};
