import { Box, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { ChipContained, Icon, Typography } from 'components';
import { IEnterprise, TypeProcessAct } from 'models';

import { IOperationResponse } from 'modules/boards';
import { useTabs } from 'modules/boards/tabs/hooks';

import { useConsultantNotifications } from '../hooks';
import { ConsultantNotification, ConsultantNotificationType } from '../services';

interface NotificationItemProps {
  notification: ConsultantNotification;
}

export const NotificationItem = ({ notification }: NotificationItemProps) => {
  const history = useHistory();
  const { handleOpenNegotiationDialog } = useTabs();
  const { handleCloseNotificationsDialog } = useConsultantNotifications();

  const fromOperation = notification.operation;
  const fromCustomer = notification.enterprise && !notification.processAct;
  const hasProcessAct = notification.processAct;
  const fromCustomerAct =
    notification?.processAct?.typeProcessAct === TypeProcessAct.CLIENT;
  const fromOperationAct =
    notification?.processAct?.typeProcessAct === TypeProcessAct.OPERATION;
  const deadline = notification.processAct?.deadline;

  const tagName = () => {
    if (fromCustomer) return 'Cliente';
    if (fromCustomerAct) return 'Ato de Cliente';
    if (fromOperation) return 'Operação';
    if (fromOperationAct) return 'Ato de Operação';
  };

  const companyName = () => {
    if (fromCustomer) {
      return (
        notification.enterprise?.bigData?.fantasyName ||
        notification.enterprise?.bigData?.socialReason
      );
    }
    if (fromCustomerAct) {
      return (
        notification.processAct?.enterprise?.bigData?.fantasyName ||
        notification.processAct?.enterprise?.bigData?.socialReason
      );
    }
    if (fromOperation) {
      return (
        notification.operation?.enterprise?.bigData?.fantasyName ||
        notification.operation?.enterprise?.bigData?.socialReason
      );
    }
    if (fromOperationAct) {
      return (
        notification.processAct?.operation?.enterprise?.bigData?.fantasyName ||
        notification.processAct?.operation?.enterprise?.bigData?.socialReason
      );
    }
    return '';
  };

  const creditorName = () => {
    if (fromOperation) {
      return (
        notification?.operation?.creditor?.bigData?.fantasyName ||
        notification?.operation?.creditor?.bigData?.socialReason
      );
    }
    if (fromOperationAct) {
      return (
        notification?.processAct?.operation?.creditor?.bigData?.fantasyName ||
        notification?.processAct?.operation?.creditor?.bigData?.socialReason
      );
    }
    return '';
  };

  const handleRedirect = () => {
    handleCloseNotificationsDialog();

    if (fromCustomer || fromCustomerAct) history.push('customers');
    if (fromOperation || fromOperationAct) history.push('operations');

    if (fromCustomer) {
      handleOpenNegotiationDialog(TypeProcessAct.CLIENT, {
        id: notification?.enterprise?.id,
        fantasyName: companyName(),
        bigData: notification?.enterprise?.bigData,
        isFinancing: notification?.enterprise?.isFinancing,
        responsible: { ...notification?.enterprise?.responsible },
      } as IEnterprise);
    }

    if (fromCustomerAct) {
      handleOpenNegotiationDialog(TypeProcessAct.CLIENT, {
        id: notification?.processAct?.enterprise?.id,
        fantasyName: companyName(),
        bigData: notification?.processAct?.enterprise?.bigData,
        isFinancing: notification?.processAct?.enterprise?.isFinancing,
        responsible: { ...notification?.processAct?.enterprise?.responsible },
      } as IEnterprise);
    }

    if (fromOperation) {
      handleOpenNegotiationDialog(TypeProcessAct.OPERATION, undefined, {
        id: notification?.operation?.id,
        creditor: {
          id: notification?.operation?.creditor?.id,
          fantasyName: creditorName(),
          bigData: notification?.operation?.creditor?.bigData,
        },
        enterprise: {
          id: notification?.operation?.enterprise?.id,
          fantasyName: companyName(),
          bigData: notification?.operation?.enterprise?.bigData,
          isFinancing: notification?.operation?.enterprise?.isFinancing,
        },
        responsible: { ...notification?.operation?.responsible },
      } as IOperationResponse);
    }

    if (fromOperationAct) {
      handleOpenNegotiationDialog(TypeProcessAct.OPERATION, undefined, {
        id: notification?.processAct?.operation?.id,
        creditor: {
          id: notification?.processAct?.operation?.creditor?.id,
          fantasyName: creditorName(),
          bigData: notification?.processAct?.operation?.creditor?.bigData,
        },
        enterprise: {
          id: notification?.processAct?.operation?.enterprise?.id,
          fantasyName: companyName(),
          bigData: notification?.processAct?.operation?.enterprise?.bigData,

          isFinancing: notification?.processAct?.operation?.enterprise?.isFinancing,
        },
        responsible: { ...notification?.processAct?.operation?.responsible },
      } as IOperationResponse);
    }
  };

  return (
    <Box
      width={420}
      border={2}
      borderColor={notification.read ? '#E6E6E5' : '#007ce0'}
      padding={2}
      borderRadius={4}
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <Typography fontWeight={'bold'} fontSize={20}>
            {ConsultantNotificationType[notification.type]}
          </Typography>
        </Box>
        <Box display={'flex'} alignItems={'center'} gap={1}>
          <ChipContained style={{ minWidth: 120 }} size="small" label={tagName()} />
          <IconButton aria-label="expand row" size="small" onClick={handleRedirect}>
            <Icon color="gray-500">open_in_new_icon</Icon>
          </IconButton>
        </Box>
      </Box>

      {hasProcessAct && (
        <>
          <Typography color={'dark'}>
            <strong>Nome do ato: </strong>
            {notification.processAct?.act?.name}
          </Typography>

          {deadline && <Typography color={'dark'}>Prazo: {deadline}</Typography>}
        </>
      )}

      <Typography color={'dark'}>
        <strong>Empresa:</strong> {companyName()}
      </Typography>

      {creditorName() && (
        <Typography color={'dark'}>
          <strong>Instituição Financeira:</strong> {creditorName()}
        </Typography>
      )}
    </Box>
  );
};
