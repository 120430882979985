import {
  IPagination,
  IPaginationParams,
  IProcessAct,
  TypeProcessAct,
} from 'models';

import { ISort } from 'utils/ordination';

export enum NotificationsQueryKey {
  LIST_BY_CONSULTANT = 'LIST_BY_CONSULTANT',
  READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS',
}

export enum ConsultantNotificationType {
  ACT_CREATION = 'Criação de ato',
  ACT_CLOSING = 'Fechamento de ato',
  RESPONSIBILITY_ASSIGNMENT = 'Atribuição de responsável',
}

export type ConsultantNotificationKeys = keyof typeof ConsultantNotificationType;

export interface INotificationPayload {
  type: ConsultantNotificationKeys;
  read: boolean;
  consultantId: string;
  processAct?: IProcessAct;
}

type BigData = {
  socialReason: string;
  fantasyName: string;
};
type Responsible = {
  id: string;
  name: string;
};

export interface ConsultantNotification {
  id: string;
  type: ConsultantNotificationKeys;
  processAct?: {
    typeProcessAct: keyof typeof TypeProcessAct;
    deadline: string;
    enterprise: {
      id: string;
      bigData: BigData;
      isFinancing: boolean;
      responsible: Responsible;
    };
    act: {
      name: string;
    };
    operation: {
      id: string;
      creditor: {
        id: string;
        bigData: BigData;
      };
      enterprise: {
        id: string;
        bigData: BigData;
        isFinancing: boolean;
      };
      responsible: Responsible;
    };
  };
  operation: {
    id: string;
    enterprise: {
      id: string;
      bigData: BigData;
      isFinancing: boolean;
    };
    creditor: {
      id: string;
      bigData: BigData;
    };
    responsible: Responsible;
  };
  enterprise: {
    id: string;
    isFinancing: boolean;
    bigData: BigData;
    responsible: Responsible;
  };
  read: boolean;
}

export interface ListNotificationsPayload extends IPaginationParams {
  orderBy?: string;
  sort?: ISort;
}

export type ListNotificationsResponse = IPagination<ConsultantNotification>;
