import { Icon } from '@mui/material';
import Delta from 'quill-delta';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';

import { Typography, Box } from 'components';

import {
  useCardConditionMultipleChoice,
  useTrueAutocompleteVariableMultipleChoice,
} from 'modules/dynamicForm/form/hooks';
import { IQuestionForm } from 'modules/dynamicForm/form/services';

import { QuestionsAutocomplete } from '../QuestionsAutocomplete';
import ExpressionInput from './ExpressionInput';

interface VariableCardConditionProps {
  question: IQuestionForm;
  identifierCondition: number;
  identifierVariable: number;
}

export const VariableCardCondition = ({
  question,
  identifierCondition,
  identifierVariable,
}: VariableCardConditionProps) => {
  const {
    allQuestions,
    handleChange,
    setSearch,
    isOptionEqualToValue,
    formNexQuestionAutocomplete,
  } = useTrueAutocompleteVariableMultipleChoice();

  const {
    handleRemoveVariable,
    handleExpressionChange,
    expression,
    setExpression,
  } = useCardConditionMultipleChoice();

  const variablesUntilThisVariable = question?.conditions[
    identifierCondition - 1
  ]?.variables.slice(0, identifierVariable);

  const numberVariablesExludeUntilThisCondition = variablesUntilThisVariable?.filter(
    variable => variable.identifierVariable === -1,
  );

  const numberRenderVariable = //calculando numero da variavel de acordo com as que ja foram excluidas
    identifierVariable - numberVariablesExludeUntilThisCondition?.length;

  const variableLabel =
    question?.conditions[identifierCondition - 1]?.variables[identifierVariable - 1]
      ?.nextQuestionLabel;

  const condition = question?.conditions[identifierCondition - 1];

  const deltaExpression =
    question?.conditions[identifierCondition - 1]?.variables[identifierVariable - 1]
      ?.deltaExpression || new Delta();

  useEffect(() => {
    setExpression((deltaExpression as unknown) as Delta);
  }, [question]);

  return (
    <>
      {identifierVariable < 0 ? null : (
        <Box
          border="1px solid gray"
          borderRadius="5px"
          marginTop="1.5em"
          display="flex"
          flexDirection="column"
          paddingX="1.5em"
          paddingY="1em"
          width={'100%'}
        >
          <Box>
            {identifierVariable > 1 && (
              <Box
                style={{ display: 'flex', float: 'right' }}
                onClick={() =>
                  handleRemoveVariable(
                    question,
                    identifierCondition,
                    identifierVariable,
                  )
                }
                width={40}
              >
                <Icon style={{ cursor: 'pointer' }} fontSize="medium">
                  delete
                </Icon>
              </Box>
            )}
            <Typography> Opção por variável {numberRenderVariable}</Typography>

            <ExpressionInput
              question={question}
              identifierCondition={identifierCondition}
              identifierVariable={identifierVariable}
              deltaExpression={(expression as unknown) as ReactQuill.Value}
              onChange={handleExpressionChange}
            />

            <Typography>Proxima pergunta Se True</Typography>

            <QuestionsAutocomplete
              question={question}
              identifierCondition={identifierCondition}
              identifierVariable={identifierVariable}
              value={variableLabel}
              setSearch={setSearch}
              form={formNexQuestionAutocomplete}
              allQuestions={allQuestions}
              isOptionEqualToValue={isOptionEqualToValue}
              handleChange={handleChange}
              disabled={
                String(condition).length === 0 ||
                String(condition.condition).length === 0
              }
            />
          </Box>
        </Box>
      )}
    </>
  );
};
