import { ConficAct, Header, List } from '../components';
import { useConfigAct } from '../hooks';

const ListActsPage = () => {
  const { form } = useConfigAct();

  return (
    <>
      <Header />
      <List />
      <ConficAct form={form} />
    </>
  );
};

export { ListActsPage };
