import { IFileStorage, IKleverDocument, IProcessAct } from 'models';
import { IClicksignDocument } from 'models/document/types';

export enum ETypeProcessAct {
  ACT = 'ACT',
  ANSWER = 'ANSWER',
}

export interface IProcessActAttachment {
  id: string;
  type: ETypeProcessAct;
  processAct: IProcessAct;
  file: IFileStorage;
  kleverAttachment: IKleverDocument | null;
  clicksignAttachment: IClicksignDocument | null;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
}
