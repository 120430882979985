import { create } from 'zustand';

import { IFormQuestion, IQuestionAttachmentList } from 'models';

import { IFormAnswereds } from '../services';

type State = {
  openModalCancel: boolean;
  openModalFinishLater: boolean;
  allowEdit: boolean;
  formQuestions: IFormQuestion[];
  currentQuestion: IFormQuestion;
  currentAnswer: string;
  questionAnswerAttachment: IQuestionAttachmentList[];
  currentQuestionAttachment: IQuestionAttachmentList[];
  formQuestionAnswereds: IFormAnswereds[];
  isRevisionQuestion: boolean;
  formRecordId: string;
};

interface Actions {
  setOpenModalCancel: (openModalCancel: boolean) => void;
  setOpenModalFinishLater: (openModal: boolean) => void;
  setAllowEdit: (allowEdit: boolean) => void;
  setFormQuestion: (formQuestion: IFormQuestion[]) => void;
  setCurrentQuestion: (currentQuestion: IFormQuestion) => void;
  setCurrentAnswer: (currentAnswer: string) => void;
  setQuestionAnswerAttachments: (
    questionAnswerAttachment: IQuestionAttachmentList[],
  ) => void;
  setCurrentQuestionAttachments: (
    currentQuestionAttachment: IQuestionAttachmentList[],
  ) => void;
  setformQuestionAnswereds: (formQuestionAnswereds: IFormAnswereds[]) => void;
  setIsRevisionQuestion: (isRevisionQuestion: boolean) => void;
  setFormRecordId: (formRecordId: string) => void;
  clearFormAnswerStore: () => void;
}

const initialState: State = {
  openModalCancel: false,
  openModalFinishLater: false,
  allowEdit: true,
  formQuestions: [],
  currentQuestion: {} as IFormQuestion,
  currentAnswer: '',
  questionAnswerAttachment: [],
  currentQuestionAttachment: [],
  formQuestionAnswereds: [],
  isRevisionQuestion: false,
  formRecordId: '',
};

const useFormAnswerStore = create<State & Actions>()(set => ({
  ...initialState,
  setOpenModalCancel: openModalCancel => set({ openModalCancel }),
  setOpenModalFinishLater: openModalFinishLater => set({ openModalFinishLater }),
  setAllowEdit: allowEdit => set({ allowEdit }),
  setFormQuestion: formQuestions => set({ formQuestions }),
  setCurrentQuestion: currentQuestion => set({ currentQuestion }),
  setCurrentAnswer: currentAnswer => set({ currentAnswer }),
  setQuestionAnswerAttachments: questionAnswerAttachment =>
    set({ questionAnswerAttachment }),
  setCurrentQuestionAttachments: currentQuestionAttachment =>
    set({ currentQuestionAttachment }),
  setformQuestionAnswereds: formQuestionAnswereds => set({ formQuestionAnswereds }),
  setIsRevisionQuestion: isRevisionQuestion => set({ isRevisionQuestion }),
  setFormRecordId: formRecordId => set({ formRecordId }),
  clearFormAnswerStore: () => {
    set(initialState);
  },
}));

export default useFormAnswerStore;
