import ExcelJS from 'exceljs';

import {
  ESerasaType,
  KleverSerasaPerson,
  OverdueDebtsTO,
  PersonCheckInnerResponseTO,
  PersonPefinInnerResponseTO,
  PersonRefinInnerResponseTO,
  ResponseTO,
  inactiveClients,
} from 'models';

import { useSerasaRequest } from './useSerasaRequest';

export const useDownloadExcelSerasa = () => {
  const { data: response } = useSerasaRequest({
    limitPagination: true,
  });

  const serasaData = response?.data;

  const getDatePtBr = (date: string | Date) => {
    if (!date) return '';
    const newDate = new Date(date);
    return newDate.toLocaleDateString('pt-BR');
  };

  const getMoneyPtBr = (value: number) => {
    if (!value) return '';

    const valueFormatted = value.toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2,
    });
    return valueFormatted;
  };

  const titleCell: ExcelJS.FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF0070C0' }, // Azul
  };

  const handleDownloadExcel = async () => {
    // Criando uma nova planilha Excel
    const workbook = new ExcelJS.Workbook();
    const tableWorksheet = workbook.addWorksheet('Dados - Serasa');
    const pefinWorksheet = workbook.addWorksheet('Pendência Financeira - PEFIN');
    const refinWorksheet = workbook.addWorksheet('Restrições Financeiras - REFIN');
    const debtsWorkSheet = workbook.addWorksheet('Dívidas Vencidas (Convem)');
    const protestsWorkSheet = workbook.addWorksheet('Protestos');
    const checksWorkSheet = workbook.addWorksheet('Cheques Sem Fundo (CCF)');
    const inquerysWorkSheet = workbook.addWorksheet('Inquéritos');
    const stolenDocsWorkSheet = workbook.addWorksheet('Documentos Roubados');
    const bankruptsWorkSheet = workbook.addWorksheet('Falências');
    const judgementFilingsWorksheet = workbook.addWorksheet(
      'Arquivos de julgamento',
    );
    const partnerWorkSheet = workbook.addWorksheet('Sócio');
    const historyPaymentsWorkSheet = workbook.addWorksheet(
      'Histórico de Pagamentos',
    );
    const inquerysSpcWorksheet = workbook.addWorksheet('Inquéritos - SPC');

    const headersTable = [
      'TIPO',
      'CNPJ',
      'NOME FANTASIA',
      'DÍVIDAS EM ABERTO',
      'PROTESTOS',
      'STATUS',
      'ATIVO/INATIVO',
      'DATA DE NASCIMENTO',
      'NOME DA MÃE',
      'SITUAÇÃO',
      'DATA - STATUS',
      'ENDEREÇO 1',
      'ENDEREÇO 2',
      'MUNÍCIPIO FRONTEIRIÇO',
      'TIPO DE RESIDÊNCIA',
      'CÓDIGO DO PÁIS',
      'CÓDIGO DO MUNICÍPIO',
      'EDIFÍCIO COLETIVO',
      'PAÍS',
      'RESTRIÇÃO DE ENTREGA',
      'ENDEREÇO FORMATADO',
      'ÚLTIMO ENDEREÇO',
      'LATITUDE',
      'LONGITUDE',
      'MICROREGIÃO',
      'MACROREGIÃO',
      'MUNICÍPIO',
      'BAIRRO',
      'NÚMERO',
      'BAIRRO ORIGINAL',
      'PRECISÃO',
      'REGIÃO',
      'ESTADO REGISTRADO',
      'ENDEREÇO RESIDENTE',
      'TELEFONES',
      'ESTADO',
      'CEP',
      'PEFIN (RESUMO) - QUANTIDADE',
      'REFIN (RESUMO) - QUANTIDADE',
      'DÍVIDAS VENCIDAS CONVEM (RESUMO) - QUANTIDADE',
      'DÍVIDAS VENCIDAS CONVEM (RESUMO) - VALOR',
      'PROTESTOS (RESUMO) - QUANTIDADE',
      'CHEQUES SEM FUNDO (RESUMO) - QUANTIDADE',
      'INQUÉRITOS (RESUMO) - QUANTIDADE',
      'DOCUMENTOS ROUBADOS (RESUMO) - QUANTIDADE',
      'ANOTAÇÕES SPC - QUANTIDADE TOTAL',
      'ANOTAÇÕES SPC - VALOR TOTAL',
      'ANOTAÇÕES SPC - PRIMEIRA OCORRÊNCIA',
      'ANOTAÇÕES SPC - ULTIMA OCORRÊNCIA',
      'ANOTAÇÕES SPC - VALOR DA ANOTAÇÃO',
      'ANOTAÇÕES SPC - CIDADE',
      'ANOTAÇÕES SPC - IDENTIFICADOR DO CONTRATO',
      'ANOTAÇÕES SPC - CIDADE DA INSTUIÇÃO FINANCEIRA',
      'ANOTAÇÕES SPC - ESTADO DA INSTUIÇÃO FINANCEIRA',
      'ANOTAÇÕES SPC - NOME DA INSTUIÇÃO FINANCEIRA',
      'ANOTAÇÕES SPC - ESTADO',
      'ANOTAÇÕES SPC - DATA DA OCORRÊNCIA',
      'ATRIBUTO ACESSIBILIDADE - MODELO',
      'ATRIBUTO ACESSIBILIDADE - CÓDIGO',
      'ATRIBUTO ACESSIBILIDADE - MENSAGEM',
      'ATRIBUTO ACESSIBILIDADE - PONTUAÇÃO',
      'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - MODELO',
      'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - CÓDIGO',
      'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - MENSAGEM',
      'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - PONTUAÇÃO',
      'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - MODELO',
      'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - CÓDIGO',
      'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - MENSAGEM',
      'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - PONTUAÇÃO',
      'ATRIBUTO RENDA ESTIMADA - MODELO',
      'ATRIBUTO RENDA ESTIMADA - CÓDIGO',
      'ATRIBUTO RENDA ESTIMADA - MENSAGEM',
      'ATRIBUTO RENDA ESTIMADA - PONTUAÇÃO',
      'ATRIBUTO COMPROMISSO DE RENDA - MODELO',
      'ATRIBUTO COMPROMISSO DE RENDA - CÓDIGO',
      'ATRIBUTO COMPROMISSO DE RENDA - MENSAGEM',
      'ATRIBUTO COMPROMISSO DE RENDA - PONTUAÇÃO',
      'ATRIBUTO PAGAMENTOS EM DIA - MODELO',
      'ATRIBUTO PAGAMENTOS EM DIA - CÓDIGO',
      'ATRIBUTO PAGAMENTOS EM DIA - MENSAGEM',
      'ATRIBUTO PAGAMENTOS EM DIA - PONTUAÇÃO',
      'FALÊNCIAS (RESUMO) - QUANTIDADE',
      'FALÊNCIAS (RESUMO) - VALOR',
      'FALÊNCIAS (RESUMO) - PRIMEIRA OCORRÊNCIA',
      'FALÊNCIAS (RESUMO) - ÚLTIMA OCORRÊNCIA',
      'LIMITE DE CRÉDITO E DECISÃO - CÓDIGO',
      'LIMITE DE CRÉDITO E DECISÃO - MENSAGEM',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DOCUMENTO ATIVO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - COMPROMISSO BAIXA RENDA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - SEM RECLAMAÇÃO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - SEM RESTRIÇÃO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - COBRANÇA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - NOME DO CONSUMIDOR',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DATA DE CRIAÇÃO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DOCUMENTO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - NUMERO DA PROPOSTA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE RECOMENTDAÇÃO VENDA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE RECOMENDAÇÃO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - VALOR LIMITE RECOMENDADO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - CÓDIGO DO NÍVEL DE RISCO',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE VENDA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - CÓDIGO DO TIPO DE VENDA',
      'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - VALOR DA TRANSAÇÃO',
      'ARQUIVOS DE JULGAMENTO (RESUMO) - QUANTIDADE',
      'ARQUIVOS DE JULGAMENTO (RESUMO) - VALOR',
      'ARQUIVOS DE JULGAMENTO (RESUMO) - PRIMEIRA OCORRÊNCIA',
      'ARQUIVOS DE JULGAMENTO (RESUMO) - ÚLTIMA OCORRÊNCIA',
      'SÓCIO - QUANTIDADE',
      'INQUÉRITOS (SPC) - QUANTIDADE',
      'INQUÉRITOS (SPC) - QUANTIDADE ATUAL',
      'INQUÉRITOS (SPC) - DATA INQUERITO',
      'INQUÉRITOS (SPC) - OCORRÊNCIAS',
      // INQUERITOS SPC
      'SERASA SCORE 2.0 - PONTUAÇÃO',
      'SERASA SCORE 2.0 - MODELO',
      'SERASA SCORE 2.0 - PROBABILIDADE DE NAO PAGAMENTO',
      'SERASA SCORE 2.0 - CÓDIGO MSG',
    ];

    const headersPefinRefin = [
      'CNPJ',
      'NOME FANTASIA',
      'VALOR',
      'DATA DISPONÍVEL',
      'NÚMERO DO CASO',
      'CIDADE',
      'UNIDADE FEDERATIVA',
      'TRIBUNAL CIVIL',
      'IDENTIFICADOR INSTUIÇÃO FINANCEIRA',
      'NOME DA INSTUIÇÃO FINANCEIRA',
      'DISTRIBUIDOR',
      'SUBJUDICE',
      'DATA INCLUSÃO',
      'NATUREZA JURÍDICA',
      'ID NATUREZA JURÍDICA',
      'PRAÇA LEGAL',
      'DATA OCORRÊNCIA',
      'PRINCIPAL',
      'ID DO CONTRATO',
      'AGÊNCIA PÚBLICA',
    ];

    const headersDebts = [
      'CNPJ',
      'NOME FANTASIA',
      'VALOR',
      'CIDADE',
      'ID DO CONTRATO',
      'IDENTIFICADOR INSTITUIÇÃO FINANCEIRA',
      'NOME DA INSTITUIÇÃO FINANCEIRA',
      'UNIDADE FEDERATIVA',
      'SUBJUDICE',
      'NATUREZA JURÍDICA',
      'ID NATUREZA JURÍDICA',
      'DATA OCORRÊNCIA',
      'PRINCIPAL',
      'AGÊNCIA PÚBLICA',
    ];

    const headersProtests = [
      'CNPJ',
      'NOME FANTASIA',
      'VALOR',
      'CIDADE',
      'UNIDADE FEDERATIVA',
      'NOME DO ESCRITÓRIO',
      'NÚMERO DO ESCRITÓRIO',
      'DATA OCORRÊNCIA',
    ];

    const headersChecks = [
      'CNPJ',
      'NOME FANTASIA',
      'CIDADE',
      'UNIDADE FEDERATIVA',
      'IDENTIFICADOR DO MOTIVO DE DEVOLUÇÃO DO CHEQUE',
      'NÚMERO DA AGÊNCIA',
      'ID DO BANCO',
      'NOME DO BANCO',
      'QUANTIDADE DE CHEQUES',
      'NÚMERO DO CHEQUE',
      'DATA OCORRÊNCIA',
      'PRAÇA LEGAL',
    ];

    const headersInquerysAndStolenDocs = [
      'CNPJ',
      'NOME FANTASIA',
      'DESCRIÇÃO DO SEGMENTO',
      'DATA DA OCORRÊNCIA',
    ];

    const headersBankrupts = [
      'CNPJ',
      'NOME FANTASIA',
      'ESTADO',
      'CIDADE',
      'NÚMERO DO PROCESSO',
      'IDENTIFICADOR DOCUMENTO DA EMPRESA',
      'NOME DA EMPRESA',
      'DATA DA INCLUSÃO',
      'NATUREZA JURÍDICA',
      'DATA DA OCORRÊNCIA',
      'POSIÇÃO EMPRESA',
    ];

    const headersJudgementFilings = [
      'CNPJ',
      'NOME FANTASIA',
      'VALOR',
      'DATA DISPONÍVEL',
      'NÚMERO DO CASO',
      'CIDADE',
      'UNIDADE FEDERATIVA',
      'TRIBUNAL CIVIL',
      'DISTRIBUIDOR',
      'SUBJUDICE',
      'DATA INCLUSÃO',
      'CÓDIGO LEGAL',
      'IDENTIFICADOR CÓDIGO LEGAL',
      'PRAÇA LEGAL',
      'DATA OCORRÊNCIA',
      'PRINCIPAL',
    ];

    const headersPartners = [
      'CNPJ',
      'NOME FANTASIA',
      'NOME',
      'ESTADO',
      'STATUS',
      'CÓDIGO STATUS',
      'DATA STATUS',
      'DATA INÍCIO DA SOCIEDADE',
      'PORCENTAGEM DE PARTICIPAÇÃO',
      'DATA ATUALIZAÇÃO',
    ];

    const headersHistoryPayments = [
      'CNPJ',
      'NOME FANTASIA',
      'SCORE',
      'RANGE',
      'DIA INICIAL DO RANGE',
      'DIA FINAL DO RANGE',
    ];

    // Configurando relacao key - header pra cada table
    const applyHeaders = (sheet: ExcelJS.Worksheet, headers: string[]) => {
      sheet.columns = headers.map(header => ({
        header,
        key: header,
      }));
    };

    applyHeaders(tableWorksheet, headersTable);
    applyHeaders(pefinWorksheet, headersPefinRefin);
    applyHeaders(refinWorksheet, headersPefinRefin);
    applyHeaders(debtsWorkSheet, headersDebts);
    applyHeaders(protestsWorkSheet, headersProtests);
    applyHeaders(checksWorkSheet, headersChecks);
    applyHeaders(inquerysWorkSheet, headersInquerysAndStolenDocs);
    applyHeaders(stolenDocsWorkSheet, headersInquerysAndStolenDocs);
    applyHeaders(inquerysSpcWorksheet, headersInquerysAndStolenDocs);
    applyHeaders(bankruptsWorkSheet, headersBankrupts);
    applyHeaders(judgementFilingsWorksheet, headersJudgementFilings);
    applyHeaders(partnerWorkSheet, headersPartners);
    applyHeaders(historyPaymentsWorkSheet, headersHistoryPayments);
    applyHeaders(inquerysSpcWorksheet, headersInquerysAndStolenDocs);

    // Aplicando a cor de fundo azul para a primeira linha (headers)
    const applyStyle = (sheet: ExcelJS.Worksheet) => {
      sheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
        cell.fill = titleCell;
        cell.font = { bold: true, color: { argb: 'FFFFFF' } };
      });
    };

    applyStyle(tableWorksheet);
    applyStyle(pefinWorksheet);
    applyStyle(refinWorksheet);
    applyStyle(debtsWorkSheet);
    applyStyle(protestsWorkSheet);
    applyStyle(checksWorkSheet);
    applyStyle(inquerysWorkSheet);
    applyStyle(stolenDocsWorkSheet);
    applyStyle(bankruptsWorkSheet);
    applyStyle(judgementFilingsWorksheet);
    applyStyle(partnerWorkSheet);
    applyStyle(historyPaymentsWorkSheet);
    applyStyle(inquerysSpcWorksheet);

    // Preenchendo os dados
    serasaData?.forEach(serasa => {
      const rowTable = {};
      const serasaData = serasa.serasa as KleverSerasaPerson;
      const address = serasaData.registration?.address;

      const openDebts =
        serasa.type === 'company'
          ? serasa.checkAmount + serasa.pefinAmount + serasa.refinAmount
          : serasa.pefinAmount + serasa.refinAmount;

      const serasaBalance =
        serasa.type === 'company'
          ? serasa.protests.summary?.balance
          : serasa.protests?.response?.reduce(
              (acc, curr) => acc + (curr.amount ?? 0),
              0,
            );

      const active = inactiveClients.includes(serasa?.enterprise?.status)
        ? 'Inativo'
        : 'Ativo';

      const overdueDebtsSummary = serasa.overdueDebts.summary;

      const spcNegativeResponse =
        serasaData.optionalFeatures?.spcAnnotations?.spcNegative
          ?.spcNegativeResponse;

      const attAffordability =
        serasaData.optionalFeatures?.attributes?.affordability;

      const attCreditCardOnTimePaymentIndex =
        serasaData.optionalFeatures?.attributes?.creditCardOnTimePaymentIndex;

      const attCreditCardTrend =
        serasaData.optionalFeatures?.attributes?.creditCardUsageTrend;

      const attEstimatedIncome =
        serasaData.optionalFeatures?.attributes?.estimatedIncome;

      const attIncomeCommitment =
        serasaData.optionalFeatures?.attributes?.incomeCommitment;

      const attOnTimePaymentsIndex =
        serasaData.optionalFeatures?.attributes?.onTimePaymentsIndex;

      const bankruptsSummary = serasaData.optionalFeatures?.bankrupts?.summary;

      const creditLimitDecision =
        serasaData.optionalFeatures?.creditLimitAndDecision?.query?.data;

      const judgementFilingsResume =
        serasaData.optionalFeatures?.judgementFilings?.summary;

      const partner = serasaData.optionalFeatures?.partner;

      const spcInquerysCredit =
        serasaData.optionalFeatures?.spcInquiries?.creditInquiriesQuantity;

      rowTable['TIPO'] = ESerasaType[serasa.type];
      rowTable['CNPJ'] = serasa.document;
      rowTable['NOME FANTASIA'] = serasa.enterprise.fantasyName;
      rowTable['DÍVIDAS EM ABERTO'] = getMoneyPtBr(openDebts);
      rowTable['PROTESTOS'] = getMoneyPtBr(serasaBalance);
      rowTable['STATUS'] = serasa.enterprise?.status;
      rowTable['ATIVO/INATIVO'] = active;
      rowTable['DATA DE NASCIMENTO'] = getDatePtBr(
        serasaData.registration?.birthDate,
      );
      rowTable['NOME DA MÃE'] = serasaData.registration?.motherName;
      rowTable['SITUAÇÃO'] = serasaData.registration?.statusRegistration;
      rowTable['DATA - STATUS'] = getDatePtBr(serasaData.registration?.statusDate);
      rowTable['ENDEREÇO 1'] = address?.address1;
      rowTable['ENDEREÇO 2'] = address?.address2;
      rowTable['MUNÍCIPIO FRONTEIRIÇO'] = address?.borderMunicipality;
      rowTable['TIPO DE RESIDÊNCIA'] = address?.buildingType;
      rowTable['CÓDIGO DO PÁIS'] = address?.codeCountry;
      rowTable['CÓDIGO DO MUNICÍPIO'] = address?.codeMunicipality;
      rowTable['EDIFÍCIO COLETIVO'] = address?.collectiveBuilding ? 'Sim' : 'Não';
      rowTable['PAÍS'] = address?.country;
      rowTable['RESTRIÇÃO DE ENTREGA'] = address?.deliveryRestriction
        ? 'Sim'
        : 'Não';
      rowTable['ENDEREÇO FORMATADO'] = address?.formattedAddress;
      rowTable['ÚLTIMO ENDEREÇO'] = address?.latestAddress ? 'Sim' : 'Não';
      rowTable['LATITUDE'] = address?.latitude?.toString();
      rowTable['LONGITUDE'] = address?.longitude?.toString();
      rowTable['MICROREGIÃO'] = address?.microRegion;
      rowTable['MACROREGIÃO'] = address?.mregion;
      rowTable['MUNICÍPIO'] = address?.municipality;
      rowTable['BAIRRO'] = address?.neighborhood;
      rowTable['NÚMERO'] = address?.number;
      rowTable['BAIRRO ORIGINAL'] = address?.originalNeighborhood;
      rowTable['PRECISÃO'] = address?.precision;
      rowTable['REGIÃO'] = address?.region;
      rowTable['ESTADO REGISTRADO'] = address?.registryUf;
      rowTable['ENDEREÇO RESIDENTE'] = address?.residentialAddress ? 'Sim' : 'Não';
      rowTable['TELEFONES'] = address?.rfPhones?.map(item => item).join(' , ');
      rowTable['ESTADO'] = address?.uf;
      rowTable['CEP'] = address?.zip;
      rowTable[
        'PEFIN (RESUMO) - QUANTIDADE'
      ] = serasa.pefin.summary?.count?.toString();
      rowTable[
        'REFIN (RESUMO) - QUANTIDADE'
      ] = serasa.refin.summary?.count?.toString();
      rowTable[
        'DÍVIDAS VENCIDAS CONVEM (RESUMO) - QUANTIDADE'
      ] = overdueDebtsSummary?.count?.toString();
      rowTable['DÍVIDAS VENCIDAS CONVEM (RESUMO) - VALOR'] = getMoneyPtBr(
        overdueDebtsSummary?.balance,
      );
      rowTable[
        'PROTESTOS (RESUMO) - QUANTIDADE'
      ] = serasa.protests.summary?.count.toString();
      rowTable[
        'CHEQUES SEM FUNDO (RESUMO) - QUANTIDADE'
      ] = serasa.check.summary?.count.toString();
      rowTable[
        'INQUÉRITOS (RESUMO) - QUANTIDADE'
      ] = serasaData.facts?.inquiry?.summary?.count.toString();
      rowTable[
        'DOCUMENTOS ROUBADOS (RESUMO) - QUANTIDADE'
      ] = serasaData.facts?.stolenDocuments?.summary?.count.toString();
      // ANNOTATIONS
      rowTable[
        'ANOTAÇÕES SPC - QUANTIDADE TOTAL'
      ] = spcNegativeResponse?.summary.count.toString();
      rowTable['ANOTAÇÕES SPC - VALOR TOTAL'] = getMoneyPtBr(
        spcNegativeResponse?.summary.balance,
      );
      rowTable['ANOTAÇÕES SPC - PRIMEIRA OCORRÊNCIA'] =
        spcNegativeResponse?.summary.firstOccurrence;
      rowTable['ANOTAÇÕES SPC - ULTIMA OCORRÊNCIA'] =
        spcNegativeResponse?.summary.lastOccurrence;
      rowTable['ANOTAÇÕES SPC - VALOR DA ANOTAÇÃO'] = getMoneyPtBr(
        spcNegativeResponse?.amount,
      );
      rowTable['ANOTAÇÕES SPC - CIDADE'] = spcNegativeResponse?.city;
      rowTable['ANOTAÇÕES SPC - IDENTIFICADOR DO CONTRATO'] =
        spcNegativeResponse?.contractId;
      rowTable['ANOTAÇÕES SPC - CIDADE DA INSTITUIÇÃO FINANCEIRA'] =
        spcNegativeResponse?.creditorCity;
      rowTable['ANOTAÇÕES SPC - ESTADO DA INSTITUIÇÃO FINANCEIRA'] =
        spcNegativeResponse?.creditorFederalUnit;
      rowTable['ANOTAÇÕES SPC - NOME DA INSTITUIÇÃO FINANCEIRA'] =
        spcNegativeResponse?.creditorName;
      rowTable['ANOTAÇÕES SPC - ESTADO'] = spcNegativeResponse?.federalUnit;
      rowTable['ANOTAÇÕES SPC - DATA DA OCORRÊNCIA'] = getDatePtBr(
        spcNegativeResponse?.occurrenceDate,
      );
      // ATRIBUTO
      rowTable['ATRIBUTO ACESSIBILIDADE - MODELO'] =
        attAffordability?.attributeModel;
      rowTable[
        'ATRIBUTO ACESSIBILIDADE - CÓDIGO'
      ] = attAffordability?.codeMessage.toString();
      rowTable['ATRIBUTO ACESSIBILIDADE - MENSAGEM'] = attAffordability?.message;
      rowTable[
        'ATRIBUTO ACESSIBILIDADE - PONTUAÇÃO'
      ] = attAffordability?.scoring.toString();
      rowTable[
        'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - MODELO'
      ] = attCreditCardOnTimePaymentIndex?.attributeModel;
      rowTable[
        'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - CÓDIGO'
      ] = attCreditCardOnTimePaymentIndex?.codeMessage.toString();
      rowTable[
        'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - MENSAGEM'
      ] = attCreditCardOnTimePaymentIndex?.message;
      rowTable[
        'ATRIBUTO PAGAMENTO DENTRO DO PRAZO COM CARTAO DE CRÉDITO - PONTUAÇÃO'
      ] = attCreditCardOnTimePaymentIndex?.scoring.toString();
      rowTable['ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - MODELO'] =
        attCreditCardTrend?.attributeModel;
      rowTable[
        'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - CÓDIGO'
      ] = attCreditCardTrend?.codeMessage.toString();
      rowTable['ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - MENSAGEM'] =
        attCreditCardTrend?.message;
      rowTable[
        'ATRIBUTO TENDÊNCIA DO USO DE CARTAO DE CRÉDITO - PONTUAÇÃO'
      ] = attCreditCardTrend?.scoring.toString();
      rowTable['ATRIBUTO RENDA ESTIMADA - MODELO'] =
        attEstimatedIncome?.attributeModel;
      rowTable[
        'ATRIBUTO RENDA ESTIMADA - CÓDIGO'
      ] = attEstimatedIncome?.codeMessage.toString();
      rowTable['ATRIBUTO RENDA ESTIMADA - MENSAGEM'] = attEstimatedIncome?.message;
      rowTable[
        'ATRIBUTO RENDA ESTIMADA - PONTUAÇÃO'
      ] = attEstimatedIncome?.scoring.toString();
      rowTable['ATRIBUTO COMPROMISSO DE RENDA - MODELO'] =
        attIncomeCommitment?.attributeModel;
      rowTable[
        'ATRIBUTO COMPROMISSO DE RENDA - CÓDIGO'
      ] = attIncomeCommitment?.codeMessage.toString();
      rowTable['ATRIBUTO COMPROMISSO DE RENDA - MENSAGEM'] =
        attIncomeCommitment?.message;
      rowTable[
        'ATRIBUTO COMPROMISSO DE RENDA - PONTUAÇÃO'
      ] = attEstimatedIncome?.scoring.toString();
      rowTable['ATRIBUTO PAGAMENTOS EM DIA - MODELO'] =
        attOnTimePaymentsIndex?.attributeModel;
      rowTable[
        'ATRIBUTO PAGAMENTOS EM DIA - CÓDIGO'
      ] = attOnTimePaymentsIndex?.codeMessage.toString();
      rowTable['ATRIBUTO PAGAMENTOS EM DIA - MENSAGEM'] =
        attOnTimePaymentsIndex?.message;
      rowTable[
        'ATRIBUTO PAGAMENTOS EM DIA - PONTUAÇÃO'
      ] = attOnTimePaymentsIndex?.scoring.toString();
      // FALENCIAS (BANKRUPT)
      rowTable[
        'FALÊNCIAS (RESUMO) - QUANTIDADE'
      ] = bankruptsSummary?.count.toString();
      rowTable['FALÊNCIAS (RESUMO) - VALOR'] = getMoneyPtBr(
        bankruptsSummary?.balance,
      );
      rowTable['FALÊNCIAS (RESUMO) - PRIMEIRA OCORRÊNCIA'] = getDatePtBr(
        bankruptsSummary?.firstOccurrence,
      );
      rowTable['FALÊNCIAS (RESUMO) - ÚLTIMA OCORRÊNCIA'] = getDatePtBr(
        bankruptsSummary?.lastOccurrence,
      );
      // LIMITE DE CREDITO (CREDIT LIMITATION)
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO - CÓDIGO'
      ] = serasaData.optionalFeatures?.creditLimitAndDecision?.query.code.toString();
      rowTable['LIMITE DE CRÉDITO E DECISÃO - MENSAGEM'] =
        serasaData.optionalFeatures?.creditLimitAndDecision?.query.message;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DOCUMENTO ATIVO'
      ] = creditLimitDecision?.analyzedCriteria.documentActive ? 'Sim' : 'Não';
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - COMPROMISSO BAIXA RENDA'
      ] = creditLimitDecision?.analyzedCriteria.lowIncomeCommitment ? 'Sim' : 'Não';
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - SEM RECLAMAÇÃO'
      ] = creditLimitDecision?.analyzedCriteria.noProtests ? 'Sim' : 'Não';
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - SEM RESTRIÇÃO'
      ] = creditLimitDecision?.analyzedCriteria.noRestriction ? 'Sim' : 'Não';
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - COBRANÇA'
      ] = creditLimitDecision?.billing ? 'Sim' : 'Não';
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - NOME DO CONSUMIDOR'
      ] = creditLimitDecision?.consumerName;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DATA DE CRIAÇÃO'
      ] = getDatePtBr(creditLimitDecision?.creationDate);
      rowTable['LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - DOCUMENTO'] =
        creditLimitDecision?.document;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - NUMERO DA PROPOSTA'
      ] = creditLimitDecision?.proposalNumber;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE RECOMENTDAÇÃO VENDA'
      ] = creditLimitDecision?.recommendationSaleType;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE RECOMENDAÇÃO'
      ] = creditLimitDecision?.recommendationType;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - VALOR LIMITE RECOMENDADO'
      ] = getMoneyPtBr(creditLimitDecision?.recommendedLimitValue);
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - CÓDIGO DO NÍVEL DE RISCO'
      ] = creditLimitDecision?.riskLevelCode.toString();
      rowTable['LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - TIPO DE VENDA'] =
        creditLimitDecision?.saleType;
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - CÓDIGO DO TIPO DE VENDA'
      ] = creditLimitDecision?.saleTypeCode.toString();
      rowTable[
        'LIMITE DE CRÉDITO E DECISÃO CRITÉRIO ANALISADO - VALOR DA TRANSAÇÃO'
      ] = getMoneyPtBr(creditLimitDecision?.transactionValue);
      rowTable[
        'ARQUIVOS DE JULGAMENTO (RESUMO) - QUANTIDADE'
      ] = judgementFilingsResume?.count.toString();
      rowTable['ARQUIVOS DE JULGAMENTO (RESUMO) - VALOR'] = getMoneyPtBr(
        judgementFilingsResume?.balance,
      );
      rowTable[
        'ARQUIVOS DE JULGAMENTO (RESUMO) - PRIMEIRA OCORRÊNCIA'
      ] = getDatePtBr(judgementFilingsResume?.firstOccurrence);
      rowTable['ARQUIVOS DE JULGAMENTO (RESUMO) - ÚLTIMA OCORRÊNCIA'] = getDatePtBr(
        judgementFilingsResume?.lastOccurrence,
      );
      rowTable['SÓCIO - QUANTIDADE'] = partner?.summary?.count.toString();
      rowTable[
        'INQUÉRITOS (SPC) - QUANTIDADE'
      ] = serasaData.optionalFeatures?.spcInquiries?.summary?.count.toString();
      rowTable[
        'INQUÉRITOS (SPC) - QUANTIDADE ATUAL'
      ] = serasaData.optionalFeatures?.spcInquiries?.spcInquirySummary.spcInquiryQuantity.actual.toString();
      rowTable['INQUÉRITOS (SPC) - DATA INQUERITO'] = getDatePtBr(
        spcInquerysCredit?.inquiryDate,
      );
      rowTable[
        'INQUÉRITOS (SPC) - OCORRÊNCIAS'
      ] = spcInquerysCredit?.occurrences.toString();
      rowTable[
        'SERASA SCORE 2.0 - PONTUAÇÃO'
      ] = serasaData?.score?.score.toString();
      rowTable['SERASA SCORE 2.0 - MODELO'] = serasaData.score?.scoreModel;
      rowTable['SERASA SCORE 2.0 - PROBABILIDADE DE NAO PAGAMENTO'] =
        serasaData.score?.defaultRate;
      rowTable[
        'SERASA SCORE 2.0 - CÓDIGO MSG'
      ] = serasaData.score?.codeMessage?.toString();

      tableWorksheet.addRow(rowTable);

      //rowTable[] = ;

      // PREENCHENDO DADOS PEFIN REFIN
      const generateRowsPefinRefin = (
        data: PersonPefinInnerResponseTO[] | PersonRefinInnerResponseTO[],
        sheet: ExcelJS.Worksheet,
      ) => {
        if (data && data.length > 0) {
          const rowPFRF = {};
          data.map(item => {
            rowPFRF['CNPJ'] = serasa.document;
            rowPFRF['NOME FANTASIA'] = serasa.enterprise.fantasyName;
            rowPFRF['VALOR'] = getMoneyPtBr(item?.amount ?? 0);
            rowPFRF['DATA DISPONÍVEL'] = getDatePtBr(item?.availableDate ?? '');
            rowPFRF['NÚMERO DO CASO'] = item?.caseNumber;
            rowPFRF['CIDADE'] = item?.city;
            rowPFRF['UNIDADE FEDERATIVA'] = item?.federalUnit;
            rowPFRF['TRIBUNAL CIVIL'] = item?.civilCourt;
            rowPFRF['IDENTIFICADOR INSTITUIÇÃO FINANCEIRA'] =
              item?.creditorBusinessId;
            rowPFRF['NOME DA INSTITUIÇÃO FINANCEIRA'] = item?.creditorName;
            rowPFRF['DISTRIBUIDOR'] = item?.distributor;
            rowPFRF['SUBJUDICE'] = item?.flagSubJudice ? 'Sim' : 'Não';
            rowPFRF['DATA INCLUSÃO'] = getDatePtBr(item?.inclusionDate ?? '');
            rowPFRF['NATUREZA JURÍDICA'] = item?.legalNature;
            rowPFRF['ID NATUREZA JURÍDICA'] = item?.legalNatureId;
            rowPFRF['PRAÇA LEGAL'] = item?.legalSquare;
            rowPFRF['DATA OCORRÊNCIA'] = getDatePtBr(item?.occurrenceDate ?? '');
            rowPFRF['PRINCIPAL'] = item?.principal ? 'Sim' : 'Não';
            rowPFRF['ID DO CONTRATO'] = item?.contractId;
            rowPFRF['AGÊNCIA PÚBLICA'] = item?.publicAgency ? 'Sim' : 'Não';

            sheet.addRow(rowPFRF);
          });
        }
      };

      const pefinArray = serasa.pefin?.response as PersonPefinInnerResponseTO[];
      const refinArray = serasa.refin?.response as PersonRefinInnerResponseTO[];

      generateRowsPefinRefin(pefinArray, pefinWorksheet);
      generateRowsPefinRefin(refinArray, refinWorksheet);

      // PREENCHENDO DADOS DÉBITOS
      const overdueDebts = serasa.overdueDebts as OverdueDebtsTO;
      const debts = overdueDebts.collectionRecordsResponse;

      debts?.map(debt => {
        const rowDebts = {};

        rowDebts['CNPJ'] = serasa.document;
        rowDebts['NOME FANTASIA'] = serasa.enterprise.fantasyName;
        rowDebts['VALOR'] = getMoneyPtBr(debt.amount ?? 0);
        rowDebts['CIDADE'] = debt.city;
        rowDebts['ID DO CONTRATO'] = debt.contractId;
        rowDebts['IDENTIFICADOR INSTITUIÇÃO FINANCEIRA'] = debt.creditorBusinessId;
        rowDebts['NOME DA INSTITUIÇÃO FINANCEIRA'] = debt.creditorName;
        rowDebts['UNIDADE FEDERATIVA'] = debt.federalUnit;
        rowDebts['SUBJUDICE'] = debt.flagSubJudice ? 'Sim' : 'Não';
        rowDebts['NATUREZA JURÍDICA'] = debt.legalNature;
        rowDebts['ID NATUREZA JURÍDICA'] = debt.legalNatureId;
        rowDebts['DATA OCORRÊNCIA'] = getDatePtBr(debt.occurrenceDate ?? '');
        rowDebts['PRINCIPAL'] = debt.principal ? 'Sim' : 'Não';
        rowDebts['AGÊNCIA PÚBLICA'] = debt.publicAgency ? 'Sim' : 'Não';

        debtsWorkSheet.addRow(rowDebts);
      });

      // PREENCHENDO DADOS PROTESTOS
      const protests = serasa.protests?.response;

      protests?.map(protest => {
        const rowProtests = {};

        rowProtests['CNPJ'] = serasa.document;
        rowProtests['NOME FANTASIA'] = serasa.enterprise.fantasyName;
        rowProtests['VALOR'] = getMoneyPtBr(protest.amount ?? 0);
        rowProtests['CIDADE'] = protest.city;
        rowProtests['UNIDADE FEDERATIVA'] = protest.federalUnit;
        rowProtests['NOME DO ESCRITÓRIO'] = protest.officeName;
        rowProtests['NÚMERO DO ESCRITÓRIO'] = protest.officeNumber;
        rowProtests['DATA OCORRÊNCIA'] = getDatePtBr(protest.occurrenceDate ?? '');

        protestsWorkSheet.addRow(rowProtests);
      });

      // PREENCHENDO DADOS CHEQUES SEM FUNDO
      const checks = serasa.check?.response;

      checks?.map(check => {
        const rowChecks = {};
        rowChecks['CNPJ'] = serasa.document;
        rowChecks['NOME FANTASIA'] = serasa.enterprise.fantasyName;

        rowChecks['CIDADE'] = check.city;
        rowChecks['UNIDADE FEDERATIVA'] = check.federalUnit;
        rowChecks[
          'IDENTIFICADOR DO MOTIVO DE DEVOLUÇÃO DO CHEQUE'
        ] = check.alinea.toString();
        rowChecks['NÚMERO DA AGÊNCIA'] = check.bankAgencyId.toString();
        rowChecks['ID DO BANCO'] = check.bankId?.toString();
        rowChecks['NOME DO BANCO'] = check.bankName;
        rowChecks['QUANTIDADE DE CHEQUES'] = check.checkCount?.toString();
        rowChecks['NÚMERO DO CHEQUE'] = check.checkNumber?.toString();
        rowChecks['DATA OCORRÊNCIA'] = getDatePtBr(check.occurrenceDate ?? '');
        rowChecks['PRAÇA LEGAL'] =
          (check as PersonCheckInnerResponseTO).legalSquare ?? '';

        checksWorkSheet.addRow(rowChecks);
      });

      // PREENCHENDO DADOS INQUERYS AND STOLEN DOCS
      const generateRowsInquerysAndStolenDocs = (
        data: ResponseTO[],
        sheet: ExcelJS.Worksheet,
      ) => {
        if (data && data.length > 0) {
          const rowInqueryStolenDocs = {};
          data.map(item => {
            rowInqueryStolenDocs['CNPJ'] = serasa.document;
            rowInqueryStolenDocs['NOME FANTASIA'] = serasa.enterprise.fantasyName;
            rowInqueryStolenDocs['DESCRIÇÃO DO SEGMENTO'] = item.segmentDescription;
            rowInqueryStolenDocs['DATA DA OCORRÊNCIA'] = getDatePtBr(
              item?.occurrenceDate ?? '',
            );

            sheet.addRow(rowInqueryStolenDocs);
          });
        }
      };

      const inquerys = serasaData.facts?.inquiry?.inquiryResponse;
      const stolenDocs = serasaData.facts?.stolenDocuments?.stolenDocumentsResponse;
      const inquerysSpc =
        serasaData.optionalFeatures?.spcInquiries?.spcInquires
          ?.spcInquiriesResponse;

      generateRowsInquerysAndStolenDocs(inquerys, inquerysWorkSheet);
      generateRowsInquerysAndStolenDocs(stolenDocs, stolenDocsWorkSheet);
      generateRowsInquerysAndStolenDocs(inquerysSpc, inquerysSpcWorksheet);

      // PREENCHENDO DADOS FALÊNCIAS
      const bankrupts = serasaData.optionalFeatures?.bankrupts?.response;

      bankrupts?.map(bankrupt => {
        const rowBankrupts = {};
        rowBankrupts['CNPJ'] = serasa.document;
        rowBankrupts['NOME FANTASIA'] = serasa.enterprise.fantasyName;

        rowBankrupts['CIDADE'] = bankrupt.city;
        rowBankrupts['ESTADO'] = bankrupt.state;
        rowBankrupts['NÚMERO DO PROCESSO'] = bankrupt.caseNumber;
        rowBankrupts['IDENTIFICADOR DOCUMENTO DA EMPRESA'] =
          bankrupt.companyDocumentId;
        rowBankrupts['NOME DA EMPRESA'] = bankrupt.companyName;
        rowBankrupts['DATA DA INCLUSÃO'] = getDatePtBr(
          bankrupt.inclusionDate ?? '',
        );
        rowBankrupts['NATUREZA JURÍDICA'] = bankrupt.legalNature;
        rowBankrupts['DATA DA OCORRÊNCIA'] = getDatePtBr(
          bankrupt.occurrenceDate ?? '',
        );
        rowBankrupts['POSIÇÃO EMPRESA'] = bankrupt.positionCompany;

        bankruptsWorkSheet.addRow(rowBankrupts);
      });

      // PREENCHENDO DADOS ARQUIVOS DE JULGAMENTO
      const judgementFilings =
        serasaData.optionalFeatures?.judgementFilings?.response;

      judgementFilings?.map(jF => {
        const rowJudgeFil = {};
        rowJudgeFil['CNPJ'] = serasa.document;
        rowJudgeFil['NOME FANTASIA'] = serasa.enterprise.fantasyName;
        rowJudgeFil['VALOR'] = getMoneyPtBr(jF.amount ?? 0);
        rowJudgeFil['DATA DISPONÍVEL'] = getDatePtBr(jF.availableDate ?? '');
        rowJudgeFil['NÚMERO DO CASO'] = jF.caseNumber;
        rowJudgeFil['CIDADE'] = jF.city;
        rowJudgeFil['UNIDADE FEDERATIVA'] = jF.state;
        rowJudgeFil['TRIBUNAL CIVIL'] = jF.civilCourt;
        rowJudgeFil['DISTRIBUIDOR'] = jF.distributor;
        rowJudgeFil['SUBJUDICE'] = jF.flagSubJudice ? 'Sim' : 'Não';
        rowJudgeFil['DATA INCLUSÃO'] = getDatePtBr(jF.inclusionDate ?? '');
        rowJudgeFil['CÓDIGO LEGAL'] = jF.legalNature;
        rowJudgeFil['IDENTIFICADOR CÓDIGO LEGAL'] = jF.legalNatureId;
        rowJudgeFil['PRAÇA LEGAL'] = jF.legalSquare;
        rowJudgeFil['DATA OCORRÊNCIA'] = getDatePtBr(jF.occurrenceDate ?? '');
        rowJudgeFil['PRINCIPAL'] = jF.principal ? 'Sim' : 'Não';

        judgementFilingsWorksheet.addRow(rowJudgeFil);
      });

      // PREENCHENDO DADOS SÓCIO
      const partners = serasaData.optionalFeatures?.partner.partnerShipResponse;

      partners?.map(partner => {
        const rowPartner = {};
        rowPartner['CNPJ'] = serasa.document;
        rowPartner['NOME FANTASIA'] = serasa.enterprise.fantasyName;
        rowPartner['NOME'] = partner.companyName;
        rowPartner['ESTADO'] = partner.companyState;
        rowPartner['STATUS'] = partner.companyStatus;
        rowPartner['CÓDIGO STATUS'] = partner.companyStatusCode;
        rowPartner['DATA STATUS'] = getDatePtBr(partner.companyStatusDate ?? '');
        rowPartner['DATA INÍCIO DA SOCIEDADE'] = getDatePtBr(
          partner.participationInitialDate ?? '',
        );
        rowPartner[
          'PORCENTAGEM DE PARTICIPAÇÃO'
        ] = partner.participationPercentage?.toLocaleString('pt-BR', {
          style: 'percent',
          minimumFractionDigits: 2,
        });
        rowPartner['DATA ATUALIZAÇÃO'] = getDatePtBr(partner.updateDate ?? '');

        partnerWorkSheet.addRow(rowPartner);
      });

      // PREENCHENDO DADOS SÓCIO
      const paymentsHistory =
        serasaData.optionalFeatures?.paymentsHistory?.response;

      paymentsHistory?.map(payH => {
        const rowHistPay = {};
        rowHistPay['CNPJ'] = serasa.document;
        rowHistPay['NOME FANTASIA'] = serasa.enterprise.fantasyName;
        rowHistPay['SCORE'] = payH.score?.toString();
        rowHistPay['RANGE'] = payH.range;
        rowHistPay['DIA INICIAL DO RANGE'] = payH.initialDayRange?.toString();
        rowHistPay['DIA FINAL DO RANGE'] = payH.finalDayRange?.toString();

        historyPaymentsWorkSheet.addRow(rowHistPay);
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_serasa.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcel,
  };
};
