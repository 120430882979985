import { IGetValueVariableResponse, ISystemValueMapping } from 'models';

import api from 'config/api';

export class SystemValueMappingService {
  static async find() {
    const { data } = await api.get<ISystemValueMapping[]>(`/system-value-mapping`);
    return data;
  }

  static async getValueVariable(ids: string[], enterpriseId: string) {
    const { data } = await api.get<IGetValueVariableResponse[]>(
      `/system-value-mapping/get-value-variable/${enterpriseId}`,
      { params: { idsVariable: ids } },
    );
    return data;
  }
}
