import { stringify } from 'qs';

import { IFilterDatabasePayload } from 'components/FilterDatabase/filterDatabase.types';
import { IPagination } from 'models';

import api from 'config/api';

import { ISerasaResponse } from './types';

export class SerasaService {
  static async getSerasaData({
    page,
    perPage,
    search,
    type,
    active,
    status,
    manager,
    product,
  }: IFilterDatabasePayload): Promise<IPagination<ISerasaResponse>> {
    const params = stringify({
      page,
      perPage,
      search,
      type,
      active,
      status,
      manager,
      product,
    });

    const response = await api.get<IPagination<ISerasaResponse>>(
      `/enterprise/serasa?${params}`,
    );

    return response.data;
  }
}
