import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  FileStorageService,
  IFileResponse,
  IFindOneFileStoragePayload,
} from 'modules/file';

const useGetUrlDownloadRequest = (
  options?: UseMutationOptions<
    IFileResponse,
    IApiAxiosError,
    IFindOneFileStoragePayload
  >,
) => {
  return useMutation(
    'questionAttachentByQuestion',
    FileStorageService.findOne,
    options,
  );
};

export { useGetUrlDownloadRequest };
