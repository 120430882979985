import { ContentCopy, Edit } from '@mui/icons-material';
import { TableCell, Icon, Checkbox } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { IForm } from 'models';

import { useDynamicForm, useEditDynamicForm, useFormItem } from '../hooks';

export const ItemForm = (value: IForm) => {
  const { valueCheckBox, handleInactiveForms } = useFormItem();
  const { handleOpenEditFormPage } = useEditDynamicForm();

  const { setOpenConfirmDuplicateDialog, setIdFormDuplicate } = useDynamicForm();

  const emptyString = '---';
  const { colors, fontSizes } = themes;

  return (
    <>
      <TableRow key={value.id} style={{ cursor: 'default' }}>
        <TableCell width={300}>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value?.title ? value?.title : emptyString}
          </Typography>
        </TableCell>

        <TableCell width={500}>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value?.caption ? value?.caption : emptyString}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value?.user?.name ?? emptyString}
          </Typography>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value?.createdAt &&
              new Date(value.createdAt).toLocaleDateString('pt-br', {
                timeZone: 'UTC',
              })}
            {' - '}
            {value?.createdAt &&
              new Date(value.createdAt).toLocaleTimeString('pt-br', {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </Typography>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          ></Typography>
        </TableCell>

        <TableCell width={'60px'}>
          <TypographyActive
            style={{ width: '40px', fontSize: `${fontSizes.s}px` }}
            textAlign="center"
            backgroundColor={value.active ? colors.green : colors.error}
          >
            {value.active ? 'Ativo' : 'Inativo'}
          </TypographyActive>
        </TableCell>

        <TableCell align="center" padding="checkbox">
          <Icon>
            <Typography
              onClick={() => {
                setOpenConfirmDuplicateDialog(true);
                setIdFormDuplicate(value?.id);
              }}
              style={{ cursor: 'pointer' }}
            >
              <ContentCopy />
            </Typography>
          </Icon>
        </TableCell>

        <TableCell align="center" padding="checkbox">
          <Icon>
            <Typography
              onClick={() => handleOpenEditFormPage(value)}
              style={{ cursor: 'pointer' }}
            >
              <Edit />
            </Typography>
          </Icon>
        </TableCell>

        <TableCell width={50} align="center" padding="checkbox">
          <Typography>
            <Checkbox
              checked={valueCheckBox}
              disabled={!value.active}
              size={'medium'}
              onChange={() => handleInactiveForms(value.id)}
            />
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
};
