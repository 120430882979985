import { Box, Logo } from 'components';

import { FormPasswordSetting } from 'modules/signUp/components';

import { FormCodeValidation } from '../components';
import { useInternalPasswordSetting } from '../hooks/useInternalPasswordSetting';
import { useValidateCode } from '../hooks/useValidateCode';
import { useSignUpEnterpriseStore } from '../store/store';

export function PasswordSettingInternalRegisterPage() {
  const {
    form: passwordForm,
    request: passwordRequest,
  } = useInternalPasswordSetting();
  const { form: codeForm, request: codeRequest } = useValidateCode();
  const { codeValidated } = useSignUpEnterpriseStore();

  return (
    <Box
      marginTop={6}
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '3em',
      }}
    >
      <Logo />
      {codeValidated ? (
        <FormPasswordSetting
          form={passwordForm}
          isSigningUp={passwordRequest.isLoading}
        />
      ) : (
        <FormCodeValidation form={codeForm} isLoading={codeRequest.isLoading} />
      )}
    </Box>
  );
}
