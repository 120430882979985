import { useActOperationDocumentsRequest } from './useActOperationDocumentsRequest';

export const useActOperationDocuments = (operationId: string) => {
  const { data, isLoading, refetch } = useActOperationDocumentsRequest(operationId);

  const noData = !data || data.length === 0;

  return {
    data,
    isLoading,
    refetch,

    noData,
  };
};
