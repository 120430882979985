import { stringify } from 'qs';

import api from 'config/api';

import {
  INotificationPayload,
  ListNotificationsPayload,
  ListNotificationsResponse,
} from './types';

export class NotificationService {
  static async create(payload: INotificationPayload) {
    const response = await api.post(`/notification`, payload);

    return response.data;
  }

  static async getPaginatedNotifications(params: ListNotificationsPayload) {
    const queryString = stringify(params);

    const response = await api.get<ListNotificationsResponse>(
      `/notification?${queryString}`,
    );

    return response.data;
  }

  static async readAllNotifications() {
    const { data } = await api.put<{ data: { affected: number } }>(
      `notification/read-all`,
    );

    return data;
  }
}
