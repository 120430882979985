import { Divider, Grid, Typography } from '@mui/material';

import { KleverBalanceInfoTO } from 'models';

import { KMethodItem } from './KMethodItem';

interface SpedBalanceProps {
  storage: KleverBalanceInfoTO[];
  title: string;
}

const SpedBalance = ({ storage, title }: SpedBalanceProps) => {
  return (
    <>
      <Grid xs={12} marginTop={1}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <Typography fontWeight={700} fontSize={20} marginTop={1}>
          {title}
        </Typography>
      </Grid>

      {storage?.map((item, index) => (
        <Grid container key={item?.code} marginY={index > 0 ? 2 : 0}>
          <KMethodItem title="Código" value={item?.code} />
          <KMethodItem title="Nome" value={item?.name} />
          <KMethodItem title="Percentual HA" value={item?.percentHA} />
          <KMethodItem title="Percentual VA" value={item?.percentVA} />
          <KMethodItem title="Código raíz" value={item?.rootCode} />
          <KMethodItem
            title="Planilha"
            value={item?.sheet !== undefined ? String(item?.sheet) : null}
          />
          <KMethodItem title="Tipo" value={item?.type} />
          <KMethodItem title="Valor" value={item?.value} />
        </Grid>
      ))}
    </>
  );
};

export { SpedBalance };
