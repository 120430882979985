import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  CustomerQueryKey,
  CustomerService,
  IUpdateCustomerStatusPayload,
} from '../services';

const useUpdateStatusRequest = (
  options?: UseMutationOptions<
    unknown,
    IApiAxiosError,
    IUpdateCustomerStatusPayload
  >,
) => {
  return useMutation(
    CustomerQueryKey.UPDATE_STATUS,
    CustomerService.updateCustomerStatus,
    options,
  );
};

export { useUpdateStatusRequest };
