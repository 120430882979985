import { toast } from 'react-toastify';

import { useSignUpEnterpriseStore } from '../store/store';
import { useInternalPasswordSettingRequest } from './useInternalPasswordSettingRequest';
import { usePasswordSettingForm } from './usePasswordSettingForm';

export const useInternalPasswordSetting = () => {
  const form = usePasswordSettingForm();

  const { email, clearSignupEnterpriseStore } = useSignUpEnterpriseStore();

  const request = useInternalPasswordSettingRequest({
    onSuccess: () => {
      toast.success(`Cadastro realizado com sucesso!`);
      clearSignupEnterpriseStore();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar finalizar o cadastro');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const payload = {
      email,
      confirmPassword: data.confirmPassword,
      password: data.password,
    };

    request.mutate(payload);
  });

  return {
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseInternalPasswordSetting = ReturnType<
  typeof useInternalPasswordSetting
>;
