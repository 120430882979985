import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { ISignInPayload } from '../services';

export const signInSchema = z.object({
  email: z
    .string()
    .trim()
    .nonempty('E-mail é obrigatório')
    .email('E-mail inválido'),
  password: z.string().trim().nonempty('Senha é obrigatória'),
  origin: z.string().optional(),
});

const defaultValues = {
  email: '',
  password: '',
  branchId: null,
};

export const useSignInForm = () => {
  return useForm<ISignInPayload>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(signInSchema),
  });
};
