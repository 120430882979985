import { Dialog } from '@mui/material';
import styled from 'styled-components';

const RootWrapper = styled(Dialog)`
  & > .MuiDialog-container {
    align-items: flex-start;
  }
`;

export { RootWrapper };
