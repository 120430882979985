import { IEnterprise, IValidateParams } from 'models';

import api from 'config/api';

import {
  ICheckCNPJPayload,
  ICheckDataPayload,
  IEnterpriseOperationResponse,
  IEnterpriseValidateParams,
  ISignUpEnterprisePayload,
} from './types';

export class SignUpEnterpriseService {
  static async signUpEnterprise(payload: ISignUpEnterprisePayload) {
    const { data } = await api.post<IEnterprise>('/enterprise', payload);
    return data;
  }

  static async checkExistCnpj(payload: ICheckCNPJPayload) {
    const { data } = await api.post<boolean>(
      '/enterprise/check-exist-cnpj',
      payload,
    );
    return data;
  }

  static async checkExistData(payload: ICheckDataPayload) {
    const { data } = await api.post<boolean>('/enterprise/check-data', payload);
    return data;
  }

  static async validate({
    value,
    key,
    id,
  }: IValidateParams<IEnterpriseValidateParams>) {
    const { data } = await api.get<boolean>(`/enterprise/validate`, {
      params: {
        value,
        id,
        key,
      },
    });

    return data;
  }

  static async internalRegisterPassword(payload: any) {
    const { data } = await api.post<boolean>('/enterprise/customer', payload);

    return data;
  }

  static async listEntrprisesByType(isFinancing: boolean) {
    const { data } = await api.get<IEnterpriseOperationResponse[]>(
      '/enterprise/by-type',
      {
        params: { isFinancing },
      },
    );

    return data;
  }
}
