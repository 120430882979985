import { useMutation, UseMutationOptions } from 'react-query';

import { IFormQuestion } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateFormQuestionRequest } from '../services';
import { FormService } from '../services/services';

const useUpdateFormQuestionRequest = (
  options?: UseMutationOptions<
    IFormQuestion,
    IApiAxiosError,
    IUpdateFormQuestionRequest
  >,
) => {
  return useMutation('udpateFormQuestion', FormService.updateFormQuestion, options);
};

export { useUpdateFormQuestionRequest };
