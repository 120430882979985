import { Grid, Typography } from '@mui/material';

import { Dialog, ButtonContained, ButtonOutlined } from 'components';

interface ConfirmInactiveDialogProps {
  showDialog: boolean;
  title: string;
  infoCard: string;
  setShowDialog: (showDialog: boolean) => void;
  handleDuplicateForm: () => void;
}

export default function DialogConfirmDuplicate({
  showDialog,
  title,
  infoCard,
  setShowDialog,
  handleDuplicateForm,
}: ConfirmInactiveDialogProps) {
  return (
    <Dialog.Root open={showDialog} onClose={() => setShowDialog(false)}>
      <Dialog.Header title={title} />
      <Typography textAlign={'center'} mt={4}>
        {infoCard}
      </Typography>
      <Grid
        container
        width={650}
        pt={2}
        pl={4}
        pr={4}
        spacing={2}
        textAlign={'center'}
      >
        <Grid item xs={9} alignContent={'center'}>
          <Dialog.Content>
            <Dialog.Footer>
              <ButtonOutlined
                label="Cancelar"
                onClick={() => setShowDialog(false)}
              />
              <ButtonContained
                label="Confirmar"
                onClick={() => handleDuplicateForm()}
              />
            </Dialog.Footer>
          </Dialog.Content>
        </Grid>
      </Grid>
    </Dialog.Root>
  );
}
