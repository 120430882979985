import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { IFormAnswerResponse, IFormAnswerUpdatePayload } from '../services';
import FormAnswerServices from '../services/services';

const useUpdateAnswerRequest = (
  options?: UseMutationOptions<
    IFormAnswerResponse,
    IApiAxiosError,
    IFormAnswerUpdatePayload
  >,
) => {
  return useMutation(
    'updateAnswerRequest',
    FormAnswerServices.updateAnswer,
    options,
  );
};

export { useUpdateAnswerRequest };
