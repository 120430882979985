import { Box, Divider, Typography } from '@mui/material';
import themes from 'themes';

import { ButtonContained, Attachments, NoAttachment } from 'components';
import { Dropzone } from 'components/Upload/Dialog';

import { useFormAnswer, useHandleAnswers } from '../../hooks';
import useFormAnswerStore from '../../store/store';
import DialogConfirmActions from '../DialogConfirmActions';
import { QuestionAttachments } from './ QuestionAttachment';
import { FormQuestions } from './FormQuestions';

export const FormContainer = () => {
  const { currentQuestion } = useFormAnswerStore();
  const {
    openDropzone,
    questionAnswerAttachment,
    currentQuestionAttachment,
    openModalCancel,
    openModalFinishLater,
    allowEdit,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setOpenDropzone,
    handleCancelForm,
    handleFinishLater,
  } = useFormAnswer();

  const { handleSaveAttachments, handleRemoveAttachment } = useHandleAnswers();

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      maxHeight={900}
      overflow={'auto'}
      mt={3}
    >
      <DialogConfirmActions
        title={'Cancelar'}
        informationCard={
          'Tem certeza que deseja cancelar? Todo progresso será perdido.'
        }
        showDialog={openModalCancel}
        setShowDialog={setOpenModalCancel}
        handleAction={handleCancelForm}
      />

      <DialogConfirmActions
        title={'Finalizar em outro momento'}
        informationCard={'Tem certeza que deseja finalizar em outro momento?'}
        showDialog={openModalFinishLater}
        setShowDialog={setOpenModalFinishLater}
        handleAction={handleFinishLater}
      />

      <Box minWidth={1050} style={{ width: '100%' }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          sx={{
            gap: 2,
          }}
        >
          <Typography
            fontFamily={themes.fonts.body}
            color={themes.colors['gray-700']}
          >
            {currentQuestion?.question?.description}
          </Typography>
          <Typography fontFamily={themes.fonts.body}>
            {currentQuestion?.question?.title}
          </Typography>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          alignContent={'center'}
          width={'100%'}
          marginTop={2}
        >
          {currentQuestionAttachment?.map(attachment => (
            <QuestionAttachments key={attachment.id} file={attachment} />
          ))}
        </Box>
        <Divider
          variant="middle"
          sx={{
            marginY: 4,
          }}
        />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <FormQuestions formQuestion={currentQuestion} allowEdit={allowEdit} />
        </Box>
        {!!currentQuestion?.question?.acceptAttachment && (
          <Box mt={5}>
            <Dropzone
              open={openDropzone}
              onClose={() => setOpenDropzone(false)}
              onSave={handleSaveAttachments}
            />
            <ButtonContained
              disabled={!allowEdit}
              color={'gray-700'}
              label="Anexar Arquivos"
              onClick={() => setOpenDropzone(true)}
            />
            {questionAnswerAttachment.length === 0 ? (
              <NoAttachment customColors={'white'} colorText={'gray-500'} />
            ) : (
              questionAnswerAttachment?.map(attachment => (
                <Attachments
                  key={attachment.id}
                  file={attachment.file}
                  disabled={!allowEdit}
                  removeFile={() => handleRemoveAttachment(attachment.file.id)}
                />
              ))
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
