import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import themes from 'themes';

import { Box, PasswordTextField } from 'components';

import { useForgotPassword } from '../hooks';
import PasswordInstructionsList from './PasswordInstructionsList';

function NewPasswordForm() {
  const {
    form: {
      control,
      formState: { errors },
      handleSubmitForm,
    },
    request: { isLoading },
  } = useForgotPassword();

  const history = useHistory();

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '1em',
        minWidth: '560px',
      }}
    >
      <PasswordTextField
        name="password"
        label="Senha"
        control={control}
        error={errors.password}
        sx={{
          '& .MuiOutlinedInput-root': {
            zIndex: 0,
            '& input': {
              zIndex: 1,
            },
            '& fieldset': {
              backgroundColor: '#e0e0e0',
              border: 'none',
              borderRadius: '8px',
            },
          },
        }}
      />
      <PasswordTextField
        name="confirmPassword"
        label="Confirme sua senha"
        control={control}
        error={errors.confirmPassword}
        sx={{
          '& .MuiOutlinedInput-root': {
            zIndex: 0,
            '& input': {
              zIndex: 1,
            },
            '& fieldset': {
              backgroundColor: '#e0e0e0',
              border: 'none',
              borderRadius: '8px',
            },
          },
        }}
      />
      <PasswordInstructionsList />
      <Grid container columnSpacing={3} marginTop={4}>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            onClick={() => history.goBack()}
            loading={isLoading}
            fullWidth
            sx={{
              backgroundColor: 'white',
              color: 'black',
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,

              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            Cancelar
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isLoading}
            fullWidth
            sx={{
              backgroundColor: themes.colors.text.primary,
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,
            }}
          >
            Confirmar
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NewPasswordForm;
