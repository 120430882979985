import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IPagination } from 'models';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';

import { IOperationResponse, OperationQueryKey } from '../services';
import { OperationService } from '../services/services';
import { useOperationStore } from '../store/store';
import { useOperationFilterForm } from './useOperationFilterForm';
import { useSelectedOperations } from './useSelectedOperations';
import { useUserRole } from './useUserRole';

export const useOperation = () => {
  const {
    page,
    perPage,
    search,
    limit,
    filterBy,
    currentFilter,
    setOperationsQtd,
    setPage,
    setLimit,
    clearOperationStore,
  } = useOperationStore();

  const {
    numberOfSelectedOperations,
    handleChangeAll,
    isAllSelected,
    selectedOperations,
  } = useSelectedOperations();

  const { showCreditor, showEnterprise, isConsultant } = useUserRole();
  const { form, typeScreenForm } = useTabAct();

  const { filterParams } = useOperationFilterForm();

  const { data: response, isLoading, refetch } = useQuery(
    [OperationQueryKey.INFINITE_OPERATIONS, page, search, filterBy, currentFilter],
    () =>
      OperationService.getPaginatedOperations({
        page,
        perPage,
        orderBy: 'updatedAt',
        sort: 'DESC',
        search,
        ...filterParams,
      }) as Promise<IPagination<IOperationResponse>>,
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  useEffect(() => {
    return () => clearOperationStore();
  }, []);

  useEffect(() => {
    setOperationsQtd(response?.data?.length ?? 0);

    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    noData,
    setPage,
    page,
    limit,
    numberOfSelectedOperations,
    handleChangeAll,
    isAllSelected,
    selectedOperations,

    showCreditor,
    showEnterprise,
    isConsultant,
    form,
    typeScreenForm,

    isLoading,
    refetch,
    showPagination,
  };
};
