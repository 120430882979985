import { TableRowProps } from '@mui/material';

import { TableRowWrapper } from './styles';

export const TableRow = ({ children, hover = true, ...rest }: TableRowProps) => {
  return (
    <TableRowWrapper hover={hover} {...rest}>
      {children}
    </TableRowWrapper>
  );
};
