import { Delete } from '@mui/icons-material';
import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  DialogConfirmInactives,
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

//import { useResetPagination } from 'modules/user/hooks';
import { useFindQuestions, useQuestionItem } from '../hooks';
import { ItemQuestion } from './ItemQuestion';

export const ListQuestion = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useFindQuestions();
  // useResetPagination();

  const {
    openConfirmInactivesDialog,
    setOpenConfirmInactivesDialog,
    handleUpdateQuestions,
  } = useQuestionItem();

  return (
    <>
      <DialogConfirmInactives
        title={
          'Você tem certeza que deseja desativar a(s) pergunta(s) selecionada(s)?'
        }
        showDialog={openConfirmInactivesDialog}
        setShowDialog={setOpenConfirmInactivesDialog}
        handleUpdate={handleUpdateQuestions}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Pergunta
                </Typography>
              </TableCell>
              <TableCell align="center" width="180px">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Tipo de resposta
                </Typography>
              </TableCell>
              <TableCell align="center" width="60px">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Ativo/Inativo
                </Typography>
              </TableCell>
              <TableCell align="center" width="20px" padding="checkbox">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Editar
                </Typography>
              </TableCell>
              <TableCell
                padding="checkbox"
                width="20px"
                align="center"
                onClick={() => setOpenConfirmInactivesDialog(true)}
              >
                <Delete color="action" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            columns={9}
            isLoading={isLoading}
            empty={noData}
            emptyMessage="Nenhuma pergunta encontrada"
          >
            {data?.map(question => (
              <ItemQuestion key={question.id} {...question} />
            ))}
          </TableBody>
        </Table>

        {showPagination && (
          <Pagination step={page} onSetStep={setPage} limit={limit} />
        )}
      </TableContainer>
    </>
  );
};
