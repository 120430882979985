import { useActCustomerDocumentsRequest } from './useActCustomerDocumentsRequest';

export const useActCustomerDocuments = (enterpriseId: string) => {
  const { data, isLoading, refetch } = useActCustomerDocumentsRequest(enterpriseId);

  const noData = !data || data.length === 0;

  return {
    data,
    isLoading,
    refetch,
    noData,
  };
};
