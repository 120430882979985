import { Box, IconButton } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Icon, Typography } from 'components';
import { TypeProcessAct, UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';
import { useTabs } from 'modules/boards/tabs/hooks';

import { usePendingIssues } from '../hooks';
import { PendingIssue } from '../services';

interface PendingIssueItemProps {
  pendingIssue: PendingIssue;
}

export const PendingIssueItem = ({ pendingIssue }: PendingIssueItemProps) => {
  const { user } = useAuth();
  const { handleOpenNegotiationDialog } = useTabs();
  const { handleMoreInformation } = useTabAct();
  const { handleCloseDialog } = usePendingIssues({});
  const history = useHistory();

  const isCompany = user?.userType === UserTypes.ENTERPRISE;
  const isCreditor = user?.userType === UserTypes.CREDITOR;
  const isConsultant = user?.userType === UserTypes.CONSULTANT;
  const deadline = pendingIssue?.deadline;
  const isOperation =
    pendingIssue.typeProcessAct === TypeProcessAct.OPERATION &&
    pendingIssue.operation;

  const handleRedirect = () => {
    handleCloseDialog({});

    if (isOperation) {
      history.push('/operations');

      handleOpenNegotiationDialog(
        TypeProcessAct.OPERATION,
        undefined,
        pendingIssue?.operation,
      );
    }

    handleMoreInformation(pendingIssue.id as string, 'pending', pendingIssue);
  };

  return (
    <Box
      width={420}
      border={2}
      borderColor={'#E6E6E5'}
      padding={2}
      borderRadius={4}
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontWeight={'bold'} fontSize={20} color={'dark'}>
          {pendingIssue?.act?.name}
        </Typography>

        <IconButton aria-label="expand row" size="small" onClick={handleRedirect}>
          <Icon color="gray-500">open_in_new_icon</Icon>
        </IconButton>
      </Box>

      {deadline && <Typography color={'dark'}>Prazo: {deadline}</Typography>}

      {isOperation && (isCreditor || isConsultant) && (
        <Typography color={'dark'}>
          <strong>Empresa:</strong>{' '}
          {pendingIssue.operation?.enterprise?.fantasyName}
        </Typography>
      )}

      {isOperation && (isCompany || isConsultant) && (
        <Typography color={'dark'}>
          <strong>Instituição Financeira:</strong>{' '}
          {pendingIssue.operation?.creditor?.fantasyName}
        </Typography>
      )}
    </Box>
  );
};
