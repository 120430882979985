import { Link } from '@mui/material';
import themes from 'themes';

import { Icon, Typography } from 'components';

interface IDownloadLinkProps {
  url: string;
}

const DownloadLink = ({ url }: IDownloadLinkProps) => {
  return (
    <Typography fontWeight={700}>
      <Link
        href={url}
        target="_blank"
        rel="noopener"
        color={themes.colors.text.primary}
        underline="hover"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1em',
        }}
      >
        Baixar
        <Icon color="gray-300">file_download</Icon>
      </Link>
    </Typography>
  );
};

export { DownloadLink };
