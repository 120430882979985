import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { EmailService, IEmailActPayload } from 'modules/email';

const useSendActEmailRequest = (
  options?: UseMutationOptions<boolean, IApiAxiosError, IEmailActPayload>,
) => {
  return useMutation('sendActEmail', EmailService.actEmail, options);
};

export { useSendActEmailRequest };
