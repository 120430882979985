import { useInfiniteQuery } from 'react-query';

import { NotificationService, NotificationsQueryKey } from '../services';

const useListNotificationsRequest = (enabled?: boolean) => {
  const { data, ...query } = useInfiniteQuery(
    [NotificationsQueryKey.LIST_BY_CONSULTANT],
    ({ pageParam }) =>
      NotificationService.getPaginatedNotifications({
        perPage: 10,
        page: pageParam || 0,
        orderBy: 'createdAt',
        sort: 'DESC',
      }),
    {
      getNextPageParam: ({ totalPages, currentPage, nextPage }) =>
        currentPage !== totalPages ? nextPage : undefined,
      enabled,
      refetchOnWindowFocus: false,
    },
  );

  return {
    notifications: data?.pages.map(page => page.data).flat() ?? [],
    ...query,
  };
};

export { useListNotificationsRequest };
