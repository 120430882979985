import { List, ListItem } from '@mui/material';

function PasswordInstructionsList() {
  return (
    <List
      dense
      sx={{
        alignSelf: 'flex-start',
        color: 'gray',
        listStyleType: 'disc',
        paddingLeft: 3,
        '& .MuiListItem-root': {
          display: 'list-item',
        },
      }}
    >
      <ListItem disablePadding>Use pelo menos 8 caracteres;</ListItem>
      <ListItem disablePadding>Use um número no mínimo;</ListItem>
      <ListItem disablePadding>
        Use um caracter especial (!, @, #, $, %, etc.);
      </ListItem>
      <ListItem disablePadding>Use uma letra maiúscula no mínimo.</ListItem>
    </List>
  );
}

export default PasswordInstructionsList;
