import { Box, Checkbox, TableCell } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { TypeProcessAct } from 'models';

import { useTabs } from 'modules/boards/tabs/hooks';

import { useSelectedOperations } from '../hooks';
import { IOperationResponse, inactiveOperations } from '../services';

interface OperationItemProps {
  operation: IOperationResponse;
  showCompany: boolean;
  showCreditor: boolean;
}

export const OperationItem = ({
  showCompany,
  showCreditor,
  operation,
}: OperationItemProps) => {
  const {
    isOperationSelected,
    handleChangeSelectedOperations,
  } = useSelectedOperations();
  const { handleOpenNegotiationDialog } = useTabs();

  const { creditor, enterprise, responsible, type, status } = operation;
  const emptyString = '---';
  const inactive = inactiveOperations.includes(status);
  const enterpriseName =
    enterprise?.bigData?.fantasyName || enterprise?.bigData?.socialReason;
  const creditorName =
    creditor?.bigData?.fantasyName || creditor?.bigData?.socialReason;
  const responsibleName = () => {
    if (responsible?.name && !responsible?.surname) return responsible.name;
    if (responsible?.name && responsible?.surname)
      return `${responsible.name} ${responsible.surname}`;

    return emptyString;
  };

  return (
    <TableRow
      key={operation.id}
      onClick={() =>
        handleOpenNegotiationDialog(TypeProcessAct.OPERATION, undefined, operation)
      }
    >
      <TableCell width={20} align="center" padding="checkbox">
        <Box
          onClick={e => {
            e.stopPropagation();
            handleChangeSelectedOperations(operation);
          }}
        >
          <Checkbox
            name={`checkbox-${operation.id}`}
            checked={isOperationSelected(operation)}
          />
        </Box>
      </TableCell>
      {showCompany && (
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {enterpriseName ?? emptyString}
          </Typography>
        </TableCell>
      )}
      {showCreditor && (
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {creditorName ?? emptyString}
          </Typography>
        </TableCell>
      )}
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {responsibleName()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {type ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {new Date(operation?.createdAt)?.toLocaleDateString('pt-BR') ??
            emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {operation?.value
            ? (operation?.value / 100)?.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                style: 'currency',
                currency: 'BRL',
              })
            : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {operation.acts[0]?.act.name ?? emptyString}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {operation?.updatedAt
            ? new Date(operation.updatedAt).toLocaleDateString('pt-BR')
            : emptyString}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {status}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {inactive ? (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
              backgroundColor={'#f2665e'}
            >
              Inativo
            </TypographyActive>
          ) : (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
            >
              Ativo
            </TypographyActive>
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
