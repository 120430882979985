import { useState } from 'react';
import { MouseEvent } from 'react';

const useUserMenu = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);

  const open = Boolean(anchor);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  return {
    anchor,
    open,
    handleOpen,
    handleClose,
  };
};

export { useUserMenu };
