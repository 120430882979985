import { ChipProps as MuiChipProps } from '@mui/material';
import { Colors } from 'themes';

import { ChipContainedWrapper } from './styles';

type ChipProps = Omit<MuiChipProps, 'color' | 'variant'> & {
  backgroundColor?: Colors;
  color?: Colors;
};

export function ChipContained({
  backgroundColor = 'blue',
  color = 'white',
  ...props
}: ChipProps) {
  return (
    <ChipContainedWrapper
      customBackgroundColor={backgroundColor}
      customColor={color}
      variant="filled"
      {...props}
    />
  );
}
