import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { CustomerService, ICustomerRegisterPayload } from '../services';
import { useCustomerManagementStore } from '../store/store';

const useInternalRegisterRequest = (
  options?: UseMutationOptions<unknown, IApiAxiosError, ICustomerRegisterPayload>,
) => {
  const { formDialogType } = useCustomerManagementStore();
  return useMutation(
    'customer-register',
    formDialogType === 'edit'
      ? CustomerService.updateCustomer
      : CustomerService.registerCustomer,
    options,
  );
};

export { useInternalRegisterRequest };
