import { Download, Cancel } from '@mui/icons-material';

import { Box, Icon, Typography } from 'components';
import { useDownloadFile } from 'hooks';
import { IFileStorage } from 'models';

interface AttachmentsProps {
  file: IFileStorage;
  disabled?: boolean;
  removeFile?: (id: string) => void;
}

export const Attachments = ({
  file,
  disabled = false,
  removeFile,
}: AttachmentsProps) => {
  const { downloadFile } = useDownloadFile(file);
  return (
    <Box display="flex" p={2} mt={2}>
      <Icon color={'gray-300'}> insert_drive_file</Icon>
      <Typography style={{ paddingLeft: 2 }}>{file.fileName}</Typography>
      <Typography
        color={'blue'}
        style={{
          paddingLeft: 15,
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        onClick={() => downloadFile()}
      >
        <Download />
      </Typography>

      {removeFile && !disabled && (
        <Typography
          style={{
            paddingLeft: 15,
            cursor: 'pointer',
            color: '#cc474e',
            fontWeight: 'bold',
          }}
          onClick={() => removeFile(file.id)}
        >
          <Cancel />
        </Typography>
      )}
    </Box>
  );
};
