import { LoadingButton } from '@mui/lab';
import {
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
  Link,
  InputBaseComponentProps,
  Paper,
} from '@mui/material';
import { ElementType } from 'react';
import themes from 'themes';

import { Box, Select, TextField, TextMask } from 'components';
import { useMaskInputs } from 'hooks';

import { UseSignUpEnterprise, useSignUpEnterprise } from '../hooks';

export interface SignUpFormProps {
  form: UseSignUpEnterprise['form'];
  isSigningUp?: boolean;
}

export const FormRegister = ({ form, isSigningUp }: SignUpFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  const {
    acceptTerms,
    setAcceptTerms,
    handleCancelRegister,
    USER_POSITIONS,
  } = useSignUpEnterprise({});

  const { TELEPHONE_MASK, CNPJ_MASK, DATE_MASK, CPF_MASK } = useMaskInputs();

  return (
    <Paper
      elevation={16}
      style={{
        borderRadius: '11px',
        maxWidth: '532px',
        padding: '1.5em',
        backgroundColor: '#f0f0f0',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box as="form">
        <Box display="flex" flexDirection="column" paddingX={20}>
          <Typography
            fontFamily={themes.fonts.body}
            variant="h4"
            fontSize={23}
            textAlign="center"
            fontWeight={700}
            color={themes.colors.blue}
          >
            Cadastro
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            fontFamily={themes.fonts.body}
          >
            Digite seus dados
          </Typography>
        </Box>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <TextField
              name="cnpj"
              type="text"
              control={control}
              error={errors.cnpj}
              label="CNPJ"
              inputProps={{
                mask: CNPJ_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="fantasyName"
              label="Nome Fantasia"
              control={control}
              type="text"
              error={errors.fantasyName}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Nome"
              control={control}
              type="text"
              error={errors.name}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="surname"
              label="Sobrenome"
              control={control}
              type="text"
              error={errors.surname}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cpf"
              type="text"
              control={control}
              error={errors.cpf}
              label="CPF"
              inputProps={{
                mask: CPF_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="birthDate"
              type="text"
              control={control}
              error={errors.birthDate}
              label="Data de nascimento"
              inputProps={{
                mask: DATE_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="email"
              label="E-mail"
              control={control}
              type="text"
              error={errors.email}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="confirmEmail"
              label="Confirmação de e-mail"
              control={control}
              type="text"
              error={errors.confirmEmail}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="telephone"
              type="text"
              control={control}
              error={errors.telephone}
              label="Telefone"
              inputProps={{
                mask: TELEPHONE_MASK.ONE_MORE_DIGIT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="site"
              label="Site"
              control={control}
              type="text"
              error={errors.site}
              sx={{
                '& .MuiOutlinedInput-root': {
                  zIndex: 0,
                  '& input': {
                    zIndex: 1,
                  },
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              name="position"
              control={control}
              label="Cargo"
              sx={{
                '& .MuiSelect-select': {
                  backgroundColor: '#e0e0e0',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                  borderRadius: '8px',
                },
              }}
            >
              {USER_POSITIONS.map(role => (
                <MenuItem key={role.value} value={role.value}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                />
              }
              sx={{
                mr: 0,
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                    borderRadius: '8px',
                  },
                },
              }}
              label={
                <Typography fontFamily={themes.fonts.body}>
                  Aceitar{' '}
                  <Link style={{ color: '#0477cf' }} href="/" target="_blank">
                    termos de uso e política de privacidade.
                  </Link>
                </Typography>
              }
            />
          </Grid>
        </Grid>

        <Box
          padding="1em"
          display={'flex'}
          flexDirection={'row'}
          style={{ gap: '2em' }}
        >
          <LoadingButton
            variant="contained"
            onClick={() => handleCancelRegister()}
            loading={isSigningUp}
            fullWidth
            sx={{
              backgroundColor: 'white',
              color: 'black',
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,

              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            Cancelar
          </LoadingButton>
          <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isSigningUp}
            disabled={!isValid || !acceptTerms || isSigningUp}
            fullWidth
            sx={{
              backgroundColor: themes.colors.text.primary,
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,
            }}
          >
            Enviar
          </LoadingButton>
        </Box>
      </Box>
    </Paper>
  );
};
