import { Add } from '@mui/icons-material';

import { Icon, ButtonOutlined } from 'components';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useDynamicForm, useFormSearch } from '../hooks';
import { useFormStore } from '../store/store';

export function HeaderFormList() {
  const { setSearch } = useFormStore();
  const { handleOpenPageCreateDynamicForm } = useDynamicForm();
  const { handleChangeSearch } = useFormSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            icon={<Icon fontSize="h4">format_list_bulleted</Icon>}
            title="Formulários"
          />

          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={value => handleChangeSearch(value)}
          />
          <PrivateLayout.Content.Header.Actions>
            <ButtonOutlined
              color="white"
              label={'Cadastrar Formulário'}
              icon={<Add />}
              loading={false}
              onClick={() => handleOpenPageCreateDynamicForm()}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
}
