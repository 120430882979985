import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Icon, Typography } from 'components';
import { UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

export const Unauthorized = () => {
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (user?.userType === UserTypes.CONSULTANT) history.push('/customers');
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      marginTop="-40px"
      style={{
        gap: '0.5em',
      }}
    >
      <Icon color="gray-700" fontSize="h1">
        block
      </Icon>

      <Typography variant="h5" color="gray-700">
        Você não tem acesso a este recurso!
      </Typography>
    </Box>
  );
};
