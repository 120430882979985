import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { NotificationService, NotificationsQueryKey } from '../services';

const useReadAllNotificationsRequest = (
  options?: UseMutationOptions<unknown, IApiAxiosError>,
) => {
  return useMutation(
    NotificationsQueryKey.READ_ALL_NOTIFICATIONS,
    NotificationService.readAllNotifications,
    options,
  );
};

export { useReadAllNotificationsRequest };
