import { Box, Checkbox, TableCell } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { IEnterprise, TypeProcessAct, UserTypes, inactiveCustomers } from 'models';

import { useTabActsStore } from 'modules/boards/tabs';
import { useTabs } from 'modules/boards/tabs/hooks';

import { renderCnpjMask } from 'utils/renderCNPJMask';

import { useSelectedCustomers } from '../hooks/useSelectedCustomers';

export const CustomerItem = (customer: IEnterprise) => {
  if (!customer) return null;

  const { handleOpenNegotiationDialog } = useTabs();
  const {
    isCustomerSelected,
    handleChangeSelectedCustomers,
  } = useSelectedCustomers();

  const { setProcessActs } = useTabActsStore();

  const inactive = inactiveCustomers.includes(customer?.status);
  const representative = customer?.users[0];
  const responsible = customer?.responsible;
  const emptyString = '---';
  const primaryCompanyName =
    customer?.primaryEnterprise?.bigData?.fantasyName ||
    customer?.primaryEnterprise?.bigData?.socialReason;
  const customerName =
    customer?.bigData?.fantasyName || customer?.bigData?.socialReason;

  return (
    <TableRow
      key={customer.id}
      onClick={() => {
        setProcessActs([]);
        handleOpenNegotiationDialog(TypeProcessAct.CLIENT, customer);
      }}
    >
      <TableCell align="center" padding="checkbox">
        <Box
          onClick={e => {
            e.stopPropagation();
            handleChangeSelectedCustomers(customer);
          }}
        >
          <Checkbox checked={isCustomerSelected(customer)} />
        </Box>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {customer?.cnpj ? renderCnpjMask(customer?.cnpj) : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {customerName ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {customer?.bigData?.socialReason}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {primaryCompanyName ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {representative
            ? `${representative?.name ?? ''} ${representative?.surname ?? ''}`
            : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {customer?.isFinancing ? UserTypes.CREDITOR : UserTypes.ENTERPRISE}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
          lineHeight={1.3}
        >
          {responsible
            ? `${responsible?.name ?? ''} ${responsible?.surname ?? ''}`
            : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {customer?.status ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        {!customer.product ? (
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {emptyString}
          </Typography>
        ) : (
          customer.product.map(product => (
            <Typography
              key={product}
              textAlign="center"
              style={{ marginTop: 7, fontSize: `${themes.fontSizes.s}px` }}
              lineHeight={1.3}
            >
              {product};
            </Typography>
          ))
        )}
      </TableCell>
      <TableCell width={'60px'}>
        {inactive ? (
          <TypographyActive
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
            backgroundColor={'#f2665e'}
          >
            Inativo
          </TypographyActive>
        ) : (
          <TypographyActive
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            Ativo
          </TypographyActive>
        )}
      </TableCell>
    </TableRow>
  );
};
