import { IPaginationParams, IProcessAct } from 'models';

import { ISort } from 'utils/ordination';

export enum PendingIssuesQueryKey {
  LIST_PENDING_ISSUES = 'LIST_PENDING_ISSUES',
}

export type PendingIssue = IProcessAct;

export interface ListPendingIssuesPayload extends IPaginationParams {
  orderBy?: string;
  sort?: ISort;
}

export type ListPendingIssuesResponse = IProcessAct[];

export interface IListPendingIssuesPayload {
  operationId?: string;
  enterpriseId?: string;
}
