import { makeStyles } from '@mui/styles';

const useVerticalTimeLineStyles = makeStyles(() => ({
  icon: {
    textAlign: 'center',
    justifyContent: 'center',
    flex: 1,
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
}));

export default useVerticalTimeLineStyles;
