import styled from 'styled-components';

import { Box } from 'components';

const BackgroundWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
  background-color: ${({ theme }) => theme.colors.blue};
  z-index: -1;
`;

export { BackgroundWrapper };
