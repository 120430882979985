import { ReactNode } from 'react';

import { RootWrapper } from './styles';

interface Props {
  children: ReactNode;
}

export function Root({ children }: Props) {
  return <RootWrapper>{children}</RootWrapper>;
}
