import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IApiError } from 'config/api/types';

import { useDefinePasswordStore } from 'modules/definePassword';

import { useSignUpEnterpriseStore } from '../store/store';
import { usePasswordSettingForm } from './usePasswordSettingForm';
import { useSignUpEnterpriseRequest } from './useSignUpEnterpriseRequest';
import { useValidateCodeRequest } from './useValidateCodeRequest';

export const usePasswordSetting = () => {
  const { code } = useDefinePasswordStore();
  const history = useHistory();

  const form = usePasswordSettingForm();

  const {
    enterprisePayload,
    clearSignupEnterpriseStore,
  } = useSignUpEnterpriseStore();

  const requestCode = useValidateCodeRequest({
    onError: () => {
      toast.error('Erro ao validar código.');
    },
  });

  const request = useSignUpEnterpriseRequest({
    onSuccess: async data => {
      await requestCode.mutate({ code });
      toast.success(
        `Cadastro da empresa ${data.fantasyName} realizado com sucesso!`,
      );
      clearSignupEnterpriseStore();

      toast.success('Cadastro realizado com sucesso!');

      history.replace('/');
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === 'Invalid credentials') {
        toast.error('Email já utilizado!');
      } else {
        toast.error('Houve um erro ao tentar finalizar o cadastro');
      }
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const payload = {
      ...enterprisePayload,
      site: !!enterprisePayload?.site ? enterprisePayload?.site : undefined,
      password: data.password,
    };

    await request.mutate(payload);
  });

  return {
    requestPassword: request,
    formPassword: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UsePasswordSetting = ReturnType<typeof usePasswordSetting>;
