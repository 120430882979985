import { OperationActions, useSelectedOperations } from 'modules';
import { ReactNode, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { RoutesProps } from 'routes/types';

import { useAuth } from 'config/auth/hooks';

import LogoutDialog from 'modules/SignOut/components/LogoutDialog';
import { CustomerActions } from 'modules/boards/customerManagement/components/CustomerActions';
import { useSelectedCustomers } from 'modules/boards/customerManagement/hooks/useSelectedCustomers';
import { ConsultantNotificationsDialog } from 'modules/notifications/components/ConsultantNotificationsDialog';
import { CustomerPendingIssueDialog } from 'modules/pendingIssues/components/CustomerPendingIssueDialog';
import { usePendingIssues } from 'modules/pendingIssues/hooks';

import { PrivateLayout } from '.';
import { PrivateLayoutContext } from './Context';
import { useUserMenu } from './components/Header/hooks';
import { useAuthorizedRoutes, useValidateRouteAccess } from './hooks';

const PrivateLayoutRoute = ({
  component: Component,
  path,
  ...rest
}: RouteProps) => {
  const { sidebarBroken } = useContext(PrivateLayoutContext);
  const { anchor, open, handleOpen, handleClose } = useUserMenu();
  const { authorizedRoutes } = useAuthorizedRoutes();
  const { hasPermission } = useValidateRouteAccess();
  const { numberOfSelectedOperations } = useSelectedOperations();
  const { numberOfSelectedCustomers } = useSelectedCustomers();

  const {
    openPendingIssuesDialog,
    pendingIssues,
    isLoading: loadingPendingIssues,
    handleCloseDialog,
    form,
  } = usePendingIssues({});

  const renderSidebarMenu = (route: RoutesProps): ReactNode => {
    if (!!route.subs) {
      return (
        <PrivateLayout.Sidebar.SubMenu key={route.name} route={route}>
          {route.subs.map(sub => renderSidebarMenu(sub))}
        </PrivateLayout.Sidebar.SubMenu>
      );
    }

    return <PrivateLayout.Sidebar.MenuItem key={route.name} route={route} />;
  };

  const { user, isLoading, isAuthenticated } = useAuth();

  if (isLoading && !user) return null;

  if (!isAuthenticated()) {
    return (
      <Route
        path={path}
        render={({ location }) => (
          <Redirect to={{ pathname: '/', state: { from: location } }} />
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <PrivateLayout.Root>
          <PrivateLayout.Sidebar.Root>
            <PrivateLayout.Sidebar.Logo />
            <PrivateLayout.Sidebar.UserProfile />
            <PrivateLayout.Sidebar.Menu>
              {authorizedRoutes.map(route => renderSidebarMenu(route))}
            </PrivateLayout.Sidebar.Menu>
            <PrivateLayout.Sidebar.MenuLogout />
          </PrivateLayout.Sidebar.Root>

          <PrivateLayout.Container>
            <ConsultantNotificationsDialog />
            <CustomerPendingIssueDialog
              pendingIssues={pendingIssues ?? []}
              isLoading={loadingPendingIssues}
              handleCloseDialog={() => handleCloseDialog({ isTab: false })}
              openDialog={openPendingIssuesDialog}
              form={form}
            />
            <LogoutDialog />
            <PrivateLayout.Header.Root>
              {sidebarBroken && <PrivateLayout.Header.MenuButton />}
              {numberOfSelectedOperations > 0 && path === '/operations' && (
                <OperationActions />
              )}
              {numberOfSelectedCustomers > 0 && path === '/customers' && (
                <CustomerActions />
              )}
              <PrivateLayout.Header.UserMenu.Root>
                <PrivateLayout.Header.UserMenu.Button onClick={handleOpen} />
                <PrivateLayout.Header.UserMenu.Menu.Root
                  anchor={anchor}
                  open={open}
                  onClose={handleClose}
                >
                  <PrivateLayout.Header.UserMenu.Menu.Header />
                  <PrivateLayout.Header.UserMenu.Menu.Content />
                  <PrivateLayout.Header.UserMenu.Menu.Footer />
                </PrivateLayout.Header.UserMenu.Menu.Root>
              </PrivateLayout.Header.UserMenu.Root>
            </PrivateLayout.Header.Root>

            <PrivateLayout.Content.Root>
              {Component ? (
                hasPermission(String(path)) ? (
                  <Component {...props} />
                ) : (
                  <PrivateLayout.Unauthorized />
                )
              ) : null}
            </PrivateLayout.Content.Root>
          </PrivateLayout.Container>
        </PrivateLayout.Root>
      )}
    />
  );
};

export default PrivateLayoutRoute;
