import { TextField } from '@mui/material';
import { useState } from 'react';

import { Box, ButtonContained, ButtonOutlined, Dialog } from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { ClientDestineStatus } from 'models';

import { useCustomerActions } from '../hooks';

export const ChangeCustomerStatsDialog = () => {
  const [status, setStatus] = useState<ClientDestineStatus>();

  const {
    openChangeStatsDialog,
    handleCloseChangeStatsDialog,
    handleApplyStatus,
    updateCustomerStatusRequest: { isLoading },
  } = useCustomerActions();

  return (
    <Dialog.Root
      open={openChangeStatsDialog}
      onClose={handleCloseChangeStatsDialog}
    >
      <Dialog.Header title={'Mudar Status'} />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyItems="center"
          style={{ gap: '4em' }}
          width={500}
        >
          <AutocompleteWrapper
            fullWidth
            multiple={false}
            options={Object.values(ClientDestineStatus)}
            renderInput={params => (
              <TextField {...params} label="Selecione o Status" />
            )}
            value={status}
            onChange={(_, value) => setStatus(value as ClientDestineStatus)}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyItems="center"
            style={{ gap: '2em' }}
          >
            <ButtonOutlined
              label="CANCELAR"
              onClick={handleCloseChangeStatsDialog}
            />
            <ButtonContained
              label="SALVAR"
              disabled={!status || isLoading}
              onClick={() => status && handleApplyStatus(status)}
              loading={isLoading}
            />
          </Box>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};
