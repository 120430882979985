import { Balance, indexesAndIndicatorsHighlight } from 'modules/dashboard/services';

import { DashboardTableContainer } from './DashboardTable';

interface BalanceProps {
  data: Balance | undefined | null;
  isLoading: boolean;
  noData: boolean;
}

const IndexesAndIndicatorsTable = ({ data, isLoading, noData }: BalanceProps) => {
  return (
    <DashboardTableContainer
      isLoading={isLoading}
      noData={noData}
      data={data ?? null}
      title="Balanço K"
      mapping={indexesAndIndicatorsHighlight}
      titleSession="Índices e Indicadores"
    />
  );
};

export { IndexesAndIndicatorsTable };
