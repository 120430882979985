import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { UserPositions, UserTypes } from 'models';

import { IApiError } from 'config/api/types';
import queryClient from 'config/queryClient';

import { useCheckExistCnpjRequest } from 'modules/signUp/Enterprise/hooks/useCheckExistCnpjRequest';

import { enumToArray } from 'utils/enumToArray';

import { CustomerQueryKey, ICustomerRegisterPayload } from '../services';
import { useCustomerManagementStore } from '../store/store';
import { useCustomerDialog } from './useCustomerDialog';
import { useInternalRegisterForm } from './useInternalRegisterForm';
import { useInternalRegisterRequest } from './useInternalRegisterRequest';

export const useInternalRegister = () => {
  const { handleCloseFormDialog, handleOpenFormDialog } = useCustomerDialog();
  const { hasSecondCnpj, setHasSecondCnpj } = useCustomerManagementStore();

  const [customerData, setCustomerData] = useState<ICustomerRegisterPayload>();
  const USER_ROLES = enumToArray(UserPositions);
  const USER_TYPES = enumToArray(UserTypes);
  const COMMON_USER_TYPES = enumToArray(UserTypes).filter(
    userType => userType.label !== UserTypes.CONSULTANT,
  );
  const ENTERPRISE_USER_TYPE = enumToArray(UserTypes).filter(
    userType => userType.label === UserTypes.ENTERPRISE,
  );

  const form = useInternalRegisterForm();

  const openInternalRegisterDialog = async () => {
    handleOpenFormDialog();
  };

  const request = useInternalRegisterRequest({
    onSuccess: () => {
      toast.success(`Cadastro realizado com sucesso!`);
      queryClient.invalidateQueries([CustomerQueryKey.LIST_CUSTOMERS]);
      form.reset(undefined);
      handleCloseFormDialog();
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === 'CNPJ not found') {
        form.setError('cnpj', { message: 'CNPJ não encontrado', type: 'custom' });
        toast.error(error.response?.data.detail);
        return;
      }

      if (error.response?.data.title === 'Invalid credentials') {
        toast.error(error.response?.data.detail);
        return;
      }

      if (error.response?.data.title === 'Invalid register data') {
        toast.error('Não é póssível criar uma instituição financeira como filial');
        return;
      }
      if (error.response?.data.title === 'CNPJ secundary not found') {
        toast.error('CNPJ secundário incorreto');
        return;
      }

      toast.error('Houve um erro ao tentar finalizar o cadastro');
      return;
    },
  });

  const checkExistCnpjRequest = useCheckExistCnpjRequest({
    onSuccess: () => {
      request.mutate(customerData as ICustomerRegisterPayload);

      setCustomerData(undefined);
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === 'Invalid credentials') {
        form.setError('cnpj', { message: 'CNPJ já registrado', type: 'custom' });
        toast.error('Este CNPJ já possui um cadastro!');
      }

      if (error.response?.data.title === 'Invalid register data') {
        toast.error('CNPJ Principal incorreto');
      }
    },
  });

  const validateSecondCnpj = (
    primaryEnterprise: string | null,
    typeUser: UserTypes,
  ) => {
    if (!primaryEnterprise || primaryEnterprise.length === 0) {
      toast.error('Insira o CNPJ da empresa principal!');
      return false;
    }

    if (primaryEnterprise && typeUser === UserTypes.CREDITOR) {
      toast.error('Selecione o tipo de cliente corretamente');
      return false;
    }
    return true;
  };

  const handleSubmitForm = form.handleSubmit(data => {
    if (hasSecondCnpj) {
      const isValid = validateSecondCnpj(data.primaryEnterprise, data.type);

      if (!isValid) return;
    } else {
      data.primaryEnterprise = null;
    }

    setCustomerData(data);

    checkExistCnpjRequest.mutate({
      cnpj: data.cnpj,
      cnpjSecundary: data.primaryEnterprise,
    });
  });

  return {
    USER_ROLES,
    USER_TYPES,
    COMMON_USER_TYPES: hasSecondCnpj ? ENTERPRISE_USER_TYPE : COMMON_USER_TYPES,
    hasSecondCnpj,
    setHasSecondCnpj,
    openInternalRegisterDialog,
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseInternalRegister = ReturnType<typeof useInternalRegister>;
