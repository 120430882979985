import { TextField } from '@mui/material';

import {
  Box,
  Dialog,
  ButtonOutlined,
  ButtonContained,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { OptionsValuesString } from 'utils/helper';

import { useTabs } from '../../hooks';

const ModalChangeResponsible = () => {
  const {
    openChangeResponsibleModal,
    newResponsible,
    consultantsOptions,
    typeChangeResponsible,
    setOpenChangeResponsibleModal,
    setNewResponsible,
    handleChangeResponsible,
  } = useTabs();

  return (
    <Dialog.Root
      open={openChangeResponsibleModal}
      onClose={() => setOpenChangeResponsibleModal(false)}
    >
      <Dialog.Header
        title={`Alterar Responsável -  ${
          typeChangeResponsible === 'act' ? 'Ato' : 'Operação'
        }`}
      />

      <Box width={500} p={6} marginY={4}>
        <AutocompleteWrapper
          options={consultantsOptions}
          noOptionsText="Nenhum reponsável encontrado"
          value={newResponsible}
          renderInput={params => (
            <TextField {...params} label="Selecione o novo responsável" />
          )}
          onChange={(_, value) => setNewResponsible(value as OptionsValuesString)}
        />
      </Box>

      <SectionButtons gap={1.8}>
        <ButtonOutlined
          color="black"
          label="Cancelar"
          onClick={() => setOpenChangeResponsibleModal(false)}
        />
        <ButtonContained
          label="Salvar"
          onClick={() => handleChangeResponsible(typeChangeResponsible)}
        ></ButtonContained>
      </SectionButtons>
    </Dialog.Root>
  );
};

export { ModalChangeResponsible };
