import styled from 'styled-components';

import { Box } from 'components';

const RootWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[6]}px;

  ${({ theme }) => theme.mediaQueries.sm} {
    gap: ${({ theme }) => theme.space[8]}px;
  }
`;

export { RootWrapper };
