import { Popper } from '@mui/material';

const CustomPopper = props => {
  const { popperOptions, ...other } = props;
  return (
    <Popper
      {...other}
      popperOptions={{
        ...popperOptions,
        modifiers: [{ name: 'flip', enabled: false }],
      }}
      placement="bottom-start"
    />
  );
};

export default CustomPopper;
