import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { QuestionType } from 'models';

import { IQuestionStepForm } from '../services';

export const useQuestionStepperForm = () => {
  const userSchema = z.object({
    type: z.string().trim().nonempty('Escolhe o tipo da pergunta'),
    title: z.string().trim().nonempty('Preencha a pergunta'),
    description: z.string().optional(),
    active: z.boolean(),
  });

  const defaultValues = {
    type: {} as QuestionType,
    title: '',
    description: '',
    active: true,
  };

  return useForm<IQuestionStepForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userSchema),
  });
};
