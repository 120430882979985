import {
  SerasaDialogFilter,
  SerasaSearchContainer,
  SerasaTable,
} from '../components';

const kleverSerasaPage = () => {
  return (
    <>
      <SerasaDialogFilter />
      <SerasaSearchContainer />
      <SerasaTable />
    </>
  );
};

export { kleverSerasaPage };
