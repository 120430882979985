import { Grid, InputBaseComponentProps } from '@mui/material';
import { ElementType } from 'react';

import {
  Dialog,
  Box,
  TextField,
  ButtonContained,
  ButtonOutlined,
  Switch,
  TextMask,
  SectionButtons,
} from 'components';
import { useMaskInputs } from 'hooks';

import { useConsultantDialog, UseConsultantDialog } from '../../hooks';
import { useConsultantsStore } from '../../store/store';

export interface DialogFormProps {
  form: UseConsultantDialog['form'];
}

export const DialogForm = ({ form }: DialogFormProps) => {
  const {
    openFormDialog,
    requestCreateConsultant,
    handleCloseFormDialog,
  } = useConsultantDialog();
  const { currentConsultant } = useConsultantsStore();

  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  const { CPF_MASK, DATE_MASK } = useMaskInputs();

  return (
    <Dialog.Root open={openFormDialog} onClose={handleCloseFormDialog}>
      <Box as="form">
        <Dialog.Header
          title={currentConsultant?.id ? 'Editar Consultor' : 'Novo Consultor'}
        />
        <Grid container width={700} p={4} spacing={2}>
          <Switch name="active" label="Ativo" control={control} />
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Nome"
              control={control}
              type="text"
              error={errors.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="surname"
              label="Sobrenome"
              control={control}
              type="text"
              error={errors.surname}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cpf"
              type="text"
              control={control}
              error={errors.cpf}
              label="CPF"
              inputProps={{
                mask: CPF_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="birthDate"
              type="text"
              control={control}
              error={errors.birthDate}
              label="Data de nascimento"
              inputProps={{
                mask: DATE_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email"
              label="E-mail"
              control={control}
              type="text"
              error={errors.email}
            />
          </Grid>
        </Grid>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseFormDialog} />
        <ButtonContained
          label="Confirmar"
          onClick={() => handleSubmitForm()}
          disabled={!isValid}
          loading={requestCreateConsultant.isLoading}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
