import { useQuery } from 'react-query';

import { OptionsValuesString } from 'utils/helper';

import { CustomerQueryKey, CustomerService } from '../services';

export const useListMainCustomers = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [CustomerQueryKey.LIST_MAIN_CUSTOMERS],
    () => CustomerService.getMainCustomers(),
    { enabled: enabled ?? true, keepPreviousData: true },
  );

  const optionsMainCustomers: OptionsValuesString[] =
    data
      ?.filter(main => main.bigData)
      ?.map(({ id, bigData }) => ({
        value: id,
        label: bigData?.fantasyName || bigData?.socialReason,
      })) || [];

  return {
    optionsMainCustomers,
    data,
    isLoading,
    refetch,
  };
};
