import { useQuery } from 'react-query';

import { UserQueryKey } from 'modules/user';

import { OptionsValuesString } from 'utils/helper';

import { CustomerService } from '../services';

export const useListEnterprises = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [UserQueryKey.LIST_ALL_ENTERPRISES],
    () => CustomerService.listByCustomerType({ isFinancing: false }),
    { enabled, keepPreviousData: true },
  );

  const enterpriseOptions: OptionsValuesString[] =
    data
      ?.filter(enterprise => enterprise?.bigData)
      ?.map(({ id, bigData }) => ({
        value: id,
        label: bigData?.fantasyName || bigData?.socialReason,
      })) || [];

  return {
    enterpriseOptions,
    data,
    isLoading,
    refetch,
  };
};
