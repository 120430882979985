import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IEnterprise, IPagination } from 'models';

import { CustomerQueryKey } from '../services';
import { CustomerService } from '../services/services';
import { useCustomerManagementStore } from '../store/store';
import { useCustomerFilterForm } from './useCustomerFilterForm';

export const useCustomer = () => {
  const {
    page,
    perPage,
    limit,
    search,
    filterBy,
    currentFilter,
    setPage,
    setLimit,
    setCustomersAmount,
  } = useCustomerManagementStore();

  const { filterParams } = useCustomerFilterForm();

  const { data: response, isLoading, refetch } = useQuery(
    [CustomerQueryKey.LIST_CUSTOMERS, page, search, filterBy, currentFilter],
    () =>
      CustomerService.getCustomers({
        page,
        perPage,
        search,
        ...filterParams,
      }) as Promise<IPagination<IEnterprise>>,
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  useEffect(() => {
    setCustomersAmount(response?.data?.length || 0);

    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
  };
};
