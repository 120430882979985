import { toast } from 'react-toastify';

import { IValidateCodePayload } from 'modules/email';

import { useSignUpEnterpriseStore } from '../store/store';
import { useCodeForm } from './useCodeForm';
import { useValidateCodeRequest } from './useValidateCodeRequest';

export const useValidateCode = () => {
  const {
    codeValidated,
    renderCodeField,
    setCodeValidated,
    setRenderCodeField,
    setEmail,
  } = useSignUpEnterpriseStore();

  const form = useCodeForm();

  const request = useValidateCodeRequest({
    onSuccess: data => {
      if (data.isValid) {
        setCodeValidated(true);
        setRenderCodeField(false);
        setEmail(data.email);
        toast.success('Email validado com sucesso!');
      }
    },
    onError: () => {
      toast.error('Código inválido');
    },
  });

  const validateCode = (data: IValidateCodePayload) => {
    setRenderCodeField(true);

    request.mutate({
      code: data.code,
    });
  };

  const handleSubmitForm = form.handleSubmit(data => {
    request.mutate({
      code: data.code,
    });
  });

  return {
    form: {
      ...form,
      handleSubmitForm,
    },
    request,
    codeValidated,
    renderCodeField,
    validateCode,
  };
};

export type UseValidateCode = ReturnType<typeof useValidateCode>;
