import { Button } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Colors } from 'themes';

interface ContainedProps {
  customColor: Colors;
  customTextColor: Colors;
  loading: boolean;
}

const ContainedWrapper = styled(Button)<ContainedProps>`
  &.MuiButton-contained {
    position: relative;
    text-transform: none;
    box-shadow: none;
    color: ${props =>
      rgba(props.theme.colors[props.customTextColor], props.loading ? 0 : 1)};
    background-color: ${props => props.theme.colors[props.customColor]};

    :hover {
      box-shadow: none;
      background-color: ${props =>
        rgba(props.theme.colors[props.customColor], 0.8)};
    }

    :disabled {
      box-shadow: none;
      color: ${props =>
        rgba(props.theme.colors['gray-300'], props.loading ? 0 : 1)};
      background-color: ${props => props.theme.colors['gray-100']};
    }
  }
`;

export { ContainedWrapper };
