import { Balance, assetsHighlight } from 'modules/dashboard/services';

import { DashboardTableContainer } from './DashboardTable';

interface BalanceProps {
  data: Balance | undefined | null;
  isLoading: boolean;
  noData: boolean;
}

const CurrentAssetsTable = ({ data, isLoading, noData }: BalanceProps) => {
  return (
    <DashboardTableContainer
      isLoading={isLoading}
      noData={noData}
      data={data ?? null}
      title="Balanço"
      total="ATIVO TOTAL"
      current="ATIVO CIRCULANTE"
      mapping={assetsHighlight}
      titleSession="Balanço - Ativos"
    />
  );
};

export { CurrentAssetsTable };
