import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const AccordionWrapper = styled(Accordion)`
  && {
    background-color: ${({ theme }) => theme.colors['gray-100']};

    &.Mui-expanded {
      margin: 0;
    }
  }
`;
