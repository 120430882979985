import { useCallback, useMemo } from 'react';

import {
  IDatabaseFilter,
  IDatabaseFilterBy,
  IDatabaseFilterByOption,
  pageFilter,
} from 'components/FilterDatabase/filterDatabase.types';
import { ClientDestineStatus, ProductClient } from 'models';

import { useListAllQuestions } from 'modules/dynamicForm/form';
import { useListAllConsultants } from 'modules/user/hooks';
import { useFindActiveOptions, useFindUsersTypesOptions } from 'modules/user/utils';

import { enumToArray } from 'utils/enumToArray';
import { OptionsValues } from 'utils/helper';

interface DatabaseFilterProps {
  currentFilter: IDatabaseFilter[];
  filterBy: IDatabaseFilterByOption;
  openFilterDialog: boolean;
  setCurrentFilter: (filters: IDatabaseFilter[]) => void;
  setOpenFilterDialog: (open: boolean) => void;
  setSearchOption: (search: string) => void;
  typeFilter: pageFilter;
}

export const useFilterDatabase = ({
  currentFilter,
  filterBy,
  openFilterDialog,
  typeFilter,
  setCurrentFilter,
  setOpenFilterDialog,
  setSearchOption,
}: DatabaseFilterProps) => {
  const { activeOptions } = useFindActiveOptions();
  const { usersTypesOptions } = useFindUsersTypesOptions();
  const productOptions = enumToArray(ProductClient);
  const CLIENT_STATUS = enumToArray(ClientDestineStatus);
  const { consultantsOptions } = useListAllConsultants(openFilterDialog);
  const { questionsOptions } = useListAllQuestions();

  const handleOpenFilterDialog = () => {
    setOpenFilterDialog(true);
  };
  const handleCloseFilterDialog = () => setOpenFilterDialog(false);

  const handleChangesFilter = (value: OptionsValues[]) => {
    const addedFilter = value[value.length - 1];
    const newValues = [
      ...currentFilter,
      {
        filterBy: filterBy.value,
        ...addedFilter,
      },
    ];
    setCurrentFilter(newValues);
  };

  const handleRemoveFilter = (option: IDatabaseFilter) => {
    const newFilters = currentFilter.filter(item => item !== option);

    setCurrentFilter(newFilters);
  };

  const resetFilter = useCallback(() => {
    setCurrentFilter([]);
  }, []);

  const clearSearchOption = useCallback(() => setSearchOption(''), []);

  const isFiltering = currentFilter.length > 0;

  const getOptions = () => {
    switch (filterBy?.value) {
      case IDatabaseFilterBy.MANAGER:
        return consultantsOptions;
      case IDatabaseFilterBy.TYPE:
        return usersTypesOptions;
      case IDatabaseFilterBy.PRODUCT:
        return productOptions;
      case IDatabaseFilterBy.STATUS:
        return CLIENT_STATUS;
      case IDatabaseFilterBy.ACTIVE_INATIVE:
        return activeOptions;
      case IDatabaseFilterBy.QUESTION:
        return questionsOptions;
      default:
        return [];
    }
  };

  const optionsFilter = getOptions();

  const isOptionEqualToValue = (option, filter) => {
    return option.value === filter.value && option.label === filter.label;
  };

  const generateFilterParams = useMemo(() => {
    const filterParams = currentFilter.reduce((acc, item) => {
      if (!acc[item.filterBy]) {
        acc[item.filterBy] = [];
      }

      if (item.filterBy === IDatabaseFilterBy.QUESTION) {
        acc[item.filterBy].push(item?.label);
      } else if (item.filterBy === IDatabaseFilterBy.STATUS) {
        acc[item.filterBy].push(ClientDestineStatus[item.value]);
      } else if (item.filterBy === IDatabaseFilterBy.PRODUCT) {
        acc[item.filterBy].push(ProductClient[item.value]);
      } else {
        acc[item.filterBy].push(item.value);
      }

      return acc;
    }, {});

    return filterParams;
  }, [currentFilter]);

  return {
    isFiltering,
    optionsFilter,
    open: openFilterDialog,
    filterParams: generateFilterParams,
    typeFilter,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
    setOpenFilterDialog,
    resetFilter,
    handleRemoveFilter,
    clearSearchOption,
    handleChangesFilter,
    isOptionEqualToValue,
  };
};
