import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  IAnswerOption,
  IQuestionAttachment,
  IQuestionAttachmentList,
  QuestionType,
} from 'models';
import { IFileStorage } from 'models';

import { useAuth } from 'config/auth/hooks';

import { enumToArray } from 'utils/enumToArray';

import { useGetUrlDownloadRequest, useQuestionStepperForm } from '.';
import { IQuestionPayload, IQuestionStepForm } from '../services';
import { useQuestionStore } from '../store/store';

export const useQuestionStep = () => {
  const [checkDescription, setCheckDescription] = useState(false);
  const [openDropzone, setOpenDropzone] = useState(false);
  const {
    currentQuestion,
    questionAttachment,
    setQuestionAttachments,
    setDataQuestion,
  } = useQuestionStore();
  const form = useQuestionStepperForm();
  const auth = useAuth();
  const QUESTIONS_TYPES = enumToArray(QuestionType);

  useEffect(() => {
    if (currentQuestion) {
      const formQuestion: IQuestionStepForm = {
        title: currentQuestion.title,
        description: currentQuestion?.description ?? '',
        type: currentQuestion.type,
        active: currentQuestion.active,
      };
      form.reset(formQuestion);
      setCheckDescription(currentQuestion?.description ? true : false);
    }
  }, [currentQuestion]);

  const handleChangeCheckDescription = () => {
    setCheckDescription(!checkDescription);
  };

  const requestDownloadFile = useGetUrlDownloadRequest({
    onError: () => {
      toast.error('Houve um erro ao tentar baixar o arquivo');
    },
  });

  const handleSaveAttachments = (files: IFileStorage[]) => {
    const filesAtacchments: IQuestionAttachmentList[] = [];
    files.map(file => {
      filesAtacchments.push({
        file: {
          id: file.id,
          path: file.path,
          directory: file.directory,
          fileName: file.fileName,
          mimeType: file.mimeType,
          size: file.size,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt,
        },
      });
    });

    setQuestionAttachments([...questionAttachment, ...filesAtacchments]);
  };

  const handleRemoveAttachment = (id: string) => {
    const newQuestionAttachments = questionAttachment.filter(
      att => att.file.id !== id,
    );
    setQuestionAttachments(newQuestionAttachments);
  };

  const handleDownloadFile = (id: string) => {
    //const file = questionAttachment.filter(att => att.file.id === id);
    requestDownloadFile.mutate({ idFileStorage: id });
  };

  const handleSubmitQuestionStepForm = form.handleSubmit(data => {
    const parcialDataQuestion: IQuestionPayload = {
      ...data,
      description: data.description?.length === 0 ? null : data.description,
      acceptAttachment: false,
      attachments: [] as IQuestionAttachment[],
      answerOptions: [] as IAnswerOption[],
      user: auth.user?.id ?? '',
      systemValueMapping: '',
    };
    setDataQuestion(parcialDataQuestion);
  });

  return {
    checkDescription,
    openDropzone,
    questionAttachment,
    currentQuestion,
    QUESTIONS_TYPES,
    setOpenDropzone,
    handleChangeCheckDescription,
    handleSaveAttachments,
    handleRemoveAttachment,
    handleDownloadFile,
    form: {
      handleSubmitQuestionStepForm,
      ...form,
    },
  };
};

export type UseQuestionStep = ReturnType<typeof useQuestionStep>;
