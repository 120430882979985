import { DialogActions } from '@mui/material';
import styled from 'styled-components';

const FooterWrapper = styled(DialogActions)`
  && {
    padding: 0px ${({ theme }) => theme.space[3]}px
      ${({ theme }) => theme.space[3]}px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0px ${({ theme }) => theme.space[5]}px
      ${({ theme }) => theme.space[5]}px !important;
  }
`;

export { FooterWrapper };
