import { useEffect } from 'react';

import { useMe, useUser } from '.';
import { isAuthenticated, setTokens } from '..';
import { ISignInPayload } from '../types';

const useAuthActions = () => {
  const { user, addUser, updateLoggedBranch } = useUser();
  const { isLoading, data } = useMe();

  const signIn = ({ accessToken, refreshToken, user }: ISignInPayload) => {
    setTokens(accessToken, refreshToken);
    addUser({
      id: user.id,
      name: user.name,
      email: user.email,
      surname: user.surname,
      cpf: user.cpf,
      birthDate: user.birthDate,
      position: user.position,
      userType: user.userType,
      linkedin: user.linkedin,
      language: user.language,
      enterprise: user.enterprise,
      role: user.role,
      active: user.active,
    });
  };

  useEffect(() => {
    data &&
      addUser({
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        surname: data.user.surname,
        cpf: data.user.cpf,
        birthDate: data.user.birthDate,
        position: data.user.position,
        userType: data.user.userType,
        linkedin: data.user.linkedin,
        language: data.user.language,
        enterprise: data.user.enterprise,
        role: data.user.role,
        active: data.user.active,
      });
  }, [data]);

  return {
    user,
    userPermissions: user?.role.permissions,
    isLoading,
    isAuthenticated,
    signIn,
    updateLoggedBranch,
  };
};

export { useAuthActions };
