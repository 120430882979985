import { create } from 'zustand';

type State = {
  step: number;
  userId: string;
  code: string;
};

const initialState: State = {
  step: 1,
  userId: '',
  code: '',
};

interface Actions {
  setStep: () => void;
  setUserId: (userId: string) => void;
  setCode: (code: string) => void;
  clearDefinePasswordStore: () => void;
}

const useDefinePasswordStore = create<State & Actions>()(set => ({
  ...initialState,
  setStep: () => set(state => ({ step: state.step === 2 ? 1 : state.step + 1 })),
  setUserId: (userId: string) => set({ userId }),
  setCode: (code: string) => set({ code }),
  clearDefinePasswordStore: () => set(initialState),
}));

export { useDefinePasswordStore };
