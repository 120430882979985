import { LoadingButton } from '@mui/lab';
import { useHistory } from 'react-router-dom';
import themes from 'themes';

import { Box, TextField } from 'components';

import { UseValidationCode } from 'modules/definePassword';

export interface ValidationCodeFormProps {
  form: UseValidationCode['codeForm'];
  isValidating?: boolean;
}

export const FormValidationCode = ({
  form,
  isValidating,
}: ValidationCodeFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  const history = useHistory();

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '3em',
        minWidth: '532px',
        padding: '1em',
      }}
    >
      <TextField
        name="code"
        label="Código"
        control={control}
        type="text"
        error={errors.code}
        sx={{
          '& .MuiOutlinedInput-root': {
            zIndex: 0,
            '& input': {
              zIndex: 1,
            },
            '& fieldset': {
              backgroundColor: '#e0e0e0',
              border: 'none',
              borderRadius: '8px',
            },
          },
        }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          gap: '2em',
        }}
        width={'100%'}
      >
        <LoadingButton
          variant="contained"
          onClick={() => history.push('/')}
          fullWidth
          sx={{
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,

            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          Cancelar
        </LoadingButton>

        <LoadingButton
          variant="contained"
          onClick={handleSubmitForm}
          loading={isValidating}
          disabled={!isValid || isValidating}
          fullWidth
          sx={{
            backgroundColor: themes.colors.text.primary,
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,
          }}
        >
          Confirmar
        </LoadingButton>
      </Box>
    </Box>
  );
};
