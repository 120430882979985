import { Paper, Typography } from '@mui/material';
import themes from 'themes';

import { Box, Logo } from 'components';
import { UserTypes } from 'models';

import EnterpriseSelect from '../components/EnterpriseSelect';
import SignIn from '../components/SignIn';
import { useSignIn } from '../hooks';
import { useSignInStore } from '../store/store';

export function SignInCompanyPage() {
  const { step } = useSignInStore();
  const {
    request,
    enterprise,
    enterprises,
    handleCancel,
    handleSignInEnterpriseSecondaryCnpj,
    setEnterprise,
  } = useSignIn();

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <Logo />
      {step === 1 && <SignIn title={UserTypes.ENTERPRISE} />}
      {step === 2 && (
        <Paper
          elevation={16}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',

            marginY: 'auto',
            gap: '1em',
            paddingX: '3em',
            paddingBottom: '2em',
            paddingTop: '3em',
            backgroundColor: '#f0f0f0',
            borderRadius: '11px',
            boxShadow:
              '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
            width: 532,
          }}
        >
          <Box
            display="flex"
            paddingX={20}
            style={{ gap: '1em' }}
            flexDirection="column"
          >
            <Typography
              fontFamily={themes.fonts.body}
              fontWeight={700}
              fontSize={23}
              color={themes.colors.blue}
              variant="h4"
              textAlign="center"
            >
              Portal de Empresas
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              fontFamily={themes.fonts.body}
            >
              Selecione uma empresa para entrar no seu sistema
            </Typography>
          </Box>
          <EnterpriseSelect
            request={request}
            handleCancel={handleCancel}
            handleEnter={handleSignInEnterpriseSecondaryCnpj}
            setEnterprise={setEnterprise}
            options={enterprises}
            enterprise={enterprise}
          />
        </Paper>
      )}
    </Box>
  );
}
