import { create } from 'zustand';

import { DEFAULT_PAGINATION } from 'utils/helper';

type State = {
  page: number;
  openNotificationsDialog: boolean;
};

interface Actions {
  setPage: (page: number) => void;
  setOpenNotificationsDialog: (open: boolean) => void;
}

const initialState: State = {
  page: DEFAULT_PAGINATION.page,
  openNotificationsDialog: false,
};

const useNotificationStore = create<State & Actions>()(set => ({
  ...initialState,
  setPage: page => set({ page }),
  setOpenNotificationsDialog: openNotificationsDialog =>
    set({ openNotificationsDialog }),
  clearOperationStore: () => set(initialState),
}));

export { useNotificationStore };
