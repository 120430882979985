import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { IFormAnswerResponse, IFormAnswerPayload } from '../services';
import FormAnswerServices from '../services/services';

const useSubmitAnswerRequest = (
  options?: UseMutationOptions<
    IFormAnswerResponse,
    IApiAxiosError,
    IFormAnswerPayload
  >,
) => {
  return useMutation(
    'submitAnswerRequest',
    FormAnswerServices.submitAnswer,
    options,
  );
};

export { useSubmitAnswerRequest };
