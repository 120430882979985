import { Header } from '../components';
//import DialogFilter from '../components/DialogFilter';
import { DialogForm } from '../components/DialogForm';
import { List } from '../components/List';
import { useConsultantDialog } from '../hooks';

const ListConsultantsPage = () => {
  const { form } = useConsultantDialog();

  return (
    <>
      <Header />
      <List />
      <DialogForm form={form} />
      {/* <DialogFilter /> } */}
    </>
  );
};

export { ListConsultantsPage };
