import { ReactNode } from 'react';

import { RootToolbar, RootWrapper } from './styles';

interface RootProps {
  children: ReactNode;
}

export function Root({ children }: RootProps) {
  return (
    <RootWrapper position="static">
      <RootToolbar>{children}</RootToolbar>
    </RootWrapper>
  );
}
