import { RoutesProps } from 'routes/types';

import { useGetPrivateRoutes } from '.';
import PrivateLayoutRoute from '../Route';

export function useGeneratePrivateLayoutRoutes() {
  const { privateRoutes } = useGetPrivateRoutes();

  const privateLayoutRoutes: JSX.Element[] = [];

  function addPrivateRoute(route: RoutesProps) {
    privateLayoutRoutes.push(
      <PrivateLayoutRoute
        key={route.path}
        exact
        path={route.path}
        component={route.component}
      />,
    );

    if (route.subs) {
      route.subs.forEach(addPrivateRoute);
    }
  }

  privateRoutes.forEach(addPrivateRoute);
  return { privateLayoutRoutes };
}
