import { Box, Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import plugin from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { useDashboardCreditor } from 'modules/dashboard/hooks';

import {
  calculateSumChart,
  getColors,
  increaseDistanceLegendPlugin,
} from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type typeOperationChart = 'status' | 'state' | 'product';

interface MappedItem {
  item: string;
  total: number;
}

interface ItemChart {
  title: string;
  totalPercent: number;
}

const OperationStatusChart = () => {
  const { data } = useDashboardCreditor();

  const exfinColors = true;

  const operationsData = data?.operationsData;

  const hasValues = operationsData?.some(item => item.value !== null) ?? false;

  const hasProducts = operationsData?.some(item => item.product !== null) ?? false;

  const getTotalSum = (type: typeOperationChart) => {
    let others = 0;
    const percentTotals: ItemChart[] = [];

    const mappedItem: Record<string, number> = (operationsData ?? []).reduce(
      (acc, item) => {
        const typeOp = item[type];
        const value = Number(item.value);

        if (Array.isArray(typeOp)) {
          typeOp.forEach(op => {
            if (acc[op]) {
              acc[op] += value;
            } else {
              acc[op] = value;
            }
          });
        } else {
          acc[typeOp] = acc[typeOp] ? acc[typeOp] + value : value;
        }
        return acc;
      },
      {} as Record<string, number>,
    );

    const result: MappedItem[] = Object.entries(mappedItem).map(
      ([item, total]) => ({
        item,
        total,
      }),
    );

    const totalSum =
      operationsData?.reduce(
        (acc, currentItem) => acc + Number(currentItem.value),
        0,
      ) ?? 0;

    // Checando se o valor é acima de 10%
    result.map(item => {
      const valuePercent = (item.total / totalSum) * 100;
      if (valuePercent > 10) {
        percentTotals.push({
          title: item.item,
          totalPercent: valuePercent,
        });
        return;
      }
      others = others + valuePercent;
    });

    return {
      totalSum,
      percentTotals,
      others,
    };
  };

  const { others: statusOthers, percentTotals: statusTotals } = getTotalSum(
    'status',
  );
  const { others: stateOthers, percentTotals: stateTotals } = getTotalSum('state');
  const { others: productOthers, percentTotals: productTotals } = getTotalSum(
    'product',
  );

  const getStatusName = (data: ItemChart[], others: number) => {
    const allNames = data.map(item => item.title);
    if (others > 0) {
      allNames.push('Outros');
    }

    return allNames;
  };

  const getPercentageData = (percentTotals: ItemChart[], others: number) => {
    const percentageData = percentTotals.map(item =>
      Number(item.totalPercent.toFixed(2)),
    );
    if (others > 0) {
      percentageData.push(Number(others.toFixed(2)));
    }
    return percentageData;
  };

  const renderChart = (
    title: string,
    percentTotals: ItemChart[],
    others: number,
  ) => {
    const heightWindow = window.innerHeight;
    const widthChart = heightWindow > 900 ? '30.5%' : '30%';

    return (
      <Card
        elevation={2}
        style={{ width: widthChart, padding: 14, maxHeight: 500 }}
      >
        <Chart
          type="pie"
          data={{
            labels: getStatusName(percentTotals, others),
            datasets: [
              {
                label: 'Dado',
                data: getPercentageData(percentTotals, others),
                backgroundColor: getColors(
                  getPercentageData(percentTotals, others),
                  title === 'Valor por Produto' ? exfinColors : !exfinColors,
                ),
              },
            ],
          }}
          plugins={[plugin, increaseDistanceLegendPlugin(15)]}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: themes.colors.dashboard.table.text.black,
                },
              },
              datalabels: {
                formatter: (value, ctx) => {
                  const chartData = ctx.chart;

                  const sumTotal = calculateSumChart(chartData);

                  const percent = ((value * 100) / sumTotal).toFixed(2) + '%';
                  return percent;
                },
                font: {
                  weight: 'bold',
                },
                color: '#fff',
              },
              title: {
                font: {
                  size: 24,
                },
                color: themes.colors.dashboard.table.text.black,
                text: title,
                display: true,
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const dataChart = context.chart;
                    const valueParsed = context.parsed;

                    const sumTotal = calculateSumChart(dataChart);

                    const percent = ((valueParsed * 100) / sumTotal).toFixed(2);
                    return context.label + ': ' + percent + '%';
                  },
                },
              },
            },
          }}
        />
      </Card>
    );
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      width={'100%'}
      justifyContent={'space-around'}
    >
      {hasValues && renderChart('Valor por Status', statusTotals, statusOthers)}

      {hasValues && renderChart('Valor por Estado', stateTotals, stateOthers)}

      {hasValues &&
        hasProducts &&
        renderChart('Valor por Produto', productTotals, productOthers)}
    </Box>
  );
};

export { OperationStatusChart };
