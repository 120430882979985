import { useLocation } from 'react-router-dom';

const useMenuItemActive = (routePath: string) => {
  const { pathname } = useLocation();

  const active = pathname === routePath;

  return {
    active,
  };
};

export { useMenuItemActive };
