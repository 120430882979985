import { toast } from 'react-toastify';

import { OperationDestineStatus } from 'models';

import queryClient from 'config/queryClient';

import { useTabActsStore } from 'modules/boards/tabs';
import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';
import { useListActsAllowedsRequest } from 'modules/boards/tabs/hooks';

import { OperationQueryKey } from '../services';
import { useOperationStore } from '../store/store';
import { useUpdateOpStatusRequest } from './useUpdateOpStatusRequest';

interface Props {
  enabledListAllActs?: boolean;
}

export const useOperationActions = ({ enabledListAllActs }: Props = {}) => {
  const {
    openChangeStatsDialog,
    openCreateActDialog,
    setOpenChangeStatsDialog,
    setOpenCreateActDialog,
    setOpenConfirmationDialog,
    selectedOperations,
    operationsQtd,
    setOperationActionType,
  } = useOperationStore();
  const { actsOptions } = useTabActsStore();

  const { handleNewAct } = useTabAct({ enabledListAllActs });
  useListActsAllowedsRequest(openCreateActDialog, true);

  const updateOperationStatusRequest = useUpdateOpStatusRequest();

  const handleOpenCreateActDialog = () => {
    if (selectedOperations.length === operationsQtd) {
      setOperationActionType('createAct');
      setOpenConfirmationDialog(true);

      return;
    }
    setOpenCreateActDialog(true);

    return;
  };

  const handleCloseCreateActDialog = () => {
    setOpenCreateActDialog(false);
  };

  const handleOpenChangeStatsDialog = () => {
    if (selectedOperations.length === operationsQtd) {
      setOperationActionType('changeStats');
      setOpenConfirmationDialog(true);

      return;
    }

    setOpenChangeStatsDialog(true);

    return;
  };

  const handleCloseChangeStatsDialog = () => {
    setOpenChangeStatsDialog(false);
  };

  const handleApplyStatus = async (status: OperationDestineStatus) => {
    updateOperationStatusRequest.mutate(
      { operationIds: selectedOperations.map(op => op.id), newStatus: status },
      {
        onSuccess: () => {
          toast.success('Status alterado com sucesso');
          setOpenChangeStatsDialog(false);
          queryClient.invalidateQueries([OperationQueryKey.INFINITE_OPERATIONS]);
        },
        onError: () => {
          toast.error('Não foi possível alterar o status.');
        },
      },
    );
  };

  const handleApplyAct = () => {
    toast.success('Ato criado com sucesso');

    setOpenCreateActDialog(false);
    // TODO - Redirecionar para tela de ato selecionado
  };

  return {
    openChangeStatsDialog,
    openCreateActDialog,
    actsOptions,
    selectedOperations,
    handleOpenCreateActDialog,
    handleCloseCreateActDialog,
    handleOpenChangeStatsDialog,
    handleCloseChangeStatsDialog,
    handleApplyStatus,
    handleApplyAct,
    handleNewAct,
  };
};
