import styled from 'styled-components';

import { Box } from 'components';

const HeaderWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-100']};
  padding: ${({ theme }) => theme.space[5]}px;
`;

export { HeaderWrapper };
