import { Button } from '@mui/material';
import styled from 'styled-components';

const ButtonWrapper = styled(Button)`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[4]}px;
  text-transform: none !important;
`;

export { ButtonWrapper };
