import { Icon } from '@mui/material';
import themes from 'themes';

import { Box, ChipOutlined, Typography } from 'components';

import {
  useQuestionAutocomplete,
  useQuestion,
  useDynamicForm,
  useGetTagsOutputsQuestion,
  useGetTagsInputsQuestion,
} from '../../hooks';
import { useCardQuestion } from '../../hooks/useCardQuestion';
import { IQuestionForm } from '../../services';
import { useFormStore } from '../../store/store';
import { DetailsQuestionDropdown } from './DetailsQuestionDropdown';
import { QuestionsAutocomplete } from './QuestionsAutocomplete';

interface CardQuestionProps {
  question: IQuestionForm;
}

export const CardQuestion = ({ question }: CardQuestionProps) => {
  const {
    allQuestions,
    formAutocomplete,
    handleChange,
    isOptionEqualToValue,
    handleOpenDropdown,
  } = useQuestionAutocomplete();
  const { setSearchQuestion } = useFormStore();

  const { handleDeleteCardQuestion } = useDynamicForm();
  const { handleAddCondition } = useCardQuestion();
  const { formQuestion } = useQuestion();

  const { allQuestionsOutput } = useGetTagsOutputsQuestion(question, allQuestions);
  const { allQuestionsInput } = useGetTagsInputsQuestion(question, allQuestions);

  return (
    <Box
      border="1px solid gray"
      borderRadius="5px"
      marginTop="1.5em"
      display="flex"
      flexDirection="column"
      paddingX="1.5em"
      paddingY="1em"
      width={'95%'}
      height={'100%'}
      overflow={'auto'}
    >
      <Box>
        {!question?.isChild && (
          <Box
            style={{ display: 'flex', float: 'right' }}
            onClick={() => handleDeleteCardQuestion(question.numberQuestion)}
            width={40}
          >
            <Icon style={{ cursor: 'pointer' }} fontSize="large">
              delete
            </Icon>
          </Box>
        )}
        <Box
          style={{ display: 'flex', float: 'right' }}
          onClick={() => handleOpenDropdown(question.numberQuestion)}
          width={40}
        >
          <Icon style={{ cursor: 'pointer' }} fontSize="large">
            {question.openDropdown ? 'expand_more' : 'expand_less'}
          </Icon>
        </Box>
      </Box>

      <Box display={'flex'} width={'90%'} justifyContent={'space-around'}>
        <QuestionsAutocomplete
          question={question}
          setSearch={setSearchQuestion}
          disabled={question?.isChild}
          form={formAutocomplete}
          allQuestions={allQuestions}
          isOptionEqualToValue={isOptionEqualToValue}
          value={question?.question?.title}
          handleChange={handleChange}
        />
        <Box ml={5} minWidth={220} maxWidth={300}>
          <Typography color={themes.colors['black']} fontWeight={'bold'}>
            Perguntas de Entrada{' '}
          </Typography>
          {allQuestionsInput?.map(
            (question, index) =>
              question?.inputQuestion?.label &&
              question?.inputQuestion?.label?.length > 0 &&
              question?.identifierInputQuestion > 0 && (
                <Box key={index} mt={2}>
                  <ChipOutlined key={index} label={question.inputQuestion.label} />
                </Box>
              ),
          )}
        </Box>
        <Box ml={5} minWidth={220} maxWidth={300}>
          <Typography color={themes.colors['black']} fontWeight={'bold'}>
            Perguntas de Saída{' '}
          </Typography>
          {allQuestionsOutput?.map(
            (question, index) =>
              question?.outputQuestion?.label &&
              question?.outputQuestion?.label.length > 0 &&
              question?.identifierOutputQuestion > 0 && (
                <Box key={index} mt={2}>
                  <ChipOutlined
                    key={index}
                    label={question.outputQuestion?.label}
                  />
                </Box>
              ),
          )}
        </Box>
      </Box>

      {question.openDropdown && (
        <Box mt={5}>
          <DetailsQuestionDropdown
            questionForm={question}
            control={formQuestion.control}
            identifierQuestion={question.numberQuestion}
            addCondition={handleAddCondition}
          />
        </Box>
      )}
    </Box>
  );
};
