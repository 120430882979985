import { IFileStorage } from 'models';

import { FileStorageService } from 'modules/file';

export const useDownloadFile = (file: IFileStorage) => {
  const downloadFile = async () => {
    const response = await FileStorageService.downloadDocument(file.id);

    const blob = new Blob([response.data], { type: file.mimeType });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.download = file.fileName.split('/')[1];
    link.href = url;
    link.click();
    link.remove();
  };

  const getUrlImage = async () => {
    const response = await FileStorageService.downloadDocument(file.id);

    const blob = new Blob([response.data], { type: file.mimeType });

    const url = window.URL.createObjectURL(blob);

    return url;
  };

  return { downloadFile, getUrlImage };
};
