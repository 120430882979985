import { DialogContent } from '@mui/material';
import styled from 'styled-components';

const ContentWrapper = styled(DialogContent)`
  && {
    padding: ${({ theme }) => theme.space[3]}px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: ${({ theme }) => theme.space[5]}px;
    }
  }
`;

export { ContentWrapper };
