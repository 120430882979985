import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { IQuestionInput } from 'models';

import { OptionsValues } from 'utils/helper';

import { useQuestionAutocompleteForm } from '.';
import { useGetFormsQuestion } from '.';
import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { useListActiveQuestions } from './useListActiveQuestions';

export const useTrueAutocompleteConditionQuestionTypeInput = () => {
  const [search, setSearch] = useState('');
  const { questionsOptions, dataAllQuestions } = useListActiveQuestions();
  const form = useQuestionAutocompleteForm(); //pode ser o usado o msm em todos
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();

  const handleChange = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
    identifierCondition?: number,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;

    field.onChange(value);

    // pergunta que foi selecionada para a questão do formulário
    const questionSelected = dataAllQuestions?.find(
      question => question.id === `${selectedOption?.value}`,
    );

    if (!identifierCondition) return;

    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const questionChanged =
      questionSelected?.id !==
      questionsForm[indexQuestion].conditions[indexCondition].conditionTypeInput
        .nextQuestion;

    if (!questionChanged) return;

    const idQuestionChanged =
      questionsForm[indexQuestion].conditions[indexCondition].conditionTypeInput
        .nextQuestion;

    const questionChildExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged && qF.numberQuestion !== -1,
    );

    if (questionChildExcluded)
      handleDeleteSpecificChildQuestion(questionChildExcluded);

    if (questionSelected) {
      questionsForm[indexQuestion].conditions[
        indexCondition
      ].conditionTypeInput.nextQuestion = questionSelected.id;

      questionsForm[indexQuestion].conditions[
        indexCondition
      ].conditionTypeInput.nextQuestionLabel = questionSelected.title;

      setQuestionsForm(questionsForm);
    } else {
      questionsForm[indexQuestion].conditions[
        indexCondition
      ].conditionTypeInput.nextQuestion = '';

      questionsForm[indexQuestion].conditions[
        indexCondition
      ].conditionTypeInput.nextQuestionLabel = '';

      setQuestionsForm(questionsForm);
    }
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  return {
    search,
    allQuestions: questionsOptions,
    formAutocompleteConditionQuestionTypeText: {
      ...form,
    },
    setSearch,
    handleChange,
    isOptionEqualToValue,
  };
};
