import ExcelJS from 'exceljs';
import { useQuery } from 'react-query';

import {
  ICompanyBigData,
  IPagination,
  KleverAddress,
  KleverCompanyEmployee,
  KleverCompanyExport,
} from 'models';

import { StorageService } from '../services';

export const useDownloadExcelBigData = () => {
  const unlimited = 10000000000;

  const { data: response } = useQuery(
    ['storage excel'],
    () =>
      StorageService.getBigData({
        page: 0,
        perPage: unlimited,
      }) as Promise<IPagination<ICompanyBigData>>,
    { keepPreviousData: true },
  );

  const dataStorage = response?.data;

  const getDatePtBr = (date: string | Date, includeHour?: boolean) => {
    if (!date) return '';

    const newDate = new Date(date);
    if (includeHour) {
      return newDate.toLocaleString('pt-BR');
    }
    return newDate.toLocaleDateString('pt-BR');
  };

  const getCurrencyPtBr = (value: number | string) => {
    if (value === 0) return '';

    const newValue = value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    });
    return newValue;
  };

  const titleCell: ExcelJS.FillPattern = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FF0070C0' }, // Azul
  };

  const joinArrayString = (array: string[]) => {
    return array.map(item => item).join(' , ');
  };

  const handleDownloadExcel = async () => {
    // Criando uma nova planilha Excel
    const workbook = new ExcelJS.Workbook();

    const tableWorksheet = workbook.addWorksheet('Dados - Big Data');
    const adressWorksheet = workbook.addWorksheet('Endereço');
    const othersCnaeWorksheet = workbook.addWorksheet('Outros CNAEs da empresa');
    const physicalProductionWorksheet = workbook.addWorksheet('Produção Física');
    const judicialProcessWorksheet = workbook.addWorksheet(
      'Quantidade de Processos Judiciais',
    );
    const partnersWorksheet = workbook.addWorksheet('Sócios');
    const partnerPersonWorksheet = workbook.addWorksheet('Sócio Pessoa');
    const partnerPersonAddressWorksheet = workbook.addWorksheet(
      'Sócio Pessoa - Endereço',
    );
    const affiliatesWorksheet = workbook.addWorksheet('Afiliados');
    const aircraftWorksheet = workbook.addWorksheet('Aeronaves');
    const fuelStationEquipamentsWorksheet = workbook.addWorksheet(
      'Postos de combustíveis - Equipamentos',
    );
    const fuelStationFlagsWorksheet = workbook.addWorksheet(
      'Postos de combustíveis - Bandeiras',
    );
    const fuelStationWorksheet = workbook.addWorksheet('Postos de combustíveis');
    const anttWorksheet = workbook.addWorksheet('ANTT');
    const anvisaProcessWorkSheet = workbook.addWorksheet('Anvisa - Processos');
    const bankOperationsWorkSheet = workbook.addWorksheet('Operações Bancárias');
    const beneficiariesWorksheet = workbook.addWorksheet('Beneficiários');
    const cafirWorksheet = workbook.addWorksheet('CAFIR');
    const ceisWorksheet = workbook.addWorksheet('CEIS');
    const cepimsWorksheet = workbook.addWorksheet('CEPIMS');
    const cfcWorksheet = workbook.addWorksheet('CFCs');
    const cnepsWorksheet = workbook.addWorksheet('CNEPs');
    const cnjCniasWorksheet = workbook.addWorksheet('CNJ-CNIAS');
    const companysRelatedWorksheet = workbook.addWorksheet('Empresas Relacionadas');
    const coursesEmecWorksheet = workbook.addWorksheet('Cursos Emec');
    const criCraDebenturesWorksheet = workbook.addWorksheet('Debêntures CRI-CRA');
    const crSefWorksheet = workbook.addWorksheet('CR-SEF');
    const cvmRegistrationWorksheet = workbook.addWorksheet(
      'Dados de Registro na CVM',
    );
    const debitMteProcessWorksheet = workbook.addWorksheet(
      'Débitos MTE - Processos',
    );
    const debitsPgfnDauWorksheet = workbook.addWorksheet('Débitos PGFN-DAU');
    const documentsWorksheet = workbook.addWorksheet('Documentos');
    const domainsWorksheet = workbook.addWorksheet('Domínios');
    const employeedGrowthsWorksheet = workbook.addWorksheet(
      'Crescimento de Colaboradores',
    );
    const employeedsWorksheet = workbook.addWorksheet('Colaboradores');
    const environmentalLicensesWorksheet = workbook.addWorksheet(
      'Licensas Ambientais',
    );
    const exEmployeedsWorksheet = workbook.addWorksheet('Ex-Colaboradores');
    const exportationsWorksheet = workbook.addWorksheet('Exportações');
    const financialIndicatorsWorksheet = workbook.addWorksheet(
      'Indicadores Financeiros',
    );
    const healthEstablishmentsWorksheet = workbook.addWorksheet(
      'Estabelecimento de Saúde',
    );
    const heavyVehicleInfoWorksheet = workbook.addWorksheet(
      'Veículos Pesados - Especificações',
    );
    const ibgeWorksheet = workbook.addWorksheet('IBGE');
    const importationsWorksheet = workbook.addWorksheet('Importações');
    const inpiBrandsWorksheet = workbook.addWorksheet('INPI - Marcas');
    const inpiPatentsWorksheet = workbook.addWorksheet('INPI - Patentes');
    const inpiSoftwaresWorksheet = workbook.addWorksheet('INPI - Softwares');
    const internationalListWorksheet = workbook.addWorksheet('Lista Internacional');
    const contractWorksheet = workbook.addWorksheet('Contratos');
    const contractModalitytWorksheet = workbook.addWorksheet(
      'Contratos - Modalidade',
    );
    const actionPositionsWorksheet = workbook.addWorksheet(
      'Capital Aberto - Posições em Ações em Aberto',
    );
    const patrimonialBalanceWorksheet = workbook.addWorksheet(
      'Capital Aberto - Balanço Patrimonial',
    );
    const penaltysOCWorksheet = workbook.addWorksheet(
      'Capital Aberto - Penalidades',
    );
    const demonstrationResultsOCWorksheet = workbook.addWorksheet(
      'Capital Aberto - Demonstração dos Resultados',
    );

    const mealPatWorksheet = workbook.addWorksheet('PAT - Refeições');
    const modalitysPatWorksheet = workbook.addWorksheet('PAT - Modalidades');
    const proconGroupsWorksheet = workbook.addWorksheet('PROCON - Grupos');
    const proconProcessWorksheet = workbook.addWorksheet('PROCON - Processos');
    const propertiesWorksheet = workbook.addWorksheet('Propriedades');
    const schoolsWorksheet = workbook.addWorksheet('Escolas');
    const sintegraWorksheet = workbook.addWorksheet('Inscrição SINTEGRA');
    const taxHealthCNDSWorksheet = workbook.addWorksheet(
      'Saúde Financeira da Empresa - CNDS',
    );
    const workMTEsWorksheet = workbook.addWorksheet('Endereços MTEs');

    const headersTable = [
      'CNPJ',
      'RAZÃO SOCIAL',
      'NOME FANTASIA',
      'DATA DE ABERTURA',
      'CAPITAL SOCIAL',
      'CÓDIGO JURÍDICO',
      'DESCRIÇÃO JURÍDICA',
      'EMAIL FINANCEIRO',
      'TELEFONES DE CONTATO',
      'NÍVEL DE ATIVIDADE',
      'IDADE DA EMPRESA',
      'DATA DE ÚLTIMA CONSULTA BNDES',
      'PROPENSÃO DE FECHAMENTO DA EMPRESA',
      'TAMANHO DA EMPRESA',
      'SITUAÇÃO INDIVIDUAL COVID-19',
      'SEGMENTO COVID-19',
      'DATA DA CRIAÇÃO',
      'DATA DA SITUAÇÃO DE REGISTRO',
      'SITUAÇÃO ESPECIAL DA EMPRESA',
      'DATA DE SITUAÇÃO ESPECIAL',
      'PROPENSÃO DE ENTREGA',
      'PROPENSÃO DE ECOMMERCE',
      'NÚMERO DE ANALISTAS',
      'NÚMERO DE COLABORADORES DE BASE',
      'NÚMERO DE COMPRADORES',
      'NÚMERO DE MÉDICOS',
      'NÚMERO DE ENGENHEIROS',
      'NÚMERO DE ADVOGADOS',
      'NÚMERO DE GERENTES',
      'OUTRAS CONTAGENS',
      'NÚMERO DE PDVs',
      'NÚMERO DE VENDEDORES',
      'NÚMERO DE SUPERVISORES',
      'NÚMERO DE PROFESSORES',
      'VALOR ESTIMADO DE FATURAMENTO',
      'GRUPO DE FATURAMENTO ESTIMADO',
      'NOME DA FRANQUIA',
      'FATURAMENTO BRUTO DA EMPRESA',
      'GRUPO MULTINACIONAL',
      'CONTATO DE CONTADOR DISPONÍVEL',
      'QUADRO DE SÓCIOS E ADMINISTRADORES DIVERGENTES',
      'INDICATIVO DE FRANQUIA',
      'DATA DA ÚLIMA CONSULTA IBGE',
      'DATA E HORA DA ULTIMA ATUALIZAÇÃO DOS DADOS DA EMPRESA',
      'EMPRESA MATRIZ',
      'EMPRESA MULTINACIONAL',
      'PÁIS DE ORIGEM',
      'PASSIVEL DE IISS',
      'QUANTIDADE DE FILIAIS ATIVAS',
      'QUANTIDADE DE COLABORADORES',
      'QUANTIDADE DE EX COLABORADORES',
      'MOTIVO DE SITUAÇÃO DE REGISTRO DA EMPRESA',
      'CAPACIDADE DE TRABALHO REMOTO',
      'DATA E HORA DA ÚLTIMA CONSULTA SIDRA',
      'CNAE - CÓDIGO',
      'CNAE - DESCRIÇÃO',
      'CNAE - ATIVIDADE PRINCIPAL',
      'CNAE - SETOR',
      'VALOR TOTAL DE OUTROS PROCESSOS JUDICIAIS',
      'VALOR TOTAL DE PROCESSOS JUDICIAIS ATIVOS',
      'VALOR TOTAL DE PROCESSOS JUDICIAIS COMO PARTE PASSIVA',
      'VALOR TOTAL DE TODOS OS PROCESSOS JUDICIAIS',
      'BANDEIRA ATUAL',
      'TIPO DE POSTO',
      'AUTORIZAÇÃO DO POSTO',
      'TOTAL DE BICOS',
      'TOTAL DE TANQUES',
      'ANVISA - REPRESENTANTES LEGAIS',
      'ANVISA - REPRESENTANTES TÉCNICOS',
      'CADASTUR - NÚMERO DO CERTIFICADO',
      'CADASTUR - LISTA DE IDIOMAS',
      'CADASTUR - NÚMERO DE VEÍCULOS',
      'CADASTUR - CADASTRADO',
      'CADASTUR - LISTA DE SEGMENTOS',
      'CADASTUR - LISTA DE SERVIÇOS',
      'CADASTUR - SITE DO ESTABELECIMENTO',
      'CADASTUR - SITUAÇÃO CADASTUR',
      'CADASTUR - UF',
      'CVM - ANO DE BALANÇO',
      'CVM - LUCRO BRUTO',
      'CVM - RECEITA BRUTA',
      'CVM - ATIVOS LÍQUIDOS',
      'CVM - FATURAMENTO LÍQUIDO',
      'CVM - LUCRO LÍQUIDO',
      'CVM - ATIVOS TOTAIS',
      'DÉBITOS MTE - TIPO DE CERTIFICADO',
      'DÉBITOS MTE - CÓDIGO DA EMPRESA',
      'DÉBITOS MTE - SITUAÇÃO DO DÉBITO',
      'DÉBITOS MTE - DATA DE EMISSÃO',
      'DÉBITOS PGFN & DAU - TOTAL DE DÉBITOS',
      'ATIVIDADE FINANCEIRA - DATA DA HABILITAÇÃO',
      'ATIVIDADE FINANCEIRA - NÚMERO DA HABILITAÇÃO',
      'ATIVIDADE FINANCEIRA - SITUAÇÃO DA HABILITAÇÃO',
      'ATIVIDADE FINANCEIRA - DATA DA CONSULTA',
      'ATIVIDADE FINANCEIRA - SEGMENTO',
      'COMÉRCIO EXTERIOR - OPERAÇÕES AUTORIZADAS',
      'COMÉRCIO EXTERIOR - COMÉRCIO EXTERIOR HABILITADO',
      'COMÉRCIO EXTERIOR - SITUAÇÃO DE COMÉRCIO EXTERIOR HABILITADO',
      'COMÉRCIO EXTERIOR - MODALIDADE',
      'COMÉRCIO EXTERIOR - DATA DA SITUAÇÃO',
      'COMÉRCIO EXTERIOR - SUBMODALIDADE',
      'VEÍCULOS PESADOS - ATÉ 1 TONELADA',
      'VEÍCULOS PESADOS - ACIMA DE 10 TONELADAS',
      'VEÍCULOS PESADOS - ENTRE 2 E 5 TONELADAS',
      'VEÍCULOS PESADOS - ENTRE 5 E 10 TONELADAS',
      'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ATÉ 1 TONELADA',
      'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ACIMA DE 10 TONELADAS',
      'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ENTRE 2 E 5 TONELADAS',
      'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ENTRE 5 E 10 TONELADAS',
      'VEÍCULOS PESADOS - TOTAL DE VEÍCULOS',
      'VEÍCULOS PESADOS - GRUPO DE NÚMERO DE VEÍCULOS PESADOS',
      'IBAMA CND - NÚMERO DE CERTIFICADO DE REGULARIDADE',
      'IBAMA CND - DATA DE EMISSÃO',
      'IBAMA CND - SITUAÇÃO',
      'IBAMA CND - DATA DE VALIDADE',
      'GERENCIAMENTO DE CONTRATO - QUANTIDADE TOTAL',
      'GERENCIAMETNO DE CONTRATO - VALOR TOTAL',
      'CAPITAL ABERTO - COMPLEMENTO DO LOGRADOURO DO DIRETO DE INVESTIMENTOS',
      'CAPITAL ABERTO - E-MAIL DO DIRETO DE INVESTIMENTOS',
      'CAPITAL ABERTO - LOGRADOURO DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - ATIVIDADE PRINCIPAL DA EMPRESA',
      'CAPITAL ABERTO - MUNICÍPIO DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - NOME DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - CÓDIGO DE NEGOCIAÇÃO DA EMPRESA',
      'CAPITAL ABERTO - NÚMERO DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - NÚMERO DO TELEFONE DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - CLASSIFICAÇÃO SETORIAL',
      'CAPITAL ABERTO - SITE DA EMPRESA',
      'CAPITAL ABERTO - CÓDIGO DO PREGÃO',
      'CAPITAL ABERTO - UF DO DIRETOR DE INVESTIMENTOS',
      'CAPITAL ABERTO - CEP DO DIRETOR DE INVESTIMENTOS',
      'PAT - NÚMERO DE FUNCIONÁRIOS BENEFICIADOS',
      'PAT - DATA DO EXERCÍCIO',
      'PAT - INSCRIÇÃO',
      'PAT - SITUAÇÃO DE REGISTRO',
      'PAT - CPF DO RESPONSÁVEL',
      'PAT - E-MAIL DO RESPONSÁVEL',
      'PAT - NOME DO RESPONSÁVEL',
      'PROCON - VALOR TOTAL DE PENALIDADE DE GRUPO DO PROCON',
      'PROCON - NOME DO PROCON',
      'PROCON - VALOR TOTAL DAS PENALIDADES',
      'PROCON - DATA DE ATUALIZAÇÃO',
      'SIMPLE NATIONAL - SIMEI',
      'SIMPLE NATIONAL - DATA DE ADESÃO SIMEI',
      'SIMPLE NATIONAL - SIMPLES',
      'SIMPLE NATIONAL - DATA DE ADESÃO SIMPLES',
      'SIMPLE NATIONAL - SIMPLES REGULAR',
      'SUFRAMA - DATA DE EXPIRAÇÃO',
      'SUFRAMA - PERCENTUAL DE ICMS',
      'SUFRAMA - PERCENTUAL IPI',
      'SUFRAMA - PERCENTUAL PIS/COFINS',
      'SUFRAMA - NÚMERO DO REGISTRO',
      'SUFRAMA - SITUAÇÃO DO REGISTRO',
      'INFORMAÇÕES RELACIONADA À SAÚDE FISCAL DA EMPRESA',
    ];

    const headersAddress = [
      'CNPJ',
      'FANTASY NAME',
      'ENDEREÇO 1',
      'ENDEREÇO 2',
      'MUNÍCIPIO DE FRONTEIRA',
      'TIPO DE EDIFICAÇÃO',
      'CÓDIGO DO PÁIS',
      'CÓDIGO DO MUNICÍPIO',
      'EDIFÍCIO COLETIVO',
      'PAÍS',
      'RESTRIÇÃO DE ENTREGA',
      'ENDEREÇO FORMATADO',
      'ÚLTIMO ENDEREÇO',
      'LATITUDE',
      'LONGITUDE',
      'MICROREGIÃO',
      'MACROREGIÃO',
      'MUNICÍPIO',
      'BAIRRO',
      'NÚMERO',
      'BAIRRO ORIGINAL',
      'PRECISÃO',
      'REGIÃO',
      'ESTADO REGISTRADO',
      'ENDEREÇO RESIDENCIAL',
      'TELEFONES RESIDENCIAIS',
      'ESTADO',
      'CEP',
    ];

    const headersOthersCnae = [
      'CNPJ',
      'FANTASY NAME',
      'CNAE - CÓDIGO',
      'CNAE - DESCRIÇÃO',
      'CNAE - ATIVIDADE PRINCIPAL',
      'CNAE - SETOR',
    ];

    const headersPhysicalProduction = [
      'CNPJ',
      'FANTASY NAME',
      'CÓDIGO',
      'DATA',
      'NÍVEL TERRITORIAL DAS PRODUÇÕES FÍSICAS DO CNAE PRINCIPAL',
      'ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - UNIDADE DE MEDIDA',
      'ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - NOME',
      'ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - VALOR',
      'ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - UNIDADE DE MEDIDA',
      'ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - NOME',
      'ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - VALOR',
      'ÍNDICE MENSAL - UNIDADE DE MEDIDA',
      'ÍNDICE MENSAL - NOME',
      'ÍNDICE MENSAL - VALOR',
      'VARIAÇÃO PERCENTUAL MENSAL - UNIDADE DE MEDIDA',
      'VARIAÇÃO PERCENTUAL MENSAL - NOME',
      'VARIAÇÃO PERCENTUAL MENSAL - VALOR',
      'VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - UNIDADE DE MEDIDA',
      'VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - NOME',
      'VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - VALOR',
      'VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - UNIDADE DE MEDIDA',
      'VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - NOME',
      'VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - VALOR',
      'ÍNDICE ACUMULADO DO ANO ATÉ A DATA - UNIDADE DE MEDIDA',
      'ÍNDICE ACUMULADO DO ANO ATÉ A DATA - NOME',
      'ÍNDICE ACUMULADO DO ANO ATÉ A DATA - VALOR',
    ];

    const headersJudicalProcess = [
      'CNPJ',
      'FANTASY NAME',
      'TIPO',
      'QUANTIDADE TOTAL',
      'QUANTIDADE ATIVA',
      'QUANTIDADE OUTROS',
      'QUANTIDADE COMO PARTE ATIVA',
      'QUANTIDADE COMO PARTE PASSIVA',
    ];

    const headersPartners = [
      'CNPJ',
      'FANTASY NAME',
      'DOCUMENTO',
      'NOME',
      'PAÍS DE ORIGEM',
      'NÍVEL DE FORMAÇÃO',
      'NÍVEL DE FORMAÇÃO NO RF',
      'QUALIFICAÇÃO',
      'QUALIFICAÇÃO NO RF',
      'PARTICIPAÇÃO',
      'PARTICIPAÇÃO NO RF',
      'PARTICIPAÇÃO NO CAPITAL SOCIAL',
      'PARTICIPAÇÃO NO CAPITAL SOCIAL NO RF',
      'FALECIDO',
      'LISTAGEM DE PROCESSOS CRIMINAIS',
    ];

    const headersPersonPartner = [
      'CNPJ',
      'FANTASY NAME',
      'CPF',
      'NOME',
      'GÊNERO',
      'E-MAIL',
      'CNS',
      'NIS',
      'CPF DA MÃE',
      'NOME DA MÃE',
      'NOME DO PAI',
      'PAÍS DE ORIGEM',
      'INSCRIÇÃO SOCIAL',
      'VALOR DO DÉBITO PGFN/DAU',
      'SITUAÇÃO DO CPF',
      'NÍVEL DE EDUCAÇÃO',
      'TIPO DE DEFICIÊNCIA',
      'CÓDIGO NEOWAY DA PROFISSÃO',
      'CÓDIGO CBO DA PROFISSÃO',
      'SITUAÇÃO GERAL',
      'SITUAÇÃO DE REGISTRO',
      'DATA DE INCRIÇÃO DO CPF',
      'QUANTIDADE ÚNICA DE SÓCIOS',
      'IDADE',
      'DATA DE FALECIMENTO',
      'DEFICIÊNCIA',
      'FALECIDO',
      'CONFIRMAÇÃO DE FALECIMENTO',
      'AGENTE PÚBLICO',
      'DATA DE NASCIMENTO',
      'DATA E HORA DA ULTIMA CONSULTA DE PROTESTO',
    ];

    const headersPersonPartnerAddress = ['CPF', ...headersAddress];

    const headersAffiliates = [
      'CNPJ',
      'FANTASY NAME',
      'CNPJ - AFILIADO',
      'MUNÍCIO - AFILIADO',
      'DATA DE ABERTURA - AFILIADO',
      'SITUAÇÃO DE REGISTRO - AFILIADO',
      'RAZÃO SOCIAL - AFILIADO',
      'UF - AFILIADO',
    ];

    const headersAircrafts = [
      'CNPJ',
      'FANTASY NAME',
      'FABRICANTE',
      'MODELO',
      'NOME DO OPERADOR',
      'NOME DO PROPRIETÁRIO',
      'ANO DE FABRICAÇÃO',
      'REGISTRO DE AERONAVE',
      'SITUAÇÃO',
      'STATUS RAB',
    ];

    const headersFuelStationEquipaments = [
      'CNPJ',
      'FANTASY NAME',
      'QUANTIDADE DE BICOS',
      'TIPO DE COMBUSTÍVEL',
      'ABASTECIMENTO POR BICO',
    ];

    const headersFuelStationFlags = [
      'CNPJ',
      'FANTASY NAME',
      'BANDEIRA',
      'DATA DE INÍCIO',
    ];

    const headersFuelStation = [
      'CNPJ',
      'FANTASY NAME',
      'MUNICÍPIO',
      'CAPACIDADE TOTAL DOS TANQUES',
      'NÚMERO TOTAL DE TANQUES',
      'UF',
    ];

    const headersAntt = [
      'CNPJ',
      'FANTASY NAME',
      'CATEGORIA',
      'DATA DE EMISSÃO',
      'DATA DE EXPIRAÇÃO',
      'MUNICÍPIO',
      'NÚMERO RNTRC',
      'SITUAÇÃO',
      'UF',
    ];

    const headersAnvisaProcess = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE AUTORIZAÇÃO',
      'PROCESSO DE AUTORIZAÇÃO',
      'DESCRIÇÃO DO PROCESSO',
      'NÚMERO DO PROCESSO',
    ];

    const headersBankOperations = [
      'CNPJ',
      'FANTASY NAME',
      'PERÍODO DE AMORTIZAÇÃO',
      'DATA DA CONTRATAÇÃO',
      'VALOR CONTRATADO',
      'AGENTE FINANCEIRO',
      'CUSTO FINANCEIRO',
      'PERÍODO DE CARÊNCIA',
      'PRODUTO FINANCEIRO',
      'STATUS DE OPERAÇÃO',
      'TAXA DE JUROS',
      'TIPO DE OPERAÇÃO',
      'UNIDADE FEDERATIVA',
    ];

    const headersBeneficiaries = [
      'CNPJ',
      'FANTASY NAME',
      'DOCUMENTO',
      'NOME',
      'NOTA',
      'NOTA QSA',
      'PARTICIPAÇÃO',
      'PARTICIPAÇÃO QSA',
      'FALECIDO',
    ];

    const headersCafir = [
      'CNPJ',
      'FANTASY NAME',
      'ÁREA',
      'CONDOMÍNIO',
      'MUNICÍPIO',
      'NOME',
      'NÚMERO NIRF',
    ];

    const headersCeis = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE TÉRMINO DA SANÇÃO',
      'DATA DE INFORMAÇÃO',
      'ENTIDADE INFORMANTE',
      'DATA DE INÍCIO DA SANÇÃO',
      'FUNDAMENTAÇÃO LEGAL',
      'ÓRGÃO COMPLEMENTAR',
      'NÚMERO DO PROCESSO',
      'SANÇÃO',
      'ENTIDADE SANCIONADORA',
      'UF DA ENTIDADE SANCIONADORA',
    ];

    const headersCepims = [
      'CNPJ',
      'FANTASY NAME',
      'NÚMERO CONTRATO',
      'DATA DE TÉRMINO DO CONTRATO',
      'NOME DA ENTIDADE CONCEDENTE',
      'TIPO DE IMPEDIMENTO',
      'VALOR LIBERADO',
    ];

    const headersCfc = [
      'CNPJ',
      'FANTASY NAME',
      'CRC',
      'NÚMERO REGISTRO',
      'SITUAÇÃO',
      'TIPO',
    ];

    const headersCenep = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE TÉRMINO DA SANÇÃO',
      'DATA DE INÍCIO DA SANÇÃO',
      'VALOR DA PENALIDADE',
      'NÚMERO DO PROCESSO',
      'SANÇÃO',
      'ENTIDADE SANCIONADORA',
      'UF DA ENTIDADE SANCIONADORA',
    ];

    const headersCnjCnias = [
      'CNPJ',
      'FANTASY NAME',
      'DESCRIÇÃO DE ENTIDADE',
      'NÚMERO DO PROCESSO',
      'DATA DO REGISTRO',
      'PROBLEMAS RELACIONADOS',
      'ESFERA DA ENTIDADE',
      'UF',
      'VALOR RELACIONADO',
    ];

    const headersCompanysRelated = [
      'CNPJ - EMPRESA ORIGINAL',
      'FANTASY NAME - EMPRESA ORIGINAL',
      'CNPJ',
      'DESCRIÇÃO DO CNAE',
      'MUNICÍPIO',
      'DATA DE ABERTURA',
      'RAZÃO SOCIAL',
      'UF',
    ];

    const headersCoursesEmec = [
      'CNPJ',
      'FANTASY NAME',
      'ORGANIZAÇÃO ACADÊMICA',
      'CATEGORIA ADMINISTRATIVA',
      'CÓDIGO LES',
      'DESCRIÇÃO LES',
      'MUNICÍPIO',
      'SITUAÇÃO',
      'UF',
    ];

    const headersCriCraDebentures = [
      'CNPJ',
      'FANTASY NAME',
      'CÓDIGO',
      'DEVEDOR/EMISSOR',
      'DATA DE VENCIMENTO',
      'VALOR INDICATIVO',
      'SETOR DE SEGURO',
      'DATA DE EMISSÃO',
      'PU Par',
      'REMUNERAÇÃO',
      'SÉRIE DE EMISSÃO',
      'VOLUMA DA SÉRIA NA DATA DE EMISSÃO',
      'TIPO',
    ];

    const headersCrSef = [
      'CNPJ',
      'FANTASY NAME',
      'NÚMERO ACORDADO',
      'PARTE ENVOLVIDA',
      'NÚMERO DO PROCESSO',
      'NÚMERO DO RECURSO',
      'TIPO DO RECURSO',
    ];

    const headersCvmRegistration = [
      'CNPJ',
      'FANTASY NAME',
      'SETOR DE ATIVIDADE',
      'CÓDIGO DA EMPRESA',
      'ATIVOS LÍQUIDOS',
      'DATA DE REGISTRO',
      'TIPO DE REGISTRO',
      'NOME DO RESPONSÁVEL',
      'TIPO DE RESPONSÁVEL',
      'DATA DA SITUAÇÃO',
      'REGISTRO DA SITUAÇÃO',
    ];

    const headersDebitMteProcess = [
      'CNPJ',
      'FANTASY NAME',
      'CAPITULAÇÃO DA INFRAÇÃO NO PROCESSO',
      'CATEGORIA DA INFRAÇÃO NO PROCESSO',
      'NÚMERO DO PROCESSO',
      'SITUAÇÃO DO PROCESSO',
    ];

    const headersDebitsPgfnDau = [
      'CNPJ',
      'FANTASY NAME',
      'NATUREZA DO DÉBITO',
      'NÚMERO DA INSCRIÇÃO',
      'VALOR DO DÉBITO',
    ];

    const headersDocuments = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE CRIAÇÃO',
      'A EXTENSÃO',
      'NOME',
      'TIPO',
      'URL',
    ];

    const headersDomains = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE CRIAÇÃO',
      'DATA DE EXPIRAÇÃO',
      'DATA DE MODIFICAÇÃO',
      'NOME',
      'RESPONSÁVEL',
    ];

    const headersEmployeedGrowths = [
      'CNPJ',
      'FANTASY NAME',
      'CRESCIMENTO DE COLABORADORES',
      'TAXA DE CRESCIMENTO DE COLABORADORES',
      'ANO DE CRESCIMENTO',
    ];

    const headersEmployeeds = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE ADMISSÃO',
      'DATA DE NASCIMENTO',
      'CPF',
      'NOME',
      'ANO DE DESLIGAMENTO',
    ];

    const headersEnvironmentalLicenses = [
      'CNPJ',
      'FANTASY NAME',
      'DESCRIÇÃO DA TIPOLOGIA DA LICENÇA',
      'DATA DE EMISSÃO',
      'MUNÍCIPIO',
      'NÚMERO DO PROCESSO',
      'SITUAÇÃO',
      'TIPO DE LICENÇA',
      'NÚMERO TIPOLOGIA DA LICENÇA',
      'UF',
      'DATA DE ATUALIZAÇÃO',
    ];

    const headersFinancialIndicators = [
      'CNPJ',
      'FANTASY NAME',
      'INDICADOR DE AUMENTO',
      'MARGEM',
    ];

    const headersExportationsImportations = [
      'CNPJ',
      'FANTASY NAME',
      'ANO',
      'VALOR',
    ];

    const headersHealthEstablishment = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DA ÚLTIMA ATUALIZAÇÃO',
      'QUANTIDADE DE LEITOS',
      'QUANTIDADE DE PROFISSIONAIS',
      'TIPO DE UNIDADE',
    ];

    const headersHeavyVehicleInfo = [
      'CNPJ',
      'FANTASY NAME',
      'REGISTRO ANTT',
      'PLACA',
      'COMBUSTÍVEL',
      'MODELO',
      'ANO DE FABRICAÇÃO',
      'RENAVAM',
      'TIPO',
      'UF',
    ];

    const headersIBGE = [
      'CNPJ',
      'FANTASY NAME',
      'ESTATÍSTICAS ECÔNOMICAS - IDHM',
      'ESTATÍSTICAS ECÔNOMICAS - ANO DO IDHM',
      'ESTATÍSTICAS ECÔNOMICAS - PERCENTUAL DE FONTES DE RECEITA',
      'ESTATÍSTICAS ECÔNOMICAS - ANO DO PERCENTUAL DE FONTES DE RECEITA',
      'ESTATÍSTICAS ECÔNOMICAS - PIB',
      'ESTATÍSTICAS ECÔNOMICAS - ANO DO PIB',
      'ESTATÍSTICAS ECÔNOMICAS - TOTAL DE DESPEDAS',
      'ESTATÍSTICAS ECÔNOMICAS - ANO DO TOTAL DE DESPEDAS',
      'ESTATÍSTICAS ECÔNOMICAS - TOTAL DE RECEITAS',
      'ESTATÍSTICAS ECÔNOMICAS - ANO DO TOTAL DE RECEITAS',
      'ESTATÍSTICAS GEOGRÁFICAS - DENSIDADE DEMOGRÁFICA',
      'ESTATÍSTICAS GEOGRÁFICAS - ANO DA DENSIDADE DEMOGRÁFICA',
      'ESTATÍSTICAS GEOGRÁFICAS - POPULAÇÃO ESTIMADA',
      'ESTATÍSTICAS GEOGRÁFICAS - ANO DA POPULAÇÃO ESTIMADA',
      'ESTATÍSTICAS GEOGRÁFICAS - POPULAÇÃO ESTIMADA NO ÚLTIMO CENSO',
      'ESTATÍSTICAS GEOGRÁFICAS - ANO DA POPULAÇÃO ESTIMADA NO ÚLTIMO CENSO',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - SALÁRIO MÉDIO',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DO SALÁRIO MÉDIO',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - PESSOAS OCUPADAS',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DO PESSOAS OCUPADAS',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - POPULAÇÃO OCUPADA',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DA POPULAÇÃO OCUPADA',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - RENDA MENSAL NOMINAL POR PESSOA',
      'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DA RENDA MENSAL NOMINAL POR PESSOA',
      'INFORMAÇÕES DA MATRIZ - CNPJ',
      'INFORMAÇÕES DA MATRIZ - MUNÍCIPIO',
      'INFORMAÇÕES DA MATRIZ - DATA DE ABERTURA',
      'INFORMAÇÕES DA MATRIZ - RAZÃO SOCIAL',
      'INFORMAÇÕES DA MATRIZ - UF',
    ];

    const headersInpiBrands = [
      'CNPJ',
      'FANTASY NAME',
      'MARCA REGISTRADA',
      'CLASSE DA MARCA REGISTRADA',
      'DATA DO DEPÓSITO',
      'NÚMERO DO PROCESSO',
      'SITUAÇÃO',
    ];

    const headersInpiPatents = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE CONCESSÃO',
      'DATA DE DEPÓSITO',
      'DEPOSITANTE',
      'INVENTORES',
      'NÚMERO DO PROCESSO',
      'PROCURADOR',
      'DATA DE PUBLICAÇÃO',
      'TÍTULO',
    ];

    const headersInpiSoftwares = [
      'CNPJ',
      'FANTASY NAME',
      'AUTORES',
      'DATA DE DEPÓSITO',
      'NÚMERO DO PROCESSO',
      'PROCURADOR',
      'TÍTULO',
    ];

    const headersInternationalList = [
      'CNPJ',
      'FANTASY NAME',
      'NOME DA LISTA RELACIONADA',
      'DATA DE CONSULTA',
    ];

    const headersContract = [
      'CNPJ',
      'FANTASY NAME',
      'NÚMERO DO CONTRATO',
      'DATA DE INÍCIO',
      'DATA DE TÉRMINO',
      'VALOR DO CONTRATO',
      'MESES DE VALIDADE',
      'ÓRGÃO RESPONSÁVEL',
      'ESFERA',
      'UF',
    ];

    const headersContractModality = ['CNPJ', 'FANTASY NAME', 'QUANTIDADE', 'TIPO'];

    const headersActionsPositions = [
      'CNPJ',
      'FANTASY NAME',
      'DOCUMENTO',
      'VALOR DAS AÇÕES EM ABERTO DO TIPO ORDINARAS',
      'VALOR DAS AÇÕES EM ABERTO DO TIPO PREFERENCIAIS',
      'VALOR TOTAL DAS AÇÕES EM ABERTO',
    ];

    const headersPatrimonialBalance = [
      'CNPJ',
      'FANTASY NAME',
      'TOTAL DE ATIVOS',
      'VALOR DOS ATIVOS',
      'PATRIMÔNIO LÍQUIDO',
      'PATRIMÔNIO LÍQUIDO CONTROLADO',
      'ANO DO BALANÇO PATRIMONIAL',
    ];

    const headersPenaltyOC = [
      'CNPJ',
      'FANTASY NAME',
      'DATA DE CRIAÇÃO PENALIDADE',
      'RAZÃO DA PENALIDADE',
    ];

    const headersDemonstrationsResultsOC = [
      'CNPJ',
      'FANTASY NAME',
      'PERÍODO DE BENEFÍCIO EM CAPITAL ABERTO',
      'PERÍODO DE BENEFÍCIO CONTROLADOR EM CAPITAL ABERTO',
      'RECEITA DE VENDAS EM CAPITAL ABERTO',
      'RESULTADO BRUTO EM CAPITAL ABERTO',
      'RESULTADO FINANCEIRO EM CAPITAL ABERTO',
      'RESULTADO DE OPERAÇÕES LÍQUIDAS EM CAPITAL ABERTO',
      'RESULTADO DE EQUIVALÊNCIA PATRIMIONIAL EM CAPITAL ABERTO',
      'ANO DE DEMONSTRAÇÃO DE RESULTADOS EM CAPITAL ABERTO',
    ];

    const headersPatMeal = [
      'CNPJ',
      'FANTASY NAME',
      'QUANTIDADE DE REFEIÇÕES FORNECIDAS',
      'TIPO DE REFEIÇÃO',
    ];

    const headersPatModality = [
      'CNPJ',
      'FANTASY NAME',
      'MODALIDE',
      'NÚMERO DE FUNCIONÁRIOS BENEFICIADOS PELA MODALIDADE',
      'NÚMERO DE FUNCIONÁRIOS QUE RECEBEM MAIS DE 5 SALÁRIOS MÍNIMOS NA MODALIDADE PAT',
      'CNPJ DO FORNECEDOR NA MODALIDADE PAT',
      'RAZÃO SOCIAL DO FORNECEDOR NA MODALIDADE PAT',
      'NÚMERO DE FUNCIONÁRIOS QUE RECEBEM ATÉ 5 SALÁRIOS MÍNIMOS NA MODALIDADE PAT',
    ];

    const headersProconGroups = [
      'CNPJ',
      'FANTASY NAME',
      'CNPJ DO GRUPO DO PROCON',
      'VALOR TOTAL DA PENALIDADE DO GRUPO DO PROCON',
    ];

    const headersProconProcess = [
      'CNPJ',
      'FANTASY NAME',
      'VALOR DA PENALIDADE DO PROCESSO DO PROCON',
      'NÚMERO DO PROCESSO DO PROCON',
      'STATUS DO PROCESSO DO PROCON',
    ];

    const headersPropertys = [
      'CNPJ',
      'FANTASY NAME',
      'DADOS DO IMÓVEL',
      'ÁREA CONSTRUÍDA',
      'COMPLEMENTO',
      'VALOR DE AVALIAÇÃO',
      'ÁREA DO TERRENO',
      'MUNICÍPIO',
      'BAIRRO',
      'NÚMERO DO BAIRRO',
      'UF DO REGISTRO',
      'NÚMERO DO REGISTRO',
      'RUA',
      'UF',
      'CEP',
    ];

    const headersSchools = [
      'CNPJ',
      'FANTASY NAME',
      'CATEGORIA',
      'STATUS DE FUNCIONAMENTO',
      'LINK DO SITE',
      'NOME',
      'TOTAL DE MAATRÍCULAS',
    ];

    const headersSintegra = [
      'CNPJ',
      'FANTASY NAME',
      'NÚMERO DA INSCRIÇÃO',
      'SITUAÇÃO DA INSCRIÇÃO',
      'DATA DA SITUAÇÃO DA INSCRIÇÃO',
      'REGIME',
      'EMAIL',
      'TELEFONE',
      'UF',
    ];

    const headersTaxHealthCnds = [
      'CNPJ',
      'FANTASY NAME',
      'NÚMERO DO CERTIFICADO',
      'DATA DE CRIAÇÃO',
      'A EXTENSÃO',
      'NOME',
      'TIPO',
      'URL',
      'NOME DO EMISSOR',
      'DATA DA EMISSÃO',
      'DATA DA EXPEDIÇÃO',
      'SITUAÇÃO',
    ];

    const headersWorkerMTE = [
      'CNPJ',
      'FANTASY NAME',
      'COMPLEMENTO',
      'ANO DA AÇÃO FISCAL DO MINISTÉRIO DO TRABALHO',
      'MUNICÍPIO ONDE OCORREU AÇÃO FISCAL',
      'BAIRRO ONDE OCORREU AÇÃO FISCAL',
      'DATA DE DECISÃO DE PROCEDÊNCIA DA AÇÃO FISCAL',
      'QUANTIDADE DE TRABALHADORES ENVOLVIDOS NA AÇÃO FISCAL',
      'UF',
    ];

    // Configurando relacao key - header pra cada table
    const applyHeaders = (sheet: ExcelJS.Worksheet, headers: string[]) => {
      sheet.columns = headers.map(header => ({
        header,
        key: header,
      }));
    };

    applyHeaders(tableWorksheet, headersTable);
    applyHeaders(adressWorksheet, headersAddress);
    applyHeaders(othersCnaeWorksheet, headersOthersCnae);
    applyHeaders(physicalProductionWorksheet, headersPhysicalProduction);
    applyHeaders(judicialProcessWorksheet, headersJudicalProcess);
    applyHeaders(partnersWorksheet, headersPartners);
    applyHeaders(partnerPersonWorksheet, headersPersonPartner);
    applyHeaders(partnerPersonAddressWorksheet, headersPersonPartnerAddress);
    applyHeaders(affiliatesWorksheet, headersAffiliates);
    applyHeaders(aircraftWorksheet, headersAircrafts);
    applyHeaders(fuelStationEquipamentsWorksheet, headersFuelStationEquipaments);
    applyHeaders(fuelStationFlagsWorksheet, headersFuelStationFlags);
    applyHeaders(fuelStationWorksheet, headersFuelStation);
    applyHeaders(anttWorksheet, headersAntt);
    applyHeaders(anvisaProcessWorkSheet, headersAnvisaProcess);
    applyHeaders(bankOperationsWorkSheet, headersBankOperations);
    applyHeaders(beneficiariesWorksheet, headersBeneficiaries);
    applyHeaders(cafirWorksheet, headersCafir);
    applyHeaders(ceisWorksheet, headersCeis);
    applyHeaders(cepimsWorksheet, headersCepims);
    applyHeaders(cfcWorksheet, headersCfc);
    applyHeaders(cnepsWorksheet, headersCenep);
    applyHeaders(cnjCniasWorksheet, headersCnjCnias);
    applyHeaders(companysRelatedWorksheet, headersCompanysRelated);
    applyHeaders(coursesEmecWorksheet, headersCoursesEmec);
    applyHeaders(criCraDebenturesWorksheet, headersCriCraDebentures);
    applyHeaders(crSefWorksheet, headersCrSef);
    applyHeaders(cvmRegistrationWorksheet, headersCvmRegistration);
    applyHeaders(debitMteProcessWorksheet, headersDebitMteProcess);
    applyHeaders(debitsPgfnDauWorksheet, headersDebitsPgfnDau);
    applyHeaders(documentsWorksheet, headersDocuments);
    applyHeaders(domainsWorksheet, headersDomains);
    applyHeaders(employeedGrowthsWorksheet, headersEmployeedGrowths);
    applyHeaders(employeedsWorksheet, headersEmployeeds);
    applyHeaders(environmentalLicensesWorksheet, headersEnvironmentalLicenses);
    applyHeaders(exEmployeedsWorksheet, headersEmployeeds);
    applyHeaders(exportationsWorksheet, headersExportationsImportations);
    applyHeaders(financialIndicatorsWorksheet, headersFinancialIndicators);
    applyHeaders(healthEstablishmentsWorksheet, headersHealthEstablishment);
    applyHeaders(heavyVehicleInfoWorksheet, headersHeavyVehicleInfo);
    applyHeaders(ibgeWorksheet, headersIBGE);
    applyHeaders(importationsWorksheet, headersExportationsImportations);
    applyHeaders(inpiBrandsWorksheet, headersInpiBrands);
    applyHeaders(inpiPatentsWorksheet, headersInpiPatents);
    applyHeaders(inpiSoftwaresWorksheet, headersInpiSoftwares);
    applyHeaders(internationalListWorksheet, headersInternationalList);
    applyHeaders(contractWorksheet, headersContract);
    applyHeaders(contractModalitytWorksheet, headersContractModality);
    applyHeaders(actionPositionsWorksheet, headersActionsPositions);
    applyHeaders(patrimonialBalanceWorksheet, headersPatrimonialBalance);
    applyHeaders(penaltysOCWorksheet, headersPenaltyOC);
    applyHeaders(demonstrationResultsOCWorksheet, headersDemonstrationsResultsOC);
    applyHeaders(mealPatWorksheet, headersPatMeal);
    applyHeaders(modalitysPatWorksheet, headersPatModality);
    applyHeaders(proconGroupsWorksheet, headersProconGroups);
    applyHeaders(proconProcessWorksheet, headersProconProcess);
    applyHeaders(propertiesWorksheet, headersPropertys);
    applyHeaders(schoolsWorksheet, headersSchools);
    applyHeaders(sintegraWorksheet, headersSintegra);
    applyHeaders(taxHealthCNDSWorksheet, headersTaxHealthCnds);
    applyHeaders(workMTEsWorksheet, headersWorkerMTE);

    // Aplicando a cor de fundo azul para a primeira linha (headers)
    const applyStyle = (sheet: ExcelJS.Worksheet) => {
      sheet.getRow(1).eachCell({ includeEmpty: true }, cell => {
        cell.fill = titleCell;
        cell.font = { bold: true, color: { argb: 'FFFFFF' } };
      });
    };

    applyStyle(tableWorksheet);
    applyStyle(adressWorksheet);
    applyStyle(othersCnaeWorksheet);
    applyStyle(physicalProductionWorksheet);
    applyStyle(judicialProcessWorksheet);
    applyStyle(partnersWorksheet);
    applyStyle(partnerPersonWorksheet);
    applyStyle(partnerPersonAddressWorksheet);
    applyStyle(affiliatesWorksheet);
    applyStyle(aircraftWorksheet);
    applyStyle(fuelStationEquipamentsWorksheet);
    applyStyle(fuelStationFlagsWorksheet);
    applyStyle(fuelStationWorksheet);
    applyStyle(anttWorksheet);
    applyStyle(anvisaProcessWorkSheet);
    applyStyle(bankOperationsWorkSheet);
    applyStyle(beneficiariesWorksheet);
    applyStyle(cafirWorksheet);
    applyStyle(ceisWorksheet);
    applyStyle(cepimsWorksheet);
    applyStyle(cfcWorksheet);
    applyStyle(cnepsWorksheet);
    applyStyle(cnjCniasWorksheet);
    applyStyle(companysRelatedWorksheet);
    applyStyle(coursesEmecWorksheet);
    applyStyle(criCraDebenturesWorksheet);
    applyStyle(crSefWorksheet);
    applyStyle(cvmRegistrationWorksheet);
    applyStyle(debitMteProcessWorksheet);
    applyStyle(debitsPgfnDauWorksheet);
    applyStyle(documentsWorksheet);
    applyStyle(domainsWorksheet);
    applyStyle(employeedGrowthsWorksheet);
    applyStyle(employeedsWorksheet);
    applyStyle(environmentalLicensesWorksheet);
    applyStyle(exEmployeedsWorksheet);
    applyStyle(exportationsWorksheet);
    applyStyle(financialIndicatorsWorksheet);
    applyStyle(healthEstablishmentsWorksheet);
    applyStyle(heavyVehicleInfoWorksheet);
    applyStyle(ibgeWorksheet);
    applyStyle(importationsWorksheet);
    applyStyle(inpiBrandsWorksheet);
    applyStyle(inpiPatentsWorksheet);
    applyStyle(inpiSoftwaresWorksheet);
    applyStyle(internationalListWorksheet);
    applyStyle(contractWorksheet);
    applyStyle(contractModalitytWorksheet);
    applyStyle(actionPositionsWorksheet);
    applyStyle(patrimonialBalanceWorksheet);
    applyStyle(penaltysOCWorksheet);
    applyStyle(demonstrationResultsOCWorksheet);
    applyStyle(mealPatWorksheet);
    applyStyle(modalitysPatWorksheet);
    applyStyle(proconGroupsWorksheet);
    applyStyle(proconProcessWorksheet);
    applyStyle(propertiesWorksheet);
    applyStyle(schoolsWorksheet);
    applyStyle(sintegraWorksheet);
    applyStyle(taxHealthCNDSWorksheet);
    applyStyle(workMTEsWorksheet);

    // COMEÇA A ATRIBUIR OS DADOS PRA CADA TABLE
    dataStorage?.forEach(async BD => {
      const rowTable = {};

      const company = BD.bigData.company;
      const mainCnae = BD.mainCnae;
      const judicialProcess = BD.judicialProcess;
      const fuelStation = BD.bigData.company.anpFuelStation;
      const anvisa = BD.bigData.company.anvisa;
      const cadastur = BD.bigData.company.cadastur;
      const cvm = BD.bigData.company.cvm;
      const debitMte = BD.bigData.company.debitMte;
      const financialActivity = BD.bigData.company.financialActivity;
      const foreignCommerce = BD.bigData.company.foreignCommerce;
      const heavyVehicle = BD.bigData.company.heavyVehicleInfo;
      const ibamaCnd = BD.bigData.company.ibamaCnd;
      const contract = BD.bigData.company.managementContract;
      const maxtrixInfo = BD.bigData.company.matrixInfo;
      const openCapital = BD.bigData.company.openCapital;
      const pat = BD.bigData.company.pat;
      const procon = BD.bigData.company.procon;
      const simpleNational = BD.bigData.company.simpleNational;
      const suframa = BD.bigData.company.suframa;
      const taxhealth = BD.bigData.company.taxHealth;

      // PREENCHENDO DADOS TABLE
      rowTable['CNPJ'] = BD.cnpj;
      rowTable['RAZÃO SOCIAL'] = BD.socialReason;
      rowTable['NOME FANTASIA'] = BD.fantasyName;
      rowTable['DATA DE ABERTURA'] = getDatePtBr(BD.openingDate ?? '');
      rowTable['CAPITAL SOCIAL'] = getCurrencyPtBr(BD.socialCapital ?? 0);
      rowTable['CÓDIGO JURÍDICO'] = BD.legalNatureCode;
      rowTable['DESCRIÇÃO JURÍDICA'] = BD.legalNatureDescription;
      rowTable['EMAIL FINANCEIRO'] = BD.rfEmail;
      rowTable['TELEFONES DE CONTATO'] = joinArrayString(BD?.phones ?? []);
      rowTable['NÍVEL DE ATIVIDADE'] = company.activityLevel;
      rowTable['IDADE DA EMPRESA'] = company.age.toString();
      rowTable['DATA DE ÚLTIMA CONSULTA BNDES'] = getDatePtBr(
        company.bndesLastConsultDate ?? '',
      );
      rowTable['PROPENSÃO DE FECHAMENTO DA EMPRESA'] =
        company.companyClosingPropensity;
      rowTable['TAMANHO DA EMPRESA'] = company.companySize;
      rowTable['SITUAÇÃO INDIVIDUAL COVID-19'] = company.covid19Individual;
      rowTable['SEGMENTO COVID-19'] = company.covid19Segment;
      rowTable['DATA DA CRIAÇÃO'] = getDatePtBr(company.created ?? '');
      rowTable['DATA DA SITUAÇÃO DE REGISTRO'] = getDatePtBr(
        company.dateRegistrationSituation ?? '',
      );
      rowTable['SITUAÇÃO ESPECIAL DA EMPRESA'] = company.specialSituation;
      rowTable['DATA DE SITUAÇÃO ESPECIAL'] = getDatePtBr(
        company.dateSpecialSituation ?? '',
      );
      rowTable['PROPENSÃO DE ENTREGA'] = company.deliveryPropensity;
      rowTable['PROPENSÃO DE ECOMMERCE'] = company.ecommercePropensity?.toString();
      rowTable['NÚMERO DE ANALISTAS'] = company.employeeAnalystCount.toString();
      rowTable[
        'NÚMERO DE COLABORADORES DE BASE'
      ] = company.employeeBaseCount.toString();
      rowTable['NÚMERO DE COMPRADORES'] = company.employeeBuyerCount.toString();
      rowTable['NÚMERO DE MÉDICOS'] = company.employeeDoctorCount.toString();
      rowTable['NÚMERO DE ENGENHEIROS'] = company.employeeEngineerCount.toString();
      rowTable['NÚMERO DE ADVOGADOS'] = company.employeeLawyerCount.toString();
      rowTable['NÚMERO DE GERENTES'] = company.employeeManagerCount.toString();
      rowTable['OUTRAS CONTAGENS'] = company.employeeOtherCount.toString();
      rowTable['NÚMERO DE PDVs'] = company.employeePdvCount.toString();
      rowTable['NÚMERO DE VENDEDORES'] = company.employeeSellerCount.toString();
      rowTable[
        'NÚMERO DE SUPERVISORES'
      ] = company.employeeSupervisorCount.toString();
      rowTable['NÚMERO DE PROFESSORES'] = company.employeeTeacherCount.toString();
      rowTable['VALOR ESTIMADO DE FATURAMENTO'] = company.estimatedBilling;
      rowTable['GRUPO DE FATURAMENTO ESTIMADO'] = company.estimatedBillingGroup;
      rowTable['NOME DA FRANQUIA'] = company.franchiseName;
      rowTable['FATURAMENTO BRUTO DA EMPRESA'] = getCurrencyPtBr(
        company.grossBilling ?? 0,
      );
      rowTable['GRUPO MULTINACIONAL'] = company.groupMultinational ? 'Sim' : 'Não';
      rowTable['CONTATO DE CONTADOR DISPONÍVEL'] = company.hasAccountantContact
        ? 'Sim'
        : 'Não';
      rowTable[
        'QUADRO DE SÓCIOS E ADMINISTRADORES DIVERGENTES'
      ] = company.hasDivergentQSA ? 'Sim' : 'Não';
      rowTable['INDICATIVO DE FRANQUIA'] = company.hasFranchiseIndicative
        ? 'Sim'
        : 'Não';
      rowTable['DATA DA ÚLIMA CONSULTA IBGE'] = getDatePtBr(
        company.ibgeLastConsultDate ?? '',
      );
      rowTable[
        'DATA E HORA DA ULTIMA ATUALIZAÇÃO DOS DADOS DA EMPRESA'
      ] = getDatePtBr(company.lastUpdate ?? '', true);
      rowTable['EMPRESA MATRIZ'] = company.matrix ? 'Sim' : 'Não';
      rowTable['EMPRESA MULTINACIONAL'] = company.multinational ? 'Sim' : 'Não';
      rowTable['PÁIS DE ORIGEM'] = company.originCountry;
      rowTable['PASSIVEL DE IISS'] = company.passiveIISS ? 'Sim' : 'Não';
      rowTable[
        'QUANTIDADE DE FILIAIS ATIVAS'
      ] = company.quantityActiveBranches.toString();
      rowTable['QUANTIDADE DE COLABORADORES'] = company.quantityEmployee.toString();
      rowTable[
        'QUANTIDADE DE EX COLABORADORES'
      ] = company.quantityExEmployee.toString();
      rowTable['MOTIVO DE SITUAÇÃO DE REGISTRO DA EMPRESA'] =
        company.registrationSituationReason;
      rowTable['CAPACIDADE DE TRABALHO REMOTO'] = company.remoteWorkingCapacity;
      rowTable['DATA E HORA DA ÚLTIMA CONSULTA SIDRA'] = getDatePtBr(
        company.sidraLastConsultDate ?? '',
        true,
      );
      rowTable['CNAE - CÓDIGO'] = mainCnae.code;
      rowTable['CNAE - DESCRIÇÃO'] = mainCnae.description;
      rowTable['CNAE - ATIVIDADE PRINCIPAL'] = mainCnae.businessActivity;
      rowTable['CNAE - SETOR'] = mainCnae.sector;
      rowTable['VALOR TOTAL DE OUTROS PROCESSOS JUDICIAIS'] = getCurrencyPtBr(
        judicialProcess?.totalOthersValue ?? 0,
      );
      rowTable['VALOR TOTAL DE PROCESSOS JUDICIAIS ATIVOS'] = getCurrencyPtBr(
        judicialProcess?.totalActiveValue ?? 0,
      );
      rowTable[
        'VALOR TOTAL DE PROCESSOS JUDICIAIS COMO PARTE PASSIVA'
      ] = getCurrencyPtBr(judicialProcess?.totalPassiveValue ?? 0);
      rowTable['VALOR TOTAL DE TODOS OS PROCESSOS JUDICIAIS'] = getCurrencyPtBr(
        judicialProcess?.totalValue ?? 0,
      );
      rowTable['BANDEIRA ATUAL'] = fuelStation?.currentFlag;
      rowTable['TIPO DE POSTO'] = fuelStation?.postType;
      rowTable['AUTORIZAÇÃO DO POSTO'] = fuelStation?.stationAuthorization;
      rowTable['TOTAL DE BICOS'] = fuelStation?.totalSpikes.toString();
      rowTable['TOTAL DE TANQUES'] = fuelStation?.totalTank.toString();
      rowTable['ANVISA - REPRESENTANTES LEGAIS'] = joinArrayString(
        anvisa?.legalRepresentatives ?? [],
      );
      rowTable['ANVISA - REPRESENTANTES TÉCNICOS'] = joinArrayString(
        anvisa?.technicalRepresentatives ?? [],
      );
      rowTable['CADASTUR - NÚMERO DO CERTIFICADO'] = cadastur?.certificateNumber;
      rowTable['CADASTUR - LISTA DE IDIOMAS'] = joinArrayString(
        cadastur?.idioms ?? [],
      );
      rowTable[
        'CADASTUR - NÚMERO DE VEÍCULOS'
      ] = cadastur?.numberOfVehicles.toString();
      rowTable['CADASTUR - CADASTRADO'] = cadastur?.registered ? 'Sim' : 'Não';
      rowTable['CADASTUR - LISTA DE SEGMENTOS'] = joinArrayString(
        cadastur?.segments ?? [],
      );
      rowTable['CADASTUR - LISTA DE SERVIÇOS'] = joinArrayString(
        cadastur?.services ?? [],
      );
      rowTable['CADASTUR - SITE DO ESTABELECIMENTO'] = cadastur?.site;
      rowTable['CADASTUR - SITUAÇÃO CADASTUR'] = cadastur?.situation;
      rowTable['CADASTUR - UF'] = cadastur?.uf;
      rowTable['CVM - ANO DE BALANÇO'] = cvm?.balanceYear.toString();
      rowTable['CVM - LUCRO BRUTO'] = getCurrencyPtBr(cvm?.grossProfit ?? 0);
      rowTable['CVM - RECEITA BRUTA'] = getCurrencyPtBr(cvm?.grossRevenue ?? 0);
      rowTable['CVM - ATIVOS LÍQUIDOS'] = getCurrencyPtBr(cvm?.liquidAssets ?? 0);
      rowTable['CVM - FATURAMENTO LÍQUIDO'] = getCurrencyPtBr(
        cvm?.liquidBilling ?? 0,
      );
      rowTable['CVM - LUCRO LÍQUIDO'] = getCurrencyPtBr(cvm?.liquidProfit ?? 0);
      rowTable['CVM - ATIVOS TOTAIS'] = getCurrencyPtBr(cvm?.totalActive ?? 0);
      rowTable['DÉBITOS MTE - TIPO DE CERTIFICADO'] = debitMte?.certificateType;
      rowTable['DÉBITOS MTE - CÓDIGO DA EMPRESA'] = debitMte?.code;
      rowTable['DÉBITOS MTE - SITUAÇÃO DO DÉBITO'] = debitMte?.debitSituation;
      rowTable['DÉBITOS MTE - DATA DE EMISSÃO'] = getDatePtBr(
        debitMte?.emissionDate ?? '',
      );
      rowTable['DÉBITOS PGFN & DAU - TOTAL DE DÉBITOS'] = getCurrencyPtBr(
        BD.bigData.company.debitPgfnDau?.totalDebits ?? 0,
      );
      rowTable['ATIVIDADE FINANCEIRA - DATA DA HABILITAÇÃO'] = getDatePtBr(
        financialActivity?.enablementDate ?? '',
      );
      rowTable['ATIVIDADE FINANCEIRA - NÚMERO DA HABILITAÇÃO'] =
        financialActivity?.enablementNumber;
      rowTable['ATIVIDADE FINANCEIRA - SITUAÇÃO DA HABILITAÇÃO'] =
        financialActivity?.enablementSituation;
      rowTable['ATIVIDADE FINANCEIRA - DATA DA CONSULTA'] = getDatePtBr(
        financialActivity?.queryDate ?? '',
      );
      rowTable['ATIVIDADE FINANCEIRA - SEGMENTO'] = financialActivity?.segment;
      rowTable['COMÉRCIO EXTERIOR - OPERAÇÕES AUTORIZADAS'] =
        foreignCommerce?.authorizedOperations;
      rowTable[
        'COMÉRCIO EXTERIOR - COMÉRCIO EXTERIOR HABILITADO'
      ] = foreignCommerce?.enabled ? 'Sim' : 'Não';
      rowTable['COMÉRCIO EXTERIOR - SITUAÇÃO DE COMÉRCIO EXTERIOR HABILITADO'] =
        foreignCommerce?.enabledSituation;
      rowTable['COMÉRCIO EXTERIOR - MODALIDADE'] = foreignCommerce?.modality;
      rowTable['COMÉRCIO EXTERIOR - DATA DA SITUAÇÃO'] = getDatePtBr(
        foreignCommerce?.situationDate ?? '',
      );
      rowTable['COMÉRCIO EXTERIOR - SUBMODALIDADE'] = foreignCommerce?.submodality;
      rowTable[
        'VEÍCULOS PESADOS - ATÉ 1 TONELADA'
      ] = heavyVehicle?.upto1.toString();
      rowTable[
        'VEÍCULOS PESADOS - ACIMA DE 10 TONELADAS'
      ] = heavyVehicle?.over10.toString();
      rowTable[
        'VEÍCULOS PESADOS - ENTRE 2 E 5 TONELADAS'
      ] = heavyVehicle?.between2And5.toString();
      rowTable[
        'VEÍCULOS PESADOS - ENTRE 5 E 10 TONELADAS'
      ] = heavyVehicle?.between5And10.toString();
      rowTable[
        'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ATÉ 1 TONELADA'
      ] = heavyVehicle?.groupUpTo1.toString();
      rowTable[
        'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ACIMA DE 10 TONELADAS'
      ] = heavyVehicle?.groupOver10.toString();
      rowTable[
        'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ENTRE 2 E 5 TONELADAS'
      ] = heavyVehicle?.groupBetween2And5.toString();
      rowTable[
        'VEÍCULOS PESADOS - GRUPO DE VEÍCULOS ENTRE 5 E 10 TONELADAS'
      ] = heavyVehicle?.groupBetween5And10.toString();
      rowTable[
        'VEÍCULOS PESADOS - TOTAL DE VEÍCULOS'
      ] = heavyVehicle?.heavyVehicles?.toString();
      rowTable[
        'VEÍCULOS PESADOS - GRUPO DE NÚMERO DE VEÍCULOS PESADOS'
      ] = heavyVehicle?.heavyVehiclesGroup.toString();
      rowTable['IBAMA CND - NÚMERO DE CERTIFICADO DE REGULARIDADE'] =
        ibamaCnd?.certificateNumber;
      rowTable['IBAMA CND - DATA DE EMISSÃO'] = getDatePtBr(
        ibamaCnd?.emitDate ?? '',
      );
      rowTable['IBAMA CND - SITUAÇÃO'] = ibamaCnd?.situation;
      rowTable['IBAMA CND - DATA DE VALIDADE'] = getDatePtBr(
        ibamaCnd?.validDate ?? '',
      );
      rowTable[
        'GERENCIAMENTO DE CONTRATO - QUANTIDADE TOTAL'
      ] = contract?.quantity?.toString();
      rowTable[
        'GERENCIAMETNO DE CONTRATO - VALOR TOTAL'
      ] = contract?.totalValue?.toString();
      rowTable['INFORMAÇÕES DA MATRIZ - CNPJ'] = maxtrixInfo?.cnpj;
      rowTable['INFORMAÇÕES DA MATRIZ - MUNÍCIPIO'] = maxtrixInfo?.municipality;
      rowTable['INFORMAÇÕES DA MATRIZ - DATA DE ABERTURA'] = getDatePtBr(
        maxtrixInfo?.openingDate ?? '',
      );
      rowTable['INFORMAÇÕES DA MATRIZ - RAZÃO SOCIAL'] = maxtrixInfo?.socialReason;
      rowTable['INFORMAÇÕES DA MATRIZ - UF'] = maxtrixInfo?.uf;
      rowTable[
        'CAPITAL ABERTO - COMPLEMENTO DO LOGRADOURO DO DIRETO DE INVESTIMENTOS'
      ] = openCapital?.complementLogradouroInvestmentDirector;
      rowTable['CAPITAL ABERTO - E-MAIL DO DIRETO DE INVESTIMENTOS'] =
        openCapital?.emailInvestmentDirector;
      rowTable['CAPITAL ABERTO - LOGRADOURO DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.logradouroInvestmentDirector;
      rowTable['CAPITAL ABERTO - ATIVIDADE PRINCIPAL DA EMPRESA'] =
        openCapital?.mainActivity;
      rowTable['CAPITAL ABERTO - MUNICÍPIO DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.municipalityInvestmentDirector;
      rowTable['CAPITAL ABERTO - NOME DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.nameInvestmentDirector;
      rowTable['CAPITAL ABERTO - CÓDIGO DE NEGOCIAÇÃO DA EMPRESA'] =
        openCapital?.negotiationCode;
      rowTable['CAPITAL ABERTO - NÚMERO DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.numberInvestmentDirector;
      rowTable['CAPITAL ABERTO - NÚMERO DO TELEFONE DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.phoneInvestmentDirector;
      rowTable['CAPITAL ABERTO - CLASSIFICAÇÃO SETORIAL'] =
        openCapital?.sectorialClassification;
      rowTable['CAPITAL ABERTO - SITE DA EMPRESA'] = openCapital?.site;
      rowTable['CAPITAL ABERTO - CÓDIGO DO PREGÃO'] = openCapital?.tradingFloorCode;
      rowTable['CAPITAL ABERTO - UF DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.ufInvestmentDirector;
      rowTable['CAPITAL ABERTO - CEP DO DIRETOR DE INVESTIMENTOS'] =
        openCapital?.zipInvestmentDirector;
      rowTable[
        'PAT - NÚMERO DE FUNCIONÁRIOS BENEFICIADOS'
      ] = pat?.benefitedEmployees.toString();
      rowTable['PAT - DATA DO EXERCÍCIO'] = getDatePtBr(pat?.exerciseDate ?? '');
      rowTable['PAT - INSCRIÇÃO'] = pat?.inscription;
      rowTable['PAT - SITUAÇÃO DE REGISTRO'] = pat?.registrationSituation;
      rowTable['PAT - CPF DO RESPONSÁVEL'] = pat?.responsibleCpf;
      rowTable['PAT - E-MAIL DO RESPONSÁVEL'] = pat?.responsibleEmail;
      rowTable['PAT - NOME DO RESPONSÁVEL'] = pat?.responsibleName;
      rowTable['PROCON - VALOR TOTAL DE PENALIDADE DE GRUPO DO PROCON'] =
        procon?.groupPenaltyValue;
      rowTable['PROCON - NOME DO PROCON'] = procon?.name;
      rowTable['PROCON - VALOR TOTAL DAS PENALIDADES'] = getCurrencyPtBr(
        procon?.totalPenaltyValue ?? 0,
      );
      rowTable['PROCON - DATA DE ATUALIZAÇÃO'] = getDatePtBr(
        procon?.updateDate ?? '',
      );
      rowTable['SIMPLE NATIONAL - SIMEI'] = simpleNational?.simei ? 'Sim' : 'Não';
      rowTable['SIMPLE NATIONAL - DATA DE ADESÃO SIMEI'] = getDatePtBr(
        simpleNational?.simeiDate ?? '',
      );
      rowTable['SIMPLE NATIONAL - SIMPLES'] = simpleNational?.simple
        ? 'Sim'
        : 'Não';
      rowTable['SIMPLE NATIONAL - DATA DE ADESÃO SIMPLES'] = getDatePtBr(
        simpleNational?.simpleDate ?? '',
      );
      rowTable[
        'SIMPLE NATIONAL - SIMPLES REGULAR'
      ] = simpleNational?.simpleIrregular ? 'Sim' : 'Não';
      rowTable['SUFRAMA - DATA DE EXPIRAÇÃO'] = getDatePtBr(
        suframa?.expirationDate ?? '',
      );
      rowTable['SUFRAMA - PERCENTUAL DE ICMS'] = suframa?.icms;
      rowTable['SUFRAMA - PERCENTUAL IPI'] = suframa?.ipi;
      rowTable['SUFRAMA - PERCENTUAL PIS/COFINS'] = suframa?.pisCofins;
      rowTable['SUFRAMA - NÚMERO DO REGISTRO'] = suframa?.registrationNumber;
      rowTable['SUFRAMA - SITUAÇÃO DO REGISTRO'] = suframa?.registrationSituation;
      rowTable['INFORMAÇÕES RELACIONADA À SAÚDE FISCAL DA EMPRESA'] =
        taxhealth?.taxHealth;

      //rowTable[] = ;

      tableWorksheet.addRow(rowTable);

      // PREENCHENDO DADOS ENDEREÇO
      const generateRowsAddress = (
        address: KleverAddress,
        sheet: ExcelJS.Worksheet,
        cpf?: string,
      ) => {
        const rowAddress = {};
        rowAddress['CNPJ'] = BD.cnpj;
        rowAddress['FANTASY NAME'] = BD.fantasyName;
        rowAddress['CPF'] = cpf;
        rowAddress['ENDEREÇO 1'] = address.address1;
        rowAddress['ENDEREÇO 2'] = address.address2;
        rowAddress['MUNÍCIPIO DE FRONTEIRA'] = address.borderMunicipality;
        rowAddress['TIPO DE EDIFICAÇÃO'] = address.buildingType;
        rowAddress['CÓDIGO DO PÁIS'] = address.codeCountry;
        rowAddress['CÓDIGO DO MUNICÍPIO'] = address.codeMunicipality;
        rowAddress['EDIFÍCIO COLETIVO'] = address.collectiveBuilding
          ? 'Sim'
          : 'Não';
        rowAddress['PAÍS'] = address.country;
        rowAddress['RESTRIÇÃO DE ENTREGA'] = address.deliveryRestriction
          ? 'Sim'
          : 'Não';
        rowAddress['ENDEREÇO FORMATADO'] = address.formattedAddress;
        rowAddress['ÚLTIMO ENDEREÇO'] = address.latestAddress ? 'Sim' : 'Não';
        rowAddress['LATITUDE'] = address.latitude;
        rowAddress['LONGITUDE'] = address.longitude;
        rowAddress['MICROREGIÃO'] = address.microRegion;
        rowAddress['MACROREGIÃO'] = address.mregion;
        rowAddress['MUNICÍPIO'] = address.municipality;
        rowAddress['BAIRRO'] = address.neighborhood;
        rowAddress['NÚMERO'] = address.number;
        rowAddress['BAIRRO ORIGINAL'] = address.originalNeighborhood;
        rowAddress['PRECISÃO'] = address.precision;
        rowAddress['REGIÃO'] = address.region;
        rowAddress['ESTADO REGISTRADO'] = address.registryUf;
        rowAddress['ENDEREÇO RESIDENCIAL'] = address.residentialAddress
          ? 'Sim'
          : 'Não';
        rowAddress['TELEFONES RESIDENCIAIS'] = joinArrayString(
          address.rfPhones ?? [],
        );
        rowAddress['ESTADO'] = address.uf;
        rowAddress['CEP'] = address.zip;

        sheet.addRow(rowAddress);
      };

      const address = BD.address;
      generateRowsAddress(address, adressWorksheet);

      // PREENCHENDO DADOS OUTROS CNAES DA EMPRESA
      const rowOthersCnae = {};
      const cnaes = BD.cnaes;

      cnaes?.map(cnae => {
        rowOthersCnae['CNPJ'] = BD.cnpj;
        rowOthersCnae['FANTASY NAME'] = BD.fantasyName;
        rowOthersCnae['CNAE - CÓDIGO'] = cnae.code;
        rowOthersCnae['CNAE - DESCRIÇÃO'] = cnae.description;
        rowOthersCnae['CNAE - ATIVIDADE PRINCIPAL'] = cnae.businessActivity;
        rowOthersCnae['CNAE - SETOR'] = cnae.sector;

        othersCnaeWorksheet.addRow(rowOthersCnae);
      });

      // PREENCHENDO DADOS OUTROS CNAES DA EMPRESA
      const rowPhyProd = {};
      const physicalProductions = BD.mainCnae.physicalProductions;

      physicalProductions?.map(phyProd => {
        rowPhyProd['CNPJ'] = BD.cnpj;
        rowPhyProd['FANTASY NAME'] = BD.fantasyName;
        rowPhyProd['CÓDIGO'] = phyProd.codeDescription;
        rowPhyProd['DATA'] = getDatePtBr(phyProd.date ?? '');
        rowPhyProd['NÍVEL TERRITORIAL DAS PRODUÇÕES FÍSICAS DO CNAE PRINCIPAL'] =
          phyProd.territorialLevel;
        rowPhyProd['ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - UNIDADE DE MEDIDA'] =
          phyProd.fixedBaseIndexWithoutSeasonalAdjustment?.measureUnit;
        rowPhyProd['ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - NOME'] =
          phyProd.fixedBaseIndexWithoutSeasonalAdjustment?.name;
        rowPhyProd['ÍNDICE DE BASE FIXA SEM AJUSTE SAZONAL - VALOR'] =
          phyProd.fixedBaseIndexWithoutSeasonalAdjustment?.value;
        rowPhyProd['ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - UNIDADE DE MEDIDA'] =
          phyProd.indexAccumulatedLast12Months?.measureUnit;
        rowPhyProd['ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - NOME'] =
          phyProd.indexAccumulatedLast12Months?.name;
        rowPhyProd['ÍNDICE ACUMULADO NOS ÚLTIMOS 12 MESES - VALOR'] =
          phyProd.indexAccumulatedLast12Months?.value;
        rowPhyProd['ÍNDICE MENSAL - UNIDADE DE MEDIDA'] =
          phyProd.monthlyIndex?.measureUnit;
        rowPhyProd['ÍNDICE MENSAL - NOME'] = phyProd.monthlyIndex?.name;
        rowPhyProd['ÍNDICE MENSAL - VALOR'] = phyProd.monthlyIndex?.value;
        rowPhyProd['VARIAÇÃO PERCENTUAL MENSAL - UNIDADE DE MEDIDA'] =
          phyProd.monthlyPercentageChange?.measureUnit;
        rowPhyProd['VARIAÇÃO PERCENTUAL MENSAL - NOME'] =
          phyProd.monthlyPercentageChange?.name;
        rowPhyProd['VARIAÇÃO PERCENTUAL MENSAL - VALOR'] =
          phyProd.monthlyPercentageChange?.value;
        rowPhyProd[
          'VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - UNIDADE DE MEDIDA'
        ] = phyProd.percentageChangeAccumulatedLast12Months?.measureUnit;
        rowPhyProd['VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - NOME'] =
          phyProd.percentageChangeAccumulatedLast12Months?.name;
        rowPhyProd['VARIAÇÃO PERCENTUAL ACUMULADA NOS ÚLTIMOS 12 MESES - VALOR'] =
          phyProd.percentageChangeAccumulatedLast12Months?.value;
        rowPhyProd['VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - UNIDADE DE MEDIDA'] =
          phyProd.percentageChangeAccumulatedYear?.measureUnit;
        rowPhyProd['VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - NOME'] =
          phyProd.percentageChangeAccumulatedYear?.name;
        rowPhyProd['VARIAÇÃO PERCENTUAL ACUMULADA NO ANO - VALOR'] =
          phyProd.percentageChangeAccumulatedYear?.value;
        rowPhyProd['ÍNDICE ACUMULADO DO ANO ATÉ A DATA - UNIDADE DE MEDIDA'] =
          phyProd.yearToDateIndex?.measureUnit;
        rowPhyProd['ÍNDICE ACUMULADO DO ANO ATÉ A DATA - NOME'] =
          phyProd.yearToDateIndex?.name;
        rowPhyProd['ÍNDICE ACUMULADO DO ANO ATÉ A DATA - VALOR'] =
          phyProd.yearToDateIndex?.value;

        physicalProductionWorksheet.addRow(rowPhyProd);
      });

      // PREENCHENDO DADOS QUANTIDADE DE PROCESSOS JUDICAIS
      const rowJudProc = {};
      const judProcQt = judicialProcess.judicialProcessQuantities;

      judProcQt?.map(judProcQt => {
        rowJudProc['CNPJ'] = BD.cnpj;
        rowJudProc['FANTASY NAME'] = BD.fantasyName;
        rowJudProc['TIPO'] = judProcQt.type;
        rowJudProc['QUANTIDADE TOTAL'] = judProcQt.quantityTotal.toString();
        rowJudProc['QUANTIDADE ATIVA'] = judProcQt.quantityActive.toString();
        rowJudProc['QUANTIDADE OUTROS'] = judProcQt.quantityOthers.toString();
        rowJudProc[
          'QUANTIDADE COMO PARTE ATIVA'
        ] = judProcQt.quantityActivePart.toString();
        rowJudProc[
          'QUANTIDADE COMO PARTE PASSIVA'
        ] = judProcQt.quantityPassivePart.toString();

        judicialProcessWorksheet.addRow(rowJudProc);
      });

      // PREENCHENDO DADOS SÓCIOS
      const rowPartner = {};
      const partners = BD.partners;

      partners?.map(partner => {
        rowPartner['CNPJ'] = BD.cnpj;
        rowPartner['FANTASY NAME'] = BD.fantasyName;
        rowPartner['DOCUMENTO'] = partner.document;
        rowPartner['NOME'] = partner.name;
        rowPartner['PAÍS DE ORIGEM'] = partner.originCountry;
        rowPartner['NÍVEL DE FORMAÇÃO'] = partner.levelPreparation;
        rowPartner['NÍVEL DE FORMAÇÃO NO RF'] = partner.levelPreparationRF;
        rowPartner['QUALIFICAÇÃO'] = partner.qualification;
        rowPartner['QUALIFICAÇÃO NO RF'] = partner.qualificationRF;
        rowPartner['PARTICIPAÇÃO'] = partner.participation;
        rowPartner['PARTICIPAÇÃO NO RF'] = partner.participationRF;
        rowPartner['PARTICIPAÇÃO NO CAPITAL SOCIAL'] =
          partner.participationSocialCapital;
        rowPartner['PARTICIPAÇÃO NO CAPITAL SOCIAL NO RF'] =
          partner.participationSocialCapitalRF;
        rowPartner['FALECIDO'] = partner.dead ? 'Sim' : 'Não';
        rowPartner['LISTAGEM DE PROCESSOS CRIMINAIS'] = joinArrayString(
          partner.criminalProcess ?? [],
        );

        partnersWorksheet.addRow(rowPartner);
      });

      // PREENCHENDO DADOS SÓCIO PESSOA
      const rowPartPerson = {};
      const personPartners = BD.personPartners;

      personPartners?.map(perPart => {
        rowPartPerson['CNPJ'] = BD.cnpj;
        rowPartPerson['FANTASY NAME'] = BD.fantasyName;
        rowPartPerson['CPF'] = perPart.cpf;
        rowPartPerson['NOME'] = perPart.name;
        rowPartPerson['GÊNERO'] = perPart.sex;
        rowPartPerson['E-MAIL'] = perPart.email;
        rowPartPerson['CNS'] = perPart.cns;
        rowPartPerson['NIS'] = perPart.nis;
        rowPartPerson['CPF DA MÃE'] = perPart.cpf;
        rowPartPerson['NOME DA MÃE'] = perPart.motherName;
        rowPartPerson['NOME DO PAI'] = perPart.fatherName;
        rowPartPerson['PAÍS DE ORIGEM'] = perPart.originCountry;
        rowPartPerson['INSCRIÇÃO SOCIAL'] = perPart.socialInscription;
        rowPartPerson['VALOR DO DÉBITO PGFN/DAU'] = perPart.debitPgfnDauValue;
        rowPartPerson['SITUAÇÃO DO CPF'] = perPart.situationCpf;
        rowPartPerson['NÍVEL DE EDUCAÇÃO'] = perPart.educationLevel;
        rowPartPerson['TIPO DE DEFICIÊNCIA'] = perPart.deficiencyType;
        rowPartPerson['CÓDIGO NEOWAY DA PROFISSÃO'] = perPart.professionNeoway;
        rowPartPerson['CÓDIGO CBO DA PROFISSÃO'] = perPart.professionCbo;
        rowPartPerson['SITUAÇÃO GERAL'] = perPart.situation;
        rowPartPerson['SITUAÇÃO DE REGISTRO'] = perPart.registerSituation;
        rowPartPerson['DATA DE INCRIÇÃO DO CPF'] = getDatePtBr(
          perPart.inscriptionCpfDate ?? '',
        );
        rowPartPerson[
          'QUANTIDADE ÚNICA DE SÓCIOS'
        ] = perPart.quantityQsaUnique?.toString();
        rowPartPerson['IDADE'] = perPart.age?.toString();
        rowPartPerson['DATA DE FALECIMENTO'] = perPart.deadDate?.toString();
        rowPartPerson['DEFICIÊNCIA'] = perPart.deficiency ? 'Sim' : 'Não';
        rowPartPerson['FALECIDO'] = perPart.dead ? 'Sim' : 'Não';
        rowPartPerson['CONFIRMAÇÃO DE FALECIMENTO'] = perPart.deadConfirmation
          ? 'Sim'
          : 'Não';
        rowPartPerson['AGENTE PÚBLICO'] = perPart.publicAgent ? 'Sim' : 'Não';
        rowPartPerson['DATA DE NASCIMENTO'] = getDatePtBr(perPart.birthDate ?? '');
        rowPartPerson['DATA E HORA DA ULTIMA CONSULTA DE PROTESTO'] = getDatePtBr(
          perPart.protestLastConsultDate ?? '',
          true,
        );

        partnerPersonWorksheet.addRow(rowPartPerson);

        // PREENCHENDO DADOS SÓCIO PESSOA - ENDEREÇO
        const addressPartnerPerson = perPart.mainAddress;
        if (addressPartnerPerson) {
          generateRowsAddress(
            addressPartnerPerson,
            partnerPersonAddressWorksheet,
            perPart.cpf,
          );
        }
      });

      // PREENCHENDO DADOS AFILIADOS
      const rowAffil = {};
      const affiliates = BD.bigData.company.affiliates;

      affiliates?.map(affil => {
        rowAffil['CNPJ'] = BD.cnpj;
        rowAffil['FANTASY NAME'] = BD.fantasyName;
        rowAffil['CNPJ - AFILIADO'] = affil.cnpj;
        rowAffil['MUNÍCIO - AFILIADO'] = affil.municipality;
        rowAffil['DATA DE ABERTURA - AFILIADO'] = getDatePtBr(
          affil.openingDate ?? '',
        );
        rowAffil['SITUAÇÃO DE REGISTRO - AFILIADO'] = affil.registrationSituation;
        rowAffil['RAZÃO SOCIAL - AFILIADO'] = affil.socialReason;
        rowAffil['UF - AFILIADO'] = affil.uf;

        affiliatesWorksheet.addRow(rowAffil);
      });

      // PREENCHENDO DADOS AERONAVES
      const rowAircraft = {};
      const aircrafts = BD.bigData.company.aircraft;

      aircrafts?.map(aircraft => {
        rowAircraft['CNPJ'] = BD.cnpj;
        rowAircraft['FANTASY NAME'] = BD.fantasyName;
        rowAircraft['FABRICANTE'] = aircraft.maker;
        rowAircraft['MODELO'] = aircraft.model;
        rowAircraft['NOME DO OPERADOR'] = aircraft.operatorName;
        rowAircraft['NOME DO PROPRIETÁRIO'] = aircraft.ownerName;
        rowAircraft['ANO DE FABRICAÇÃO'] = aircraft.productionYear.toString();
        rowAircraft['REGISTRO DE AERONAVE'] = aircraft.registration;
        rowAircraft['SITUAÇÃO'] = aircraft.situation;
        rowAircraft['STATUS RAB'] = aircraft.statusRAB;

        aircraftWorksheet.addRow(rowAircraft);
      });

      // PREENCHENDO DADOS POSTO DE COMBUSTIVEIS - EQUIPAMENTOS
      const rowFuelStatEquip = {};
      const fuelStatEquips = fuelStation?.equipments;

      fuelStatEquips?.map(fSEstquip => {
        rowFuelStatEquip['CNPJ'] = BD.cnpj;
        rowFuelStatEquip['FANTASY NAME'] = BD.fantasyName;
        rowFuelStatEquip['QUANTIDADE DE BICOS'] = fSEstquip.beaks?.toString();
        rowFuelStatEquip['TIPO DE COMBUSTÍVEL'] = fSEstquip.fuel;
        rowFuelStatEquip['ABASTECIMENTO POR BICO'] = fSEstquip.tanking?.toString();

        fuelStationEquipamentsWorksheet.addRow(rowFuelStatEquip);
      });

      // PREENCHENDO DADOS POSTO DE COMBUSTIVEIS - BANDEIRAS
      const rowFuelStatFlag = {};
      const fuelStatFlags = fuelStation?.flags;

      fuelStatFlags?.map(flag => {
        rowFuelStatFlag['CNPJ'] = BD.cnpj;
        rowFuelStatFlag['FANTASY NAME'] = BD.fantasyName;
        rowFuelStatFlag['BANDEIRA'] = flag.flag;
        rowFuelStatFlag['DATA DE INÍCIO'] = getDatePtBr(flag.startFlagDate ?? '');

        fuelStationFlagsWorksheet.addRow(rowFuelStatFlag);
      });

      // PREENCHENDO DADOS POSTO DE COMBUSTIVEIS
      const rowFuelStation = {};
      const fuelStations = BD.bigData.company.anpSupplyPoints;

      fuelStations?.map(fuelStat => {
        rowFuelStation['CNPJ'] = BD.cnpj;
        rowFuelStation['FANTASY NAME'] = BD.fantasyName;
        rowFuelStation['MUNICÍPIO'] = fuelStat.municipality;
        rowFuelStation[
          'CAPACIDADE TOTAL DOS TANQUES'
        ] = fuelStat.totalCapacityTanks.toString();
        rowFuelStation[
          'NÚMERO TOTAL DE TANQUES'
        ] = fuelStat.totalNumberTanks.toString();
        rowFuelStation['UF'] = fuelStat.uf;

        fuelStationWorksheet.addRow(rowFuelStation);
      });

      // PREENCHENDO DADOS ANTT
      const rowAntt = {};
      const antt = BD.bigData.company.antt;

      rowAntt['CNPJ'] = BD.cnpj;
      rowAntt['FANTASY NAME'] = BD.fantasyName;
      rowAntt['CATEGORIA'] = antt?.category;
      rowAntt['DATA DE EMISSÃO'] = antt?.emissionDate;
      rowAntt['DATA DE EXPIRAÇÃO'] = antt?.expirationDate;
      rowAntt['MUNICÍPIO'] = antt?.municipality;
      rowAntt['NÚMERO RNTRC'] = antt?.rntrcNumber;
      rowAntt['SITUAÇÃO'] = antt?.situation;
      rowAntt['UF'] = antt?.uf;

      anttWorksheet.addRow(rowAntt);

      // PREENCHENDO DADOS PROCESOS ANVISA
      const rowAnvProcess = {};
      const anvisaProcess = anvisa?.processes;

      anvisaProcess?.map(anvProc => {
        rowAnvProcess['CNPJ'] = BD.cnpj;
        rowAnvProcess['FANTASY NAME'] = BD.fantasyName;
        rowAnvProcess['DATA DE AUTORIZAÇÃO'] = getDatePtBr(
          anvProc.authorizationDate ?? '',
        );
        rowAnvProcess['PROCESSO DE AUTORIZAÇÃO'] = anvProc.authorizationProcess;
        rowAnvProcess['DESCRIÇÃO DO PROCESSO'] = anvProc.description;
        rowAnvProcess['NÚMERO DO PROCESSO'] = anvProc.processNumber;

        anvisaProcessWorkSheet.addRow(rowAnvProcess);
      });

      // PREENCHENDO DADOS OPERAÇÕES BANCÁRIAS
      const rowBankOp = {};
      const bankOperations = BD.bigData.company.bankOperations;

      bankOperations?.map(bankOp => {
        rowBankOp['CNPJ'] = BD.cnpj;
        rowBankOp['FANTASY NAME'] = BD.fantasyName;
        rowBankOp['PERÍODO DE AMORTIZAÇÃO'] = bankOp.amortizationPeriod.toString();
        rowBankOp['DATA DA CONTRATAÇÃO'] = getDatePtBr(bankOp.contractedDate ?? '');
        rowBankOp['VALOR CONTRATADO'] = getCurrencyPtBr(
          bankOp?.contractedValue ?? 0,
        );
        rowBankOp['AGENTE FINANCEIRO'] = bankOp.financialAgent;
        rowBankOp['CUSTO FINANCEIRO'] = bankOp.financialCost;
        rowBankOp['PERÍODO DE CARÊNCIA'] = bankOp.gracePeriod.toString();
        rowBankOp['PRODUTO FINANCEIRO'] = bankOp.product;
        rowBankOp['STATUS DE OPERAÇÃO'] = bankOp.status;
        rowBankOp['TAXA DE JUROS'] = bankOp.tax.toString();
        rowBankOp['TIPO DE OPERAÇÃO'] = bankOp.type;
        rowBankOp['UNIDADE FEDERATIVA'] = bankOp.uf;

        bankOperationsWorkSheet.addRow(rowBankOp);
      });

      // PREENCHENDO DADOS BENEFICIÁRIOS
      const rowBeneficiarie = {};
      const beneficiaries = BD.bigData.company.beneficiaries;

      beneficiaries?.map(ben => {
        rowBeneficiarie['CNPJ'] = BD.cnpj;
        rowBeneficiarie['FANTASY NAME'] = BD.fantasyName;
        rowBeneficiarie['DOCUMENTO'] = ben.document;
        rowBeneficiarie['NOME'] = ben.name;
        rowBeneficiarie['NOTA'] = ben.grade?.toString();
        rowBeneficiarie['NOTA QSA'] = ben.gradeQsa?.toString();
        rowBeneficiarie['PARTICIPAÇÃO'] = ben.participation?.toString();
        rowBeneficiarie['PARTICIPAÇÃO QSA'] = ben.participationQsa?.toString();
        rowBeneficiarie['FALECIDO'] = ben.dead ? 'Sim' : 'Não';

        beneficiariesWorksheet.addRow(rowBeneficiarie);
      });

      // PREENCHENDO DADOS CAFIR
      const rowCafir = {};
      const cafirs = BD.bigData.company.cafir?.propertiesRural;

      cafirs?.map(cafir => {
        rowCafir['CNPJ'] = BD.cnpj;
        rowCafir['FANTASY NAME'] = BD.fantasyName;
        rowCafir['ÁREA'] = cafir.area.toString();
        rowCafir['CONDOMÍNIO'] = cafir.condominium;
        rowCafir['MUNICÍPIO'] = cafir.municipality;
        rowCafir['NOME'] = cafir.name;
        rowCafir['NÚMERO NIRF'] = cafir.nirf;

        cafirWorksheet.addRow(rowCafir);
      });

      // PREENCHENDO DADOS CEIS
      const rowCeis = {};
      const ceis = BD.bigData.company.ceis;

      ceis?.map(cei => {
        rowCeis['CNPJ'] = BD.cnpj;
        rowCeis['FANTASY NAME'] = BD.fantasyName;
        rowCeis['DATA DE TÉRMINO DA SANÇÃO'] = getDatePtBr(
          cei.endSanctionDate ?? '',
        );
        rowCeis['DATA DE INFORMAÇÃO'] = getDatePtBr(cei.informationDate ?? '');
        rowCeis['ENTIDADE INFORMANTE'] = cei.informationEntity;
        rowCeis['DATA DE INÍCIO DA SANÇÃO'] = getDatePtBr(
          cei.initSanctionDate ?? '',
        );
        rowCeis['FUNDAMENTAÇÃO LEGAL'] = cei.legalSubstantiation;
        rowCeis['ÓRGÃO COMPLEMENTAR'] = cei.organComplement;
        rowCeis['NÚMERO DO PROCESSO'] = cei.processNumber;
        rowCeis['SANÇÃO'] = cei.sanction;
        rowCeis['ENTIDADE SANCIONADORA'] = cei.sanctioningEntity;
        rowCeis['UF DA ENTIDADE SANCIONADORA'] = cei.ufSanctioningEntity;

        ceisWorksheet.addRow(rowCeis);
      });

      // PREENCHENDO DADOS CEPIMS
      const rowCepims = {};
      const cepims = BD.bigData.company.cepims;

      cepims?.map(cepim => {
        rowCepims['CNPJ'] = BD.cnpj;
        rowCepims['FANTASY NAME'] = BD.fantasyName;
        rowCepims['NÚMERO CONTRATO'] = cepim.contract;
        rowCepims['DATA DE TÉRMINO DO CONTRATO'] = getDatePtBr(
          cepim.endContractDate ?? '',
        );
        rowCepims['NOME DA ENTIDADE CONCEDENTE'] = cepim.grantorEntity;
        rowCepims['TIPO DE IMPEDIMENTO'] = cepim.impediment;
        rowCepims['VALOR LIBERADO'] = getCurrencyPtBr(cepim.valueReleased ?? 0);

        cepimsWorksheet.addRow(rowCepims);
      });

      // PREENCHENDO DADOS CFCS
      const rowCfc = {};
      const cfcs = BD.bigData.company.cfcs;

      cfcs?.map(cfc => {
        rowCfc['CNPJ'] = BD.cnpj;
        rowCfc['FANTASY NAME'] = BD.fantasyName;
        rowCfc['CRC'] = cfc.crc;
        rowCfc['NÚMERO REGISTRO'] = cfc.record;
        rowCfc['SITUAÇÃO'] = cfc.situation;
        rowCfc['TIPO'] = cfc.type;

        cfcWorksheet.addRow(rowCfc);
      });

      // PREENCHENDO DADOS CENEPs
      const rowCnep = {};
      const cneps = BD.bigData.company.cneps;

      cneps?.map(cnep => {
        rowCnep['CNPJ'] = BD.cnpj;
        rowCnep['FANTASY NAME'] = BD.fantasyName;
        rowCnep['DATA DE TÉRMINO DA SANÇÃO'] = getDatePtBr(
          cnep.endSanctionDate ?? '',
        );
        rowCnep['DATA DE INÍCIO DA SANÇÃO'] = getDatePtBr(
          cnep.initSanctionDate ?? '',
        );
        rowCnep['VALOR DA PENALIDADE'] = getCurrencyPtBr(cnep.penaltyValue ?? 0);
        rowCnep['NÚMERO DO PROCESSO'] = cnep.processNumber;
        rowCnep['SANÇÃO'] = cnep.sanction;
        rowCnep['ENTIDADE SANCIONADORA'] = cnep.sanctioningEntity;
        rowCnep['UF DA ENTIDADE SANCIONADORA'] = cnep.ufSanctioningEntity;

        cnepsWorksheet.addRow(rowCnep);
      });

      // PREENCHENDO DADOS CENEPs
      const rowCnjCnias = {};
      const cnjCnias = BD.bigData.company.cnjCnias;

      cnjCnias?.map(cnjCnia => {
        rowCnjCnias['CNPJ'] = BD.cnpj;
        rowCnjCnias['FANTASY NAME'] = BD.fantasyName;
        rowCnjCnias['DESCRIÇÃO DE ENTIDADE'] = cnjCnia.descriptionEntity;
        rowCnjCnias['NÚMERO DO PROCESSO'] = cnjCnia.processNumber;
        rowCnjCnias['DATA DO REGISTRO'] = getDatePtBr(cnjCnia.registrationDate);
        rowCnjCnias['PROBLEMAS RELACIONADOS'] = joinArrayString(
          cnjCnia.relatedIssues ?? [],
        );
        rowCnjCnias['ESFERA DA ENTIDADE'] = cnjCnia.sphere;
        rowCnjCnias['UF'] = cnjCnia.uf;
        rowCnjCnias['VALOR RELACIONADO'] = getCurrencyPtBr(cnjCnia.value ?? 0);

        cnjCniasWorksheet.addRow(rowCnjCnias);
      });

      // PREENCHENDO DADOS EMPRESAS RELACIONADA
      const rowCompRelated = {};
      const companysRelated = BD.bigData.company.companiesRelated;

      companysRelated?.map(cpRltd => {
        rowCompRelated['CNPJ - EMPRESA ORIGINAL'] = BD.cnpj;
        rowCompRelated['FANTASY NAME - EMPRESA ORIGINAL'] = BD.fantasyName;
        rowCompRelated['CNPJ'] = cpRltd.cnpj;
        rowCompRelated['DESCRIÇÃO DO CNAE'] = cpRltd.descriptionCnae;
        rowCompRelated['MUNICÍPIO'] = cpRltd.municipality;
        rowCompRelated['DATA DE ABERTURA'] = getDatePtBr(cpRltd.openingDate ?? '');
        rowCompRelated['RAZÃO SOCIAL'] = cpRltd.socialReason;
        rowCompRelated['UF'] = cpRltd.uf;

        companysRelatedWorksheet.addRow(rowCompRelated);
      });

      // PREENCHENDO DADOS CURSOS EMEC
      const rowCourseEmec = {};
      const coursesEmec = BD.bigData.company.companyEmecCourses;

      coursesEmec?.map(courseEmec => {
        rowCourseEmec['CNPJ'] = BD.cnpj;
        rowCourseEmec['FANTASY NAME'] = BD.fantasyName;
        rowCourseEmec['ORGANIZAÇÃO ACADÊMICA'] = courseEmec.academicOrganization;
        rowCourseEmec['CATEGORIA ADMINISTRATIVA'] =
          courseEmec.administrativeCategory;
        rowCourseEmec['CÓDIGO LES'] = courseEmec.codeLes;
        rowCourseEmec['DESCRIÇÃO LES'] = courseEmec.descriptionLes;
        rowCourseEmec['MUNICÍPIO'] = courseEmec.municipality;
        rowCourseEmec['SITUAÇÃO'] = courseEmec.situation;
        rowCourseEmec['UF'] = courseEmec.uf;

        coursesEmecWorksheet.addRow(rowCourseEmec);
      });

      //  PREENCHENDO DADOS CRI CRA DEBENTURES
      const rowCriCraDebent = {};
      const criCraDebentures = BD.bigData.company.criCraDebentures;

      criCraDebentures?.map(criCraDebent => {
        rowCriCraDebent['CNPJ'] = BD.cnpj;
        rowCriCraDebent['FANTASY NAME'] = BD.fantasyName;
        rowCriCraDebent['CÓDIGO'] = criCraDebent.code;
        rowCriCraDebent['DEVEDOR/EMISSOR'] = criCraDebent.debtorIssuer;
        rowCriCraDebent['DATA DE VENCIMENTO'] = getDatePtBr(
          criCraDebent.dueDate ?? '',
        );
        rowCriCraDebent['VALOR INDICATIVO'] = getCurrencyPtBr(
          criCraDebent.indicativeValue ?? 0,
        );
        rowCriCraDebent['SETOR DE SEGURO'] = criCraDebent.insuranceSector;
        rowCriCraDebent['DATA DE EMISSÃO'] = getDatePtBr(
          criCraDebent.issueDate ?? '',
        );
        rowCriCraDebent['PU Par'] = criCraDebent.puParDebenture.toString();
        rowCriCraDebent['REMUNERAÇÃO'] = criCraDebent.remuneration;
        rowCriCraDebent['SÉRIE DE EMISSÃO'] = criCraDebent.seriesIssue;
        rowCriCraDebent[
          'VOLUMA DA SÉRIA NA DATA DE EMISSÃO'
        ] = criCraDebent.seriesVolumeOnIssueDate.toString();
        rowCriCraDebent['TIPO'] = criCraDebent.type;

        criCraDebenturesWorksheet.addRow(rowCriCraDebent);
      });

      // PREENCHENDO DADOS CR SEF
      const rowCrSef = {};
      const crSefs = BD.bigData.company.crsfns;

      crSefs?.map(crSef => {
        rowCrSef['CNPJ'] = BD.cnpj;
        rowCrSef['FANTASY NAME'] = BD.fantasyName;
        rowCrSef['NÚMERO ACORDADO'] = crSef.agreedNumber;
        rowCrSef['PARTE ENVOLVIDA'] = crSef.part;
        rowCrSef['NÚMERO DO PROCESSO'] = crSef.processNumber;
        rowCrSef['NÚMERO DO RECURSO'] = crSef.resourceNumber;
        rowCrSef['TIPO DO RECURSO'] = crSef.resourceType;

        crSefWorksheet.addRow(rowCrSef);
      });

      // PREENCHENDO DADOS Dados de Registro na CVM
      const rowCvmReg = {};
      const cvmRegistereds = BD.bigData.company.cvmRegistrationData;

      cvmRegistereds?.map(cvmReg => {
        rowCvmReg['CNPJ'] = BD.cnpj;
        rowCvmReg['FANTASY NAME'] = BD.fantasyName;
        rowCvmReg['SETOR DE ATIVIDADE'] = cvmReg.activitySector;
        rowCvmReg['CÓDIGO DA EMPRESA'] = cvmReg.code;
        rowCvmReg['ATIVOS LÍQUIDOS'] = getCurrencyPtBr(cvmReg.liquidAssets ?? 0);
        rowCvmReg['DATA DE REGISTRO'] = getDatePtBr(cvmReg.registrationDate ?? '');
        rowCvmReg['TIPO DE REGISTRO'] = cvmReg.registrationType;
        rowCvmReg['NOME DO RESPONSÁVEL'] = cvmReg.responsibleName;
        rowCvmReg['TIPO DE RESPONSÁVEL'] = cvmReg.responsibleType;
        rowCvmReg['DATA DA SITUAÇÃO'] = getDatePtBr(cvmReg.situationDate);
        rowCvmReg['REGISTRO DA SITUAÇÃO'] = cvmReg.situationRecord;

        crSefWorksheet.addRow(rowCvmReg);
      });

      // PREENCHENDO DADOS DEBITOS MTE
      const rowDebMteProc = {};
      const debitMteProcess = debitMte?.processes;

      debitMteProcess?.map(dbtMteProc => {
        rowDebMteProc['CNPJ'] = BD.cnpj;
        rowDebMteProc['FANTASY NAME'] = BD.fantasyName;
        rowDebMteProc['CAPITULAÇÃO DA INFRAÇÃO NO PROCESSO'] =
          dbtMteProc.infringementCapitulation;
        rowDebMteProc['CATEGORIA DA INFRAÇÃO NO PROCESSO'] =
          dbtMteProc?.infringementCategory;
        rowDebMteProc['NÚMERO DO PROCESSO'] = dbtMteProc?.number;
        rowDebMteProc['SITUAÇÃO DO PROCESSO'] = dbtMteProc?.situation;

        debitMteProcessWorksheet.addRow(rowDebMteProc);
      });

      // PREENCHENDO DADOS DÉBITOS PGFN/DAU
      const rowDbtPgfnDau = {};
      const dbtPgfnDaus = BD.bigData.company.debitPgfnDau?.debitPgfns;

      dbtPgfnDaus?.map(dbtPgfnDau => {
        rowDbtPgfnDau['CNPJ'] = BD.cnpj;
        rowDbtPgfnDau['FANTASY NAME'] = BD.fantasyName;
        rowDbtPgfnDau['NATUREZA DO DÉBITO'] = dbtPgfnDau.nature;
        rowDbtPgfnDau['NÚMERO DA INSCRIÇÃO'] = dbtPgfnDau.inscriptionNumber;
        rowDbtPgfnDau['VALOR DO DÉBITO'] = getCurrencyPtBr(dbtPgfnDau.debit ?? 0);

        debitsPgfnDauWorksheet.addRow(rowDbtPgfnDau);
      });

      // PREENCHENDO DADOS DOCUMENTOS
      const rowDoc = {};
      const documents = BD.bigData.company.documents;

      documents?.map(doc => {
        rowDoc['CNPJ'] = BD.cnpj;
        rowDoc['FANTASY NAME'] = BD.fantasyName;
        rowDoc['DATA DE CRIAÇÃO'] = getDatePtBr(doc.created ?? '');
        rowDoc['A EXTENSÃO'] = doc.ext;
        rowDoc['NOME'] = doc.name;
        rowDoc['TIPO'] = doc.type;
        rowDoc['URL'] = doc.url;

        documentsWorksheet.addRow(rowDoc);
      });

      // PREENCHENDO DADOS DOMÍNIOS
      const rowDomain = {};
      const domains = BD.bigData.company.domains;

      domains?.map(domain => {
        rowDomain['CNPJ'] = BD.cnpj;
        rowDomain['FANTASY NAME'] = BD.fantasyName;
        rowDomain['DATA DE CRIAÇÃO'] = getDatePtBr(domain.createdDate ?? '');
        rowDomain['DATA DE EXPIRAÇÃO'] = getDatePtBr(domain.expirationDate ?? '');
        rowDomain['DATA DE MODIFICAÇÃO'] = getDatePtBr(
          domain.modificationDate ?? '',
        );
        rowDomain['NOME'] = domain.name;
        rowDomain['RESPONSÁVEL'] = domain.responsible;

        domainsWorksheet.addRow(rowDomain);
      });

      // PREENCHENDO DADOS
      const rowEmpGr = {};
      const employeedGrowths = BD.bigData.company.employeeGrowths;

      employeedGrowths?.map(empGr => {
        rowEmpGr['CNPJ'] = BD.cnpj;
        rowEmpGr['FANTASY NAME'] = BD.fantasyName;
        rowEmpGr['CRESCIMENTO DE COLABORADORES'] = empGr.employeeGrowth.toString();
        rowEmpGr['TAXA DE CRESCIMENTO DE COLABORADORES'] = empGr.growth.toString();
        rowEmpGr['ANO DE CRESCIMENTO'] = empGr.year.toString();

        employeedGrowthsWorksheet.addRow(rowEmpGr);
      });

      // PREENCHENDO DADOS COLABORADORES && EX-COLABORADORES
      const generateRowsEmploeeyrs = (
        data: KleverCompanyEmployee[],
        sheet: ExcelJS.Worksheet,
      ) => {
        if (data && data.length > 0) {
          const rowEmp = {};
          data.map(emp => {
            rowEmp['CNPJ'] = BD.cnpj;
            rowEmp['FANTASY NAME'] = BD.fantasyName;
            rowEmp['DATA DE ADMISSÃO'] = getDatePtBr(emp.admissionDate ?? '');
            rowEmp['DATA DE NASCIMENTO'] = getDatePtBr(emp.birthDate ?? '');
            rowEmp['CPF'] = emp.cpf;
            rowEmp['NOME'] = emp.name;
            rowEmp['ANO DE DESLIGAMENTO'] = emp.resignationYear?.toString();

            sheet.addRow(rowEmp);
          });
        }
      };

      const employees = BD.bigData.company.employees;
      const exEmployees = BD.bigData.company.exEmployees;
      generateRowsEmploeeyrs(employees, employeedsWorksheet);
      generateRowsEmploeeyrs(exEmployees, exEmployeedsWorksheet);

      // PREENCHENDO DADOS LICEENÇAS AMBIENTAIS
      const rowEnvLic = {};
      const environmentalLicenses = BD.bigData.company.environmentalLicenses;

      environmentalLicenses?.map(envLic => {
        rowEnvLic['CNPJ'] = BD.cnpj;
        rowEnvLic['FANTASY NAME'] = BD.fantasyName;
        rowEnvLic['DESCRIÇÃO DA TIPOLOGIA DA LICENÇA'] = envLic.descriptionTypology;
        rowEnvLic['DATA DE EMISSÃO'] = getDatePtBr(envLic.emitData ?? '');
        rowEnvLic['MUNÍCIPIO'] = envLic.municipality;
        rowEnvLic['NÚMERO DO PROCESSO'] = envLic.processNumber;
        rowEnvLic['SITUAÇÃO'] = envLic.situation;
        rowEnvLic['TIPO DE LICENÇA'] = envLic.type;
        rowEnvLic['NÚMERO TIPOLOGIA DA LICENÇA'] = envLic.typologyNumber;
        rowEnvLic['UF'] = envLic.uf;
        rowEnvLic['DATA DE ATUALIZAÇÃO'] = getDatePtBr(envLic.updateData ?? '');

        environmentalLicensesWorksheet.addRow(rowEnvLic);
      });

      // PREENCHENDO DADOS EXPORTAÇÕES && IMPORTAÇÕES
      const generateRowsExpImpo = (
        data: KleverCompanyExport[],
        sheet: ExcelJS.Worksheet,
      ) => {
        if (data && data.length > 0) {
          const rowExpImpo = {};
          data.map(item => {
            rowExpImpo['CNPJ'] = BD.cnpj;
            rowExpImpo['FANTASY NAME'] = BD.fantasyName;
            rowExpImpo['ANO'] = item.year.toString();
            rowExpImpo['VALOR'] = item.value;

            sheet.addRow(rowExpImpo);
          });
        }
      };

      const exportations = BD.bigData.company.exports;
      const imports = BD.bigData.company.imports;
      generateRowsExpImpo(exportations, exportationsWorksheet);
      generateRowsExpImpo(imports, importationsWorksheet);

      // PREENCHENDO DADOS INDICADORES FINANCEIROS
      const rowFinancInd = {};
      const finanInds = BD.bigData.company.financialIndicators;

      finanInds?.map(finanInd => {
        rowFinancInd['CNPJ'] = BD.cnpj;
        rowFinancInd['FANTASY NAME'] = BD.fantasyName;
        rowFinancInd['INDICADOR DE AUMENTO'] = finanInd.increase.toString();
        rowFinancInd['MARGEM'] = finanInd.margin.toString();

        financialIndicatorsWorksheet.addRow(rowFinancInd);
      });

      // PREENCHENDO DADOS ESTABELECIMENTOS DE SAÚDE
      const rowHealthEstab = {};
      const healthEstablishments = BD.bigData.company.healthEstablishments;

      healthEstablishments?.map(healthEstab => {
        rowHealthEstab['CNPJ'] = BD.cnpj;
        rowHealthEstab['FANTASY NAME'] = BD.fantasyName;
        rowHealthEstab['DATA DA ÚLTIMA ATUALIZAÇÃO'] = getDatePtBr(
          healthEstab.lastUpdate ?? '',
        );
        rowHealthEstab[
          'QUANTIDADE DE LEITOS'
        ] = healthEstab.quantityBeds?.toString();
        rowHealthEstab[
          'QUANTIDADE DE PROFISSIONAIS'
        ] = healthEstab.quantityProfessionals?.toString();
        rowHealthEstab['TIPO DE UNIDADE'] = healthEstab?.unitType;

        healthEstablishmentsWorksheet.addRow(rowHealthEstab);
      });

      // PREENCHENDO DADOS VEICULOS PESADOS
      const rowHeavyVc = {};
      const vehicules = BD.bigData.company.heavyVehicleInfo?.vehicles;

      vehicules?.map(vehicule => {
        rowHeavyVc['CNPJ'] = BD.cnpj;
        rowHeavyVc['FANTASY NAME'] = BD.fantasyName;
        rowHeavyVc['REGISTRO ANTT'] = vehicule.antt;
        rowHeavyVc['PLACA'] = vehicule.carPlate;
        rowHeavyVc['COMBUSTÍVEL'] = vehicule.fuel;
        rowHeavyVc['MODELO'] = vehicule.model;
        rowHeavyVc['ANO DE FABRICAÇÃO'] = vehicule.productionYear.toString();
        rowHeavyVc['RENAVAM'] = vehicule.renavam;
        rowHeavyVc['TIPO'] = vehicule.type;
        rowHeavyVc['UF'] = vehicule.uf;

        heavyVehicleInfoWorksheet.addRow(rowHeavyVc);
      });

      // PREENCHENDO DADOS IBGE
      const rowIbge = {};
      const ecoStat = BD.bigData.company.ibge?.economicStatistics;
      const ecoGeo = BD.bigData.company.ibge?.geographicStatistics;
      const ecoWork = BD.bigData.company.ibge?.workPerformanceStatistics;

      rowIbge['CNPJ'] = BD.cnpj;
      rowIbge['FANTASY NAME'] = BD.fantasyName;
      rowIbge['ESTATÍSTICAS ECÔNOMICAS - IDHM'] = ecoStat?.idhm.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - ANO DO IDHM'
      ] = ecoStat?.idhmYear.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - PERCENTUAL DE FONTES DE RECEITA'
      ] = ecoStat?.percentageRevenueSources.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - ANO DO PERCENTUAL DE FONTES DE RECEITA'
      ] = ecoStat?.percentageRevenueSourcesYear.toString();
      rowIbge['ESTATÍSTICAS ECÔNOMICAS - PIB'] = ecoStat?.pib.toString();
      rowIbge['ESTATÍSTICAS ECÔNOMICAS - ANO DO PIB'] = ecoStat?.pibYear.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - TOTAL DE DESPEDAS'
      ] = ecoStat?.totalExpenses.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - ANO DO TOTAL DE DESPEDAS'
      ] = ecoStat?.totalExpensesYear.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - TOTAL DE RECEITAS'
      ] = ecoStat?.totalRevenue.toString();
      rowIbge[
        'ESTATÍSTICAS ECÔNOMICAS - ANO DO TOTAL DE RECEITAS'
      ] = ecoStat?.totalRevenueYear.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - DENSIDADE DEMOGRÁFICA'
      ] = ecoGeo?.demographicDensity.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - ANO DA DENSIDADE DEMOGRÁFICA'
      ] = ecoGeo?.demographicDensityYear.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - POPULAÇÃO ESTIMADA'
      ] = ecoGeo?.estimatedPopulation.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - ANO DA POPULAÇÃO ESTIMADA'
      ] = ecoGeo?.estimatedPopulationYear.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - POPULAÇÃO ESTIMADA NO ÚLTIMO CENSO'
      ] = ecoGeo?.estimatedPopulationLastCensus.toString();
      rowIbge[
        'ESTATÍSTICAS GEOGRÁFICAS - ANO DA POPULAÇÃO ESTIMADA NO ÚLTIMO CENSO'
      ] = ecoGeo?.estimatedPopulationLastCensusYear.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - SALÁRIO MÉDIO'
      ] = ecoWork?.averageSalary?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DO SALÁRIO MÉDIO'
      ] = ecoWork?.averageSalaryYear?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - PESSOAS OCUPADAS'
      ] = ecoWork?.busyPeople?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DO PESSOAS OCUPADAS'
      ] = ecoWork?.busyPeopleYear.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - POPULAÇÃO OCUPADA'
      ] = ecoWork?.occupiedPopulation?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DA POPULAÇÃO OCUPADA'
      ] = ecoWork?.occupiedPopulationYear?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - RENDA MENSAL NOMINAL POR PESSOA'
      ] = ecoWork?.populationIncomeMonthlyNominal?.toString();
      rowIbge[
        'ESTATÍSTICAS DE PERFORMANCE DE TRABALHO - ANO DA RENDA MENSAL NOMINAL POR PESSOA'
      ] = ecoWork?.populationIncomeMonthlyNominalYear?.toString();

      ibgeWorksheet.addRow(rowIbge);

      // PREENCHENDO DADOS INPI - Marcas
      const rowInpiBrand = {};
      const inpiBrands = BD.bigData.company.inpiBrands;

      inpiBrands?.map(inpiBrand => {
        rowInpiBrand['CNPJ'] = BD.cnpj;
        rowInpiBrand['FANTASY NAME'] = BD.fantasyName;
        rowInpiBrand['MARCA REGISTRADA'] = inpiBrand.brand;
        rowInpiBrand['CLASSE DA MARCA REGISTRADA'] = inpiBrand.classBrand;
        rowInpiBrand['DATA DO DEPÓSITO'] = getDatePtBr(inpiBrand.depositDate ?? '');
        rowInpiBrand['NÚMERO DO PROCESSO'] = inpiBrand.processNumber;
        rowInpiBrand['SITUAÇÃO'] = inpiBrand.situation;

        inpiBrandsWorksheet.addRow(rowInpiBrand);
      });

      // PREENCHENDO DADOS INPI - Patents
      const rowInpiPat = {};
      const inpiPatents = BD.bigData.company.inpiPatents;

      inpiPatents?.map(inpiPat => {
        rowInpiPat['CNPJ'] = BD.cnpj;
        rowInpiPat['FANTASY NAME'] = BD.fantasyName;
        rowInpiPat['DATA DE CONCESSÃO'] = getDatePtBr(inpiPat.concessionDate ?? '');
        rowInpiPat['DATA DE DEPÓSITO'] = getDatePtBr(inpiPat.depositDate ?? '');
        rowInpiPat['DEPOSITANTE'] = inpiPat.depositor;
        rowInpiPat['INVENTORES'] = joinArrayString(inpiPat.inventors ?? []);
        rowInpiPat['NÚMERO DO PROCESSO'] = inpiPat.processNumber;
        rowInpiPat['PROCURADOR'] = inpiPat.procurator;
        rowInpiPat['DATA DE PUBLICAÇÃO'] = getDatePtBr(
          inpiPat.publicationDate ?? '',
        );
        rowInpiPat['TÍTULO'] = inpiPat.title;

        inpiPatentsWorksheet.addRow(rowInpiPat);
      });

      // PREENCHENDO DADOS INPI - Softwares
      const rowInpiSoft = {};
      const inpisoftwares = BD.bigData.company.inpiSoftwares;

      inpisoftwares?.map(inpiSoft => {
        rowInpiSoft['CNPJ'] = BD.cnpj;
        rowInpiSoft['FANTASY NAME'] = BD.fantasyName;
        rowInpiSoft['AUTORES'] = inpiSoft.authors;
        rowInpiSoft['DATA DE DEPÓSITO'] = getDatePtBr(inpiSoft.depositDate ?? '');
        rowInpiSoft['NÚMERO DO PROCESSO'] = inpiSoft.processNumber;
        rowInpiSoft['PROCURADOR'] = inpiSoft.procurator;
        rowInpiSoft['TÍTULO'] = inpiSoft.title;

        inpiSoftwaresWorksheet.addRow(rowInpiSoft);
      });

      // PREENCHENDO DADOS LISTA INTERNACIONAL
      const rowIntList = {};
      const intLists = BD.bigData.company.internationalLists;

      intLists?.map(intList => {
        rowIntList['CNPJ'] = BD.cnpj;
        rowIntList['FANTASY NAME'] = BD.fantasyName;
        rowIntList['NOME DA LISTA RELACIONADA'] = intList.name;
        rowIntList['DATA DE CONSULTA'] = getDatePtBr(intList.queryDate ?? '');

        internationalListWorksheet.addRow(rowIntList);
      });

      // PREENCHENDO DADOS CONTRATOS
      const rowContract = {};
      const contracts = BD.bigData.company.managementContract?.contracts;

      contracts?.map(contract => {
        rowContract['CNPJ'] = BD.cnpj;
        rowContract['FANTASY NAME'] = BD.fantasyName;
        rowContract['NÚMERO DO CONTRATO'] = contract.contractNumber;
        rowContract['DATA DE INÍCIO'] = getDatePtBr(contract.initDate ?? '');
        rowContract['DATA DE TÉRMINO'] = getDatePtBr(contract?.endDate ?? '');
        rowContract['VALOR DO CONTRATO'] = getCurrencyPtBr(
          contract?.finalValue ?? 0,
        );
        rowContract['MESES DE VALIDADE'] = contract?.monthsValidity?.toString();
        rowContract['ÓRGÃO RESPONSÁVEL'] = contract?.organ;
        rowContract['ESFERA'] = contract?.sphere;
        rowContract['UF'] = contract?.uf;

        contractWorksheet.addRow(rowContract);
      });

      //  // PREENCHENDO DADOS CONTRATOS - MODALDIADE
      const rowContModal = {};
      const contModalitys =
        BD.bigData.company.managementContract?.modalityContracts;

      contModalitys?.map(contModal => {
        rowContModal['CNPJ'] = BD.cnpj;
        rowContModal['FANTASY NAME'] = BD.fantasyName;
        rowContModal['QUANTIDADE'] = contModal.quantity?.toString();
        rowContModal['TIPO'] = contModal?.type?.toString();

        contractModalitytWorksheet.addRow(rowContModal);
      });

      // PREENCHENDO DADOS CAPITAL ABERTO - POSIÇÕES AÇÕES EM ABERTO
      const rowActOpen = {};
      const actionsOpen = openCapital?.openCapitalActionPositions;

      actionsOpen?.map(actOpen => {
        rowActOpen['CNPJ'] = BD.cnpj;
        rowActOpen['FANTASY NAME'] = BD.fantasyName;
        rowActOpen['DOCUMENTO'] = actOpen.document;
        rowActOpen['VALOR DAS AÇÕES EM ABERTO DO TIPO ORDINARAS'] = getCurrencyPtBr(
          actOpen.orderActionsValue ?? 0,
        );
        rowActOpen[
          'VALOR DAS AÇÕES EM ABERTO DO TIPO PREFERENCIAIS'
        ] = getCurrencyPtBr(actOpen?.preferentialActionsValue ?? 0);
        rowActOpen['VALOR TOTAL DAS AÇÕES EM ABERTO'] = getCurrencyPtBr(
          actOpen?.totalValue ?? 0,
        );

        actionPositionsWorksheet.addRow(rowActOpen);
      });

      // PREENCHENDO DADOS CAPITAL ABERTO - BALANÇO PATRIMONIAL
      const rowPatBal = {};
      const PatsBals = openCapital?.openCapitalPatrimonialBalances;

      PatsBals?.map(patBal => {
        rowPatBal['CNPJ'] = BD.cnpj;
        rowPatBal['FANTASY NAME'] = BD.fantasyName;
        rowPatBal['TOTAL DE ATIVOS'] = getCurrencyPtBr(patBal.activeTotal ?? 0);
        rowPatBal['VALOR DOS ATIVOS'] = getCurrencyPtBr(patBal.activeValue ?? 0);
        rowPatBal['PATRIMÔNIO LÍQUIDO'] = getCurrencyPtBr(
          patBal.liquidPatrimony ?? 0,
        );
        rowPatBal['PATRIMÔNIO LÍQUIDO CONTROLADO'] = getCurrencyPtBr(
          patBal.liquidPatrimony ?? 0,
        );
        rowPatBal['ANO DO BALANÇO PATRIMONIAL'] = patBal?.year?.toString();

        patrimonialBalanceWorksheet.addRow(rowPatBal);
      });

      // PREENCHENDO DADOS PENALIDADE - CAPITAL ABERTO
      const rowPenal = {};
      const penaltys = openCapital?.openCapitalPenalties;

      penaltys?.map(penal => {
        rowPenal['CNPJ'] = BD.cnpj;
        rowPenal['FANTASY NAME'] = BD.fantasyName;
        rowPenal['DATA DE CRIAÇÃO PENALIDADE'] = getDatePtBr(penal.crated ?? '');
        rowPenal['RAZÃO DA PENALIDADE'] = penal.reason;

        penaltysOCWorksheet.addRow(rowPenal);
      });

      // PREENCHENDO DADOS CAPITAL ABERTO - DEMONSTRAÇÃO DE RESULTADOS
      const rowDemoRes = {};
      const demonstrationsResults = openCapital?.openCapitalResultDemonstrations;

      demonstrationsResults?.map(demoRes => {
        rowDemoRes['CNPJ'] = BD.cnpj;
        rowDemoRes['FANTASY NAME'] = BD.fantasyName;
        rowDemoRes[
          'PERÍODO DE BENEFÍCIO EM CAPITAL ABERTO'
        ] = demoRes.benefitPeriod?.toString();
        rowDemoRes[
          'PERÍODO DE BENEFÍCIO CONTROLADOR EM CAPITAL ABERTO'
        ] = demoRes.benefitPeriodController?.toString();
        rowDemoRes['RECEITA DE VENDAS EM CAPITAL ABERTO'] = getCurrencyPtBr(
          demoRes.recipeSale ?? 0,
        );
        rowDemoRes['RESULTADO BRUTO EM CAPITAL ABERTO'] = getCurrencyPtBr(
          demoRes.resultBrute ?? 0,
        );
        rowDemoRes['RESULTADO FINANCEIRO EM CAPITAL ABERTO'] = getCurrencyPtBr(
          demoRes.resultFinancial ?? 0,
        );
        rowDemoRes[
          'RESULTADO DE OPERAÇÕES LÍQUIDAS EM CAPITAL ABERTO'
        ] = getCurrencyPtBr(demoRes.resultLiquidOperations ?? 0);
        rowDemoRes[
          'RESULTADO DE EQUIVALÊNCIA PATRIMIONIAL EM CAPITAL ABERTO'
        ] = getCurrencyPtBr(demoRes.resultPatrimonialEquivalence ?? 0);
        rowDemoRes[
          'ANO DE DEMONSTRAÇÃO DE RESULTADOS EM CAPITAL ABERTO'
        ] = demoRes.year?.toString();

        demonstrationResultsOCWorksheet.addRow(rowDemoRes);
      });

      // PREENCHENDO DADOS PAT REFEIÇÕES
      const rowMeals = {};
      const meals = pat?.mealProvides;

      meals?.map(meal => {
        rowMeals['CNPJ'] = BD.cnpj;
        rowMeals['FANTASY NAME'] = BD.fantasyName;
        rowMeals['QUANTIDADE DE REFEIÇÕES FORNECIDAS'] = meal.quantity.toString();
        rowMeals['TIPO DE REFEIÇÃO'] = meal.type;

        mealPatWorksheet.addRow(rowMeals);
      });
      // ---------------------------------

      // PREENCHENDO DADOS PAT - MODALIDADES
      const rowModalPat = {};
      const modalitiesPat = pat?.modalities;

      modalitiesPat?.map(modalPet => {
        rowModalPat['CNPJ'] = BD.cnpj;
        rowModalPat['FANTASY NAME'] = BD.fantasyName;
        rowModalPat[
          'NÚMERO DE FUNCIONÁRIOS BENEFICIADOS PELA MODALIDADE'
        ] = modalPet.benefitedEmployees.toString();
        rowModalPat['MODALIDE'] = modalPet.mode;
        rowModalPat[
          'NÚMERO DE FUNCIONÁRIOS QUE RECEBEM MAIS DE 5 SALÁRIOS MÍNIMOS NA MODALIDADE PAT'
        ] = modalPet.over5Sm.toString();
        rowModalPat['CNPJ DO FORNECEDOR NA MODALIDADE PAT'] = modalPet.providerCnpj;
        rowModalPat['RAZÃO SOCIAL DO FORNECEDOR NA MODALIDADE PAT'] =
          modalPet.providerSocialReason;
        rowModalPat[
          'NÚMERO DE FUNCIONÁRIOS QUE RECEBEM ATÉ 5 SALÁRIOS MÍNIMOS NA MODALIDADE PAT'
        ] = modalPet.to5Sm.toString();

        modalitysPatWorksheet.addRow(rowModalPat);
      });

      // PREENCHENDO DADOS PROCON GROUPS
      const rowProconGroups = {};
      const proconGroups = procon?.proconGroups;

      proconGroups?.map(procGroup => {
        rowProconGroups['CNPJ'] = BD.cnpj;
        rowProconGroups['FANTASY NAME'] = BD.fantasyName;
        rowProconGroups['CNPJ DO GRUPO DO PROCON'] = procGroup.cnpj;
        rowProconGroups[
          'VALOR TOTAL DA PENALIDADE DO GRUPO DO PROCON'
        ] = getCurrencyPtBr(procGroup.totalPenaltyValue ?? 0);

        proconGroupsWorksheet.addRow(rowProconGroups);
      });
      //----------------------------------------------------
      // PREENCHENDO DADOS PROCON PROCESSOS
      const rowProconProc = {};
      const proconProcess = procon?.proconProcesses;

      proconProcess?.map(procProc => {
        rowProconProc['CNPJ'] = BD.cnpj;
        rowProconProc['FANTASY NAME'] = BD.fantasyName;
        rowProconProc[
          'VALOR DA PENALIDADE DO PROCESSO DO PROCON'
        ] = getCurrencyPtBr(procProc.penaltyValue ?? 0);
        rowProconProc['NÚMERO DO PROCESSO DO PROCON'] = procProc.processNumber;
        rowProconProc['STATUS DO PROCESSO DO PROCON'] = procProc.status;

        proconProcessWorksheet.addRow(rowProconProc);
      });

      // PREENCHENDO DADOS PROPRIEDADES
      const rowProperties = {};
      const properties = BD.bigData.company.properties;

      properties?.map(property => {
        rowProperties['CNPJ'] = BD.cnpj;
        rowProperties['FANTASY NAME'] = BD.fantasyName;
        rowProperties['DADOS DO IMÓVEL'] = property.buildingData;
        rowProperties['ÁREA CONSTRUÍDA'] = property.builtArea.toString();
        rowProperties['COMPLEMENTO'] = property.complement;
        rowProperties['VALOR DE AVALIAÇÃO'] = getCurrencyPtBr(
          property.evaluationValue ?? 0,
        );
        rowProperties['ÁREA DO TERRENO'] = property.groundArea.toString();
        rowProperties['MUNICÍPIO'] = property.municipality;
        rowProperties['BAIRRO'] = property.neighborhood;
        rowProperties['NÚMERO DO BAIRRO'] = property.neighborhoodNumber;
        rowProperties['UF DO REGISTRO'] = property.registerUf;
        rowProperties['NÚMERO DO REGISTRO'] = property.registryNumber;
        rowProperties['RUA'] = property.street;
        rowProperties['UF'] = property.uf;
        rowProperties['CEP'] = property.zip;

        propertiesWorksheet.addRow(rowProperties);
      });

      // PREENCHENDO DADOS ESCOLA
      const rowSchool = {};
      const schools = BD.bigData.company.schools;

      schools?.map(school => {
        rowSchool['CNPJ'] = BD.cnpj;
        rowSchool['FANTASY NAME'] = BD.fantasyName;
        rowSchool['CATEGORIA'] = school.category;
        rowSchool['STATUS DE FUNCIONAMENTO'] = school.functioning;
        rowSchool['LINK DO SITE'] = school.link;
        rowSchool['NOME'] = school.name;
        rowSchool['TOTAL DE MAATRÍCULAS'] = school.totalEnrollment.toString();

        schoolsWorksheet.addRow(rowSchool);
      });

      // PREENCHENDO DADOS SINTEGRA
      const rowSint = {};
      const sintegras = BD.bigData.company.sintegraInscriptions;

      sintegras?.map(sintegra => {
        rowSint['CNPJ'] = BD.cnpj;
        rowSint['FANTASY NAME'] = BD.fantasyName;
        rowSint['NÚMERO DA INSCRIÇÃO'] = sintegra.registrationNumber;
        rowSint['SITUAÇÃO DA INSCRIÇÃO'] = sintegra.registrationSituation;
        rowSint['DATA DA SITUAÇÃO DA INSCRIÇÃO'] = getDatePtBr(
          sintegra.registrationSituationDate ?? '',
        );
        rowSint['REGIME'] = sintegra.regime;
        rowSint['EMAIL'] = sintegra.email;
        rowSint['TELEFONE'] = sintegra.phone;
        rowSint['UF'] = sintegra.uf;

        sintegraWorksheet.addRow(rowSint);
      });

      // PREENCHENDO DADOS SAUDE FINANCEIRA
      const rowTaxHealthCnds = {};
      const taxHealthCnds = taxhealth?.cnds;

      taxHealthCnds?.map(tHCnds => {
        rowTaxHealthCnds['CNPJ'] = BD.cnpj;
        rowTaxHealthCnds['FANTASY NAME'] = BD.fantasyName;
        rowTaxHealthCnds['NÚMERO DO CERTIFICADO'] = tHCnds.certificateNumber;
        rowTaxHealthCnds['DATA DE CRIAÇÃO'] = getDatePtBr(tHCnds.document?.created);
        rowTaxHealthCnds['A EXTENSÃO'] = tHCnds.document?.ext;
        rowTaxHealthCnds['NOME'] = tHCnds.document?.name;
        rowTaxHealthCnds['TIPO'] = tHCnds.document?.type;
        rowTaxHealthCnds['URL'] = tHCnds.document?.url;
        rowTaxHealthCnds['NOME DO EMISSOR'] = tHCnds.emitterName;
        rowTaxHealthCnds['DATA DA EMISSÃO'] = getDatePtBr(
          tHCnds.emissionDate ?? '',
        );
        rowTaxHealthCnds['DATA DA EXPEDIÇÃO'] = getDatePtBr(
          tHCnds.expirationDate ?? '',
        );
        rowTaxHealthCnds['SITUAÇÃO'] = tHCnds.situation;

        taxHealthCNDSWorksheet.addRow(rowTaxHealthCnds);
      });

      // PREENCHENDO DADOS WORK MTE
      const rowWorkMte = {};
      const workMTEs = BD.bigData.company.workMtes;

      workMTEs?.map(workMTE => {
        rowWorkMte['CNPJ'] = BD.cnpj;
        rowWorkMte['FANTASY NAME'] = BD.fantasyName;
        rowWorkMte['COMPLEMENTO'] = workMTE.complement;
        rowWorkMte[
          'ANO DA AÇÃO FISCAL DO MINISTÉRIO DO TRABALHO'
        ] = workMTE.fiscalActionYear.toString();
        rowWorkMte['MUNICÍPIO ONDE OCORREU AÇÃO FISCAL'] = workMTE.municipality;
        rowWorkMte['BAIRRO ONDE OCORREU AÇÃO FISCAL'] = workMTE.neighborhood;
        rowWorkMte['DATA DE DECISÃO DE PROCEDÊNCIA DA AÇÃO FISCAL'] = getDatePtBr(
          workMTE.provenanceDecisionDate ?? '',
        );
        rowWorkMte[
          'QUANTIDADE DE TRABALHADORES ENVOLVIDOS NA AÇÃO FISCAL'
        ] = workMTE.quantityWorkers.toString();
        rowWorkMte['UF'] = workMTE.uf;

        workMTEsWorksheet.addRow(rowWorkMte);
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_big_data.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcel,
  };
};
