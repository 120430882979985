import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  EmailService,
  IEmailDefinePasswordPayload,
  IEmailDefinePasswordResponse,
} from 'modules/email/services';

const useSendEmailDefinePasswordRequest = (
  options?: UseMutationOptions<
    IEmailDefinePasswordResponse,
    IApiAxiosError,
    IEmailDefinePasswordPayload
  >,
) => {
  return useMutation(
    'sendEmailDefinePassword',
    EmailService.definePassword,
    options,
  );
};

export { useSendEmailDefinePasswordRequest };
