import { Route, RouteProps, useHistory } from 'react-router-dom';

import { UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { useSignInStore } from 'modules/signIn/store/store';

import { PublicLayout } from '.';

function PublicLayoutRoute({ component: Component, ...rest }: RouteProps) {
  const history = useHistory();
  const { isAuthenticated, user } = useAuth();
  const { step } = useSignInStore();

  const validGetRoute = isAuthenticated() && step !== 2;

  if (validGetRoute && user) {
    history.push(user.userType === UserTypes.CONSULTANT ? '/customers' : '/home');
  }

  return (
    <Route
      {...rest}
      render={props => (
        <PublicLayout.Root>
          {/* <PublicLayout.Header /> */}
          <PublicLayout.Content>
            {Component ? <Component {...props} /> : null}
          </PublicLayout.Content>
          {/* <PublicLayout.Footer /> */}
        </PublicLayout.Root>
      )}
    />
  );
}

export default PublicLayoutRoute;
