import { Card, Table, TableCell } from '@mui/material';
import html2canvas from 'html2canvas';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import themes from 'themes';

import { Box, TableBody, TableContainer, TableRow, Typography } from 'components';

import { BillingMonthly } from 'modules/dashboard/services';
import { useDashboardStore } from 'modules/dashboard/store/store';

import { isEven } from 'utils/isEven';

interface TableItem {
  nfValueN2: number;
  nfValueN2Acc: number;
  nfValueN1: number;
  nfValueN1Acc: number;
  nfValueN: number;
  nfValueNAcc: number;
  month: string;
}

interface MonthBillingProps {
  data: BillingMonthly[];
  isLoading: boolean;
  noData: boolean;
}

// Função principal para processar os dados
export function processData(data: BillingMonthly[]): TableItem[] {
  const months = Array.from({ length: 12 }, (_, i) => i);

  const currentYear = new Date().getFullYear();

  return months.map(month => calculateValuesForMonth(data, month, currentYear));
}

// Função para calcular os valores de nfValueN, nfValueN1, e nfValueN2
function calculateValuesForMonth(
  data: BillingMonthly[],
  month: number,
  currentYear: number,
): TableItem {
  const result: TableItem = {
    nfValueN2: 0,
    nfValueN2Acc: 0,
    nfValueN1: 0,
    nfValueN1Acc: 0,
    nfValueN: 0,
    nfValueNAcc: 0,
    month: '',
  };

  const previousYearData = data.filter(
    item => new Date(item.date).getFullYear() === currentYear - 1,
  );
  const twoYearsData = data.filter(
    item => new Date(item.date).getFullYear() === currentYear - 2,
  );
  const threeYearsAgoData = data.filter(
    item => new Date(item.date).getFullYear() === currentYear - 3,
  );

  const previousYearMonthData = previousYearData.filter(
    item => new Date(item.date).getMonth() === month,
  );
  const twoYearsMonthData = twoYearsData.filter(
    item => new Date(item.date).getMonth() === month,
  );
  const threeYearsAgoMonthData = threeYearsAgoData.filter(
    item => new Date(item.date).getMonth() === month,
  );

  const monthShort = new Date(currentYear, month, 1).toLocaleString('pt-BR', {
    month: 'short',
  });

  result.month =
    monthShort.charAt(0).toUpperCase() +
    monthShort.slice(1).toLowerCase().replace('.', '');

  if (previousYearMonthData.length > 0) {
    const nfValueN = previousYearMonthData.reduce(
      (acc, curr) => acc + curr.nfValue,
      0,
    );
    result.nfValueN = nfValueN;
  }

  if (twoYearsMonthData.length > 0) {
    result.nfValueN1 = twoYearsMonthData.reduce(
      (acc, curr) => acc + curr.nfValue,
      0,
    );
  }

  if (threeYearsAgoMonthData.length > 0) {
    result.nfValueN2 = threeYearsAgoMonthData.reduce(
      (acc, curr) => acc + curr.nfValue,
      0,
    );
  }

  const monthNAcc = sumNfValuesByMonthAndYear(month + 1, currentYear - 1, data);
  const monthN1Acc = sumNfValuesByMonthAndYear(month + 1, currentYear - 2, data);
  const monthN2Acc = sumNfValuesByMonthAndYear(month + 1, currentYear - 3, data);

  result.nfValueNAcc = monthNAcc;
  result.nfValueN1Acc = monthN1Acc;
  result.nfValueN2Acc = monthN2Acc;

  return result;
}

function sumNfValuesByMonthAndYear(
  month: number,
  year: number,
  data: BillingMonthly[] | undefined,
) {
  const filteredData = data?.filter(item => {
    const itemDate = new Date(item.date);
    const itemMonth = itemDate.getMonth() + 1; // Mês começa de zero, então add 1
    const itemYear = itemDate.getFullYear();
    // Verifica se o ano é igual ao ano fornecido e se o mês é igual ou anterior ao mês fornecido
    return itemYear === year && itemMonth <= month;
  });

  if (!filteredData) {
    return 0;
  }

  // Calcula a soma dos valores nfValue dos objetos filtrados
  const sum = filteredData.reduce((total, item) => total + item.nfValue, 0);

  return sum;
}

const MonthlyBillingTable = ({ data, isLoading, noData }: MonthBillingProps) => {
  const { setTablesImagesUrl } = useDashboardStore();
  const tableRef = useRef<HTMLDivElement>(null);

  const captureComponent = useCallback(async (ref, key) => {
    if (ref.current) {
      const canvas = await html2canvas(ref.current);
      const imageData = canvas.toDataURL('image/png');

      setTablesImagesUrl(key, imageData, canvas.width, canvas.height);
    }
  }, []);

  useEffect(() => {
    const captureTable = async () => {
      await captureComponent(tableRef, 'monthlyBilling');
    };

    if (tableRef) {
      captureTable();
    }
  }, []);

  const emptyString = '---';
  const sizeRow = 40;
  const headerRow = 50;
  const currentYear = new Date().getFullYear();
  const heightWindow = window.innerHeight;
  const widthTable = heightWindow > 900 ? '97%' : '95.8%';
  const renderSecondLine = heightWindow > 900;

  // Resultado final
  const result = processData(data ?? []);

  const getHead = (yearTitle: string) => {
    return (
      <TableCell
        align="center"
        width={'30%'}
        style={{
          alignItems: 'center',
          padding: 0,
          borderLeft: `1px solid ${themes.colors.white}`,
        }}
        sx={{ height: headerRow, maxHeight: headerRow }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignContent={'center'}
          width={'100%'}
          padding={0}
          maxHeight={headerRow}
        >
          <Box
            display={'flex'}
            justifyContent={'center'}
            // borderRight={`1px solid ${themes.colors.white}`}
            width={'43%'}
            maxHeight={headerRow}
          >
            <Typography
              color={themes.colors.white}
              fontWeight={700}
              style={{
                minWidth: '50%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'Fat. mensal'}
              {renderSecondLine && (
                <>
                  <br />
                  {`${yearTitle}`}
                </>
              )}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            width={'57%'}
            minHeight={headerRow}
            justifyContent={'center'}
            borderLeft={`1px solid ${themes.colors.white}`}
          >
            <Typography
              color={themes.colors.white}
              fontWeight={700}
              style={{
                minWidth: '50%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {'Fat. mensal acumulado'}
              {renderSecondLine ? (
                <>
                  <br />
                  {`${yearTitle}`}
                </>
              ) : (
                <>
                  <> {` `}</>
                  <> {`${yearTitle}`}</>
                </>
              )}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    );
  };

  const getYearValues = (value: number, valueAcc: number) => {
    return (
      <TableCell
        align="center"
        style={{
          //borderTop: `1px solid ${themes.colors.black}`,
          padding: 0,
        }}
        sx={{
          height: sizeRow,
          maxHeight: sizeRow,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          width={'100%'}
          padding={0}
          // borderRight={`1px solid ${themes.colors.black}`}
          // borderBottom={`1px solid ${themes.colors.black}`}
        >
          <Box
            //borderRight={`1px solid ${themes.colors.black}`}
            width={'43%'}
            minHeight={sizeRow}
            alignContent={'center'}
          >
            <Typography
              fontWeight={500}
              fontSize={15}
              color={themes.colors.dashboard.table.text.black}
            >
              {value === 0 || undefined
                ? emptyString
                : value.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
            </Typography>
          </Box>
          <Box width={'57%'} minHeight={sizeRow} alignContent={'center'}>
            <Typography
              fontWeight={700}
              fontSize={15}
              color={themes.colors.dashboard.table.text.black}
            >
              {valueAcc === 0 || undefined
                ? emptyString
                : valueAcc.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    );
  };

  return (
    <Card style={{ width: widthTable, padding: 25, margin: 0 }}>
      <Typography
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        color={themes.colors.dashboard.table.header.main}
      >
        Faturamento Mensal
      </Typography>
      <div ref={tableRef}>
        <TableContainer sx={{ padding: 0, margin: 0, mt: 2 }}>
          <Table>
            <TableRow
              hover={false}
              sx={{
                height: headerRow,
                maxHeight: headerRow,
                padding: 0,
                backgroundColor: themes.colors.dashboard.table.header.main,
              }}
            >
              <TableCell
                align="center"
                style={{
                  width: '4%',
                  borderRight: `1px solid ${themes.colors.white}`,
                }} //border: `1px solid ${themes.colors.black}`
                sx={{
                  height: headerRow,
                  maxHeight: headerRow,
                  padding: 0,
                }}
              >
                <Typography fontWeight={700} color={themes.colors.white}>
                  Mês
                </Typography>
              </TableCell>

              {getHead(`${currentYear - 3}`)}
              {getHead(`${currentYear - 2}`)}
              {getHead(`${currentYear - 1}`)}
            </TableRow>

            <TableBody
              columns={7}
              isLoading={isLoading}
              empty={noData}
              emptyMessage="Nenhuma informação do faturamento mensal"
            >
              {result.map((monthStatement, index) => (
                <Fragment key={monthStatement.month}>
                  <TableRow
                    hover={false}
                    sx={{
                      backgroundColor: isEven(index)
                        ? '#FFF'
                        : `${themes.colors.dashboard.table.body.tertiary}`,
                      height: sizeRow,
                      maxHeight: sizeRow,
                    }}
                  >
                    <TableCell
                      align="center"
                      style={{ padding: 0 }}
                      sx={{
                        height: sizeRow,
                        maxHeight: sizeRow,
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={'100%'}
                        padding={0}
                        // borderLeft={`1px solid ${themes.colors.black}`}
                        // borderRight={`1px solid ${themes.colors.black}`}
                        //borderBottom={`1px solid ${themes.colors.black}`}
                        minHeight={sizeRow}
                      >
                        <Typography
                          fontWeight={500}
                          color={themes.colors.dashboard.table.text.black}
                        >
                          {monthStatement.month}
                        </Typography>
                      </Box>
                    </TableCell>
                    {getYearValues(
                      monthStatement.nfValueN2,
                      monthStatement.nfValueN2Acc,
                    )}
                    {getYearValues(
                      monthStatement.nfValueN1,
                      monthStatement.nfValueN1Acc,
                    )}
                    {getYearValues(
                      monthStatement.nfValueN,
                      monthStatement.nfValueNAcc,
                    )}
                  </TableRow>
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
};

export { MonthlyBillingTable };
