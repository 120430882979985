import { Grid, Typography } from '@mui/material';

import { CompanyScr } from 'models/enterprise/scr.types';

import { ScrModalItem } from './ScrModalItem';

interface Props {
  scr: CompanyScr;
}

const ScrModalData = ({ scr }: Props) => {
  const scrData = scr.scr;

  return (
    <Grid container spacing={4} gap={8}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Informações gerais
          </Typography>
        </Grid>
        <ScrModalItem title="Documento" value={scr.document} />
        <ScrModalItem title="Data da última consulta" value={scr.lastConsultDate} />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Lista SCR
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {scrData.scrInformation?.scr?.map(scr => (
            <Grid item xs={12} lg={6} key={scr.consultDate}>
              <ScrModalItem title="Data da consulta" value={scr.consultDate} />
              <ScrModalItem
                title="Quantidade de operações"
                value={scr.countOperation.toString()}
              />
              <ScrModalItem
                title="Quantidade de instituições"
                value={scr.countInstitution.toString()}
              />
              <ScrModalItem
                title="Quantidade de operações sub judice"
                value={scr.countOperationSubJudice.toString()}
              />
              <ScrModalItem
                title="O total de responsabilidade sub judice"
                value={scr.responsibilityTotalSubJudice.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              />
              <ScrModalItem
                title="Quantidade de operações com discordância"
                value={scr.countOperationDisagreement.toString()}
              />
              <ScrModalItem
                title="O total de responsabilidade com discordância"
                value={scr.responsibilityTotalDisagreement.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              />
              <ScrModalItem
                title="Obrigação assumida"
                value={scr.assumedObligation.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              />
              <ScrModalItem
                title="Risco indireto do fornecedor"
                value={scr.vendorIndirectRisk.toString()}
              />
              <ScrModalItem
                title="Porcentagem de documentos processados"
                value={scr.percentDocumentProcessed.toLocaleString('pt-br', {
                  style: 'percent',
                  maximumFractionDigits: 2,
                })}
              />
              <ScrModalItem
                title="Porcentagem de volume processado"
                value={scr.percentVolumeProcessed.toLocaleString('pt-br', {
                  style: 'percent',
                  maximumFractionDigits: 2,
                })}
              />
              <ScrModalItem
                title="As informações foram encontradas e não contêm erros"
                value={scr.find ? 'Sim' : 'Não'}
              />
              <ScrModalItem
                title="Data de inicio do relacionamento"
                value={new Date(scr.startRelationshipDate).toLocaleDateString(
                  'pt-br',
                )}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            SCR - Método K
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {scrData.scrMethodK?.map(scrMethodK => (
            <Grid item xs={12} key={scrMethodK.consultDate}>
              <Grid container spacing={2}>
                <ScrModalItem
                  title="Data da consulta"
                  value={new Date(scrMethodK.consultDate).toLocaleDateString(
                    'pt-br',
                  )}
                />
                <ScrModalItem
                  title="Quantidade de instituições"
                  value={scrMethodK.amountInstitutions.toString()}
                />
                <ScrModalItem
                  title="Quantidade de operações"
                  value={scrMethodK.amountOperations.toString()}
                />
                <ScrModalItem
                  title="Volume vencido"
                  value={scrMethodK.expiredVolume.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      fontWeight={700}
                      fontSize={20}
                      style={{ marginTop: 40 }}
                    >
                      SCR - Método K - Resumo -{' '}
                      {new Date(scrMethodK.consultDate).toLocaleDateString('pt-br')}
                    </Typography>
                  </Grid>
                </Grid>

                {scrMethodK.sumaries.map(summary => (
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    key={`${scrMethodK.consultDate}-${summary.productCode}`}
                  >
                    <ScrModalItem
                      title="Código do produto"
                      value={summary.productCode}
                    />
                    <ScrModalItem
                      title="Nome do produto"
                      value={summary.productName}
                    />
                    <ScrModalItem
                      title="Total com menos de 90 dias"
                      value={summary.totalLessThan90d.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    />
                    <ScrModalItem
                      title="Total entre 91 e 180 dias"
                      value={summary.totalBetween91and180d.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    />
                    <ScrModalItem
                      title="Total entre 181 e 360 dias"
                      value={summary.totalBetween181and360d.toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        },
                      )}
                    />
                    <ScrModalItem
                      title="Total entre 361 e 720 dias"
                      value={summary.totalBetween361and720d.toLocaleString(
                        'pt-br',
                        {
                          style: 'currency',
                          currency: 'BRL',
                        },
                      )}
                    />
                    <ScrModalItem
                      title="Total acima de 720 dias"
                      value={summary.totalGreaterThan720d.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    />
                    <ScrModalItem
                      title="Total"
                      value={summary.total.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={20}>
              Dados de Endividamento
            </Typography>
          </Grid>
          <ScrModalItem
            title="Identificador de documento de análise"
            value={scr.documentOcrIndebtedness?.documentId}
          />
          <ScrModalItem
            title="Valor total"
            value={scr.documentOcrIndebtedness?.total?.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })}
          />
          <Grid container spacing={2}>
            {scr.documentOcrIndebtedness?.indebtednesses?.map(indebtedness => (
              <Grid item xs={12} lg={6} key={indebtedness.id}>
                <ScrModalItem title="Garantia" value={indebtedness.guarantee} />
                <ScrModalItem
                  title="Nome da instituição"
                  value={indebtedness.institution}
                />
                <ScrModalItem
                  title="Taxa"
                  value={indebtedness.tax.toLocaleString('pt-br', {
                    style: 'percent',
                    maximumFractionDigits: 2,
                  })}
                />
                <ScrModalItem
                  title="Valor"
                  value={indebtedness.value.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScrModalData;
