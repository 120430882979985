import { Divider, Grid, Typography } from '@mui/material';

import { ICompanyKMethod } from 'models';

import { KMethodItem } from './KMethodItem';

interface VetoesAndAlertsProps {
  storage: ICompanyKMethod;
}

const VetoesAndAlerts = ({ storage }: VetoesAndAlertsProps) => {
  return (
    <>
      <Grid xs={12} marginTop={1}>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <Typography fontWeight={700} fontSize={20} marginTop={1}>
          VETOS E ALERTAS
        </Typography>
      </Grid>

      <Grid xs={12} mt={2} mb={2}>
        <Typography fontWeight={700} color="grey" fontSize={20}>
          Vetos
        </Typography>
        {storage?.vetoesAndAlerts?.vetoes?.map(vetoes => (
          <Grid container key={`${vetoes?.id}`}>
            <KMethodItem title="Descrição" value={vetoes?.description} />
          </Grid>
        ))}
      </Grid>

      <Typography fontWeight={700} color="grey" fontSize={20}>
        Alertas
      </Typography>
      {storage?.vetoesAndAlerts?.alerts?.map(alert => (
        <>
          <Grid xs={12}>
            <Typography fontWeight={700} color="grey" fontSize={20}>
              Nível
            </Typography>
          </Grid>

          <Grid container>
            <KMethodItem title="Nível" value={String(alert.level)} />
            <KMethodItem title="Score" value={String(alert.score)} />
            <KMethodItem title="Descrição" value={alert.description} />
          </Grid>
        </>
      ))}
    </>
  );
};

export { VetoesAndAlerts };
