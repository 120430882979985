import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export interface IVariableForm {
  title: string | null;
}

export const useVariableForm = () => {
  const userSchema = z.object({
    title: z.any().optional(),
  });

  const defaultValues = {
    title: '' || null,
  };

  return useForm<IVariableForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userSchema),
  });
};
