import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  ForgotPasswordService,
  IForgotPasswordEmailPayload,
  IValidateEmailResponse,
} from '../services';

const useForgotPasswordEmailRequest = (
  options: UseMutationOptions<
    IValidateEmailResponse,
    IApiAxiosError,
    IForgotPasswordEmailPayload
  >,
) => {
  return useMutation(
    'forgotPassword-isEmailValid',
    ForgotPasswordService.isEmailValid,
    options,
  );
};

export { useForgotPasswordEmailRequest };
