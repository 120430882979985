import { stringify } from 'qs';

import {
  IOptionCustomerOperationFilter,
  IPagination,
  IPaginationParams,
} from 'models';

import api from 'config/api';

import { ISort } from 'utils/ordination';

import { IOperationResponse, IUpdateOperationStatusPayload } from './types';

export interface ListOperationsPayload extends IPaginationParams {
  search: string;
  active?: boolean[];
  operationType?: string[];
  creditor?: string[];
  responsible?: string[];
  lastAct?: string[];
  enterprise?: string[];
  orderBy?: string;
  status?: string;
  sort?: ISort;
}

export class OperationService {
  static async getPaginatedOperations(params: ListOperationsPayload) {
    const queryString = stringify(params);

    const { data } = await api.get<
      IPagination<IOperationResponse> | IOperationResponse[]
    >(`/operation?${queryString}`);

    return data;
  }

  static async updateOperationStatus({
    operationIds,
    newStatus,
  }: IUpdateOperationStatusPayload) {
    const { data } = await api.put<{ data: { affected: number } }>(
      `/operation/status`,
      {
        operationIds,
        newStatus,
      },
    );

    return data;
  }

  static async listOptionsCompanysOperationFilter() {
    const { data } = await api.get<IOptionCustomerOperationFilter[]>(
      `/configs-user/list-options/operation/filter`,
    );
    return data;
  }
}
