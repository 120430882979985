import { Avatar } from '@mui/material';
import { MouseEvent } from 'react';

import { Box, Icon, Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import { ButtonWrapper } from './styles';

interface ButtonProps {
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

export function Button({ onClick }: ButtonProps) {
  const { user } = useAuth();

  return (
    <ButtonWrapper onClick={onClick}>
      <Avatar>{user?.name[0] ?? 'U'}</Avatar>

      <Box>
        <Typography fontSize="m" fontWeight="800" textAlign="left" color="black">
          {user?.name}
        </Typography>
        <Typography fontSize="s" textAlign="left" color="gray-300">
          {user?.role.name}
        </Typography>
      </Box>

      <Icon color="black" fontSize="m">
        keyboard_arrow_down
      </Icon>
    </ButtonWrapper>
  );
}
