import {
  KMethodDialogFilter,
  KMethodSearchContainer,
  KMethodTable,
} from '../components';

const KMethodPage = () => {
  return (
    <>
      <KMethodSearchContainer />
      <KMethodDialogFilter />
      <KMethodTable />
    </>
  );
};

export { KMethodPage };
