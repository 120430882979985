import { Box, Grid } from '@mui/material';

import { Dialog } from 'components';
import { ICompanyKMethod } from 'models';

import { Analysis } from './Analysis';
import { CompanyInfo } from './CompanyInfo';
import { KMethod } from './KMethod';
import { SpedBalance } from './SpedBalance';
import { VetoesAndAlerts } from './VetoesAndAlerts';

interface Props {
  storage: ICompanyKMethod;
  open: boolean;
  closeDialog: () => void;
}

export const KMethodModal = ({ storage, open, closeDialog }: Props) => {
  return (
    <Dialog.Root open={open} onClose={closeDialog}>
      <Dialog.Header title="Método K" />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          gap={8}
          alignItems="center"
          paddingX={5}
        >
          <Grid container>
            <CompanyInfo storage={storage} />
            <KMethod storage={storage} />
            <Analysis storage={storage} />
            <VetoesAndAlerts storage={storage} />
            {storage?.actives?.length > 0 && (
              <SpedBalance storage={storage.actives} title="Ativos" />
            )}
            {storage?.passives?.length > 0 && (
              <SpedBalance storage={storage.passives} title="Passivos" />
            )}
            {storage?.demonstrations?.length > 0 && (
              <SpedBalance
                storage={storage.demonstrations}
                title="Demonstrativos"
              />
            )}
            {storage?.indexesAndIndicators?.length > 0 && (
              <SpedBalance
                storage={storage.indexesAndIndicators}
                title="Índices e Indicadores"
              />
            )}
          </Grid>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};
