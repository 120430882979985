import { ReactNode } from 'react';

import { RootWrapper } from './styles';

interface RootProps {
  children: ReactNode;
}

export function Root({ children }: RootProps) {
  return <RootWrapper>{children}</RootWrapper>;
}
