import { ReactNode } from 'react';

import { RootWrapper } from './styles';

interface MenuProps {
  children: ReactNode;
  anchor: HTMLElement | null;
  open: boolean;
  onClose: () => void;
}

export function Root({ children, anchor, open, onClose }: MenuProps) {
  return (
    <RootWrapper
      anchorEl={anchor}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {children}
    </RootWrapper>
  );
}
