import { SelectChangeEvent } from '@mui/material/Select';
import Delta from 'quill-delta';
import { useState } from 'react';
import ReactQuill from 'react-quill';

import {
  IConditionQuestion,
  IConditionTypeInput,
  IQuestionForm,
  IVariable,
} from '../services';
import { useFormStore } from '../store/store';
import { useGetFormsQuestion } from './useGetFormQuestions';

export const useCardConditionMultipleChoice = () => {
  const [answer, setAnswer] = useState<string | number | boolean>('');
  const [openSelect, setOpenSelect] = useState(false);
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();
  const [expression, setExpression] = useState<Delta>({} as Delta);

  const handleChange = (
    question: IQuestionForm,
    identifierCondition: number,
    event: SelectChangeEvent<typeof answer>,
  ) => {
    setAnswer(event.target.value);
    handleSeletectedCondition(question, identifierCondition, event.target.value);
  };

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
  };

  function handleSeletectedCondition(
    question: IQuestionForm,
    identifierCondition: number,
    value: string | number | boolean,
  ) {
    const initialIndex = 0;
    const indexQuestionForm = question.numberQuestion - 1;
    const indexConditionForm = identifierCondition
      ? identifierCondition - 1
      : initialIndex;

    const answer = question.question.answerOptions.find(
      answer => answer.label === value,
    );

    if (!identifierCondition) {
      questionsForm[indexQuestionForm].conditions = [
        {
          identifierCondition: 1,
          openDropdown: false,
          variables: [] as IVariable[],
          condition: answer?.value ?? '',
          conditionTypeInput: {} as IConditionTypeInput,
          showOptionFalse: false,
        },
      ];
      setQuestionsForm(questionsForm);
      return;
    }
    questionsForm[indexQuestionForm].conditions[indexConditionForm].condition =
      answer?.value ?? '';
    setQuestionsForm(questionsForm);
  }

  function handleOpenDropdownCondition(
    identifierQuestion: number,
    identifierCondition: number,
  ) {
    const indexQuestion = identifierQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const conditionDropdown =
      questionsForm[indexQuestion]?.conditions[indexCondition]?.openDropdown;

    if (conditionDropdown === undefined || conditionDropdown == false) {
      questionsForm[indexQuestion].conditions[indexCondition].openDropdown = true;
      setQuestionsForm(questionsForm);
      return;
    }
    questionsForm[indexQuestion].conditions[indexCondition].openDropdown = false;
    setQuestionsForm(questionsForm);
  }

  function handleDeleteCardCondition(
    identifierQuestion: number,
    identifierCondition: number,
  ) {
    const indexQuestion = identifierQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const idQuestionChanged = //deletando a false intern da condition
      questionsForm[indexQuestion].conditions[indexCondition]?.nextConditionFalse;

    const questionChildExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged,
    );

    if (questionChildExcluded)
      handleDeleteSpecificChildQuestion(questionChildExcluded);

    const questionVariables = //deletando todas variaveis daquela condition
      questionsForm[indexQuestion].conditions[indexCondition]?.variables;

    questionVariables.map(variable => {
      const questionChildExcluded = questionsForm.find(
        qF => qF?.question?.id === variable?.nextQuestion,
      );

      if (questionChildExcluded)
        handleDeleteSpecificChildQuestion(questionChildExcluded);
    });

    questionsForm[indexQuestion].conditions[
      indexCondition
    ] = {} as IConditionQuestion;

    questionsForm[indexQuestion].conditions[
      indexCondition
    ].identifierCondition = -1; //deletado

    setQuestionsForm(questionsForm);
  }

  function handleAddVariable(question: IQuestionForm, identifierCondition: number) {
    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const variables = question.conditions[indexCondition].variables;

    const newVariableIdentifier = variables.length + 1;

    questionsForm[indexQuestion].conditions[indexCondition].variables = [
      ...variables,
      {
        identifierVariable: newVariableIdentifier,
        nextQuestion: '',
        nextQuestionLabel: '',
        expression: '',
      } as IVariable,
    ];

    setQuestionsForm(questionsForm);
  }

  function handleRemoveVariable(
    question: IQuestionForm,
    identifierCondition: number,
    identifierVariable: number,
  ) {
    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;
    const indexVariable = identifierVariable - 1;

    const idQuestionChanged = //deletando a false intern da condition
      questionsForm[indexQuestion].conditions[indexCondition].variables[
        indexVariable
      ].nextQuestion;

    const questionChildExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged,
    );

    if (questionChildExcluded)
      handleDeleteSpecificChildQuestion(questionChildExcluded);

    questionsForm[indexQuestion].conditions[indexCondition].variables[
      indexVariable
    ] = {} as IVariable;

    questionsForm[indexQuestion].conditions[indexCondition].variables[
      indexVariable
    ].identifierVariable = -1; //deletado

    setQuestionsForm(questionsForm);
  }

  const handleExpressionChange = (
    deltaExpression: ReactQuill.Value,
    question: IQuestionForm,
    identifierCondition: number,
    identifierVariable?: number,
  ) => {
    setExpression((deltaExpression as unknown) as Delta);

    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;
    let indexVariable;

    if (identifierVariable) {
      indexVariable = identifierVariable - 1;
    }

    questionsForm[indexQuestion].conditions[indexCondition].variables[
      indexVariable
    ].deltaExpression = deltaExpression;

    setQuestionsForm(questionsForm);
  };

  return {
    answer,
    openSelect,
    expression,
    setExpression,
    handleSeletectedCondition,
    handleChange,
    handleCloseSelect,
    handleOpenSelect,
    handleDeleteCardCondition,
    handleOpenDropdownCondition,
    handleAddVariable,
    handleRemoveVariable,
    handleExpressionChange,
  };
};

export type UseCardConditionMultipleChoice = ReturnType<
  typeof useCardConditionMultipleChoice
>;
