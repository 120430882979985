import { TextField } from 'components';

import { useForgotPasswordEmail } from '../hooks/useForgotPasswordEmail';
import FormWrapper from './FormWrapper';

function EmailForm() {
  const {
    form: {
      control,
      formState: { errors },
      handleSubmitForm,
    },
    request: { isLoading },
  } = useForgotPasswordEmail();

  return (
    <FormWrapper
      isLoading={isLoading}
      handleSubmitForm={handleSubmitForm}
      submitFormButtonLabel="Recuperar senha"
    >
      <TextField
        name="email"
        label="Email"
        control={control}
        type="text"
        error={errors.email}
        sx={{
          '& .MuiOutlinedInput-root': {
            zIndex: 0,
            '& input': {
              zIndex: 1,
            },
            '& fieldset': {
              backgroundColor: '#e0e0e0',
              border: 'none',
              borderRadius: '8px',
            },
          },
        }}
      />
    </FormWrapper>
  );
}

export default EmailForm;
