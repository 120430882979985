import { useQuery } from 'react-query';

import { OptionsValuesString } from 'utils/helper';

import { OperationQueryKey, OperationService } from '../services';

export const useListOptionsCompanysOperationFilter = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [OperationQueryKey.LIST_OPTIONS_COMPANY_OPERATION_FILTER],
    () => OperationService.listOptionsCompanysOperationFilter(),
    { enabled, keepPreviousData: true },
  );

  const creditorFilterOptions: OptionsValuesString[] =
    data?.map(({ creditor }) => ({
      value: creditor?.id ?? '',
      label:
        creditor?.bigData?.fantasyName || creditor?.bigData?.socialReason || '',
    })) || [];

  const enterpriseFilterOptions: OptionsValuesString[] =
    data?.map(({ enterprise }) => ({
      value: enterprise?.id,
      label:
        enterprise?.bigData?.fantasyName || enterprise?.bigData?.socialReason || '',
    })) || [];

  return {
    creditorFilterOptions,
    enterpriseFilterOptions,
    data,
    isLoading,
    refetch,
  };
};
