import { IProcessAct } from 'models';

import api from 'config/api';

import { ICreateProcessAct, IUpdateProcessAct, IUpdateResponsible } from './types';

class TabActServices {
  static async createProcessAct({ body, selectedOperations }: ICreateProcessAct) {
    const { data } = await api.post<IProcessAct>(`process-act`, body, {
      params: { selectedOperations },
    });

    return data;
  }

  static async update({ id, body }: IUpdateProcessAct) {
    const { data } = await api.put<IProcessAct>(`process-act/${id}`, body);

    return data;
  }

  static async updateResponsibleAct({ id, newResponsible }: IUpdateResponsible) {
    const { data } = await api.put<IProcessAct>(
      `process-act/update-responsible/${id}`,
      { newResponsible },
    );

    return data;
  }
}

export default TabActServices;
