import { DatabaseDialogFilter } from 'components';

import { useDynamicFormStorageFilter, useDynamicFormStorageSearch } from '../hooks';

const DynamicFormStorageDialogFilter = () => {
  const {
    isFiltering,
    open,
    filterBy,
    currentFilter,
    setOpenFilterDialog,
    setFilterBy,
    resetFilter,
    handleRemoveFilter,
    clearSearchOption,
    handleChangesFilter,
    setSearchOption,
    setCurrentFilter,
  } = useDynamicFormStorageFilter();
  const { handleChangeSearch } = useDynamicFormStorageSearch(500, setSearchOption);

  return (
    <DatabaseDialogFilter
      open={open}
      title={'Configurar Filtro Formulário Dinâmico'}
      isFiltering={isFiltering}
      filterBy={filterBy}
      currentFilter={currentFilter}
      setOpenFilterDialog={setOpenFilterDialog}
      setFilterBy={setFilterBy}
      resetFilter={resetFilter}
      handleRemoveFilter={handleRemoveFilter}
      clearSearchOption={clearSearchOption}
      handleChangesFilter={handleChangesFilter}
      setSearchOption={setSearchOption}
      handleChangeSearch={handleChangeSearch}
      setCurrentFilter={setCurrentFilter}
      pageFilter="formAnswer"
    />
  );
};

export { DynamicFormStorageDialogFilter };
