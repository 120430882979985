import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import styled from 'styled-components';

import { Variant } from './index';

interface RadioWrapperProps {
  variant: Variant;
}

export const RadioIconUnchecked = styled(RadioButtonUnchecked)<RadioWrapperProps>`
  color: ${props => props.theme.colors[props.variant]};
`;

export const RadioIconChecked = styled(RadioButtonChecked)<RadioWrapperProps>`
  color: ${props => props.theme.colors[props.variant]};
`;
