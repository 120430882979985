import { ClientDestineStatus, ProductClient } from 'models/act';
import { RolesTypes } from 'models/roles';
import { IUser } from 'models/user';

import { ICompanyBigData } from './companyBigData.types';

export interface IEnterprise {
  id: string;
  cnpj: string;
  fantasyName: string;
  name: string;
  surname: string;
  email: string;
  password: string;
  telephone: string;
  site: string;
  role: RolesTypes;
  isFinancing: boolean;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
  status: ClientDestineStatus;
  users: IUser[];
  responsible: IUser;
  product: ProductClient[];
  bigData: ICompanyBigData;
  primaryEnterprise: IEnterprise;
}

export const inactiveCustomers = [
  ClientDestineStatus.QUALY_OPPORTUNITY_DISQUALIFIED,
  ClientDestineStatus.PROPOSAL_DENIED,
  ClientDestineStatus.CLOSED_CONTRACT,
];

export interface MainCustomers {
  id: string;
  bigData: { fantasyName?: string; socialReason: string };
}

export interface IOptionCustomerOperationFilter {
  id: string;
  creditor: MainCustomers;
  enterprise: MainCustomers;
}
