import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { IActPayload } from '../services';
import ActServices from '../services/services';

const useCreateActRequest = (
  options?: UseMutationOptions<IActPayload, IApiAxiosError, IActPayload>,
) => {
  return useMutation('createActRequest', ActServices.createAct, options);
};

export { useCreateActRequest };
