import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ClosingOperation,
  ETypeProcessAct,
  FormStatus,
  IAct,
  IFileStorage,
  IProcessAct,
  Operation,
  ProductClient,
  RolesTypes,
  TypeProcessAct,
  UserTypes,
} from 'models';

import { ApiUnauthorizedErrorsTitles, IApiError } from 'config/api/types';
import { useAuth } from 'config/auth/hooks';

import {
  ActStatusPayload,
  useListAllActs,
  useListAllForms,
  useListAllVariables,
} from 'modules/backoffice/acts';
import {
  CustomerQueryKey,
  CustomerService,
} from 'modules/boards/customerManagement';
import {
  OperationQueryKey,
  OperationService,
  useSelectedOperations,
} from 'modules/boards/operations';
import {
  useFilterRequest,
  useListActsAllowedsRequest,
  useTabs,
} from 'modules/boards/tabs/hooks';
import { EFormAnswerType } from 'modules/dynamicForm';
import useFormAnswerStore from 'modules/dynamicForm/formAnswer/store/store';
import { useGetUrlDownloadRequest } from 'modules/dynamicForm/question/hooks';
import { NotificationService } from 'modules/notifications/services';
import {
  PendingIssue,
  PendingIssuesQueryKey,
} from 'modules/pendingIssues/services';
import { SignUpEnterpriseService } from 'modules/signUp';
import { SystemValueMappingService } from 'modules/systemValueMapping';
import { UserService, UserTypesPayload } from 'modules/user';

import { enumToArray } from 'utils/enumToArray';
import { OptionsValuesString } from 'utils/helper';

import { IProcessActPayload, ScreenCalled } from '../service';
import { useTabActsStore } from '../store/store';
import {
  ICreateAct,
  defaultValuesActs,
  useGetStatusAct,
  useCreateProcessActForm,
  useCreateProcessActRequest,
  useSendActEmailRequest,
  useUpdateProcessActRequest,
} from './';

interface Props {
  enabledListAllActs?: boolean;
}

export const useTabAct = ({ enabledListAllActs }: Props = {}) => {
  const user = useAuth();
  const { currentTabInfos, openNegotiationModal } = useTabs();

  const {
    currentAct,
    currentProcessAct,
    attachmentsAct,
    attachmentsAnswer,
    variablesValues,
    renderNewActOption,
    openCreateActDialog,
    typeScreenForm,
    actsOptions,
    opEnterpriseOptions,
    processActs,
    setProcessActs,
    setActsOptions,
    setRenderActOption,
    setOpenCreateActDialog,
    setCurrentAct,
    setCurrentProcessAct,
    setVariablesValues,
    setTypeScreenForm,
    clearTabActsStore,
    setAttachmentsAct,
    setAttachmentsAnswer,
    setOpEnterprisesOptions,
    setScreenCalled,
  } = useTabActsStore();

  const { data, isLoading: loadingActs } = useFilterRequest(openNegotiationModal);

  useEffect(() => {
    setProcessActs(data ?? []);
  }, [data]);

  const history = useHistory();
  const queryClient = useQueryClient();
  const form = useCreateProcessActForm();
  const { formOptions } = useListAllForms({
    enabled: renderNewActOption,
    active: true,
  });
  const { actOptions, dataAllActsAlloweds } = useListActsAllowedsRequest(
    renderNewActOption,
  );
  const { dataAllActs } = useListAllActs({
    enabled: enabledListAllActs ?? false,
  });
  const { variables } = useListAllVariables({ enabled: renderNewActOption });
  const PRODUCTS_CLIENT = enumToArray(ProductClient);
  const OPERATIONS_TYPES = enumToArray(Operation);
  const CLOSING_OPERATIONS_TYPES = enumToArray(ClosingOperation);
  let USER_TYPES = enumToArray(UserTypes);
  USER_TYPES = USER_TYPES.filter(userType => userType.value !== 'CONSULTANT');

  const { checkStatusByAnswers, getStatusActOnCreation } = useGetStatusAct();
  const { setAllowEdit, clearFormAnswerStore } = useFormAnswerStore();

  const { selectedOperations, clearOperationStore } = useSelectedOperations();

  useEffect(() => {
    getActOptionsAllowEmit();
  }, [dataAllActsAlloweds]);

  useEffect(() => {
    form.setValue(
      'createOpValues',
      findOptionLabelArray(
        currentProcessAct?.createOpValues ?? [],
        opEnterpriseOptions,
      ),
    );
    form.setValue('valueOp', currentProcessAct?.valueOp ?? '');
    form.setValue('chargeLink', currentProcessAct?.chargeLink ?? '');
    form.setValue('motiveEnd', currentProcessAct?.motiveEnd ?? '');
  }, [form]);

  useEffect(() => {
    form.setValue('answer', currentProcessAct?.answer ?? '');
  }, [currentProcessAct]);

  const getActOptionsAllowEmit = () => {
    setActsOptions(actOptions);
  };

  const handleCloseDialog = () => {
    setOpenCreateActDialog(false);
    const acts = actsOptions;
    clearTabActsStore();
    setActsOptions(acts);
    form.reset(defaultValuesActs);
  };

  const handleMoreInformation = async (
    id: string,
    screenCalled: ScreenCalled,
    pendingIssue?: PendingIssue,
  ) => {
    const processAct = pendingIssue ?? processActs?.find(act => act.id === id);

    const act = processAct?.act;
    const conf = act?.configs;
    let enterprisesOptions: OptionsValuesString[] = [];
    if (conf?.createOp) {
      enterprisesOptions = await getEnterprises();
    }

    form.reset(defaultValuesActs);
    form.clearErrors();
    form.resetField('answer');

    if (processAct?.isChild && !processAct?.isConfigured) {
      setTypeScreenForm('create');
      setRenderActOption(true);
    } else setTypeScreenForm('edit');

    setScreenCalled(screenCalled);

    let vars: OptionsValuesString[] = [];
    if (processAct?.dbInfo && processAct.dbInfo.length > 0) {
      const data = await SystemValueMappingService.find();
      vars =
        data?.map(variables => ({
          value: variables.id,
          label: `${variables.title}`,
        })) || [];
    }

    if (processAct && act) {
      const infoAct: IAct = {
        ...act,
        configs: {
          ...act.configs,
          formId: processAct?.form?.title,
          info: processAct?.info ?? '',
          dbInfo: findOptionLabelArray(processAct?.dbInfo ?? [], vars),
          productClient: processAct?.productClient ?? [],
          createOpValues: findOptionLabelArray(
            processAct?.createOpValues ?? [],
            enterprisesOptions,
          ),
          operation: processAct?.operationType ?? '',
          valueOp: processAct?.valueOp ?? '',
          scheduleLink: processAct?.scheduleLink ?? '',
          chargeLink: processAct?.chargeLink ?? '',
          deadline: processAct?.deadline ?? '',
          emailTitle: processAct?.emailTitle ?? '',
          emailBody: processAct?.emailBody ?? '',
          motiveEnd: processAct?.motiveEnd ?? '',
        },
      };

      if (conf?.showDbInfo)
        getVariablesValues(
          [...(processAct?.dbInfo ?? [])],
          act?.configs?.clientTypeInfo ?? '',
        );

      setCurrentAct(infoAct ?? ({} as IAct));
      setCurrentProcessAct(processAct);
      const files = processAct.attachments
        ?.filter(att => att.type === ETypeProcessAct.ACT)
        ?.map(att => att.file);
      setAttachmentsAct(files);
      const filesAnswer = processAct.attachments
        ?.filter(att => att.type === ETypeProcessAct.ANSWER)
        ?.map(att => att.file);
      setAttachmentsAnswer(filesAnswer);

      setOpenCreateActDialog(true);
    } else {
      toast.error('Ato do processo nao encontrado');
    }
  };

  const handleNewAct = async (value: OptionsValuesString) => {
    form.reset(defaultValuesActs);
    setTypeScreenForm('create');
    setScreenCalled('acts');

    const act = dataAllActs?.find(act => act.id === value?.value);

    if (act) {
      const conf = act.configs;
      const newAct: IAct = {
        ...act,
        configs: {
          ...act.configs,
          formId: findOptionLabel(conf?.formId ?? '', formOptions),
          dbInfo: findOptionLabelArray(conf?.dbInfo ?? [], variables),
          productClient: conf?.productClient ?? [],
          operation: conf?.operation ?? '',
        },
      };

      if (conf?.showDbInfo)
        getVariablesValues(
          [...(conf?.dbInfo ?? [])],
          act?.configs?.clientTypeInfo ?? '',
        );

      if (conf?.createOp) getEnterprises();

      setCurrentAct(newAct ?? ({} as IAct));

      setOpenCreateActDialog(true);

      return newAct;
    } else {
      toast.error('Ato não encontrado');
    }
  };

  const handleDatasAfterSave = () => {
    queryClient.invalidateQueries('filterProcessActs');
    setOpenCreateActDialog(false);
    setRenderActOption(false);
    setCurrentAct({} as IAct);
    const acts = actsOptions;
    clearTabActsStore();
    setActsOptions(acts);
  };

  const createProcessActRequest = useCreateProcessActRequest({
    onSuccess: async (data, payload) => {
      if (currentAct?.configs.sendEmail) {
        await handleSendEmail(data[0]);
      }

      if (payload.body.operationType || payload.body.valueOp) {
        // atualizar lista de operações...
        queryClient.invalidateQueries([OperationQueryKey.INFINITE_OPERATIONS]);
      }
      const changedProduct =
        payload.body.productClient && payload.body.productClient.length > 0;

      if (changedProduct) {
        queryClient.invalidateQueries([CustomerQueryKey.LIST_CUSTOMERS]);
      }

      toast.success('Ato criado com sucesso!');
      handleDatasAfterSave();
    },
    onError: error => {
      if (error.response?.data.title === 'Operation Closed') {
        toast.error('Pelo menos uma operação selecionada já foi encerrada.');
        return;
      }

      if (
        (error.response?.data.detail as { error: { detail: string }[] }).error[0]
          .detail === 'Child act is inactive'
      ) {
        toast.error('O ato que deve ser gerado está inativo');
        return;
      }

      toast.error('Houve um erro ao tentar criar o ato processual');
    },
  });

  const updateProcessActRequest = useUpdateProcessActRequest({
    onSuccess: () => {
      const conf = currentAct?.configs;
      const changeStatusOperation =
        currentProcessAct?.status === ActStatusPayload.Aberto && conf?.newStatusOp;

      if (changeStatusOperation) {
        queryClient.invalidateQueries([OperationQueryKey.INFINITE_OPERATIONS]);
      }

      toast.success('Ato atualizado com sucesso!');
      handleDatasAfterSave();
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === ApiUnauthorizedErrorsTitles.BAD_REQUEST) {
        toast.error(error.response?.data.detail);
        return;
      }

      toast.error('Houve um erro ao tentar salvar a resposta do ato');
    },
  });

  const sendActEmailRequest = useSendActEmailRequest({
    onError: () => {
      toast.error('Houve um erro ao tentar enviar o email');
    },
  });

  const requestDownloadFile = useGetUrlDownloadRequest({
    onError: () => {
      toast.error('Houve um erro ao tentar baixar o arquivo');
    },
  });

  const handleSaveAttachmentsAct = (files: IFileStorage[]) => {
    setAttachmentsAct([...attachmentsAct, ...files]);
  };

  const handleRemoveAttachmentAct = (id: string) => {
    const newAttachments = attachmentsAct.filter(att => att.id !== id);
    setAttachmentsAct(newAttachments);
  };

  const handleSaveAttachmentsAnswer = (files: IFileStorage[]) => {
    try {
      if (attachmentsAnswer) {
        setAttachmentsAnswer([...attachmentsAnswer, ...files]);
        return;
      }
      setAttachmentsAnswer([...files]);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemoveAttachmentAnswer = (id: string) => {
    const newAttachments = attachmentsAnswer.filter(att => att.id !== id);
    setAttachmentsAnswer(newAttachments);
  };

  const handleDownloadFile = (id: string) => {
    requestDownloadFile.mutate({ idFileStorage: id });
  };

  const handleSendEmail = async (payload: IProcessAct) => {
    const rolesTypes: RolesTypes[] = [];

    currentAct.userViewAndAnswer?.map(user => {
      rolesTypes.push(RolesTypes[user]);
    });

    const usersEnterprise = await UserService.list({
      page: 0,
      perPage: 1000,
      userTypes: [UserTypes.CREDITOR, UserTypes.ENTERPRISE],
      rolesTypes,
      enterpriseId: currentTabInfos.enterpriseId,
    });

    usersEnterprise.data.map(userEnterprise => {
      sendActEmailRequest.mutate({
        email: userEnterprise.email,
        name: userEnterprise.name,
        title: payload.emailTitle,
        bodyActMessage: payload.emailBody,
      });
    });
  };

  const getValue = (
    switchValue: boolean,
    valueConfig: string | null,
    valueAct: string | null,
  ) => {
    if (!switchValue) return null;
    if (!valueConfig && !valueAct) return null;
    if (valueConfig && valueConfig.length > 0) return valueConfig;
    if (valueAct && valueAct.length > 0) return valueAct;

    return null;
  };

  const getValueArray = (
    switchValue: boolean,
    valueConfigArray: string[] | null,
    valueActArray: string[] | null,
  ) => {
    if (!switchValue) return null;
    if (!valueConfigArray && !valueActArray) return null;
    if (valueConfigArray && valueConfigArray.length > 0) return valueConfigArray;
    if (valueActArray && valueActArray.length > 0) return valueActArray;

    return null;
  };

  function findIdsByLabels(array1: string[], array2: OptionsValuesString[]) {
    const idsWithSameLabel: string[] = [];

    array2.forEach(objeto => {
      if (array1.includes(objeto.label)) {
        idsWithSameLabel.push(objeto.value);
      }
    });

    return idsWithSameLabel;
  }

  const getProcessActPayload = (
    data: ICreateAct,
    isOperation?: boolean,
    actInfo?: IAct,
  ): IProcessActPayload => {
    const conf = currentAct?.configs ?? actInfo?.configs;

    const formConfigId = formOptions.find(f => f.label === conf.formId)?.value;
    const formActId = formOptions.find(f => f.label === data.formId)?.value;
    const varConfigIds = findIdsByLabels(conf?.dbInfo ?? [], variables);
    const varActIds = findIdsByLabels(data?.dbInfo ?? [], variables);
    const creditors = findIdsByLabels(
      data?.createOpValues ?? [],
      opEnterpriseOptions,
    );
    let valueOpFixed = data?.valueOp?.replace(/[.$A-Za-z]/g, '');
    valueOpFixed = valueOpFixed?.slice(0, -3);

    const attAct: string[] = [];
    attachmentsAct.map(att => {
      attAct.push(att.id);
    });

    const processActPayload: IProcessActPayload = {
      active: true,
      status: getStatusActOnCreation()
        ? ActStatusPayload.Fechado
        : ActStatusPayload.Aberto,
      typeProcessAct: currentProcessAct.typeProcessAct,
      enterprise: [currentProcessAct?.enterprise?.id],
      operation: currentTabInfos?.operationId ?? null,
      multipleOperationActs: isOperation
        ? selectedOperations.map(op => ({
            operationId: op.id,
            enterpriseId: op.enterprise.id,
            responsibleId: op.responsible.id,
          }))
        : [],
      form: getValue(conf.triggerForm, formConfigId ?? '', formActId ?? ''),
      act: currentAct?.id ?? actInfo?.id ?? '',
      info: getValue(conf.showInfo, conf?.info ?? '', data.info),
      dbInfo: getValueArray(conf.showDbInfo, varConfigIds, varActIds),
      clientTypeInfo: getValue(
        conf.showDbInfo,
        UserTypesPayload[conf?.clientTypeInfo ?? ''],
        UserTypesPayload[data?.clientTypeInfo ?? ''],
      ),
      productClient: getValueArray(
        conf.defineProductClient,
        conf?.productClient ?? [],
        data?.productClient ?? [],
      ),
      createOpValues: getValueArray(conf.createOp, [], creditors),
      operationType: getValue(
        conf.defineOp,
        conf?.operation ?? '',
        data?.operation ?? '',
      ),
      valueOp: getValue(conf.defineValueOp, '', valueOpFixed ?? null),
      scheduleLink: getValue(
        conf.showScheduleLink,
        conf?.scheduleLink ?? '',
        data?.scheduleLink ?? '',
      ),
      deadline: getValue(
        conf.showDeadline,
        conf?.deadline ?? '',
        data?.deadline ?? '',
      ),
      chargeLink: getValue(conf.allowCharge, '', data?.chargeLink),
      emailTitle: getValue(
        conf.sendEmail,
        conf?.emailTitle ?? '',
        data?.emailTitle ?? '',
      ),
      emailBody: getValue(
        conf.sendEmail,
        conf?.emailBody ?? '',
        data?.emailBody ?? '',
      ),
      motiveEnd: getValue(conf.negotiationEnd, '', data?.motiveEnd),
      responsible: currentTabInfos.responsibleId ?? null,
      operator: user?.user?.id ?? null,
      executionDate: null,
      attachments: attAct,
    };

    return processActPayload;
  };

  const checkNeedUpdateStatus = async (
    changeStatusClient: boolean,
    changeStatusOperation: boolean,
  ) => {
    const conf = currentAct?.configs;

    if (changeStatusClient) {
      const enterpriseId =
        currentProcessAct?.enterprise?.id ?? currentTabInfos?.enterpriseId;
      const newStatus = conf?.newStatusClient;

      if (enterpriseId && newStatus) {
        await CustomerService.updateCustomerStatus({
          customerIds: [enterpriseId],
          newStatus,
        });
      }
    }

    if (changeStatusOperation) {
      const opId = currentTabInfos?.operationId ?? '';

      if (conf.newStatusOp && opId && opId.length > 0) {
        await OperationService.updateOperationStatus({
          operationIds: [opId],
          newStatus: conf.newStatusOp,
        });
      }
    }
  };

  const handleSubmitForm = (multipleEnterprises?: string[], newAct?: IAct) => {
    const enterprise = multipleEnterprises ?? [currentTabInfos.enterpriseId];
    const isOperation =
      currentTabInfos.typeOp === TypeProcessAct.OPERATION || !!multipleEnterprises;

    if (enterprise.length === 0) enterprise.push(currentTabInfos.enterpriseId);

    const selectOpsIds = selectedOperations?.map(op => op.id);

    form.handleSubmit(async data => {
      const actInfo = Object.entries(currentAct)?.length > 0 ? currentAct : newAct;

      const processActPayload = getProcessActPayload(data, isOperation, actInfo);

      if (getStatusActOnCreation()) {
        await checkNeedUpdateStatus(
          !!actInfo?.configs?.newStatusClient,
          !!actInfo?.configs?.newStatusOp,
        );
      }

      processActPayload.enterprise = enterprise;
      processActPayload.typeProcessAct = isOperation
        ? TypeProcessAct.OPERATION
        : TypeProcessAct.CLIENT;
      processActPayload.isConfigured = true;

      createProcessActRequest.mutate(
        {
          body: processActPayload,
          selectedOperations: [...selectOpsIds],
        },
        {
          onSuccess: () => {
            const queryKey = isOperation
              ? [OperationQueryKey.INFINITE_OPERATIONS]
              : [CustomerQueryKey.LIST_CUSTOMERS];
            isOperation && clearOperationStore();
            queryClient.invalidateQueries(queryKey);
          },
        },
      );
    })();
  };

  const handleEditForm = form.handleSubmit(async data => {
    const attsAnswerAct: string[] = [];
    attachmentsAnswer?.map(att => {
      attsAnswerAct.push(att.id);
    });

    const processActPayload = getProcessActPayload(data);
    processActPayload.attachmentsAnswer = attsAnswerAct;
    processActPayload.answer =
      data?.answer && data.answer.length > 0 ? data?.answer : null;
    processActPayload.responsible = currentProcessAct?.responsible?.id ?? null;
    processActPayload.operator = currentProcessAct?.operator?.id ?? null;
    processActPayload.isConfigured = true;
    processActPayload.form =
      processActPayload.form ?? currentProcessAct?.form?.id ?? null;
    processActPayload.dbInfo =
      processActPayload.dbInfo ?? currentProcessAct?.dbInfo ?? null;

    const checkStatus = checkStatusByAnswers(data);

    if (checkStatus) {
      // se retornou TRUE, alterar status do ato pra fechado!
      processActPayload.status = ActStatusPayload.Fechado;

      const conf = currentAct?.configs;
      const changeStatusClient =
        currentProcessAct?.status === ActStatusPayload.Aberto &&
        conf?.newStatusClient;
      const changeStatusOperation =
        currentProcessAct?.status === ActStatusPayload.Aberto && conf?.newStatusOp;

      await checkNeedUpdateStatus(!!changeStatusClient, !!changeStatusOperation);

      await NotificationService.create({
        type: 'ACT_CLOSING',
        read: false,
        processAct: currentProcessAct,
        consultantId: currentProcessAct?.responsible?.id,
      });
    }

    updateProcessActRequest.mutate(
      {
        id: currentProcessAct.id ?? '',
        body: processActPayload,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            PendingIssuesQueryKey.LIST_PENDING_ISSUES,
          ]);
        },
      },
    );
  });

  const findOptionLabel = (id: string | null, options: OptionsValuesString[]) => {
    const findedOp = options.find(op => op.value === id);
    if (findedOp) return findedOp.label;
    return '';
  };

  const findOptionLabelArray = (
    ids: string[] | null,
    options: OptionsValuesString[],
  ) => {
    const idsLabels: string[] = [];

    ids?.map(id => {
      const findedOp = options.find(op => op.value === id);
      if (findedOp) idsLabels.push(findedOp.label);
    });

    if (idsLabels.length > 0) return idsLabels;

    return [];
  };

  const getVariablesValues = async (varIds: string[], clientType: string) => {
    let enterpriseId = currentTabInfos?.enterpriseId;

    if (clientType === UserTypesPayload.Credor) {
      enterpriseId = currentTabInfos?.creditorId ?? currentTabInfos?.enterpriseId;
    }

    const dataVar = await SystemValueMappingService.getValueVariable(
      varIds,
      enterpriseId,
    );
    setVariablesValues(dataVar);
  };

  const getEnterprises = async () => {
    try {
      const data = await SignUpEnterpriseService.listEntrprisesByType(true);
      const options: OptionsValuesString[] =
        data
          ?.filter(i => i?.bigData)
          .map(({ id, bigData }) => ({
            value: id,
            label: bigData?.fantasyName || bigData?.socialReason,
          })) || [];

      setOpEnterprisesOptions(options);

      return options;
    } catch (err) {
      toast.error('Houve um error ao tentar listar as empresas');
      return [];
    }
  };

  const pushFormPage = () => {
    const formId = currentProcessAct?.form?.id;

    if (!formId) {
      toast.error('Formulário não encontrado!');
      return;
    }

    clearFormAnswerStore();

    if (currentProcessAct?.formRecord?.status === FormStatus.COMPLETED) {
      setAllowEdit(false);
      history.push(`/form-resume/${formId}/${EFormAnswerType.answer}`);
      setOpenCreateActDialog(false);
      return;
    }
    setAllowEdit(true);
    history.push(`/form-answer/${formId}/${EFormAnswerType.answer}`);
    setOpenCreateActDialog(false);
  };

  return {
    openCreateActDialog,
    user,
    formOptions,
    actOptions: actsOptions,
    creditorsOptions: opEnterpriseOptions,
    variables,
    USER_TYPES,
    PRODUCTS_CLIENT,
    OPERATIONS_TYPES,
    CLOSING_OPERATIONS_TYPES,
    attachmentsAct,
    variablesValues,
    processActs: processActs ?? [],
    loadingActs,
    act: currentAct,
    renderNewActOption,
    currentProcessAct,
    typeScreenForm,
    attachmentsAnswer,
    handleNewAct,
    handleMoreInformation,
    handleCloseDialog,
    handleSaveAttachmentsAct,
    handleRemoveAttachmentAct,
    handleSaveAttachmentsAnswer,
    handleRemoveAttachmentAnswer,
    handleDownloadFile,
    setRenderActOption,
    findOptionLabelArray,
    pushFormPage,
    form: {
      handleSubmitForm,
      handleEditForm,
      isLoadingCreate: createProcessActRequest?.isLoading,
      isLoadingUpdating: updateProcessActRequest?.isLoading,
      ...form,
    },
  };
};

export type UseCreateActForm = ReturnType<typeof useTabAct>;
