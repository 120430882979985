import { TextField as MuiTextField } from '@mui/material';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';

import { Box } from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { useSearchDebounce } from 'hooks';
import { IQuestionInput } from 'models';

import { OptionsValues, OptionsValuesString } from 'utils/helper';

import { IQuestionAutocompleteInput } from '../../services';
import { IQuestionForm } from '../../services';

interface QuestionsAutocompleteProps {
  question: IQuestionForm;
  identifierCondition?: number;
  identifierVariable?: number;
  value?: string;
  disabled?: boolean;
  allQuestions: OptionsValuesString[];
  form: UseFormReturn<IQuestionAutocompleteInput, undefined>;
  setSearch: (value: string) => void;
  handleChange: (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
    identifierCondition?: number,
    identifierVariable?: number,
    identifierConditionInput?: number,
  ) => void;
  isOptionEqualToValue: (option: OptionsValues, value: string) => boolean;
}

export const QuestionsAutocomplete = ({
  question,
  identifierCondition,
  identifierVariable,
  value,
  disabled = false,
  allQuestions,
  form,
  setSearch,
  handleChange,
  isOptionEqualToValue,
}: QuestionsAutocompleteProps) => {
  const { handleChangeSearch: handleChangeSearchNextQuestion } = useSearchDebounce(
    setSearch,
  );

  const allQuestionsWithoutCurrentQuestion = allQuestions.filter(
    quest => quest.value !== question?.question?.id,
  );

  return (
    <Box minWidth={350} maxWidth={400} mt={3}>
      <Controller
        name="title"
        control={form.control}
        render={({ field }) => (
          <AutocompleteWrapper
            {...field}
            id="title"
            options={allQuestionsWithoutCurrentQuestion ?? []}
            onClose={() => setSearch('')}
            disabled={disabled}
            value={value}
            onChange={(_, option) =>
              handleChange(
                option as OptionsValues,
                field,
                question,
                identifierCondition,
                identifierVariable,
              )
            }
            onInputChange={(_, value) => handleChangeSearchNextQuestion(value)}
            renderInput={params => (
              <MuiTextField
                {...params}
                label="Selecione uma pergunta"
                fullWidth
                error={Boolean(!!form.formState.errors.title)}
                helperText={<>{!!form.formState.errors.title?.message}</>}
                required
              />
            )}
            isOptionEqualToValue={(option, value) =>
              isOptionEqualToValue(option as OptionsValues, value as string)
            }
            noOptionsText="Nenhum resultado encontrado"
          />
        )}
      />
    </Box>
  );
};
