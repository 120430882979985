import { Button, Paper } from '@mui/material';
import themes from 'themes';

import { Box } from 'components';
import { ContainedProps } from 'components/Buttons/Contained';

interface SignInButtonProps extends ContainedProps {
  children: React.ReactNode;
}

function SignInButton({ children, onClick }: Readonly<SignInButtonProps>) {
  return (
    <Paper
      style={{
        padding: '1em',
        borderRadius: '11px',
        width: '360px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f0f0f0',
        gap: '2.5em',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box
        paddingTop="1em"
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        style={{
          gap: '1em',
        }}
      >
        {children}
      </Box>
      <Box paddingX="1em" paddingBottom="1em" width="100%">
        <Button
          onClick={onClick}
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: themes.colors.text.primary,
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,
          }}
        >
          Acessar
        </Button>
      </Box>
    </Paper>
  );
}

export default SignInButton;
