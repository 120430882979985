import { Container } from './components/Container';
import { Content } from './components/Content';
import { Header } from './components/Header';
import { Root } from './components/Root';
import { Sidebar } from './components/Sidebar';
import { Unauthorized } from './components/Unauthorized';

export const PrivateLayout = {
  Root,
  Sidebar,
  Container,
  Header,
  Content,
  Unauthorized,
};
