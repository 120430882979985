import { Tabs } from 'modules/boards/tabs';

import {
  CustomerDialogFilter,
  CustomerDialogForm,
  Customers,
  SearchContainer,
} from '../components';
import {
  ChangeCustomerStatsDialog,
  CustomerConfirmationDialog,
} from '../components/';

const CustomerManagementPage = () => {
  return (
    <>
      <Tabs />
      <CustomerConfirmationDialog />
      <ChangeCustomerStatsDialog />
      <CustomerDialogFilter />
      <CustomerDialogForm />
      <SearchContainer />
      <Customers />
    </>
  );
};

export { CustomerManagementPage };
