import { ReactNode } from 'react';

import { FooterWrapper } from './styles';

interface FooterProps {
  children: ReactNode;
}

export function Footer({ children }: FooterProps) {
  return <FooterWrapper>{children}</FooterWrapper>;
}
