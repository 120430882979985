import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { ITriggerDocSignPayload } from '../services';
import FormAnswerServices from '../services/services';

const useTriggerDocSignRequest = (
  options?: UseMutationOptions<void, IApiAxiosError, ITriggerDocSignPayload>,
) => {
  return useMutation(
    'triggerDocSignRequest',
    FormAnswerServices.triggerDocSign,
    options,
  );
};

export { useTriggerDocSignRequest };
