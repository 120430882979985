import styled from 'styled-components';

import { Box } from 'components';

const RootWrapper = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export { RootWrapper };
