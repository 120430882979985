import { Box, ButtonContained, Dialog, Typography } from 'components';

import { useCustomerConfirm } from '../hooks';

export const CustomerConfirmationDialog = () => {
  const {
    numberOfSelectedCustomers,
    handleCloseConfirmationDialog,
    openConfirmationDialog,
    handleConfirmCustomers,
    handleCancelCustomers,
  } = useCustomerConfirm();

  return (
    <Dialog.Root
      open={openConfirmationDialog}
      onClose={handleCloseConfirmationDialog}
    >
      <Dialog.Header title={'Confirmação'} />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          style={{ gap: '2em' }}
        >
          <Typography>{`Você selecionou ${numberOfSelectedCustomers} itens. Deseja alterar todos?`}</Typography>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignContent="center"
            style={{ gap: '1em' }}
          >
            <ButtonContained label="CANCELAR" onClick={handleCancelCustomers} />
            <ButtonContained label="CONFIRMAR" onClick={handleConfirmCustomers} />
          </Box>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};
