export enum PermissionsNames {
  ShowHomePage = 'show_homepage',
  ShowDashboard = 'show_dashboard',
  ShowNotifications = 'show_notifications',
  CustomerPendingIssues = 'customer_pending_issues',
  ShowClientsBoard = 'show_clients_board',
  ShowOperations = 'show_operations',
  ChangeActResponsible = 'change_act_responsible',
  ChangeOperationStatus = 'change_operation_status',
  ShowConfigs = 'show_configs',
  ShowBackoffice = 'show_backoffice',
  ShowUsers = 'show_users',
  ShowDocs = 'show_docs',
  ShowConsultants = 'show_consultants',
  Questions = 'questions',
  Forms = 'forms',
  ShowFormConfigs = 'show_form_configs',
  ChangeResponsible = 'change_responsible',
}

export enum PermissionsDescriptions {
  ShowHomePage = 'Mostrar home page',
  ShowDashboard = 'Mostrar indicadores',
  ShowNotifications = 'Show Notifications',
  CustomerPendingIssues = 'Customer Pending Issues',
  ShowClientsBoard = 'Mostrar board de clientes',
  ShowOperations = 'Mostrar operações',
  ChangeActResponsible = 'Change Act Responsible',
  ChangeOperationStatus = 'Alterar status de operações',
  ShowConfigs = 'Mostrar configurações',
  ShowBackoffice = 'Mostrar backoffice',
  ShowUsers = 'Mostrar configuração de usuários',
  ShowDocs = 'Mostrar documentos',
  ShowConsultants = 'Mostrar consultores',
  Questions = 'Mostrar perguntas',
  Forms = 'Mostrar formulários',
  ShowFormConfigs = 'Mostrar configurações de formulários',
  ChangeResponsible = 'Alterar responsável',
}

const Permissions = {
  ShowHomePage: {
    name: PermissionsNames.ShowHomePage,
    description: PermissionsDescriptions.ShowHomePage,
  },
  ShowDashboard: {
    name: PermissionsNames.ShowDashboard,
    description: PermissionsDescriptions.ShowDashboard,
  },
  ShowNotifications: {
    name: PermissionsNames.ShowNotifications,
    description: PermissionsDescriptions.ShowNotifications,
  },
  CustomerPendingIssues: {
    name: PermissionsNames.CustomerPendingIssues,
    description: PermissionsDescriptions.CustomerPendingIssues,
  },
  ShowClientsBoard: {
    name: PermissionsNames.ShowClientsBoard,
    description: PermissionsDescriptions.ShowClientsBoard,
  },
  ShowOperations: {
    name: PermissionsNames.ShowOperations,
    description: PermissionsDescriptions.ShowOperations,
  },
  ChangeActResponsible: {
    name: PermissionsNames.ChangeActResponsible,
    description: PermissionsDescriptions.ChangeActResponsible,
  },
  ChangeOperationStatus: {
    name: PermissionsNames.ChangeOperationStatus,
    description: PermissionsDescriptions.ChangeOperationStatus,
  },
  ShowConfigs: {
    name: PermissionsNames.ShowConfigs,
    description: PermissionsDescriptions.ShowConfigs,
  },
  ShowBackoffice: {
    name: PermissionsNames.ShowBackoffice,
    description: PermissionsDescriptions.ShowBackoffice,
  },
  ShowUsers: {
    name: PermissionsNames.ShowUsers,
    description: PermissionsDescriptions.ShowUsers,
  },
  ShowDocs: {
    name: PermissionsNames.ShowDocs,
    description: PermissionsDescriptions.ShowDocs,
  },
  ChangeResponsible: {
    name: PermissionsNames.ChangeResponsible,
    description: PermissionsDescriptions.ChangeResponsible,
  },
  ShowConsultants: {
    name: PermissionsNames.ShowConsultants,
    description: PermissionsDescriptions.ShowConsultants,
  },
  Questions: {
    name: PermissionsNames.Questions,
    description: PermissionsDescriptions.Questions,
  },
  Forms: {
    name: PermissionsNames.Forms,
    description: PermissionsDescriptions.Forms,
  },
};

export { Permissions };
