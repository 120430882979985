import { Box, Logo } from 'components';

import { FormRegister } from '../components';
import { useSignUpEnterprise } from '../hooks';

export function SignUpEnterprisePage() {
  const { form, isSigningUp } = useSignUpEnterprise({
    isFinancing: false,
  });

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '2em',
      }}
    >
      <Logo />
      <FormRegister form={form} isSigningUp={isSigningUp} />
    </Box>
  );
}
