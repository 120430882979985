import { Table, TableCell } from '@mui/material';

import {
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useActCustomerDocuments } from '../hooks';
import { DocumentsItem } from './DocumentsItem';

interface Props {
  enterpriseId: string;
}

const DocumentsCustomerTable = ({ enterpriseId }: Props) => {
  const { data, isLoading, noData } = useActCustomerDocuments(enterpriseId);

  return (
    <TableContainer style={{ padding: 0 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle3">Nome</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Data de Upload</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Atos</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Usuário</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">
                Empresa ou Instituição Financeira
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3" style={{ minWidth: 120 }}>
                Status
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Baixar</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          columns={7}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhum documento encontrado"
        >
          {data?.map(document => (
            <DocumentsItem
              key={document.id}
              document={document}
              origin="dashboard"
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { DocumentsCustomerTable };
