import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { Box } from '@mui/material';

import { Typography } from 'components';
import { UserTypes } from 'models';
import { Permissions } from 'models/roles/Permission';

import { useAuth } from 'config/auth/hooks';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { useConsultantNotifications } from 'modules/notifications/hooks';
import { usePendingIssues } from 'modules/pendingIssues/hooks';

import { List, ListItem } from './styles';

export function Content() {
  const { user } = useAuth();
  const { handleOpenNotificationsDialog } = useConsultantNotifications();
  const { handleOpenDialog } = usePendingIssues({});
  const { getSpecificPermissionRole } = useValidateRouteAccess();

  const { ShowNotifications, CustomerPendingIssues } = Permissions;
  const showNotifications = getSpecificPermissionRole(ShowNotifications);
  const showPendingIssues = getSpecificPermissionRole(CustomerPendingIssues);

  const isConsultant = user?.userType === UserTypes.CONSULTANT;

  return (
    <List as="ul">
      {isConsultant && showNotifications && (
        <ListItem as="li">
          <Box
            display={'flex'}
            gap={1}
            alignItems={'center'}
            onClick={handleOpenNotificationsDialog}
          >
            <NotificationsActiveIcon color="primary" />
            <Typography variant="body2">Notificações</Typography>
          </Box>
        </ListItem>
      )}

      {!isConsultant && showPendingIssues && (
        <ListItem as="li">
          <Box
            display={'flex'}
            gap={1}
            alignItems={'center'}
            onClick={() => handleOpenDialog({})}
          >
            <NotificationsActiveIcon color="primary" />
            <Typography variant="body2">Pendências</Typography>
          </Box>
        </ListItem>
      )}
    </List>
  );
}
