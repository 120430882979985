import { useQuery } from 'react-query';

import { isAuthenticated } from '..';
import { API } from '../../../modules/me';

export const useMe = () => {
  const enabled = isAuthenticated();

  const { data, isLoading } = useQuery('user/me', API.retrieveMe, {
    retry: false,
    retryOnMount: false,
    enabled,
  });

  return { data, isLoading };
};
