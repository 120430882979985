import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IFormQuestion, IQuestionAttachmentList } from 'models';

import api from 'config/api';
import { useAuth } from 'config/auth/hooks';

import { useTabAct } from 'modules/boards/tabs/components/tabActs/hooks';
import { useFindQuestionAttachmentRequest } from 'modules/dynamicForm/question/hooks';

import {
  EFormAnswerType,
  IFinishLaterResponse,
  IFormAnswerPayload,
  IFormAnswerResponse,
  IFormAnswereds,
} from '../services';
import useFormAnswerStore from '../store/store';
import { useGetFormAnswerAttachmentsRequest } from './useGetFormAnswersAttachmentsRequest';
import { useSubmitAnswerRequest } from './useSubmitAnswerRequest';
import { useUpdateAnswerRequest } from './useUpdateAnswerRequest';

interface RouteParams {
  id: string;
  type: string;
}

export const useFormAnswer = () => {
  const history = useHistory();
  const { id, type } = useParams<RouteParams>();
  const [openDropzone, setOpenDropzone] = useState(false);
  const { user } = useAuth();

  const {
    openModalCancel,
    openModalFinishLater,
    currentQuestion,
    currentAnswer,
    questionAnswerAttachment,
    currentQuestionAttachment,
    formQuestionAnswereds,
    formQuestions,
    isRevisionQuestion,
    allowEdit,
    formRecordId,
    setFormQuestion,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setCurrentQuestion,
    setCurrentQuestionAttachments,
    setformQuestionAnswereds,
    setCurrentAnswer,
    setQuestionAnswerAttachments,
    setFormRecordId,
  } = useFormAnswerStore();

  const { currentProcessAct, act } = useTabAct();

  const clearCurrenQuestionData = () => {
    setCurrentQuestion({} as IFormQuestion);
    setCurrentAnswer('');
    setCurrentQuestionAttachments([]);
    setQuestionAnswerAttachments([]);
    setOpenModalCancel(false);
    setOpenModalFinishLater(false);
    setformQuestionAnswereds([]);
  };

  const requestFindAttachmentQuestions = useFindQuestionAttachmentRequest({
    onSuccess: data => {
      setCurrentQuestionAttachments(data);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar buscar os anexos da pergunta');
    },
  });

  const requestFindAttachmentFormAnswers = useGetFormAnswerAttachmentsRequest({
    onSuccess: data => {
      setQuestionAnswerAttachments(data);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar buscar os anexos da resposta');
    },
  });

  useEffect(() => {
    const getForm = async () => {
      if (isRevisionQuestion) return;

      const refreshed = await handleContinueForm();

      if (refreshed) return;

      const { data } = await api.get<IFormQuestion[]>(`/forms/${id}/questions`);

      const firstQuestion = data.find(question => question?.formQuestion === null);

      if (firstQuestion) {
        setFormQuestion([firstQuestion]);
        setCurrentQuestion(firstQuestion);
        await requestFindAttachmentQuestions.mutate({
          idQuestion: firstQuestion.question.id,
        });
      }
    };

    getForm();
  }, [id]);

  const handleContinueForm = async () => {
    if (type === EFormAnswerType.simulation && !formRecordId) {
      return false;
    }

    const idFormRecord = getFormRecordId();

    if (!idFormRecord || idFormRecord === '') return false;

    const questionsStarted = await api.get<IFinishLaterResponse[]>(
      `/form/form-answer/finish-later`,
      {
        params: {
          formId: id,
          enterpriseId: user?.enterprise?.id,
          formRecordId: idFormRecord,
        },
      },
    );

    if (questionsStarted.data.length > 0) {
      const questions = await questionsStarted.data.map(
        question => question.formQuestion,
      );
      const answers = questionsStarted.data.map(question => question.answer);

      const lastQuestion = questionsStarted.data[questionsStarted.data.length - 1];

      await setFormQuestion([...questions]);

      setCurrentQuestion(lastQuestion.formQuestion);
      setformQuestionAnswereds(answers);
      setCurrentAnswer(lastQuestion.answer.answer);
      const currentAttachments: IQuestionAttachmentList[] = [];

      lastQuestion.attachments?.map(att => {
        currentAttachments.push({ id: att?.id, file: att.file });
      });

      setQuestionAnswerAttachments([...currentAttachments]);

      return true;
    }
    return false;
  };

  const handleCancelForm = async () => {
    const idFormRecord = getFormRecordId();

    const deleted = await api.delete<boolean>(`/form/form-answer/delete`, {
      params: {
        formId: id,
        formRecordId: idFormRecord,
        enterpriseId: user?.enterprise?.id,
      },
    });
    if (deleted) {
      setformQuestionAnswereds([]);

      if (type === EFormAnswerType.answer) {
        setOpenModalCancel(false);
        alert('Vai redirecionar pra pendências (ainda nao implementado)');
        history.push('/home');
        return;
      }

      history.push('/config-form/?edit=true');
      return;
    }

    toast.error('Erro ao tenta excluir questões respondidas');
  };

  const handleBack = async () => {
    const currenQuestionIsAnswered = formQuestionAnswereds.find(
      question => question?.formQuestionId === currentQuestion?.id,
    );

    let lastQuestion = {} as IFormAnswereds;

    if (currenQuestionIsAnswered) {
      lastQuestion = formQuestionAnswereds[formQuestionAnswereds.length - 2];
    } else {
      lastQuestion = formQuestionAnswereds[formQuestionAnswereds.length - 1];
    }

    const lastFormQuestion = formQuestions.find(
      question => question.id === lastQuestion?.formQuestionId,
    );

    if (!lastFormQuestion) return;

    setCurrentAnswer(lastQuestion.answer);
    formQuestions.pop();
    setFormQuestion(formQuestions);
    setCurrentQuestion(lastFormQuestion);
    setCurrentQuestionAttachments(lastFormQuestion.question?.attachments);
    await requestFindAttachmentFormAnswers.mutate(lastQuestion?.formAnswerId);
  };

  const handleSetNextQuestion = (formQuestion: IFormQuestion) => {
    setCurrentQuestion(formQuestion);
    setFormQuestion([...formQuestions, formQuestion]);
  };

  const handleAnswerSavedSuccess = (formAnswer: IFormAnswerResponse) => {
    const newQuestions: IFormAnswereds[] = [
      ...formQuestionAnswereds,
      {
        formQuestionId: currentQuestion.id,
        answer: currentAnswer,
        formAnswerId: formAnswer?.formAnswer?.id ?? '',
        formRecordId: formAnswer?.formAnswer?.formRecord?.id,
      },
    ];
    setformQuestionAnswereds(newQuestions);
    setCurrentAnswer('');
    setQuestionAnswerAttachments([]);

    if (formAnswer.nextQuestion !== null) {
      handleSetNextQuestion(formAnswer.nextQuestion);

      const attachments = formAnswer?.nextQuestion?.question?.attachments;
      if (attachments) {
        setCurrentQuestionAttachments(attachments);
      }

      if (formAnswer?.formRecordId && type === EFormAnswerType.simulation) {
        setFormRecordId(formAnswer?.formRecordId);
      }

      return;
    }

    history.push(`/form-resume/${id}/${type}`);
  };

  const requestSubtmitAnswer = useSubmitAnswerRequest({
    onSuccess: async data => {
      await handleAnswerSavedSuccess(data);
    },
    onError: error => {
      if (error.response?.data.title === 'Error on upload doc sign') {
        toast.error('Houve um erro ao tentar enviar o documento para assinatura');
        return;
      }
      toast.error('Houve um erro ao tentar salvar a resposta da pergunta');
      throw error;
    },
  });

  const requestUpdateAnswer = useUpdateAnswerRequest({
    onSuccess: async data => {
      await handleAnswerSavedSuccess(data);
    },
    onError: error => {
      if (error.response?.data.title === 'Error on upload doc sign') {
        toast.error('Houve um erro ao tentar enviar o documento para assinatura');
        return;
      }
      toast.error('Houve um erro ao tentar editar a resposta da pergunta');
      throw error;
    },
  });

  const handleNext = async () => {
    const findAnswer =
      formQuestionAnswereds
        .slice()
        .reverse()
        .find(answer => answer.formQuestionId === currentQuestion.id) || null;

    if (!findAnswer) {
      await handleSubmitAnswer(false);

      return;
    }

    await handleSubmitAnswer(true, findAnswer.formAnswerId);
  };

  const getFormRecordId = () => {
    const idFormRecord =
      type === EFormAnswerType.simulation
        ? formRecordId ?? ''
        : currentProcessAct?.formRecord?.id ?? formRecordId ?? '';

    return idFormRecord;
  };

  const handleSubmitAnswer = async (isUpdate: boolean, id?: string) => {
    const fileIds = questionAnswerAttachment.map(file => file.file.id);

    const idFormRecord = getFormRecordId();

    const submitAnswerPayload: IFormAnswerPayload = {
      questionId: currentQuestion?.id ?? '',
      formId: currentQuestion?.form?.id ?? '',
      enterpriseId: user?.enterprise?.id ?? '',
      answerValue: currentAnswer,
      answerAttachment: [...fileIds],
      formRecordId: idFormRecord,
      actId: act.id ?? '',
      processActId: currentProcessAct?.id ?? '',
      type: EFormAnswerType[type],
    };

    if (!isUpdate) {
      await requestSubtmitAnswer.mutateAsync(submitAnswerPayload);
      return;
    }

    if (!id) {
      toast.error('Id da resposta nao encontrado');
      return;
    }

    await requestUpdateAnswer.mutateAsync({
      id,
      body: submitAnswerPayload,
    });
  };

  const handleFinishLater = async () => {
    history.push('/home');
    setOpenModalFinishLater(false);
    clearCurrenQuestionData();
  };

  const handleSave = async () => {
    try {
      await handleNext();
      clearCurrenQuestionData();
    } catch (error) {
      console.error('Erro ao processar a próxima ação:', error);
    }
  };

  return {
    id,
    type,
    currentQuestion,
    openDropzone,
    questionAnswerAttachment,
    currentQuestionAttachment,
    openModalCancel,
    openModalFinishLater,
    allowEdit,
    isLoadingHandleAnswer:
      requestSubtmitAnswer?.isLoading || requestUpdateAnswer?.isLoading,
    requestUpdateAnswer,
    setOpenDropzone,
    handleCancelForm,
    handleBack,
    handleSave,
    handleNext,
    handleSubmitAnswer,
    handleFinishLater,
    setOpenModalCancel,
    setOpenModalFinishLater,
    clearCurrenQuestionData,
  };
};

export type UseFormAnswer = ReturnType<typeof useFormAnswer>;
