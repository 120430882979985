import { MenuItem, Grid } from '@mui/material';
import themes from 'themes';

import { Typography, Box, TextField, Select, ButtonContained } from 'components';

import { useGetFormsQuestion } from '../../hooks';
import { UseDynamicForm, useDynamicForm } from '../../hooks';
import { useFormStore } from '../../store/store';
import { CardQuestion } from './CardQuestion';

export interface DialogFormProps {
  form: UseDynamicForm['form'];
  editForm: boolean;
}

export const DynamicForm = ({ form, editForm }: DialogFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    isLoading,
    handleSubmitForm,
  } = form;

  const {
    roles,
    renderButtonSimulate,
    handleAddNewQuestion,
    simulateForm,
    handleClose,
  } = useDynamicForm();

  useGetFormsQuestion();
  const { questionsForm } = useFormStore();

  const questionsNotExcluded = questionsForm.filter(qF => qF.numberQuestion !== -1);
  const disableButtonSave = !isValid || (renderButtonSimulate && !editForm);

  return (
    <Box
      backgroundColor={themes.colors.white}
      mt={5}
      p={4}
      borderRadius={8}
      height={'92%'}
      display={'flex'}
      alignItems={'center'}
      flexDirection={'column'}
    >
      <Box
        display={'flex'}
        alignItems={'center'}
        flexDirection={'column'}
        width={'100%'}
        height={'90%'}
        overflow={'auto'}
        paddingBottom={15}
      >
        <Grid container width={'100%'} pt={2} pl={2} pr={2} spacing={2}>
          <Grid item xs={5} display={'flex'}>
            <Typography>Dados do Formulário</Typography>

            <TextField
              name="title"
              label="Nome"
              control={control}
              type="text"
              error={errors.title}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="caption"
              label="Descrição"
              control={control}
              type="text"
              error={errors.caption}
            />
          </Grid>

          <Grid item xs={3}>
            <Select
              name="userPermission"
              control={control}
              label="Permissão para resposta"
            >
              {roles?.map(permission => (
                <MenuItem key={permission?.id} value={permission?.id}>
                  {permission?.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        {questionsForm.map((question, index) => {
          return (
            question.numberQuestion > 0 && ( // nao deletado
              <Box
                key={index}
                width={'100%'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                mt={20}
              >
                <CardQuestion question={question} />
              </Box>
            )
          );
        })}
      </Box>

      <Box
        display={'flex'}
        height={'8%'}
        mt={4}
        p={2}
        alignItems={'flex-end'}
        justifyContent={'center'}
      >
        <Box width={700} display={'flex'} justifyContent={'space-between'}>
          <Box width={150}>
            <ButtonContained
              fullWidth
              label="Fechar"
              onClick={() => handleClose()}
              loading={isLoading}
            />
          </Box>

          <Box width={180}>
            <ButtonContained
              fullWidth
              label="Adicionar Pergunta"
              onClick={() => handleAddNewQuestion()}
              disabled={!isValid || questionsNotExcluded?.length > 0}
              loading={isLoading}
            />
          </Box>

          <Box width={150}>
            <ButtonContained
              fullWidth
              label="Simular"
              onClick={() => simulateForm()}
              disabled={!isValid || !renderButtonSimulate}
              loading={isLoading}
            />
          </Box>

          <Box width={150}>
            <ButtonContained
              fullWidth
              label="Salvar"
              onClick={() => handleSubmitForm()}
              disabled={disableButtonSave}
              loading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
