import { Autocomplete } from '@mui/material';
import styled from 'styled-components';

const AutocompleteWrapper = styled(Autocomplete)`
  & label.Mui-focused {
    color: ${({ theme }) => theme.colors.blue};
  }

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: ${({ theme }) => theme.colors.blue};
    }
  }
`;

export { AutocompleteWrapper };
