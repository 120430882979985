import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  SignUpEnterpriseService,
  ICustomerInternalPasswordPayload,
} from '../services';

const useInternalPasswordSettingRequest = (
  options?: UseMutationOptions<
    boolean,
    IApiAxiosError,
    ICustomerInternalPasswordPayload
  >,
) => {
  return useMutation(
    'internal-password',
    SignUpEnterpriseService.internalRegisterPassword,
    options,
  );
};

export { useInternalPasswordSettingRequest };
