import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { IApiError } from 'config/api/types';

export const signInErrorFeedback = (error: AxiosError<IApiError>) => {
  switch (error.response?.data.title) {
    case 'Invalid credentials':
      toast.error('Credenciais inválidas!');
      break;
    case 'Invalid signIn section':
      toast.error('Área de login incompatível com o tipo de usuário!');
      break;
    case 'Attempts limit':
      toast.error('Limite de tentativas excedido! Tente novamente mais tarde.');
      break;
    case 'Blocked user':
      toast.error('Usuário bloqueado! Tente novamente mais tarde.');
      break;
    default:
      toast.error('Houve um erro ao fazer login!');
  }
};
