import { create } from 'zustand';

import {
  DEFAULT_DATABASE_FILTER_BY,
  IDatabaseFilter,
  IDatabaseFilterByOption,
} from 'components/FilterDatabase/filterDatabase.types';

import { DEFAULT_PAGINATION } from 'utils/helper';

type State = {
  openSerasaModal: boolean;

  search: string;

  page: number;
  perPage: number;
  limit: boolean;

  // filter Dialog
  openFilterDialog: boolean;
  searchOption: string;
  currentFilter: IDatabaseFilter[];
  filterBy: IDatabaseFilterByOption;
};

interface Actions {
  setOpenSerasaModal: (openSerasaModal: boolean) => void;

  setSearch: (searchTerm: string) => void;

  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;

  setOpenFilterDialog: (open: boolean) => void;
  setSearchOption: (searchBigData: string) => void;
  setCurrentFilter: (filters: IDatabaseFilter[]) => void;
  setFilterBy: (filterBy: IDatabaseFilterByOption) => void;

  clearSerasaStore: () => void;
}

const initialState: State = {
  openSerasaModal: false,

  search: '',

  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,

  openFilterDialog: false,
  searchOption: '',
  currentFilter: [],
  filterBy: DEFAULT_DATABASE_FILTER_BY,
};

const useSerasaStore = create<State & Actions>()(set => ({
  ...initialState,
  setOpenSerasaModal: openSerasaModal => set({ openSerasaModal }),

  setSearch: search => set({ search }),

  setPage: page => set({ page }),
  setPerPage: perPage => ({ perPage }),
  setLimit: limit => set({ limit }),

  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  setSearchOption: searchOption => set({ searchOption }),
  setCurrentFilter: currentFilter => set({ currentFilter }),
  setFilterBy: filterBy => set({ filterBy }),

  clearSerasaStore: () => set(initialState),
}));

export { useSerasaStore };
