import { IProcessAct, IAct, Operation, IEnterprise } from 'models';

import api from 'config/api';

import { IUpdateResponsible } from '../components';
import { IActAllowedsParams, IFilterProcessActParams } from './types';

export class TabsService {
  static async getProcessActs(params: IFilterProcessActParams) {
    const { data } = await api.get<IProcessAct[]>(`/process-act`, {
      params,
    });

    return data;
  }

  static async listActsAlloweds(params: IActAllowedsParams) {
    const { data } = await api.get<IAct[]>(`/act/list-alloweds`, {
      params,
    });
    return data;
  }

  static async updateResponsibleOperation({
    id,
    newResponsible,
  }: IUpdateResponsible) {
    const { data } = await api.put<Operation>(
      `operation/update-responsible/${id}`,
      { newResponsible },
    );

    return data;
  }

  static async updateResponsibleClient({ id, newResponsible }: IUpdateResponsible) {
    const { data } = await api.put<IEnterprise>(
      `enterprise/update-responsible/${id}`,
      { newResponsible },
    );

    return data;
  }
}
