import { TableHead } from '@mui/material';
import styled from 'styled-components';

const TableHeadWrapper = styled(TableHead)`
  && {
    height: 63px;

    th {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
`;

export { TableHeadWrapper };
