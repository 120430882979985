import { useOperationStore } from '../store/store';
import { useSelectedOperations } from './useSelectedOperations';

export const useOperationConfirm = () => {
  const {
    setOpenChangeStatsDialog,
    setOpenCreateActDialog,
    setOpenConfirmationDialog,
    openConfirmationDialog,
    operationActionType,
  } = useOperationStore();
  const { numberOfSelectedOperations } = useSelectedOperations();

  const handleOpenConfirmationDialog = async () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleConfirmOperations = () => {
    setOpenConfirmationDialog(false);

    if (operationActionType === 'createAct') {
      setOpenCreateActDialog(true);

      return;
    }

    setOpenChangeStatsDialog(true);

    return;
  };

  const handleCancelOperations = () => {
    setOpenConfirmationDialog(false);
  };

  return {
    openConfirmationDialog,
    numberOfSelectedOperations,
    handleOpenConfirmationDialog,
    handleCloseConfirmationDialog,
    handleConfirmOperations,
    handleCancelOperations,
  };
};
