import { routes } from 'routes';
import { RoutesProps } from 'routes/types';

export function useGetSidebarRoutes() {
  const getSidebarRoutes = (routesParam: RoutesProps[]) => {
    const sidebarRoutes: RoutesProps[] = [];

    for (const route of routesParam) {
      if (route.type === 'private' && route.showOnSidebar === true) {
        if (route.subs && route.subs.length > 0) {
          const filteredSubs = getSidebarRoutes(route.subs);

          if (filteredSubs.length > 0) {
            sidebarRoutes.push({
              ...route,
              subs: filteredSubs,
            });
          }
        } else {
          sidebarRoutes.push(route);
        }
      }
    }

    return sidebarRoutes;
  };

  return { sidebarRoutes: getSidebarRoutes(routes) };
}
