import { useCallback } from 'react';

import { IOperationResponse } from '../services';
import { useOperationStore } from '../store/store';

export const useSelectedOperations = () => {
  const {
    selectedOperations,
    setSelectedOperations,
    operationsQtd,
    clearOperationStore,
  } = useOperationStore();

  const clearSelectedOperations = useCallback(() => {
    setSelectedOperations([]);
  }, []);

  const handleRemoveOperation = (operation: IOperationResponse) => {
    const operations = selectedOperations.filter(
      selectedOperation => selectedOperation.id !== operation.id,
    );

    setSelectedOperations(operations);
  };

  const handleAddSelectedOperation = (operation: IOperationResponse) => {
    setSelectedOperations([...selectedOperations, operation]);
  };

  const handleChangeSelectedOperations = (operation: IOperationResponse) => {
    const isSelected = selectedOperations.some(
      selectedOperation => selectedOperation.id === operation.id,
    );

    if (isSelected) {
      handleRemoveOperation(operation);

      return;
    }

    handleAddSelectedOperation(operation);

    return;
  };

  const isOperationSelected = (operation: IOperationResponse) =>
    selectedOperations.some(
      selectedOperation => selectedOperation.id === operation.id,
    );

  const numberOfSelectedOperations = selectedOperations.length;

  const handleChangeAll = (operations: IOperationResponse[] = []) => {
    if (operations.length === numberOfSelectedOperations) {
      clearSelectedOperations();
      return;
    }

    setSelectedOperations([...operations]);

    return;
  };

  const isAllSelected = () =>
    numberOfSelectedOperations > 0 && operationsQtd === numberOfSelectedOperations;

  return {
    selectedOperations,
    clearOperationStore,
    handleChangeSelectedOperations,
    isOperationSelected,
    numberOfSelectedOperations,
    handleChangeAll,
    isAllSelected,
  };
};
