import { IPaginationParams, IAct } from 'models';

import { IOrdination } from 'utils/ordination';

export interface IFilterActParams extends IPaginationParams, IOrdination<IAct> {
  search?: string;
  active?: boolean;
}

export interface IActForm {
  active: boolean;
  name: string;
  description: string | null;
  type: string;
  clientViewAndAnswer: string[];
  clientEmit: string[];
  userViewAndAnswer: string[];
  userEmit: string[];

  triggerForm: boolean;
  formId?: string | null;
  showInfo: boolean;
  info?: string | null;
  showDbInfo: boolean;
  clientTypeInfo?: string | null;
  dbInfo?: string[] | null;

  textInputAnswer: boolean;
  generateAct: boolean;
  actId?: string;

  defineProductClient: boolean;
  productClient?: string[];

  createOp: boolean;
  defineOp: boolean;
  operation?: string | null;
  defineValueOp: boolean;

  showScheduleLink: boolean;
  scheduleLink?: string | null;

  showDeadline: boolean;
  deadline?: string | null;
  triggerNotification: boolean;
  notifyBefore?: string[] | null;
  notifyAfter?: string[] | null;
  changeStatusClient: boolean;
  newStatusClient?: string | null;
  changeStatusOp: boolean;
  newStatusOp?: string | null;

  allowCharge: boolean;
  triggerDocSign: boolean;
  allowAttach: boolean;
  allowAnswerAttach: boolean;
  sendEmail: boolean;
  emailTitle?: string | null;
  emailBody?: string | null;
  negotiationEnd: boolean;
  closureAct: boolean;
}

export interface IActPayload {
  id?: string;
  active: boolean;
  name: string;
  description: string | null;
  type: string;
  clientViewAndAnswer: string[];
  clientEmit: string[];
  userViewAndAnswer: string[];
  userEmit: string[];
  configs: IActConfigPayload;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export interface IActConfigPayload {
  id?: string;
  triggerForm: boolean;
  formId?: string | null;
  showInfo: boolean;
  info?: string | null;
  showDbInfo: boolean;
  clientTypeInfo?: string | null;
  dbInfo?: string[] | null;

  textInputAnswer: boolean;
  actId?: string | null;

  defineProductClient: boolean;
  productClient?: string[] | null;

  createOp: boolean;
  defineOp: boolean;
  operation?: string | null;
  defineValueOp: boolean;

  showScheduleLink: boolean;
  scheduleLink?: string | null;

  showDeadline: boolean;
  deadline?: string | null;
  notifyBefore?: string[] | null;
  notifyAfter?: string[] | null;

  newStatusClient?: string | null;
  newStatusOp?: string | null;

  allowCharge: boolean;
  triggerDocSign: boolean;
  allowAttach: boolean;
  allowAnswerAttach: boolean;
  sendEmail: boolean;
  emailTitle?: string | null;
  emailBody?: string | null;
  negotiationEnd: boolean;
  closureAct: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

export interface IUpdateActRequest {
  actId: string;
  configActId: string;
  body: IActPayload;
}

export enum ActTypePayload {
  'Formulário' = 'FORM',
  'Comunicação' = 'COMMUNICATION',
  'Resposta' = 'ANSWER',
  'Solicitação' = 'INFO_SOLICITATION',
  'Envio de informações (ExFin)' = 'SEND_INFO_EXFIN',
  'Envio de informações (Cliente)' = 'SEND_INFO_CLIENT',
  'Criação de Operação (Empresa e Instituição Financeira)' = 'OP_CREATION',
  'Assinatura de documento' = 'DOC_SIGN',
  'Pagamento' = 'PAYMENT',
  'Agendamento de reunião' = 'MEETING_SCHEDULING',
  'Encerramento' = 'CLOSURE',
}

export enum UserTypeHandleActPayload {
  'Administrador' = 'ADMIN',
  'Colaborador' = 'COLABORATOR',
}

export enum ActStatusPayload {
  'Aberto' = 'OPEN',
  'Fechado' = 'CLOSED',
}

export enum ProductClientPayload {
  'Preparação de crédito' = 'CREDIT_PREPARATION',
  'Relacionamento com instituições financeiras' = 'RELATION_FIN_INST',
  'Operações estruturadas' = 'STRUCTURED_OPERATIONS',
}

export enum ClientDestineStatusPayload {
  'Usuário cadastrado' = 'REGISTERED_USER',
  'Formulário de  qualificação - disponível' = 'QUALY_FORM_AVAILABLE',
  'Formulário de qualificação - abandono' = 'QUALY_FORM_ABANDONED',
  'Formulário de qualificação - concluído' = 'QUALY_FORM_COMPLETED',
  'Reunião comercial - contatar' = 'BUSINESS_MEETING_CONTACT',
  'Reunião comercial - agendada' = 'BUSINESS_MEETING_SCHEDULED',
  'Reunião comercial - sem retorno' = 'BUSINESS_MEETING_NO_RETURN',
  'Reunião comercial - concluída' = 'BUSINESS_MEETING_COMPLETED',
  'Qualificação oportunidade - qualificado' = 'QUALY_OPPORTUNITY_QUALIFIED',
  'Qualificação oportunidade - desqualificado' = 'QUALY_OPPORTUNITY_DISQUALIFIED',
  'Proposta - pendente' = 'PROPOSAL_PENDING',
  'Proposta - gerada' = 'PROPOSAL_GENERATED',
  'Proposta - enviada' = 'PROPOSAL_SENT',
  'Proposta - em negociação' = 'PROPOSAL_UNDER_NEGOTIATION',
  'Proposta - disponível para assinatura' = 'PROPOSAL_AVAILABLE_SIGN',
  'Proposta - assinada' = 'PROPOSAL_SIGNED',
  'Proposta - negada' = 'PROPOSAL_DENIED',
  'Formulário de demanda de crédito - disponível' = 'CREDIT_DEMAND_FORM_AVAILABLE',
  'Formulário de demanda de crédito - concluído' = 'CREDIT_DEMAND_FORM_COMPLETED',
  'Formulário de demanda de crédito - revisão' = 'CREDIT_DEMAND_FORM_REVIEW',
  'Definição de financiadores - disponível' = 'DEFINITION_FINANCIER_AVAILABLE',
  'Definição de financiadores - concluído' = 'DEFINITION_FINANCIER_COMPLETED',
  'Definição de financiadores - revisão' = 'DEFINITION_FINANCIER_REVIEW',
  'Reunião onboarding - contatar' = 'ONBOARD_MEETING_CONTACT',
  'Reunião onboarding - agendada' = 'ONBOARD_MEETING_SCHEDULED',
  'Reunião onboarding - sem retorno' = 'ONBOARD_MEETING_NO_FEEDBACK',
  'Reunião onboarding - concluída' = 'ONBOARD_MEETING_COMPLETED',
  'Solicitação de documentos - disponível' = 'DOCUMENT_REQUEST_AVAILABLE',
  'Solicitação de documentos - pendente' = 'DOCUMENT_REQUEST_PENDING',
  'Solicitação de documentos - concluída' = 'DOCUMENT_REQUEST_COMPLETED',
  'Documentos contábeis finalizado' = 'ACCOUNTING_DOCS_FINISHED',
  'Consulta Serasa PF - disponível' = 'SERASA_PF_QUERY_AVAILABLE',
  'Consulta Serasa PF - abandono' = 'SERASA_PF_QUERY_ABANDONED',
  'Consulta Serasa PF - concluído' = 'SERASA_PF_QUERY_COMPLETED',
  'IRPF dos sócios em PDF - disponível' = 'PARTNERS_IRPF_IN_PDF_AVAILABLE',
  'One pager - concluído' = 'ONE_PAGER_COMPLETED',
  'One pager - pendente' = 'ONE_PAGER_PENDING',
  'One pager - produção' = 'ONE_PAGER_PRODUCTION',
  'Book - produção' = 'BOOK_PRODUCTION',
  'Book - Validação cliente' = 'BOOK_CLIENT_VALIDATION',
  'Book - Concluído' = 'BOOK_COMPLETED',
  'Definição da operação - disponível' = 'OP_DEFINITION_AVAILABLE',
  'Definição da operação - concluído' = 'OP_DEFINITION_COMPLETED',
  'Definição da operação - revisão' = 'OP_DEFINITION_REVIEW',
  'Monitoramento - atualizado' = 'MONITORING_UPDATED',
  'Monitoramento - envio de docs' = 'MONITORING_SEND_DOCS',
  'Monitoramento - solicitação certificado A1' = 'MONITORING_A1_CERT_REQUEST',
  'Operação em andamento' = 'OPERATION_IN_PROGRESS',
  'Manutenção de relacionamento' = 'RELATIONSHIP_MAINTENANCE',
  'Projeto' = 'PROJECT',
  'Operação estruturada' = 'STRUCTURED_OPERATION',
  'Contrato encerrado' = 'CLOSED_CONTRACT',
}

export enum OperationDestineStatusPayload {
  'Envio one pager' = 'SENDING_ONE_PAGER',
  'Envio book + info - Enviado' = 'SENDING_BOOK_INFO_SENT',
  'Envio book + info - sem retono' = 'SENDING_BOOK_INFO_NO_RETURN',
  'Envio book + info - info adicional' = 'SENDING_BOOK_INFO_ADD_INFO',
  'Envio book + info - concluído' = 'SENDING_BOOK_INFO_COMPLETED',
  'Abertura de conta - envio de docs' = 'OPENING_ACCOUNT_SEND_DOCS',
  'Abertura de conta - documentos pendentes' = 'OPENING_ACCOUNT_PENDING_DOCS',
  'Abertura de conta - assinatura cliente' = 'OPENING_ACCOUNT_SIGN_CUSTOMER',
  'Abertura de conta - em processamento na instituição' = 'OPENING_ACCOUNT_PROCESSING_INSTUTION',
  'Análise de crédito - andamento' = 'CREDIT_ANALYSIS_IN_PROGRESS',
  'Análise de crédito - sem retorno' = 'CREDIT_ANALYSIS_NO_RETURN',
  'Análise de crédito - aprovado' = 'CREDIT_ANALYSIS_APPROVED',
  'Análise de crédito - negado' = 'CREDIT_ANALYSIS_DENIED',
  'Proposta de crédito - disponível' = 'CREDIT_PROPOSAL_AVAILABLE',
  'Proposta de crédito - enviado cliente' = 'CREDIT_PROPOSAL_SENT_CLIENT',
  'Proposta de crédito - análise cliente' = 'CREDIT_PROPOSAL_CLIENT_ANALYSIS',
  'Proposta de crédito - aprovado cliente' = 'CREDIT_PROPOSAL_APPROVED_CLIENT',
  'Proposta de crédito - negado cliente' = 'CREDIT_PROPOSAL_DENIED_CLIENT',
  'Proposta de crédito - expirado' = 'CREDIT_PROPOSAL_EXPIRED',
  'Proposta de crédito - em negociação' = 'CREDIT_PROPOSAL_UNDER_NEGOTIATION',
  'Suporte dua diligence - Analise de instrumento' = 'DUA_DILIGENCE_SUPPORT_INST_ANALYSIS',
  'Suporte dua diligence - Garantias' = 'DUA_DILIGENCE_SUPPORT_GUARANTEES',
  'Assinatura de contrato - Disponível' = 'CONTRACT_SIGNATURE_AVAILABLE',
  'Assinatura de contrato - Pendente' = 'CONTRACT_SIGNATURE_PENDING',
  'Assinatura de contrato - Concluída' = 'CONTRACT_SIGNATURE_COMPLETED',
  'Liquidação' = 'SETTLEMENT',
  'Emissão NF - Pendente' = 'NF_ISSUE_PENDING',
  'Emissão NF - Em emissão' = 'NF_ISSUE_IN_ISSUE',
  'Emissão NF - Enviada' = 'NF_ISSUE_SENT',
  'Análise de viabilidade' = 'FEASIBILITY_ANALYSIS',
  'Definição de parceiros' = 'PARTNERS_DEFINITION',
  'Elaboração de material - projeto' = 'MATERIAL_PREPARATION_PROJECT',
  'Elaboração de material - apresentação' = 'MATERIAL_PREPARATION_PRESENTATION',
  'Estruturação de garantias' = 'STRUCTURING_GUARANTEES',
  'Formalização da operação' = 'OPERATION_FORMALIZING',
  'Operação finalizada - perdida' = 'OPERATION_FINALIZED_MISSED',
  'Operação finalizada - efetivada' = 'OPERATION_FINALIZED_COMPLETED',
}

export enum OperationPayload {
  'Operações de Venture Debt' = 'VENTURE_DEBT',
  'Operações off balance' = 'OFF_BALANCE',
  'Capital de giro' = 'WOKING_CAPITAL',
  'Linhas oficiais' = 'OFFICIAL_LINES',
  'Linhas subvencionadas' = 'SUBSIDIZED_LINES',
  'Financiamento de CAPEX e OPEX' = 'CAPEX_OPEX_FINANCING',
  'Antecipação de recebíveis' = 'ANTICIPATION_RECEIVABLES',
  'Bacen' = 'BACEN',
  'ACC(s)' = 'ACC(s)',
  'ACE(s)' = 'ACE(s)',
  'Project Finance' = 'PROJECT_FINANCE',
  'CCB(s)' = 'CCB(s)',
  'Debêntures' = 'DEBENTURES',
  'CRI(s)' = 'CRI(s)',
  'CRA(s)' = 'CRA(s)',
  'Estruturação de fundo de investimento' = 'STRUCTURING_INVESTMENT_FUND',
  'Crédito Estruturado' = 'STRUCTURED_CREDIT',
  'Home Equity' = 'HOME_EQUIT',
  'High yield' = 'HIGH_YIELD',
  'Distressed' = 'DISTRESSED',
  'High grade' = 'HIGH_GRADE',
  'Legal Claims' = 'LEGAL_CLAIMS',
  'Precatórios' = 'PREACTORYS',
  'Marketplace' = 'MARKETPLACE',
  'Seguros' = 'INSECURANCE',
  'FGI' = 'FGI',
  'Crédito Rotativo' = 'REVOLVING_CREDIT',
  'Limite cartão de crédito' = 'CREDIT_CARD_LIMIT',
  'Sales and Lease back' = 'SALES_LEASE_BACK',
}
