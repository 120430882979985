import { create } from 'zustand';

import { IEnterprise } from 'models';

import { DEFAULT_PAGINATION, OptionsValuesString } from 'utils/helper';

import {
  DEFAULT_CUSTOMER_BOARD_FILTER_BY,
  ICustomerBoardFilter,
  ICustomerBoardFilterByOption,
  ICustomerRegisterPayload,
} from '../services';

type State = {
  search: string;

  page: number;
  perPage: number;
  limit: boolean;

  hasSecondCnpj: boolean;
  mainCustomers: OptionsValuesString[];

  openFilterDialog: boolean;
  searchFilterOption: string;
  currentFilter: ICustomerBoardFilter[];
  filterBy: ICustomerBoardFilterByOption;

  selectedCustomers: IEnterprise[];
  customersAmount: number;
  openChangeStatsDialog: boolean;
  openConfirmationDialog: boolean;

  openFormDialog: boolean;
  formDialogType: 'add' | 'edit';
  customerData: ICustomerRegisterPayload;
};

interface Actions {
  setSearch: (searchTerm: string) => void;

  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;

  setHasSecondCnpj: (hasSecondCnpj: boolean) => void;
  setMainCustomers: (mainCustomers: OptionsValuesString[]) => void;

  setOpenFilterDialog: (open: boolean) => void;
  setSearchFilterOption: (option: string) => void;
  setCurrentFilter: (filters: ICustomerBoardFilter[]) => void;
  setFilterBy: (filter: ICustomerBoardFilterByOption) => void;

  setOpenFormDialog: (open: boolean) => void;
  setFormDialogType: (type: 'add' | 'edit') => void;
  setCustomerData: (customer: ICustomerRegisterPayload) => void;

  setOpenChangeStatsDialog: (open: boolean) => void;

  setSelectedCustomers: (customers: IEnterprise[]) => void;
  setCustomersAmount: (amount: number) => void;
  setOpenConfirmationDialog: (open: boolean) => void;

  clearCustomerManagementStore: () => void;
}

const initialState: State = {
  search: '',

  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,

  hasSecondCnpj: false,
  mainCustomers: [],

  openFilterDialog: false,
  searchFilterOption: '',
  currentFilter: [],
  filterBy: DEFAULT_CUSTOMER_BOARD_FILTER_BY,

  selectedCustomers: [],
  customersAmount: 0,
  openChangeStatsDialog: false,
  openConfirmationDialog: false,

  openFormDialog: false,
  formDialogType: 'add',
  customerData: {} as ICustomerRegisterPayload,
};

const useCustomerManagementStore = create<State & Actions>()(set => ({
  ...initialState,
  setSearch: search => set({ search }),

  setPage: page => set({ page }),
  setPerPage: perPage => ({ perPage }),
  setLimit: limit => set({ limit }),

  setHasSecondCnpj: hasSecondCnpj => set({ hasSecondCnpj }),
  setMainCustomers: mainCustomers => set({ mainCustomers }),

  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  setSearchFilterOption: searchFilterOption => set({ searchFilterOption }),
  setCurrentFilter: currentFilter => set({ currentFilter }),
  setFilterBy: filterBy => set({ filterBy }),

  setSelectedCustomers: selectedCustomers => set({ selectedCustomers }),
  setCustomersAmount: customersAmount => set({ customersAmount }),
  setOpenChangeStatsDialog: openChangeStatsDialog => set({ openChangeStatsDialog }),
  setOpenConfirmationDialog: openConfirmationDialog =>
    set({ openConfirmationDialog }),

  setOpenFormDialog: openFormDialog => set({ openFormDialog }),
  setFormDialogType: formDialogType => set({ formDialogType }),
  setCustomerData: customerData => set({ customerData }),

  clearCustomerManagementStore: () => set(initialState),
}));

export { useCustomerManagementStore };
