import { useQuery } from 'react-query';

import { UserQueryKey } from 'modules/user';

import { OptionsValuesString } from 'utils/helper';

import { CustomerService } from '../services';

export const useListCreditors = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [UserQueryKey.LIST_ALL_CREDITORS],
    () => CustomerService.listByCustomerType({ isFinancing: true }),
    { enabled, keepPreviousData: true },
  );

  const creditorOptions: OptionsValuesString[] =
    data
      ?.filter(creditor => creditor?.bigData)
      ?.map(({ id, bigData }) => ({
        value: id,
        label: bigData?.fantasyName || bigData?.socialReason,
      })) || [];

  return {
    creditorOptions,
    data,
    isLoading,
    refetch,
  };
};
