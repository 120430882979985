import { useEffect } from 'react';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { useUserStore } from '../store/store';

export const useResetPagination = () => {
  const { search, currentFilter, setPage } = useUserStore();

  useEffect(() => {
    setPage(DEFAULT_PAGINATION.page);
  }, [search, currentFilter]);
};
