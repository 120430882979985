import { Actions } from './components/Actions';
import { Background } from './components/Background';
import { Content } from './components/Content';
import { Root } from './components/Root';
import { SearchBar } from './components/SearchBar';
import { Title } from './components/Title';
import { Toolbar } from './components/Toolbar';

export const Header = {
  Background,
  Root,
  Content,
  Title,
  SearchBar,
  Toolbar,
  Actions,
};
