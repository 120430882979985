import { Box, Dialog } from 'components';

import { useCustomerDialog, useInternalRegister } from '../hooks';
import { InternalRegisterForm } from './InternalRegisterForm';

export const CustomerDialogForm = () => {
  const { handleCloseFormDialog, openFormDialog } = useCustomerDialog();
  const { form, request } = useInternalRegister();

  return (
    <Dialog.Root open={openFormDialog} onClose={handleCloseFormDialog}>
      <Dialog.Header title={'Cliente'} />
      <Box>
        <InternalRegisterForm form={form} isLoading={request.isLoading} />
      </Box>
    </Dialog.Root>
  );
};
