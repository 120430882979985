import styled from 'styled-components';

import Box from 'components/Box';

const LogoWrapper = styled(Box)`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[3]}px;
  background-color: ${({ theme }) => theme.colors['gray-700']};
  color: ${({ theme }) => theme.colors.text};
`;

const LogoImage = styled.img`
  width: 150px;
  height: 'auto';
`;

export { LogoWrapper, LogoImage };
