import { Checkbox, Table, TableCell, Tooltip } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { CreateProcessAct } from 'modules/boards/tabs';

import { useOperation } from '../hooks/useOperation';
import { OperationItem } from './OperationItem';

const Operations = () => {
  const {
    noData,
    data: operationsList,
    numberOfSelectedOperations,
    handleChangeAll,
    isAllSelected,
    selectedOperations,
    form,
    typeScreenForm,
    showEnterprise,
    showCreditor,
    isConsultant,
    isLoading,
    showPagination,
    page,
    setPage,
    limit,
  } = useOperation();

  return (
    <>
      <CreateProcessAct
        form={form}
        type={typeScreenForm}
        multipleEnterprises={selectedOperations.map(i => i.enterprise.id)}
        typeScreenCalled="operations"
      />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width={20} align="center" padding="checkbox">
                <Tooltip
                  title={
                    numberOfSelectedOperations >= 1
                      ? `${numberOfSelectedOperations} item(s) selecionado(s)`
                      : 'Selecionar todos'
                  }
                >
                  <Checkbox
                    name="header-op-checkbox"
                    checked={isAllSelected()}
                    onChange={() => handleChangeAll(operationsList)}
                  />
                </Tooltip>
              </TableCell>
              {showEnterprise && (
                <TableCell align="center">
                  <Typography
                    style={{
                      fontSize: `${themes.fontSizes.s}px`,
                    }}
                    variant="subtitle3"
                  >
                    Empresa
                  </Typography>
                </TableCell>
              )}
              {showCreditor && (
                <TableCell align="center">
                  <Typography
                    style={{
                      fontSize: `${themes.fontSizes.s}px`,
                    }}
                    variant="subtitle3"
                  >
                    Instituição Financeira
                  </Typography>
                </TableCell>
              )}
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Responsável
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Tipo de operação
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Data de início
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Valor
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Último ato
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Última modificação
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Status da operação
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Ativo
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            columns={isConsultant ? 11 : 10}
            isLoading={isLoading}
            empty={noData}
            emptyMessage="Nenhuma operação encontrada"
          >
            {operationsList?.map(operation => (
              <OperationItem
                key={operation.id}
                operation={operation}
                showCompany={showEnterprise}
                showCreditor={showCreditor}
              />
            ))}
          </TableBody>
        </Table>
        {showPagination && (
          <Pagination step={page} onSetStep={setPage} limit={limit} />
        )}
      </TableContainer>
    </>
  );
};

export { Operations };
