import { OptionsValuesString } from 'utils/helper';

import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { useGetTagsOutputsQuestion } from './useGetTagsOutputsQuestion';

interface IAllQuestionsInput {
  inputQuestion: OptionsValuesString | undefined;
  identifierInputQuestion: number;
}

export const useGetTagsInputsQuestion = (
  question: IQuestionForm,
  allQuestions: OptionsValuesString[],
) => {
  const { questionsForm } = useFormStore();

  const allQuestionsInput: IAllQuestionsInput[] = [];

  if (!question?.question?.id) return { allQuestionsInput };

  const currentQuestionId = question?.question?.id;
  const currentNumberQuestion = question?.numberQuestion;

  questionsForm.filter(questionForm => {
    if (questionForm.numberQuestion === currentNumberQuestion) return;
    if (questionForm.numberQuestion === -1) return;

    const { allQuestionsOutput } = useGetTagsOutputsQuestion(
      questionForm,
      allQuestions,
    );

    const inputQuestions = allQuestionsOutput.filter(
      question => question?.outputQuestion?.value === currentQuestionId,
    );

    if (inputQuestions.length > 0) {
      allQuestionsInput.push({
        inputQuestion: {
          label: questionForm?.question.title ?? '',
          value: questionForm?.question.id ?? '',
        },
        identifierInputQuestion: allQuestionsInput.length + 1,
      });
    }
  });

  return { allQuestionsInput };
};
