import { TableCell } from '@mui/material';

import { ButtonContained, TableRow, Typography } from 'components';
import { useDownloadFile } from 'hooks';
import { EKleverAttachmentStatusToDisplay, IProcessActAttachment } from 'models';
import {
  EClickSignAttachmentStatus,
  EClickSignAttachmentType,
  EClickSignStatusToDisplay,
} from 'models/document/types';

interface Props {
  document: IProcessActAttachment;
  origin?: 'dashboard' | 'operations';
}

const DocumentsItem = ({ document, origin }: Props) => {
  const { downloadFile } = useDownloadFile(document?.file);

  const docClickSign = document?.clicksignAttachment;

  const waitingSign =
    docClickSign?.type === EClickSignAttachmentType.SIGN &&
    docClickSign?.status !== EClickSignAttachmentStatus.SIGNED;

  const renderStatusDocument = (document: IProcessActAttachment) => {
    const clickSignAtt = document?.clicksignAttachment;

    if (clickSignAtt) {
      if (clickSignAtt?.status)
        return EClickSignStatusToDisplay[clickSignAtt.status];
      return 'Original';
    }

    if (document?.kleverAttachment)
      return EKleverAttachmentStatusToDisplay[document.kleverAttachment?.status];

    return 'N/A';
  };

  return (
    <TableRow>
      <TableCell>
        <Typography textAlign="center">{document?.file?.fileName}</Typography>
      </TableCell>
      <TableCell>
        <Typography textAlign="center">
          {`${
            document?.file?.createdAt &&
            new Date(document?.file?.createdAt).toLocaleDateString('pt-BR')
          } ${
            document?.file?.createdAt &&
            new Date(document?.file?.createdAt).toLocaleTimeString('pt-br')
          }`}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography textAlign="center">{document.processAct.act.name}</Typography>
      </TableCell>
      <TableCell>
        <Typography textAlign="center">
          {document.processAct.enterprise.name}
        </Typography>
      </TableCell>
      {origin === 'dashboard' && (
        <TableCell>
          <Typography textAlign="center">
            {`${
              document.processAct.enterprise?.isFinancing
                ? 'Empresa'
                : 'Instituição Financeira'
            } ${document.processAct.enterprise.name}`}
          </Typography>
        </TableCell>
      )}
      <TableCell style={{ minWidth: 120 }}>
        <Typography textAlign="center">{renderStatusDocument(document)}</Typography>
      </TableCell>
      <TableCell align="center">
        <ButtonContained
          label="BAIXAR"
          disabled={waitingSign}
          onClick={() => downloadFile()}
        />
      </TableCell>
    </TableRow>
  );
};
export { DocumentsItem };
