import { Box } from '@mui/material';
import QuillMention from 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import { memo, useCallback } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { RolesTypes } from 'models';

import {
  FormService,
  IExpressionKeysResponse,
  IQuestionForm,
} from 'modules/dynamicForm/form/services';
import { useFormStore } from 'modules/dynamicForm/form/store/store';

import './index.css';

Quill.register('modules/mention', QuillMention);

interface Props {
  question: IQuestionForm;
  identifierCondition: number;
  identifierVariable?: number;
  deltaExpression: ReactQuill.Value;
  onChange: (
    delta: ReactQuill.Value,
    question: IQuestionForm,
    identifierCondition: number,
    identifierVariable?: number,
  ) => void;
}

const ExpressionInput = ({
  deltaExpression,
  question,
  identifierCondition,
  identifierVariable,
  onChange,
}: Props) => {
  const { questionsForm } = useFormStore();

  const currentQuestionIndex = question.numberQuestion;

  const handleChange = useCallback(
    (_, __, ___, editor) => {
      onChange(
        editor.getContents(),
        question,
        identifierCondition,
        identifierVariable,
      );
    },
    [onChange, question, identifierCondition, identifierVariable],
  );

  const handleSource = useCallback(async (searchTerm, renderList) => {
    let systemVars: IExpressionKeysResponse[] = [];
    const domElement = document.getElementById('userPermission');
    const formForConsultants =
      (domElement?.innerText || domElement?.textContent) === RolesTypes.CONSULTANT;

    if (!formForConsultants) {
      systemVars = await FormService.getOnlyExpressionKeys();
    }

    const questionVars = questionsForm
      .filter(question => question.numberQuestion < currentQuestionIndex)
      .map(question => ({
        ...question,
        origin: 'form',
        title: question.question.title,
        value: question.question.title.replace(/ /g, '_').replace(/\W/g, ''),
      }));

    let varOptions = [...questionVars, ...systemVars];

    if (searchTerm.length) {
      varOptions = varOptions.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    renderList(varOptions, searchTerm);
  }, []);

  const handleRenderItem = useCallback(
    item =>
      `$${item.title} - ${
        item.origin === 'sys' ? 'Variável de Sistema' : 'Pergunta do Formulário'
      }`,
    [],
  );

  return (
    <Box>
      <ReactQuill
        value={deltaExpression}
        theme="snow"
        onChange={handleChange}
        modules={{
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['$'],
            source: handleSource,
            renderItem: handleRenderItem,
            dataAttributes: [
              'id',
              'title',
              'sys_column',
              'valueType',
              'value',
              'type',
              'description',
              'hint',
              'acceptAttachment',
              'questionType',
              'denotationChar',
              'link',
              'target',
              'origin',
            ],
          },
          toolbar: false,
        }}
      />
    </Box>
  );
};

export default memo(ExpressionInput);
