import { ButtonContained, Icon } from 'components';

import { useLogout } from 'modules/SignOut/hooks';

import { FooterWrapper } from './styles';

export function Footer() {
  const { setShowDialog } = useLogout();

  return (
    <FooterWrapper as="footer">
      <ButtonContained
        icon={<Icon>exit_to_app</Icon>}
        label="Sair"
        onClick={() => setShowDialog(true)}
      />
    </FooterWrapper>
  );
}
