import { TableCell } from '@mui/material';

import { TableRow, Typography } from 'components';

import { IDocumentResponse } from '../services';
import { DownloadLink } from './DownloadLink';

const DocumentItem = (document: IDocumentResponse) => {
  return (
    <TableRow key={document.id}>
      <TableCell>
        <Typography textAlign="center">{document.customer.name}</Typography>
      </TableCell>
      <TableCell>
        <DownloadLink url={document.NdaUrl} />
      </TableCell>
      <TableCell>
        <DownloadLink url={document.DreUrl} />
      </TableCell>
      <TableCell>
        <DownloadLink url={document.BpUrl} />
      </TableCell>
    </TableRow>
  );
};

export { DocumentItem };
