import styled from 'styled-components';

import { Box } from 'components';

const ContainerWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export { ContainerWrapper };
