import { Edit } from '@mui/icons-material';
import { TableCell, Icon, Checkbox } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { ActType, IAct } from 'models';

import { useConfigAct, useActItem } from '../hooks';

export const Item = (value: IAct) => {
  const { handleOpenConfigActDialog } = useConfigAct();
  const { valueCheckBox, handleInactiveAct } = useActItem();

  const emptyString = '---';
  const { colors, fontSizes } = themes;

  return (
    <TableRow key={value.id} style={{ cursor: 'default' }}>
      <TableCell width={500}>
        <Typography
          style={{
            fontSize: `${fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {value?.name ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell width={250}>
        <Typography
          style={{
            fontSize: `${fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {ActType[value?.type] ?? emptyString}
        </Typography>
      </TableCell>
      <TableCell width="60px" align="center">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TypographyActive
            style={{ width: '40px', fontSize: `${fontSizes.s}px` }}
            textAlign="center"
            backgroundColor={value.active ? colors.green : colors.error}
          >
            {value.active ? 'Ativo' : 'Inativo'}
          </TypographyActive>
        </div>
      </TableCell>
      <TableCell width={70} align="center">
        <Icon>
          <Typography
            onClick={() => handleOpenConfigActDialog({ ...value })}
            style={{ cursor: 'pointer' }}
          >
            <Edit />
          </Typography>
        </Icon>
      </TableCell>
      <TableCell width={20} align="center">
        <Typography>
          <Checkbox
            checked={valueCheckBox}
            disabled={!value.active}
            size={'medium'}
            onChange={() => handleInactiveAct(value.id ?? '')}
          />
        </Typography>
      </TableCell>
    </TableRow>
  );
};
