import { useEffect, useState } from 'react';

import { useDebounceArray } from 'hooks';

export const useSearchArrayDebounce = (
  setSearch: (search: string[]) => void,
  debounceDelay?: number,
) => {
  const [search, setSearchText] = useState<string[]>([]);

  const handleChangeSearch = (value: string[]) => {
    setSearchText([...value]);
  };

  const debounced = useDebounceArray(search, debounceDelay);

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return { search, handleChangeSearch };
};
