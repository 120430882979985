import api from 'config/api';

import {
  IAuthWithRefreshTokenPayload,
  IAuthWithRefreshTokenResponse,
  IEnterpriseResponse,
  ISignInPayload,
  ISignInResponse,
} from './types';

export class AuthService {
  static async signIn(payload: ISignInPayload) {
    const { data } = await api.post<ISignInResponse>('/auth/sign-in', payload);
    return data;
  }

  static async signOut() {
    await api.delete('/auth/sign-out');
  }

  static async authWithRefreshToken(payload: IAuthWithRefreshTokenPayload) {
    const { data } = await api.post<IAuthWithRefreshTokenResponse>(
      '/auth/refresh-token',
      payload,
    );
    return data;
  }

  static async enterprisesList() {
    const { data } = await api.get<IEnterpriseResponse[]>('/enterprise/list');

    return data;
  }
}
