import { IPaginationParams } from 'models/pagination/types';

export type OptionsValues = {
  label: string;
  value: number;
};

export type OptionsValuesString = {
  label: string;
  value: string;
};

export interface OrderBy<T> {
  value: keyof T;
  label: string;
}

export const statusOptions: OptionsValues[] = [
  {
    label: 'Ativado',
    value: 1,
  },
  {
    label: 'Desativado',
    value: 0,
  },
];

export const DEFAULT_PAGINATION: IPaginationParams = {
  page: 0,
  perPage: 20,
};

export const cpfValidation = (value: string | null) => {
  if (!value) return false;
  const newValue = value.replace(/[^\d]+/g, '');
  const invalid = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];
  // Elimina CPFs invalidos conhecidos
  if (newValue.length != 11 || invalid.indexOf(newValue) != -1) return false;

  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(newValue.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(newValue.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(newValue.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(newValue.charAt(10))) return false;

  return true;
};
