import { Grid, Typography } from '@mui/material';

import { CompanySummaryTO, SummaryWithOcurrenceBoundariesTO } from 'models';

import { SerasaModalItem } from '../../SerasaModalItem';

interface Props {
  overdueDebtsSummary: CompanySummaryTO | SummaryWithOcurrenceBoundariesTO;
}

const OverdueDebtsSummary = ({ overdueDebtsSummary }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Dívidas Vencidas (Convem) - Resumo
        </Typography>
      </Grid>
      <SerasaModalItem
        title="Quantiade"
        value={overdueDebtsSummary.count.toString()}
      />
      <SerasaModalItem
        title="Valor"
        value={overdueDebtsSummary.balance.toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
          minimumFractionDigits: 2,
        })}
      />
    </Grid>
  );
};

export { OverdueDebtsSummary };
