import { RoutesProps } from 'routes/types';

import { useGetPublicRoutes } from '.';
import PrivateLayoutRoute from '../Route';

export function useGeneratePublicLayoutRoutes() {
  const { publicRoutes } = useGetPublicRoutes();

  const publicLayoutRoutes: JSX.Element[] = [];

  function addPublicRoute(route: RoutesProps) {
    publicLayoutRoutes.push(
      <PrivateLayoutRoute
        key={route.path}
        exact
        path={route.path}
        component={route.component}
      />,
    );

    if (route.subs) {
      route.subs.forEach(addPublicRoute);
    }
  }

  publicRoutes.forEach(addPublicRoute);
  return { publicLayoutRoutes };
}
