import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IQuestion, IRole, QuestionType } from 'models';

import { useAuth } from 'config/auth/hooks';

import { EFormAnswerType } from 'modules/dynamicForm/formAnswer';
import useFormAnswerStore from 'modules/dynamicForm/formAnswer/store/store';

import {
  useCreateFormRequest,
  useDuplicateFormRequest,
  useDynamicFormForm,
  useFindRoles,
  useGetFormsQuestion,
} from '.';
import {
  IConditionQuestion,
  IConditionsPayload,
  IFormPayload,
  IFormQuestionPayload,
  IQuestionForm,
} from '../services';
import { useFormStore } from '../store/store';
import { useCreateFormQuestionRequest } from './useCreateFormQuestionRequest';

export interface DeltaExpressionPayload {
  ops: {
    insert: {
      mention: {
        id: string;
        origin: 'sys' | 'form';
        title: string;
      };
    };
  }[];
}

export const useDynamicForm = () => {
  const form = useDynamicFormForm();
  const auth = useAuth();
  const history = useHistory();
  const {
    questionsForm,
    idFormDuplicate,
    openConfirmDuplicateDialog,
    currentForm,
    renderButtonSimulate,
    setQuestionsForm,
    setIdFormDuplicate,
    setOpenConfirmDuplicateDialog,
    setRenderButtonSimulate,
    setCurrentForm,
  } = useFormStore();
  const { setFormRecordId } = useFormAnswerStore();
  const ROLES_ADMINS = useFindRoles('Administrador');
  const ROLES_COLLABORATORS = useFindRoles('Colaborador');
  const ROLES_CONSULTANT = useFindRoles('Consultor');
  const queryClient = useQueryClient();
  const roles: IRole[] = [];
  const { handleDeleteChildsQuestions } = useGetFormsQuestion();
  const selectValue = form.watch('userPermission');
  const formForConsultants = selectValue === ROLES_CONSULTANT?.data?.[0]?.id;

  const hasSysVar = questionsForm
    .flatMap(question => question?.conditions || [])
    .flatMap(condition => condition.conditionTypeInput?.deltaExpression || [])
    .flatMap(expression => (expression as DeltaExpressionPayload).ops || [])
    .some(op => op?.insert?.mention?.origin === 'sys');
  const queryParams = location.search;
  const isEdit = queryParams.replace('?edit=', '');

  useEffect(() => {
    if (!!ROLES_ADMINS?.data) {
      roles.push(...ROLES_ADMINS.data);
    }
  }, [ROLES_ADMINS, ROLES_COLLABORATORS]);

  useEffect(() => {
    if (hasSysVar && formForConsultants) {
      toast.error(
        'Não é possível utilizar variáveis do sistema para formulários para consultores.',
      );
    }
  }, [selectValue]);

  useEffect(() => {
    if (isEdit) {
      setRenderButtonSimulate(true);
      return;
    }
    setRenderButtonSimulate(false);
  }, [isEdit]);

  function getAllConditionsTypeMultipleChoice(
    conditions: IConditionQuestion[],
    externFalseId: string | undefined,
  ) {
    const allConditons = [] as IConditionsPayload[];

    if (conditions && conditions.length > 0) {
      conditions.map(condition => {
        const questionOption = condition.condition;
        const variables = condition.variables;
        const conditionFalse = condition?.nextConditionFalse;

        if (variables && variables.length > 0) {
          variables.map(variable => {
            allConditons.push({
              optionConditional: questionOption,
              expression: variable.expression,
              default: false,
              questionId: variable.nextQuestion,
              deltaExpression: variable.deltaExpression,
            });
          });
        }

        if (conditionFalse) {
          allConditons.push({
            optionConditional: questionOption,
            expression: '',
            default: true,
            questionId: conditionFalse,
            deltaExpression: {} as ReactQuill.Value,
          });
        }
      });
    }

    if (externFalseId && externFalseId.length > 0) {
      allConditons.push({
        expression: '',
        default: true,
        questionId: externFalseId,
        deltaExpression: {} as ReactQuill.Value,
      });
    }

    return allConditons;
  }

  function getAllConditionsTypeInput(
    conditions: IConditionQuestion[],
    externFalseId: string | undefined,
  ) {
    const allConditons = [] as IConditionsPayload[];

    if (conditions && conditions.length > 0) {
      conditions.map(condition => {
        const conditionInput = condition?.conditionTypeInput;

        if (conditionInput && conditionInput?.nextQuestion.length > 0) {
          allConditons.push({
            expression: conditionInput?.expression,
            default: false,
            questionId: conditionInput.nextQuestion,
            deltaExpression: conditionInput.deltaExpression,
          });
        }
      });
    }

    if (externFalseId && externFalseId.length > 0) {
      allConditons.push({
        expression: '',
        default: true,
        questionId: externFalseId,
        deltaExpression: {} as ReactQuill.Value,
      });
    }

    return allConditons;
  }

  async function createQuestionChild(
    formId: string,
    payload: IFormQuestionPayload,
  ) {
    return await requestCreateFormQuestion.mutateAsync({
      formId,
      formQuestion: payload,
    });
  }

  const savedQuestions = questionsForm;
  let currentIndex = 0;

  const requestCreateFormQuestion = useCreateFormQuestionRequest({
    onSuccess: async data => {
      savedQuestions[currentIndex] = {
        ...savedQuestions[currentIndex],
        savedId: data.id,
      };
      currentIndex++;

      const conditions = data?.conditions;

      // Each condition is a new child question
      if (conditions && conditions.length > 0) {
        await conditions.reduce(async (previousPromise, condition) => {
          await previousPromise;

          const childId = condition.questionId;

          const questionChild = questionsForm.find(
            qF => qF.question?.id === childId && qF.numberQuestion !== -1,
          );

          if (questionChild) {
            const typeQuestion = questionChild.question.type;

            const payload: IFormQuestionPayload = {
              form: `${data?.form}`,
              question: questionChild?.question?.id,
              answerValueMapping: questionChild?.question?.systemValueMapping?.id,
              formQuestion: data?.id,
              conditions:
                QuestionType[typeQuestion] === QuestionType.MULTIPLE_CHOICE
                  ? await getAllConditionsTypeMultipleChoice(
                      questionChild?.conditions,
                      questionChild?.nextQuestionFalse,
                    )
                  : await getAllConditionsTypeInput(
                      questionChild?.conditions,
                      questionChild?.nextQuestionFalse,
                    ),
            };

            await createQuestionChild(`${data?.form}`, {
              ...payload,
              conditions: payload.conditions.map(cond => {
                const ops = (cond?.deltaExpression as DeltaExpressionPayload)?.ops;

                if (!ops) return cond;

                return {
                  ...cond,
                  deltaExpression: {
                    ops: ops.map(op => {
                      const isSysVar = op?.insert?.mention?.origin === 'sys';
                      const hasNoMention = !op?.insert?.mention;

                      if (hasNoMention || isSysVar) return op;

                      return {
                        ...op,
                        insert: {
                          mention: {
                            ...op.insert.mention,
                            id: savedQuestions.find(
                              ({ question }) =>
                                question.title === op.insert.mention.title,
                            )?.savedId,
                          },
                        },
                      };
                    }),
                  },
                };
              }),
            } as IFormQuestionPayload);
          }
        }, Promise.resolve());
      }
    },
    onError: () => {
      toast.error(
        'Houve um erro ao tentar salvar a primeira questão do formulário',
      );
    },
  });

  const requestCreateForm = useCreateFormRequest({
    onSuccess: async data => {
      const firstQuestion = questionsForm.find(
        qF => qF.isFirstQuestion === true && qF.numberQuestion !== -1,
      );

      if (firstQuestion) {
        const typeQuestion = firstQuestion.question.type;

        const payload: IFormQuestionPayload = {
          form: data?.id,
          question: firstQuestion?.question?.id,
          answerValueMapping: firstQuestion?.question?.systemValueMapping?.id,
          formQuestion: null,
          conditions:
            QuestionType[typeQuestion] === QuestionType.MULTIPLE_CHOICE
              ? await getAllConditionsTypeMultipleChoice(
                  firstQuestion?.conditions,
                  firstQuestion?.nextQuestionFalse,
                )
              : await getAllConditionsTypeInput(
                  firstQuestion?.conditions,
                  firstQuestion?.nextQuestionFalse,
                ),
        };

        requestCreateFormQuestion.mutate({
          formId: data?.id,
          formQuestion: payload,
        });

        toast.success('Formulário criado com sucesso!');
        queryClient.invalidateQueries('forms');
        setRenderButtonSimulate(true);
        setCurrentForm(data);
      }
    },
    onError: () => {
      toast.error('Houve um erro ao tentar criar o form');
    },
  });

  function handleOpenPageCreateDynamicForm() {
    setQuestionsForm([]);
    history.push('/config-form');
  }

  function handleAddNewQuestion() {
    const lengthCurrentForm = questionsForm.length;
    const newQuestion: IQuestionForm = {
      numberQuestion: lengthCurrentForm + 1,
      nextQuestionFalse: '',
      isFirstQuestion: true,
      isLastQuestion: false,
      isChild: false,
      openDropdown: true,
    } as IQuestionForm;

    setQuestionsForm([...questionsForm, newQuestion]);
  }

  function handleDeleteCardQuestion(identifierQuestion: number) {
    const indexQuestionForm = identifierQuestion - 1;

    questionsForm[indexQuestionForm].question = {} as IQuestion;
    questionsForm[indexQuestionForm].numberQuestion = -1; //deletado

    setQuestionsForm(questionsForm);
    handleDeleteChildsQuestions();
  }

  const handleSubmitForm = form.handleSubmit(async data => {
    const firstQuestion = questionsForm.find(qF => qF.isFirstQuestion === true);
    if (!firstQuestion) {
      toast.error('Primeira Questão nao encontrada!');
      return;
    }

    if (hasSysVar && formForConsultants) {
      toast.error(
        'Não é possível utilizar variáveis do sistema para formulários para consultores.',
      );
      return;
    }

    let correctLastQuestion = true;
    let questionSelected = '';

    questionsForm.map(qF => {
      if (qF.numberQuestion === -1) return;

      const nextFalseExtern = qF?.nextQuestionFalse;

      if (nextFalseExtern && nextFalseExtern.length > 0) return;

      if (!qF.isLastQuestion) {
        const conditions = qF?.conditions;

        const hasChild = conditions?.find(
          condition => condition.identifierCondition !== -1,
        );

        if (!conditions || !hasChild) {
          correctLastQuestion = false;
          questionSelected = qF?.question?.title;
          return;
        }
      }
    });

    if (!correctLastQuestion) {
      toast.error(
        `Indique pelo menos uma proxima pegunta para a questão: ${questionSelected} - Ou selecione a mesma como última`,
      );
      return;
    }

    const payload: IFormPayload = {
      ...data,
      active: true,
      caption: data?.caption && data.caption?.length > 0 ? data.caption : null,
      user: auth?.user?.id ?? '',
    };

    requestCreateForm.mutate(payload);
  });

  const requestDuplicateForm = useDuplicateFormRequest({
    onSuccess: () => {
      toast.success('Formulário duplicado com sucesso');
      queryClient.invalidateQueries('forms');
      setOpenConfirmDuplicateDialog(false);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar duplicar o form');
    },
  });

  const duplicateForm = () => {
    requestDuplicateForm.mutate(idFormDuplicate);
  };

  const simulateForm = () => {
    if (currentForm?.id) {
      setFormRecordId('');
      history.push(`/form-answer/${currentForm?.id}/${EFormAnswerType.simulation}`);
      return;
    }

    toast.error('Formulário pra simulação não encontrado!');
  };

  const handleClose = () => {
    setRenderButtonSimulate(false);
    history.push('/dynamic-form');
  };

  return {
    roles: [
      ...(ROLES_ADMINS?.data || []),
      ...(ROLES_COLLABORATORS?.data || []),
      ...(ROLES_CONSULTANT?.data || []),
    ],
    form: {
      isLoading: requestCreateForm.isLoading,
      handleSubmitForm,
      ...form,
    },
    openConfirmDuplicateDialog,
    renderButtonSimulate,
    setOpenConfirmDuplicateDialog,
    setIdFormDuplicate,
    duplicateForm,
    getAllConditionsTypeInput,
    getAllConditionsTypeMultipleChoice,
    handleAddNewQuestion,
    handleDeleteCardQuestion,
    handleOpenPageCreateDynamicForm,
    simulateForm,
    handleClose,
  };
};

export type UseDynamicForm = ReturnType<typeof useDynamicForm>;
