import { TableContainer } from '@mui/material';
import styled from 'styled-components';

const TableContainerWrapper = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 2.5rem;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.space[4]}px;
  border-top-right-radius: ${({ theme }) => theme.space[4]}px;
  border: 1px solid ${({ theme }) => theme.colors['gray-100']};

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.colors['gray-100']};
    border-radius: ${({ theme }) => theme.space[4]}px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors['gray-300']};
    border-radius: ${({ theme }) => theme.space[4]}px;
  }
`;

export { TableContainerWrapper };
