import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { RolesService } from 'modules/roles';
import { useRoleStore } from 'modules/roles';

export const useFindRoles = () => {
  const {
    page,
    perPage,
    setPage,
    setLimit,
    limit,
    inactive,
    search,
    orderBy,
    sort,
  } = useRoleStore();

  const { data, isLoading, refetch } = useQuery(
    ['roles', page, inactive, search, sort],
    () =>
      RolesService.list({
        page,
        perPage,
        active: inactive ? undefined : true,
        orderBy,
        sort,
        role: search,
      }),
    { keepPreviousData: true },
  );

  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
    }
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
  };
};
