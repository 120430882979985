import { Grid, TextField } from '@mui/material';

import {
  Box,
  ButtonContained,
  ButtonOutlined,
  ContainerFilters,
  Dialog,
  MessageFilter,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import {
  useFilterDialog,
  useSearchDebounce,
  useFilterForm,
} from 'modules/user/hooks';
import { IUserFilterByOption } from 'modules/user/services';
import { useUserStore } from 'modules/user/store/store';

import { OptionsValues } from 'utils/helper';

import { FiltersSelected } from './FiltersSelected';

export default function DialogFilter() {
  const { open, handleCloseFilterDialog } = useFilterDialog();
  const { setSearchOption } = useUserStore();
  const {
    options,
    isFiltering,
    filterBy,
    handleChangeFilter,
    handleRemoveFilter,
    currentFilter,
    resetSearchOption,
    resetFilter,
    isOptionEqualToValue,
    filterOptions,
    setFilterBy,
  } = useFilterForm();

  const { handleChangeSearch } = useSearchDebounce(500, setSearchOption);

  return (
    <Dialog.Root open={open} onClose={handleCloseFilterDialog}>
      <Dialog.Header title="Configurar filtro" />
      <Box width={650} alignItems={'center'}>
        <Dialog.Content>
          <ContainerFilters>
            <Grid container width={650} p={4} pb={3} pt={2} spacing={2}>
              <MessageFilter />
              <Grid item xs={12}>
                <AutocompleteWrapper
                  multiple={false}
                  options={filterOptions}
                  renderInput={params => (
                    <TextField {...params} label="Filtrar por" />
                  )}
                  value={filterBy}
                  onChange={(e, value) => setFilterBy(value as IUserFilterByOption)}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={options}
                  multiple={true}
                  loadingText="Carregando..."
                  noOptionsText="Nenhuma opção encontrada"
                  renderTags={() => null}
                  onClose={resetSearchOption}
                  filterSelectedOptions
                  onReset={resetFilter}
                  value={currentFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione a opção"
                      onChange={e => handleChangeSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => {
                    handleChangeFilter(value as OptionsValues[]);
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  disableClearable
                />
              </Grid>
            </Grid>
          </ContainerFilters>
          <Grid item xs={12}>
            <FiltersSelected
              currentFilter={currentFilter}
              onRemove={handleRemoveFilter}
            />
          </Grid>
        </Dialog.Content>
      </Box>
      <SectionButtons>
        <ButtonOutlined
          label="Limpar filtros"
          onClick={resetFilter}
          disabled={!isFiltering}
        />
        <ButtonContained label="Fechar" onClick={() => handleCloseFilterDialog()} />
      </SectionButtons>
    </Dialog.Root>
  );
}
