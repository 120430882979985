import styled from 'styled-components';

import { Box } from 'components';

const RootWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
`;

export { RootWrapper };
