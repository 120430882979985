import { create } from 'zustand';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { ISignUpEnterprisePayload } from '../services';

type State = {
  //pagination
  page: number;
  perPage: number;
  limit: boolean;

  step: number;
  email: string;
  acceptTerms: boolean;
  labelEmailButton: string;
  disableEmailButton: boolean;
  renderCodeField: boolean;
  codeValidated: boolean;
  enterprisePayload: ISignUpEnterprisePayload;
  isSigningUp: boolean;
};

const initialState: State = {
  //pagination
  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,
  step: 1,
  email: '',
  acceptTerms: false,
  labelEmailButton: '',
  disableEmailButton: true,
  renderCodeField: false,
  codeValidated: false,
  enterprisePayload: {} as ISignUpEnterprisePayload,
  isSigningUp: false,
};

interface Actions {
  //pagination
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;

  setStep: () => void;
  setEmail: (email: string) => void;
  setAcceptTerms: (acceptTerms: boolean) => void;
  setLabelEmailButton: (labelEmailButton: string) => void;
  setDisableEmailButton: (disableEmailButton: boolean) => void;
  setRenderCodeField: (renderCodeField: boolean) => void;
  setCodeValidated: (codeValidated: boolean) => void;
  setEnterprisePayload: (enterprisePayload: ISignUpEnterprisePayload) => void;
  setIsSigningUp: (isSigningUp: boolean) => void;
  clearSignupEnterpriseStore: () => void;
}

const useSignUpEnterpriseStore = create<State & Actions>(set => ({
  ...initialState,
  setPage: page => set({ page }),
  setPerPage: perPage => set({ perPage }),
  setLimit: limit => set({ limit }),
  setStep: () => set(state => ({ step: state.step === 2 ? 1 : state.step + 1 })),
  setEmail: email => set({ email }),
  setAcceptTerms: acceptTerms => set({ acceptTerms }),
  setLabelEmailButton: labelEmailButton => set({ labelEmailButton }),
  setDisableEmailButton: disableEmailButton => set({ disableEmailButton }),
  setRenderCodeField: renderCodeField => set({ renderCodeField }),
  setCodeValidated: codeValidated => set({ codeValidated }),
  setEnterprisePayload: enterprisePayload => set({ enterprisePayload }),
  setIsSigningUp: isSigningUp => set({ isSigningUp }),
  clearSignupEnterpriseStore: () => {
    set(initialState);
  },
}));

export { useSignUpEnterpriseStore };
