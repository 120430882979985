import create from 'zustand';

import { IRole } from 'models/roles';

import { DEFAULT_PAGINATION } from 'utils/helper';
import { IOrderBy, ISort, getDefaultOrdination } from 'utils/ordination';

const DEFAULT_ORDINATION = getDefaultOrdination<IRole>();

interface Store {
  inactive: boolean;
  setInactive: (active: boolean) => void;
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  limit: boolean;
  setLimit: (limit: boolean) => void;
  orderBy: IOrderBy<IRole>;
  setOrderBy: (orderBy: IOrderBy<IRole>) => void;
  sort: ISort;
  setSort: (sort: ISort) => void;
  search: string;
  setSearch: (search: string) => void;

  openDialog: boolean;
  setOpenDialog: (openDialog: boolean) => void;

  currentRole: IRole | undefined;
  setCurrentRole: (currentRole: IRole | undefined) => void;
}

const useRoleStore = create<Store>(set => ({
  inactive: false,
  setInactive: inactive => set({ inactive }),
  page: DEFAULT_PAGINATION.page,
  setPage: page => set({ page }),
  perPage: DEFAULT_PAGINATION.perPage,
  setPerPage: perPage => set({ perPage }),
  limit: false,
  setLimit: limit => set({ limit }),
  orderBy: DEFAULT_ORDINATION.orderBy,
  setOrderBy: orderBy => set({ orderBy }),
  sort: DEFAULT_ORDINATION.sort,
  setSort: sort => set({ sort }),
  search: '',
  setSearch: search => set({ search }),
  openDialog: false,
  setOpenDialog: openDialog => set({ openDialog }),
  currentRole: undefined,
  setCurrentRole: currentRole => set({ currentRole }),
}));

export { useRoleStore };
