export function limitSomeDescription(
  description?: string,
  numberLimitCharacters?: number,
) {
  const limitCharacters = numberLimitCharacters || 50;

  if (description) {
    const descriptionLimited =
      description.length > limitCharacters
        ? description.substring(0, limitCharacters) + '...'
        : description;
    return descriptionLimited;
  }
  return '---';
}
