import { BubbleDataPoint, ChartTypeRegistry, ScatterDataPoint } from 'chart.js';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

export const exfinPatternColors: string[] = [
  'rgba(0, 43, 106, 1)',
  'rgba(51, 185, 255, 1)',
  'rgba(52, 58, 72, 1)',
  'rgba(0, 43, 106, 0.8)',
  'rgba(51, 185, 255, 0.5)',
  'rgba(52, 58, 72, 0.8)',
  'rgba(0, 43, 106, 0.5)',
  'rgba(51, 185, 255, 0.2)',
  'rgba(52, 58, 72, 0.5)',

  'rgba(0, 14, 26, 1)',
];

export const randomColors: string[] = [
  'rgb(0, 43, 106)',
  'rgb(189, 214, 238)',
  'rgb(230, 145, 56)',
  'rgb(98, 219, 149)',
  'rgb(54, 112, 255)',
  'rgb(232, 68, 84)',
];

export function getRandomColor(colorsUsed: string[]) {
  while (true) {
    // get randomics values
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    const color = `rgba(${r}, ${g}, ${b}, 1)`; //generate color rgba

    if (!colorsUsed.includes(color)) {
      //verify if already used
      return color;
    }
  }
}

export const getColors = (
  data: number[] | string[],
  exfinColors: boolean,
): string[] => {
  const colors: string[] = [];
  const allColors = exfinColors ? exfinPatternColors : randomColors;

  data.map((_, index) => {
    const verifyHasColor = index + 1 <= allColors.length;
    if (verifyHasColor) {
      colors.push(allColors[index]);
      return;
    }
    const randomColor = getRandomColor(colors);
    colors.push(randomColor);
  });

  return colors;
};

export const calculateSumChart = (
  dataChart: ChartJSOrUndefined<
    keyof ChartTypeRegistry,
    (number | ScatterDataPoint | BubbleDataPoint | null)[],
    unknown
  >,
) => {
  if (!dataChart) return 0;

  const sumTotal = dataChart.data.datasets.reduce((acc, curr) => {
    curr.data.forEach((d, invisibility) => {
      if (dataChart.getDataVisibility(invisibility)) {
        acc += Number(d);
      }
    });

    return acc;
  }, 0);

  return sumTotal;
};

export const increaseDistanceLegendPlugin = (distance: number) => {
  const pluginDistanceLegend = {
    id: 'apply_distance_legend_graph',
    beforeInit(chart) {
      const origFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        origFit.bind(chart.legend)();
        this.height += distance;
      };
    },
  };

  return pluginDistanceLegend;
};
