import { useQuery } from 'react-query';

import { IForm } from 'models';

import { FormService } from 'modules/dynamicForm/form';

import { OptionsValuesString } from 'utils/helper';

export const useListAllForms = ({
  enabled,
  active,
}: {
  enabled?: boolean;
  active?: boolean;
}) => {
  const { data, isLoading, refetch } = useQuery(
    ['listAllForms'],
    () => FormService.listAll({ active }) as Promise<IForm[]>,
    { enabled: enabled ?? true, keepPreviousData: true },
  );

  const formOptions: OptionsValuesString[] =
    data?.map(form => ({
      value: form.id,
      label: `${form.title}`,
    })) || [];

  return {
    formOptions,
    dataAllQuestions: data,
    isLoading,
    refetch,
  };
};
