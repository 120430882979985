import { ClientDestineStatus, IUser, UserPositions, UserTypes } from 'models';

export enum CustomerQueryKey {
  LIST_CUSTOMERS = 'LIST_CUSTOMERS',
  LIST_CREDITORS = 'LIST_CREDITORS',
  UPDATE_STATUS = 'UPDATE_STATUS',
  LIST_MAIN_CUSTOMERS = 'LIST_MAIN_CUSTOMERS',
}

export type IFilterValues =
  | 'credor'
  | 'empresa'
  | 'responsavel'
  | 'ultimoAto'
  | 'ativo'
  | 'inativo';

export interface IAppliedFilter {
  label: string;
  value: IFilterValues;
}

export enum ICustomerBoardFilterBy {
  'TYPE' = 'enterpriseType',
  'RESPONSIBLE' = 'responsible',
  'STATUS' = 'customerStatus',
  'PRODUCT' = 'product',
  'ACTIVE_INACTIVE' = 'activeInactive',
  'PRIMARY_ENTERPRISE' = 'primaryEnterprise',
}
export enum ICustomerBoardFilterByLabel {
  'TYPE' = 'Tipo',
  'RESPONSIBLE' = 'Reponsável',
  'STATUS' = 'Status',
  'PRODUCT' = 'Produto',
  'ACTIVE_INACTIVE' = 'Ativo/Inativo',
  'PRIMARY_ENTERPRISE' = 'Empresa principal',
}

export interface ICustomerBoardFilter {
  label: string;
  value: number;
  filterBy: ICustomerBoardFilterBy;
}

export interface ICustomerBoardFilterByOption {
  value: ICustomerBoardFilterBy;
  label: ICustomerBoardFilterByLabel;
}

export const DEFAULT_CUSTOMER_BOARD_FILTER_BY = {
  value: ICustomerBoardFilterBy.TYPE,
  label: ICustomerBoardFilterByLabel.TYPE,
};

export const customerBoardFilterByOptions: ICustomerBoardFilterByOption[] = [
  {
    value: ICustomerBoardFilterBy.TYPE,
    label: ICustomerBoardFilterByLabel.TYPE,
  },
  {
    value: ICustomerBoardFilterBy.RESPONSIBLE,
    label: ICustomerBoardFilterByLabel.RESPONSIBLE,
  },
  {
    value: ICustomerBoardFilterBy.STATUS,
    label: ICustomerBoardFilterByLabel.STATUS,
  },
  {
    value: ICustomerBoardFilterBy.PRODUCT,
    label: ICustomerBoardFilterByLabel.PRODUCT,
  },
  {
    value: ICustomerBoardFilterBy.ACTIVE_INACTIVE,
    label: ICustomerBoardFilterByLabel.ACTIVE_INACTIVE,
  },
  {
    value: ICustomerBoardFilterBy.PRIMARY_ENTERPRISE,
    label: ICustomerBoardFilterByLabel.PRIMARY_ENTERPRISE,
  },
];

export interface ICustomerPayload {
  search?: string;
  page?: number;
  perPage?: number;
  activeInactive?: boolean[];
  enterpriseType?: string[];
  customerStatus?: string[];
  product?: string[];
  responsible?: string[];
  primaryEnterprise?: string[];
}

export interface ICustomerResponse {
  id: string;
  active: boolean;
  name: string;
  userType: 'Empresa' | 'Credor';
  creditor: IUser;
  enterprise: {
    id: string;
    cnpj: string;
    fantasyName: string;
    responsible: IUser;
    lastAct: string;
  };
}

export interface ICustomerRegisterPayload {
  cnpj: string;
  fantasyName: string;
  name: string;
  surname: string;
  cpf: string;
  birthDate: string;
  email: string;
  confirmEmail?: string;
  telephone: string;
  site: string;
  linkedin: string;
  role: UserPositions;
  type: UserTypes;
  primaryEnterprise: string | null;
}

export interface ICustomerRegisterResponse {
  cnpj: string;
  fantasyName: string;
  name: string;
  surname: string;
  cpf: string;
  birthDate: string;
  email: string;
  telephone: string;
  site: string;
  linkedin: string;
  role: UserPositions;
  type: string;
}

export interface IUpdateCustomerStatusPayload {
  customerIds: string[];
  newStatus: ClientDestineStatus;
}
