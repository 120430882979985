import { Delete } from '@mui/icons-material';
import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useActItem, useFindActs } from '../hooks';
import DialogConfirmInactives from './DialogConfirmInactives';
import { Item } from './Item';

export const List = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useFindActs();

  const {
    openConfirmInactivesDialog,
    setOpenConfirmInactivesDialog,
    handleUpdateActs,
  } = useActItem();

  return (
    <TableContainer>
      <DialogConfirmInactives
        textInfo={
          'Você tem certeza que deseja desativar o(s) atos(s) selecionado(s)?'
        }
        showDialog={openConfirmInactivesDialog}
        setShowDialog={setOpenConfirmInactivesDialog}
        handleUpdate={handleUpdateActs}
      />
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={550}>
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Ato
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Tipo de Ato
              </Typography>
            </TableCell>
            <TableCell align="center" width="60px">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Ativo/Inativo
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Editar
              </Typography>
            </TableCell>
            <TableCell
              width={20}
              align="center"
              onClick={() => setOpenConfirmInactivesDialog(true)}
            >
              <Delete color="action" />
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          columns={4}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhum ato encontrado"
        >
          {data?.map(act => (
            <Item key={act.id} {...act} />
          ))}
        </TableBody>
      </Table>

      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};
