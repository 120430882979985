import { Typography } from '@mui/material';

import {
  Dialog,
  ButtonContained,
  ButtonOutlined,
  SectionButtons,
  Box,
} from 'components';

interface ConfirmInactiveDialogProps {
  showDialog: boolean;
  title: string;
  informationCard: string;
  setShowDialog: (showDialog: boolean) => void;
  handleAction: () => void;
}

export default function DialogConfirmActions({
  showDialog,
  title,
  informationCard,
  setShowDialog,
  handleAction,
}: ConfirmInactiveDialogProps) {
  return (
    <Dialog.Root open={showDialog} onClose={() => setShowDialog(false)}>
      <Dialog.Header title={title} />
      <Box minWidth={500} mb={7}>
        <Typography textAlign={'center'} mt={4}>
          {informationCard}
        </Typography>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={() => setShowDialog(false)} />
        <ButtonContained label="Confirmar" onClick={() => handleAction()} />
      </SectionButtons>
    </Dialog.Root>
  );
}
