import Axios from 'axios';

import {
  getAccessToken,
  getRefreshToken,
  removeTokens,
  authWithRefreshToken,
} from 'config/auth';

import { IApiError } from './types';
import { getApiError, isAccessTokenError, isTokenError } from './utils';

const api = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // TODO: Chek if its not too much
  timeout: 60000,
});

api.interceptors.request.use(
  async config => {
    const accessToken = getAccessToken();
    if (accessToken) {
      // eslint-disable-next-line
      config.headers.Authorization = 'Bearer ' + accessToken;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;

    const unauthorizedError = error.response?.status === 401;
    const apiError = getApiError(error) as IApiError;
    const isATokenError = isTokenError(apiError?.title);

    if (unauthorizedError && isATokenError) {
      const isAnAccessTokenError = isAccessTokenError(apiError?.title);
      const refreshToken = getRefreshToken();

      if (isAnAccessTokenError && refreshToken && !originalRequest._retry) {
        try {
          originalRequest._retry = true;

          const newAccessToken = await authWithRefreshToken(refreshToken);

          api.defaults.headers.common.Authorization = 'Bearer ' + newAccessToken;
          return api(originalRequest);
        } catch (e) {
          removeTokens();
        }
      } else {
        removeTokens();
      }
    }

    return Promise.reject(error);
  },
);

export default api;
