import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  FormAnswerStatus,
  IFormAnswer,
  IQuestionAttachmentList,
  IFormQuestion,
} from 'models';

import { useAuth } from 'config/auth/hooks';

import {
  EFormAnswerType,
  IFormAnswerPayload,
} from 'modules/dynamicForm/formAnswer';
import useFormAnswerStore from 'modules/dynamicForm/formAnswer/store/store';

import useFormResumeStore from '../store/store';
import { useUpdateRevisionQuestionRequest } from './useUpdateRevisionQuestionRequest';

interface RouteParams {
  id: string;
  type: string;
}

export const useSaveRevisionQuestion = () => {
  const {
    revisionQuestion,
    setRevisionQuestion,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setOpenModalSendForm,
  } = useFormResumeStore();
  const {
    currentAnswer,
    questionAnswerAttachment,
    setIsRevisionQuestion,
    setCurrentAnswer,
    setCurrentQuestion,
    setCurrentQuestionAttachments,
    setQuestionAnswerAttachments,
  } = useFormAnswerStore();

  const history = useHistory();
  const { id, type } = useParams<RouteParams>();
  const { user } = useAuth();

  const clearCurrenQuestionData = () => {
    setIsRevisionQuestion(false);
    setCurrentQuestion({} as IFormQuestion);
    setCurrentAnswer('');
    setRevisionQuestion({} as IFormAnswer);
    setCurrentQuestionAttachments([]);
    setQuestionAnswerAttachments([]);
    setOpenModalCancel(false);
    setOpenModalFinishLater(false);
    setOpenModalSendForm(false);
  };

  const requestUpdateAnswer = useUpdateRevisionQuestionRequest({
    onSuccess: () => {
      if (revisionQuestion.status === FormAnswerStatus.DISCARDED) {
        clearCurrenQuestionData();
        if (type === EFormAnswerType.simulation) {
          history.push(`/form-resume/${id}/${EFormAnswerType.simulation}`);
          return;
        }
        history.push(`/form-resume/${id}/${EFormAnswerType.answer}`);
        return;
      }

      setIsRevisionQuestion(false);
      setRevisionQuestion({} as IFormAnswer);
      if (type === EFormAnswerType.simulation) {
        history.push(`/form-answer/${id}/${EFormAnswerType.simulation}`);
        return;
      }
      history.push(`/form-answer/${id}/${EFormAnswerType.answer}`);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar a resposta da pergunta');
    },
  });

  const handleSaveRevisionQuestion = async () => {
    const oldAnswer = revisionQuestion.answerValue;

    const oldAttachments: IQuestionAttachmentList[] = [];

    revisionQuestion.attachments?.map(att => {
      oldAttachments.push({ id: att?.id, file: att.file });
    });

    // checa se possui mesma resposta e se possui mesmos anexos (tbm serve de resposta);
    const sameAnswer =
      oldAnswer === currentAnswer &&
      JSON.stringify(oldAttachments) === JSON.stringify(questionAnswerAttachment);

    if (sameAnswer) {
      clearCurrenQuestionData();
      if (type === EFormAnswerType.simulation) {
        history.push(`/form-resume/${id}/${EFormAnswerType.simulation}`);
        return;
      }
      history.push(`/form-resume/${id}/${EFormAnswerType.answer}`);
      return;
    }

    const attachments = questionAnswerAttachment.map(att => att.file.id);

    const payload: IFormAnswerPayload = {
      ...revisionQuestion,
      answerValue: currentAnswer,
      answerAttachment: [...attachments],
      formId: id,
      enterpriseId: user?.enterprise?.id ?? '',
      questionId: revisionQuestion.formQuestion.id,
      actId: '',
      processActId: '',
      type: EFormAnswerType[type],
    };

    requestUpdateAnswer.mutate({
      id: revisionQuestion.id ?? '',
      body: payload,
    });
  };

  return {
    handleSaveRevisionQuestion,
  };
};
