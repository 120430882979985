import { useLocation } from 'react-router-dom';
import { RoutesProps } from 'routes';

const useSubMenuOpen = (routes: RoutesProps[]) => {
  const { pathname } = useLocation();

  const hasSubRouteActive = (routes: RoutesProps[], pathname: string) => {
    for (const route of routes) {
      if (route.path === pathname) {
        return true;
      }

      if (route.subs && route.subs.length > 0) {
        if (hasSubRouteActive(route.subs, pathname)) {
          return true;
        }
      }
    }

    return false;
  };

  const open = hasSubRouteActive(routes, pathname);

  return {
    open,
  };
};

export { useSubMenuOpen };
