import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { IAllQuestionsOutput } from './useGetTagsOutputsQuestion';
import { useGetTagsOutputsQuestion } from './useGetTagsOutputsQuestion';
import { useListAllQuestions } from './useListAllQuestions';

export const useGetFormsQuestion = () => {
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { questionsOptions, dataAllQuestions } = useListAllQuestions();

  let allOutputs: IAllQuestionsOutput[] = [];
  const newQuestionsForm: IQuestionForm[] = [...questionsForm];

  function getAllOutPuts(question: IQuestionForm) {
    if (question?.numberQuestion === -1) return;
    const { allQuestionsOutput } = useGetTagsOutputsQuestion(
      question,
      questionsOptions,
    );

    if (allQuestionsOutput && allQuestionsOutput.length > 0) {
      allQuestionsOutput.map(outputQuestion => {
        if (
          outputQuestion?.outputQuestion?.label &&
          outputQuestion?.outputQuestion.label.length > 0
        )
          allOutputs.push(outputQuestion);
      });
    }
  }

  function setAllNewQuestions(outputQuestion: IAllQuestionsOutput) {
    const output = outputQuestion.outputQuestion;
    const questionExist = questionsForm.find(
      qF => qF?.question?.id === output?.value && qF?.numberQuestion > 0,
    );

    if (!questionExist) {
      const findQuestionData = dataAllQuestions?.find(
        question => question.id === output?.value,
      );

      newQuestionsForm.push({
        numberQuestion: questionsForm.length + 1,
        nextQuestionFalse: '',
        isFirstQuestion: false,
        isLastQuestion: false,
        isChild: true,
        question: findQuestionData,
        openDropdown: true,
      } as IQuestionForm);
    }
  }

  questionsForm.map(async question => {
    await getAllOutPuts(question);
  });

  allOutputs.map(async outputQuestion => {
    await setAllNewQuestions(outputQuestion);
  });

  if (newQuestionsForm.length !== questionsForm.length) {
    setQuestionsForm(newQuestionsForm);
  }

  function handleDeleteSpecificChildQuestion(question: IQuestionForm) {
    const indexQuestion = question.numberQuestion - 1;
    const numberQuestion = questionsForm[indexQuestion]?.numberQuestion;

    if (numberQuestion && numberQuestion > 0) {
      const falseExtern = question.nextQuestionFalse;

      if (falseExtern && falseExtern.length > 0) {
        const externFalseQuestion = questionsForm.find(
          qF => qF?.question?.id === question?.nextQuestionFalse,
        );

        if (!externFalseQuestion) return;
        handleDeleteSpecificChildQuestion(externFalseQuestion);
      }
      // const typeInputTrue = question?.conditions?.
      question.conditions?.map(condition => {
        const nextQuestionTypeInput = condition?.conditionTypeInput?.nextQuestion;
        if (nextQuestionTypeInput && nextQuestionTypeInput.length > 0) {
          const questionTypeInput = questionsForm.find(
            qF => qF?.question?.id === nextQuestionTypeInput,
          );
          if (!questionTypeInput) return;
          handleDeleteSpecificChildQuestion(questionTypeInput);
        }

        const falseTypeMultipleChoice = condition?.nextConditionFalse;

        if (falseTypeMultipleChoice && falseTypeMultipleChoice.length > 0) {
          const questionFalseMultipleChoice = questionsForm.find(
            qF => qF?.question?.id === falseTypeMultipleChoice,
          );

          if (!questionFalseMultipleChoice) return;
          handleDeleteSpecificChildQuestion(questionFalseMultipleChoice);
        }

        condition?.variables?.map(variable => {
          const nextQuestionTypeMultipleChoice = variable?.nextQuestion;

          if (
            nextQuestionTypeMultipleChoice &&
            nextQuestionTypeMultipleChoice.length > 0
          ) {
            const questionTypeMultipleChoice = questionsForm.find(
              qF => qF?.question?.id === nextQuestionTypeMultipleChoice,
            );

            if (!questionTypeMultipleChoice) return;
            handleDeleteSpecificChildQuestion(questionTypeMultipleChoice);
          }
        });
      });
      questionsForm[indexQuestion].numberQuestion = -1;
    }
  }

  function handleDeleteChildsQuestions() {
    allOutputs = [];

    questionsForm.map(async questionForm => {
      if (questionForm.numberQuestion === -1) return;
      await getAllOutPuts(questionForm);
    });

    if (allOutputs.length > 0) {
      questionsForm.map(async qF => {
        if (qF.numberQuestion === -1) return;
        if (!qF?.isChild) return;

        const existOutputQuestion = allOutputs.find(
          output => output?.outputQuestion?.value === qF?.question?.id,
        );

        if (!existOutputQuestion) {
          await handleDeleteSpecificChildQuestion(qF);
        }
      });
    } else {
      // se nao tem nenhum output, delete all questions childs
      questionsForm.map(async qF => {
        if (qF.numberQuestion === -1) return;
        if (qF.isChild) {
          await handleDeleteSpecificChildQuestion(qF);
        }
      });
    }
  }

  return {
    newQuestionsForm,
    handleDeleteChildsQuestions,
    handleDeleteSpecificChildQuestion,
  };
};
