import { Icon } from 'components';

import { ClearFiltersWrapper } from './styles';

interface ClearFilterProps {
  onClear: () => void;
}

export const ClearFilters = ({ onClear }: ClearFilterProps) => {
  return (
    <ClearFiltersWrapper onClick={onClear}>
      <Icon fontSize="s" color="white">
        close
      </Icon>
    </ClearFiltersWrapper>
  );
};
