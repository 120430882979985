import { IconButton } from '@mui/material';

import { Icon, Box, ClearFilters } from 'components';
import { ButtonDownloadExcel } from 'components/FilterDatabase/ButtonDownloadExcel';

import { PrivateLayout } from 'layouts/PrivateLayout';

import {
  useDynamicFormStorageFilter,
  useDynamicFormStorageSearch,
  useHandleExcel,
} from '../hooks';
import { useDynamicFormStorageStore } from '../store/store';

const DynamicFormStorageContainer = () => {
  const { setSearch } = useDynamicFormStorageStore();

  const { handleChangeSearch } = useDynamicFormStorageSearch(500, setSearch);

  const { handleDownloadExcel } = useHandleExcel();

  const {
    isFiltering,
    resetFilter,
    handleOpenFilterDialog,
  } = useDynamicFormStorageFilter();

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title="Base de dados - Respostas de formulários"
            icon={<Icon>storage</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />

          <PrivateLayout.Content.Header.Actions>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
              position="relative"
              minWidth={40}
              display={'flex'}
              justifyContent={'center'}
              marginRight={3}
            >
              <IconButton onClick={handleOpenFilterDialog}>
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={resetFilter} />}
            </Box>
            <ButtonDownloadExcel
              handleDownload={() => handleDownloadExcel()}
              loading={false}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { DynamicFormStorageContainer };
