import { Logo } from './components/Logo';
import { Menu } from './components/Menu';
import { MenuItem } from './components/MenuItem';
import { MenuLogout } from './components/MenuLogout';
import { Root } from './components/Root';
import { SubMenu } from './components/SubMenu';
import { UserProfile } from './components/UserProfile';

export const Sidebar = {
  Root,
  Logo,
  UserProfile,
  Menu,
  SubMenu,
  MenuItem,
  MenuLogout,
};
