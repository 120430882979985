import { Add } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { Icon, ButtonOutlined, Box, ClearFilters } from 'components';
import { IUser } from 'models';

import { PrivateLayout } from 'layouts/PrivateLayout';

import {
  useFilterDialog,
  useSearchDebounce,
  useFilterForm,
  useDialog,
} from '../hooks';
import { useUserStore } from '../store/store';

export function Header() {
  const { setSearch } = useUserStore();
  const { handleOpenFormDialog } = useDialog();
  const { handleOpenFilterDialog } = useFilterDialog();
  const { resetFilter, isFiltering } = useFilterForm();
  const { handleChangeSearch } = useSearchDebounce(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            icon={<Icon fontSize="h4">people</Icon>}
            title="Usuários"
          />

          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={value => handleChangeSearch(value)}
          />
          <PrivateLayout.Content.Header.Actions>
            <Box position="relative">
              <IconButton onClick={handleOpenFilterDialog}>
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={resetFilter} />}
            </Box>
            <ButtonOutlined
              color="white"
              label={'Adicionar Usuário'}
              icon={<Add />}
              loading={false}
              onClick={() => handleOpenFormDialog({} as IUser)}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
}
