import { IFileStorage } from 'models/fileStorage';

import { Type } from './companyBigData.types';
import { KleverDocumentsList } from './kleverDocuments.types';

export enum EKleverAttachmentStatus {
  ANALYSIS = 'ANALYSIS',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ERROR = 'ERROR',
}

export enum EKleverAttachmentStatusToDisplay {
  ANALYSIS = 'Análise',
  COMPLETED = 'Completo',
  APPROVED = 'Aprovado',
  REJECTED = 'Rejeitado',
  ERROR = 'Erro',
}

export interface ICompanyKMethodAttachment {
  id: string;
  file: IFileStorage;
  changeStatusManually: boolean;
  cnpj: string;
  descriptionState: string;
  discarded: boolean;
  documentId: string;
  documentCreated: string;
  documentExt: string;
  documentName: string;
  documentUrl: string;
  documentDate: string;
  document: KleverDocumentsList;
  status: EKleverAttachmentStatus;
  attachmentType: Type;
  createdAt: string;
  updatedAt: string;
}
