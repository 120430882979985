import { useDashboardEnterpriseRequest } from './useDashboardEnterpriseRequest';

export const useDashboardEnterprise = () => {
  const { data, isLoading, refetch } = useDashboardEnterpriseRequest();
  let numbersWillRender = 0; // numero de tabelas e graficos que vao ser renderizados.

  //const vetoesAndAlertsNoData = !data?.vetoesAndAlertsData.kScale;

  const activesNoData = !data?.actives?.maxColumns;
  if (!activesNoData) numbersWillRender++;

  const passivesNoData = !data?.passives?.maxColumns;
  if (!passivesNoData) numbersWillRender++;

  const dreNoData = !data?.demonstrations?.maxColumns;
  if (!dreNoData) numbersWillRender = numbersWillRender + 2;

  const indexesAndIndicatorsNoData = !data?.indexesAndIndicators?.maxColumns;
  if (!indexesAndIndicatorsNoData) numbersWillRender++;

  const billingStatementsNoData = !data?.billingMonthly;
  if (!billingStatementsNoData) numbersWillRender = numbersWillRender + 2;

  const debtsNoData = !data?.debts;
  if (!debtsNoData) numbersWillRender = numbersWillRender + 3;

  const fiscalNotesNoData = !data?.fiscalNotes;
  if (!fiscalNotesNoData) numbersWillRender = numbersWillRender + 4;

  return {
    data,
    isLoading,
    //vetoesAndAlertsNoData,
    activesNoData,
    passivesNoData,
    dreNoData,
    indexesAndIndicatorsNoData,
    billingStatementsNoData,
    debtsNoData,
    fiscalNotesNoData,
    numbersWillRender,
    refetch,
  };
};
