import { create } from 'zustand';

import { IForgotPasswordPayload } from '../services';

type State = {
  step: number;
  emailValidated: boolean;
  codeValidated: boolean;
  forgotPasswordPayload: IForgotPasswordPayload;
};

const initialState: State = {
  step: 1,
  emailValidated: false,
  codeValidated: false,
  forgotPasswordPayload: {} as IForgotPasswordPayload,
};

interface Actions {
  setStep: () => void;
  setEmailValidated: (emailValidated: boolean) => void;
  setCodeValidated: (codeValidated: boolean) => void;
  setForgotPasswordPayload: (forgotPasswordPayload: IForgotPasswordPayload) => void;
  clearForgotPasswordStore: () => void;
}

const useForgotPasswordStore = create<State & Actions>()(set => ({
  ...initialState,
  setStep: () => set(state => ({ step: state.step === 3 ? 1 : state.step + 1 })),
  setEmailValidated: emailValidated => set({ emailValidated }),
  setCodeValidated: codeValidated => set({ codeValidated }),
  setForgotPasswordPayload: forgotPasswordPayload => set({ forgotPasswordPayload }),
  clearForgotPasswordStore: () => set(initialState),
}));

export { useForgotPasswordStore };
