import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { ICompanyKMethod, IPagination } from 'models';

import { KMethodQueryKey, KMethodService } from '../services';
import { useKMethodStore } from '../store/store';
import { useKMethodFilter } from './useKMethodFilter';

export const useKMethod = () => {
  const { limit, page, perPage, search, setLimit, setPage } = useKMethodStore();
  const { filterParams, filterBy, currentFilter } = useKMethodFilter();

  const { data: response, isLoading, refetch } = useQuery(
    [KMethodQueryKey.K_METHOD, page, search, filterBy, currentFilter],
    () =>
      (KMethodService.getKMethodData({
        page,
        perPage,
        search,
        ...filterParams,
      }) as unknown) as Promise<IPagination<ICompanyKMethod>>,
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  useEffect(() => {
    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
  };
};
