import { useQuery } from 'react-query';

import { IQuestion } from 'models';

import { QuestionService } from 'modules/dynamicForm/question/services/services';

import { OptionsValuesString } from 'utils/helper';

export const useListAllQuestions = () => {
  const { data, isLoading, refetch } = useQuery(
    ['listAllQuestions'],
    () => QuestionService.listAll() as Promise<IQuestion[]>,
    { keepPreviousData: true },
  );

  const questionsOptions: OptionsValuesString[] =
    data?.map(variables => ({
      value: variables.id,
      label: `${variables.title}`,
    })) || [];

  return {
    questionsOptions,
    dataAllQuestions: data,
    isLoading,
    refetch,
  };
};
