import { PrivateLayout } from 'layouts/PrivateLayout';

const HeaderDynamicForm = () => {
  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title="Configurar Formulário"
            icon=""
          />
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { HeaderDynamicForm };
