import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IValidationCodeFormPayload } from '../services';

export const validationCodeFormSchema = z.object({
  code: z.string().trim().nonempty('Insira o código'),
});

const defaultValues = {
  code: '',
};

export const useValidationCodeForm = () => {
  return useForm<IValidationCodeFormPayload>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(validationCodeFormSchema),
  });
};
