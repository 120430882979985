import { Breakpoint, Dialog } from '@mui/material';
import { ReactNode } from 'react';

interface RootProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  fullScreen?: boolean;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint | undefined;
  disableEscapeKeyDown?: boolean;
  style?: React.CSSProperties;
  backdropColor?: string;
  blurLevel?: number;
}

export function Root({
  children,
  open,
  onClose,
  fullScreen = false,
  fullWidth = false,
  maxWidth = 'xl',
  disableEscapeKeyDown = false,
  style,
  backdropColor = 'rgba(0, 0, 0, 0.5)',
  blurLevel = 1.5,
}: RootProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableEscapeKeyDown}
      style={style}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: backdropColor,
            backdropFilter: `blur(${blurLevel}px)`,
          },
        },
      }}
    >
      {children}
    </Dialog>
  );
}
