import { Balance, dreHighlight } from 'modules/dashboard/services';

import { DashboardTableContainer } from './DashboardTable';

interface BalanceProps {
  data: Balance | undefined | null;
  isLoading: boolean;
  noData: boolean;
}

const DreTable = ({ data, isLoading, noData }: BalanceProps) => {
  return (
    <DashboardTableContainer
      isLoading={isLoading}
      noData={noData}
      data={data ?? null}
      title=""
      mapping={dreHighlight}
      titleSession="DRE"
    />
  );
};

export { DreTable };
