import { Box } from '@mui/material';

import { ChipContained } from 'components';

import { IUserFilter } from 'modules/user/services';

type FiltersSelectedProps = {
  currentFilter: IUserFilter[];
  onRemove: (value: IUserFilter) => void;
};

export const FiltersSelected = ({
  currentFilter,
  onRemove,
}: FiltersSelectedProps) => {
  return (
    <Box
      marginLeft={3}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 1,
      }}
      maxHeight={250}
      maxWidth={570}
      overflow={'auto'}
      pb={1}
    >
      {currentFilter.map((option, index) => (
        <ChipContained
          key={`${option.label}-${index}`}
          label={option.label}
          onDelete={() => onRemove(option)}
        />
      ))}
    </Box>
  );
};
