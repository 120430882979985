import styled from 'styled-components';

import { Box } from 'components';

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[4]}px;
`;

const HeaderLogo = styled.img`
  max-width: 100px;
`;

export { HeaderWrapper, HeaderLogo };
