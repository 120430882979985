import { Paper, Typography } from '@mui/material';
import themes from 'themes';

import { Box } from 'components';
import { UserTypes } from 'models';

import { useSignIn } from '../hooks';
import { Form } from './Form';

interface SignInProps {
  title: UserTypes;
}

function SignIn({ title }: SignInProps) {
  const { form, request, handleCancel } = useSignIn();

  return (
    <Paper
      elevation={16}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: '3em',
        paddingTop: '3em',
        paddingBottom: '2em',
        marginY: 'auto',
        gap: '1em',
        backgroundColor: '#f0f0f0',
        borderRadius: '11px',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
        width: 532,
      }}
    >
      <Box display="flex" flexDirection="column" style={{ gap: '1em' }}>
        <Typography
          fontFamily={themes.fonts.body}
          variant="h4"
          fontSize={23}
          textAlign="center"
          fontWeight={700}
          color={themes.colors.blue}
        >
          {title === UserTypes.ENTERPRISE
            ? 'Portal de Empresas'
            : 'Portal de Instituições Financeiras'}
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          fontFamily={themes.fonts.body}
        >
          Digite suas credenciais
        </Typography>
      </Box>

      <Form
        form={form}
        isSigningIn={request.isLoading}
        handleCancel={handleCancel}
        origin={title}
      />
    </Paper>
  );
}

export default SignIn;
