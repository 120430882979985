import { Content } from './components/Content';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Root } from './components/Root';

export const PublicLayout = {
  Root,
  Header,
  Content,
  Footer,
};
