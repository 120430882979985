import { useLogout } from 'modules';

import { Icon, Typography } from 'components';

import { MenuLogoutWrapper } from './style';

export function MenuLogout() {
  const { setShowDialog } = useLogout();
  return (
    <MenuLogoutWrapper
      onClick={() => setShowDialog(true)}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
    >
      <Icon fontSize="h6" color="white">
        logout
      </Icon>
      <Typography fontSize={14} color={'white'} style={{ paddingLeft: 16 }}>
        Sair
      </Typography>
    </MenuLogoutWrapper>
  );
}
