import { Box, Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { Indebtedness } from 'models';

import { useDashboardStore } from 'modules/dashboard/store/store';

import { increaseDistanceLegendPlugin } from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface VctoDataItem {
  vctoUntil180dSum: number;
  vctoBetween181and360dSum: number;
  vctoGreaterThan360dSum: number;
  vctoTotalSum: number;
}

interface PairDataItem {
  pair: [number, number];
}

interface DebtsProps {
  data: Indebtedness[];
}

const DebtsVCTOChart = ({ data }: DebtsProps) => {
  const { setChartsImagesUrl } = useDashboardStore();

  const [sizeBarFull, setSizeBarFull] = useState(false);
  const [imagesSet, setImagesSet] = useState(false);

  const dataItem: VctoDataItem[] = [];

  const heightWindow = window.innerHeight;
  const widthBar = heightWindow > 900 ? '61%' : '57%';

  const getDataTableItem = () => {
    data?.map(debt => {
      dataItem.push({
        vctoUntil180dSum: debt?.untilSixMonths,
        vctoBetween181and360dSum: debt?.betweenSixAndTwelveMonths,
        vctoGreaterThan360dSum: debt?.moreThanTwelveMonths,
        vctoTotalSum: debt?.totalCurrentDebts,
      });
    });
    dataItem.sort((a, b) => b.vctoTotalSum - a.vctoTotalSum);

    return dataItem;
  };

  const dataTable = getDataTableItem();

  const totalSums = dataTable.reduce(
    (acc, item) => {
      acc.vctoTotalUntil180dSum += item.vctoUntil180dSum;
      acc.vctoTotalBetween181and360dSum += item.vctoBetween181and360dSum;
      acc.vctoTotalGreaterThan360dSum += item.vctoGreaterThan360dSum;
      acc.vctoTotalSum += item.vctoTotalSum;
      return acc;
    },
    {
      vctoTotalUntil180dSum: 0,
      vctoTotalBetween181and360dSum: 0,
      vctoTotalGreaterThan360dSum: 0,
      vctoTotalSum: 0,
    },
  );

  const vctoData = Object.values(totalSums);

  const getPairData = (data: number[]) => {
    const pairs: PairDataItem[] = [];
    let sum = 0;
    data.map((item, index) => {
      //first
      if (index === 0) {
        pairs.push({ pair: [0, Number(item.toFixed(2))] });
        sum = sum + Number(item.toFixed(2));
        return;
      }

      //last
      if (index + 1 === data.length) {
        pairs.push({ pair: [0, Number(item.toFixed(2))] });
        return;
      }

      pairs.push({ pair: [sum, Number(item.toFixed(2)) + sum] });
      sum = sum + Number(item.toFixed(2));
    });

    const pairsFiltered = pairs.map(item => item.pair);

    return pairsFiltered;
  };

  return (
    <Card elevation={2} style={{ width: widthBar, padding: 16, maxHeight: 550 }}>
      <Box display="flex" flexDirection="column" width={'100%'} height={'100%'}>
        <Chart
          type="bar"
          data={{
            labels: [
              'VCTO até 6 meses',
              'VCTO de 6 a 12 meses',
              'VCTO acima 12 meses',
              'TOTAL',
            ],
            datasets: [
              {
                label: 'Positivo',
                data: getPairData(vctoData),
                backgroundColor: [
                  'rgb(2, 97, 173)',
                  'rgb(2, 97, 173)',
                  'rgb(2, 97, 173)',
                  'rgb(153, 153, 153)',
                ],
                barThickness: sizeBarFull ? 130 : 'flex',
              },
              {
                label: 'Total',
                data: null,
                backgroundColor: ['rgb(153, 153, 153)'],
              },
            ],
          }}
          plugins={[
            increaseDistanceLegendPlugin(25),
            {
              id: 'apply_data_inside_bar',
              beforeDraw: function (chartInstance) {
                const ctx = chartInstance.ctx;
                ctx.font = `11px ${ChartJS.defaults.font.family}`;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                chartInstance.data.datasets.forEach(function (dataset, i) {
                  if (chartInstance.isDatasetVisible(i)) {
                    const meta = chartInstance.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                      const data = dataset.data[index];
                      if (data) {
                        const onlyValue = data[1] - data[0];
                        const valueSize = onlyValue.toFixed(2).toString().length;
                        if (valueSize > 12) {
                          setSizeBarFull(true);
                        }
                        const dataMoney = onlyValue?.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        });
                        ctx.fillText(String(dataMoney), bar.x, bar.y - 5);
                      }
                    });
                  }
                });
              },
            },
          ]}
          options={{
            responsive: true,
            skipNull: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: themes.colors.dashboard.table.text.black,
                },
              },
              title: {
                display: true,
                text: 'Saldo devedor',
                font: {
                  size: 24,
                },
                color: themes.colors.dashboard.table.text.black,
                align: 'start',
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                grid: {
                  drawBorder: false,
                  drawOnChartArea: true,
                },
                ticks: {
                  color: themes.colors.dashboard.table.text.black,
                },
                grace: '5%',
              },
              x: {
                grid: {
                  display: false,
                },
              },
            },
            animation: {
              onComplete: animation => {
                if (!imagesSet) {
                  setChartsImagesUrl(
                    'debtsVCTO',
                    animation.chart.toBase64Image(),
                    animation.chart.width,
                    animation.chart.height,
                  );

                  setImagesSet(true);
                }
              },
            },
          }}
        />
      </Box>
    </Card>
  );
};

export { DebtsVCTOChart };
