import { Box } from '@mui/material';

import { ButtonContained, ButtonOutlined } from 'components';

import { EFormAnswerType } from 'modules/dynamicForm/formAnswer';

import { useFormResume } from '../hooks';

const FormActions = () => {
  const {
    renderButtonsOptions,
    loadingTriggerDocSign,
    type,
    setOpenModalCancel,
    setOpenModalFinishLater,
    setOpenModalSendForm,
    handleCancelForm,
  } = useFormResume();

  return (
    <>
      {!renderButtonsOptions && (
        <Box display="flex" alignItems="center" justifyContent="end" gap={2}>
          <ButtonOutlined
            label="Cancelar"
            color="black"
            onClick={() =>
              type === EFormAnswerType.simulation
                ? handleCancelForm()
                : setOpenModalCancel(true)
            }
          />

          {type === EFormAnswerType.answer && (
            <ButtonContained
              label="Finalizar em Outro Momento"
              onClick={() => setOpenModalFinishLater(true)}
            />
          )}

          <ButtonContained
            label="Enviar"
            color="navy"
            disabled={loadingTriggerDocSign}
            loading={loadingTriggerDocSign}
            onClick={() => setOpenModalSendForm(true)}
          />
        </Box>
      )}
    </>
  );
};

export { FormActions };
