import { SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';

const SubMenuWrapper = styled(SubMenu)`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  a {
    height: 40px !important;
    border-radius: 0 25px 25px 0;
    margin-right: ${({ theme }) => theme.space[2]}px;
    margin-left: -${({ theme }) => theme.space[4]}px;
    font-size: ${({ theme }) => theme.fontSizes.m}px;

    span {
      margin-right: ${({ theme }) => theme.space[0]}px;
    }
  }

  a:hover {
    background-color: ${({ theme }) => theme.colors.navy} !important;
    transition: 0.2s ease-in-out;
  }
`;

export { SubMenuWrapper };
