import { Grid, TextField, Box } from '@mui/material';

import {
  ButtonContained,
  ButtonOutlined,
  Dialog,
  ChipContained,
  ContainerFilters,
  MessageFilter,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { OptionsValues } from 'utils/helper';

import {
  useFilterActs,
  useFilterSearch,
  useFilterRequest,
  useTabs,
} from '../../hooks';
import { IActTimelineFilterByOption } from '../../services';

const ModalFilterActs = () => {
  const {
    filterBy,
    currentFilter,
    optionsFilter,
    filterActsOptions,
    isFiltering,
    openFilterModal,
    clearFilter,
    handleChangesFilter,
    isOptionEqualToValue,
    setFilterBy,
    clearSearchOption,
    handleRemoveFilter,
    setOpenFilterModal,
    setSearchFilterOption,
  } = useFilterActs();
  const { openNegotiationModal } = useTabs();
  const { isLoading } = useFilterRequest(openNegotiationModal);
  const { handleChangeSearch } = useFilterSearch(500, setSearchFilterOption);

  return (
    <Dialog.Root open={openFilterModal} onClose={() => setOpenFilterModal(false)}>
      <Dialog.Header title="Filtro dos atos" />
      <Box width={650} alignItems={'center'}>
        <Dialog.Content>
          <ContainerFilters>
            <Grid container width={650} p={4} pb={3} pt={2} spacing={2}>
              <MessageFilter />
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={filterActsOptions}
                  renderInput={params => (
                    <TextField {...params} label="Filtrar por" />
                  )}
                  value={filterBy}
                  onChange={(e, value) => {
                    setFilterBy(value as IActTimelineFilterByOption);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={optionsFilter}
                  multiple={true}
                  loadingText="Buscando opções..."
                  noOptionsText="Nenhuma opção foi encontrada"
                  renderTags={() => null}
                  onClose={clearSearchOption}
                  filterSelectedOptions
                  onReset={clearFilter}
                  value={currentFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione uma opção"
                      onChange={e => handleChangeSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => {
                    handleChangesFilter(value as OptionsValues[]);
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  disableClearable
                />
              </Grid>
            </Grid>
          </ContainerFilters>

          <Grid>
            <Box
              marginLeft={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
              maxHeight={250}
              maxWidth={550}
              overflow={'auto'}
              pb={1}
            >
              {currentFilter.map((option, index) => (
                <ChipContained
                  key={`${option.label}-${index}`}
                  label={option.label}
                  onDelete={() => handleRemoveFilter(option)}
                />
              ))}
            </Box>
          </Grid>
        </Dialog.Content>
      </Box>
      <SectionButtons>
        <ButtonOutlined
          label="Limpar filtros"
          onClick={clearFilter}
          disabled={!isFiltering || isLoading}
        />
        <ButtonContained label="Fechar" onClick={() => setOpenFilterModal(false)} />
      </SectionButtons>
    </Dialog.Root>
  );
};

export { ModalFilterActs };
