import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IFormEdit } from '../services';
import { useFormStore } from '../store/store';
import { useUpdateFormRequest } from './useUpdateFormRequest';

export const useFormItem = () => {
  const [valueCheckBox, setValueCheckBox] = useState(false);
  const {
    list,
    listSetInativeForms,
    openConfirmInactivesDialog,
    setListSetInativeForms,
    setOpenConfirmInactivesDialog,
  } = useFormStore();
  const queryClient = useQueryClient();

  const requestUpdateForm = useUpdateFormRequest({
    onSuccess: () => {
      queryClient.invalidateQueries('forms');
    },
    onError: () => {
      toast.error('Houve um erro ao desativar o form');
    },
  });

  const handleInactiveForms = (id: string) => {
    const formAlredySetted = listSetInativeForms.find(
      idQuestion => idQuestion === id,
    );

    if (formAlredySetted) {
      const newList = listSetInativeForms.filter(id => id !== formAlredySetted);
      setListSetInativeForms(newList);
    } else {
      setListSetInativeForms([...listSetInativeForms, id]);
    }

    setValueCheckBox(!valueCheckBox);
  };

  const handleUpdateForms = () => {
    listSetInativeForms.map(id => {
      const formInactive = list.find(form => form.id === id);

      if (formInactive) {
        const form: IFormEdit = {
          title: formInactive.title,
          caption: formInactive.caption,
          active: false,
          user: formInactive.user.id,
          userPermission: formInactive.userPermission.id,
        };

        requestUpdateForm.mutate({
          id: formInactive.id,
          form,
        });
      }
    });

    setOpenConfirmInactivesDialog(false);
  };

  return {
    valueCheckBox,
    openConfirmInactivesDialog,
    handleInactiveForms,
    handleUpdateForms,
    setValueCheckBox,
    setOpenConfirmInactivesDialog,
  };
};

export type UseFormItem = ReturnType<typeof useFormItem>;
