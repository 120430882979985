import { create } from 'zustand';

export type ChartsImages = {
  abcCurveCustomer: {
    url: string;
    width: number;
    height: number;
  };
  abcCurveSupplier: {
    url: string;
    width: number;
    height: number;
  };
  debts: {
    url: string;
    width: number;
    height: number;
  };
  debtsVCTO: {
    url: string;
    width: number;
    height: number;
  };
  dre: {
    url: string;
    width: number;
    height: number;
  };
  monthlyBilling: {
    url: string;
    width: number;
    height: number;
  };
};

export type TablesImages = {
  // vetoesAndAlerts: {
  //   url: string;
  //   width: number;
  //   height: number;
  // };
  monthlyBilling: {
    url: string;
    width: number;
    height: number;
  };
  indexesAndIndicators: {
    url: string;
    width: number;
    height: number;
  };
  currentAssets: {
    url: string;
    width: number;
    height: number;
  };
  currentPassives: {
    url: string;
    width: number;
    height: number;
  };
  dre: {
    url: string;
    width: number;
    height: number;
  };
  debts: {
    url: string;
    width: number;
    height: number;
  };
  abcCurveCustomer: {
    url: string;
    width: number;
    height: number;
  };
  abcCurveSupplier: {
    url: string;
    width: number;
    height: number;
  };
};

export type GeneralDataImages = {
  companyData: {
    url: string;
    width: number;
    height: number;
  };
};

type State = {
  valueTab: number;
  chartsImagesUrl: ChartsImages;
  tablesImagesUrl: TablesImages;
  generalDataImageUrls: GeneralDataImages;
  numbersWillRender: number;
};

const imagesDataInitialState = {
  url: '',
  width: 0,
  height: 0,
};

const initialState: State = {
  valueTab: 0,
  chartsImagesUrl: {
    abcCurveCustomer: imagesDataInitialState,
    abcCurveSupplier: imagesDataInitialState,
    debts: imagesDataInitialState,
    debtsVCTO: imagesDataInitialState,
    dre: imagesDataInitialState,
    monthlyBilling: imagesDataInitialState,
  },
  tablesImagesUrl: {
    // vetoesAndAlerts: imagesDataInitialState,
    monthlyBilling: imagesDataInitialState,
    indexesAndIndicators: imagesDataInitialState,
    currentAssets: imagesDataInitialState,
    currentPassives: imagesDataInitialState,
    dre: imagesDataInitialState,
    debts: imagesDataInitialState,
    abcCurveCustomer: imagesDataInitialState,
    abcCurveSupplier: imagesDataInitialState,
  },
  generalDataImageUrls: {
    companyData: imagesDataInitialState,
  },
  numbersWillRender: 14, // 14 é o numero de tables/charts q vai renderizar se tiver todos dados.
};

interface Actions {
  setValueTab: (valueTab: number) => void;
  setChartsImagesUrl: (
    key: keyof ChartsImages,
    url: string,
    width: number,
    height: number,
  ) => void;
  setTablesImagesUrl: (
    key: keyof TablesImages,
    url: string,
    width: number,
    height: number,
  ) => void;
  setGeneralDataImageUrls: (
    key: keyof GeneralDataImages,
    url: string,
    width: number,
    height: number,
  ) => void;
  setNumbersWillRender: (value: number) => void;
}

const useDashboardStore = create<State & Actions>()(set => ({
  ...initialState,

  setValueTab: valueTab => set({ valueTab }),
  setChartsImagesUrl: (key, url, width, height) =>
    set(state => ({
      chartsImagesUrl: {
        ...state.chartsImagesUrl,
        [key]: { url, width, height },
      },
    })),
  setTablesImagesUrl: (key, url, width, height) =>
    set(state => ({
      tablesImagesUrl: {
        ...state.tablesImagesUrl,
        [key]: { url, width, height },
      },
    })),
  setGeneralDataImageUrls: (key, url, width, height) =>
    set(state => ({
      generalDataImageUrls: {
        ...state.generalDataImageUrls,
        [key]: { url, width, height },
      },
    })),
  setNumbersWillRender: numbersWillRender => set({ numbersWillRender }),
}));

export { useDashboardStore };
