import { create } from 'zustand';

import { DEFAULT_PAGINATION } from 'utils/helper';

import {
  DEFAULT_OPERATIONS_FILTER,
  IOperationFilterOption,
  IOperationResponse,
  IOperationsFilter,
  OperationType,
} from '../services';

type State = {
  search: string;

  page: number;
  perPage: number;
  limit: boolean;

  openFilterDialog: boolean;
  searchFilterOption: string;
  currentFilter: IOperationsFilter[];
  filterBy: IOperationFilterOption;

  operationsQtd: number;
  selectedOperations: IOperationResponse[];

  openCreateActDialog: boolean;
  openChangeStatsDialog: boolean;

  openConfirmationDialog: boolean;
  operationActionType: OperationType;
};

interface Actions {
  setSearch: (searchTerm: string) => void;

  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;

  setOpenFilterDialog: (open: boolean) => void;
  setSearchFilterOption: (option: string) => void;
  setCurrentFilter: (filters: IOperationsFilter[]) => void;
  setFilterBy: (filter: IOperationFilterOption) => void;

  setSelectedOperations: (operations: IOperationResponse[]) => void;
  setOperationsQtd: (qtd: number) => void;

  setOpenCreateActDialog: (open: boolean) => void;
  setOpenChangeStatsDialog: (open: boolean) => void;

  setOpenConfirmationDialog: (open: boolean) => void;
  setOperationActionType: (type: OperationType) => void;

  clearOperationStore: () => void;
}

const initialState: State = {
  search: '',

  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,

  openFilterDialog: false,
  searchFilterOption: '',
  currentFilter: [],
  filterBy: DEFAULT_OPERATIONS_FILTER,

  selectedOperations: [],
  operationsQtd: 0,

  openCreateActDialog: false,
  openChangeStatsDialog: false,

  openConfirmationDialog: false,
  operationActionType: 'createAct',
};

const useOperationStore = create<State & Actions>()(set => ({
  ...initialState,
  setSearch: search => set({ search }),

  setPage: page => set({ page }),
  setPerPage: perPage => ({ perPage }),
  setLimit: limit => set({ limit }),

  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  setSearchFilterOption: searchFilterOption => set({ searchFilterOption }),
  setCurrentFilter: currentFilter => set({ currentFilter }),
  setFilterBy: filterBy => set({ filterBy }),

  setSelectedOperations: selectedOperations => set({ selectedOperations }),
  setOperationsQtd: operationsQtd => set({ operationsQtd }),

  setOpenCreateActDialog: openCreateActDialog => set({ openCreateActDialog }),
  setOpenChangeStatsDialog: openChangeStatsDialog => set({ openChangeStatsDialog }),

  setOpenConfirmationDialog: openConfirmationDialog =>
    set({ openConfirmationDialog }),
  setOperationActionType: operationActionType => set({ operationActionType }),

  clearOperationStore: () => set(initialState),
}));

export { useOperationStore };
