import themes from 'themes';

import { Icon, Typography } from 'components';

import { BoxWrapperAttachment } from './styles';

interface BoxProps {
  customColors: keyof typeof themes.colors;
  colorText: keyof typeof themes.colors;
}

export const NoAttachment = ({ customColors, colorText }: BoxProps) => {
  return (
    <BoxWrapperAttachment customColor={customColors} mt={1} p={1}>
      <Icon color={colorText}> attach_file</Icon>
      <Typography color={colorText}> Nenhum arquivo anexado </Typography>
    </BoxWrapperAttachment>
  );
};
