import { Card, Table, TableCell } from '@mui/material';
import React from 'react';
import themes from 'themes';

import { Box, TableBody, TableContainer, TableRow, Typography } from 'components';

import { useDashboardCreditor } from 'modules/dashboard/hooks';

import { isEven } from 'utils/isEven';

const OperationStatusTable = () => {
  const { data, isLoading, operationsNoData } = useDashboardCreditor();

  const operationsData = data?.operationsData;

  const emptyString = '---';
  const isTotalCell = true;
  const sizeRow = 50;
  const headerRow = 55;
  const heightWindow = window.innerHeight;
  const isThreeSize = operationsData?.some(item => item?.product?.length === 3);

  const widthTable = heightWindow > 900 ? '96%' : '94.9%';
  const heightLine = heightWindow < 900 && isThreeSize ? 72 : sizeRow;
  const isFirstCell = true;

  const sumTotal =
    operationsData?.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.value);
    }, 0) ?? 0;

  const getMoneyValue = (value: number) => {
    if (value === 0) return emptyString;

    return value.toLocaleString('pt-BR', {
      currency: 'BRL',
      style: 'currency',
      minimumFractionDigits: 2,
    });
  };

  const getHead = (title: string) => {
    const isLastCell = title === 'Produto';
    return (
      <TableCell
        align="center"
        style={{
          alignItems: 'center',
          padding: 0,
          paddingTop: 3,
          borderRight: isLastCell ? undefined : `1px solid ${themes.colors.white}`,
          height: headerRow,
          maxHeight: headerRow,
        }}
      >
        <Typography fontWeight={700} color="#FFF">
          {title}
        </Typography>
      </TableCell>
    );
  };

  const getCellValue = (
    firstCell: boolean,
    value: string | string[],
    totalCell: boolean,
    width?: string,
  ) => {
    if (value.length === 0) return <TableCell></TableCell>;
    const values: string[] = [];

    if (Array.isArray(value)) {
      values.push(...value);
    } else {
      values.push(value);
    }

    const valuesLength = values.length;

    return (
      <TableCell
        align="center"
        style={{
          //borderTop: `1px solid ${themes.colors.black}`,
          padding: 0,
          width: width,
          height: heightLine,
          maxHeight: heightLine,
          maxWidth: 200,
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={'100%'}
          padding={0}
          paddingRight={3}
          paddingLeft={3}
          //borderLeft={firstCell ? `1px solid ${themes.colors.black}` : null}
          //borderRight={`1px solid ${themes.colors.black}`}
          //borderBottom={`1px solid ${themes.colors.black}`}
          minHeight={heightLine}
        >
          <Typography
            fontWeight={totalCell ? 700 : 500}
            color={themes.colors.dashboard.table.text.black}
          >
            {values.map((val, index) => (
              <React.Fragment key={val}>
                {val}
                {index + 1 === valuesLength ? '' : ', '}
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      </TableCell>
    );
  };

  return (
    <Card
      style={{
        width: widthTable,
        padding: 25,
        margin: 0,
      }}
    >
      <Typography
        fontWeight={700}
        fontSize={24}
        textAlign="center"
        color={themes.colors.dashboard.table.header.main}
      >
        {'Operações'}
      </Typography>
      <TableContainer sx={{ padding: 0, margin: 0, mt: 2 }}>
        <Table>
          <TableRow
            style={{ borderCollapse: 'collapse' }}
            sx={{ backgroundColor: themes.colors.dashboard.table.header.main }}
            hover={false}
          >
            {getHead('Operação')}
            {getHead('Valor')}
            {getHead('Status')}
            {getHead('Estado')}
            {getHead('Produto')}
          </TableRow>

          <TableBody
            columns={7}
            isLoading={isLoading}
            empty={operationsNoData}
            emptyMessage="Nenhuma informação das operações"
          >
            {operationsData?.map((operation, index) => (
              <TableRow
                hover={false}
                sx={{
                  backgroundColor: isEven(index)
                    ? '#FFF'
                    : `${themes.colors.dashboard.table.body.tertiary}`,
                }}
                key={operation.operationId}
              >
                {getCellValue(
                  isFirstCell,
                  operation.enterprise,
                  !isTotalCell,
                  '22%',
                )}
                {getCellValue(
                  !isFirstCell,
                  getMoneyValue(Number(operation.value)),
                  !isTotalCell,
                  '16%',
                )}
                {getCellValue(!isFirstCell, operation.status, !isTotalCell, '22%')}
                {getCellValue(!isFirstCell, operation.state, !isTotalCell, '8%')}
                {getCellValue(
                  !isFirstCell,
                  operation.product ?? emptyString,
                  !isTotalCell,
                )}
              </TableRow>
            ))}
            <TableRow
              hover={false}
              sx={{
                backgroundColor: isEven(operationsData?.length ?? 0)
                  ? '#FFF'
                  : `${themes.colors.dashboard.table.body.tertiary}`,
              }}
            >
              {getCellValue(isFirstCell, 'TOTAL', isTotalCell)}
              {getCellValue(!isFirstCell, getMoneyValue(sumTotal), isTotalCell)}
              {getCellValue(!isFirstCell, emptyString, !isTotalCell)}
              {getCellValue(!isFirstCell, emptyString, !isTotalCell)}
              {getCellValue(!isFirstCell, emptyString, !isTotalCell)}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export { OperationStatusTable };
