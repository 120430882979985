export const validateCPF = (cpf: string) => {
  // Remove caracteres não numéricos
  cpf = cpf.replace(/\D/g, '');

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf[i]) * (10 - i);
  }
  let firstDigit = 11 - (sum % 11);
  if (firstDigit === 10 || firstDigit === 11) {
    firstDigit = 0;
  }

  // Calcula o segundo dígito verificador
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf[i]) * (11 - i);
  }
  let secondDigit = 11 - (sum % 11);
  if (secondDigit === 10 || secondDigit === 11) {
    secondDigit = 0;
  }

  // Verifica se os dígitos verificadores são iguais aos fornecidos
  if (parseInt(cpf[9]) === firstDigit && parseInt(cpf[10]) === secondDigit) {
    return true;
  } else {
    return false;
  }
};
