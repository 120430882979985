import styled from 'styled-components';

import { Box } from 'components';

const FooterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.space[5]}px;
`;

export { FooterWrapper };
