import styled from 'styled-components';

import { Box, Typography } from 'components';

const FooterWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space[4]}px;
`;

const FooterContent = styled(Typography)`
  font-size: ${({ theme }) => theme.fontSizes.s}px;
  color: ${({ theme }) => theme.colors.navy};
`;

export { FooterWrapper, FooterContent };
