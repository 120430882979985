import { Box, Grid, Typography } from '@mui/material';

interface Props {
  title: string;
  value?: string | null;
}

export const BigDataItem = ({ title, value }: Props) => {
  if (!value) return null;

  return (
    <Grid item xs={12} lg={6}>
      <Box display="flex" gap={2}>
        <Typography fontWeight={700}>{title}: </Typography>
        <Typography>{value}</Typography>
      </Box>
    </Grid>
  );
};
