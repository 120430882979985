import styled from 'styled-components';

import Box from 'components/Box';

const RootWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding-top: ${({ theme }) => theme.space[6]}px;
  padding-left: ${({ theme }) => theme.space[5]}px;
  padding-right: ${({ theme }) => theme.space[5]}px;
  max-height: calc(100% - 56px);

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: ${({ theme }) => theme.space[8]}px;
    padding-left: ${({ theme }) => theme.space[7]}px;
    padding-right: ${({ theme }) => theme.space[7]}px;
    max-height: calc(100% - 64px);
  }
`;

export { RootWrapper };
