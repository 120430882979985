import { ReactNode } from 'react';

import { ContentWrapper } from './styles';

interface CotentProps {
  children: ReactNode;
}

export function Content({ children }: CotentProps) {
  return <ContentWrapper>{children}</ContentWrapper>;
}
