import { Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { Balance } from 'modules/dashboard/services';
import { useDashboardStore } from 'modules/dashboard/store/store';

import { increaseDistanceLegendPlugin } from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface DREChartProps {
  demonstrations: Balance | undefined | null;
}

const DreChart = ({ demonstrations }: DREChartProps) => {
  const { setChartsImagesUrl } = useDashboardStore();
  const [imagesSet, setImagesSet] = useState(false);

  const heightWindow = window.innerHeight;
  const widthChart = heightWindow > 900 ? '96.8%' : '95.5%';

  return (
    <Card
      elevation={2}
      style={{ width: widthChart, padding: 16, paddingRight: 25, paddingLeft: 25 }}
    >
      <Chart
        type="bar"
        data={{
          labels: demonstrations?.dates ?? [],
          datasets: [
            {
              label: 'Receita Operacional Bruta',
              data:
                demonstrations?.data?.find(
                  dre => dre.name === 'RECEITA OPERACIONAL BRUTA',
                )?.values ?? [],
              backgroundColor: 'rgb(2, 97, 173)',
            },
            {
              label: 'EBITDA',
              data:
                demonstrations?.data?.find(dre => dre.name === 'EBITDA')?.values ??
                [],
              backgroundColor: 'rgb(81, 81, 81)',
            },
            {
              label: 'Resultado Líquido do Exercício',
              data:
                demonstrations?.data?.find(
                  dre => dre.name === 'RESULTADO LÍQUIDO DO EXERCÍCIO',
                )?.values ?? [],
              backgroundColor: 'rgb(153, 153, 153)',
            },
          ],
        }}
        plugins={[increaseDistanceLegendPlugin(20)]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: themes.colors.dashboard.table.text.black,
              },
            },
            title: {
              display: true,
              text:
                'Receita Operacional Bruta, EBITDA e Resultado Líquido do Exercício',
              font: {
                size: 24,
              },
              color: themes.colors.dashboard.table.text.black,
              align: 'start',
            },
          },
          animation: {
            onComplete: animation => {
              if (!imagesSet) {
                setChartsImagesUrl(
                  'dre',
                  animation.chart.toBase64Image(),
                  animation.chart.width,
                  animation.chart.height,
                );

                setImagesSet(true);
              }
            },
          },
        }}
        width={100} // Definindo a largura diretamente no Chart.js
        height={550}
      />
    </Card>
  );
};

export { DreChart };
