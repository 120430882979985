import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useForgotPasswordStore } from '../store/store';
import { useForgotPasswordForm } from './useForgotPasswordForm';
import { useForgotPasswordRequest } from './useForgotPasswordRequest';

export const useForgotPassword = () => {
  const form = useForgotPasswordForm();
  const history = useHistory();
  const {
    clearForgotPasswordStore,
    forgotPasswordPayload,
    setForgotPasswordPayload,
  } = useForgotPasswordStore();

  const request = useForgotPasswordRequest({
    onSuccess: () => {
      toast.success('Senha atualizada com sucesso!');

      clearForgotPasswordStore();

      history.replace('/');
    },
    onError: () => {
      toast.error('Houve um erro ao tentar atualizar a senha');
    },
  });

  const handleSubmitForm = form.handleSubmit(data => {
    request.mutate({
      ...forgotPasswordPayload,
      password: data.password,
      confirmPassword: data.confirmPassword,
    });

    setForgotPasswordPayload({
      ...forgotPasswordPayload,
      password: data.password,
      confirmPassword: data.confirmPassword,
    });
  });

  return {
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseForgotPassword = ReturnType<typeof useForgotPassword>;
