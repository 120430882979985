import { Button } from '@mui/material';
import themes from 'themes';

import { Box, Logo } from 'components';

import SignInOptions from '../components/SignInOptions';
import { useSignIn } from '../hooks';

export function SignInPage() {
  const { handleConsultantAccess } = useSignIn();

  return (
    <Box flex={1} display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100vw"
        marginTop={6}
      >
        <Box minWidth={235}></Box>
        <Box>
          <Logo />
        </Box>
        <Box
          minWidth={235}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            disableElevation
            variant="contained"
            size="small"
            onClick={handleConsultantAccess}
            sx={{
              backgroundColor: '#f5f5f5',
              color: '#000',
              borderRadius: '8px',
              textTransform: 'none',
              fontFamily: themes.fonts.body,

              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            Consultor
          </Button>
        </Box>
      </Box>
      <SignInOptions />
    </Box>
  );
}
