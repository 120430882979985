import { Box } from '@mui/material';

import { ButtonContained, ButtonOutlined } from 'components';
import { QuestionType } from 'models';

import { useFormAnswer, useHandleAnswers } from '../hooks';
import { EFormAnswerType } from '../services';

const FormActions = () => {
  const {
    type,
    currentQuestion,
    questionAnswerAttachment,
    isLoadingHandleAnswer,
    setOpenModalCancel,
    setOpenModalFinishLater,
    handleBack,
    handleNext,
    handleSave,
    handleCancelForm,
  } = useFormAnswer();
  const { currentAnswer, checkIsLast } = useHandleAnswers();

  const isLastQuestion = checkIsLast(currentQuestion?.conditions);
  const isAnsweredText = currentAnswer?.length > 0;
  const isAnsweredAttachment = questionAnswerAttachment.length > 0;

  const isAnswered =
    QuestionType[currentQuestion?.question?.type] === QuestionType.UPLOAD
      ? isAnsweredAttachment
      : isAnsweredText;

  return (
    <Box display="flex" alignItems="center" justifyContent="end" gap={2}>
      <ButtonOutlined
        label="Cancelar"
        color="black"
        onClick={() =>
          type === EFormAnswerType.answer
            ? setOpenModalCancel(true)
            : handleCancelForm()
        }
      />

      {type === EFormAnswerType.answer && (
        <ButtonContained
          label="Finalizar em Outro Momento"
          onClick={() => setOpenModalFinishLater(true)}
        />
      )}

      {currentQuestion?.formQuestion !== null && (
        <ButtonContained
          label="Anterior"
          color="navy"
          onClick={() => handleBack()}
        />
      )}

      {!isLastQuestion && (
        <ButtonContained
          label="Próximo"
          color="navy"
          disabled={!isAnswered}
          onClick={() => handleNext()}
        />
      )}

      {isLastQuestion && (
        <ButtonContained
          label="Salvar"
          color="navy"
          disabled={!isAnswered || isLoadingHandleAnswer}
          loading={isLoadingHandleAnswer}
          onClick={() => handleSave()}
        />
      )}
    </Box>
  );
};

export { FormActions };
