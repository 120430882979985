import themes from 'themes';

import {
  ClientDestineStatus,
  IEnterprise,
  Indebtedness,
  KleverFiscalNotesAnalysis,
  ProductClient,
} from 'models';

import { DataTableAbcCurveItem, TypeCurveItem } from '../components';

export interface VetoesAndAlerts {
  vetoesAndAlerts: number | null;
  kScale: number | null;
}

export interface BalanceData {
  name: string;
  values: (string | number)[];
}

export interface Balance {
  data: BalanceData[];
  maxColumns: number;
  dates: string[];
}

export interface BillingMonthly {
  approved: boolean;
  date: string;
  nfValue: number;
  nfValueLastModifiedDate: string;
  ocrValue: number;
  ocrValueLastModifiedDate: string;
}

export interface DashboardResponse {
  enterprise: IEnterprise;
  vetoesAndAlertsData: VetoesAndAlerts;
  actives: Balance | null;
  passives: Balance | null;
  demonstrations: Balance | null;
  indexesAndIndicators: Balance | null;
  billingMonthly: BillingMonthly[];
  debts: Indebtedness[];
  fiscalNotes: KleverFiscalNotesAnalysis;
}

interface CreditorDataDashboard {
  id: string;
  operationId: string;
  enterprise: string;
  status: ClientDestineStatus;
  product: ProductClient[];
  state: string;
  value?: number;
}

export interface DashboardCreditorsResponse {
  enterprise: IEnterprise;
  operationsData: CreditorDataDashboard[];
}

export interface MapHighlight {
  name: string;
  color: string;
}

export interface TitleKey {
  title: string;
  key: string;
}

export const assetsHighlight: MapHighlight[] = [
  {
    name: 'ATIVO TOTAL',
    color: themes.colors.dashboard.table.header.secondary,
  },
  {
    name: 'ATIVO CIRCULANTE',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Disponibilidades',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Clientes',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Estoques',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Outros Créditos',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'REALIZÁVEL LONGO PRAZO',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Investimentos',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Imobilizado',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Intangível',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Caixa e Bancos',
    color: themes.colors.white,
  },
  {
    name: 'Aplicações Financeiras',
    color: themes.colors.white,
  },
  {
    name: 'Impostos a Recuperar CP',
    color: themes.colors.white,
  },
  {
    name: 'Adiantamentos a Fornecedores',
    color: themes.colors.white,
  },
  {
    name: 'Outros Créditos CP',
    color: themes.colors.white,
  },
  {
    name: 'Partes Relacionadas LP',
    color: themes.colors.white,
  },
  {
    name: 'IR Diferido de Longo Prazo',
    color: themes.colors.white,
  },
  {
    name: 'Impostos a Recuperar LP',
    color: themes.colors.white,
  },
  {
    name: 'Outros Créditos LP',
    color: themes.colors.white,
  },
  {
    name: 'Investimentos',
    color: themes.colors.white,
  },
  {
    name: 'Imobilizado',
    color: themes.colors.white,
  },
  {
    name: 'Intangível',
    color: themes.colors.white,
  },
];

export const passivesHighlight: MapHighlight[] = [
  {
    name: 'PASSIVO TOTAL',
    color: themes.colors.dashboard.table.header.secondary,
  },
  {
    name: 'PASSIVO CIRCULANTE',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Obrigações Financeiras CP',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Obrigações Operacionais CP',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Passivo não circulante',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Empréstimos e Financiamentos LP',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Tributos e Impostos LP',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'Outras Obrigações LP',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'PATRIMÔNIO LÍQUIDO',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Duplicatas Descontadas',
    color: themes.colors.white,
  },
  {
    name: 'Empréstimos e Financiamentos CP',
    color: themes.colors.white,
  },
  {
    name: 'Fornecedores CP',
    color: themes.colors.white,
  },
  {
    name: 'Obrigações sociais, trabalhistas e tributárias',
    color: themes.colors.white,
  },
  {
    name: 'Outras Obrigações Operacionais',
    color: themes.colors.white,
  },
  {
    name: 'Capital Subscrito',
    color: themes.colors.white,
  },
  {
    name: 'Prejuízos Acumulados',
    color: themes.colors.white,
  },
  {
    name: 'Resultado do Exercício',
    color: themes.colors.white,
  },
];

export const dreHighlight: MapHighlight[] = [
  {
    name: 'DRE',
    color: themes.colors.dashboard.table.header.secondary,
  },
  {
    name: 'RECEITA OPERACIONAL BRUTA',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'RECEITA OPERACIONAL LÍQUIDA',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'RESULTADO OPERACIONAL BRUTO',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Despesas Operacionais',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'EBITDA',
    color: themes.colors.dashboard.table.header.secondary,
  },
  {
    name: 'Resultado Financeiro',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'RESULTADO OPERACIONAL',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Resultado Não Operacional',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'LUCRO ANTES DO IR',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Impostos',
    color: themes.colors.dashboard.table.body.tertiary,
  },
  {
    name: 'RESULTADO LÍQUIDO DO EXERCÍCIO',
    color: themes.colors.dashboard.table.header.secondary,
  },
  {
    name: 'Deduções da Receita',
    color: themes.colors.white,
  },
  {
    name: 'Custos Produtos Vendidos',
    color: themes.colors.white,
  },
  {
    name: 'Despesas Comerciais e Administrativas',
    color: themes.colors.white,
  },
  {
    name: 'Despesas de Depreciação / Amortização',
    color: themes.colors.white,
  },
  {
    name: 'Receitas Financeiras',
    color: themes.colors.white,
  },
  {
    name: 'Despesas Financeiras',
    color: themes.colors.white,
  },
  {
    name: 'Variações Monetárias e Cambiais',
    color: themes.colors.white,
  },
  {
    name: 'Receitas Não Operacionais',
    color: themes.colors.white,
  },
  {
    name: 'Despesas Não Operacionais',
    color: themes.colors.white,
  },
  {
    name: 'Resultado de equivalência patrimonial',
    color: themes.colors.white,
  },
  {
    name: 'Provisões para IR e CSLL',
    color: themes.colors.white,
  },
  {
    name: 'Provisão para IR e CSLL - diferido',
    color: themes.colors.white,
  },
  {
    name: 'Reversão JCP',
    color: themes.colors.white,
  },
  {
    name: 'Participações nos Lucros',
    color: themes.colors.white,
  },
];

export const indexesAndIndicatorsHighlight: MapHighlight[] = [
  {
    name: 'CAPITAL DE GIRO',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'DEMAIS ÍNDICES',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'ÍNDICES DE LIQUIDEZ',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'ESTRUTURA DE CAPITAL',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'RENTABILIDADE',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'CICLO FINANCEIRO',
    color: themes.colors.dashboard.table.body.secondary,
  },
  {
    name: 'Ativo Circulante Cíclico',
    color: themes.colors.white,
  },
  {
    name: 'Passivo Circulante Cíclico',
    color: themes.colors.white,
  },
  {
    name: 'Necessidade de Capital de Giro',
    color: themes.colors.white,
  },
  {
    name: 'Saldo de Tesouraria',
    color: themes.colors.white,
  },
  {
    name: 'Relação Necessidade/Receita Op. Líquida',
    color: themes.colors.white,
  },
  {
    name: 'Saldo em Tesouraria Sobre Vendas',
    color: themes.colors.white,
  },
  {
    name: 'Capital Circulante Líquido',
    color: themes.colors.white,
  },
  {
    name: 'Dívida Líquida',
    color: themes.colors.white,
  },
  {
    name: 'Dívida Líquida/EBITDA',
    color: themes.colors.white,
  },
  {
    name: 'Liquidez Geral',
    color: themes.colors.white,
  },
  {
    name: 'Liquidez Corrente',
    color: themes.colors.white,
  },
  {
    name: 'Liquidez Seca',
    color: themes.colors.white,
  },
  {
    name: 'Solvência Geral',
    color: themes.colors.white,
  },
  {
    name: 'Participação de Capital de Terceiros',
    color: themes.colors.white,
  },
  {
    name: 'Endividamento Geral',
    color: themes.colors.white,
  },
  {
    name: 'Endividamento Oneroso',
    color: themes.colors.white,
  },
  {
    name: 'Composição do Endividamento',
    color: themes.colors.white,
  },
  {
    name: 'Nível de Desconto de Duplicatas',
    color: themes.colors.white,
  },
  {
    name: 'Imobilização do Patrimônio Líquido',
    color: themes.colors.white,
  },
  {
    name: 'Margem Bruta',
    color: themes.colors.white,
  },
  {
    name: 'Margem Líquida',
    color: themes.colors.white,
  },
  {
    name: 'Retorno Sobre Ativo',
    color: themes.colors.white,
  },
  {
    name: 'Retorno Sobre PL',
    color: themes.colors.white,
  },
  {
    name: 'Prazo Médio de Estoques',
    color: themes.colors.white,
  },
  {
    name: 'Prazo Médio de Recebimentos',
    color: themes.colors.white,
  },
  {
    name: 'Prazo Médio de Pagamentos',
    color: themes.colors.white,
  },
  {
    name: 'Ciclo Financeiro',
    color: themes.colors.white,
  },
  {
    name: 'Ciclo do Ativo',
    color: themes.colors.white,
  },
];

export const billingMonthlyTitles: TitleKey[] = [
  { title: 'Mês', key: 'month' },
  { title: 'Faturamento Mensal Ano N-2', key: 'nfValueN2' },
  { title: 'Faturamento mensalmente acumulado N-2', key: 'nfValueN2Acc' },
  { title: 'Faturamento Mensal Ano N-1', key: 'nfValueN1' },
  { title: 'Faturamento mensalmente acumulado N-1', key: 'nfValueN1Acc' },
  { title: 'Faturamento Mensal Ano N', key: 'nfValueN' },
  { title: 'Faturamento mensalmente acumulado N', key: 'nfValueNAcc' },
];

export const monthShortNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

export const debtsTitles: TitleKey[] = [
  { title: 'Ordem da Dívida', key: 'orderDebt' },
  { title: 'Instituição Financeira', key: 'institutionName' },
  { title: 'Saldo devedor atual', key: 'totalSum' },
  { title: 'VCTO até 6 meses', key: 'until180dSum' },
  { title: 'VCTO 6 meses a 12 meses', key: 'between181and360dSum' },
  { title: 'VCTO acima de 12 meses', key: 'greaterThan360dSum' },
];

export interface BalanceExcel {
  data: BalanceData[];
  maxColumns: number;
  dates: string[];
}

export interface AbcCurveData {
  dataProcessed: DataTableAbcCurveItem[];
  dataTypeCurve: TypeCurveItem[];
  totalValueLastExAcc: number;
}

export const abcCurveTitles: TitleKey[] = [
  { title: 'Valor Acumulado do último exercício R$', key: 'valueLastExAcc' },
  {
    title: 'Percentual de participação sobre o valor total',
    key: 'partPercentTotalValue',
  },
  {
    title: 'Percentual acumulado de participação sobre o valor total',
    key: 'accPartPercentTotalValue',
  },
  { title: 'Classificação ABC', key: 'abcClassification' },
];
