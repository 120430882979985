import { Tab, Tabs, Box as MuiBox } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';

import { Box, ButtonContained, Scrollbar } from 'components';
import { Permissions } from 'models/roles/Permission';

import { useAuth } from 'config/auth/hooks';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { ModalSuportExFin } from 'modules/boards/tabs';

import {
  DashboardCreditor,
  DashboardEnterprise,
  DashboardTabPanel,
  DashboardDocumentsCustomers,
  DashboardDocumentsCreditor,
} from '../components';
import { DashboardGeneral } from '../components/DashboardGeneral';
import DashboardPDF from '../components/DashboardPDF';
import { useDashboardActions } from '../hooks';
import { useDashboardStore } from '../store/store';

export const getNumbersWithUrl = urlsObject => {
  let numbersWithUrl = 0;
  for (const key in urlsObject) {
    if (urlsObject[key]?.url?.length > 0) {
      numbersWithUrl++;
    }
  }
  return numbersWithUrl;
};

const DashboardPage = () => {
  const {
    valueTab,
    handleChangeTab,
    getProps,
    handleDownloadExcel,
    handleOpenSupportExFin,
  } = useDashboardActions();
  const { getSpecificPermissionRole } = useValidateRouteAccess();
  const { user } = useAuth();
  const {
    chartsImagesUrl,
    tablesImagesUrl,
    numbersWillRender,
  } = useDashboardStore();

  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);

  useEffect(() => {
    const handler = setTimeout(() => {
      const chartsWithUrl = Number(getNumbersWithUrl(chartsImagesUrl));
      const tablesWithUrl = Number(getNumbersWithUrl(tablesImagesUrl));

      const newIsDownloadEnabled =
        numbersWillRender === chartsWithUrl + tablesWithUrl;

      if (newIsDownloadEnabled !== isDownloadEnabled) {
        setIsDownloadEnabled(newIsDownloadEnabled);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [isDownloadEnabled, chartsImagesUrl, tablesImagesUrl]);

  const isEnterprise = !user?.enterprise?.isFinancing;
  const renderTabDoc = getSpecificPermissionRole(Permissions.ShowDocs);
  const alturaDaJanela = window.innerHeight;
  const highDashboard = alturaDaJanela > 900 ? '77vh' : '72vh';

  return (
    <MuiBox flex={1} display="flex" flexDirection="column" style={{ padding: 0 }}>
      <ModalSuportExFin />
      <Box display="flex" flexDirection="row">
        <Box width={'50%'}>
          <Tabs value={valueTab} onChange={handleChangeTab}>
            <Tab label="Visão Geral" {...getProps(0)} />
            {renderTabDoc && <Tab label="Documentos" {...getProps(1)} />}
          </Tabs>
        </Box>
        <Box
          display="flex"
          justifyContent="end"
          style={{ gap: 32, padding: 0, paddingTop: 10, paddingRight: 10 }}
          marginBottom={8}
          width={'50%'}
        >
          {isEnterprise && (
            <>
              {isDownloadEnabled ? (
                <PDFDownloadLink
                  document={<DashboardPDF />}
                  fileName="material_de_credito.pdf"
                >
                  {({ loading, error }) => (
                    <ButtonContained
                      label="MATERIAL DE CRÉDITO"
                      loading={loading}
                      disabled={loading ?? error}
                    />
                  )}
                </PDFDownloadLink>
              ) : (
                <ButtonContained
                  label="MATERIAL DE CRÉDITO"
                  loading={true}
                  disabled={true}
                />
              )}
              <ButtonContained label="BAIXAR EXCEL" onClick={handleDownloadExcel} />
            </>
          )}
          <ButtonContained label="SUPORTE EXFIN" onClick={handleOpenSupportExFin} />
        </Box>
      </Box>
      <Scrollbar
        display="flex"
        flexDirection="column"
        overflow={'auto'}
        height={highDashboard}
        maxHeight={highDashboard}
      >
        <DashboardGeneral />
        <>
          <DashboardTabPanel index={0}>
            {isEnterprise ? <DashboardEnterprise /> : <DashboardCreditor />}
          </DashboardTabPanel>
          <DashboardTabPanel index={1}>
            {isEnterprise ? (
              <DashboardDocumentsCustomers />
            ) : (
              <DashboardDocumentsCreditor />
            )}
          </DashboardTabPanel>
        </>
      </Scrollbar>
    </MuiBox>
  );
};

export { DashboardPage };
