import { useUserStore } from '../store/store';

export const useFilterDialog = () => {
  const { openFilterDialog, setOpenFilterDialog } = useUserStore();

  const handleOpenFilterDialog = () => {
    setOpenFilterDialog(true);
  };
  const handleCloseFilterDialog = () => setOpenFilterDialog(false);

  return {
    open: openFilterDialog,
    handleOpenFilterDialog,
    handleCloseFilterDialog,
  };
};
