import { stringify } from 'qs';

import { IFilterDatabasePayload } from 'components/FilterDatabase/filterDatabase.types';
import { ICompanyBigData, IPagination } from 'models';

import api from 'config/api';

export class KMethodService {
  static async getKMethodData({
    page,
    perPage,
    search,
    type,
    manager,
    status,
    product,
    active,
  }: IFilterDatabasePayload) {
    const params = stringify({
      page,
      perPage,
      search,
      type,
      manager,
      status,
      product,
      active,
    });

    const { data } = await api.get<
      IPagination<ICompanyBigData> | ICompanyBigData[]
    >(`/enterprise/k-method?${params}`);

    return data;
  }
}
