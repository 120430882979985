import { Box, Step, StepLabel, Stepper } from '@mui/material';

import useFormAnswerStore from '../store/store';

const calculateStepperWidth = (stepCount: number) => {
  if (stepCount <= 2) return '9em';
  if (stepCount === 3) return '18em';
  if (stepCount === 4) return '27em';
  if (stepCount === 5) return '36em';
  if (stepCount === 6) return '45em';
  return '60em';
};

const FormStepper = () => {
  const { formQuestions } = useFormAnswerStore();
  return (
    <Box display="flex" justifyContent="center" alignItems={'center'} mt={2} mb={2}>
      <Stepper
        activeStep={formQuestions.length - 1}
        style={{
          minWidth: calculateStepperWidth(formQuestions.length),
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {formQuestions.map(formQuestion => (
          <Step key={formQuestion.id} completed={false}>
            <StepLabel
              StepIconProps={{ style: { width: '1.2em', height: '1.2em' } }}
            ></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export { FormStepper };
