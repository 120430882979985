import { Box, CircularProgress } from '@mui/material';

import { ButtonContained, Dialog, SectionButtons, Typography } from 'components';

import { useConsultantNotifications } from '../hooks';
import { NotificationItem } from './NotificationItem';

export const ConsultantNotificationsDialog = () => {
  const {
    ref,
    notifications,
    isFetching,
    isLoading,
    isFetchingNextPage,
    openNotificationsDialog,
    handleCloseNotificationsDialog,
  } = useConsultantNotifications();

  return (
    <Dialog.Root
      open={openNotificationsDialog}
      onClose={handleCloseNotificationsDialog}
    >
      <Dialog.Header title={'Notificações'} />

      <Dialog.Content>
        {isLoading ? (
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress size={22} />
          </Box>
        ) : (
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {notifications.length ? (
              notifications.map(notification => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))
            ) : (
              <Typography>Nenhuma notificação encontrada.</Typography>
            )}

            {!isFetching && !isFetchingNextPage && <div ref={ref} />}
          </Box>
        )}
      </Dialog.Content>

      <SectionButtons>
        <ButtonContained
          boxShadow="0 5px 5px rgba(0, 0, 0, 0.5)"
          color="blue"
          label="Fechar"
          onClick={handleCloseNotificationsDialog}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
