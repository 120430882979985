import { toast } from 'react-toastify';

import { ClientDestineStatus } from 'models';

import queryClient from 'config/queryClient';

import { CustomerQueryKey } from '../services';
import { useCustomerManagementStore } from '../store/store';
import { useUpdateStatusRequest } from './useUpdateStatusRequest';

export const useCustomerActions = () => {
  const {
    selectedCustomers,
    customersAmount,
    setOpenChangeStatsDialog,
    setOpenConfirmationDialog,
    openChangeStatsDialog,
    clearCustomerManagementStore,
  } = useCustomerManagementStore();

  const updateCustomerStatusRequest = useUpdateStatusRequest();

  const handleOpenChangeStatsDialog = () => {
    const allSelected = selectedCustomers.length === customersAmount;

    allSelected ? setOpenConfirmationDialog(true) : setOpenChangeStatsDialog(true);
  };

  const handleCloseChangeStatsDialog = () => {
    setOpenChangeStatsDialog(false);
  };

  const handleApplyStatus = async (status: ClientDestineStatus) => {
    updateCustomerStatusRequest.mutate(
      {
        customerIds: selectedCustomers.map(customer => customer.id),
        newStatus: status,
      },
      {
        onSuccess: ({ emptySerasa }) => {
          toast.success('Status alterado com sucesso');

          if (emptySerasa.length) {
            emptySerasa.forEach(cnpj =>
              toast.info(`O cnpj ${cnpj} não possui consulta Serasa disponível.`),
            );
          }

          setOpenChangeStatsDialog(false);
          queryClient.invalidateQueries([CustomerQueryKey.LIST_CUSTOMERS]);
          clearCustomerManagementStore();
        },
        onError: error => {
          if (error.response?.data.title === 'Internal error - Klever') {
            toast.error(error.response?.data.detail);
            return;
          }
          toast.error('Não foi possível alterar o status.');
        },
      },
    );
  };

  return {
    handleOpenChangeStatsDialog,
    handleCloseChangeStatsDialog,
    handleApplyStatus,
    openChangeStatsDialog,
    updateCustomerStatusRequest,
  };
};
