import { Add } from '@mui/icons-material';

import { Icon, ButtonOutlined } from 'components';
import { IUser } from 'models';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useConsultantSearch, useConsultantDialog } from '../hooks';
import { useConsultantsStore } from '../store/store';

export function Header() {
  const { setSearch } = useConsultantsStore();
  const { handleOpenFormDialog } = useConsultantDialog();
  const { handleChangeSearch } = useConsultantSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            icon={<Icon fontSize="h4">people</Icon>}
            title="Consultores"
          />

          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={value => handleChangeSearch(value)}
          />
          <PrivateLayout.Content.Header.Actions>
            <ButtonOutlined
              color="white"
              label={'Adicionar Consultor'}
              icon={<Add />}
              loading={false}
              onClick={() => handleOpenFormDialog({} as IUser)}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
}
