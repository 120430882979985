import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IForgotPasswordEmailPayload } from '../services';

export const forgotPasswordEmailSchema = z.object({
  email: z
    .string()
    .trim()
    .nonempty('E-mail é obrigatório')
    .email('E-mail inválido'),
});

const defaultValues = {
  email: '',
};

export const useForgotPasswordEmailForm = () => {
  return useForm<IForgotPasswordEmailPayload>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(forgotPasswordEmailSchema),
  });
};
