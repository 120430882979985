import {
  IFileStorage,
  IFormQuestionCondition,
  IQuestionAttachmentList,
} from 'models';

import useFormAnswerStore from '../store/store';

export const useHandleAnswers = () => {
  const {
    currentAnswer,
    questionAnswerAttachment,
    setCurrentAnswer,
    setQuestionAnswerAttachments,
  } = useFormAnswerStore();

  function checkIsLast(conditions: IFormQuestionCondition[]) {
    if (!conditions || conditions.length === 0) return true;
    return false;
  }

  const handleSaveAttachments = (files: IFileStorage[]) => {
    const filesAtacchments: IQuestionAttachmentList[] = [];
    files.map(file => {
      filesAtacchments.push({
        file: {
          id: file.id,
          path: file.path,
          directory: file.directory,
          fileName: file.fileName,
          mimeType: file.mimeType,
          size: file.size,
          createdAt: file.createdAt,
          updatedAt: file.updatedAt,
        },
      });
    });

    setQuestionAnswerAttachments([
      ...questionAnswerAttachment,
      ...filesAtacchments,
    ]);
  };

  const handleRemoveAttachment = (id: string) => {
    const newQuestionAttachments = questionAnswerAttachment.filter(
      att => att.file.id !== id,
    );
    setQuestionAnswerAttachments(newQuestionAttachments);
  };

  const getChecked = (option: string) => {
    if (option === currentAnswer) return true;

    return false;
  };

  const handleOnChangeMultipleChoice = (option: string) => {
    if (option === currentAnswer) {
      setCurrentAnswer('');
      return;
    }
    setCurrentAnswer(option);
  };

  const handleChangeInputsQuestion = (answer: string) => {
    if (answer.length > 0) {
      setCurrentAnswer(answer);
    } else if (currentAnswer.length > 0 && answer.length === 0) {
      setCurrentAnswer('');
    }
  };

  return {
    currentAnswer,
    setCurrentAnswer,
    checkIsLast,
    handleSaveAttachments,
    handleRemoveAttachment,
    getChecked,
    handleOnChangeMultipleChoice,
    handleChangeInputsQuestion,
  };
};
