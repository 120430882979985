import { DialogActions } from '@mui/material';
import styled from 'styled-components';

const FooterWrapper = styled(DialogActions)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px ${({ theme }) => theme.space[5]}px
      ${({ theme }) => theme.space[5]}px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: 0px ${({ theme }) => theme.space[7]}px
        ${({ theme }) => theme.space[7]}px;
    }
  }
`;

export { FooterWrapper };
