import { Delete } from '@mui/icons-material';
import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  DialogConfirmInactives,
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

//import { useResetPagination } from 'modules/user/hooks';
import { useFindForms, useFormItem, useDynamicForm } from '../hooks';
import DialogConfirmDuplicate from './DialogConfirmDuplicate';
import { ItemForm } from './ItemForm';

export const ListForms = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useFindForms();
  // useResetPagination();

  const {
    openConfirmDuplicateDialog,
    setOpenConfirmDuplicateDialog,
    duplicateForm,
  } = useDynamicForm();

  const {
    openConfirmInactivesDialog,
    setOpenConfirmInactivesDialog,
    handleUpdateForms,
  } = useFormItem();

  return (
    <>
      <DialogConfirmInactives
        title={
          'Você tem certeza que deseja desativar o(s) formulário(s) selecionado(s)?'
        }
        showDialog={openConfirmInactivesDialog}
        setShowDialog={setOpenConfirmInactivesDialog}
        handleUpdate={handleUpdateForms}
      />

      <DialogConfirmDuplicate
        title={'Duplicar Formulário'}
        infoCard={'Você tem certeza que deseja duplicar o Formulário selecionado?'}
        showDialog={openConfirmDuplicateDialog}
        setShowDialog={setOpenConfirmDuplicateDialog}
        handleDuplicateForm={duplicateForm}
      />
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Nome
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Descrição
                </Typography>
              </TableCell>

              <TableCell align="center">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Criação
                </Typography>
              </TableCell>

              <TableCell align="center" width="60px">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Ativo/Inativo
                </Typography>
              </TableCell>

              <TableCell align="center" width="20px" padding="checkbox">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Duplicar
                </Typography>
              </TableCell>

              <TableCell align="center" width="20px" padding="checkbox">
                <Typography
                  style={{
                    fontSize: `${themes.fontSizes.s}px`,
                  }}
                  variant="subtitle3"
                >
                  Editar
                </Typography>
              </TableCell>

              <TableCell
                padding="checkbox"
                width="20px"
                align="center"
                onClick={() => setOpenConfirmInactivesDialog(true)}
              >
                <Delete color="action" />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            columns={9}
            isLoading={isLoading}
            empty={noData}
            emptyMessage="Nenhum formulário encontrado"
          >
            {data?.map(form => (
              <ItemForm key={form.id} {...form} />
            ))}
          </TableBody>
        </Table>

        {showPagination && (
          <Pagination step={page} onSetStep={setPage} limit={limit} />
        )}
      </TableContainer>
    </>
  );
};
