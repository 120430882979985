import { TextField } from '@mui/material';
import {
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: FieldError;
  label: string;
};

export function CurrencyField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({ error, name, label, control, ...rest }: Props<TFieldValues, TName>) {
  const {
    field: { onChange, value, ref, ...props },
  } = useController({ control, name });

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <NumericFormat
          id={field.name}
          label={label}
          fullWidth
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          allowNegative={false}
          onChange={onChange}
          value={value}
          getInputRef={ref}
          error={Boolean(!!error)}
          helperText={error && <>{error.message}</>}
          customInput={TextField}
          decimalSeparator={','}
          thousandSeparator={'.'}
          {...rest}
          {...props}
        />
      )}
    />
  );
}
