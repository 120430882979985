import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;
  border: 1px dashed ${props => props.theme.colors['gray-100']};
  border-radius: ${({ theme }) => theme.space[2]}px;
  background-color: ${props => props.theme.colors['gray-50']};
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;

  :hover {
    border-color: ${props => props.theme.colors['gray-200']};
  }
`;

export const DropzoneInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space[4]}px;
  padding-bottom: ${({ theme }) => theme.space[9]}px;
`;
