import { IQuestion } from 'models';
import { IPagination } from 'models/pagination';

import api from 'config/api';

import {
  IFilterQuestionParams,
  IQuestionPayload,
  IUpdateQuestionRequest,
} from './types';

export class QuestionService {
  static async list(params?: Partial<IFilterQuestionParams>) {
    const { data } = await api.get<IPagination<IQuestion> | IQuestion[]>(
      `/form/questions`,
      { params },
    );
    return data;
  }

  static async listAll() {
    const { data } = await api.get<IQuestion[]>(`/form/questions/list-all`);
    return data;
  }

  static async create(question: IQuestionPayload) {
    const { data } = await api.post<IQuestion>(`/form/questions/`, question);
    return data;
  }

  static async update({ id, question }: IUpdateQuestionRequest) {
    const { data } = await api.put<IQuestion>(`/form/questions/${id}`, question);
    return data;
  }
}
