import { Box } from '@mui/material';
import styled from 'styled-components';
import themes from 'themes';

interface BoxProps {
  customColor: keyof typeof themes.colors;
}

const BoxWrapperAttachment = styled(Box)<BoxProps>`
  && {
    display: flex;
    color: ${props => props.theme.colors[props.customColor]};
    background-color: ${props => props.theme.colors[props.customColor]};
  }
`;

export { BoxWrapperAttachment };
