import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Box, ButtonContained, Icon, Typography } from 'components';
import { useDownloadFile } from 'hooks';
import { IQuestionAttachmentList } from 'models';

interface QuestionAttachemntsProps {
  file: IQuestionAttachmentList;
}

export const QuestionAttachments = ({ file }: QuestionAttachemntsProps) => {
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { downloadFile, getUrlImage } = useDownloadFile(file.file);
  const mimeType = file.file.mimeType;
  const validImg = mimeType === 'image/png' || mimeType === 'image/jpeg';
  const validPdf = mimeType === 'application/pdf';

  useEffect(() => {
    async function fetchImageURL() {
      setIsLoading(true);
      try {
        const fetchedUrl = await getUrlImage();
        setUrl(fetchedUrl);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        toast.error('Erro ao pegar url da imagem');
      }
    }

    fetchImageURL();
  }, []);

  return (
    <Box display="flex" p={2} mt={3} width={'100%'} justifyContent={'center'}>
      {!!validPdf && (
        <>
          <Icon color={'gray-300'}> insert_drive_file</Icon>
          <Box marginLeft={5} marginRight={8}>
            <Typography style={{ padding: 2 }}> {file?.file?.fileName} </Typography>
          </Box>

          <ButtonContained label="Baixar" onClick={() => downloadFile()} />
        </>
      )}

      {!!validImg && !isLoading && (
        <img
          style={{ maxHeight: 800, maxWidth: 300 }}
          src={url}
          alt={'Imagem anexada na questão'}
        />
      )}
    </Box>
  );
};
