import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { SignUpEnterpriseService, ICheckCNPJPayload } from '../services';

const useCheckExistCnpjRequest = (
  options?: UseMutationOptions<boolean, IApiAxiosError, ICheckCNPJPayload>,
) => {
  return useMutation(
    'checkExistCnpj',
    SignUpEnterpriseService.checkExistCnpj,
    options,
  );
};

export { useCheckExistCnpjRequest };
