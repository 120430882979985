import { useState } from 'react';

import { IUserAuth } from 'modules/signIn/services';

export const useUser = () => {
  const [user, setUser] = useState<IUserAuth | undefined>(undefined);

  const addUser = (user: IUserAuth) => {
    setUser(user);
  };

  const removeUser = () => {
    setUser(undefined);
  };

  const updateLoggedBranch = (branchId: string) => {
    setUser(prev => (prev ? { ...prev, loggedBranch: branchId } : undefined));
  };

  return { user, addUser, removeUser, updateLoggedBranch };
};
