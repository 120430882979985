import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { UserPositions, UserTypes } from 'models';
import { IValidateParams } from 'models';

import { validateCPF } from 'utils/validateCPF';
import { validateDate } from 'utils/validateDate';

import { IUserEdit, IUserValidateParams, UserService } from '../services';
import { useUserStore } from '../store/store';

export const useUserDialogForm = () => {
  const { currentUser } = useUserStore();

  const validate = async (
    value: string,
    key: keyof IUserValidateParams,
  ): Promise<boolean> => {
    const params: IValidateParams<IUserValidateParams> = {
      value: value,
      key: key,
    };

    if (currentUser) {
      params['id'] = currentUser?.id;
    }

    return await UserService.validate(params);
  };

  const userSchema = z.object({
    name: z.string().trim().nonempty('Nome é obrigatório'),
    surname: z.string().trim().nonempty('Sobrenome é obrigatório'),
    cpf: z
      .string()
      .trim()
      .nonempty('CPF é obrigatório')
      .refine(cpf => {
        return validateCPF(cpf);
      }, 'CPF inválido'),
    birthDate: z
      .string()
      .trim()
      .nonempty('Data de nascimento é obrigatória')
      .refine(birthDate => {
        return validateDate(birthDate);
      }, 'Data inválida'),
    email: z
      .string()
      .trim()
      .nonempty('E-mail é obrigatório')
      .email('E-mail inválido')
      .refine(async value => await validate(value, 'email'), 'E-mail já utilizado'),
    position: z.string().trim().nonempty('Selecione um cargo'),
    userType: z.string().trim().nonempty('Tipo de usuário é obrigatório'),
    role: z.string().trim().nonempty('Selecione uma Permissão'),
    linkedin: z.string().trim().optional(),
    active: z.boolean(),
  });

  const defaultValues = {
    email: '',
    name: '',
    surname: '',
    cpf: '',
    birthDate: '',
    position: {} as UserPositions,
    userType: {} as UserTypes,
    role: '',
    linkedin: '',
    active: true,
  };

  return useForm<IUserEdit>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(userSchema),
  });
};
