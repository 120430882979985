import { BrowserRouter, Switch } from 'react-router-dom';

import { useGeneratePrivateLayoutRoutes } from 'layouts/PrivateLayout/hooks';
import { useGeneratePublicLayoutRoutes } from 'layouts/PublicLayout/hooks';

function Router() {
  const { publicLayoutRoutes } = useGeneratePublicLayoutRoutes();
  const { privateLayoutRoutes } = useGeneratePrivateLayoutRoutes();

  return (
    <BrowserRouter>
      <Switch>
        {publicLayoutRoutes}
        {privateLayoutRoutes}
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
