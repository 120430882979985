import {
  DynamicFormStorageContainer,
  DynamicFormStorageDialogFilter,
  DynamicFormStorageTable,
} from '../components';

const DynamicFormStoragePage = () => {
  return (
    <>
      <DynamicFormStorageDialogFilter />
      <DynamicFormStorageContainer />
      <DynamicFormStorageTable />
    </>
  );
};

export { DynamicFormStoragePage };
