import { useLocation } from 'react-router-dom';

import { DynamicForm, HeaderDynamicForm } from '../components';
import { useDynamicForm, useEditDynamicForm } from '../hooks';

const DynamicFormPage = () => {
  const location = useLocation();
  const queryParams = location.search;
  const isEdit = queryParams.replace('?edit=', '');

  const { form } = useDynamicForm();
  const { editForm } = useEditDynamicForm();

  return (
    <>
      <HeaderDynamicForm />
      <DynamicForm form={isEdit ? editForm : form} editForm={isEdit === 'true'} />
    </>
  );
};

export { DynamicFormPage };
