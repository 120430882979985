import { Grid, Typography } from '@mui/material';

import { KleverPerson } from 'models';

import { BigDataAddress } from './BigDataAddress';
import { BigDataItem } from './BigDataItem';

interface Props {
  personPartners: KleverPerson[];
}

export const BigDataPersonPartners = ({ personPartners }: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Sócio Pessoa
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {personPartners?.map(personPartner => (
          <Grid item xs={12} lg={6} key={personPartner.id}>
            <BigDataItem title="CPF" value={personPartner.cpf} />
            <BigDataItem title="Nome" value={personPartner.name} />
            <BigDataItem title="Gênero" value={personPartner.sex} />
            <BigDataItem title="E-mail" value={personPartner.email} />
            <BigDataItem title="CNS" value={personPartner.cns} />
            <BigDataItem title="NIS" value={personPartner.nis} />
            <BigDataItem title="CPF da mãe" value={personPartner.motherCpf} />
            <BigDataItem title="Nome da mãe" value={personPartner.motherName} />
            <BigDataItem title="Nome do pai" value={personPartner.fatherName} />
            <BigDataItem
              title="País de origem"
              value={personPartner.originCountry}
            />
            <BigDataItem
              title="Inscrição social"
              value={personPartner.socialInscription}
            />
            <BigDataItem
              title="Valor do débito PGFN/DAU"
              value={personPartner.debitPgfnDauValue}
            />
            <BigDataItem
              title="Situação do CPF"
              value={personPartner.situationCpf}
            />
            <BigDataItem
              title="Nível de educação"
              value={personPartner.educationLevel}
            />
            <BigDataItem
              title="Tipo de deficiência"
              value={personPartner.deficiencyType}
            />
            <BigDataItem
              title="Código Neoway da profissão"
              value={personPartner.professionNeoway}
            />
            <BigDataItem
              title="Código CBO da profissão"
              value={personPartner.professionCbo}
            />
            <BigDataItem title="Situação geral" value={personPartner.situation} />
            <BigDataItem
              title="Situação do Registro"
              value={personPartner.registerSituation}
            />
            <BigDataItem
              title="Data de inscrição do CPF"
              value={personPartner.inscriptionCpfDate}
            />
            <BigDataItem
              title="Quantidade única de sócios"
              value={personPartner.quantityQsaUnique?.toString()}
            />
            <BigDataItem title="Idade" value={personPartner.age?.toString()} />
            <BigDataItem
              title="Data de Falecimento"
              value={personPartner.deadDate?.toString()}
            />
            <BigDataItem
              title="Deficiencia"
              value={personPartner.deficiency ? 'Sim' : 'Não'}
            />
            <BigDataItem
              title="Falecido"
              value={personPartner.dead ? 'Sim' : 'Não'}
            />
            <BigDataItem
              title="Confirmação de falecimento"
              value={personPartner.deadConfirmation ? 'Sim' : 'Não'}
            />
            <BigDataItem
              title="Agente Público"
              value={personPartner.publicAgent ? 'Sim' : 'Não'}
            />
            <BigDataItem
              title="Data de nascimento"
              value={
                personPartner.birthDate &&
                new Date(personPartner.birthDate).toLocaleDateString('pt-BR')
              }
            />
            <BigDataItem
              title="Data e hora da última consulta de protesto"
              value={
                personPartner.protestLastConsultDate &&
                new Date(personPartner.protestLastConsultDate).toLocaleDateString(
                  'pt-BR',
                )
              }
            />
            <BigDataAddress address={personPartner.mainAddress} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
