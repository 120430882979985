import {
  Box,
  ButtonContained,
  ButtonOutlined,
  Dialog,
  SectionButtons,
  Typography,
} from 'components';

import { useOperationConfirm } from '../hooks';

export const OperationConfirmationDialog = () => {
  const {
    numberOfSelectedOperations,
    handleCloseConfirmationDialog,
    openConfirmationDialog,
    handleConfirmOperations,
    handleCancelOperations,
  } = useOperationConfirm();
  return (
    <Dialog.Root
      open={openConfirmationDialog}
      onClose={handleCloseConfirmationDialog}
    >
      <Dialog.Header title={'Confirmar Operações?'} />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          style={{ gap: '2em' }}
          padding={4}
        >
          <Typography>{`Você selecionou ${numberOfSelectedOperations} itens. Deseja seguir para todos?`}</Typography>
        </Box>
      </Dialog.Content>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCancelOperations} />
        <ButtonContained label="Confirmar" onClick={handleConfirmOperations} />
      </SectionButtons>
    </Dialog.Root>
  );
};
