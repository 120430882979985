import { Grid, Typography } from '@mui/material';

import { KleverJudicialProcess } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  judicialProcess: KleverJudicialProcess;
}

export const BigDataJudicialProcess = ({ judicialProcess }: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Processos Judiciais
        </Typography>
      </Grid>
      <BigDataItem
        title="Valor total de outros processos judiciais"
        value={judicialProcess?.totalOthersValue?.toLocaleString('pt-BR', {
          currency: 'BRL',
          minimumFractionDigits: 2,
          style: 'currency',
        })}
      />
      <BigDataItem
        title="Valor total de processos judiciais ativos"
        value={judicialProcess?.totalActiveValue?.toLocaleString('pt-BR', {
          currency: 'BRL',
          minimumFractionDigits: 2,
          style: 'currency',
        })}
      />
      <BigDataItem
        title="Valor total de processos judiciais como parte passiva"
        value={judicialProcess?.totalPassiveValue?.toLocaleString('pt-BR', {
          currency: 'BRL',
          minimumFractionDigits: 2,
          style: 'currency',
        })}
      />
      <BigDataItem
        title="Valor total de todos os processos judiciais"
        value={judicialProcess?.totalValue?.toLocaleString('pt-BR', {
          currency: 'BRL',
          minimumFractionDigits: 2,
          style: 'currency',
        })}
      />
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={18}>
          Quantidade de Processos Judiciais
        </Typography>

        <Grid container spacing={2}>
          {judicialProcess?.judicialProcessQuantities?.map(
            (judicialProcess, index) => (
              <Grid item xs={12} lg={6} key={index}>
                <BigDataItem title="Tipo" value={judicialProcess.type} />
                <BigDataItem
                  title="Quantidade Total"
                  value={judicialProcess.quantityTotal?.toString()}
                />
                <BigDataItem
                  title="Quantidade Ativa"
                  value={judicialProcess.quantityActive?.toString()}
                />
                <BigDataItem
                  title="Quantidade Outros"
                  value={judicialProcess.quantityOthers?.toString()}
                />
                <BigDataItem
                  title="Quantidade como parte ativa"
                  value={judicialProcess.quantityActivePart?.toString()}
                />
                <BigDataItem
                  title="Quantidade como parte passiva"
                  value={judicialProcess.quantityPassivePart?.toString()}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
