export interface IAct {
  id?: string;
  active: boolean;
  name: string;
  description: string;
  type: string;
  clientViewAndAnswer: string[];
  clientEmit: string[];
  userViewAndAnswer: string[];
  userEmit: string[];
  configs: IActConfig;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface IActConfig {
  id?: string;
  triggerForm: boolean;
  formId?: string | null;
  showInfo: boolean;
  info?: string | null;
  showDbInfo: boolean;
  clientTypeInfo?: string | null;
  dbInfo?: string[] | null;

  textInputAnswer: boolean;
  actId?: string;

  defineProductClient: boolean;
  productClient?: string[] | null;

  createOp: boolean;
  createOpValues?: string[];
  defineOp: boolean;
  operation?: string | null;
  defineValueOp: boolean;
  valueOp?: string;

  showScheduleLink: boolean;
  scheduleLink?: string | null;

  showDeadline: boolean;
  deadline?: string | null;
  notifyBefore?: string[];
  notifyAfter?: string[];

  newStatusClient?: ClientDestineStatus | null;
  newStatusOp?: OperationDestineStatus | null;

  allowCharge: boolean;
  chargeLink?: string;
  triggerDocSign: boolean;
  allowAttach: boolean;
  allowAnswerAttach: boolean;
  sendEmail: boolean;
  emailTitle?: string | null;
  emailBody?: string | null;
  negotiationEnd: boolean;
  motiveEnd?: string;
  closureAct: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum UserTypeHandleAct {
  'ADMIN' = 'Administrador',
  'COLABORATOR' = 'Colaborador',
}

export enum ActsTypes {
  'FORMULARIO' = 'Formulário',
  'COMUNICACAO' = 'Comunicação',
  'RESPOSTA' = 'Resposta',
  'SOLICITACAO_INFORMACAO' = 'Solicitação de Informação',
  'ENVIO_INFORMACOES_EXFIN' = 'Envio de informações (ExFin)',
  'ENVIO_INFORMACOES_CLIENTE' = 'Envio de informações (Cliente)',
  'CRIACAO_OPERACAO' = 'Criação de Operação (Empresa e Credor)',
  'ASSINATURA_DOCUMENTO' = 'Assinatura de documento',
  'PAGAMENTO' = 'Pagamento',
  'AGENDAMENTO_REUNIAO' = 'Agendamento de reunião',
  'ENCERRAMENTO' = 'Encerramento',
}

export enum ActType {
  FORM = 'Formulário',
  COMMUNICATION = 'Comunicação',
  ANSWER = 'Resposta',
  INFO_SOLICITATION = 'Solicitação',
  SEND_INFO_EXFIN = 'Envio de informações (ExFin)',
  SEND_INFO_CLIENT = 'Envio de informações (Cliente)',
  OP_CREATION = 'Criação de Operação (Empresa e Credor)',
  DOC_SIGN = 'Assinatura de documento',
  PAYMENT = 'Pagamento',
  MEETING_SCHEDULING = 'Agendamento de reunião',
  CLOSURE = 'Encerramento',
}

export enum ActStatus {
  OPEN = 'Aberto',
  CLOSED = 'Fechado',
}

export enum ClientDestineStatus {
  REGISTERED_USER = 'Usuário cadastrado',
  QUALY_FORM_AVAILABLE = 'Formulário de qualificação - disponível',
  QUALY_FORM_ABANDONED = 'Formulário de qualificação - abandono',
  QUALY_FORM_COMPLETED = 'Formulário de qualificação - concluído',
  BUSINESS_MEETING_CONTACT = 'Reunião comercial - contatar',
  BUSINESS_MEETING_SCHEDULED = 'Reunião comercial - agendada',
  BUSINESS_MEETING_NO_RETURN = 'Reunião comercial - sem retorno',
  BUSINESS_MEETING_COMPLETED = 'Reunião comercial - concluída',
  QUALY_OPPORTUNITY_QUALIFIED = 'Qualificação oportunidade - qualificado',
  QUALY_OPPORTUNITY_DISQUALIFIED = 'Qualificação oportunidade - desqualificado',
  PROPOSAL_PENDING = 'Proposta - pendente',
  PROPOSAL_GENERATED = 'Proposta - gerada',
  PROPOSAL_SENT = 'Proposta - enviada',
  PROPOSAL_UNDER_NEGOTIATION = 'Proposta - em negociação',
  PROPOSAL_AVAILABLE_SIGN = 'Proposta - disponível para assinatura',
  PROPOSAL_SIGNED = 'Proposta - assinada',
  PROPOSAL_DENIED = 'Proposta - negada',
  CREDIT_DEMAND_FORM_AVAILABLE = 'Formulário de demanda de crédito - disponível',
  CREDIT_DEMAND_FORM_COMPLETED = 'Formulário de demanda de crédito - concluído',
  CREDIT_DEMAND_FORM_REVIEW = 'Formulário de demanda de crédito - revisão',
  DEFINITION_FINANCIER_AVAILABLE = 'Definição de financiadores - disponível',
  DEFINITION_FINANCIER_COMPLETED = 'Definição de financiadores - concluído',
  DEFINITION_FINANCIER_REVIEW = 'Definição de financiadores - revisão',
  ONBOARD_MEETING_CONTACT = 'Reunião onboarding - contatar',
  ONBOARD_MEETING_SCHEDULED = 'Reunião onboarding - agendada',
  ONBOARD_MEETING_NO_FEEDBACK = 'Reunião onboarding - sem retorno',
  ONBOARD_MEETING_COMPLETED = 'Reunião onboarding - concluída',
  DOCUMENT_REQUEST_AVAILABLE = 'Solicitação de documentos - disponível',
  DOCUMENT_REQUEST_PENDING = 'Solicitação de documentos - pendente',
  DOCUMENT_REQUEST_COMPLETED = 'Solicitação de documentos - concluída',
  ACCOUNTING_DOCS_FINISHED = 'Documentos contábeis finalizado',
  SERASA_PF_QUERY_AVAILABLE = 'Consulta Serasa PF - disponível',
  SERASA_PF_QUERY_ABANDONED = 'Consulta Serasa PF - abandono',
  SERASA_PF_QUERY_COMPLETED = 'Consulta Serasa PF - concluído',
  PARTNERS_IRPF_IN_PDF_AVAILABLE = 'IRPF dos sócios em PDF - disponível',
  ONE_PAGER_COMPLETED = 'One pager - concluído',
  ONE_PAGER_PENDING = 'One pager - pendente',
  ONE_PAGER_PRODUCTION = 'One pager - produção',
  BOOK_PRODUCTION = 'Book - produção',
  BOOK_CLIENT_VALIDATION = 'Book - Validação cliente',
  BOOK_COMPLETED = 'Book - Concluído',
  OP_DEFINITION_AVAILABLE = 'Definição da operação - disponível',
  OP_DEFINITION_COMPLETED = 'Definição da operação - concluído',
  OP_DEFINITION_REVIEW = 'Definição da operação - revisão',
  MONITORING_UPDATED = 'Monitoramento - atualizado',
  MONITORING_SEND_DOCS = 'Monitoramento - envio de docs',
  MONITORING_A1_CERT_REQUEST = 'Monitoramento - solicitação certificado A1',
  OPERATION_IN_PROGRESS = 'Operação em andamento',
  RELATIONSHIP_MAINTENANCE = 'Manutenção de relacionamento',
  PROJECT = 'Projeto',
  STRUCTURED_OPERATION = 'Operação estruturada',
  CLOSED_CONTRACT = 'Contrato encerrado',
  SPED = 'SPED',
}

export const inactiveClients = [
  ClientDestineStatus.QUALY_OPPORTUNITY_DISQUALIFIED,
  ClientDestineStatus.PROPOSAL_DENIED,
  ClientDestineStatus.CLOSED_CONTRACT,
];

export enum OperationDestineStatus {
  SENDING_ONE_PAGER = 'Envio one pager',
  SENDING_BOOK_INFO_SENT = 'Envio book + info - Enviado',
  SENDING_BOOK_INFO_NO_RETURN = 'Envio book + info - sem retono',
  SENDING_BOOK_INFO_ADD_INFO = 'Envio book + info - info adicional',
  SENDING_BOOK_INFO_COMPLETED = 'Envio book + info - concluído',
  OPENING_ACCOUNT_SEND_DOCS = 'Abertura de conta - envio de docs',
  OPENING_ACCOUNT_PENDING_DOCS = 'Abertura de conta - documentos pendentes',
  OPENING_ACCOUNT_SIGN_CUSTOMER = 'Abertura de conta - assinatura cliente',
  OPENING_ACCOUNT_PROCESSING_INSTUTION = 'Abertura de conta - em processamento na instituição',
  CREDIT_ANALYSIS_IN_PROGRESS = 'Análise de crédito - andamento',
  CREDIT_ANALYSIS_NO_RETURN = 'Análise de crédito - sem retorno',
  CREDIT_ANALYSIS_APPROVED = 'Análise de crédito - aprovado',
  CREDIT_ANALYSIS_DENIED = 'Análise de crédito - negado',
  CREDIT_PROPOSAL_AVAILABLE = 'Proposta de crédito - disponível',
  CREDIT_PROPOSAL_SENT_CLIENT = 'Proposta de crédito - enviado cliente',
  CREDIT_PROPOSAL_CLIENT_ANALYSIS = 'Proposta de crédito - análise cliente',
  CREDIT_PROPOSAL_APPROVED_CLIENT = 'Proposta de crédito - aprovado cliente',
  CREDIT_PROPOSAL_DENIED_CLIENT = 'Proposta de crédito - negado cliente',
  CREDIT_PROPOSAL_EXPIRED = 'Proposta de crédito - expirado',
  CREDIT_PROPOSAL_UNDER_NEGOTIATION = 'Proposta de crédito - em negociação',
  DUA_DILIGENCE_SUPPORT_INST_ANALYSIS = 'Suporte dua diligence - Analise de instrumento',
  DUA_DILIGENCE_SUPPORT_GUARANTEES = 'Suporte dua diligence - Garantias',
  CONTRACT_SIGNATURE_AVAILABLE = 'Assinatura de contrato - Disponível',
  CONTRACT_SIGNATURE_PENDING = 'Assinatura de contrato - Pendente',
  CONTRACT_SIGNATURE_COMPLETED = 'Assinatura de contrato - Concluída',
  SETTLEMENT = 'Liquidação',
  NF_ISSUE_PENDING = 'Emissão NF - Pendente',
  NF_ISSUE_IN_ISSUE = 'Emissão NF - Em emissão',
  NF_ISSUE_SENT = 'Emissão NF - Enviada',
  FEASIBILITY_ANALYSIS = 'Análise de viabilidade',
  PARTNERS_DEFINITION = 'Definição de parceiros',
  MATERIAL_PREPARATION_PROJECT = 'Elaboração de material - projeto',
  MATERIAL_PREPARATION_PRESENTATION = 'Elaboração de material - apresentação',
  STRUCTURING_GUARANTEES = 'Estruturação de garantias',
  OPERATION_FORMALIZING = 'Formalização da operação',
  OPERATION_FINALIZED_MISSED = 'Operação finalizada - perdida',
  OPERATION_FINALIZED_COMPLETED = 'Operação finalizada - efetivada',
}

export enum ProductClient {
  CREDIT_PREPARATION = 'Preparação de Crédito',
  RELATION_FIN_INST = 'Relacionamento com Instituições Financeiras',
  STRUCTURED_OPERATIONS = 'Operações Estruturadas',
}

export enum Operation {
  VENTURE_DEBT = 'Operações de Venture Debt',
  OFF_BALANCE = 'Operações off balance',
  WOKING_CAPITAL = 'Capital de giro',
  OFFICIAL_LINES = 'Linhas oficiais',
  SUBSIDIZED_LINES = 'Linhas subvencionadas',
  CAPEX_OPEX_FINANCING = 'Financiamento de CAPEX e OPEX',
  ANTICIPATION_RECEIVABLES = 'Antecipação de recebíveis',
  BACEN = 'Bacen',
  'ACC(s)' = 'ACC(s)',
  'ACE(s)' = 'ACE(s)',
  PROJECT_FINANCE = 'Project Finance',
  'CCB(s)' = 'CCB(s)',
  DEBENTURES = 'Debêntures',
  'CRI(s)' = 'CRI(s)',
  'CRA(s)' = 'CRA(s)',
  STRUCTURING_INVESTMENT_FUND = 'Estruturação de fundo de investimento',
  STRUCTURED_CREDIT = 'Crédito Estruturado',
  HOME_EQUIT = 'Home Equity',
  HIGH_YIELD = 'High yield',
  DISTRESSED = 'Distressed',
  HIGH_GRADE = 'High grade',
  LEGAL_CLAIMS = 'Legal Claims',
  PREACTORYS = 'Precatórios',
  MARKETPLACE = 'Marketplace',
  INSECURANCE = 'Seguros',
  FGI = 'FGI',
  REVOLVING_CREDIT = 'Crédito Rotativo',
  CREDIT_CARD_LIMIT = 'Limite cartão de crédito',
  SALES_LEASE_BACK = 'Sales and Lease back',
}

export enum ClosingOperation {
  DISQUALIFIED = 'Disqualified ',
  DISQUALIFIED_ICP = 'Disqualified- ICP ',
  DISQUALIFIED_NO_FIXED_ACTIVE = 'Disqualified - sem ativo fixo',
  DISQUALIFIED_OTHERS = 'Disqualified - outros ',
  INTEREST_NO_DEMAND = 'Interest - sem demanda para crédito ',
  INTEREST_NO_RETURN = 'Interest - sem retorno',
  INTEREST_TIMING = 'Interest - Incorrect timing ',
  COMPETITOR_SPEED = 'Competitor - velocidade',
  COMPETITOR_OFFER = 'Competitor - melhor oferta',
  DOCS = 'Docs - Missing docs ',
  PRODUCT_NO_FIT = 'Product - não atende',
  PRODUCT_PRICE = 'Product - valor alto ',
  PRODUCT_TERMS = 'Product - Termos da proposta não aprovados ',
  CREDIT_COMPLIANCE = 'Credit - Compliance',
  CREDIT_RISK = 'Credit - Risco ',
  CREDIT_DETERIORATION = 'Credit - deterioração financeira',
  CREDIT_LEVERAGE = 'Credit - Alavancagem',
  OTHER = 'Outros (detalhar)',
  RELEASED_OPERATION = 'Operação liberada',
}
