import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useDynamicFormStorage } from '../hooks';
import { DynamicFormStorageItem } from './DynamicFormStorageItem';

const DynamicFormStorageTable = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useDynamicFormStorage();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={180}>
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                CNPJ
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Nome fantasia
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Nome Formulário
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Status do formulário
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Status do cliente
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Data da resposta
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Mais detalhes
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          columns={7}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhuma resposta realizada"
        >
          {data?.map(answer => (
            <DynamicFormStorageItem key={answer.id} {...answer} />
          ))}
        </TableBody>
      </Table>
      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};

export { DynamicFormStorageTable };
