import { ReactNode } from 'react';

import Typography from 'components/Typography';

import { TitleWrapper } from './styles';

interface TitleProps {
  icon: ReactNode;
  title: string;
}

export function Title({ icon, title }: TitleProps) {
  return (
    <TitleWrapper>
      {icon}

      <Typography variant="h5" color="white">
        {title}
      </Typography>
    </TitleWrapper>
  );
}
