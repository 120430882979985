import { Box, ButtonContained } from 'components';
import { Permissions } from 'models/roles/Permission';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { useOperationActions } from '../hooks';

const OperationActions = () => {
  const {
    handleOpenChangeStatsDialog,
    handleOpenCreateActDialog,
  } = useOperationActions();
  const { getSpecificPermissionRole } = useValidateRouteAccess();

  const enableStatusChange = getSpecificPermissionRole(
    Permissions.ChangeOperationStatus,
  );

  return (
    <Box display="flex" alignItems="center" style={{ gap: '1em' }}>
      <ButtonContained label="CRIAR ATO" onClick={handleOpenCreateActDialog} />

      {enableStatusChange && (
        <ButtonContained
          label="MUDAR STATUS"
          onClick={handleOpenChangeStatsDialog}
        />
      )}
    </Box>
  );
};

export { OperationActions };
