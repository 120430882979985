import { Paper, Typography } from '@mui/material';
import themes from 'themes';

import { Box, Logo } from 'components';

import { FormPasswordSetting, FormValidationCode } from 'modules/signUp';

import { useDefinePassword, useValidationCode } from '../hooks';
import { useDefinePasswordStore } from '../store/store';

function DefinePasswordPage() {
  const { step } = useDefinePasswordStore();

  const { codeForm, requestCodeForm } = useValidationCode();
  const { setPasswordForm, requestPassword } = useDefinePassword();

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <Logo />
      {step === 1 && (
        <Paper
          elevation={16}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingX: '3em',
            paddingTop: '3em',
            paddingBottom: '2.5em',
            marginY: 'auto',
            gap: '2em',
            backgroundColor: '#f0f0f0',
            borderRadius: '11px',
            boxShadow:
              '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
            width: 532,
          }}
        >
          <Box
            marginY="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{
              gap: '1em',
            }}
          >
            <Typography
              fontFamily={themes.fonts.body}
              variant="h4"
              fontSize={23}
              textAlign="center"
              fontWeight={700}
              color={themes.colors.blue}
            >
              Defina a sua senha
            </Typography>
            <Typography
              variant="body1"
              textAlign="center"
              fontFamily={themes.fonts.body}
            >
              Digite o código enviado para seu email
            </Typography>
            <FormValidationCode
              form={codeForm}
              isValidating={requestCodeForm.isLoading}
            />
          </Box>
        </Paper>
      )}
      {step === 2 && (
        <FormPasswordSetting
          form={setPasswordForm}
          isSigningUp={requestPassword.isLoading}
        />
      )}
    </Box>
  );
}

export { DefinePasswordPage };
