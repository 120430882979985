import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useFindUser, useResetPagination } from '../hooks';
import { Item } from './Item';

export const List = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useFindUser();
  useResetPagination();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Nome
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Email
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Perfil
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Ativo/Inativo
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Editar
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody
          columns={9}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhum usuário encontrado"
        >
          {data?.map(user => (
            <Item key={user.id} {...user} />
          ))}
        </TableBody>
      </Table>

      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};
