import moment from 'moment';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IUser, UserPositions, Languages, UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { emailUserType } from 'modules/email';
import { IUserEdit, IUserPayload } from 'modules/user/services';

import { enumToArray } from 'utils/enumToArray';

import { useFindRoles } from '../../hooks';
import { useConsultantsStore } from '../store/store';
import {
  useConsultantDialogForm,
  useSendEmailDefinePasswordRequest,
  useCreateConsultantRequest,
  useUpdateConsultantRequest,
} from './';

export const useConsultantDialog = () => {
  const {
    openFormDialog,
    currentConsultant,
    setCurrentConsultant,
    setOpenFormDialog,
  } = useConsultantsStore();
  const form = useConsultantDialogForm();
  const queryClient = useQueryClient();
  const ROLES_TYPES = useFindRoles();
  const USER_ROLES = enumToArray(UserPositions);
  const { user } = useAuth();

  useEffect(() => {
    form.reset(currentConsultant);
  }, [currentConsultant]);

  const handleOpenFormDialog = async (data: IUser) => {
    if (data.id) {
      setCurrentConsultant({
        id: data.id,
        name: data.name,
        surname: data.surname,
        cpf: data.cpf,
        linkedin: data?.linkedin ?? '',
        email: data.email,
        active: data.active,
        birthDate: new Date(data.birthDate).toLocaleDateString('pt-br'),
        language: Languages['PORTUGUESE'],
        role: data.role.name,
        position: data.position,
        userType: data.userType,
      });
    } else {
      setCurrentConsultant({
        active: true,
      } as IUserEdit);
    }
    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setCurrentConsultant(undefined);
    setOpenFormDialog(false);
  };

  const requestCreateConsultant = useCreateConsultantRequest({
    onSuccess: async data => {
      await requestSendEmail.mutate({
        emailUserType: emailUserType.CONSULTANT,
        name: data.name,
        email: data.email,
      });
      toast.success('Consultor criado com sucesso');
      queryClient.invalidateQueries('consultants');
      handleCloseFormDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar criar o consultor');
    },
  });

  const requestUpdateConsultant = useUpdateConsultantRequest({
    onSuccess: () => {
      toast.success('Consultor editado com sucesso');
      queryClient.invalidateQueries('consultants');
      handleCloseFormDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o consultor');
    },
  });

  const requestSendEmail = useSendEmailDefinePasswordRequest({
    onSuccess: () => {
      toast.success('Email para definição de senha enviado com sucesso');
    },
    onError: () => {
      toast.error('Houve um erro ao tentar enviar o email de confirmação');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const birthDate = moment(data.birthDate, 'DD/MM/YYYY HH:mm');

    const role = ROLES_TYPES.data?.find(role => role.name === UserTypes.CONSULTANT);

    const userPayload: IUserPayload = {
      ...data,
      role: role?.id ?? '',
      position: UserPositions.OUTROS,
      birthDate: birthDate.toDate(),
      userType: UserTypes.CONSULTANT,
      linkedin: undefined,
      language: Languages.PORTUGUESE,
      enterprise: user?.enterprise?.id ?? undefined,
    };

    if (currentConsultant?.id) {
      requestUpdateConsultant.mutate({
        id: currentConsultant.id,
        user: userPayload,
      });

      return;
    }

    await requestCreateConsultant.mutate(userPayload);
  });

  return {
    openFormDialog,
    USER_ROLES,
    ROLES_TYPES,
    requestCreateConsultant,
    handleOpenFormDialog,
    handleCloseFormDialog,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseConsultantDialog = ReturnType<typeof useConsultantDialog>;
