import { TextField } from '@mui/material';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import {
  Box,
  ButtonContained,
  DialogConfirmAction,
  VerticalTimeLineElement,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { ActStatus, IProcessAct, TypeProcessAct } from 'models';

import { ICurrentTabInfo } from 'modules/boards/tabs/services';

import { OptionsValuesString } from 'utils/helper';

import { CardActTimeLine, CreateProcessAct } from '../components';
import '../components/timeline.css';
import { useTabAct } from '../hooks';
import { useTabActsStore } from '../store/store';

interface TabActProps {
  processActs: IProcessAct[];
  currentTabInfos: ICurrentTabInfo;
}

const TabAct = ({ processActs, currentTabInfos }: TabActProps) => {
  const {
    actOptions,
    act,
    renderNewActOption,
    form,
    typeScreenForm,
    setRenderActOption,
    handleNewAct,
    handleCloseDialog,
  } = useTabAct({ enabledListAllActs: true });

  const { openModalOut, setOpenModalOut } = useTabActsStore();

  const lastAct = processActs[processActs.length - 1];

  const validateRenderAct = (act: IProcessAct) => {
    const isActOperation = act?.typeProcessAct === TypeProcessAct.OPERATION;
    if (isActOperation) {
      const operationId = currentTabInfos?.operationId;
      const validRenderActOperation = operationId === act?.operation?.id;
      return validRenderActOperation;
    }
    const enterpriseId = currentTabInfos?.enterpriseId;
    const validRenderActClient = enterpriseId === act?.enterprise?.id;
    return validRenderActClient;
  };

  return (
    <Box mt={5}>
      <CreateProcessAct
        form={form}
        type={typeScreenForm}
        typeScreenCalled="acts"
        handleCloseDialog={handleCloseDialog}
      />
      <DialogConfirmAction
        title={'Sair'}
        informationCard={'Você tem certeza que deseja sair sem salvar?'}
        showDialog={openModalOut}
        setShowDialog={setOpenModalOut}
        handleConfirm={handleCloseDialog}
      />

      <VerticalTimeline className="vertical-timeline-custom-line">
        {processActs.map(act => {
          return (
            <>
              {validateRenderAct(act) && (
                <VerticalTimeLineElement
                  key={act.id}
                  dateCreation={act.createdAt}
                  status={ActStatus[act.status]}
                >
                  <CardActTimeLine
                    idAct={act?.id ?? ''}
                    nameAct={act.act.name}
                    operator={act.operator.name}
                    executionDate={act.executionDate}
                    deadline={act.deadline}
                    responsible={`${act.responsible?.name ?? ''} ${
                      act.responsible?.surname ?? ''
                    }`}
                  />
                </VerticalTimeLineElement>
              )}
            </>
          );
        })}

        {!lastAct?.act?.configs?.closureAct && (
          <VerticalTimeLineElement>
            {renderNewActOption ? (
              <AutocompleteWrapper
                multiple={false}
                options={actOptions}
                noOptionsText="Nenhum ato encontrado"
                value={act?.name ?? undefined}
                renderInput={params => (
                  <TextField {...params} label="Selecione o novo ato" />
                )}
                onChange={(_, value) => handleNewAct(value as OptionsValuesString)}
              />
            ) : (
              <ButtonContained
                color="gray-700"
                label="NOVO ATO"
                onClick={() => setRenderActOption(true)}
              />
            )}
          </VerticalTimeLineElement>
        )}
      </VerticalTimeline>
    </Box>
  );
};

export { TabAct };
