import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';

import { CheckboxWrapper } from './styles';

function CustomCheckbox() {
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    setChecked(event.target.checked);
  };

  return (
    <CheckboxWrapper
      control={
        <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<RadioButtonCheckedIcon />}
          checked={checked}
          onChange={handleChange}
        />
      }
      label="Listar Inativos"
    />
  );
}

export default CustomCheckbox;
