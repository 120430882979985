import React from 'react';
import { IMaskInput } from 'react-imask';

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  mask: string;
}

export const TextMask = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMask(props, ref) {
    const { mask, onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={mask}
        inputRef={ref}
        onAccept={value =>
          onChange({ target: { name: props.name, value: value as string } })
        }
        overwrite
      />
    );
  },
);
