import { create } from 'zustand';

import { DEFAULT_PAGINATION } from 'utils/helper';

import {
  IUserFilter,
  IUserEdit,
  IUserFilterByOption,
  DEFAULT_USER_FILTER_BY,
} from '../services';

interface Store {
  // list filters
  search: string;
  setSearch: (search: string) => void;

  // pagination
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  limit: boolean;
  setLimit: (limit: boolean) => void;

  // filter Dialog
  openFilterDialog: boolean;
  setOpenFilterDialog: (open: boolean) => void;
  searchOption: string;
  setSearchOption: (searchCountry: string) => void;
  currentFilter: IUserFilter[];
  setCurrentFilter: (filters: IUserFilter[]) => void;
  filterBy: IUserFilterByOption;
  setFilterBy: (filterBy: IUserFilterByOption) => void;

  // form dialog
  openFormDialog: boolean;
  setOpenFormDialog: (open: boolean) => void;
  currentUser: IUserEdit;
  setCurrentUser: (user?: IUserEdit | undefined) => void;
}

export const useUserStore = create<Store>(set => ({
  page: DEFAULT_PAGINATION.page,
  setPage: page => set({ page }),
  perPage: DEFAULT_PAGINATION.perPage,
  setPerPage: perPage => set({ perPage }),
  limit: false,
  setLimit: limit => set({ limit }),

  search: '',
  setSearch: search => set({ search }),

  openFilterDialog: false,
  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  searchOption: '',
  setSearchOption: searchOption => set({ searchOption }),
  currentFilter: [],
  setCurrentFilter: currentFilter => set({ currentFilter }),
  filterBy: DEFAULT_USER_FILTER_BY,
  setFilterBy: filterBy => set({ filterBy }),

  openFormDialog: false,
  setOpenFormDialog: openFormDialog => set({ openFormDialog }),
  currentUser: {} as IUserEdit,
  setCurrentUser: currentUser => set({ currentUser }),
}));
