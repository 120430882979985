import { IEnterprise } from './enterpise.types';

export interface ICompanyKMethod {
  cnpj: string;
  fantasyName: string;
  kScale: number;
  kMethod: KleverKModule;
  user: {
    active: boolean;
  };
  vetoesAndAlerts: {
    cnpj: string;
    alerts: Alert[];
    vetoes: Vetoes[];
  };
  demonstrations: KleverBalanceInfoTO[];
  actives: KleverBalanceInfoTO[];
  passives: KleverBalanceInfoTO[];
  indexesAndIndicators: KleverBalanceInfoTO[];
  enterprise: IEnterprise;
}

export interface KleverBalanceInfoTO {
  code: string;
  name: string;
  percentHA: number;
  percentVA: number;
  rootCode: null | string;
  sheet: boolean;
  type: KleverBalanceType;
  value: number;
}

export enum KleverBalanceType {
  Active = 'ACTIVE',
  Dre = 'DRE',
  IndexesAndIndicators = 'INDEXES_AND_INDICATORS',
  Passive = 'PASSIVE',
}

export interface KleverKModule {
  analysis: Analysis[];
  balances: Balance[];
  cnae: CnaeKMethod;
  cnpj: string;
  consumerClientType: string;
  created: string;
  demonstrations: Demonstration[];
  fantasyName: string;
  habituality: string;
  habitualityWeight: string;
  indicativeOperation: IndicativeOperation;
  openingDate: string;
  quantityEmployee: string;
  register: string;
  registerWeight: string;
  robustnessWeight: string;
  scale: Scale;
  strength: string;
  total: string;
  uf: string;
  [property: string]: any;
}

export interface Analysis {
  operations: AnalysisOperations;
  total: string;
  type: string;
  [property: string]: any;
}
export interface Alert {
  description?: string;
  level?: number;
  score?: number;
}

export interface Vetoes {
  description?: string;
  id?: string;
}

export interface AnalysisOperations {
  ex_50?: { [key: string]: any };
  exercitation7c: { [key: string]: any };
  id_a?: { [key: string]: any };
  [property: string]: any;
}

export interface Balance {
  description: string;
  endDate: string;
  endValue: string;
  [property: string]: any;
}

export interface CnaeKMethod {
  businessActivity: string;
  code: string;
  description: string;
  id: string;
  physicalProductions: PhysicalProduction[];
  sector: string;
  [property: string]: any;
}

export interface PhysicalProduction {
  codeDescription: string;
  date: string;
  fixedBaseIndexWithoutSeasonalAdjustment: FixedBaseIndexWithoutSeasonalAdjustment;
  indexAccumulatedLast12Months: IndexAccumulatedLast12Months;
  monthlyIndex: MonthlyIndex;
  monthlyPercentageChange: MonthlyPercentageChange;
  percentageChangeAccumulatedLast12Months: PercentageChangeAccumulatedLast12Months;
  percentageChangeAccumulatedYear: PercentageChangeAccumulatedYear;
  territorialLevel: string;
  yearToDateIndex: YearToDateIndex;
  [property: string]: any;
}

export interface FixedBaseIndexWithoutSeasonalAdjustment {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface IndexAccumulatedLast12Months {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface MonthlyIndex {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface MonthlyPercentageChange {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface PercentageChangeAccumulatedLast12Months {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface PercentageChangeAccumulatedYear {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface YearToDateIndex {
  measureUnit: string;
  name: string;
  value: string;
  [property: string]: any;
}

export interface Demonstration {
  description: string;
  endDate: string;
  endValue: string;
  [property: string]: any;
}

export interface IndicativeOperation {
  gracePeriod: string;
  grossBreakeven: string;
  liquidBreakeven: string;
  operations: IndicativeOperationOperations;
  term: string;
  typeOfGuarantee: string;
  volume: string;
  [property: string]: any;
}

export interface IndicativeOperationOperations {
  magna88: { [key: string]: any };
  quib: { [key: string]: any };
  Ut_45: { [key: string]: any };
  [property: string]: any;
}

export interface Scale {
  operationAction: string;
  rating: string;
  scale: string;
  [property: string]: any;
}
