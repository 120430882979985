export const validateDate = (date: string) => {
  // Verifica se a data está no formato "dd/mm/aaaa"
  const datePattern = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!datePattern.test(date)) {
    return false;
  }

  // Divide a data em dia, mês e ano
  const [day, month, year] = date.split('/').map(Number);

  // Verifica se o ano é bissexto
  const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;

  // Verifica se o mês está dentro do intervalo válido (1 a 12)
  if (month < 1 || month > 12) {
    return false;
  }

  // Verifica o número de dias no mês
  const daysInMonth = [
    31,
    isLeapYear ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ];
  if (day < 1 || day > daysInMonth[month - 1]) {
    return false;
  }

  return true;
};
