import { Grid, Typography } from '@mui/material';

import { KleverCompanyPartner } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  partners: KleverCompanyPartner[];
}

const BigDataPartners = ({ partners }: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Sócios
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {partners?.map((partner, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <BigDataItem title="Documento" value={partner.document} />
            <BigDataItem title="Nome" value={partner.name} />
            <BigDataItem title="País de Origem" value={partner.originCountry} />
            <BigDataItem
              title="Nível de formação"
              value={partner.levelPreparation}
            />
            <BigDataItem
              title="Nivel de formação no RF"
              value={partner.levelPreparationRF}
            />
            <BigDataItem title="Qualificação" value={partner.qualification} />
            <BigDataItem
              title="Qualificação no RF"
              value={partner.qualificationRF}
            />
            <BigDataItem
              title="Participação"
              value={partner.participation?.toString()}
            />
            <BigDataItem
              title="Participação no RF"
              value={partner.participationRF?.toString()}
            />
            <BigDataItem
              title="Participação no Capital Social"
              value={partner.participationSocialCapital?.toString()}
            />
            <BigDataItem
              title="Participação no Capital Social no RF"
              value={partner.participationSocialCapitalRF?.toString()}
            />
            <BigDataItem title="Falecido" value={partner.dead ? 'Sim' : 'Não'} />
            <BigDataItem
              title="Listagem de Processos Criminais"
              value={partner.criminalProcess?.toString().replaceAll(',', ', ')}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default BigDataPartners;
