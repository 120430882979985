import { ReactNode } from 'react';
import { RoutesProps } from 'routes';

import { Icon } from 'components';

import { useSubMenuOpen } from '../../hooks/useSubMenuOpen';
import { SubMenuWrapper } from './style';

interface SubMenuProps {
  children: ReactNode;
  route: RoutesProps;
}
export function SubMenu({ children, route }: SubMenuProps) {
  const { open } = useSubMenuOpen(route.subs || []);

  return (
    <SubMenuWrapper
      key={route.name}
      icon={<Icon fontSize="h6">{route.icon}</Icon>}
      label={route.name}
      defaultOpen={open}
    >
      {children}
    </SubMenuWrapper>
  );
}
