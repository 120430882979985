import { useCallback, useMemo } from 'react';

import { useListAllConsultants } from 'modules/user/hooks';
import {
  useCustomerProductOptions,
  useCustomerTypeOptions,
  useFindActiveOptions,
} from 'modules/user/utils';

import { OptionsValues } from 'utils/helper';

import {
  ICustomerBoardFilter,
  ICustomerBoardFilterBy,
  customerBoardFilterByOptions,
} from '../services';
import { useCustomerManagementStore } from '../store/store';
import { customerStatusOptions } from '../utils';
import { useListMainCustomers } from './useListMainCustomers';

export const useCustomerFilterForm = () => {
  const {
    filterBy,
    currentFilter,
    setFilterBy,
    setSearchFilterOption,
    setCurrentFilter,
    openFilterDialog,
  } = useCustomerManagementStore();

  const { consultantsOptions } = useListAllConsultants(openFilterDialog);
  const { customerTypesOptions } = useCustomerTypeOptions();
  const { activeOptions } = useFindActiveOptions();
  const { customerProductOptions } = useCustomerProductOptions();
  const { optionsMainCustomers } = useListMainCustomers(openFilterDialog);

  const isFiltering = currentFilter.length > 0;

  const handleChangesFilter = (value: OptionsValues[]) => {
    const addedFilter = value[value.length - 1];
    const newValues = [
      ...currentFilter,
      { filterBy: filterBy.value, ...addedFilter },
    ];
    setCurrentFilter(newValues);
  };

  const handleRemoveFilter = (option: ICustomerBoardFilter) => {
    const newFilters = currentFilter.filter(item => item !== option);

    setCurrentFilter(newFilters);
  };

  const clearFilter = useCallback(() => {
    setCurrentFilter([]);
  }, []);

  const clearSearchOption = useCallback(() => setSearchFilterOption(''), []);

  const getOptions = () => {
    const optionsList = {
      [ICustomerBoardFilterBy.TYPE]: customerTypesOptions,
      [ICustomerBoardFilterBy.RESPONSIBLE]: consultantsOptions,
      [ICustomerBoardFilterBy.STATUS]: customerStatusOptions,
      [ICustomerBoardFilterBy.PRODUCT]: customerProductOptions,
      [ICustomerBoardFilterBy.ACTIVE_INACTIVE]: activeOptions,
      [ICustomerBoardFilterBy.PRIMARY_ENTERPRISE]: optionsMainCustomers,
    };

    return optionsList[filterBy?.value] ?? [];
  };

  const optionsFilter = getOptions();

  const isOptionEqualToValue = (option, filter) => {
    return option.value === filter.value && option.label === filter.label;
  };

  const generateFilterParams = useMemo(() => {
    const filterParams = currentFilter.reduce((acc, item) => {
      if (!acc[item.filterBy]) {
        acc[item.filterBy] = [];
      }

      acc[item.filterBy].push(item.value);

      return acc;
    }, {});

    return filterParams;
  }, [currentFilter]);

  return {
    filterBy,
    optionsFilter,
    isFiltering,
    customerBoardfilterOptions: customerBoardFilterByOptions,
    filterParams: generateFilterParams,
    currentFilter,
    clearSearchOption,
    clearFilter,
    handleChangesFilter,
    handleRemoveFilter,
    isOptionEqualToValue,
    setFilterBy,
  };
};
