import { IAct, IPagination, TypeProcessAct } from 'models';

import api from 'config/api';

import { IActPayload, IFilterActParams, IUpdateActRequest } from './types';

class ActServices {
  static async list(params?: IFilterActParams) {
    const { data } = await api.get<IPagination<IAct>>(`/act`, {
      params,
    });
    return data;
  }

  static async listAll(type: TypeProcessAct) {
    const { data } = await api.get<IAct[]>(`/act/list-all`, {
      params: { type },
    });
    return data;
  }

  static async createAct(body: IActPayload) {
    const { data } = await api.post<IActPayload>(`/act`, body);

    return data;
  }

  static async update({ actId, configActId, body }: IUpdateActRequest) {
    const { data } = await api.put<IActPayload>(
      `/act/${actId}/${configActId}`,
      body,
    );
    return data;
  }
}

export default ActServices;
