import { Edit } from '@mui/icons-material';
import { TableCell, Icon } from '@mui/material';
import themes from 'themes';

import { TableRow, Typography, TypographyActive } from 'components';
import { IUser } from 'models';

import { useConsultantDialog } from '../hooks';

export const Item = (value: IUser) => {
  const { handleOpenFormDialog } = useConsultantDialog();

  const { colors, fontSizes } = themes;

  return (
    <>
      <TableRow key={value.id} onClick={() => handleOpenFormDialog({ ...value })}>
        <TableCell>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.name}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.surname}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography
            style={{
              fontSize: `${fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {value.email}
          </Typography>
        </TableCell>
        <TableCell width={'60px'}>
          <TypographyActive
            style={{ width: '40px', fontSize: `${fontSizes.s}px` }}
            textAlign="center"
            backgroundColor={value.active ? colors.green : colors.error}
          >
            {value.active ? 'Ativo' : 'Inativo'}
          </TypographyActive>
        </TableCell>
        <TableCell align="center">
          <Icon>
            <Typography>
              <Edit />
            </Typography>
          </Icon>
        </TableCell>
      </TableRow>
    </>
  );
};
