import styled from 'styled-components';
import themes from 'themes';

import Box from 'components/Box';

interface IconProps {
  color?: keyof typeof themes.colors;
  fontSize?: keyof typeof themes.fontSizes;
}

const IconWrapper = styled(Box)<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;

  span {
    color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.white};
    font-size: ${({ theme, fontSize }) =>
      fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.h5}px;
  }
`;

export { IconWrapper };
