import { LoadingButton } from '@mui/lab';
import { Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import themes from 'themes';

import { Box } from 'components';

interface Props {
  isLoading: boolean;
  submitFormButtonLabel: string;

  handleSubmitForm: () => void;

  children: React.ReactNode;
}

function FormWrapper({
  isLoading,
  submitFormButtonLabel,
  handleSubmitForm,
  children,
}: Readonly<Props>) {
  const history = useHistory();

  return (
    <Box
      as="form"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '3em',
        minWidth: '532px',
        padding: '1em',
      }}
    >
      {children}
      <Grid container columnSpacing={5}>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            onClick={() => history.goBack()}
            disabled={isLoading}
            loading={isLoading}
            fullWidth
            sx={{
              backgroundColor: 'white',
              color: 'black',
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,

              '&:hover': {
                backgroundColor: '#f0f0f0',
              },
            }}
          >
            Cancelar
          </LoadingButton>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmitForm}
            loading={isLoading}
            disabled={isLoading}
            fullWidth
            sx={{
              backgroundColor: themes.colors.text.primary,
              textTransform: 'none',
              borderRadius: '8px',
              paddingY: '0.8em',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
              fontFamily: themes.fonts.body,
            }}
          >
            {submitFormButtonLabel}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default FormWrapper;
