import { Grid } from '@mui/material';

import { PersonPefinInnerResponseTO, PersonRefinInnerResponseTO } from 'models';

import { SerasaModalItem } from '../../SerasaModalItem';

interface Props {
  data: PersonPefinInnerResponseTO | PersonRefinInnerResponseTO;
}

const PersonPefinRefin = ({ data }: Props) => {
  return (
    <Grid
      item
      xs={12}
      lg={6}
      key={`${data.contractId}-${data.legalNature}-${data.creditorName}`}
    >
      <SerasaModalItem
        title="Valor"
        value={data.amount?.toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
          minimumFractionDigits: 2,
        })}
      />
      <SerasaModalItem
        title="Data disponivel"
        value={
          data.availableDate &&
          new Date(data.availableDate).toLocaleDateString('pt-br')
        }
      />
      <SerasaModalItem title="Numero do caso" value={data.caseNumber} />
      <SerasaModalItem title="Cidade" value={data.city} />
      <SerasaModalItem title="Unidade Federativa" value={data.federalUnit} />
      <SerasaModalItem title="Tribunal civil" value={data.civilCourt} />
      <SerasaModalItem
        title="Identificador instituição financeira"
        value={data.creditorBusinessId}
      />
      <SerasaModalItem
        title="Nome da instituição financeira"
        value={data.creditorName}
      />
      <SerasaModalItem title="Distribuidor" value={data.distributor} />
      <SerasaModalItem
        title="Subjudice"
        value={data.flagSubJudice ? 'Sim' : 'Não'}
      />
      <SerasaModalItem
        title="Data inclusão"
        value={
          data.inclusionDate &&
          new Date(data.inclusionDate).toLocaleDateString('pt-br')
        }
      />
      <SerasaModalItem title="Natureza Jurídica" value={data.legalNature} />
      <SerasaModalItem title="Id Natureza Jurídica" value={data.legalNatureId} />
      <SerasaModalItem title="Praça Legal" value={data.legalSquare} />
      <SerasaModalItem
        title="Data ocorrencia"
        value={
          data.occurrenceDate &&
          new Date(data.occurrenceDate).toLocaleDateString('pt-br')
        }
      />
      <SerasaModalItem title="Principal" value={data.principal ? 'Sim' : 'Não'} />
      <SerasaModalItem title="Id do Contrato" value={data.contractId} />
      <SerasaModalItem
        title="Data da Ocorrência"
        value={
          data.occurrenceDate &&
          new Date(data.occurrenceDate).toLocaleDateString('pt-br')
        }
      />
      <SerasaModalItem
        title="Agencia publica"
        value={data.publicAgency ? 'Sim' : 'Nao'}
      />
    </Grid>
  );
};

export { PersonPefinRefin };
