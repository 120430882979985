import { IEnterprise, IForm } from 'models';

export enum FormStatus {
  DRAFT = 'Draft',
  COMPLETED = 'Completed',
  SIMULATION = 'Simulation',
  // REJECTED = 'Rejected',
}

export enum FormStatusPtBr {
  Draft = 'Incompleto',
  Completed = 'Completo',
  Simulation = 'Simulação',
  // REJECTED = 'Rejected',
}

export interface IFormRecord {
  id: string;
  form: IForm;
  enterprise: IEnterprise;
  status: FormStatus;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
}
