import { Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { BillingMonthly } from 'modules/dashboard/services';
import { useDashboardStore } from 'modules/dashboard/store/store';

import { increaseDistanceLegendPlugin } from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface MonthlyBillingChartProps {
  dataStatements: BillingMonthly[];
}

const MonthlyBillingChart = ({ dataStatements }: MonthlyBillingChartProps) => {
  const { setChartsImagesUrl } = useDashboardStore();
  const [imagesSet, setImagesSet] = useState(false);

  function filterAndGroupByMonth(year: number): number[] {
    const filteredData = dataStatements?.filter(
      item => new Date(item.date).getFullYear() === year,
    );

    const groupedValues: { [month: number]: number } = {};

    if (!filteredData) return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    filteredData.forEach(item => {
      const month = new Date(item.date).getMonth();
      groupedValues[month] = (groupedValues[month] || 0) + item.nfValue;
    });

    // Preencher meses faltantes com valor zero
    const result: number[] = [];
    for (let month = 0; month < 12; month++) {
      result.push(groupedValues[month] || 0);
    }

    return result;
  }

  function cumulativeSumArray(array: number[]): number[] {
    let sum = 0;
    return array.map(value => (sum += value));
  }

  // Usando a função
  const currentYear = new Date().getFullYear();
  const nfByMonthN = filterAndGroupByMonth(currentYear - 1);
  const nfByMonthN1 = filterAndGroupByMonth(currentYear - 2);
  const nfByMonthN2 = filterAndGroupByMonth(currentYear - 3);

  const nfByMonthNAcc = cumulativeSumArray(nfByMonthN);
  const nfByMonthN1Acc = cumulativeSumArray(nfByMonthN1);
  const nfByMonthN2Acc = cumulativeSumArray(nfByMonthN2);

  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dec',
  ];

  const heightWindow = window.innerHeight;
  const widthChart = heightWindow > 900 ? '97.2%' : '96%';

  return (
    <Card
      elevation={2}
      style={{
        width: widthChart,
        padding: 16,
        paddingRight: 22,
        paddingLeft: 22,
      }}
    >
      <Chart
        type="line"
        data={{
          labels: months,
          datasets: [
            {
              label: 'Faturamento Mensal N - 1',
              data: nfByMonthN1,
              borderColor: 'rgb(230, 145, 56)',
              backgroundColor: 'rgb(230, 145, 56)',
              borderWidth: 2,
              pointRadius: 0,
            },
            {
              label: 'Faturamento Acumulado Mensalmente N - 1',
              data: nfByMonthN1Acc,
              borderColor: 'rgb(0, 43, 106)',
              backgroundColor: 'rgb(0, 43, 106)',
              borderWidth: 2,
              pointRadius: 0,
            },
            {
              label: 'Faturamento Mensal N - 2',
              data: nfByMonthN2,
              borderColor: 'rgb(189, 214, 238)',
              backgroundColor: 'rgb(189, 214, 238)',
              borderWidth: 2,
              pointRadius: 0,
            },
            {
              label: 'Faturamento Acumulado Mensalmente N - 2',
              data: nfByMonthN2Acc,
              borderColor: 'rgb(98, 219, 149)',
              backgroundColor: 'rgb(98, 219, 149)',
              borderWidth: 2,
              pointRadius: 0,
            },
            {
              label: 'Faturamento Mensal N',
              data: nfByMonthN,
              borderColor: 'rgb(54, 112, 255)',
              backgroundColor: 'rgb(54, 112, 255)',
              borderWidth: 2,
              pointRadius: 0,
            },
            {
              label: 'Faturamento Acumulado Mensalmente N',
              data: nfByMonthNAcc,
              borderColor: 'rgb(232, 68, 84)',
              backgroundColor: 'rgb(232, 68, 84)',
              borderWidth: 2,
              pointRadius: 0,
            },
          ],
        }}
        plugins={[increaseDistanceLegendPlugin(25)]}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: themes.colors.dashboard.table.text.black,
              },
            },
            title: {
              display: true,
              text: 'Faturamento mensal',
              font: {
                size: 24,
              },
              color: themes.colors.dashboard.table.text.black,
              align: 'start',
            },
          },
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          elements: {
            point: {
              pointStyle: undefined,
            },
          },
          animation: {
            onComplete: animation => {
              if (!imagesSet) {
                setChartsImagesUrl(
                  'monthlyBilling',
                  animation.chart.toBase64Image(),
                  animation.chart.width,
                  animation.chart.height,
                );

                setImagesSet(true);
              }
            },
          },
        }}
        width={100} // Definindo a largura diretamente no Chart.js
        height={450} //
      />
    </Card>
  );
};

export { MonthlyBillingChart };
