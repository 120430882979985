import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IForgotPasswordPayload } from '../services';

export const forgotPasswordSchema = z
  .object({
    password: z
      .string()
      .trim()
      .nonempty('Senha é obrigatório')
      .regex(new RegExp('.*[A-Z].*'), 'Senha deve conter uma letra maiúscula')
      .regex(new RegExp('.*[0-9].*'), 'Senha deve conter um número')
      .regex(
        new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'),
        'Senha deve conter pelo menos um caractere especial',
      )
      .min(8, 'Senha deve ter no mínimo 8 caracteres'),
    confirmPassword: z
      .string()
      .trim()
      .nonempty('confirmar senha é obrigatório')
      .regex(new RegExp('.*[A-Z].*'), 'Senha deve conter uma letra maiúscula')
      .regex(new RegExp('.*[0-9].*'), 'Senha deve conter um número')
      .regex(
        new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'),
        'Senha deve conter pelo menos um caractere especial',
      )
      .min(8, 'Senha deve ter no mínimo 8 caracteres'),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: 'Senhas não são iguais',
    path: ['confirmPassword'],
  });

const defaultValues = {
  password: '',
  confirmPassword: '',
};

export const useForgotPasswordForm = () => {
  return useForm<IForgotPasswordPayload>({
    defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(forgotPasswordSchema),
  });
};
