import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { SignUpEnterpriseService, ICheckDataPayload } from '../services';

const useCheckExistDataRequest = (
  options?: UseMutationOptions<boolean, IApiAxiosError, ICheckDataPayload>,
) => {
  return useMutation(
    'checkExistData',
    SignUpEnterpriseService.checkExistData,
    options,
  );
};

export { useCheckExistDataRequest };
