import api from 'config/api';

import { DashboardCreditorsResponse, DashboardResponse } from './types';

class DashboardService {
  static async getDashboardData() {
    const { data } = await api.get<DashboardResponse>('/dashboard');

    return data;
  }

  static async getDashboardDataCreditors() {
    const { data } = await api.get<DashboardCreditorsResponse>(
      '/dashboard/creditors',
    );

    return data;
  }
}

export default DashboardService;
