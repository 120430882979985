export enum emailUserType {
  USER = 'USUÁRIO',
  CONSULTANT = 'CONSULTOR',
}

export interface ISendEmailValidationCodePayload {
  email: string;
  name: string;
  isRecover?: boolean;
}

export interface ISendEmailValidationCodeResponse {
  sent: boolean;
}

export interface IValidateCodePayload {
  code: string;
  saveValidationCode?: boolean;
}

export interface IValidateCodeResponse {
  isValid: boolean;
  email: string;
  userId: string;
}

export interface IEmailDefinePasswordPayload {
  emailUserType: emailUserType;
  email: string;
  name: string;
}

export interface IEmailDeadlineActPayload {
  email: string;
  type: 'after' | 'before';
  name: string;
  nameAct: string;
  deadline: string;
}

export interface IEmailActPayload {
  email: string;
  name: string;
  title: string;
  bodyActMessage: string;
}

export interface IEmailDefinePasswordResponse {
  sent: boolean;
}
