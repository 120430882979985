import { useMutation, UseMutationOptions } from 'react-query';

import { IForm } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { FormService } from '../services/services';

const useDuplicateFormRequest = (
  options?: UseMutationOptions<IForm, IApiAxiosError, string>,
) => {
  return useMutation('duplicateForm', FormService.duplicateForm, options);
};

export { useDuplicateFormRequest };
