import { Grid } from '@mui/material';

import { AttributeTO } from 'models';

import { SerasaModalItem } from '../../SerasaModalItem';

const PersonAttribute = (data: AttributeTO) => {
  return (
    <Grid item xs={12}>
      <SerasaModalItem title="Modelo atributo" value={data.attributeModel} />
      <SerasaModalItem
        title="Codigo da mensagem"
        value={data.codeMessage.toString()}
      />
      <SerasaModalItem title="Mensagem" value={data.message} />
      <SerasaModalItem title="Pontuação" value={data.scoring.toString()} />
    </Grid>
  );
};

export { PersonAttribute };
