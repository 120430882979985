import { Box } from 'components';
import { KleverFiscalNotesVerticalBill } from 'models';

import { AbcCurveChart, AbcCurveDataItem } from '../charts/AbcCurveChart';
import {
  DataTableAbcCurveItem,
  TypeCurveItem,
  AbcCurveTableComponent,
} from './AbcCurveTableComponent';

interface AbcCurveProps {
  dataClients: KleverFiscalNotesVerticalBill[];
  dataSuppliers: KleverFiscalNotesVerticalBill[];
  isLoading: boolean;
  noData: boolean;
}

export const processAbcData = (data: KleverFiscalNotesVerticalBill[]) => {
  const dataProcessed: DataTableAbcCurveItem[] = [];

  const totalValueLastExAcc = data?.reduce((acc, item) => acc + item.total, 0);

  const dataOrdered = data?.slice().sort((a, b) => b.total - a.total);
  let totalPercent = 0;

  dataOrdered?.map(item => {
    const sumTotalPercent = Number(
      ((item.total / Number(totalValueLastExAcc ?? 0)) * 100).toFixed(2),
    );
    totalPercent += sumTotalPercent;
    let classification = 'B';
    if (totalPercent <= 80) classification = 'A';
    else if (totalPercent > 95) classification = 'C';

    dataProcessed.push({
      clientName: item.name,
      valueLastExAcc: item.total,
      partPercentTotalValue: sumTotalPercent,
      accPartPercentTotalValue: Number(totalPercent.toFixed(2)),
      abcClassification: classification,
    });
  });

  function calculateTotalByClassification(classification: string) {
    return dataProcessed.reduce((total, item) => {
      if (item.abcClassification === classification) {
        return total + 1;
      }
      return total;
    }, 0);
  }

  // Calcular a quantidade total para cada classificação
  const quantTotalA = calculateTotalByClassification('A');
  const quantTotalB = calculateTotalByClassification('B');
  const quantTotalC = calculateTotalByClassification('C');

  const dataTypeCurve: TypeCurveItem[] = [
    {
      type: 'A',
      numberClient: quantTotalA,
    },
    {
      type: 'B',
      numberClient: quantTotalB,
    },
    {
      type: 'C',
      numberClient: quantTotalC,
    },
  ];

  const dataChart: AbcCurveDataItem = {
    abcCurveA: quantTotalA,
    abcCurveB: quantTotalB,
    abcCurveC: quantTotalC,
  };

  return {
    dataProcessed,
    dataTypeCurve,
    dataChart,
    totalValueLastExAcc,
  };
};

const AbcCurveTable = ({
  dataClients,
  dataSuppliers,
  isLoading,
  noData,
}: AbcCurveProps) => {
  const dataClientProcessed = processAbcData(dataClients ?? []);
  const dataSuppliersProcessed = processAbcData(dataSuppliers ?? []);

  return (
    <>
      <AbcCurveTableComponent
        data={dataClientProcessed.dataProcessed}
        dataTypeCurve={dataClientProcessed.dataTypeCurve}
        total={Number(dataClientProcessed.totalValueLastExAcc ?? 0)}
        typeTable={'customer'}
        isLoading={isLoading}
        noData={noData}
        title={'Curva ABC de Clientes'}
      />

      <Box width={'100%'} marginTop={8}>
        <AbcCurveChart
          data={dataClientProcessed.dataChart}
          title={'Curva ABC de Clientes'}
          type="customer"
        />
      </Box>

      <Box width={'100%'} marginTop={8}>
        <AbcCurveTableComponent
          data={dataSuppliersProcessed.dataProcessed}
          dataTypeCurve={dataSuppliersProcessed.dataTypeCurve}
          total={Number(dataSuppliersProcessed.totalValueLastExAcc ?? 0)}
          typeTable={'supplier'}
          isLoading={isLoading}
          noData={noData}
          title={'Curva ABC de Fornecedores'}
        />

        <Box width={'100%'} marginTop={8}>
          <AbcCurveChart
            data={dataSuppliersProcessed.dataChart}
            title={'Curva ABC de Fornecedores'}
            type="supplier"
          />
        </Box>
      </Box>
    </>
  );
};

export { AbcCurveTable };
