import { IconButton } from '@mui/material';

import { Icon, Box, ClearFilters } from 'components';
import { ButtonDownloadExcel } from 'components/FilterDatabase/ButtonDownloadExcel';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useDownloadExcel, useScrFilter, useScrSearch } from '../hooks';
import { useScrStore } from '../store/store';

const ScrSearchContainer = () => {
  const { setSearch } = useScrStore();
  const { handleChangeSearch } = useScrSearch(500, setSearch);
  const { isFiltering, handleOpenFilterDialog, resetFilter } = useScrFilter();
  const { handleDownloadExcel } = useDownloadExcel();

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title={'Base de dados - Módulo "SCR"'}
            icon={<Icon>storage</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />
          <PrivateLayout.Content.Header.Actions>
            <Box
              position="relative"
              minWidth={40}
              display={'flex'}
              justifyContent={'center'}
              marginRight={3}
            >
              <IconButton onClick={handleOpenFilterDialog}>
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={resetFilter} />}
            </Box>
            <ButtonDownloadExcel
              handleDownload={() => handleDownloadExcel()}
              loading={false}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { ScrSearchContainer };
