import { ControllerRenderProps } from 'react-hook-form';

import { IQuestion, IQuestionInput } from 'models';

import { OptionsValues } from 'utils/helper';

import { IConditionQuestion, IQuestionForm } from '../services';
import { useFormStore } from '../store/store';
import { useGetFormsQuestion } from './useGetFormQuestions';
import { useListActiveQuestions } from './useListActiveQuestions';
import { useQuestionAutocompleteForm } from './useQuestionAutocompleteForm';

export const useQuestionAutocomplete = () => {
  const { questionsOptions, dataAllQuestions } = useListActiveQuestions();
  const form = useQuestionAutocompleteForm();
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteChildsQuestions } = useGetFormsQuestion();

  const handleChange = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;
    field.onChange(value);

    // pergunta que foi selecionada para a questão do formulário
    const questionSelected = dataAllQuestions?.find(
      question => question.id === `${selectedOption?.value}`,
    );

    const indexQuestionForm = question?.numberQuestion - 1;

    if (!questionSelected) {
      questionsForm[indexQuestionForm].question = {} as IQuestion;
      questionsForm[indexQuestionForm].conditions = [] as IConditionQuestion[];
      questionsForm[indexQuestionForm].nextQuestionFalse = '';
      questionsForm[indexQuestionForm].openDropdown = false;
      handleDeleteChildsQuestions();
      return;
    }
    questionsForm[indexQuestionForm].question = questionSelected;

    setQuestionsForm(questionsForm);
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  function handleOpenDropdown(identifierQuestion: number) {
    const indexQuestionForm = identifierQuestion - 1;

    const conditionSetOpenDropdown =
      questionsForm[indexQuestionForm].openDropdown === undefined ||
      questionsForm[indexQuestionForm].openDropdown === false;

    if (conditionSetOpenDropdown) {
      questionsForm[indexQuestionForm].openDropdown = true;
      setQuestionsForm(questionsForm);
      return;
    }
    questionsForm[indexQuestionForm].openDropdown = false;
    setQuestionsForm(questionsForm);
  }

  return {
    allQuestions: questionsOptions,
    handleChange,
    isOptionEqualToValue,
    handleOpenDropdown,
    formAutocomplete: {
      ...form,
    },
  };
};
