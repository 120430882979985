import { stringify } from 'qs';

import { IPagination } from 'models';

import api from 'config/api';

import { IDocumentPayload, IDocumentResponse } from './types';

export default class DocumentService {
  static async getDocuments({ page, perPage, search }: IDocumentPayload) {
    const params = stringify({ page, perPage, search });

    const { data } = await api.get<
      IPagination<IDocumentResponse> | IDocumentResponse[]
    >(`/documents?${params}`);

    return data;
  }
}
