import { useQuery } from 'react-query';

import { PendingIssuesService, PendingIssuesQueryKey } from '../services';

interface ListPendingRequestProps {
  enabled?: boolean;
  operationId?: string;
  enterpriseId?: string;
  fromCustomerModal: boolean;
}

const useListPendingIssuesRequest = ({
  enabled,
  operationId,
  enterpriseId,
  fromCustomerModal,
}: ListPendingRequestProps) => {
  return useQuery(
    [PendingIssuesQueryKey.LIST_PENDING_ISSUES, operationId, enterpriseId],
    fromCustomerModal
      ? () => PendingIssuesService.listForConsultants({ enterpriseId })
      : () => PendingIssuesService.listForCustomers({ operationId }),
    { keepPreviousData: true, enabled },
  );
};

export { useListPendingIssuesRequest };
