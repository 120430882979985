import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IDefinePasswordPayload } from '../services';

export const definePasswordSchema = z
  .object({
    password: z
      .string()
      .trim()
      .nonempty('Senha é obrigatório')
      .regex(new RegExp('.*[A-Z].*'), 'Senha deve conter uma letra maiúscula')
      .regex(new RegExp('.*[0-9].*'), 'Senha deve ter pelo menos um número')
      .regex(
        new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'),
        'Senha deve conter pelo menos um caractere especial',
      )
      .min(8, 'Senha deve ter no mínimo 8 caracteres'),
    confirmPassword: z
      .string()
      .trim()
      .regex(new RegExp('.*[A-Z].*'), 'Senha deve conter uma letra maiúscula')
      .regex(new RegExp('.*[0-9].*'), 'Senha deve ter pelo menos um número')
      .regex(
        new RegExp('.*[`~<>?,./!@#$%^&*()\\-_+="\'|{}\\[\\];:\\\\].*'),
        'Senha deve conter pelo menos um caractere especial',
      )
      .min(8, 'Senha deve ter no mínimo 8 caracteres')
      .nonempty('Confirmação de senha é obrigatório'),
  })
  .refine(({ password, confirmPassword }) => password === confirmPassword, {
    message: 'Confirmação de senha incorreta',
    path: ['confirmPassword'],
  });

const defaultValues = {
  password: '',
  confirmPassword: '',
};

export const useDefinePasswordForm = () => {
  return useForm<IDefinePasswordPayload>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(definePasswordSchema),
  });
};
