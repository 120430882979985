import { RoutesProps } from 'routes/types';

import { useAuth } from 'config/auth/hooks';

import { useGetSidebarRoutes, useParsePermissions } from '.';

export const useAuthorizedRoutes = () => {
  const { sidebarRoutes } = useGetSidebarRoutes();
  const { userPermissions } = useAuth();
  const permissionsParsed = useParsePermissions(userPermissions);

  const routesCopy = JSON.parse(JSON.stringify(sidebarRoutes));

  function validatePermissions(routesParam: RoutesProps[]) {
    const authorizedRoutes: RoutesProps[] = [];

    routesParam.forEach(route => {
      const permissions = route.permissions;

      let allowedPermissions = true;

      if (permissions) {
        allowedPermissions = permissions.every(permission => {
          const { name } = permission;

          const hasPermission = permissionsParsed.some(up => up.name === name);

          return hasPermission;
        });
      }

      if (allowedPermissions && route.subs) {
        route.subs = validatePermissions(route.subs);
      }

      if (allowedPermissions && (!route.subs || route.subs.length > 0)) {
        authorizedRoutes.push(route);
      }
    });

    return authorizedRoutes;
  }

  const authorizedRoutes = userPermissions ? validatePermissions(routesCopy) : [];

  return { authorizedRoutes };
};
