import Cookies from 'js-cookie';

import { AuthService } from 'modules/signIn/services';

export const ACCESS_TOKEN_KEY = `${process.env.REACT_APP_APP_NAME}/@access-token`;
export const REFRESH_TOKEN_KEY = `${process.env.REACT_APP_APP_NAME}/@refresh-token`;

export const getAccessToken = () => Cookies.get(ACCESS_TOKEN_KEY);

export const getRefreshToken = () => Cookies.get(REFRESH_TOKEN_KEY);

export const setTokens = (accessToken: string, refreshToken: string) => {
  const inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000); // 15 minutes
  const inThirtyMinutes = new Date(new Date().getTime() + 30 * 60 * 1000); // 30 minutes

  Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
    secure: true,
    expires: inFifteenMinutes,
  });
  Cookies.set(REFRESH_TOKEN_KEY, refreshToken, {
    secure: true,
    expires: inThirtyMinutes,
  });
};

export const removeTokens = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  Cookies.remove(REFRESH_TOKEN_KEY);
};

export const isAuthenticated = () => {
  return getRefreshToken() !== undefined;
};

export const authWithRefreshToken = async (
  refreshToken: string,
): Promise<string> => {
  const data = await AuthService.authWithRefreshToken({
    refreshToken,
  });

  setTokens(data.accessToken, data.refreshToken);

  return data.accessToken;
};
