import styled from 'styled-components';

import { Box } from 'components';

const ClearFiltersWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 1px;
  padding: 2px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
`;

export { ClearFiltersWrapper };
