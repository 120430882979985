import { Box, Logo } from 'components';

import { ConsultantSignIn } from '../components';

export function SignInConsultantPage() {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <Logo />
      <ConsultantSignIn />
    </Box>
  );
}
