import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  IForm,
  IFormQuestion,
  IFormQuestionCondition,
  IFormRecord,
  QuestionType,
  RolesTypes,
} from 'models';

import {
  IQuestionForm,
  IInputQuestions,
  IOutputQuestions,
  IConditionQuestion,
  IVariable,
  IConditionTypeInput,
  IFormPayload,
  IFormQuestionPayload,
  IConditionsPayload,
} from '../services';
import { useFormStore } from '../store/store';
import {
  useGetFormQuestionRequest,
  useUpdateFormRequest,
  useDynamicFormForm,
  useUpdateFormQuestionRequest,
  useDynamicForm,
  DeltaExpressionPayload,
} from './';
import { useCreateFormQuestionRequest } from './useCreateFormQuestionRequest';

export const useEditDynamicForm = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    currentForm,
    questionsForm,
    setQuestionsForm,
    setOriginalsQuestionsForm,
    setCurrentForm,
  } = useFormStore();
  const form = useDynamicFormForm();

  const hasSysVar = questionsForm
    .flatMap(question => question?.conditions || [])
    .flatMap(condition => condition.conditionTypeInput?.deltaExpression || [])
    .flatMap(expression => (expression as DeltaExpressionPayload).ops || [])
    .some(op => op?.insert?.mention?.origin === 'sys');

  const {
    getAllConditionsTypeInput,
    getAllConditionsTypeMultipleChoice,
  } = useDynamicForm();

  useEffect(() => {
    form.reset({
      title: currentForm?.title,
      caption: currentForm?.caption,
      userPermission: currentForm?.userPermission?.id,
    });
  }, [currentForm]);

  function getDefaultExternFalse(conditions: IFormQuestionCondition[]) {
    if (!conditions || conditions.length === 0) return '';

    const questionDefaultExternFalse = conditions.find(
      condition => !condition?.optionConditional && condition?.default === true,
    );

    return questionDefaultExternFalse?.questionId;
  }

  function checkIsLast(conditions: IFormQuestionCondition[]) {
    if (!conditions || conditions.length === 0) return true;
    return false;
  }

  function getQuestionsCondition(
    question: IFormQuestion,
    questions: IFormQuestion[],
  ) {
    const conditions = question?.conditions;
    const questionType = question?.question?.type;
    const optionsMultipleChoice = question?.question?.answerOptions;
    const conditionsQuestion: IConditionQuestion[] = [];

    if (!conditions || conditions.length === 0) {
      return conditionsQuestion;
    }

    if (QuestionType[questionType] === QuestionType.MULTIPLE_CHOICE) {
      optionsMultipleChoice.map(option => {
        const conditionWithOption = conditions.filter(
          condition => condition?.optionConditional === option?.value,
        );

        if (!conditionWithOption || conditionWithOption.length === 0) return;

        let nextConditionFalse = '';
        let nextConditionFalseLabel = '';
        const variables: IVariable[] = [];

        conditionWithOption.map((conditionOption, index) => {
          const nextQuestion = questions?.find(
            question => question?.question?.id === conditionOption?.questionId,
          );

          // se nao tem um next question mas nao é a ultima pergunta
          if (!nextQuestion && conditionWithOption.length !== index) return;

          if (conditionOption?.default && conditionOption?.optionConditional) {
            // pegando default false de cada condition;
            nextConditionFalse = conditionOption?.questionId;
            nextConditionFalseLabel = nextQuestion?.question?.title ?? '';
            return;
          }

          variables.push({
            expression: conditionOption?.expression,
            nextQuestion: conditionOption?.questionId,
            nextQuestionLabel: nextQuestion?.question?.title ?? '',
            identifierVariable: variables.length + 1,
            deltaExpression: conditionOption?.deltaExpression,
          });
        });

        conditionsQuestion.push({
          condition: option.value,
          variables: variables,
          conditionTypeInput: {} as IConditionTypeInput,
          nextConditionFalse,
          nextConditionFalseLabel,
          openDropdown: false,
          showOptionFalse: nextConditionFalse.length > 0,
          identifierCondition: conditionsQuestion.length + 1,
        });
      });

      return conditionsQuestion;
    }

    // caso seja questio type text long, shot, number, archive
    conditions.map(condition => {
      if (condition?.default) return;

      const nextQuestion = questions?.find(
        question => question?.question?.id === condition?.questionId,
      );

      const conditionTypeInput: IConditionTypeInput = {
        identifierInput: conditionsQuestion.length + 1,
        expression: condition?.expression,
        nextQuestion: condition?.questionId,
        nextQuestionLabel: nextQuestion?.question.title ?? '',
        deltaExpression: condition.deltaExpression,
      };

      conditionsQuestion.push({
        condition: '',
        variables: [] as IVariable[],
        conditionTypeInput,
        openDropdown: false,
        showOptionFalse: false,
        identifierCondition: conditionsQuestion.length + 1,
      });
    });
    return conditionsQuestion;
  }

  const getFormQuestionsRequest = useGetFormQuestionRequest({
    onSuccess: async data => {
      const questionsForm: IQuestionForm[] = [];
      data.map(question => {
        questionsForm.push({
          id: question.id,
          formId: question.form.id,
          formQuestionId: question?.formQuestion?.id,
          question: {
            ...question?.question,
            systemValueMapping: question?.answerValueMapping,
          },
          numberQuestion: questionsForm.length + 1,
          openDropdown: false,
          inputQuestions: [] as IInputQuestions[],
          outputQuestions: [] as IOutputQuestions[],
          isFirstQuestion: question?.formQuestion === null ? true : false,
          isChild: question?.formQuestion === null ? false : true,
          conditions: getQuestionsCondition(question, data),
          isLastQuestion: checkIsLast(question?.conditions),
          nextQuestionFalse: getDefaultExternFalse(question?.conditions),
        });
      });
      setQuestionsForm(questionsForm);
      setOriginalsQuestionsForm(questionsForm);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar listar as perguntas do formulário');
    },
  });

  function handleOpenEditFormPage(form: IForm) {
    if (form?.id) {
      setCurrentForm({
        id: form.id,
        title: form.title,
        caption: form?.caption ?? '',
        user: form.user,
        userPermission: form.userPermission,
        questions: [] as IFormQuestion[],
        records: [] as IFormRecord[],
        active: form.active,
      });

      history.push(`/config-form/?edit=true`);

      getFormQuestionsRequest.mutate(form.id);
    }
  }

  const requestCreateFormQuestion = useCreateFormQuestionRequest({
    onSuccess: async data => {
      await createChildsQuestion(data, data.conditions);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar salvar a questão filha');
    },
  });

  async function createChild(formId: string, payload: IFormQuestionPayload) {
    await requestCreateFormQuestion.mutateAsync({
      formId,
      formQuestion: payload,
    });
  }

  const createChildsQuestion = async (
    questionFather: IFormQuestion,
    questionsChilds: IConditionsPayload[],
  ) => {
    await questionsChilds.reduce(async (previousPromise, condition) => {
      await previousPromise;
      const question = questionsForm.find(
        qF => qF.question?.id === condition?.questionId,
      );

      if (!question) return;

      if (question.hasOwnProperty('id') || question.hasOwnProperty('formId'))
        return; //ja existe

      const typeQuestion = question.question.type;

      const payloadChild: IFormQuestionPayload = {
        form: `${questionFather?.form}`,
        question: question?.question?.id,
        answerValueMapping: question?.question?.systemValueMapping?.id,
        formQuestion: questionFather.id ?? null,
        conditions:
          QuestionType[typeQuestion] === QuestionType.MULTIPLE_CHOICE
            ? await getAllConditionsTypeMultipleChoice(
                question?.conditions,
                question?.nextQuestionFalse,
              )
            : await getAllConditionsTypeInput(
                question?.conditions,
                question?.nextQuestionFalse,
              ),
      };

      await createChild(`${questionFather?.form}`, payloadChild);
    }, Promise.resolve());
  };

  const UpdateFormQuestionRequest = useUpdateFormQuestionRequest({
    onSuccess: async (data, payload) => {
      await createChildsQuestion(data, payload?.formQuestion?.conditions);
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar a pergunta');
    },
  });

  //async function createQuestionChild(formQuestionId: string) {}

  async function saveEditedFormQuestions() {
    const formId = questionsForm.find(item => item.formId)?.formId;
    const formQuestionId = questionsForm.find(item => item.id)?.id;

    questionsForm.map(async qF => {
      if (qF.numberQuestion === -1) return;
      const typeQuestion = qF.question.type;

      const payload: IFormQuestionPayload = {
        form: qF?.formId ?? formId ?? '',
        question: qF?.question?.id,
        answerValueMapping: qF?.question?.systemValueMapping?.id,
        formQuestion: qF.formQuestionId ?? null,
        conditions:
          QuestionType[typeQuestion] === QuestionType.MULTIPLE_CHOICE
            ? await getAllConditionsTypeMultipleChoice(
                qF?.conditions,
                qF?.nextQuestionFalse,
              )
            : await getAllConditionsTypeInput(
                qF?.conditions,
                qF?.nextQuestionFalse,
              ),
      };

      if (payload?.form.length === 0) return;

      await UpdateFormQuestionRequest.mutate({
        formId: qF?.formId ?? formId ?? '',
        formQuestionId: qF?.id ?? formQuestionId ?? '',
        formQuestion: payload,
      });
    });
  }

  const saveEditFormRequest = useUpdateFormRequest({
    onSuccess: () => {
      toast.success('Formulário editado com Sucesso!');
      history.push('/dynamic-form');
      queryClient.invalidateQueries('forms');
      saveEditedFormQuestions();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o formulário');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const firstQuestion = questionsForm.find(
      qF => qF.isFirstQuestion === true && qF.numberQuestion !== -1,
    );
    if (!firstQuestion) {
      toast.error('Primeira Questão nao encontrada!');
      return;
    }

    const domElement = document.getElementById('userPermission');
    const elementValue = domElement?.innerText || domElement?.textContent;
    if (hasSysVar && elementValue === RolesTypes.CONSULTANT) {
      toast.error(
        'Não é possível utilizar variáveis do sistema para formulários para consultores.',
      );
      return;
    }

    let correctLastQuestion = true;
    let questionSelected = '';

    questionsForm.map(qF => {
      if (qF.numberQuestion === -1) return;

      const nextFalseExtern = qF?.nextQuestionFalse;

      if (nextFalseExtern && nextFalseExtern.length > 0) return;

      if (!qF.isLastQuestion) {
        const conditions = qF?.conditions;

        const hasChild = conditions?.find(
          condition => condition.identifierCondition !== -1,
        );

        if (!conditions || !hasChild) {
          correctLastQuestion = false;
          questionSelected = qF?.question?.title;
          return;
        }
      }
    });

    if (!correctLastQuestion) {
      toast.error(
        `Indique pelo menos uma proxima pegunta para a questão: ${questionSelected} - Ou selecione a mesma como última`,
      );
      return;
    }

    const formEdited: IFormPayload = {
      ...data,
      active: currentForm.active,
      caption: data?.caption && data.caption?.length > 0 ? data.caption : null,
      user: currentForm?.user?.id,
    };
    saveEditFormRequest.mutate({ id: currentForm.id, form: formEdited });
  });

  return {
    handleOpenEditFormPage,
    checkIsLast,
    editForm: {
      isLoading: saveEditFormRequest.isLoading,
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseEditDynamicForm = ReturnType<typeof useEditDynamicForm>;
