import { Typography } from '@mui/material';

import {
  Dialog,
  ButtonContained,
  ButtonOutlined,
  SectionButtons,
  Box,
} from 'components';

import { useLogout } from 'modules/SignOut/hooks';

export default function LogoutDialog() {
  const { showDialog, setShowDialog, handleSignOut } = useLogout();
  return (
    <Dialog.Root open={showDialog} onClose={() => setShowDialog(false)}>
      <Dialog.Header title={'Sair'} />
      <Box minWidth={450} mb={7}>
        <Typography textAlign={'center'} mt={4}>
          Você tem certeza que deseja sair?
        </Typography>
      </Box>

      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={() => setShowDialog(false)} />
        <ButtonContained label="Confirmar" onClick={() => handleSignOut()} />
      </SectionButtons>
    </Dialog.Root>
  );
}
