import { useQuery } from 'react-query';

import DashboardService from '../services/services';

export const useDashboardEnterpriseRequest = () => {
  const { data, isLoading, refetch } = useQuery(
    ['dashboard_data'],
    () => DashboardService.getDashboardData(),
    { keepPreviousData: true },
  );
  return {
    data,
    isLoading,
    refetch,
  };
};
