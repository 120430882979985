import { IAct, IProcessAct, TypeProcessAct } from 'models';

import { IOrdination } from 'utils/ordination';

export enum ProcessActQueryKeys {
  FILTER_PROCESS_ACTS = 'filterProcessActs',
  ENTERPRISE_ACTS = 'enterpriseActs',
}

export interface ICurrentTabInfo {
  typeOp: TypeProcessAct;
  creditorName: string;
  enterpriseName?: string;
  responsibleOperation: string;
  responsibleId?: string;
  enterpriseId: string;
  creditorId?: string;
  operationId?: string;
  customerIsFinancing: boolean;
}

export enum IActTimelineFilterBy {
  'NAME_ACT' = 'actName',
  'OPERATOR' = 'operator',
  'RESPONSIBLE' = 'responsible',
  'STATUS' = 'status',
}
export enum IActTimelineFilterByLabel {
  'NAME_ACT' = 'Nome do ato',
  'OPERATOR' = 'Operador',
  'RESPONSIBLE' = 'Responsável pelo ato',
  'LAST_ACT' = 'Último ato',
  'STATUS' = 'Status',
}

export interface IActTimelineFilter {
  label: string;
  value: number;
  filterBy: IActTimelineFilterBy;
}

export interface IActTimelineFilterByOption {
  value: IActTimelineFilterBy;
  label: IActTimelineFilterByLabel;
}

export const DEFAULT_ACT_TIMELINE_FILTER_BY = {
  value: IActTimelineFilterBy.NAME_ACT,
  label: IActTimelineFilterByLabel.NAME_ACT,
};

export const actTimelineFilterByOptions: IActTimelineFilterByOption[] = [
  {
    value: IActTimelineFilterBy.NAME_ACT,
    label: IActTimelineFilterByLabel.NAME_ACT,
  },
  {
    value: IActTimelineFilterBy.OPERATOR,
    label: IActTimelineFilterByLabel.OPERATOR,
  },
  {
    value: IActTimelineFilterBy.RESPONSIBLE,
    label: IActTimelineFilterByLabel.RESPONSIBLE,
  },
  {
    value: IActTimelineFilterBy.STATUS,
    label: IActTimelineFilterByLabel.STATUS,
  },
];

export interface IFilterProcessActParams extends IOrdination<IProcessAct> {
  actName?: string;
  operator?: string;
  responsible?: string;
  type: TypeProcessAct;
  enterpriseId: string;
  operationId?: string;
  userType: string;
  userRole: string;
  isFinancing?: boolean;
}

export interface IActAllowedsParams extends IOrdination<IAct> {
  userType: string;
  userRole: string;
  isFinancing: string;
  type: TypeProcessAct;
}
