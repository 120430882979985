import { Box } from '@mui/material';

import { Dialog } from 'components';

interface Props {
  children: React.ReactNode;

  open: boolean;
  setOpen: (open: boolean) => void;
}

const ScrModal = ({ children, open, setOpen }: Props) => {
  return (
    <Dialog.Root open={open} onClose={() => setOpen(false)}>
      <Dialog.Header title="Scr" />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          gap={12}
          alignItems="center"
          paddingX={8}
          marginTop={4}
        >
          {children}
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { ScrModal };
