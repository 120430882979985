import { Chip } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Colors } from 'themes';

interface ChipOutlinedProps {
  customColor: Colors;
}

const ChipOutlinedWrapper = styled(Chip)<ChipOutlinedProps>`
  &.MuiChip-outlined {
    color: ${props => props.theme.colors[props.customColor]};
    border-color: ${props => props.theme.colors[props.customColor]};
    border-radius: ${props => props.theme.space[2]}px;

    & .MuiChip-deleteIcon {
      margin-right: 5px;
      color: ${props => rgba(props.theme.colors[props.customColor], 0.8)};

      :hover {
        color: ${props => props.theme.colors[props.customColor]};
      }
    }
  }

  &.MuiChip-clickable {
    :hover {
      border-color: ${props => rgba(props.theme.colors[props.customColor], 0.8)};
      background-color: ${props =>
        rgba(props.theme.colors[props.customColor], 0.05)};
    }
  }

  &.Mui-disabled {
    color: ${props => props.theme.colors['gray-300']};
    border-color: ${props => props.theme.colors['gray-100']};
    opacity: 1 !important;

    & .MuiChip-deleteIcon {
      margin-right: 5px;
      color: ${props => props.theme.colors['gray-300']};
    }
  }
`;

export { ChipOutlinedWrapper };
