import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IValidateParams } from 'models';

import { IUserEdit, IUserValidateParams } from 'modules/user/services';
import { UserService } from 'modules/user/services';

import { validateCPF } from 'utils/validateCPF';
import { validateDate } from 'utils/validateDate';

import { useConsultantsStore } from '../store/store';

export const useConsultantDialogForm = () => {
  const { currentConsultant } = useConsultantsStore();

  const validate = async (
    value: string,
    key: keyof IUserValidateParams,
  ): Promise<boolean> => {
    const params: IValidateParams<IUserValidateParams> = {
      value: value,
      key: key,
    };

    if (currentConsultant) {
      params['id'] = currentConsultant?.id;
    }

    if (value.length === 0) return true;

    return await UserService.validate(params);
  };

  const consultantSchema = z.object({
    name: z.string().trim().nonempty('Nome é obrigatório'),
    surname: z.string().trim().nonempty('Sobrenome é obrigatório'),
    cpf: z
      .string()
      .trim()
      .nonempty('CPF é obrigatório')
      .refine(cpf => {
        return validateCPF(cpf);
      }, 'CPF inválido'),
    birthDate: z
      .string()
      .trim()
      .nonempty('Data de nascimento é obrigatória')
      .refine(birthDate => {
        return validateDate(birthDate);
      }, 'Data inválida'),
    email: z
      .string()
      .trim()
      .nonempty('E-mail é obrigatório')
      .email('E-mail inválido')
      .refine(async value => await validate(value, 'email'), 'E-mail já utilizado'),
    active: z.boolean(),
  });

  const defaultValues = {
    email: '',
    name: '',
    surname: '',
    cpf: '',
    birthDate: '',
    active: true,
  };

  return useForm<IUserEdit>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: zodResolver(consultantSchema),
  });
};
