import { Tabs } from 'modules/boards/tabs';

import {
  ChangeStatsDialog,
  CreateActDialog,
  OperationConfirmationDialog,
  OperationDialogFilter,
  OperationSearchContainer,
  Operations,
} from '../components';

const OperationsPage = () => {
  return (
    <>
      <Tabs />
      <OperationConfirmationDialog />
      <OperationDialogFilter />
      <ChangeStatsDialog />
      <CreateActDialog />

      <OperationSearchContainer />
      <Operations />
    </>
  );
};

export { OperationsPage };
