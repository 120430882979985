import { create } from 'zustand';

import { IAct } from 'models';

import { DEFAULT_PAGINATION } from 'utils/helper';

type PageState = {
  search: string;
  page: number;
  perPage: number;
  limit: boolean;
  list: IAct[];
};

const pageInitialState: PageState = {
  search: '',
  page: DEFAULT_PAGINATION.page,
  perPage: DEFAULT_PAGINATION.perPage,
  limit: false,
  list: [] as IAct[],
};

type State = {
  openModalOut: boolean;

  // handle inative questions
  listSetInativeActs: string[];
  openConfirmInactivesDialog: boolean;

  currentAct: IAct;
  searchActType: string;
  openConfigActsDialog: boolean;
  permissionUserAnswer: string[];
  permissionUserView: string[];
  permissionClientView: string[];
  permissionClientIssue: string[];
  searchTriggerForm: string;
  presentDatabaseInformations: string[];
  notifyBefore: string[];
  notifyAfter: string[];
  productClient: string[];
  operation: string;
};

const initialState: State = {
  openModalOut: false,
  // list filters

  listSetInativeActs: [],
  openConfirmInactivesDialog: false,
  openConfigActsDialog: false,
  currentAct: {} as IAct,
  searchActType: '',
  permissionUserAnswer: [],
  permissionUserView: [],
  permissionClientView: [],
  permissionClientIssue: [],
  searchTriggerForm: '',
  presentDatabaseInformations: [],
  notifyBefore: [],
  notifyAfter: [],
  productClient: [],
  operation: '',
};

// Dialog Confirm Inactives (armazenando ids' pra inativar)

interface Actions {
  setOpenModalOut: (openModal: boolean) => void;

  setSearch: (search: string) => void;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setLimit: (limit: boolean) => void;
  setList: (list: IAct[]) => void;

  // form configAct
  setOpenConfigActsDialog: (openConfigActsDialog: boolean) => void;

  // handle inative questions
  setListInativeActs: (listSetInativeActs: string[]) => void;
  setOpenConfirmInactivesDialog: (open: boolean) => void;

  setCurrentAct: (currentAct?: IAct | undefined) => void;

  // handle autocompletes/selects
  setSearchActType: (actType: string) => void;
  setPermissionUserAnswer: (permission: string[]) => void;
  setPermissionUserView: (permission: string[]) => void;
  setPermissionClientView: (permission: string[]) => void;
  setPermissionClientIssue: (permission: string[]) => void;
  setSearchTriggerForm: (searchTriggerForm: string) => void;
  setPresentDatabaseInformations: (informations: string[]) => void;
  setNotifyBefore: (notifyBefore: string[]) => void;
  setNotifyAfter: (notifyAfter: string[]) => void;
  setProductClient: (productClient: string[]) => void;
  setOperation: (operation: string) => void;

  clearActsStore: () => void;
}

export const useActsStore = create<State & PageState & Actions>(set => ({
  ...initialState,
  ...pageInitialState,

  setOpenModalOut: openModalOut => set({ openModalOut }),

  setSearch: search => set({ search }),

  setPage: page => set({ page }),

  setPerPage: perPage => set({ perPage }),

  setLimit: limit => set({ limit }),

  setList: list => set({ list }),

  setListInativeActs: listSetInativeActs => set({ listSetInativeActs }),

  setOpenConfirmInactivesDialog: (open: boolean) =>
    set({ openConfirmInactivesDialog: open }),

  setOpenConfigActsDialog: openConfigActsDialog => set({ openConfigActsDialog }),

  setCurrentAct: currentAct => set({ currentAct }),

  setSearchActType: searchActType => set({ searchActType }),

  setPermissionUserAnswer: permissionUserAnswer => set({ permissionUserAnswer }),

  setPermissionUserView: permissionUserView => set({ permissionUserView }),

  setPermissionClientView: permissionClientView => set({ permissionClientView }),

  setPermissionClientIssue: permissionClientIssue => set({ permissionClientIssue }),

  setSearchTriggerForm: searchTriggerForm => set({ searchTriggerForm }),

  setPresentDatabaseInformations: presentDatabaseInformations =>
    set({ presentDatabaseInformations }),

  setNotifyBefore: notifyBefore => set({ notifyBefore }),
  setNotifyAfter: notifyAfter => set({ notifyAfter }),

  setProductClient: productClient => set({ productClient }),

  setOperation: operation => set({ operation }),

  clearActsStore: () => {
    set(initialState);
  },
}));
