import { TextField } from '@mui/material';
import { useState } from 'react';

import {
  Box,
  ButtonContained,
  ButtonOutlined,
  Dialog,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { OperationDestineStatus } from 'models';

import { useOperationActions } from '../hooks';

export const ChangeStatsDialog = () => {
  const [status, setStatus] = useState<OperationDestineStatus>(); // TODO - Retirar ao definir status

  const {
    openChangeStatsDialog,
    handleCloseChangeStatsDialog,
    handleApplyStatus,
  } = useOperationActions();
  return (
    <Dialog.Root
      open={openChangeStatsDialog}
      onClose={handleCloseChangeStatsDialog}
    >
      <Dialog.Header title={'Mudar Status'} />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyItems="center"
          style={{ gap: '4em' }}
          width={500}
          p={4}
        >
          <AutocompleteWrapper
            fullWidth
            multiple={false}
            options={Object.values(OperationDestineStatus)}
            renderInput={params => (
              <TextField {...params} label="Selecione o Status" />
            )}
            value={status} // TODO - Modificar ao definir status
            onChange={(e, value) => {
              setStatus(value as OperationDestineStatus);
            }} // TODO - Modificar ao definir status
          />
        </Box>
      </Dialog.Content>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseChangeStatsDialog} />
        <ButtonContained
          label="Salvar"
          disabled={!status}
          onClick={() => status && handleApplyStatus(status)}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
