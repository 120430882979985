import { Grid, TextField, Box } from '@mui/material';

import {
  ButtonContained,
  ButtonOutlined,
  Dialog,
  ChipContained,
  ContainerFilters,
  MessageFilter,
  SectionButtons, //Scrollbar,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';

import { OptionsValues } from 'utils/helper';

import { useCustomerSearch } from '../hooks';
import { useCustomerFilterForm } from '../hooks/useCustomerFilterForm';
import { ICustomerBoardFilterByOption } from '../services';
import { useCustomerManagementStore } from '../store/store';

const CustomerDialogFilter = () => {
  const {
    openFilterDialog,
    setOpenFilterDialog,
    setSearchFilterOption,
  } = useCustomerManagementStore();
  const { handleChangeSearch } = useCustomerSearch(500, setSearchFilterOption);
  const {
    filterBy,
    currentFilter,
    optionsFilter,
    customerBoardfilterOptions,
    isFiltering,
    clearFilter,
    handleChangesFilter,
    isOptionEqualToValue,
    setFilterBy,
    clearSearchOption,
    handleRemoveFilter,
  } = useCustomerFilterForm();

  return (
    <Dialog.Root open={openFilterDialog} onClose={() => setOpenFilterDialog(false)}>
      <Dialog.Header title="Configurar Filtro Cliente" />
      <Box minWidth={650} maxWidth={600} alignItems={'center'}>
        <Dialog.Content>
          <ContainerFilters>
            <Grid container maxWidth={650} p={4} pb={3} pt={2} spacing={2}>
              <MessageFilter />
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={customerBoardfilterOptions}
                  renderInput={params => (
                    <TextField {...params} label="Filtrar por" />
                  )}
                  value={filterBy}
                  onChange={(e, value) =>
                    setFilterBy(value as ICustomerBoardFilterByOption)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AutocompleteWrapper
                  options={optionsFilter}
                  multiple={true}
                  loadingText="Buscando opções..."
                  noOptionsText="Nenhuma opção foi encontrada"
                  renderTags={() => null}
                  onClose={clearSearchOption}
                  filterSelectedOptions
                  onReset={clearFilter}
                  value={currentFilter}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Selecione uma opção"
                      onChange={e => handleChangeSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) => {
                    handleChangesFilter(value as OptionsValues[]);
                  }}
                  isOptionEqualToValue={isOptionEqualToValue}
                  disableClearable
                />
              </Grid>
            </Grid>
          </ContainerFilters>

          <Grid>
            <Box
              marginLeft={4}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 1,
              }}
              maxHeight={240}
              maxWidth={560}
              overflow={'auto'}
              pb={1}
            >
              {currentFilter.map((option, index) => (
                <ChipContained
                  key={`${option.label}-${index}`}
                  label={option.label}
                  onDelete={() => handleRemoveFilter(option)}
                />
              ))}
            </Box>
          </Grid>
        </Dialog.Content>

        <SectionButtons>
          <ButtonOutlined
            label="Limpar filtros"
            onClick={clearFilter}
            disabled={!isFiltering}
          />
          <ButtonContained
            label="Fechar"
            onClick={() => setOpenFilterDialog(false)}
          />
        </SectionButtons>
      </Box>
    </Dialog.Root>
  );
};

export { CustomerDialogFilter };
