import { IEnterprise } from './enterpise.types';

export interface ICompanyBigData {
  cnpj: string;
  socialReason: string;
  fantasyName: string;
  legalNatureCode: string;
  legalNatureDescription: string;
  openingDate: string;
  registrationSituation: string;
  socialCapital: number;
  rfEmail: string;
  phones: string[];
  address: KleverAddress;
  mainCnae: KleverCnae;
  cnaes: Cnae[];
  judicialProcess: KleverJudicialProcess;
  partners: KleverCompanyPartner[];
  personPartners: KleverPerson[];
  bigData: KleverBigData;
  enterprise: IEnterprise;
}

export interface KleverBigData {
  company: KleverCompany;
  economicGroupInformation: KleverEconomicGroupTO[];
  geolocationInformation: null | KleverGeolocationTO;
  historicalInformation: KleverHistoricalInformationTO;
  protestInformation: KleverProtestInformation[];
}

export interface KleverCompany {
  activityLevel: string;
  address: KleverAddress;
  affiliates: AffiliateElement[];
  age: number;
  aircraft: KleverAircraft[];
  anpFuelStation: null | KleverAnpFuelStation;
  anpSupplyPoints: KleverAnpSupplyPoint[];
  antt: null | KleverAntt;
  anvisa: null | KleverAnvisa;
  bankOperations: KleverBankOperation[];
  beneficiaries: KleverCompanyBeneficiary[];
  bndesLastConsultDate: string;
  cadastur: null | KleverCadastur;
  cafir: null | KleverCafir;
  ceis: KleverCeis[];
  cepims: KleverCepim[];
  cfcs: KleverCfc[];
  cnaes: Cnae[];
  cneps: KleverCnep[];
  cnjCnias: KleverCnjCnia[];
  cnpj: string;
  companiesRelated: KleverCompanyRelated[];
  companyClosingPropensity: string;
  companyEmecCourses: KleverCompanyEmecCourse[];
  companySize: string;
  covid19Individual: string;
  covid19Segment: string;
  created: string;
  criCraDebentures: KleverCriCraDebenture[];
  crsfns: KleverCrsfn[];
  cvm: null | KleverCvm;
  cvmRegistrationData: KleverCvmParticipant[];
  dateRegistrationSituation: string;
  dateSpecialSituation: null | string;
  debitMte: null | KleverDebitMte;
  debitPgfnDau: null | KleverDebitPgfnDau;
  deliveryPropensity: null | string;
  documents: KleverDocumentMetadata[];
  domains: KleverDomain[];
  ecommercePropensity: null | string;
  employeeAnalystCount: number;
  employeeBaseCount: number;
  employeeBuyerCount: number;
  employeeDoctorCount: number;
  employeeEngineerCount: number;
  employeeGrowths: KleverEmployeeGrowth[];
  employeeLawyerCount: number;
  employeeManagerCount: number;
  employeeOtherCount: number;
  employeePdvCount: number;
  employees: KleverCompanyEmployee[];
  employeeSellerCount: number;
  employeeSupervisorCount: number;
  employeeTeacherCount: number;
  environmentalLicenses: KleverEnvironmentalLicense[];
  estimatedBilling: string;
  estimatedBillingGroup: null | string;
  exEmployees: ExEmployee[];
  exports: KleverCompanyExport[];
  fantasyName: null | string;
  financialActivity: null | KleverFinancialActivity;
  financialIndicators: KleverFinancialIndicator[];
  foreignCommerce: null | KleverForeignCommerce;
  franchiseName: null | string;
  grossBilling: number;
  groupMultinational: boolean;
  hasAccountantContact: boolean;
  hasDivergentQSA: boolean;
  hasFranchiseIndicative: boolean;
  healthEstablishments: KleverHealthEstablishment[];
  heavyVehicleInfo: null | KleverHeavyVehicleInfo;
  ibamaCnd: null | KleverIbamaCnd;
  ibge: null | KleverIbge;
  ibgeLastConsultDate: string;
  id: string;
  imports: KleverCompanyExport[];
  inpiBrands: KleverInpiBrand[];
  inpiPatents: KleverInpiPatent[];
  inpiSoftwares: KleverInpiSoftware[];
  internationalLists: KleverInternationalList[];
  judicialProcess: null | KleverJudicialProcess;
  lastUpdate: string;
  legalNatureCode: string;
  legalNatureDescription: string;
  mainCnae: KleverCnae;
  managementContract: null | KleverManagementContract;
  matrix: boolean;
  matrixInfo: null | MatrixInfoObject;
  multinational: boolean;
  openCapital: null | KleverOpenCapital;
  openingDate: string;
  originCountry: null | string;
  partners: KleverCompanyPartner[];
  passiveIISS: boolean;
  pat: null | KleverPat;
  personPartners: KleverPerson[];
  phones: string[];
  procon: null | KleverProcon;
  properties: KleverProperty[];
  quantityActiveBranches: number;
  quantityEmployee: number;
  quantityExEmployee: number;
  registrationSituation: string;
  registrationSituationReason: null | string;
  remoteWorkingCapacity: null | string;
  rfEmail: string;
  schools: KleverSchool[];
  sidraLastConsultDate: null | string;
  simpleNational: null | KleverSimpleNational;
  sintegraInscriptions: SintegraInscription[];
  socialCapital: number;
  socialReason: string;
  specialSituation: null | string;
  suframa: null | KleverSuframa;
  taxHealth: null | KleverTaxHealth;
  workMtes: KleverWorkMte[];
}

export interface KleverEconomicGroupTO {
  cnpj: string;
  openingDate: string;
  partnerInformation: KleverPartnerInfoDataTO[];
  socialReason: string;
}

export interface KleverGeolocationTO {
  address: string;
  cnpj: string;
  created: string;
  id: string;
  latitude: number;
  longitude: number;
  mapImages: KleverGeolocationImageTO[];
  modified: string;
}

export interface KleverHistoricalInformationTO {
  address: KleverAddressEvolutionDataTO[];
  age: number;
  cnaeHistories: KleverCnaeHistoryTO[];
  cnaeTotalChanges: number;
  cnpj: string;
  created: string;
  currentName: string;
  headQuarterTotalChanges: number;
  id: string;
  lastDateChanged: string;
  lastLegalNatureChanged: string;
  lastNameChanged: string;
  nameHistories: KleverNameHistoryTO[];
  nameTotalChanges: number;
  relationships: KleverCurrentRelationshipTO[];
  taxRegimeHistories: KleverTaxRegimeHistoryTO[];
  taxRegimes: KleverValueAndStartAndEndDateTO[];
  taxRegimeTotalChanges: number;
  totalChanges: number;
  tradeNames: KleverValueAndStartAndEndDateTO[];
}

export interface KleverProtestInformation {
  analysis: null;
  created: string;
  document: string;
  id: string;
  protestRegistries: string[];
  total: number;
}

export interface KleverValueAndStartAndEndDateTO {
  endDate: string;
  startDate: string;
  value: string;
}

export interface KleverTaxRegimeHistoryTO {
  changeDate: string;
  taxRegime: string;
}

export interface KleverCurrentRelationshipTO {
  cpf: string;
  creationDate: string;
  description: string;
  lastUpdateDate: string;
  name: string;
  relatedEntityName: string;
  relatedEntityTaxIdNumber: string;
  relationshipEndDate: string;
  relationshipStartDate: string;
}

export interface KleverNameHistoryTO {
  changeDate: string;
  name: string;
  officialName: string;
  tradeName: string;
}

export interface KleverCnaeHistoryTO {
  activity: string;
  changeDate: string;
  code: string;
  docNumber: string;
  economicActivityDivision: string;
  economicActivityGroup: string;
  mainEconomicActivity: boolean;
}

export interface KleverAddressEvolutionDataTO {
  active?: boolean;
  addressMain?: string;
  city?: string;
  complement?: string;
  country?: string;
  createdDate?: string;
  lastUpdateDate?: string;
  latitude?: string;
  longitude?: string;
  neighborhood?: string;
  number?: string;
  priority?: boolean;
  state?: string;
  zipCode?: string;
}

export interface KleverPartnerInfoDataTO {
  cpf: string;
  name: string;
  participation: number;
}

export interface KleverGeolocationImageTO {
  type: KleverMapImageType;
  url: string;
}

export enum KleverMapImageType {
  Map = 'MAP',
  Satellite = 'SATELLITE',
  StreetView = 'STREET_VIEW',
  StreetViewHeading = 'STREET_VIEW_HEADING',
}

export interface KleverAddress {
  address1: string;
  address2: string;
  borderMunicipality: string;
  buildingType: null | string;
  codeCountry: null | string;
  codeMunicipality: null | string;
  collectiveBuilding: boolean;
  country: null | string;
  deliveryRestriction: boolean;
  formattedAddress: string;
  latestAddress: boolean;
  latitude: number;
  longitude: number;
  microRegion: string;
  mregion: string;
  municipality: null | string;
  neighborhood: null | string;
  number: string;
  originalNeighborhood: string;
  precision: string;
  region: string;
  registryUf: null | string;
  residentialAddress: boolean;
  rfPhones: string[];
  uf: string;
  zip: string;
}

export interface AffiliateElement {
  cnpj?: string;
  municipality?: string;
  openingDate?: string;
  registrationSituation?: string;
  socialReason?: string;
  uf?: string;
}

export interface KleverAircraft {
  maker: string;
  model: string;
  operatorName: string;
  ownerName: string;
  productionYear: number;
  registration: string;
  situation: string;
  statusRAB: string;
}

export interface KleverAnpFuelStation {
  currentFlag: string;
  equipments: KleverAnpFuelStationEquipment[];
  flags: KleverAnpFuelStationFlag[];
  postType: string;
  stationAuthorization: string;
  totalSpikes: number;
  totalTank: number;
}

export interface KleverAnpFuelStationEquipment {
  beaks?: number;
  fuel?: string;
  tanking?: number;
}

export interface KleverAnpFuelStationFlag {
  flag?: string;
  startFlagDate?: string;
}

export interface KleverAnpSupplyPoint {
  municipality: string;
  totalCapacityTanks: number;
  totalNumberTanks: number;
  uf: string;
}

export interface KleverAntt {
  category: string;
  emissionDate: string;
  expirationDate: string;
  municipality: string;
  rntrcNumber: string;
  situation: string;
  uf: string;
}

export interface KleverAnvisa {
  legalRepresentatives: string[];
  processes: KleverAnvisaProcess[];
  technicalRepresentatives: string[];
}

export interface KleverAnvisaProcess {
  authorizationDate?: string;
  authorizationProcess?: string;
  description?: string;
  processNumber?: string;
}

export interface KleverBankOperation {
  amortizationPeriod: number;
  contractedDate: string;
  contractedValue: number;
  financialAgent: string;
  financialCost: string;
  gracePeriod: number;
  product: string;
  status: string;
  tax: number;
  type: string;
  uf: string;
}

export interface KleverCompanyBeneficiary {
  dead?: boolean;
  document?: string;
  grade?: number;
  gradeQsa?: number;
  name?: string;
  participation?: number;
  participationQsa?: number;
}

export interface KleverCadastur {
  certificateNumber: string;
  idioms: string[];
  numberOfVehicles: number;
  registered: boolean;
  segments: string[];
  services: string[];
  site: string;
  situation: string;
  uf: string;
}

export interface KleverCafir {
  propertiesRural: KleverPropertyRural[];
}

export interface KleverPropertyRural {
  area: number;
  condominium: string;
  municipality: string;
  name: string;
  nirf: string;
}

export interface KleverCeis {
  endSanctionDate: string;
  informationDate: string;
  informationEntity: string;
  initSanctionDate: string;
  legalSubstantiation: string;
  organComplement: string;
  processNumber: string;
  sanction: string;
  sanctioningEntity: string;
  ufSanctioningEntity: string;
}

export interface KleverCepim {
  contract: string;
  endContractDate: string;
  grantorEntity: string;
  impediment: string;
  valueReleased: number;
}

export interface KleverCfc {
  crc: string;
  record: string;
  situation: string;
  type: string;
}

export interface Cnae {
  businessActivity: null | string;
  code: string;
  description: string;
  id: string;
  physicalProductions: KleverPhysicalProduction[];
  sector: null | string;
}

export interface KleverPhysicalProduction {
  codeDescription?: string;
  date: string;
  fixedBaseIndexWithoutSeasonalAdjustment: KleverPhysicalProductionVariable;
  indexAccumulatedLast12Months: KleverPhysicalProductionVariable;
  monthlyIndex: KleverPhysicalProductionVariable;
  monthlyPercentageChange: KleverPhysicalProductionVariable;
  percentageChangeAccumulatedLast12Months: KleverPhysicalProductionVariable;
  percentageChangeAccumulatedYear: KleverPhysicalProductionVariable;
  territorialLevel: string;
  yearToDateIndex: KleverPhysicalProductionVariable;
}

export interface KleverPhysicalProductionVariable {
  measureUnit?: string;
  name?: string;
  value?: string;
}

export interface KleverCnep {
  endSanctionDate: string;
  initSanctionDate: string;
  penaltyValue: number;
  processNumber: string;
  sanction: string;
  sanctioningEntity: string;
  ufSanctioningEntity: string;
}

export interface KleverCnjCnia {
  descriptionEntity: string;
  processNumber: string;
  registrationDate: string;
  relatedIssues: string[];
  sphere: string;
  uf: string;
  value: number;
}

export interface KleverCompanyRelated {
  cnpj: string;
  descriptionCnae: string;
  municipality: string;
  openingDate: string;
  socialReason: string;
  uf: string;
}

export interface KleverCompanyEmecCourse {
  academicOrganization: string;
  administrativeCategory: string;
  codeLes: string;
  descriptionLes: string;
  municipality: string;
  situation: string;
  uf: string;
}

export interface KleverCriCraDebenture {
  code: string;
  debtorIssuer: string;
  dueDate: string;
  id: number;
  indicativeValue: number;
  insuranceSector: string;
  issueDate: string;
  puParDebenture: number;
  remuneration: string;
  seriesIssue: string;
  seriesVolumeOnIssueDate: number;
  type: string;
}

export interface KleverCrsfn {
  agreedNumber: string;
  part: string;
  processNumber: string;
  resourceNumber: string;
  resourceType: string;
}

export interface KleverCvm {
  balanceYear: number;
  grossProfit: number;
  grossRevenue: number;
  liquidAssets: number;
  liquidBilling: number;
  liquidProfit: number;
  totalActive: number;
}

export interface KleverCvmParticipant {
  activitySector: string;
  code: string;
  liquidAssets: number;
  registrationDate: string;
  registrationType: string;
  responsibleName: string;
  responsibleType: string;
  situationDate: string;
  situationRecord: string;
}

export interface KleverDebitMte {
  certificateType: string;
  code: string;
  debitSituation: string;
  emissionDate: string;
  processes: KleverDebitMteProcess[];
}

export interface KleverDebitMteProcess {
  infringementCapitulation?: string;
  infringementCategory?: string;
  number?: string;
  situation?: string;
}

export interface KleverDebitPgfnDau {
  debitPgfns: KleverDebitPgfn[];
  totalDebits: number;
}

export interface KleverDebitPgfn {
  debit: number;
  inscriptionNumber: string;
  nature: string;
}

export interface KleverDocumentMetadata {
  created: string;
  ext: string;
  name: string;
  type: Type;
  url: string;
}

export enum Type {
  ArchivedDocuments = 'ARCHIVED_DOCUMENTS',
  Balance = 'BALANCE',
  Balancete = 'BALANCETE',
  BankDebts = 'BANK_DEBTS',
  BillingStatement = 'BILLING_STATEMENT',
  Certificate = 'CERTIFICATE',
  Cnd = 'CND',
  CreditInformation = 'CREDIT_INFORMATION',
  FiscalNote = 'FISCAL_NOTE',
  Generic = 'GENERIC',
  Irpf = 'IRPF',
  RegistrationForm = 'REGISTRATION_FORM',
  SimplifiedCertification = 'SIMPLIFIED_CERTIFICATION',
  Sped = 'SPED',
}

export interface KleverDomain {
  createdDate: string;
  expirationDate: string;
  modificationDate: string;
  name: string;
  responsible: string;
}

export interface KleverEmployeeGrowth {
  employeeGrowth: number;
  growth: number;
  year: number;
}

export interface KleverCompanyEmployee {
  admissionDate: string;
  birthDate: string;
  cpf: string;
  name: string;
  resignationYear: string;
}

export interface KleverEnvironmentalLicense {
  descriptionTypology: string;
  emitData: string;
  municipality: string;
  processNumber: string;
  situation: string;
  type: string;
  typologyNumber: string;
  uf: string;
  updateData: string;
}

export interface ExEmployee {
  admissionDate: string;
  birthDate: string;
  cpf: string;
  name: string;
  resignationYear: string;
}

export interface KleverCompanyExport {
  value: string;
  year: number;
}

export interface KleverFinancialActivity {
  enablementDate: string;
  enablementNumber: number;
  enablementSituation: string;
  queryDate: string;
  segment: string;
}

export interface KleverFinancialIndicator {
  increase: number;
  margin: number;
}

export interface KleverForeignCommerce {
  authorizedOperations: string;
  enabled: boolean;
  enabledSituation: string;
  modality: string;
  situationDate: string;
  submodality: string;
}

export interface KleverHealthEstablishment {
  lastUpdate: string;
  quantityBeds: number;
  quantityProfessionals: number;
  unitType: string;
}

export interface KleverHeavyVehicleInfo {
  between2And5: number;
  between5And10: number;
  groupBetween2And5: number;
  groupBetween5And10: number;
  groupOver10: number;
  groupUpTo1: number;
  heavyVehicles: number;
  heavyVehiclesGroup: number;
  over10: number;
  upto1: number;
  vehicles: KleverHeavyVehicle[];
}

export interface KleverHeavyVehicle {
  antt: boolean;
  carPlate: string;
  fuel: string;
  model: string;
  productionYear: number;
  renavam: string;
  type: string;
  uf: string;
}

export interface KleverIbamaCnd {
  certificateNumber: string;
  emitDate: string;
  situation: string;
  validDate: string;
}

export interface KleverIbge {
  economicStatistics: KleverEconomicStatistics;
  geographicStatistics: KleverGeographicStatistics;
  workPerformanceStatistics: KleverWorkPerformanceStatistics;
}

export interface KleverEconomicStatistics {
  idhm: number;
  idhmYear: number;
  percentageRevenueSources: number;
  percentageRevenueSourcesYear: number;
  pib: number;
  pibYear: number;
  totalExpenses: number;
  totalExpensesYear: number;
  totalRevenue: number;
  totalRevenueYear: number;
}

export interface KleverGeographicStatistics {
  demographicDensity: number;
  demographicDensityYear: number;
  estimatedPopulation: number;
  estimatedPopulationLastCensus: number;
  estimatedPopulationLastCensusYear: number;
  estimatedPopulationYear: number;
}

export interface KleverWorkPerformanceStatistics {
  averageSalary: number;
  averageSalaryYear: number;
  busyPeople: number;
  busyPeopleYear: number;
  occupiedPopulation: number;
  occupiedPopulationYear: number;
  populationIncomeMonthlyNominal: number;
  populationIncomeMonthlyNominalYear: number;
}

export interface KleverInpiBrand {
  brand: string;
  classBrand: string;
  depositDate: string;
  processNumber: string;
  situation: string;
}

export interface KleverInpiPatent {
  concessionDate: string;
  depositDate: string;
  depositor: string;
  inventors: string[];
  processNumber: string;
  procurator: string;
  publicationDate: string;
  title: string;
}

export interface KleverInpiSoftware {
  authors: string[];
  depositDate: string;
  processNumber: string;
  procurator: string;
  title: string;
}

export interface KleverInternationalList {
  name: string;
  queryDate: string;
}

export interface KleverJudicialProcess {
  judicialProcessQuantities: KleverJudicialProcessQuantity[];
  totalActiveValue: number;
  totalOthersValue: number;
  totalPassiveValue: number;
  totalValue: number;
}

export interface KleverJudicialProcessQuantity {
  quantityActive: number;
  quantityActivePart: number;
  quantityOthers: number;
  quantityPassivePart: number;
  quantityTotal: number;
  type: string;
}

export interface KleverCnae {
  businessActivity: string;
  code: string;
  description: string;
  id: string;
  physicalProductions: KleverPhysicalProduction[];
  sector: string;
}

export interface KleverManagementContract {
  contracts: KleverContract[];
  modalityContracts: KleverModalityContract[];
  quantity: number;
  totalValue: number;
}

export interface KleverContract {
  contractNumber?: string;
  endDate?: string;
  finalValue?: number;
  initDate?: string;
  monthsValidity?: number;
  organ?: string;
  sphere?: string;
  uf?: string;
}

export interface KleverModalityContract {
  quantity?: number;
  type?: string;
}

export interface MatrixInfoObject {
  cnpj: string;
  municipality: string;
  openingDate: string;
  registrationSituation: string;
  socialReason: string;
  uf: string;
}

export interface KleverOpenCapital {
  complementLogradouroInvestmentDirector: string;
  emailInvestmentDirector: string;
  logradouroInvestmentDirector: string;
  mainActivity: string;
  municipalityInvestmentDirector: string;
  nameInvestmentDirector: string;
  negotiationCode: string;
  numberInvestmentDirector: string;
  openCapitalActionPositions: KleverOpenCapitalActionPosition[];
  openCapitalPatrimonialBalances: KleverOpenCapitalPatrimonialBalance[];
  openCapitalPenalties: KleverOpenCapitalPenalty[];
  openCapitalResultDemonstrations: KleverOpenCapitalResultDemonstration[];
  phoneInvestmentDirector: string;
  sectorialClassification: string;
  site: string;
  tradingFloorCode: string;
  ufInvestmentDirector: string;
  zipInvestmentDirector: string;
}

export interface KleverOpenCapitalActionPosition {
  document?: string;
  orderActionsValue?: number;
  preferentialActionsValue?: number;
  totalValue?: number;
}

export interface KleverOpenCapitalPatrimonialBalance {
  activeTotal?: number;
  activeValue?: number;
  liquidPatrimony?: number;
  liquidPatrimonyController?: number;
  year?: number;
}

export interface KleverOpenCapitalPenalty {
  crated?: string;
  reason?: string;
}

export interface KleverOpenCapitalResultDemonstration {
  benefitPeriod?: number;
  benefitPeriodController?: number;
  recipeSale?: number;
  resultBrute?: number;
  resultFinancial?: number;
  resultLiquidOperations?: number;
  resultPatrimonialEquivalence?: number;
  year?: number;
}

export interface KleverCompanyPartner {
  criminalProcess?: string[];
  dead?: boolean;
  document?: string;
  levelPreparation?: string;
  levelPreparationRF?: null | string;
  name?: string;
  originCountry?: null | string;
  participation?: number;
  participationRF?: number;
  participationSocialCapital?: number;
  participationSocialCapitalRF?: number;
  qualification?: string;
  qualificationRF?: null | string;
}

export interface KleverPat {
  benefitedEmployees: number;
  exerciseDate: string;
  inscription: string;
  mealProvides: KleverPatMealProvided[];
  modalities: KleverPatModality[];
  registrationSituation: string;
  responsibleCpf: string;
  responsibleEmail: string;
  responsibleName: string;
}

export interface KleverPatMealProvided {
  quantity: number;
  type: string;
}

export interface KleverPatModality {
  benefitedEmployees: number;
  mode: string;
  over5Sm: number;
  providerCnpj: string;
  providerSocialReason: string;
  to5Sm: number;
}

export interface KleverPerson {
  age?: number;
  birthDate?: string;
  cafir?: null | KleverCafir;
  cns?: null | string;
  corporatesParticipation?: KleverCorporateParticipation[];
  cpf?: string;
  dead?: boolean;
  deadConfirmation?: boolean;
  deadDate?: number;
  debitMte?: KleverDebitMte;
  debitPgfnDau?: null | KleverDebitPgfnDau;
  debitPgfnDauValue?: null | string;
  deficiency?: boolean;
  deficiencyType?: null | string;
  disabilitiesBacens?: KleverDisabilitiesBacen[];
  educationLevel?: null | string;
  email?: string;
  fatherName?: null | string;
  historicalCriminal?: KleverHistoricalCriminal;
  historicalFunctional?: KleverHistoricalFunctional[];
  id?: string;
  inscriptionCpfDate?: string;
  irpf?: KleverIrpf[];
  judicialProcess?: null | KleverJudicialProcess;
  mainAddress?: KleverAddress;
  motherCpf?: string;
  motherName?: string;
  name?: string;
  nis?: null | string;
  originCountry?: null | string;
  otherAddresses?: KleverAddress[];
  phones?: string[];
  professionCbo?: null | string;
  professionNeoway?: string;
  properties?: KleverProperty[];
  protestLastConsultDate?: null | string;
  publicAgent?: boolean;
  quantityQsaUnique?: number;
  registerSituation?: null | string;
  relationships?: KleverRelationship[];
  sex?: string;
  situation?: null | string;
  situationCpf?: string;
  socialInscription?: string;
}

export interface KleverCorporateParticipation {
  businessActivityCnae: string;
  cnpj: string;
  descriptionCnae: string;
  entryDate: string;
  estimatedBilling: string;
  estimatedBillingGroup: string;
  levelPreparation: string;
  levelPreparationRF: string;
  municipality: string;
  openingDate: string;
  participation: number;
  participationRF: number;
  participationSocialCapital: number;
  participationSocialCapitalRF: number;
  qualification: string;
  qualificationRF: string;
  situation: string;
  socialCapital: string;
  socialReason: string;
  uf: string;
}

export interface KleverDisabilitiesBacen {
  duration: number;
  penalty: string;
  penaltyPeriodDate: string;
  publicationDate: string;
}

export interface KleverHistoricalCriminal {
  consultationDate: string;
  protocol: string;
  situation: string;
  status: string;
}

export interface KleverHistoricalFunctional {
  admissionDate: string;
  cnpj: string;
  dismissedDate: string;
  months: number;
  socialReason: string;
}

export interface KleverIrpf {
  agency: null | string;
  availabilityDate: null | string;
  bank: null | string;
  lot: null | string;
  statementStatus: string;
  yearExercise: number;
}

export interface KleverProperty {
  buildingData: string;
  builtArea: number;
  complement: string;
  evaluationValue: number;
  groundArea: number;
  municipality: string;
  neighborhood: string;
  neighborhoodNumber: string;
  registerUf: string;
  registryNumber: string;
  street: string;
  uf: string;
  zip: string;
}

export interface KleverRelationship {
  cpf: string;
  description: string;
  name: string;
}

export interface KleverProcon {
  groupPenaltyValue: string;
  name: string;
  proconGroups: KleverProconGroup[];
  proconProcesses: KleverProconProcesses[];
  totalPenaltyValue: number;
  updateDate: string;
}

export interface KleverProconGroup {
  cnpj: string;
  totalPenaltyValue: number;
}

export interface KleverProconProcesses {
  penaltyValue: number;
  processNumber: string;
  status: string;
}

export interface KleverSchool {
  category: string;
  functioning: string;
  link: string;
  name: string;
  totalEnrollment: number;
}

export interface KleverSimpleNational {
  simei: boolean;
  simeiDate: null | string;
  simple: boolean;
  simpleDate: null | string;
  simpleIrregular: boolean;
}

export interface SintegraInscription {
  email?: null | string;
  phone?: null;
  regime?: null;
  registrationNumber?: string;
  registrationSituation?: string;
  registrationSituationDate?: string;
  uf?: string;
}

export interface KleverSuframa {
  expirationDate: string;
  icms: string;
  ipi: string;
  pisCofins: string;
  registrationNumber: string;
  registrationSituation: string;
}

export interface KleverTaxHealth {
  cnds: KleverCnds[];
  taxHealth: string;
}

export interface KleverCnds {
  certificateNumber: null | string;
  document: KleverDocumentMetadata;
  emissionDate: null | string;
  emitterName: string;
  expirationDate: null | string;
  situation: string;
}

export interface KleverWorkMte {
  complement: string;
  fiscalActionYear: number;
  municipality: string;
  neighborhood: string;
  provenanceDecisionDate: string;
  quantityWorkers: number;
  uf: string;
}
