import { Colors } from 'themes';

import { LoaderWrapper } from './styles';

interface LoaderProps {
  color?: Colors;
  size?: number;
}

export function Loader({ color = 'blue', size = 20 }: LoaderProps) {
  return <LoaderWrapper customColor={color} size={size} />;
}
