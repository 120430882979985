import { useDashboardCreditorsRequest } from './useDashboardCreditoresRequest';

export const useDashboardCreditor = () => {
  const { data, isLoading, refetch } = useDashboardCreditorsRequest();

  const operationsNoData = !data?.operationsData;

  return {
    data,
    isLoading,
    operationsNoData,
    refetch,
  };
};
