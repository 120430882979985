import { SearchBar as SearchBarComponent } from 'components';

import { SearchBarWrapper } from './styles';

interface SearchBarProps {
  placeholder: string;
  onSearchChange: (value: string) => void;
}

export function SearchBar({ placeholder, onSearchChange }: SearchBarProps) {
  return (
    <SearchBarWrapper>
      <SearchBarComponent
        placeholder={placeholder}
        onSearchChange={value => onSearchChange(value)}
      />
    </SearchBarWrapper>
  );
}
