import { useMutation, UseMutationOptions } from 'react-query';

import { IQuestionAttachment } from 'models';

import { IApiAxiosError } from 'config/api/types';

import {
  IQuestionAttachmentPayload,
  QuestionAttachmentService,
} from 'modules/dynamicForm/questionAttachment';

const useCreateQuestionAttachmentRequest = (
  options?: UseMutationOptions<
    IQuestionAttachment,
    IApiAxiosError,
    IQuestionAttachmentPayload
  >,
) => {
  return useMutation(
    'createQuestionAttachment',
    QuestionAttachmentService.create,
    options,
  );
};

export { useCreateQuestionAttachmentRequest };
