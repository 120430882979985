import { SpeedDialAction } from '@mui/material';
import { darken } from 'polished';
import styled from 'styled-components';

const NextWrapper = styled(SpeedDialAction)`
  &.MuiFab-root {
    background-color: ${({ theme }) => theme.colors.blue};

    :hover {
      background-color: ${({ theme }) => darken(0.08, theme.colors.blue)};
    }
  }
`;

export { NextWrapper };
