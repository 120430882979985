import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import themes from 'themes';

import {
  Typography as TypographyComponents,
  Box,
  PasswordTextField,
  TextField,
} from 'components';
import { UserTypes } from 'models';

import { UseSignIn } from '../hooks';

export interface SignInFormProps {
  origin: UserTypes;
  form: UseSignIn['form'];
  isSigningIn?: boolean;
  handleCancel: () => void;
}

export const Form = ({
  origin,
  form,
  isSigningIn,
  handleCancel,
}: SignInFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmitForm,
  } = form;

  return (
    <Box as="form">
      <Grid container spacing={2} padding={2}>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Email"
            control={control}
            type="text"
            error={errors.email}
            sx={{
              '& .MuiOutlinedInput-root': {
                zIndex: 0,
                '& input': {
                  zIndex: 1,
                },
                '& fieldset': {
                  backgroundColor: '#e0e0e0',
                  border: 'none',
                  borderRadius: '8px',
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordTextField
            name="password"
            label="Senha"
            control={control}
            error={errors.password}
            sx={{
              '& .MuiOutlinedInput-root': {
                zIndex: 0,
                '& input': {
                  zIndex: 1,
                },
                '& fieldset': {
                  backgroundColor: '#e0e0e0',
                  border: 'none',
                  borderRadius: '8px',
                },
              },
            }}
          />
        </Grid>
      </Grid>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX="1em"
        style={{
          gap: '0.5em',
        }}
      >
        {origin !== UserTypes.CONSULTANT && (
          <Typography fontFamily={themes.fonts.body}>
            Sou novo aqui,{' '}
            <TypographyComponents
              as={RouterLink}
              to={`${
                origin === UserTypes.CREDITOR ? 'credor' : origin.toLowerCase()
              }/signup`}
              fontWeight={700}
              color="#000"
              fontFamily={themes.fonts.body}
            >
              criar conta
            </TypographyComponents>
          </Typography>
        )}
        <TypographyComponents
          as={RouterLink}
          to="/forgot-password"
          fontWeight={700}
          color="#000"
          fontFamily={themes.fonts.body}
        >
          Esqueci minha senha
        </TypographyComponents>
      </Box>

      <Box
        marginTop="2em"
        padding="1em"
        display="flex"
        alignItems="center"
        style={{
          gap: '2em',
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={handleCancel}
          loading={isSigningIn}
          fullWidth
          sx={{
            backgroundColor: 'white',
            color: 'black',
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,

            '&:hover': {
              backgroundColor: '#f0f0f0',
            },
          }}
        >
          Cancelar
        </LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={handleSubmitForm(origin)}
          loading={isSigningIn}
          fullWidth
          sx={{
            backgroundColor: themes.colors.text.primary,
            textTransform: 'none',
            borderRadius: '8px',
            paddingY: '0.8em',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
            fontFamily: themes.fonts.body,
          }}
        >
          Entrar
        </LoadingButton>
      </Box>
    </Box>
  );
};
