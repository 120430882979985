import { Box } from '@mui/material';

interface SectionButtonsProps {
  children: React.ReactNode;
  padding?: number;
  maxHeight?: number;
  paddingRight?: number;
  gap?: number;
}

function SectionButtons({
  children,
  padding = 1.6,
  maxHeight = 56,
  paddingRight = 1.6,
  gap = 2,
}: SectionButtonsProps) {
  return (
    <Box
      boxShadow={5}
      display={'flex'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      p={padding}
      gap={gap}
      maxHeight={maxHeight}
      paddingRight={paddingRight}
    >
      {children}
    </Box>
  );
}

export default SectionButtons;
