import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IUser, UserTypes } from 'models';
import { IPagination } from 'models/pagination/types';

import { UserService } from 'modules/user/services';

import { useConsultantsStore } from '../store/store';

export const useFindConsultants = () => {
  const {
    page,
    perPage,
    setPage,
    setLimit,
    limit,
    search,
    currentFilter,
  } = useConsultantsStore();

  const { data, isLoading, refetch } = useQuery(
    ['consultants', page, search, currentFilter],
    () =>
      UserService.listConsultants({
        page,
        perPage,
        search,
        userTypes: [UserTypes.CONSULTANT],
      }) as Promise<IPagination<IUser>>,
    { keepPreviousData: true },
  );
  const noData = !data?.data || data.data.length === 0;
  const showPagination = !noData && data?.totalPages && data.totalPages > 1;
  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
    }
  }, [data]);
  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
    noData,
    showPagination,
  };
};
