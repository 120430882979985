import { Paper, Typography } from '@mui/material';
import themes from 'themes';

import { Box, Logo } from 'components';

import CodeForm from '../components/CodeForm';
import EmailForm from '../components/EmailForm';
import NewPasswordForm from '../components/NewPasswordForm';
import { useForgotPasswordStore } from '../store/store';

function ForgotPasswordPage() {
  const { step } = useForgotPasswordStore();

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginTop={6}
    >
      <Logo />
      <Paper
        elevation={16}
        sx={{
          marginY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '2em',
          backgroundColor: '#f0f0f0',
          borderRadius: '11px',
          padding: '3em',
          boxShadow:
            '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
          width: 532,
        }}
      >
        <Box display="flex" flexDirection="column" style={{ gap: '1em' }}>
          <Typography
            fontFamily={themes.fonts.body}
            variant="h4"
            fontSize={23}
            textAlign="center"
            fontWeight={700}
            color={themes.colors.blue}
          >
            {step !== 3 ? 'Esqueci minha senha' : 'Defina sua senha'}
          </Typography>
          <Typography
            variant="body1"
            textAlign="center"
            fontFamily={themes.fonts.body}
          >
            {step === 1 && 'Digite seu email'}
            {step === 2 && 'Digite o código enviado para o seu email'}
            {step === 3 && 'Digite a nova senha'}
          </Typography>
        </Box>
        {step === 1 && <EmailForm />}
        {step === 2 && <CodeForm />}
        {step === 3 && <NewPasswordForm />}
      </Paper>
    </Box>
  );
}

export { ForgotPasswordPage };
