import { Box, Typography } from '@mui/material';
import themes from 'themes';

import { FormActions, ResumeContainer } from '../components';

const FormResumePage = () => {
  return (
    <Box padding={1} height={'95%'}>
      <Box
        style={{
          backgroundColor: themes.colors['gray-700'],
          color: themes.colors.white,
        }}
        padding="10px 15px"
      >
        <Typography>Formulário</Typography>
      </Box>

      <Box ml={5} mt={3}>
        <Typography fontSize={18}>Resumo das Respostas</Typography>
      </Box>

      <ResumeContainer />

      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        position={'absolute'}
        width={'92%'}
        height={'5%'}
        bottom={10}
      >
        <FormActions />
      </Box>
    </Box>
  );
};

export { FormResumePage };
