import { useQuery } from 'react-query';

import { IQuestion } from 'models';

import { QuestionService } from 'modules/dynamicForm/question/services/services';

import { OptionsValuesString } from 'utils/helper';

export const useListActiveQuestions = () => {
  const { data, isLoading, refetch } = useQuery(
    ['listActiveQuestions'],
    () => QuestionService.list({ active: true }) as Promise<IQuestion[]>,
    { keepPreviousData: true },
  );

  const questionsOptions: OptionsValuesString[] =
    data?.map(question => ({
      value: question.id,
      label: `${question.title}`,
    })) || [];

  return {
    questionsOptions,
    dataAllQuestions: data,
    isLoading,
    refetch,
  };
};
