export const Logo = () => {
  return (
    <img
      src="/assets/ExFin-logo.png"
      alt="ExFin Logo"
      width="100%"
      height="auto"
      style={{ maxWidth: '235px' }}
    />
  );
};
