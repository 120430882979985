import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import {
  UserTypes,
  UserTypeHandleAct,
  ActStatus,
  IAct,
  ActType,
  Operation,
  ClientDestineStatus,
  OperationDestineStatus,
  ProductClient,
} from 'models';

import { useAuth } from 'config/auth/hooks';

import { UserTypesPayload } from 'modules/user';

import { enumToArray } from 'utils/enumToArray';
import { OptionsValuesString } from 'utils/helper';

import {
  ActTypePayload,
  IActForm,
  IActPayload,
  UserTypeHandleActPayload,
} from '../services';
import { useActsStore } from '../store/store';
import {
  useCreateActRequest,
  useConfigActForm,
  useListAllForms,
  useListAllVariables,
  useUpdateActRequest,
  defaultValuesActs,
  useListAllActs,
} from './';

export const useConfigAct = () => {
  const queryClient = useQueryClient();
  const form = useConfigActForm();
  const user = useAuth();
  const { formOptions } = useListAllForms({ active: true });
  const { variables } = useListAllVariables({});
  const { actOptions } = useListAllActs({});
  const ACTS_TYPES = enumToArray(ActType);
  const USER_TYPE_HANDLE_ACT = enumToArray(UserTypeHandleAct);
  const ACTS_STATUS = enumToArray(ActStatus);
  const CLIENT_STATUS = enumToArray(ClientDestineStatus);
  const OPERATION_STATUS = enumToArray(OperationDestineStatus);
  const PRODUCTS_CLIENT = enumToArray(ProductClient);
  const OPERATIONS_TYPES = enumToArray(Operation);
  let USER_TYPES = enumToArray(UserTypes);
  USER_TYPES = USER_TYPES.filter(userType => userType.value !== 'CONSULTANT');

  const linkRouteLogin = process.env.REACT_APP_URL;

  const triggerFormSwitch = form.watch('triggerForm');
  const showInfoSwitch = form.watch('showInfo');
  const showDbInfoSwitch = form.watch('showDbInfo');
  const generateActSwitch = form.watch('generateAct');
  const productClientSwitch = form.watch('defineProductClient');
  const operationSwitch = form.watch('defineOp');
  const showScheduleLinkSwitch = form.watch('showScheduleLink');
  const showDeadlineSwitch = form.watch('showDeadline');
  const triggerNotificationSwitch = form.watch('triggerNotification');
  const statusClientSwitch = form.watch('changeStatusClient');
  const statusOpSwitch = form.watch('changeStatusOp');
  const sendEmailSwitch = form.watch('sendEmail');

  const notificationDeadlines = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '5' },
    { value: 4, label: '7' },
    { value: 5, label: '10' },
    { value: 6, label: '15' },
    { value: 7, label: '20' },
    { value: 8, label: '30' },
  ];

  const {
    permissionUserView,
    permissionUserAnswer,
    openConfigActsDialog,
    currentAct,
    openModalOut,
    setSearchActType,
    setPermissionUserAnswer,
    setPermissionUserView,
    setPermissionClientView,
    setPermissionClientIssue,
    setSearchTriggerForm,
    setPresentDatabaseInformations,
    setNotifyAfter,
    setNotifyBefore,
    setProductClient,
    setOperation,
    setOpenModalOut,
    setOpenConfigActsDialog,
    clearActsStore,
    setCurrentAct,
  } = useActsStore();

  const clientViewAndAnswer = form.watch('clientViewAndAnswer') ?? [''];
  const clientEmit = form.watch('clientEmit') ?? [''];
  const userViewAndAnswer = form.watch('userViewAndAnswer') ?? [''];
  const userEmit = form.watch('userEmit') ?? [''];

  // logica de validação -> consultor obrigatorio, client que emite deve obrigatoriamente poder ver/responder;
  useEffect(() => {
    let hasChange = false;
    if (!clientEmit.includes('Consultor')) {
      clientEmit.push('Consultor');
      form.setValue('clientEmit', clientEmit);
      return;
    }
    for (const client of clientEmit) {
      if (clientViewAndAnswer.indexOf(client) === -1) {
        hasChange = true;
        clientViewAndAnswer.push(client);
      }
    }
    if (hasChange) {
      form.setValue('clientViewAndAnswer', clientViewAndAnswer);
    }
  }, [clientEmit, clientViewAndAnswer]);

  // logica de validação -> user que emite deve obrigatoriamente poder ver/responder;
  useEffect(() => {
    let hasChange = false;
    for (const user of userEmit) {
      if (userViewAndAnswer.indexOf(user) === -1) {
        hasChange = true;
        userViewAndAnswer.push(user);
      }
    }
    if (hasChange) {
      form.setValue('userViewAndAnswer', userViewAndAnswer);
    }
  }, [userEmit, userViewAndAnswer]);

  useEffect(() => {
    if (currentAct?.id && currentAct?.id.length > 0) {
      setCurrentActDataForm();
      return;
    }
    form.reset(defaultValuesActs);
  }, [currentAct]);

  const setCurrentActDataForm = () => {
    const formName =
      formOptions.find(option => option.value === currentAct?.configs?.formId)
        ?.label ?? '';
    const actName =
      actOptions.find(option => option.value === currentAct?.configs?.actId)
        ?.label ?? '';

    const dbInfos: string[] = [];
    currentAct?.configs?.dbInfo?.map(info => {
      const findedInfo =
        variables.find(option => option.value === info)?.label ?? '';

      if (findedInfo) dbInfos.push(findedInfo);
    });

    const actForm: IActForm = {
      active: currentAct.active,
      name: currentAct.name,
      description: currentAct?.description ?? '',
      type: ActType[currentAct.type],
      clientViewAndAnswer: getEnumArrayUserType(currentAct.clientViewAndAnswer),
      clientEmit: getEnumArrayUserType(currentAct.clientEmit),
      userViewAndAnswer: getEnumArrayUserTypeHandleAct(
        currentAct.userViewAndAnswer,
      ),
      userEmit: getEnumArrayUserTypeHandleAct(currentAct.userEmit),
      ...currentAct.configs,
      triggerForm: currentAct?.configs?.triggerForm,
      formId: formName,
      generateAct: getValueSwitch(actName),
      actId: actName,
      info: currentAct.configs?.info ?? '',
      clientTypeInfo: UserTypes[currentAct.configs.clientTypeInfo ?? ''],
      dbInfo: dbInfos,
      operation: currentAct?.configs?.operation ?? '',
      productClient: currentAct?.configs?.productClient ?? [],
      scheduleLink: currentAct?.configs?.scheduleLink ?? '',
      deadline: currentAct?.configs?.deadline ?? '',
      triggerNotification:
        getValueSwitch([...(currentAct?.configs?.notifyAfter ?? [])]) ||
        getValueSwitch([...(currentAct?.configs?.notifyBefore ?? [])]),
      notifyAfter: [...(currentAct?.configs?.notifyAfter ?? [])],
      notifyBefore: [...(currentAct?.configs?.notifyBefore ?? [])],
      changeStatusClient: getValueSwitch(currentAct.configs.newStatusClient ?? ''),
      newStatusClient: currentAct?.configs?.newStatusClient ?? '',
      changeStatusOp: getValueSwitch(currentAct.configs.newStatusOp ?? ''),
      newStatusOp: currentAct?.configs?.newStatusOp ?? '',
      emailTitle: currentAct?.configs?.emailTitle ?? '',
      emailBody: currentAct?.configs?.emailBody ?? '',
    };

    form.reset(actForm);
  };

  const handleOpenConfigActDialog = async (data: IAct) => {
    if (data?.id) {
      setCurrentAct(data);
      setOpenConfigActsDialog(true);
      return;
    }

    setOpenConfigActsDialog(true);
  };

  const handleCloseDialog = () => {
    clearActsStore();
    setCurrentAct(undefined);
    setOpenConfigActsDialog(false);
  };

  const requestCreateAct = useCreateActRequest({
    onSuccess: () => {
      toast.success('Ato criado com sucesso');
      queryClient.invalidateQueries('acts');
      handleCloseDialog();
    },
    onError: error => {
      if (error.response?.data.detail === 'This name is already in use.') {
        toast.error('Este nome já esta sendo utilizado por outro ato');
        return;
      }
      toast.error('Houve um erro ao tentar criar o ato');
    },
  });

  const requestUpdateAct = useUpdateActRequest({
    onSuccess: () => {
      toast.success('Ato Editado com sucesso');
      queryClient.invalidateQueries('acts');
      handleCloseDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o ato');
    },
  });

  const getValueSwitch = (valueField: string | string[]) => {
    if (!valueField) return false;
    if (valueField.length > 0) return true;
    return false;
  };

  const getEnumArrayUserType = (data: string[]) => {
    const newData: string[] = [];

    data.map(item => {
      newData.push(UserTypes[item]);
    });

    return newData;
  };

  const getEnumArrayUserTypeHandleAct = (data: string[]) => {
    const newData: string[] = [];

    data.map(item => {
      newData.push(UserTypeHandleAct[item]);
    });

    return newData;
  };

  function findIdsByLabels(array1: string[], array2: OptionsValuesString[]) {
    const idsWithSameLabel: string[] = [];

    array2.forEach(objeto => {
      if (array1.includes(objeto.label)) {
        idsWithSameLabel.push(objeto.value);
      }
    });

    return idsWithSameLabel;
  }

  const handleValidations = async (data: IActForm) => {
    const invalidAct =
      data.generateAct && (!data?.actId || data?.actId?.length <= 1);

    if (invalidAct) {
      toast.error('Gerar ato selecionado, escolha o tipo de ato corretamente!');
      return false;
    }

    if (data.triggerNotification && !data.showDeadline) {
      toast.error('Disparar notificações selecionado, insira um prazo para o ato!');
      return false;
    }

    const invalidTriggerNotification =
      data.triggerNotification &&
      data.notifyAfter?.length === 0 &&
      data.notifyBefore?.length === 0;

    if (invalidTriggerNotification) {
      toast.error(
        'Disparar notificações selecionado, escolha os prazos corretamente!',
      );
      return false;
    }

    const invalidStatusClient =
      data.changeStatusClient &&
      (!data.newStatusClient || data.newStatusClient.length <= 1);

    if (invalidStatusClient) {
      toast.error(
        'Mudar status do cliente selecionado, escolha o status corretamente!',
      );
      return false;
    }

    const invalidStatusOp =
      data.changeStatusOp && (!data.newStatusOp || data.newStatusOp.length <= 1);

    if (invalidStatusOp) {
      toast.error(
        'Mudar status de operação selecionado, escolha o status corretamente!',
      );
      return false;
    }

    return true;
  };

  // valida se foi selecionado um valor porem switch deixado como false;
  const validateStringFields = (valueSwitch: boolean, valueField?: string) => {
    if (!valueSwitch) {
      return null;
    }

    if (!valueField) return null;

    return valueField;
  };

  const validateStringArrayFields = (
    valueSwitch: boolean,
    valueField?: string[],
  ) => {
    if (!valueSwitch) {
      return null;
    }

    if (!valueField) return null;

    return valueField;
  };

  const getEnumArrayUserTypePayload = (data: string[]) => {
    const newData: string[] = [];

    data.map(item => {
      newData.push(UserTypesPayload[item]);
    });

    return newData;
  };

  const getEnumArrayUserTypeHandleActPayload = (data: string[]) => {
    const newData: string[] = [];

    data.map(item => {
      newData.push(UserTypeHandleActPayload[item]);
    });

    return newData;
  };

  const handleUpdateAct = (act: IActPayload) => {
    act.id = currentAct?.id;
    act.createdAt = currentAct?.createdAt;
    act.updatedAt = currentAct?.updatedAt;
    act.configs.createdAt = currentAct?.configs?.createdAt;
    act.configs.updatedAt = currentAct?.configs.updatedAt;
    act.configs.id = currentAct?.configs?.id;

    requestUpdateAct.mutate({
      actId: currentAct?.id ?? '',
      configActId: currentAct.configs?.id ?? '',
      body: act,
    });
  };

  const handleSubmitForm = form.handleSubmit(async data => {
    const resultValidations = await handleValidations(data);
    if (!resultValidations) return;

    const formId = formOptions.find(option => option.label === data.formId)?.value;
    const variablesIds = findIdsByLabels(data?.dbInfo ?? [], variables);
    const actId = actOptions.find(option => option.label === data.actId)?.value;

    const clientTypeInfo = UserTypesPayload[data?.clientTypeInfo ?? ''];

    const act: IActPayload = {
      active: data.active,
      name: data.name,
      description: data?.description?.length === 0 ? null : data?.description,
      type: ActTypePayload[data.type],
      clientViewAndAnswer: getEnumArrayUserTypePayload(data.clientViewAndAnswer),
      clientEmit: getEnumArrayUserTypePayload(data.clientEmit),
      userViewAndAnswer: getEnumArrayUserTypeHandleActPayload(
        data.userViewAndAnswer,
      ),
      userEmit: getEnumArrayUserTypeHandleActPayload(data.userEmit),
      configs: {
        triggerForm: data.triggerForm,
        formId: validateStringFields(triggerFormSwitch, formId ?? ''),
        showInfo: data.showInfo,
        info: validateStringFields(showInfoSwitch, data?.info ?? ''),
        showDbInfo: data?.showDbInfo,
        clientTypeInfo: validateStringFields(showDbInfoSwitch, clientTypeInfo),
        dbInfo: validateStringArrayFields(showDbInfoSwitch, variablesIds ?? []),
        textInputAnswer: data.textInputAnswer,
        actId: validateStringFields(generateActSwitch, actId ?? ''),
        defineProductClient: data.defineProductClient,
        productClient: validateStringArrayFields(
          productClientSwitch,
          data?.productClient,
        ),
        createOp: data.createOp,
        defineOp: data.defineOp,
        operation: validateStringFields(operationSwitch, data?.operation ?? ''),
        defineValueOp: data.defineValueOp,
        showScheduleLink: data.showScheduleLink,
        scheduleLink: validateStringFields(
          showScheduleLinkSwitch,
          data?.scheduleLink ?? '',
        ),
        showDeadline: data.showDeadline,
        deadline: validateStringFields(showDeadlineSwitch, data?.deadline ?? ''),
        notifyBefore: validateStringArrayFields(
          triggerNotificationSwitch,
          data?.notifyBefore ?? [],
        ),
        notifyAfter: validateStringArrayFields(
          triggerNotificationSwitch,
          data?.notifyAfter ?? [],
        ),
        newStatusClient: validateStringFields(
          statusClientSwitch,
          data?.newStatusClient ?? '',
        ),
        newStatusOp: validateStringFields(statusOpSwitch, data?.newStatusOp ?? ''),
        allowCharge: data.allowCharge,
        triggerDocSign: data.triggerDocSign,
        allowAttach: data.allowAttach,
        allowAnswerAttach: data.allowAnswerAttach,
        sendEmail: data.sendEmail,
        emailTitle: validateStringFields(sendEmailSwitch, data?.emailTitle ?? ''),
        emailBody: validateStringFields(sendEmailSwitch, data?.emailBody ?? ''),
        negotiationEnd: data.negotiationEnd,
        closureAct: data.closureAct,
      },
    };

    if (currentAct?.id) {
      handleUpdateAct(act);
      return;
    }

    requestCreateAct.mutate(act);
  });

  return {
    ACTS_TYPES,
    USER_TYPES,
    USER_TYPE_HANDLE_ACT,
    ACTS_STATUS,
    CLIENT_STATUS,
    OPERATION_STATUS,
    PRODUCTS_CLIENT,
    OPERATIONS_TYPES,
    variables,
    currentAct,
    form: {
      handleSubmitForm,
      ...form,
    },
    user,
    actOptions: actOptions.filter(option => option.value !== currentAct?.id),
    linkRouteLogin,
    formOptions,
    permissionUserAnswer,
    permissionUserView,
    notificationDeadlines,
    openConfigActsDialog,
    openModalOut,
    setSearchActType,
    setPermissionUserAnswer,
    setPermissionUserView,
    setPermissionClientView,
    setPermissionClientIssue,
    setSearchTriggerForm,
    setPresentDatabaseInformations,
    setNotifyAfter,
    setNotifyBefore,
    setProductClient,
    setOperation,
    setOpenModalOut,
    handleOpenConfigActDialog,
    handleCloseDialog,
  };
};

export type UseConfigAct = ReturnType<typeof useConfigAct>;
