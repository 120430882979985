import { Grid } from '@mui/material';

import { CompanyResponsePefinTO, CompanyResponseRefinTO } from 'models';

import { SerasaModalItem } from '../../SerasaModalItem';

interface Props {
  data: CompanyResponsePefinTO | CompanyResponseRefinTO;
}

const CompanyPefinRefin = ({ data }: Props) => {
  return (
    <Grid
      item
      xs={12}
      lg={6}
      key={`${data.contractId}-${data.legalNature}-${data.creditorName}`}
    >
      <SerasaModalItem
        title="Valor"
        value={data.amount.toLocaleString('pt-BR', {
          currency: 'BRL',
          style: 'currency',
          minimumFractionDigits: 2,
        })}
      />
      <SerasaModalItem title="Principal" value={data.principal ? 'Sim' : 'Não'} />
      <SerasaModalItem title="Id do Contrato" value={data.contractId} />
      <SerasaModalItem title="Unidade Federativa" value={data.federalUnit} />
      <SerasaModalItem title="Natureza Jurídica" value={data.legalNature} />
      <SerasaModalItem
        title="Nome da instituição financeira"
        value={data.creditorName}
      />
      <SerasaModalItem title="Id Natureza Jurídica" value={data.legalNatureId} />
      <SerasaModalItem
        title="Data da Ocorrência"
        value={new Date(data.occurrenceDate).toLocaleDateString('pt-br')}
      />
    </Grid>
  );
};

export { CompanyPefinRefin };
