import { Operation, OperationDestineStatus } from 'models';

import { IOperationAppliedFilter } from './services';

export const OperationFilterOptions = [
  {
    label: 'Instituição Financeira',
    value: 'credor',
  },
  {
    label: 'Empresa',
    value: 'empresa',
  },
  {
    label: 'Responsável',
    value: 'responsavel',
  },
  {
    label: 'Tipo de operação',
    value: 'tipo de operação',
  },
  {
    label: 'Último Ato',
    value: 'ultimo ato',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'Ativo',
    value: 'ativo',
  },
  {
    label: 'Inativo',
    value: 'inativo',
  },
];

export const activeOptions = [
  { label: 'Ativo', value: true },
  { label: 'Inativo', value: false },
];

export const statusOptions = Object.values(OperationDestineStatus).map(status => ({
  label: status,
  value: status,
}));

export const operationTypeOptions = Object.values(Operation).map(type => ({
  label: type,
  value: type,
}));

export const useFindActiveOptions = () => ({
  activeOptions,
  isLoading: false,
});

type enterpriseType = 'empresa' | 'credor';

type QueryFilter = {
  enterpriseType: enterpriseType[];
  active: boolean[];
};

export const formatOperationFilters = (
  filter: IOperationAppliedFilter[],
): QueryFilter => {
  const queryFilter: QueryFilter = {
    active: [],
    enterpriseType: [],
  };

  filter.forEach(item => {
    switch (item.value) {
      case 'ativo':
        queryFilter.active.push(true);
        break;
      case 'inativo':
        queryFilter.active.push(false);
        break;
      case 'credor':
        queryFilter.enterpriseType.push('credor');
        break;
      case 'empresa':
        queryFilter.enterpriseType.push('empresa');
        break;
      default:
        break;
    }
  });

  return queryFilter;
};
