import create from 'zustand';

interface Store {
  showDialog: boolean;
  setShowDialog: (showDialog: boolean) => void;
}

const useSignOutStore = create<Store>(set => ({
  showDialog: false,
  setShowDialog: showDialog => set({ showDialog }),
}));

export { useSignOutStore };
