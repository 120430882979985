export type DocumentTypes =
  | 'PASSPORT'
  | 'SELFIE_WITH_PASSPORT'
  | 'CPF_RG_HAB'
  | 'IRS'
  | 'PAY_SLIP'
  | 'WORK_CONTRACT'
  | 'ADDRESS_PROOF';

export interface File {
  id: string;
  url: string;
  name: string;
  size: number;
  createdAt?: string;
  updatedAt?: string;
}

export interface Document {
  id?: string;
  type: DocumentTypes;
  file?: File;
  createdAt?: string;
  updatedAt?: string;
}

export enum EClickSignAttachmentType {
  SIGN = 'SIGN',
  ORIGINAL = 'ORIGINAL',
}

export enum EClickSignAttachmentStatus {
  DEADLINE = 'DEADLINE',
  DRAFT = 'DRAFT',
  REFUSED = 'REFUSED',
  SIGNED = 'SIGNED',
}

export enum EClickSignStatusToDisplay {
  DEADLINE = 'Prazo expirado',
  DRAFT = 'Aguardando assinatura',
  REFUSED = 'Recusado',
  SIGNED = 'Assinado',
}

export interface IClicksignDocument {
  id?: string;
  documentId: string;
  status: EClickSignAttachmentStatus;
  type: EClickSignAttachmentType;
  createdAt?: string;
  updatedAt?: string;
}
