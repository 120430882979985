import { Button } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Colors } from 'themes';

interface OutlinedProps {
  customColor: Colors;
  loading: boolean;
}

const OutlinedWrapper = styled(Button)<OutlinedProps>`
  &.MuiButton-outlined {
    position: relative;
    text-transform: none;
    color: ${props =>
      rgba(props.theme.colors[props.customColor], props.loading ? 0 : 1)};
    border: ${props => `1px solid ${props.theme.colors[props.customColor]}`};

    :hover {
      color: ${props =>
        rgba(props.theme.colors[props.customColor], props.loading ? 0 : 1)};
      background-color: ${props =>
        rgba(props.theme.colors[props.customColor], 0.05)};
      border: ${props => `1px solid ${props.theme.colors[props.customColor]}`};
    }

    :disabled {
      color: ${props =>
        rgba(props.theme.colors['gray-300'], props.loading ? 0 : 1)};
      border: ${props => `1px solid ${props.theme.colors['gray-100']}`};
    }
  }
`;

export { OutlinedWrapper };
