import { createContext } from 'react';

import { IUserAuth } from 'modules/signIn/services';

import { useAuthActions } from './hooks';
import { ISignInPayload } from './types';

export interface AuthContextProps {
  user: IUserAuth | undefined;
  userPermissions: string | undefined;
  isLoading: boolean;
  isAuthenticated: () => boolean;
  signIn: ({ accessToken, refreshToken, user }: ISignInPayload) => void;
  updateLoggedBranch: (branchId: string) => void;
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function AuthProvider({ children }) {
  const {
    user,
    userPermissions,
    isLoading,
    isAuthenticated,
    signIn,
    updateLoggedBranch,
  } = useAuthActions();

  return (
    <AuthContext.Provider
      value={{
        user,
        userPermissions,
        isLoading,
        isAuthenticated,
        signIn,
        updateLoggedBranch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
