import { Grid, Typography } from '@mui/material';

import { Cnae, KleverCnae } from 'models';

import { BigDataItem } from './BigDataItem';
import { BigDataPhysicalProduction } from './BigDataPhysicalProduction';

interface Props {
  cnae: KleverCnae | Cnae;
  title?: string;
}

export const BigDataCnae = ({ cnae, title }: Props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          {title ?? 'CNAE Principal da Empresa'}
        </Typography>
      </Grid>
      <BigDataItem title="Código" value={cnae.code} />
      <BigDataItem title="Descrição" value={cnae.description} />
      <BigDataItem title="Atividade Principal" value={cnae.businessActivity} />
      <BigDataItem title="Setor" value={cnae.sector} />
      <BigDataPhysicalProduction physicalProductions={cnae.physicalProductions} />
    </Grid>
  );
};
