import { Grid, Typography } from '@mui/material';

import { ICompanySerasa } from 'models';

import { SerasaModalItem } from './SerasaModalItem';
import { Check, CompanyPefinRefin, OverdueDebtsSummary } from './serasa';

interface Props {
  serasa: ICompanySerasa;
}

const SerasaCompanyModal = ({ serasa }: Props) => {
  const serasaData = serasa.serasa;
  const companyName =
    serasa?.enterprise?.bigData?.fantasyName ||
    serasa?.enterprise?.bigData?.socialReason;

  return (
    <Grid container spacing={4} gap={8}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Informações sobre dados cadastrais
          </Typography>
        </Grid>
        <SerasaModalItem title="CNPJ" value={serasa.document} />
        <SerasaModalItem title="Nome Fantasia" value={companyName} />
        <SerasaModalItem
          title="Situação da empresa"
          value={serasaData.registration.statusRegistration}
        />
        <SerasaModalItem
          title="Data de fundação da empresa"
          value={new Date(
            serasaData.registration.foundationDate,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Cidade"
          value={serasaData.registration.address.city}
        />
        <SerasaModalItem
          title="Estado"
          value={serasaData.registration.address.state}
        />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Pendência Financeira - PEFIN (Resumo)
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.pefin.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasa.pefin.summary.balance.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
            minimumFractionDigits: 2,
          })}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Pendência Financeira - PEFIN
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasa?.pefin?.response?.map(pefin => (
            <CompanyPefinRefin
              key={`${pefin.contractId}-${pefin.legalNature}-${pefin.creditorName}`}
              data={pefin}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Restrições Financeiras - REFIN (Resumo)
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.refin.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasa.refin.summary.balance.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
            minimumFractionDigits: 2,
          })}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Restrições Financeiras - REFIN
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasa?.refin?.response?.map(refin => (
            <CompanyPefinRefin
              key={`${refin.contractId}-${refin.legalNature}-${refin.creditorName}`}
              data={refin}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <OverdueDebtsSummary overdueDebtsSummary={serasa.overdueDebts.summary} />

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Dívidas Vencidas (Convem)
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa?.overdueDebts?.response?.map(debt => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${debt.contractId}-${debt.legalNature}-${debt.creditorName}`}
            >
              <SerasaModalItem title="Cidade" value={debt.city} />
              <SerasaModalItem
                title="Principal"
                value={debt.principal ? 'Sim' : 'Não'}
              />
              <SerasaModalItem title="Id do Contrato" value={debt.contractId} />
              <SerasaModalItem
                title="Unidade Federativa"
                value={debt.federalUnit}
              />
              <SerasaModalItem title="Natureza Jurídica" value={debt.legalNature} />
              <SerasaModalItem
                title="Nome da Instituição Financeira"
                value={debt.creditorName}
              />
              <SerasaModalItem
                title="Id Natureza Jurídica"
                value={debt.legalNatureId}
              />
              <SerasaModalItem
                title="Data da Ocorrência"
                value={new Date(debt.occurrenceDate).toLocaleDateString('pt-br')}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Protestos - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.protests.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasa.protests.summary.balance.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
            minimumFractionDigits: 2,
          })}
        />

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Protestos
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa?.protests?.response?.map(protest => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${protest.city}-${protest.federalUnit}-${protest.occurrenceDate}-${protest.amount}`}
            >
              <SerasaModalItem title="Cidade" value={protest.city} />
              <SerasaModalItem
                title="Unidade Federativa"
                value={protest.federalUnit}
              />
              <SerasaModalItem
                title="Número escritório"
                value={protest.officeNumber}
              />
              <SerasaModalItem
                title="Valor"
                value={protest.amount.toLocaleString('pt-br', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem
                title="Data de ocorrência"
                value={new Date(protest.occurrenceDate).toLocaleDateString('pt-br')}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Cheques Sem Fundo (CCF) - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasa.check.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasa.check.summary.balance.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
            minimumFractionDigits: 2,
          })}
        />

        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Cheques Sem Fundo (CCF)
          </Typography>
        </Grid>

        <Grid container spacing={2}>
          {serasa?.check?.response?.map(check => (
            <Check
              key={`${check.alinea}-${check.bankId}-${check.bankAgencyId}-${check.checkNumber}`}
              check={check}
            />
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Falências - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasaData.facts.bankrupts.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasaData.facts.bankrupts.summary.balance.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Falências
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.facts?.bankrupts?.response?.map(bankrupt => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${bankrupt.eventType}-${bankrupt.origin}-${bankrupt.eventDate}`}
            >
              <SerasaModalItem title="Estado" value={bankrupt.state} />
              <SerasaModalItem title="Cidade" value={bankrupt.city} />
              <SerasaModalItem
                title="Tipo de falência"
                value={bankrupt.eventType}
              />
              <SerasaModalItem
                title="Date de falência"
                value={
                  bankrupt.eventDate &&
                  new Date(bankrupt.eventDate).toLocaleDateString('pt-br')
                }
              />
              <SerasaModalItem title="Origem da falência" value={bankrupt.origin} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Ações Judiciais - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantiade"
          value={serasaData.facts.judgementFilings.summary.count.toString()}
        />
        <SerasaModalItem
          title="Valor"
          value={serasaData.facts.judgementFilings.summary.balance.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Ações Judiciais
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.facts?.judgementFilings?.response?.map(judgementFiling => (
            <Grid
              item
              xs={12}
              lg={6}
              key={`${judgementFiling.amount}-${judgementFiling.legalNatureId}-${judgementFiling.occurrenceDate}`}
            >
              <SerasaModalItem title="Estado" value={judgementFiling.state} />
              <SerasaModalItem title="Cidade" value={judgementFiling.city} />
              <SerasaModalItem
                title="Date da ocorrência"
                value={
                  judgementFiling.occurrenceDate &&
                  new Date(judgementFiling.occurrenceDate).toLocaleDateString(
                    'pt-br',
                  )
                }
              />
              <SerasaModalItem
                title="Valor"
                value={judgementFiling.amount.toLocaleString('pt-br', {
                  currency: 'BRL',
                  style: 'currency',
                  minimumFractionDigits: 2,
                })}
              />
              <SerasaModalItem
                title="Identificador da natureza da dívida"
                value={judgementFiling.legalNatureId}
              />
              <SerasaModalItem
                title="Natureza da dívida"
                value={judgementFiling.legalNature}
              />
              <SerasaModalItem
                title="Número do distribuidor"
                value={judgementFiling.distributor}
              />
              <SerasaModalItem
                title="Identificador da vara judicial"
                value={judgementFiling.civilCourt}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Decisão e Limite de Crédito
          </Typography>
        </Grid>

        <SerasaModalItem
          title="Código de retorno do status"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.code?.toString()}
        />
        <SerasaModalItem
          title="Mensagem de retorno do status"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.message}
        />
        <SerasaModalItem
          title="CNPJ ativo ou não"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data
              ?.analyzedCriteria?.documentActive
              ? 'Ativo'
              : 'Inativo'
          }
        />
        <SerasaModalItem
          title="Data da fundação"
          value={new Date(
            serasaData?.optionalFeatures?.decisionLimitCredit?.data?.analyzedCriteria?.foundationTime,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Se não tem restritivo"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data
              ?.analyzedCriteria?.noRestrictiveActive
              ? 'Sim'
              : 'Não'
          }
        />
        <SerasaModalItem
          title="Status junto a Receita Federal"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data
              ?.analyzedCriteria?.statusRegistration
              ? 'Sim'
              : 'Não'
          }
        />
        <SerasaModalItem
          title="Nome da empresa"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data?.companyName
          }
        />
        <SerasaModalItem
          title="Data de criação da proposta"
          value={new Date(
            serasaData?.optionalFeatures?.decisionLimitCredit?.data?.creationDate,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="CNPJ da empresa"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.document}
        />
        <SerasaModalItem
          title="Número da proposta valor dinâmico"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data?.proposalNumber
          }
        />
        <SerasaModalItem
          title="Mensagem do tipo de recomendação"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data
              ?.recommendationSaleType
          }
        />
        <SerasaModalItem
          title="Tipo de recomendação"
          value={
            serasaData?.optionalFeatures?.decisionLimitCredit?.data
              ?.recommendationType
          }
        />
        <SerasaModalItem
          title="Valor do limite de crédito recomendado"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.recommendedLimitValue?.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
        <SerasaModalItem
          title="Nível de risco da empresa"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.riskLevelCode?.toLocaleString(
            'pt-BR',
            { style: 'percent', minimumFractionDigits: 2 },
          )}
        />
        <SerasaModalItem
          title="Tipo da venda se é cliente novo ou recorrente"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.saleType}
        />
        <SerasaModalItem
          title="Código do tipo da venda"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.saleTypeCode?.toString()}
        />
        <SerasaModalItem
          title="Valor da operação"
          value={serasaData?.optionalFeatures?.decisionLimitCredit?.data?.transactionValue?.toLocaleString(
            'pt-BR',
            {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            },
          )}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Administradores
          </Typography>
        </Grid>

        <Grid container spacing={3}>
          {serasaData?.optionalFeatures?.director?.DirectorResponse?.results?.map(
            director => (
              <Grid item xs={12} lg={6} key={director.documentId}>
                <SerasaModalItem title="CPF/CNPJ" value={director.documentId} />
                <SerasaModalItem title="Nome" value={director.name} />
                <SerasaModalItem
                  title="Com anotações negativas"
                  value={director.hasNegative ? 'Sim' : 'Não'}
                />
                <SerasaModalItem title="Cargo" value={director.role} />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Consultas à Serasa - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Qtd de consultas de empresas do mes atual"
          value={serasaData?.optionalFeatures?.inquiryCompanyResponse?.quantity?.actual?.toString()}
        />
        <SerasaModalItem
          title="Qtd de consultas de bancos/financeiras do mes atual"
          value={serasaData?.optionalFeatures?.inquiryCompanyResponse?.quantity?.bankActual?.toString()}
        />
        <SerasaModalItem
          title="Qtd de consultas de bancos/financeiras dos meses anteriores"
          value={serasaData?.optionalFeatures?.inquiryCompanyResponse?.quantity?.historical?.bankOccurrences?.toString()}
        />
        <SerasaModalItem
          title="Data dos meses anteriores"
          value={new Date(
            serasaData?.optionalFeatures?.inquiryCompanyResponse?.quantity?.historical?.inquiryDate,
          ).toLocaleDateString('pt-br')}
        />
        <SerasaModalItem
          title="Qtd de consultas de empresas dos meses anteriores"
          value={serasaData?.optionalFeatures?.inquiryCompanyResponse?.quantity?.historical?.occurrences?.toString()}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Consultas à Serasa
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.optionalFeatures?.inquiryCompanyResponse?.inquiryCompanyResponse?.results?.map(
            inquiryResponse => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${inquiryResponse.companyDocumentId}-${inquiryResponse.occurrenceDate}`}
              >
                <SerasaModalItem
                  title="Número do CNPJ consultante"
                  value={inquiryResponse.companyDocumentId}
                />
                <SerasaModalItem
                  title="Nome do CNPJ consultante"
                  value={inquiryResponse.companyName}
                />
                <SerasaModalItem
                  title="Quantidade de consultas no dia"
                  value={inquiryResponse.daysQuantity.toString()}
                />
                <SerasaModalItem
                  title="Data da ocorrência"
                  value={new Date(
                    inquiryResponse.occurrenceDate,
                  ).toLocaleDateString('pt-br')}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            QSA (Sócios)
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          {serasaData?.optionalFeatures?.partner?.PartnerResponse?.results?.map(
            partner => (
              <Grid item xs={12} lg={6} key={partner.documentId}>
                <SerasaModalItem title="CPF/CNPJ" value={partner.documentId} />
                <SerasaModalItem title="Nome" value={partner.name} />
                <SerasaModalItem
                  title="Com anotações negativas"
                  value={partner.hasNegative ? 'Sim' : 'Não'}
                />
                <SerasaModalItem
                  title="Para sócios na sequência 0 ou 50 (Falso), para outras sequencias (Verdadeiro)"
                  value={partner.inconsistent ? 'Verdadeiro' : 'Falso'}
                />
                <SerasaModalItem
                  title="Percentual da participação do sócio na empresa"
                  value={partner.participationPercentage.toLocaleString('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            QSA - Quadro Social Administrativo Mais Completo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Mensagens Informativas para empresas que não possuem Quadro Societário"
          value={serasaData?.optionalFeatures?.qsaCompleteReport?.infoMessages}
        />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={18}>
              Diretores
            </Typography>
          </Grid>
          {serasaData?.optionalFeatures?.qsaCompleteReport?.directors?.map(
            director => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${director.document}-${director.idNumber}`}
              >
                <SerasaModalItem
                  title="Endereço"
                  value={director.address.addressLine}
                />
                <SerasaModalItem title="Cidade" value={director.address.city} />
                <SerasaModalItem title="País" value={director.address.country} />
                <SerasaModalItem
                  title="Código do país"
                  value={director.address.countryCode}
                />
                <SerasaModalItem title="Bairro" value={director.address.district} />
                <SerasaModalItem title="Estado" value={director.address.state} />
                <SerasaModalItem title="CEP" value={director.address.zipCode} />
                <SerasaModalItem
                  title="Data de nascimento"
                  value={new Date(director.birthDate).toLocaleDateString('pt-br')}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={700} fontSize={18}>
                      Débitos
                    </Typography>
                  </Grid>
                  {director.debts.map(debt => (
                    <Grid
                      item
                      xs={12}
                      key={`${debt.debtType}-${debt.summary.balance}`}
                    >
                      <SerasaModalItem
                        title="Tipo de dívida"
                        value={debt.debtType}
                      />
                      <SerasaModalItem
                        title="Valor total"
                        value={debt.summary.balance.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        })}
                      />
                      <SerasaModalItem
                        title="Quantidade total"
                        value={debt.summary.count.toString()}
                      />
                    </Grid>
                  ))}
                </Grid>
                <SerasaModalItem title="Documento" value={director.document} />
                <SerasaModalItem
                  title="Ramo Documento"
                  value={director.documentBranch}
                />
                <SerasaModalItem
                  title="Digito Documento"
                  value={director.documentDigit}
                />
                <SerasaModalItem
                  title="Sequencia Documento"
                  value={director.documentSequence}
                />
                <SerasaModalItem
                  title="Data final periodo"
                  value={new Date(director.endDateTerm).toLocaleDateString('pt-br')}
                />
                <SerasaModalItem
                  title="Data de início do período"
                  value={new Date(director.startDateTerm).toLocaleDateString(
                    'pt-br',
                  )}
                />
                <SerasaModalItem
                  title="Numero identificador"
                  value={director.idNumber}
                />
                <SerasaModalItem title="Tipo pessoa" value={director.kindPerson} />
                <SerasaModalItem
                  title="Situação conjugal"
                  value={director.maritalStatus}
                />
                <SerasaModalItem title="Nome" value={director.name} />
                <SerasaModalItem
                  title="Nacionalidade"
                  value={director.nationality}
                />
                <SerasaModalItem title="Escritório" value={director.office} />
                <SerasaModalItem
                  title="Telefone"
                  value={`(${director.phone.areaCode}) ${director.phone.phoneNumber}`}
                />
                <SerasaModalItem
                  title="Relacionamento"
                  value={director.relationship}
                />
                <SerasaModalItem
                  title="Indicador de restrição"
                  value={director.restrictionIndicator ? 'Sim' : 'Não'}
                />
                <SerasaModalItem
                  title="Data inicial do período"
                  value={new Date(director.startDateTerm).toLocaleDateString(
                    'pt-br',
                  )}
                />
                <SerasaModalItem
                  title="Data de atualização"
                  value={new Date(director.updateDate).toLocaleDateString('pt-br')}
                />
              </Grid>
            ),
          )}
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography fontWeight={700} fontSize={18}>
              Sócios
            </Typography>
          </Grid>
          {serasaData?.optionalFeatures?.qsaCompleteReport?.partners?.map(
            partner => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${partner.document}-${partner.idNumber}`}
              >
                <SerasaModalItem
                  title="Endereço"
                  value={partner.address.addressLine}
                />
                <SerasaModalItem title="Cidade" value={partner.address.city} />
                <SerasaModalItem title="País" value={partner.address.country} />
                <SerasaModalItem
                  title="Código do país"
                  value={partner.address.countryCode}
                />
                <SerasaModalItem title="Bairro" value={partner.address.district} />
                <SerasaModalItem title="Estado" value={partner.address.state} />
                <SerasaModalItem title="CEP" value={partner.address.zipCode} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography fontWeight={700} fontSize={18}>
                      Débitos
                    </Typography>
                  </Grid>
                  {partner.debts.map(debt => (
                    <Grid
                      item
                      xs={12}
                      key={`${debt.debtType}-${debt.summary.balance}`}
                    >
                      <SerasaModalItem
                        title="Tipo de dívida"
                        value={debt.debtType}
                      />
                      <SerasaModalItem
                        title="Valor total"
                        value={debt.summary.balance.toLocaleString('pt-BR', {
                          currency: 'BRL',
                          style: 'currency',
                          minimumFractionDigits: 2,
                        })}
                      />
                      <SerasaModalItem
                        title="Quantidade total"
                        value={debt.summary.count.toString()}
                      />
                    </Grid>
                  ))}
                </Grid>
                <SerasaModalItem title="Documento" value={partner.document} />
                <SerasaModalItem
                  title="Ramo Documento"
                  value={partner.documentBranch}
                />
                <SerasaModalItem
                  title="Digito Documento"
                  value={partner.documentDigit}
                />
                <SerasaModalItem
                  title="Sequencia Documento"
                  value={partner.documentSequence}
                />
                <SerasaModalItem
                  title="Data de entrada"
                  value={new Date(partner.entryDate).toLocaleDateString('pt-br')}
                />
                <SerasaModalItem
                  title="Data de fundação"
                  value={new Date(partner.foundationDate).toLocaleDateString(
                    'pt-br',
                  )}
                />
                <SerasaModalItem
                  title="Numero identificador"
                  value={partner.idNumber}
                />
                <SerasaModalItem title="Tipo pessoa" value={partner.kindPerson} />
                <SerasaModalItem title="Nome" value={partner.name} />
                <SerasaModalItem
                  title="Nacionalidade"
                  value={partner.nationality}
                />
                <SerasaModalItem
                  title="Porcentagem Capital"
                  value={partner.percentageCapital.toLocaleString('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  })}
                />
                <SerasaModalItem
                  title="Porcentagem Capital Voto"
                  value={partner.percentageVotingCapital.toLocaleString('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  })}
                />
                <SerasaModalItem
                  title="Telefone"
                  value={`(${partner.phone.areaCode}) ${partner.phone.phoneNumber}`}
                />
                <SerasaModalItem
                  title="Relacionamento"
                  value={partner.relationship}
                />
                <SerasaModalItem
                  title="Indicador de restrição"
                  value={partner.restrictionIndicator ? 'Sim' : 'Não'}
                />
              </Grid>
            ),
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={18}>
            Capital Social
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Valor"
          value={serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.capitalValue?.toLocaleString(
            'pt-BR',
            { currency: 'BRL', style: 'currency', minimumFractionDigits: 2 },
          )}
        />
        <SerasaModalItem
          title="Controle"
          value={
            serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.control
          }
        />
        <SerasaModalItem
          title="Natureza"
          value={
            serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.nature
          }
        />
        <SerasaModalItem
          title="Origem"
          value={
            serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.origin
          }
        />
        <SerasaModalItem
          title="Valor realizado"
          value={serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.realizedCapitalValue?.toLocaleString(
            'pt-BR',
            { currency: 'BRL', style: 'currency', minimumFractionDigits: 2 },
          )}
        />
        <SerasaModalItem
          title="Data atualização"
          value={new Date(
            serasaData?.optionalFeatures?.qsaCompleteReport?.shareCapital?.updateDate,
          ).toLocaleDateString('pt-br')}
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Limite de Crédito (HLC1)
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.optionalFeatures?.scores?.scoreResponse?.map(score => (
            <Grid item xs={12} lg={6} key={score.score}>
              <SerasaModalItem title="Taxa padrão" value={score.defaultRate} />
              <SerasaModalItem title="Mensagem" value={score.message} />
              <SerasaModalItem title="Pontuação" value={score.score?.toString()} />
              <SerasaModalItem title="Modelo Score" value={score.scoreModel} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography fontWeight={700} fontSize={18}>
                    Parametros de Score
                  </Typography>
                </Grid>
                {score.scoreParam?.map(scoreParam => (
                  <Grid item xs={12} key={`${scoreParam.key}-${scoreParam.value}`}>
                    <SerasaModalItem
                      title={scoreParam.key ?? 'N/D'}
                      value={scoreParam.value?.toString()}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Anotações SPC - Resumo
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Quantidade total"
          value={serasaData?.optionalFeatures?.spcAnnotations?.summary?.count?.toString()}
        />
        <SerasaModalItem
          title="Valor total"
          value={serasaData?.optionalFeatures?.spcAnnotations?.summary?.balance?.toLocaleString(
            'pt-BR',
            { currency: 'BRL', style: 'currency', minimumFractionDigits: 2 },
          )}
        />
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Anotações SPC
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.optionalFeatures?.spcAnnotations?.spcAnnotations?.map(
            annotation => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${annotation.contractId}-${annotation.occurrenceDate}-${annotation.amount}`}
              >
                <SerasaModalItem
                  title="Valor da anotação"
                  value={annotation.amount?.toLocaleString('pt-BR', {
                    currency: 'BRL',
                    style: 'currency',
                    minimumFractionDigits: 2,
                  })}
                />
                <SerasaModalItem title="Cidade" value={annotation.city} />
                <SerasaModalItem
                  title="Identificador do contrato"
                  value={annotation.contractId}
                />
                <SerasaModalItem
                  title="Nome da Instituição Financeira"
                  value={annotation.creditorName}
                />
                <SerasaModalItem title="Estado" value={annotation.federalUnit} />
                <SerasaModalItem
                  title="Data de inclusão"
                  value={
                    annotation.inclusionDate &&
                    new Date(annotation.inclusionDate).toLocaleDateString('pt-br')
                  }
                />
                <SerasaModalItem
                  title="Data de ocorrência"
                  value={
                    annotation.occurrenceDate &&
                    new Date(annotation.occurrenceDate).toLocaleDateString('pt-br')
                  }
                />
                <SerasaModalItem
                  title="Origem da anotação"
                  value={annotation.origin}
                />
                <SerasaModalItem
                  title="Principal ou Avalista"
                  value={annotation.principal ? 'Principal' : 'Avalista'}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Consultas SPC - Resumo
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.optionalFeatures?.spcMarketInquiries?.spcMarketInquirySummary?.map(
            inquiry => (
              <Grid
                item
                xs={12}
                lg={6}
                key={`${inquiry.inquiryQuantity?.actual}-${inquiry.inquiryQuantity?.creditInquiriesQuantity.occurrences}-${inquiry.inquiryQuantity?.creditInquiriesQuantity.firstOccurrence}`}
              >
                <SerasaModalItem
                  title="Quantidade de consulta do mês atual"
                  value={inquiry.inquiryQuantity?.actual.toString()}
                />
                <SerasaModalItem
                  title="Data dos últimos 6 meses"
                  value={
                    inquiry.inquiryQuantity?.creditInquiriesQuantity.firstOccurrence
                  }
                />
                <SerasaModalItem
                  title="Quantidade de consulta dos últimos 6 meses"
                  value={inquiry.inquiryQuantity?.creditInquiriesQuantity.occurrences.toString()}
                />
              </Grid>
            ),
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Consultas SPC
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          {serasaData?.optionalFeatures?.spcMarketInquiries?.spcMarketInquiriesResponseList?.map(
            inquiry => (
              <Grid item xs={12} lg={6} key={inquiry.companyDocumentId}>
                <SerasaModalItem
                  title="Número do CNPJ consultante"
                  value={inquiry.companyDocumentId}
                />
                <SerasaModalItem
                  title="Nome do CNPJ consultante"
                  value={inquiry.companyName}
                />
                <SerasaModalItem
                  title="Quantidade de consultas no dia"
                  value={inquiry.daysQuantity.toString()}
                />
                <SerasaModalItem
                  title="Data da consulta"
                  value={new Date(inquiry.occurrenceDate).toLocaleDateString(
                    'pt-br',
                  )}
                />
              </Grid>
            ),
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography fontWeight={700} fontSize={20}>
            Serasa Score 2.0
          </Typography>
        </Grid>
        <SerasaModalItem
          title="Pontuação do score"
          value={serasaData.score.score.toString()}
        />
        <SerasaModalItem
          title="Probabilidade de não pagamento"
          value={serasaData.score.defaultRate}
        />
        <SerasaModalItem title="Mensagem" value={serasaData.score.message} />
      </Grid>
    </Grid>
  );
};

export { SerasaCompanyModal };
