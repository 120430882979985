import { IEnterprise } from 'models/enterprise';
import { IPaginationParams } from 'models/pagination';
import { IRole } from 'models/roles';

import { IOrdination } from 'utils/ordination';

export enum Languages {
  ENGLISH = 'English',
  PORTUGUESE = 'Portuguese',
}

export interface IUser {
  id: string;
  name: string;
  surname: string;
  cpf: string;
  email: string;
  active: boolean;
  language: Languages;
  role: IRole;
  enterprise: IEnterprise;
  birthDate: Date | string;
  position: UserPositions;
  linkedin?: string;
  password?: string;
  userType: UserTypes;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
}

export interface IUserPayload {
  name: string;
  email: string;
  password?: string;
  active: boolean;
  language: string | null;
  role: number | null;
  branches: number[];
}

export enum UserPositions {
  'SOCIO' = 'Sócio',
  'CLEVEL' = 'C-level',
  'DIRETORIA' = 'Diretoria',
  'GESTAO' = 'Gestão',
  'OUTROS' = 'Outros',
}

export enum UserTypes {
  'ENTERPRISE' = 'Empresa',
  'CREDITOR' = 'Instituição Financeira',
  'CONSULTANT' = 'Consultor',
}

export type IUserTypes = keyof typeof UserTypes;

export interface IUserForm extends IUserPayload {
  id: number;
  confirmPassword: string;
}

export interface IFilterUserParams extends IPaginationParams, IOrdination<IUser> {
  user?: string;
  active?: boolean;
}
