import api from 'config/api';

import { IValidateCodeResponse } from 'modules/email';

import {
  IForgotPasswordCodePayload,
  IForgotPasswordEmailPayload,
  IForgotPasswordPayload,
  IForgotPasswordResponse,
  IValidateEmailResponse,
} from './types';

export class ForgotPasswordService {
  static async isEmailValid(payload: IForgotPasswordEmailPayload) {
    const { data } = await api.post<IValidateEmailResponse>(
      '/auth/validate-email',
      payload,
    );

    return data;
  }

  static async isCodeValid(payload: IForgotPasswordCodePayload) {
    // const { data } = await api.post<IValidateCodeResponse>('', payload);

    // return data;
    console.log({ payload });

    return { isValid: true } as IValidateCodeResponse;
  }

  static async changePassword(payload: IForgotPasswordPayload) {
    console.log({ payload });

    const { data } = await api.post<IForgotPasswordResponse>(
      'auth/update-password',
      payload,
    );

    return data;
  }
}
