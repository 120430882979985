import { ReactNode } from 'react';
import { Colors } from 'themes';

import { Loader } from '../Loader';
import { ContainedWrapper } from './styles';

export interface ContainedProps {
  onClick?: () => void;
  label?: string;
  sizeLabel?: number;
  icon?: ReactNode;
  color?: Colors;
  disabled?: boolean;
  loading?: boolean;
  fullWidth?: boolean;
  type?: 'submit';
  customTextColor?: Colors;
  boxShadow?: string;
}

function Contained({
  onClick,
  label,
  sizeLabel,
  icon,
  color = 'blue',
  disabled,
  loading = false,
  fullWidth,
  type,
  customTextColor = 'white',
  boxShadow,
}: ContainedProps) {
  return (
    <ContainedWrapper
      onClick={onClick}
      startIcon={icon}
      customColor={color}
      disabled={loading ? true : disabled}
      fullWidth={fullWidth}
      loading={loading}
      variant="contained"
      type={type}
      customTextColor={customTextColor}
      style={{ boxShadow: boxShadow }}
    >
      {loading && <Loader />}
      <span style={{ fontSize: sizeLabel }}>{label}</span>
    </ContainedWrapper>
  );
}

export default Contained;
