import { OptionsValuesString } from 'utils/helper';

import { IQuestionForm, IVariable } from '../services';

export interface IAllQuestionsOutput {
  outputQuestion: OptionsValuesString | undefined;
  identifierOutputQuestion: number;
}

export const useGetTagsOutputsQuestion = (
  question: IQuestionForm,
  allQuestions: OptionsValuesString[],
) => {
  const outputQuestions = question?.outputQuestions;

  let allQuestionsOutput: IAllQuestionsOutput[] = [];

  if (outputQuestions) {
    allQuestionsOutput = [...outputQuestions];
  }

  // encontrando todas nextQuestion selecionadas;
  const variables: IVariable[] = [];

  if (question?.conditions) {
    question?.conditions?.forEach(condition => {
      const conditionInput = condition?.conditionTypeInput;
      const validConditionTypeInput =
        conditionInput?.identifierInput > 0 &&
        conditionInput?.nextQuestion &&
        conditionInput?.nextQuestion.length > 0;

      if (validConditionTypeInput) {
        allQuestionsOutput.push({
          outputQuestion: {
            label: conditionInput.nextQuestionLabel,
            value: conditionInput.nextQuestion,
          },
          identifierOutputQuestion: allQuestionsOutput.length + 1,
        });
        return;
      }

      condition?.variables?.forEach(variable => {
        const validaVariable =
          variable?.nextQuestion &&
          variable?.nextQuestion.length > 0 &&
          variable?.identifierVariable > 0;
        if (validaVariable) {
          variables.push(variable);
        }
      });
    });

    variables.map(questionVar => {
      allQuestionsOutput.push({
        outputQuestion: {
          label: questionVar.nextQuestionLabel,
          value: questionVar.nextQuestion,
        },
        identifierOutputQuestion: allQuestionsOutput.length + 1,
      });
    });
  }

  // encontrando all conditions false selecionadas (multiple choice)
  const conditionsFalse = question?.conditions?.filter(
    condition =>
      condition?.nextConditionFalse && condition.nextConditionFalse.length > 0,
  );

  // add all options condtios false in outputs;
  conditionsFalse?.map(questionCondition => {
    allQuestionsOutput.push({
      outputQuestion: {
        label: questionCondition?.nextConditionFalseLabel ?? '',
        value: questionCondition?.nextConditionFalse ?? '',
      },
      identifierOutputQuestion: allQuestionsOutput.length + 1,
    });
  });

  // encontrando o label da opção false externa
  const questionFalse = allQuestions.find(
    all => all.value === question?.nextQuestionFalse,
  );
  // adicionando option false externa
  allQuestionsOutput.push({
    outputQuestion: questionFalse,
    identifierOutputQuestion: allQuestionsOutput.length + 1,
  });

  return {
    allQuestionsOutput,
  };
};
