import { useEffect } from 'react';

import { useSerasaStore } from '../store/store';
import { useSerasaRequest } from './useSerasaRequest';

export const useSerasa = () => {
  const { limit, page, perPage, setLimit, setPage } = useSerasaStore();
  const { data: response, isLoading, refetch } = useSerasaRequest({});

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  useEffect(() => {
    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
  };
};
