import { Box, Checkbox, Grid, TextField as MuiTextField } from '@mui/material';

import { FormAnswerStatus, IFormQuestion, QuestionType } from 'models';

import useFormResumeStore from 'modules/dynamicForm/formResume/store/store';

import { useHandleAnswers } from '../../hooks';

interface IFormQuestionProps {
  formQuestion: IFormQuestion;
  allowEdit: boolean;
}

export const FormQuestions = ({ formQuestion, allowEdit }: IFormQuestionProps) => {
  const {
    currentAnswer,
    getChecked,
    handleOnChangeMultipleChoice,
    handleChangeInputsQuestion,
  } = useHandleAnswers();

  const { revisionQuestion } = useFormResumeStore();

  const typeQuestion = QuestionType[formQuestion?.question?.type];

  const optionsMultipleChoice = formQuestion?.question?.answerOptions?.map(
    op => op.value,
  );

  const isDiscarded = revisionQuestion.status === FormAnswerStatus.DISCARDED;

  return (
    <Box>
      <Box>
        {(typeQuestion === QuestionType.SHORT_TEXT ||
          typeQuestion === QuestionType.NUMBER) && (
          <MuiTextField
            name="title"
            label="Resposta"
            disabled={!allowEdit || isDiscarded}
            fullWidth
            value={currentAnswer}
            type={typeQuestion === QuestionType.SHORT_TEXT ? 'text' : 'number'}
            inputProps={{ maxLenght: 255 }}
            onChange={e => {
              handleChangeInputsQuestion(e.target.value);
            }}
          />
        )}

        {typeQuestion === QuestionType.LONG_TEXT && (
          <MuiTextField
            name="title"
            label="Resposta"
            disabled={!allowEdit || isDiscarded}
            multiline
            rows={4}
            fullWidth
            value={currentAnswer}
            maxRows={Infinity}
            type={'text'}
            onChange={e => {
              handleChangeInputsQuestion(e.target.value);
            }}
          />
        )}
      </Box>

      <Box display={'flex'} justifyContent={'center'} sx={{ width: '100%' }}>
        <Grid container spacing={2} style={{ margin: 0, width: '1000px' }}>
          {typeQuestion === QuestionType.MULTIPLE_CHOICE &&
            optionsMultipleChoice?.map((condition, index) => (
              <Grid
                item
                key={index}
                sx={{
                  width: 250,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  disabled={!allowEdit || isDiscarded}
                  checked={getChecked(`${condition}`)}
                  size={'medium'}
                  onChange={() => handleOnChangeMultipleChoice(`${condition}`)}
                />
                <label style={{ fontSize: 16 }}>{condition}</label>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};
