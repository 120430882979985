import { useMutation, UseMutationOptions } from 'react-query';

import { IUser } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { UserService, IUserPayload } from 'modules/user/services';

const useCreateConsultantRequest = (
  options?: UseMutationOptions<IUser, IApiAxiosError, IUserPayload>,
) => {
  return useMutation('createConsultantUser', UserService.createConsultant, options);
};

export { useCreateConsultantRequest };
