import { LoadingButton } from '@mui/lab';
import { Paper, Grid } from '@mui/material';
import themes from 'themes';

import { Box, TextField, Typography } from 'components';

import { UseValidateCode } from '../hooks/useValidateCode';

export interface CodeFormProps {
  form: UseValidateCode['form'];
  isLoading?: boolean;
}

export const FormCodeValidation = ({ form, isLoading }: CodeFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  return (
    <Paper
      elevation={16}
      sx={{
        marginY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '2em',
        backgroundColor: '#f0f0f0',
        borderRadius: '11px',
        padding: '3em',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Box>
        <Typography fontSize={20} fontWeight="bold" fontFamily={themes.fonts.body}>
          Insira o código recebido em seu email
        </Typography>
        <Box as="form" marginTop={3}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={12}>
              <TextField
                control={control}
                error={errors.code}
                name="code"
                label="Código"
                type="text"
                fullWidth
                sx={{
                  '& .MuiOutlinedInput-root': {
                    zIndex: 0,
                    '& input': {
                      zIndex: 1,
                    },
                    '& fieldset': {
                      backgroundColor: '#e0e0e0',
                      border: 'none',
                      borderRadius: '8px',
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box padding="1em">
            <LoadingButton
              variant="contained"
              onClick={handleSubmitForm}
              loading={isLoading}
              disabled={!isValid}
              fullWidth
              sx={{
                backgroundColor: themes.colors.text.primary,
                textTransform: 'none',
                borderRadius: '8px',
                paddingY: '0.8em',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                fontFamily: themes.fonts.body,
              }}
            >
              Próximo
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};
