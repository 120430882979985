import { useMutation, UseMutationOptions } from 'react-query';

import { IProcessAct } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateProcessAct } from '../service';
import TabActServices from '../service/service';

const useUpdateProcessActRequest = (
  options?: UseMutationOptions<IProcessAct, IApiAxiosError, IUpdateProcessAct>,
) => {
  return useMutation('updateProcessAct', TabActServices.update, options);
};

export { useUpdateProcessActRequest };
