import { useQuery } from 'react-query';

import { TypeProcessAct } from 'models';

import { UserTypeHandleActPayload } from 'modules/backoffice/acts';
import { UserTypesPayload } from 'modules/user';

import { OptionsValuesString } from 'utils/helper';

import { ProcessActQueryKeys, TabsService } from '../services';

interface ProcessActsOptionsParams {
  type: TypeProcessAct;
  enterpriseId: string;
  operationId?: string;
  userType: UserTypesPayload;
  userRole: UserTypeHandleActPayload;
  enabled: boolean;
}

export const useProcessActsOptions = ({
  type,
  enterpriseId,
  operationId,
  userType,
  userRole,
  enabled,
}: ProcessActsOptionsParams) => {
  const { data, isLoading, refetch } = useQuery(
    [ProcessActQueryKeys.ENTERPRISE_ACTS],
    () =>
      TabsService.getProcessActs({
        type,
        enterpriseId,
        operationId,
        userType,
        userRole,
      }),
    { enabled, keepPreviousData: true },
  );

  const processActsOptionsFilter = data?.map(act => ({
    label: act.act.name,
    value: act.id ?? '',
  }));

  const removeDuplicateItems = (array: OptionsValuesString[]) => {
    const seenLabels = new Set();
    return array.filter(
      item => !seenLabels.has(item.label) && seenLabels.add(item.label),
    );
  };

  return {
    processActsOptions: removeDuplicateItems(processActsOptionsFilter ?? []),
    data,
    isLoading,
    refetch,
  };
};
