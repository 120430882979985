export type IOrderBy<T> = keyof T;
export type ISort = 'DESC' | 'ASC';

export interface IOrdination<T> {
  orderBy?: IOrderBy<T>;
  sort?: ISort;
}

type IOrdinationStore<T> = Required<IOrdination<T>>;

export function getDefaultOrdination<T>(): IOrdinationStore<T> {
  return {
    orderBy: 'updatedAt' as keyof T,
    sort: 'DESC',
  };
}
