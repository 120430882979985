import styled from 'styled-components';

export const ContentListWrapper = styled.div`
  border: 1px solid #e0e0e0;
  flex: 1;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
    background-color: ${({ theme }) => theme.colors['gray-100']};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors['gray-300']};
    border-radius: 12px;
  }
`;
