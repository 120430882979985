import {
  IFormRecord,
  IUser,
  IFormQuestion,
  IFileStorage,
  ClientDestineStatus,
} from 'models';

export enum FormAnswerStatus {
  ANSWERED = 'Answered',
  DISCARDED = 'Discarded',
}

export interface IFormAnswerAttachment {
  id?: string;
  formAnswer: IFormAnswer;
  file: IFileStorage;
}

export interface IFormAnswer {
  id?: string;
  formQuestion: IFormQuestion;
  formRecord: IFormRecord;
  user: IUser;
  answeredAt: Date;
  attachments: IFormAnswerAttachment[];
  answerValue: unknown;
  status: FormAnswerStatus;
  active: boolean;
  statusClient: ClientDestineStatus;
}
