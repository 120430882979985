import Delta from 'quill-delta';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';

import { Typography } from 'components';

import {
  useCardConditionQuestionTypesInput,
  useTrueAutocompleteConditionQuestionTypeInput,
} from 'modules/dynamicForm/form/hooks';
import { IQuestionForm } from 'modules/dynamicForm/form/services';

import { QuestionsAutocomplete } from '../QuestionsAutocomplete';
import ExpressionInput from '../cardConditionMultipleChoice/ExpressionInput';

interface CardConditionTextProps {
  question: IQuestionForm;
  identifierCondition: number;
}

export const DetailsCardConditionText = ({
  question,
  identifierCondition,
}: CardConditionTextProps) => {
  const {
    formAutocompleteConditionQuestionTypeText,
    allQuestions,
    setSearch,
    isOptionEqualToValue,
    handleChange,
  } = useTrueAutocompleteConditionQuestionTypeInput();

  const {
    expression,
    setExpression,
    handleExpressionChange,
  } = useCardConditionQuestionTypesInput();

  const conditionLabel =
    question?.conditions[identifierCondition - 1]?.conditionTypeInput
      ?.nextQuestionLabel;

  const deltaExpression =
    question?.conditions[identifierCondition - 1]?.conditionTypeInput
      ?.deltaExpression || new Delta();

  useEffect(() => {
    setExpression((deltaExpression as unknown) as Delta);
  }, [question]);

  return (
    <>
      <ExpressionInput
        question={question}
        identifierCondition={identifierCondition}
        deltaExpression={(expression as unknown) as ReactQuill.Value}
        onChange={handleExpressionChange}
      />

      <Typography>Proxima pergunta Se True</Typography>

      <QuestionsAutocomplete
        question={question}
        identifierCondition={identifierCondition}
        setSearch={setSearch}
        value={conditionLabel}
        form={formAutocompleteConditionQuestionTypeText}
        allQuestions={allQuestions}
        isOptionEqualToValue={isOptionEqualToValue}
        handleChange={handleChange}
      />
    </>
  );
};
