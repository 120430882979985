import { Box } from '@mui/material';
import styled from 'styled-components';

const MenuLogoutWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  width: 235px;
  height: 40px !important;
  border-radius: 0 25px 25px 0;
  font-size: ${({ theme }) => theme.fontSizes.m}px;
  padding-left: 30px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.navy} !important;
    transition: 0.2s ease-in-out;
  }
`;

export { MenuLogoutWrapper };
