import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MuiSelect,
  SxProps,
  Theme,
} from '@mui/material';
import { ReactNode } from 'react';
import {
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: FieldError;
  label: string;
  disabled?: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
};

export function Select<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  error,
  name,
  label,
  disabled,
  control,
  defaultValue,
  rules,
  children,
  sx,
  ...rest
}: Props<TFieldValues, TName>) {
  const {
    field: { onChange, value, ref, ...props },
  } = useController({ control, name, defaultValue });

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <FormControl fullWidth error={Boolean(!!error)}>
          <InputLabel>{label}</InputLabel>

          <MuiSelect
            id={field.name}
            label={label}
            disabled={disabled}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue}
            ref={ref}
            sx={sx}
            {...rest}
            {...props}
          >
            {children}
          </MuiSelect>

          {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
