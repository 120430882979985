import { Box, ButtonContained } from 'components';

import { useCustomerActions } from '../hooks';

const CustomerActions = () => {
  const { handleOpenChangeStatsDialog } = useCustomerActions();

  return (
    <Box display="flex" alignItems="center" style={{ gap: '1em' }}>
      <ButtonContained label="MUDAR STATUS" onClick={handleOpenChangeStatsDialog} />
    </Box>
  );
};

export { CustomerActions };
