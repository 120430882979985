import { Grid, TextField as MuiTextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import {
  Box,
  ButtonOutlined,
  ButtonContained,
  Typography,
  SectionButtons,
} from 'components';
import { AutocompleteWrapper } from 'components/Autocomplete/styles';
import { useSearchDebounce } from 'hooks';

import { OptionsValues } from 'utils/helper';

import { UseQuestionDialog, useQuestionDialog } from '../../hooks';
import { useVariableAutocomplete } from '../../hooks/useVariableAutocomplete';
import { useQuestionStore } from '../../store/store';

export interface DataStepFormProps {
  form: UseQuestionDialog['form'];
}

export const DataStep = ({ form }: DataStepFormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmitForm,
  } = form;

  const { variables, handleCloseFormDialog } = useQuestionDialog();
  const { setSearchVariable } = useQuestionStore();
  const { handleChange, isOptionEqualToValue } = useVariableAutocomplete();
  const { handleChangeSearch: handleChangeSearchVariable } = useSearchDebounce(
    setSearchVariable,
  );

  return (
    <>
      <Box as="form" padding={0}>
        <Grid container pt={4} pl={2} pr={2} minHeight={350}>
          <Box
            width={'100%'}
            minHeight={250}
            maxHeight={300}
            overflow={'auto'}
            paddingX={5}
          >
            <Grid item xs={12}>
              <Typography fontSize={25}>Variável Correspondente</Typography>
            </Grid>
            <Grid item xs={12} mt={1}>
              <Typography>
                Defina qual é o campo equivalente na base de dados
              </Typography>
            </Grid>
            <Grid item xs={6} mt={2}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <AutocompleteWrapper
                    {...field}
                    id="title"
                    options={variables ?? []}
                    onClose={() => setSearchVariable('')}
                    onChange={(_, option) =>
                      handleChange(option as OptionsValues, field)
                    }
                    onInputChange={(_, value) => handleChangeSearchVariable(value)}
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        label="Selecione a variável da base de dados"
                        fullWidth
                        error={Boolean(!!errors.title)}
                        helperText={<>{errors.title?.message}</>}
                      />
                    )}
                    isOptionEqualToValue={(option, value) =>
                      isOptionEqualToValue(option as OptionsValues, value as string)
                    }
                    noOptionsText="Nenhum resultado encontrado"
                  />
                )}
              />
            </Grid>
          </Box>
        </Grid>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseFormDialog} />
        <ButtonContained label="Salvar" onClick={() => handleSubmitForm()} />
      </SectionButtons>
    </>
  );
};
