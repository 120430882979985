import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

export const codeSchema = z.object({
  code: z.string().trim().nonempty('Código é obrigatório'),
});

const defaultValues = {
  code: '',
};

export const useCodeForm = () => {
  return useForm<{ code: string }>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(codeSchema),
  });
};
