import { IconButton, TableCell } from '@mui/material';
import { useState } from 'react';
import themes from 'themes';

import { Icon, TableRow, Typography, TypographyActive } from 'components';
import { ICompanyBigData, inactiveCustomers } from 'models';

import { renderCnpjMask } from 'utils/renderCNPJMask';

import { BigDataModal } from './bigData/components/BigDataModal';

const StorageItem = (storage: ICompanyBigData) => {
  const [open, setOpen] = useState(false);
  const emptyString = '---';

  return (
    <>
      <BigDataModal storage={storage} open={open} setOpen={val => setOpen(val)} />
      <TableRow key={storage.cnpj}>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {renderCnpjMask(storage.cnpj)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.socialReason}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.fantasyName || emptyString}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {new Date(storage.openingDate)?.toLocaleDateString('pt-BR')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.socialCapital?.toLocaleString('pt-BR', {
              currency: 'BRL',
              style: 'currency',
              minimumFractionDigits: 2,
            })}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            style={{
              fontSize: `${themes.fontSizes.s}px`,
            }}
            textAlign="center"
          >
            {storage.enterprise.status}
          </Typography>
        </TableCell>
        <TableCell width={'60px'}>
          {inactiveCustomers.includes(storage.enterprise.status) ? (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
              backgroundColor={'#f2665e'}
            >
              Inativo
            </TypographyActive>
          ) : (
            <TypographyActive
              style={{
                fontSize: `${themes.fontSizes.s}px`,
              }}
              textAlign="center"
            >
              Ativo
            </TypographyActive>
          )}
        </TableCell>
        <TableCell
          padding="checkbox"
          style={{
            textAlign: 'center',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <Icon color="gray-300">open_in_new_icon</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export { StorageItem };
