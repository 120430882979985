import { IPagination } from 'models/pagination';
import { IRole } from 'models/roles';

import api from '../../../config/api';
import { IFilterRoleParams } from './types';

export class RolesService {
  static async list(params?: IFilterRoleParams) {
    const { data } = await api.get<IPagination<IRole>>('/configs-role', {
      params,
    });
    return data;
  }
}
