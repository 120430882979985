import { Icon } from 'components';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useDocumentSearch } from '../hooks';
import { useDocumentStore } from '../store/store';

const DocumentSearchContainer = () => {
  const { setSearch } = useDocumentStore();
  const { handleChangeSearch } = useDocumentSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title="PDFs dinâmicos"
            icon={<Icon>picture_as_pdf</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { DocumentSearchContainer };
