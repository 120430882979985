import { useMutation, UseMutationOptions } from 'react-query';

import { IProcessAct } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateResponsible } from '../service';
import TabActServices from '../service/service';

const useUpdateResponsibleActRequest = (
  options?: UseMutationOptions<IProcessAct, IApiAxiosError, IUpdateResponsible>,
) => {
  return useMutation(
    'updateResponsibleAct',
    TabActServices.updateResponsibleAct,
    options,
  );
};

export { useUpdateResponsibleActRequest };
