import { useEffect, useState } from 'react';

import { useDebounce } from 'hooks';

export const useSerasaSearch = (
  debounceDelay: number,
  setSearch: (search: string) => void,
) => {
  const [searchText, setSearchText] = useState('');

  const handleChangeSearch = (value: string) => {
    setSearchText(value);
  };

  const debounced = useDebounce(searchText, debounceDelay);

  useEffect(() => {
    setSearch(debounced);
  }, [debounced, setSearch]);

  return { search: searchText, handleChangeSearch };
};
