import { Box, Grid, Typography } from '@mui/material';

import { Attachments, Dialog } from 'components';
import { IFormAnswer, QuestionType } from 'models';

interface Props {
  formData: IFormAnswer;

  open: boolean;
  setOpen: (open: boolean) => void;
}

const DynamicFormStorageModal = ({ formData, open, setOpen }: Props) => {
  const companyName =
    formData.user.enterprise?.bigData?.fantasyName ||
    formData.user.enterprise?.bigData?.socialReason;

  return (
    <Dialog.Root open={open} onClose={() => setOpen(false)}>
      <Dialog.Header title="Dados da resposta" />
      <Dialog.Content>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingX={5}
          gap={8}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Pergunta: </Typography>
                <Typography>{formData.formQuestion.question.title}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Descrição da pergunta: </Typography>
                <Typography>
                  {formData.formQuestion.question.description ?? ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Resposta: </Typography>
                <Typography>{formData.answerValue as string}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Tipo de pergunta: </Typography>
                <Typography>
                  {QuestionType[formData.formQuestion.question.type]}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Aceita anexos: </Typography>
                <Typography>
                  {formData.formQuestion.question.acceptAttachment ? 'Sim' : 'Não'}
                </Typography>
              </Box>
            </Grid>
            {formData.formQuestion.question.acceptAttachment && (
              <Grid item xs={12}>
                <Box display="flex" gap={2}>
                  {formData.attachments?.map(attachment => (
                    <Attachments key={attachment.id} file={attachment.file} />
                  ))}
                </Box>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Usuario: </Typography>
                <Typography>{`${formData.user.name} ${formData.user.surname}`}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Email: </Typography>
                <Typography>{formData.user.email}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Nome fantasia: </Typography>
                <Typography>{companyName}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Posição: </Typography>
                <Typography>{formData.user.position}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box display="flex" gap={2}>
                <Typography fontWeight={700}>Cargo: </Typography>
                <Typography>{formData.user.enterprise?.role}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { DynamicFormStorageModal };
