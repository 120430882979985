import { FilterAlt } from '@mui/icons-material';
import { Typography as MuiTypography, IconButton } from '@mui/material';

import { Box, ButtonOutlined, Link } from 'components';
import { ActStatus, IProcessAct, TypeProcessAct } from 'models';
import { Permissions } from 'models/roles/Permission';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { CustomerPendingIssueDialog } from 'modules/pendingIssues/components/CustomerPendingIssueDialog';
import { usePendingIssues } from 'modules/pendingIssues/hooks';

import { useFilterActs, useTabs } from '../../hooks';

interface OptionsProps {
  processActs: IProcessAct[];
}

const OptionsModalNegotiation = ({ processActs }: OptionsProps) => {
  const { handleOpenChangeResponsibleModal, currentTabInfos, valueTab } = useTabs();
  const { setOpenFilterModal } = useFilterActs();
  const { getSpecificPermissionRole } = useValidateRouteAccess();

  const type =
    currentTabInfos.typeOp === TypeProcessAct.CLIENT ? 'client' : 'operation';

  const id =
    type === 'client'
      ? currentTabInfos.enterpriseId ?? ''
      : currentTabInfos.operationId ?? '';

  const {
    openTabPendingIssuesDialog,
    pendingIssues,
    isLoading,
    handleOpenDialog,
    handleCloseDialog,
    form,
  } = usePendingIssues({
    type: currentTabInfos.typeOp,
    id,
  });

  const numberPendences = processActs.filter(
    act => ActStatus[act.status] === ActStatus.OPEN,
  );

  const tabActValue = 0;

  const renderChangeResponsible = getSpecificPermissionRole(
    Permissions.ChangeResponsible,
  );

  return (
    <>
      <CustomerPendingIssueDialog
        pendingIssues={pendingIssues ?? []}
        isLoading={isLoading}
        openDialog={openTabPendingIssuesDialog}
        handleCloseDialog={() => handleCloseDialog({ isTab: true })}
        form={form}
      />

      {valueTab === tabActValue && (
        <Box display={'flex'}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <MuiTypography style={{ marginTop: 5 }}>
              Responsável: {currentTabInfos.responsibleOperation}
            </MuiTypography>

            {renderChangeResponsible && (
              <Box width={'100%'} display={'flex'} justifyContent={'center'}>
                <Link
                  fontSize={12}
                  onClick={() =>
                    handleOpenChangeResponsibleModal(
                      currentTabInfos.responsibleOperation,
                      type,
                      id,
                    )
                  }
                >
                  Alterar
                </Link>
              </Box>
            )}
          </Box>

          <IconButton
            style={{ paddingRight: 9 }}
            onClick={() => setOpenFilterModal(true)}
          >
            <FilterAlt fontSize={'large'} />
          </IconButton>

          <ButtonOutlined
            color={numberPendences.length > 0 ? 'error' : 'gray-500'}
            label={`${numberPendences.length} ${
              numberPendences.length === 1 ? 'PENDÊNCIA' : 'PENDÊNCIAS'
            }`}
            onClick={() => handleOpenDialog({ isTab: true })}
          />
        </Box>
      )}
    </>
  );
};

export { OptionsModalNegotiation };
