import { Typography as MuiTypography } from '@mui/material';
import moment from 'moment';

import { Box, ButtonContained, Link } from 'components';
import { Permissions } from 'models/roles/Permission';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { useTabs } from 'modules/boards/tabs/hooks';

import { useTabAct } from '../../hooks';

interface CardActTimeLineProps {
  idAct: string;
  nameAct: string;
  operator: string;
  executionDate: Date;
  deadline?: string;
  responsible: string;
}

const CardActTimeLine = ({
  idAct,
  nameAct,
  operator,
  executionDate,
  deadline,
  responsible,
}: CardActTimeLineProps) => {
  const { handleOpenChangeResponsibleModal } = useTabs();
  const { handleMoreInformation } = useTabAct();
  const { getSpecificPermissionRole } = useValidateRouteAccess();

  const renderDeadline = deadline && deadline.length > 0;

  const enableResponsibleChange = getSpecificPermissionRole(
    Permissions.ChangeActResponsible,
  );

  return (
    <Box paddingX={2}>
      <MuiTypography style={{ fontSize: 20, fontWeight: 'bold' }}>
        {nameAct}
      </MuiTypography>
      <>
        <MuiTypography style={{ fontSize: 16, padding: 0, marginTop: 8 }}>
          Operador: {operator}
        </MuiTypography>
        {executionDate && (
          <MuiTypography style={{ fontSize: 16, padding: 0, margin: 0 }}>
            Data: {moment(executionDate).format('DD/MM/YYYY [às] HH:mm')}
          </MuiTypography>
        )}
        {renderDeadline && (
          <MuiTypography style={{ fontSize: 16, padding: 0, margin: 0 }}>
            Prazo: {deadline}
          </MuiTypography>
        )}
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <MuiTypography
            style={{ fontSize: 16, padding: 0, paddingRight: 10, margin: 0 }}
          >
            Responsável: {responsible}
          </MuiTypography>

          {enableResponsibleChange && (
            <Link
              style={{ paddingLeft: 5, marginLeft: 5 }}
              fontSize={12}
              onClick={() =>
                handleOpenChangeResponsibleModal(responsible, 'act', idAct)
              }
            >
              Alterar
            </Link>
          )}
        </Box>
        <Box width={'100%'} display={'flex'} justifyContent={'end'} mt={5}>
          <ButtonContained
            color="gray-700"
            label="Mais informações"
            onClick={() => handleMoreInformation(idAct, 'acts')}
          />
        </Box>
      </>
    </Box>
  );
};

export { CardActTimeLine };
