import { useQuery } from 'react-query';

import { ISystemValueMapping } from 'models';

import { SystemValueMappingService } from 'modules/systemValueMapping';

import { OptionsValuesString } from 'utils/helper';

export const useListAllVariables = ({ enabled }: { enabled?: boolean }) => {
  const { data, isLoading, refetch } = useQuery(
    ['systemValueMapping'],
    () => SystemValueMappingService.find() as Promise<ISystemValueMapping[]>,
    { enabled: enabled ?? true, keepPreviousData: true },
  );

  const variablesOptions: OptionsValuesString[] =
    data?.map(variables => ({
      value: variables.id,
      label: `${variables.title}`,
    })) || [];

  return {
    variables: variablesOptions,
    isLoading,
    refetch,
  };
};
