import { useCallback } from 'react';

import { IEnterprise } from 'models';

import { useCustomerManagementStore } from '../store/store';

export const useSelectedCustomers = () => {
  const {
    selectedCustomers,
    setSelectedCustomers,
    customersAmount,
  } = useCustomerManagementStore();

  const clearSelectedCustomers = useCallback(() => {
    setSelectedCustomers([]);
  }, []);

  const handleRemoveCustomer = (customer: IEnterprise) => {
    const customers = selectedCustomers.filter(
      selectedCustomer => selectedCustomer.id !== customer.id,
    );

    setSelectedCustomers(customers);
  };

  const handleAddSelectedCustomer = (customer: IEnterprise) => {
    setSelectedCustomers([...selectedCustomers, customer]);
  };

  const handleChangeSelectedCustomers = (customer: IEnterprise) => {
    const isSelected = selectedCustomers.some(
      selectedCustomer => selectedCustomer.id === customer.id,
    );

    if (isSelected) {
      handleRemoveCustomer(customer);

      return;
    }

    handleAddSelectedCustomer(customer);

    return;
  };

  const isCustomerSelected = (customer: IEnterprise) =>
    selectedCustomers.some(selectedCustomer => selectedCustomer.id === customer.id);

  const numberOfSelectedCustomers = selectedCustomers.length;

  const handleChangeAll = (customers: IEnterprise[] = []) => {
    if (customers.length === selectedCustomers.length) {
      clearSelectedCustomers();
      return;
    }

    setSelectedCustomers([...customers]);

    return;
  };

  const isAllSelected = () =>
    selectedCustomers.length > 0 && customersAmount === selectedCustomers.length;

  return {
    selectedCustomers,
    handleChangeSelectedCustomers,
    isCustomerSelected,
    numberOfSelectedCustomers,
    handleChangeAll,
    isAllSelected,
  };
};
