import { ReactNode } from 'react';

import { ContentWrapper } from './styles';

interface ContentProps {
  children: ReactNode;
}

export function Content({ children }: ContentProps) {
  return <ContentWrapper as="main">{children}</ContentWrapper>;
}
