import styled, { css } from 'styled-components';
import {
  color,
  fontFamily,
  fontWeight,
  fontSize,
  lineHeight,
  textAlign,
  borderRadius,
  variant,
  BackgroundColorProps,
  TextColorProps,
  FontFamilyProps,
  FontWeightProps,
  FontSizeProps,
  TextAlignProps,
  LineHeightProps,
  BorderRadiusProps,
} from 'styled-system';

export const variants = {
  backgroundColorGreen: {
    backgroundColor: '#037318',
  },
  h1: {
    fontSize: 'h1',
    lineHeight: 'xl',
    fontWeight: 'extraBold',
  },
  h2: {
    fontSize: 'h2',
    lineHeight: 'xl',
    fontWeight: 'extraBold',
  },
  h3: {
    fontSize: 'h3',
    lineHeight: 'xl',
    fontWeight: 'bold',
  },
  h4: {
    fontSize: 'h4',
    lineHeight: 'xl',
    fontWeight: 'bold',
  },
  h5: {
    fontSize: 'h5',
    lineHeight: 'xl',
    fontWeight: 'semiBold',
  },
  h6: {
    fontSize: 'h6',
    lineHeight: 'xl',
    fontWeight: 'medium',
  },
  subtitle1: {
    fontSize: 'l',
    lineHeight: 'xxxl',
    fontWeight: 'regular',
  },
  subtitle2: {
    fontSize: 'm',
    lineHeight: 'xxl',
    fontWeight: 'regular',
  },
  subtitle3: {
    fontSize: 'm',
    lineHeight: 'xxl',
    fontWeight: 'bold',
  },
  body1: {
    fontSize: 'l',
    lineHeight: 'xxxl',
    fontWeight: 'regular',
    borderRadius: '15px',
  },
  body2: {
    fontSize: 'm',
    lineHeight: 'xxl',
    fontWeight: 'regular',
  },
  body3: {
    fontSize: 's',
    lineHeight: 'xxxl',
    fontWeight: 'regular',
  },
};

type Variants = typeof variants;

type StyledSystemProps = TextColorProps &
  FontFamilyProps &
  FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  TextAlignProps &
  BackgroundColorProps &
  BorderRadiusProps;

export interface Props extends StyledSystemProps {
  variant?: keyof Variants;
}

const TypographyActive = styled.p<Props>`
  margin: 0;
  padding: 0 10px;

  ${fontFamily}
  ${color}
  ${textAlign}
  ${borderRadius}

  ${props => {
    if (props.variant) {
      return variant({
        variants,
      });
    }

    return css`
      ${fontSize}
      ${lineHeight}
      ${fontWeight}
      ${textAlign}
    `;
  }}
`;

TypographyActive.defaultProps = {
  fontFamily: 'body',
  color: '#fff', //'text.primary',
  variant: undefined,
  fontSize: variants.body1.fontSize,
  fontWeight: variants.body1.fontWeight,
  lineHeight: variants.body1.lineHeight,
  borderRadius: variants.body1.borderRadius,
  backgroundColor: variants.backgroundColorGreen.backgroundColor,
};

export default TypographyActive;
