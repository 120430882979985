import { Switch } from '@mui/material';
import { Control } from 'react-hook-form';
import themes from 'themes';

import { Box, ButtonContained, Typography } from 'components';
import { QuestionType } from 'models';

import { useFalseExternQuestionAutocomplete } from '../../hooks';
import { useCardQuestion } from '../../hooks/useCardQuestion';
import { IQuestionForm, IQuestionSwitchForm } from '../../services';
import { QuestionsAutocomplete } from './QuestionsAutocomplete';
import { CardConditionMultipleChoice } from './cardConditionMultipleChoice';
import { CardConditionText } from './cardConditionsText';

interface DetailsProps {
  questionForm: IQuestionForm;
  control: Control<IQuestionSwitchForm>;
  identifierQuestion: number;
  addCondition: (identifierQuestion: number) => void;
}
export const DetailsQuestionDropdown = ({
  questionForm,
  identifierQuestion,
  addCondition,
}: DetailsProps) => {
  const {
    formFalseQuestionAutocomplete,
    allQuestions,
    setSearch,
    isOptionEqualToValue,
    handleChange,
  } = useFalseExternQuestionAutocomplete();

  const {
    handleChangeSwitchIsFirstQuestion,
    handleChangeSwitchIsLastQuestion,
  } = useCardQuestion();

  const question = questionForm.question;
  const questionFalseExternId = questionForm?.nextQuestionFalse;
  const questionFalseExtern = allQuestions.find(
    question => question?.value === questionFalseExternId,
  );
  const switchIsFirst = questionForm?.isFirstQuestion;
  const switchIsLast = questionForm?.isLastQuestion;

  if (!question?.title) {
    return <></>;
  }

  return (
    <>
      <Box display={'flex'} ml={10}>
        <Box>
          <Typography fontSize={22}> Detalhes</Typography>
          <Box mt={2}>
            <Typography fontSize={15} color={themes.colors.black}>
              {question?.title}
            </Typography>
            <Box mt={2}>
              <Typography fontSize={15} color={themes.colors.black}>
                {QuestionType[question?.type]}
              </Typography>
            </Box>
          </Box>
        </Box>

        {QuestionType[question?.type] === QuestionType.MULTIPLE_CHOICE && (
          <Box
            ml={25}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
          >
            <Typography fontSize={22}> Opções</Typography>
            {question?.answerOptions.map((answer, index) => (
              <Box mt={2} key={index}>
                <Typography fontSize={15} color={themes.colors.black}>
                  {answer?.value}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      <Box display={'flex'} alignItems={'center'} width={600} mt={5} ml={10}>
        {!questionForm.isChild && (
          <>
            <Switch
              name="initialQuestion"
              onChange={() => handleChangeSwitchIsFirstQuestion(identifierQuestion)}
              checked={switchIsFirst}
              disabled={!questionForm.isChild}
            />
            <Typography color={'#b5b3b3'}>
              Pergunta de início de formulário
            </Typography>
          </>
        )}
        <Switch
          name="lastQuestion"
          onChange={() => handleChangeSwitchIsLastQuestion(identifierQuestion)}
          checked={switchIsLast}
        />
        <Typography>Pergunta de final de formulário</Typography>
      </Box>

      {questionForm?.conditions &&
        questionForm?.conditions.map((condition, index) =>
          QuestionType[questionForm.question.type] ===
            QuestionType.MULTIPLE_CHOICE && condition.identifierCondition > 0 ? ( //nao deletado
            <CardConditionMultipleChoice
              key={index}
              question={questionForm}
              identifierCondition={condition.identifierCondition}
            />
          ) : (
            condition.identifierCondition > 0 && (
              <CardConditionText
                key={index}
                question={questionForm}
                identifierCondition={condition.identifierCondition}
              />
            )
          ),
        )}

      <Box display={'flex'} justifyContent={'center'} mt={8}>
        <ButtonContained
          label="Adicionar Condicional"
          onClick={() => addCondition(identifierQuestion)}
          disabled={questionForm?.isLastQuestion}
        />
      </Box>

      <Typography>Opção Se False</Typography>

      <Typography>Próxima pergunta Se False</Typography>

      <QuestionsAutocomplete
        question={questionForm}
        setSearch={setSearch}
        form={formFalseQuestionAutocomplete}
        value={questionFalseExtern?.label}
        allQuestions={allQuestions}
        isOptionEqualToValue={isOptionEqualToValue}
        handleChange={handleChange}
        disabled={questionForm.isLastQuestion}
      />
    </>
  );
};
