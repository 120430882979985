import create from 'zustand';

import { OptionsValuesString } from 'utils/helper';

import { ISignInPayload } from '../services';

interface Store {
  email: string;
  setEmail: (email: string) => void;

  step: number;
  setStep: () => void;

  listEnterprises: OptionsValuesString[];
  setListEnterprises: (listEnterprises: OptionsValuesString[]) => void;

  signInData: ISignInPayload;
  setSignInData: (signInData: ISignInPayload) => void;

  clearSignInData: () => void;
}

const useSignInStore = create<Store>(set => ({
  email: '',
  setEmail: email => set({ email }),

  step: 1,
  setStep: () => set(set => ({ step: set.step === 2 ? 1 : set.step + 1 })),

  listEnterprises: [],
  setListEnterprises: listEnterprises => set({ listEnterprises }),

  signInData: {} as ISignInPayload,
  setSignInData: signInData => set({ signInData }),

  clearSignInData: () => set({ signInData: {} as ISignInPayload, step: 1 }),
}));

export { useSignInStore };
