import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { validateCNPJ } from 'utils/validateCNPJ';
import { validateCPF } from 'utils/validateCPF';
import { validateDate } from 'utils/validateDate';

import { ISignUpEnterpriseForm } from '../services';
import { useSignUpEnterpriseStore } from '../store/store';

export const useSignUpEnterpriseForm = () => {
  const { enterprisePayload } = useSignUpEnterpriseStore();

  const signUpSchema = z
    .object({
      cnpj: z
        .string()
        .trim()
        .length(18, 'O CNPJ deve conter 14 dígitos')
        .nonempty('CNPJ é obrigatório')
        .refine(cnpj => {
          return validateCNPJ(cnpj);
        }, 'CNPJ inválido'),
      fantasyName: z.string().trim().nonempty('Nome fantasia é obrigatório'),
      name: z.string().trim().nonempty('Nome é obrigatório'),
      surname: z.string().trim().nonempty('Sobrenome é obrigatório'),
      cpf: z
        .string()
        .trim()
        .nonempty('CPF é obrigatório')
        .refine(cpf => {
          return validateCPF(cpf);
        }, 'CPF inválido'),
      birthDate: z
        .string()
        .trim()
        .nonempty('Data de nascimento é obrigatório')
        .refine(birthDate => {
          return validateDate(birthDate);
        }, 'Data inválida'),
      email: z
        .string()
        .trim()
        .nonempty('E-mail é obrigatório')
        .email('E-mail inválido'),
      confirmEmail: z
        .string()
        .trim()
        .nonempty('Confirmação de E-mail é obrigatório')
        .email('E-mail inválido'),
      telephone: z.string().trim().nonempty('Telefone é obrigatório'),
      site: z.string().trim().nonempty('Site é obrigatório'),
      position: z.string().trim().nonempty('Selecione um cargo'),
    })
    .refine(({ email, confirmEmail }) => email === confirmEmail, {
      message: 'Confirmação de e-mail incorreta',
      path: ['confirmEmail'],
    });

  const defaultValues = {
    cnpj: enterprisePayload.cnpj ?? '',
    email: enterprisePayload.email ?? '',
    confirmEmail: '',
    name: enterprisePayload.name ?? '',
    surname: enterprisePayload.surname ?? '',
    fantasyName: enterprisePayload.fantasyName ?? '',
    cpf: enterprisePayload.cpf ?? '',
    birthDate: enterprisePayload.birthDate ?? '',
    telephone: enterprisePayload.telephone ?? '',
    site: enterprisePayload.site ?? '',
    position: enterprisePayload.position ?? '',
  };

  return useForm<ISignUpEnterpriseForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(signUpSchema),
  });
};
