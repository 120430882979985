import { useMutation, UseMutationOptions } from 'react-query';

import { IQuestion } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IQuestionPayload } from '../services';
import { QuestionService } from '../services/services';

const useCreateQuestionRequest = (
  options?: UseMutationOptions<IQuestion, IApiAxiosError, IQuestionPayload>,
) => {
  return useMutation('createQuestion', QuestionService.create, options);
};

export { useCreateQuestionRequest };
