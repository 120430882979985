import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { IQuestionInput } from 'models';

import { OptionsValues } from 'utils/helper';

import { useListAllQuestions, useQuestionAutocompleteForm } from '.';
import { useGetFormsQuestion } from '.';
import { IQuestionForm } from '../services';
import { useFormStore } from '../store/store';

export const useFalseAutocompleteConditionMultipleChoice = () => {
  const [search, setSearch] = useState('');
  const { questionsOptions, dataAllQuestions } = useListAllQuestions();
  const form = useQuestionAutocompleteForm();
  const { questionsForm, setQuestionsForm } = useFormStore();
  const { handleDeleteSpecificChildQuestion } = useGetFormsQuestion();

  const handleChangeAutocomplete = (
    selectedOption: OptionsValues,
    field: ControllerRenderProps<IQuestionInput, 'title'>,
    question: IQuestionForm,
    identifierCondition?: number,
  ) => {
    const value = selectedOption ? selectedOption.label.split(' - ').pop() : null;

    field.onChange(value);

    if (!identifierCondition) return;

    // pergunta que foi selecionada para a questão do formulário
    const questionSelected = dataAllQuestions?.find(
      question => question.id === `${selectedOption?.value}`,
    );

    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const questionChanged =
      questionSelected?.id !==
      questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse;

    if (!questionChanged) return;

    const idQuestionChanged =
      questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse;

    const questionChildExcluded = questionsForm.find(
      qF => qF?.question?.id === idQuestionChanged && qF.numberQuestion !== -1,
    );

    if (questionChildExcluded)
      handleDeleteSpecificChildQuestion(questionChildExcluded);

    if (questionSelected) {
      questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse =
        questionSelected.id;

      questionsForm[indexQuestion].conditions[
        indexCondition
      ].nextConditionFalseLabel = questionSelected.title;
      setQuestionsForm(questionsForm);
    } else {
      questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse =
        '';

      questionsForm[indexQuestion].conditions[
        indexCondition
      ].nextConditionFalseLabel = '';
      setQuestionsForm(questionsForm);
    }
  };

  const isOptionEqualToValue = (option: OptionsValues, value: string) => {
    return option.label.split(' - ').pop() === value;
  };

  function handleShowOptionFalse(
    question: IQuestionForm,
    identifierCondition: number,
  ) {
    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const conditionShowFalseOption =
      questionsForm[indexQuestion].conditions[indexCondition]?.showOptionFalse ===
        undefined ||
      questionsForm[indexQuestion].conditions[indexCondition]?.showOptionFalse ===
        false;

    if (conditionShowFalseOption) {
      questionsForm[indexQuestion].conditions[
        indexCondition
      ].showOptionFalse = true;

      setQuestionsForm(questionsForm);
      return;
    }

    questionsForm[indexQuestion].conditions[indexCondition].showOptionFalse = false;

    setQuestionsForm(questionsForm);
  }

  function handleDeleteOptionFalse(
    question: IQuestionForm,
    identifierCondition: number,
  ) {
    const indexQuestion = question.numberQuestion - 1;
    const indexCondition = identifierCondition - 1;

    const conditionShowFalseOption =
      questionsForm[indexQuestion].conditions[indexCondition]?.showOptionFalse;

    if (conditionShowFalseOption) {
      questionsForm[indexQuestion].conditions[
        indexCondition
      ].showOptionFalse = false;

      const idQuestionChanged =
        questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse;

      const questionChildExcluded = questionsForm.find(
        qF => qF?.question?.id === idQuestionChanged,
      );

      if (questionChildExcluded)
        handleDeleteSpecificChildQuestion(questionChildExcluded);

      questionsForm[indexQuestion].conditions[indexCondition].nextConditionFalse =
        '';

      questionsForm[indexQuestion].conditions[
        indexCondition
      ].nextConditionFalseLabel = '';

      setQuestionsForm(questionsForm);
      return;
    }
  }

  return {
    search,
    allQuestions: questionsOptions,
    formFalseQuestionConditionAutocomplete: {
      ...form,
    },
    setSearch,
    handleChangeAutocomplete,
    isOptionEqualToValue,
    handleShowOptionFalse,
    handleDeleteOptionFalse,
  };
};
