import { IconButton } from '@mui/material';

import { Icon, Box, ClearFilters } from 'components';
import { ButtonDownloadExcel } from 'components/FilterDatabase/ButtonDownloadExcel';

import { PrivateLayout } from 'layouts/PrivateLayout';

import {
  useDownloadExcelKmethod,
  useKMethodFilter,
  useKMethodSearch,
} from '../hooks';
import { useKMethodStore } from '../store/store';

const KMethodSearchContainer = () => {
  const { setSearch } = useKMethodStore();
  const { handleChangeSearch } = useKMethodSearch(500, setSearch);
  const { isFiltering, resetFilter, handleOpenFilterDialog } = useKMethodFilter();
  const { handleDownloadExcel } = useDownloadExcelKmethod();

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title={'Base de dados - Módulo "K"'}
            icon={<Icon>storage</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />
          <PrivateLayout.Content.Header.Actions>
            <Box
              position="relative"
              minWidth={40}
              display={'flex'}
              justifyContent={'center'}
              marginRight={3}
            >
              <IconButton onClick={handleOpenFilterDialog}>
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={resetFilter} />}
            </Box>
            <ButtonDownloadExcel
              handleDownload={() => handleDownloadExcel()}
              loading={false}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { KMethodSearchContainer };
