import { Checkbox, Table, TableCell, Tooltip } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useCustomer } from '../hooks';
import { useSelectedCustomers } from '../hooks/useSelectedCustomers';
import { CustomerItem } from './CustomerItem';

const Customers = () => {
  const {
    data: customersList,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useCustomer();

  const {
    numberOfSelectedCustomers,
    handleChangeAll,
    isAllSelected,
    // selectedCustomers,
  } = useSelectedCustomers();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" padding="checkbox">
              <Tooltip
                title={
                  numberOfSelectedCustomers >= 1
                    ? `${numberOfSelectedCustomers} item(s) selecionado(s)`
                    : 'Selecionar todos'
                }
              >
                <Checkbox
                  checked={isAllSelected()}
                  onChange={() => handleChangeAll(customersList)}
                />
              </Tooltip>
            </TableCell>
            {/* {[
              'CNPJ',
              'Nome  fantasia',
              'Razão social',
              'Empresa Principal',
              'Nome representante',
              'Tipo',
              'Responsável',
              'Status do Cliente',
              'Produto',
              'Ativo',
              'Visualizar',
            ].map(column => (
              <TableCell key={column} align="center">
                <Typography variant="subtitle3">{column}</Typography>
              </TableCell>
            ))} */}
            <TableCell align="center" width="200px">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'CNPJ'}
              </Typography>
            </TableCell>
            <TableCell align="center" width="300px">
              <Typography
                variant="subtitle3"
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
              >
                {'Nome fantasia'}
              </Typography>
            </TableCell>
            <TableCell align="center" width="300px">
              <Typography
                variant="subtitle3"
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
              >
                {'Razão social'}
              </Typography>
            </TableCell>
            <TableCell align="center" width="300px">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Empresa Principal'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Nome representante'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Tipo'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Responsável'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Status do Cliente'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Produto'}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                {'Ativo'}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          columns={10}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Nenhum cliente encontrado"
        >
          {customersList?.map(customer => (
            <CustomerItem key={customer.id} {...customer} />
          ))}
        </TableBody>
      </Table>
      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};

export { Customers };
