import { TextField } from 'components';

import { useForgotPasswordCode } from '../hooks';
import FormWrapper from './FormWrapper';

function CodeForm() {
  const {
    form: {
      control,
      formState: { errors },
      handleSubmitForm,
    },
    request: { isLoading },
  } = useForgotPasswordCode();

  return (
    <FormWrapper
      isLoading={isLoading}
      handleSubmitForm={handleSubmitForm}
      submitFormButtonLabel="Confirmar"
    >
      <TextField
        name="code"
        label="Código"
        control={control}
        type="text"
        error={errors.code}
        sx={{
          '& .MuiOutlinedInput-root': {
            zIndex: 0,
            '& input': {
              zIndex: 1,
            },
            '& fieldset': {
              backgroundColor: '#e0e0e0',
              border: 'none',
              borderRadius: '8px',
            },
          },
        }}
      />
    </FormWrapper>
  );
}

export default CodeForm;
