import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  ForgotPasswordService,
  IForgotPasswordPayload,
  IForgotPasswordResponse,
} from '../services';

const useForgotPasswordRequest = (
  options: UseMutationOptions<
    IForgotPasswordResponse,
    IApiAxiosError,
    IForgotPasswordPayload
  >,
) => {
  return useMutation(
    'forgotPassword-changePassword',
    ForgotPasswordService.changePassword,
    options,
  );
};

export { useForgotPasswordRequest };
