import * as React from 'react';

import { useQuestionStep } from '.';

export const useQuestionsSteppper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const { form } = useQuestionStep();

  const steps = ['Pergunta', 'Resposta', 'Dados'];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = (submitStepForm: () => void) => {
    submitStepForm();
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  return {
    formQuestion: form,
    steps,
    activeStep,
    completed,
    completedSteps,
    isLastStep,
    handleNext,
    handleComplete,
    handleStep,
  };
};

export type UseQuestionsSteppper = ReturnType<typeof useQuestionsSteppper>;
