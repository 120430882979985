import { SpeedDial } from '@mui/material';
import { darken } from 'polished';
import styled from 'styled-components';

const RootWrapper = styled(SpeedDial)`
  && {
    position: absolute;
    right: ${({ theme }) => theme.space[9]}px;
    bottom: ${({ theme }) => theme.space[9]}px;
  }

  > button {
    background-color: ${({ theme }) => theme.colors.blue};
    font-size: ${({ theme }) => theme.fontSizes.h6}px;
    font-weight: bold;

    :hover {
      background-color: ${({ theme }) => darken(0.08, theme.colors.blue)};
    }
  }
`;

export { RootWrapper };
