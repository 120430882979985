import { Box, Card } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import 'chart.js/auto';
import plugin from 'chartjs-plugin-datalabels';
import { useState } from 'react';
import { Chart } from 'react-chartjs-2';
import themes from 'themes';

import { Indebtedness } from 'models';

import { useDashboardStore } from 'modules/dashboard/store/store';

import { capitalizeWords } from 'utils/capitalizeWords';

import { getColors, increaseDistanceLegendPlugin } from './charts.types';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface ItemChart {
  name: string;
  total: number;
}

interface DebtsProps {
  data: Indebtedness[];
}

const DebtsChart = ({ data }: DebtsProps) => {
  const { setChartsImagesUrl } = useDashboardStore();
  const [imagesSet, setImagesSet] = useState(false);

  const heightWindow = window.innerHeight;
  const widthPie = heightWindow > 900 ? '36%' : '40%';
  const exfinColors = true;

  const getDataTableItem = () => {
    const totals: ItemChart[] = [];

    data?.map(debt => {
      totals.push({
        name: capitalizeWords(debt.institution),
        total: debt.totalCurrentDebts,
      });
    });

    return totals;
  };

  const dataChart = getDataTableItem();

  // Calculando o valor total
  const totalSum = dataChart.reduce(
    (acc, currentItem) => acc + currentItem.total,
    0,
  );
  let others = 0;

  const percentageGreater10: ItemChart[] = [];

  // Checando se o valor é acima de 10%
  dataChart.map(item => {
    const valuePercent = (item.total / totalSum) * 100;
    if (valuePercent > 10) {
      percentageGreater10.push({
        name: item.name,
        total: valuePercent,
      });
      return;
    }
    others = others + valuePercent;
  });

  const getPercentageData = (others: number) => {
    const percentageData = percentageGreater10.map(item =>
      Number(item.total.toFixed(2)),
    );
    if (others > 0) {
      percentageData.push(Number(others.toFixed(2)));
    }
    return percentageData;
  };

  const getInstutionsName = (data: ItemChart[], others: number) => {
    const allNames = data.map(item => item.name);
    if (others > 0) {
      allNames.push('Outros');
    }

    return allNames;
  };

  return (
    <Card
      elevation={2}
      style={{ width: widthPie, padding: 14, maxHeight: 554, marginLeft: 1 }}
    >
      <Box width={'100%'} maxHeight={600}>
        <Chart
          type="pie"
          data={{
            labels: getInstutionsName(percentageGreater10, others),
            datasets: [
              {
                label: 'Dado',
                data: getPercentageData(others),
                backgroundColor: getColors(getPercentageData(others), !exfinColors),
              },
            ],
          }}
          plugins={[plugin, increaseDistanceLegendPlugin(20)]}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
                labels: {
                  color: themes.colors.dashboard.table.text.black,
                },
              },
              datalabels: {
                formatter: (value, ctx) => {
                  const chart = ctx.chart;

                  const sum = chart.data.datasets.reduce((acc, curr) => {
                    curr.data.forEach((d, i) => {
                      if (chart.getDataVisibility(i)) {
                        acc += Number(d);
                      }
                    });

                    return acc;
                  }, 0);
                  const percentage = ((value * 100) / sum).toFixed(2) + '%';
                  return percentage;
                },
                color: '#fff',
                font: {
                  weight: 'bold',
                },
              },
              title: {
                display: true,
                text: 'Saldo devedor Atual vs Instituição Financeira',
                font: {
                  size: 24,
                },
                color: themes.colors.dashboard.table.text.black,
                align: 'start',
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    const chart = context.chart;
                    const value = context.parsed;

                    const sum = chart.data.datasets.reduce((acc, curr) => {
                      curr.data.forEach((d, i) => {
                        if (chart.getDataVisibility(i)) {
                          acc += Number(d);
                        }
                      });

                      return acc;
                    }, 0);

                    const percentage = ((value * 100) / sum).toFixed(2);
                    return context.label + ': ' + percentage + '%';
                  },
                },
              },
            },
            animation: {
              onComplete: animation => {
                if (!imagesSet) {
                  setChartsImagesUrl(
                    'debts',
                    animation.chart.toBase64Image(),
                    animation.chart.width,
                    animation.chart.height,
                  );

                  setImagesSet(true);
                }
              },
            },
          }}
        />
      </Box>
    </Card>
  );
};

export { DebtsChart };
