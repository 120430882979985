import { ReactNode, useContext } from 'react';

import { PrivateLayoutContext } from 'layouts/PrivateLayout/Context';

import { RootWrapper } from './styles';

interface RootProps {
  children: ReactNode;
}

export function Root({ children }: RootProps) {
  const {
    sidebarToggled,
    sidebarBroken,
    setSidebarToggled,
    setSidebarBroken,
  } = useContext(PrivateLayoutContext);

  return (
    <RootWrapper
      toggled={sidebarToggled}
      broken={sidebarBroken}
      onBackdropClick={() => setSidebarToggled(false)}
      onBreakPoint={setSidebarBroken}
      breakPoint="xl"
    >
      {children}
    </RootWrapper>
  );
}
