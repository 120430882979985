import styled from 'styled-components';

export const FileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[5]}px;
  padding: ${({ theme }) => theme.space[5]}px;
  width: 100%;
  max-width: 350px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[5]}px;

  padding: ${({ theme }) => theme.space[3]}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-100']};

  p {
    text-align: center;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
`;
