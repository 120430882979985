import { useQuery } from 'react-query';

import { DocumentQueryKey } from '../services';
import DocumentService from '../services/services';

export const useActOperationDocumentsRequest = (id: string) => {
  const { data, ...query } = useQuery(
    [DocumentQueryKey.OPERATION_DOCS_LIST],
    () => DocumentService.getOperationDocuments({ id }),
    { keepPreviousData: true },
  );

  return {
    data,
    ...query,
  };
};
