import api from 'config/api';

import { SystemValueMappingResponse } from './types';

export class DynamicFormService {
  static async getSystemValueMapping(formId: string) {
    const { data } = await api.get<SystemValueMappingResponse[]>(
      `/system-value-mapping/${formId}`,
    );

    return data;
  }
}
