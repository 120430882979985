import { create } from 'zustand';

import { OptionsValuesString } from 'utils/helper';

import {
  IActTimelineFilterByOption,
  IActTimelineFilter,
  DEFAULT_ACT_TIMELINE_FILTER_BY,
  ICurrentTabInfo,
} from '../services';

export type typeChangeResponsible = 'act' | 'operation' | 'client';

type State = {
  openNegotiationModal: boolean;
  openSupportExFinModal: boolean;
  openChangeResponsibleModal: boolean;
  newResponsible: OptionsValuesString;
  idChangeResponsible: string;
  typeChangeResponsible: typeChangeResponsible;

  openFilterModal: boolean;
  searchFilterOption: string;
  currentFilter: IActTimelineFilter[];
  filterBy: IActTimelineFilterByOption;

  currentTabInfos: ICurrentTabInfo;
  valueTab: number;
};

const initialState: State = {
  openNegotiationModal: false,
  openSupportExFinModal: false,
  openChangeResponsibleModal: false,
  newResponsible: {} as OptionsValuesString,
  idChangeResponsible: '',
  typeChangeResponsible: 'act',

  openFilterModal: false,
  searchFilterOption: '',
  currentFilter: [],
  filterBy: DEFAULT_ACT_TIMELINE_FILTER_BY,

  currentTabInfos: {} as ICurrentTabInfo,
  valueTab: 0,
};

interface Actions {
  setOpenNegotiationModal: (openCreateActDialog: boolean) => void;
  setOpenSupportExFinModal: (openSupportExFin: boolean) => void;
  setOpenChangeResponsibleModal: (change: boolean) => void;
  setNewResponsible: (newResponsible: OptionsValuesString) => void;
  setIdChangeResponsible: (id: string) => void;
  setTypeChangeResponsible: (type: typeChangeResponsible) => void;

  setOpenFilterModal: (openFilterModal: boolean) => void;
  setSearchFilterOption: (searchFilterOption: string) => void;
  setCurrentFilter: (filters: IActTimelineFilter[]) => void;
  setFilterBy: (filter: IActTimelineFilterByOption) => void;

  setCurrentTabInfos: (currentTabInfos: ICurrentTabInfo) => void;
  setValueTab: (valueTab: number) => void;

  clearTabsStore: () => void;
}

export const useTabsStore = create<State & Actions>(set => ({
  ...initialState,

  setOpenNegotiationModal: openNegotiationModal => set({ openNegotiationModal }),
  setOpenSupportExFinModal: openSupportExFinModal => set({ openSupportExFinModal }),
  setOpenChangeResponsibleModal: openChangeResponsibleModal =>
    set({ openChangeResponsibleModal }),
  setNewResponsible: newResponsible => set({ newResponsible }),
  setIdChangeResponsible: idChangeResponsible => set({ idChangeResponsible }),
  setTypeChangeResponsible: typeChangeResponsible => set({ typeChangeResponsible }),

  setOpenFilterModal: openFilterModal => set({ openFilterModal }),
  setSearchFilterOption: searchFilterOption => set({ searchFilterOption }),
  setCurrentFilter: currentFilter => set({ currentFilter }),
  setFilterBy: filterBy => set({ filterBy }),

  setCurrentTabInfos: currentTabInfos => set({ currentTabInfos }),
  setValueTab: valueTab => set({ valueTab }),

  clearTabsStore: () => {
    set(initialState);
  },
}));
