import { useMutation, UseMutationOptions } from 'react-query';

import { IEnterprise } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IUpdateResponsible } from '../components';
import { TabsService } from '../services';

const useUpdateResponsibleClientRequest = (
  options?: UseMutationOptions<IEnterprise, IApiAxiosError, IUpdateResponsible>,
) => {
  return useMutation(
    'updateResponsibleClient',
    TabsService.updateResponsibleClient,
    options,
  );
};

export { useUpdateResponsibleClientRequest };
