import { UseMutationOptions, useMutation } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import { EmailService, IValidateCodeResponse } from 'modules/email';

import { IForgotPasswordValidationCodePayload } from '../services';

const useForgotPasswordCodeRequest = (
  options: UseMutationOptions<
    IValidateCodeResponse,
    IApiAxiosError,
    IForgotPasswordValidationCodePayload
  >,
) => {
  return useMutation(
    'forgotPassword-isCodeValid',
    // ForgotPasswordService.isCodeValid,
    EmailService.validateCode,
    options,
  );
};

export { useForgotPasswordCodeRequest };
