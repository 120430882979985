import { Tab, Tabs } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  Dialog,
  ButtonOutlined,
  Box,
  ButtonContained,
  SectionButtons,
} from 'components';
import { TypeProcessAct } from 'models';
import { Permissions } from 'models/roles/Permission';

import { useValidateRouteAccess } from 'layouts/PrivateLayout/hooks';

import { DocumentsCustomerTable } from 'modules/boards/documents';
import DocumentsOperationTable from 'modules/boards/documents/components/DocumentsOperationTable';

import { useTabs } from '../../hooks';
import { ModalChangeResponsible } from '../ModalChangeResponsible';
import { ModalFilterActs } from '../ModalFilterActs';
import { ModalSuportExFin } from '../ModalSupportExfin';
import { TabAct } from '../tabActs';
import { useTabAct } from '../tabActs/hooks';
import { OptionsModalNegotiation } from './Options';
import { TabPanel } from './tabPanel';

export const ModalNegotiation = () => {
  const {
    openNegotiationModal,
    valueTab,
    currentTabInfos,
    setOpenSupportExFinModal,
    getProps,
    handleChangeTab,
    handleCloseDialog,
  } = useTabs();
  const { getSpecificPermissionRole } = useValidateRouteAccess();

  const { pathname } = useLocation();

  const renderTabDoc = getSpecificPermissionRole(Permissions.ShowDocs);

  const heightWindow = window.innerHeight;
  const widthModalNegotiation = heightWindow > 900 ? 1300 : 1100;
  const heightModalNegotiation = heightWindow > 900 ? 650 : 450;

  const { processActs } = useTabAct();

  return (
    <Dialog.Root open={openNegotiationModal} onClose={() => handleCloseDialog()}>
      <ModalChangeResponsible />
      <ModalSuportExFin />
      <ModalFilterActs />

      <Dialog.Header
        title={`${
          currentTabInfos.enterpriseName
            ? `${currentTabInfos.enterpriseName} /`
            : ''
        } ${currentTabInfos.creditorName}`.toUpperCase()}
      >
        {currentTabInfos.typeOp === TypeProcessAct.OPERATION && (
          <ButtonContained
            label="SUPORTE EXFIN"
            onClick={() => setOpenSupportExFinModal(true)}
          />
        )}
      </Dialog.Header>

      <Box
        width={widthModalNegotiation}
        height={heightModalNegotiation}
        overflow={'auto'}
        p={3}
      >
        <Box paddingX={3}>
          <Tabs
            value={valueTab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Atos" {...getProps(0)} />
            {renderTabDoc && <Tab label="Documentos" {...getProps(1)} />}
            <Box width={'100%'} display={'flex'} justifyContent={'end'}>
              <OptionsModalNegotiation processActs={processActs} />
            </Box>
          </Tabs>

          <TabPanel value={valueTab} index={0}>
            <TabAct processActs={processActs} currentTabInfos={currentTabInfos} />
          </TabPanel>
          <TabPanel value={valueTab} index={1}>
            {pathname.includes('/customers') && (
              <DocumentsCustomerTable enterpriseId={currentTabInfos.enterpriseId} />
            )}
            {pathname.includes('/operations') && (
              <DocumentsOperationTable
                operationId={currentTabInfos.operationId ?? ''}
              />
            )}
          </TabPanel>
        </Box>
      </Box>

      <SectionButtons padding={1.6} paddingRight={3.5}>
        <ButtonOutlined
          color="black"
          label="Sair"
          onClick={() => handleCloseDialog()}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
