import {
  DashboardPage,
  SignInPage,
  SignUpEnterprisePage,
  DefinePasswordPage,
  ForgotPasswordPage,
  PasswordSettingInternalRegisterPage,
  CustomerManagementPage,
  OperationsPage,
  SignUpCreditorPage,
  SignInCompanyPage,
  SignInCreditorPage,
  StoragePage,
  FormAnswerPage,
  SignInConsultantPage,
  ListUsersPage,
  kleverScrPage,
  kleverSerasaPage,
  DynamicFormStoragePage,
} from 'modules';

import { Permissions } from 'models/roles/Permission';

import { ListActsPage } from 'modules/backoffice/acts';
import { KMethodPage } from 'modules/backoffice/kModule/pages';
import { DynamicFormPage, ListFormsPage } from 'modules/dynamicForm/form/pages';
import { FormResumePage } from 'modules/dynamicForm/formResume';
import { ListQuestionsPage } from 'modules/dynamicForm/question/pages/List';
import { HomePage } from 'modules/home';
import { ListConsultantsPage } from 'modules/user/_consultants/pages';

import { RoutesProps } from './types';

export const routes: RoutesProps[] = [
  {
    path: '/',
    component: SignInPage,
    type: 'public',
  },
  {
    path: '/empresa/signup',
    component: SignUpEnterprisePage,
    type: 'public',
  },
  {
    path: '/credor/signup',
    component: SignUpCreditorPage,
    type: 'public',
  },
  {
    path: '/define-password',
    component: DefinePasswordPage,
    type: 'public',
  },
  {
    path: '/password-setting/internal',
    component: PasswordSettingInternalRegisterPage,
    type: 'public',
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordPage,
    type: 'public',
  },
  {
    path: '/empresa',
    component: SignInCompanyPage,
    type: 'public',
  },
  {
    path: '/credor',
    component: SignInCreditorPage,
    type: 'public',
  },
  {
    path: '/consultant',
    component: SignInConsultantPage,
    type: 'public',
  },
  {
    path: '/form-answer/:id/:type',
    component: FormAnswerPage,
    type: 'private',
    permissions: [],
    showOnSidebar: false,
  },
  {
    path: '/form-resume/:id/:type',
    component: FormResumePage,
    type: 'private',
    permissions: [],
    showOnSidebar: false,
  },
  {
    path: '/home',
    name: 'Página inicial',
    icon: 'home',
    component: HomePage,
    type: 'private',
    permissions: [Permissions.ShowHomePage],
    showOnSidebar: true,
  },
  {
    path: '/dashboard',
    name: 'Indicadores',
    icon: 'insert_chart_outlined',
    component: DashboardPage,
    type: 'private',
    permissions: [Permissions.ShowDashboard],
    showOnSidebar: true,
  },
  {
    path: '/customers',
    name: 'Empresas',
    icon: 'group_add',
    component: CustomerManagementPage,
    type: 'private',
    permissions: [Permissions.ShowClientsBoard],
    showOnSidebar: true,
  },
  {
    path: '/operations',
    name: 'Operações',
    icon: 'compare_arrows',
    component: OperationsPage,
    type: 'private',
    permissions: [Permissions.ShowOperations],
    showOnSidebar: true,
  },
  {
    path: '/acts',
    name: 'Atos',
    icon: 'forum',
    component: ListActsPage,
    type: 'private',
    permissions: [Permissions.ShowBackoffice],
    showOnSidebar: true,
  },
  {
    path: '/configs',
    name: 'Configurações',
    icon: 'settings',
    type: 'private',
    permissions: [Permissions.ShowConfigs],
    showOnSidebar: true,
    subs: [
      {
        path: '/users',
        name: 'Usuários',
        icon: 'manage_accounts',
        component: ListUsersPage,
        type: 'private',
        permissions: [Permissions.ShowUsers],
        showOnSidebar: true,
      },
    ],
  },
  {
    path: '/form',
    name: 'Formulário dinâmico',
    type: 'private',
    icon: 'live_help',
    permissions: [Permissions.ShowBackoffice],
    showOnSidebar: true,
    subs: [
      {
        path: '/questions',
        name: 'Perguntas',
        icon: 'quiz',
        component: ListQuestionsPage,
        type: 'private',
        permissions: [Permissions.Questions],
        showOnSidebar: true,
      },
      {
        path: '/dynamic-form',
        name: 'Formulários',
        icon: 'grading',
        component: ListFormsPage,
        type: 'private',
        permissions: [Permissions.Forms],
        showOnSidebar: true,
      },
      {
        path: '/config-form/',
        component: DynamicFormPage,
        type: 'private',
        permissions: [Permissions.Forms],
        showOnSidebar: false,
      },
      {
        path: '/config-form/?edit=true',
        component: DynamicFormPage,
        type: 'private',
        permissions: [Permissions.Forms],
        showOnSidebar: false,
      },
    ],
  },
  {
    path: '/backoffice',
    name: 'Base de dados',
    type: 'private',
    icon: 'sort',
    permissions: [Permissions.ShowBackoffice],
    showOnSidebar: true,
    subs: [
      {
        path: '/big-data',
        name: 'Módulo "Big data"',
        icon: 'storage',
        component: StoragePage,
        type: 'private',
        permissions: [Permissions.ShowBackoffice],
        showOnSidebar: true,
      },
      {
        path: '/k',
        name: 'Módulo "K"',
        icon: 'storage',
        component: KMethodPage,
        type: 'private',
        permissions: [Permissions.ShowBackoffice],
        showOnSidebar: true,
      },
      {
        path: '/serasa',
        name: 'Módulo "Serasa"',
        icon: 'storage',
        component: kleverSerasaPage,
        type: 'private',
        permissions: [Permissions.ShowBackoffice],
        showOnSidebar: true,
      },
      {
        path: '/scr',
        name: 'Módulo "SCR"',
        icon: 'storage',
        component: kleverScrPage,
        type: 'private',
        permissions: [Permissions.ShowBackoffice],
        showOnSidebar: true,
      },
      {
        path: '/form-answers',
        name: 'Respostas forms',
        icon: 'storage',
        component: DynamicFormStoragePage,
        type: 'private',
        permissions: [Permissions.ShowBackoffice],
        showOnSidebar: true,
      },
    ],
  },
  {
    path: '/consultants',
    name: 'Consultores',
    icon: 'person_pin_circle',
    component: ListConsultantsPage,
    type: 'private',
    permissions: [Permissions.ShowConsultants],
    showOnSidebar: true,
  },
];
