import { DialogContent } from '@mui/material';
import styled from 'styled-components';

const ContentWrapper = styled(DialogContent)`
  && {
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.space[5]}px;
    gap: ${({ theme }) => theme.space[4]}px;

    ${({ theme }) => theme.mediaQueries.sm} {
      padding: ${({ theme }) => theme.space[7]}px;
    }
  }
`;

export { ContentWrapper };
