import { Box, Grid } from '@mui/material';
import html2canvas from 'html2canvas';
import { useEffect, useRef } from 'react';

import { Typography } from 'components';

import { useAuth } from 'config/auth/hooks';

import { renderCnpjMask } from 'utils/renderCNPJMask';

import { useDashboardStore } from '../store/store';

const DashboardGeneral = () => {
  const { user } = useAuth();
  const { setGeneralDataImageUrls } = useDashboardStore();
  const dataRef = useRef<HTMLDivElement>(null);
  const cnpj = user?.enterprise.cnpj;
  const companyName =
    user?.enterprise?.bigData?.fantasyName ||
    user?.enterprise?.bigData?.socialReason;

  const renderGridInformation = (title: string, data: string) => {
    return (
      <Grid item xs={12} sm={6} display={'flex'} gap={1} alignItems={'baseline'}>
        <Typography fontWeight={500} fontSize={22} color={'#515151'}>
          {title}
        </Typography>
        <Typography fontWeight={700} fontSize={24} color={'#515151'}>
          {data}
        </Typography>
      </Grid>
    );
  };

  useEffect(() => {
    const captureTable = async () => {
      if (!dataRef.current) return;

      const canvas = await html2canvas(dataRef.current);
      const imageData = canvas.toDataURL('image/png');

      setGeneralDataImageUrls(
        'companyData',
        imageData,
        canvas.width,
        canvas.height,
      );
    };

    if (dataRef) {
      captureTable();
    }
  }, []);

  return (
    <Box sx={{ paddingRight: 3 }} ref={dataRef}>
      <Grid container spacing={2}>
        {renderGridInformation('Empresa:', companyName ?? '')}
        {renderGridInformation('Valores em:', 'R$')}
        {renderGridInformation('CNPJ:', renderCnpjMask(cnpj ?? ''))}
        {renderGridInformation('Data:', new Date().toLocaleDateString('pt-BR'))}
      </Grid>
    </Box>
  );
};

export { DashboardGeneral };
