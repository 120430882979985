import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { IQuestionSwitchForm } from '../services';

export const useQuestionForm = () => {
  const userSchema = z.object({
    initialQuestion: z.boolean(),
    lastQuestion: z.boolean(),
  });

  const defaultValues = {
    initialQuestion: false,
    lastQuestion: false,
  };

  return useForm<IQuestionSwitchForm>({
    defaultValues: defaultValues,
    mode: 'onBlur',
    resolver: zodResolver(userSchema),
  });
};
