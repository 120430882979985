import { Paper, Typography } from '@mui/material';
import themes from 'themes';

import { Box } from 'components';
import { UserTypes } from 'models';

import { useConsultantSignIn } from '../hooks';
import { useSignInStore } from '../store/store';
import EnterpriseSelect from './EnterpriseSelect';
import { Form } from './Form';

export const ConsultantSignIn = () => {
  const { step } = useSignInStore();
  const {
    form,
    request,
    enterprise,
    enterprises,
    setEnterprise,
    handleCancel,
    handleConsultantEnterprise,
  } = useConsultantSignIn();

  return (
    <Paper
      elevation={16}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingX: '3em',
        paddingTop: '3em',
        paddingBottom: '2em',
        marginY: 'auto',
        gap: '1em',
        backgroundColor: '#f0f0f0',
        borderRadius: '11px',
        boxShadow:
          '0px 8px 10px -5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.05), 0px 6px 30px 5px rgba(0, 0, 0, 0.05)',
        width: 532,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{ gap: '1em' }}
        paddingX={20}
      >
        <Typography
          fontFamily={themes.fonts.body}
          variant="h4"
          fontSize={23}
          textAlign="center"
          fontWeight={700}
          color={themes.colors.blue}
        >
          Consultor
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          fontFamily={themes.fonts.body}
        >
          {step === 1
            ? 'Digite suas credenciais'
            : 'Se desejar, selecione uma empresa para entrar no seu sistema'}
        </Typography>
      </Box>
      {step === 1 && (
        <Form
          form={form}
          origin={UserTypes.CONSULTANT}
          isSigningIn={request.isLoading}
          handleCancel={handleCancel}
        />
      )}
      {step === 2 && (
        <EnterpriseSelect
          request={request}
          handleCancel={handleCancel}
          handleEnter={handleConsultantEnterprise}
          setEnterprise={setEnterprise}
          options={enterprises}
          enterprise={enterprise}
        />
      )}
    </Paper>
  );
};
