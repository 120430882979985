import { useTabs } from 'modules/boards/tabs/hooks';

import { useDashboardStore } from '../store/store';
import { useDownloadExcelDash } from './useDownloadExcelDash';

export const useDashboardActions = () => {
  const { setOpenSupportExFinModal } = useTabs();
  const { handleDownloadExcelDash } = useDownloadExcelDash();
  const { valueTab, setValueTab } = useDashboardStore();

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue);
  };

  const handleDownloadExcel = async () => {
    await handleDownloadExcelDash();
  };

  const handleDownloadCreditMaterial = () => {
    console.log('Download Credit Material');
  };
  const handleOpenSupportExFin = () => {
    setOpenSupportExFinModal(true);
  };
  const getProps = (index: number) => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return {
    valueTab,
    getProps,
    handleChangeTab,
    handleDownloadExcel,
    handleDownloadCreditMaterial,
    handleOpenSupportExFin,
  };
};
