import { MenuItem, FormControl, InputLabel, Select, Icon } from '@mui/material';
import { useCallback } from 'react';

import { Box, Typography, ButtonContained } from 'components';

import {
  useCardConditionMultipleChoice,
  useFalseAutocompleteConditionMultipleChoice,
} from 'modules/dynamicForm/form/hooks';
import { IQuestionForm } from 'modules/dynamicForm/form/services';

import { QuestionsAutocomplete } from '../QuestionsAutocomplete';
import { VariableCardCondition } from './VariablesCardCondition';

interface ICardConditionMultipleChoiceProps {
  question: IQuestionForm;
  identifierCondition: number;
}

export const CardConditionMultipleChoice = ({
  question,
  identifierCondition,
}: ICardConditionMultipleChoiceProps) => {
  const {
    openSelect,
    answer,
    handleChange,
    handleCloseSelect,
    handleOpenSelect,
    handleDeleteCardCondition,
    handleOpenDropdownCondition,
    handleAddVariable,
  } = useCardConditionMultipleChoice();

  const {
    formFalseQuestionConditionAutocomplete,
    allQuestions,
    setSearch,
    isOptionEqualToValue,
    handleChangeAutocomplete,
    handleShowOptionFalse,
    handleDeleteOptionFalse,
  } = useFalseAutocompleteConditionMultipleChoice();

  const questionCondition = question?.conditions[identifierCondition - 1];

  const showOptionFalse = questionCondition?.showOptionFalse;
  const openDropdown = questionCondition?.openDropdown;
  const variables = questionCondition?.variables;
  const falseInternLabel = questionCondition?.nextConditionFalseLabel;

  const valueOption = question?.question?.answerOptions.find(
    option => option.value === questionCondition.condition,
  );

  const handleOpenDropdown = useCallback(() => {
    handleOpenDropdownCondition(question.numberQuestion, identifierCondition);
  }, [handleOpenDropdownCondition, question.numberQuestion, identifierCondition]);

  return (
    <Box
      border="1px solid gray"
      borderRadius="5px"
      marginTop="1.5em"
      display="flex"
      flexDirection="column"
      paddingX="1.5em"
      paddingY="1em"
      width={'100%'}
    >
      <Box>
        <Box
          style={{ display: 'flex', float: 'right' }}
          onClick={() =>
            handleDeleteCardCondition(question.numberQuestion, identifierCondition)
          }
          width={40}
        >
          <Icon style={{ cursor: 'pointer' }} fontSize="medium">
            delete
          </Icon>
        </Box>
        <Box
          style={{ display: 'flex', float: 'right' }}
          onClick={() => handleOpenDropdown()}
          width={40}
        >
          <Icon style={{ cursor: 'pointer' }} fontSize="medium">
            {openDropdown ? 'expand_more' : 'expand_less'}
          </Icon>
        </Box>

        <Box display={'flex'} alignItems={'center'}>
          <Typography> Se resposta</Typography>
          <Box width={300} ml={5}>
            <FormControl fullWidth>
              <InputLabel id="demo-controlled-open-select-label">
                Opção de Resposta
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                value={valueOption?.label ?? answer}
                open={openSelect}
                onClose={handleCloseSelect}
                onOpen={handleOpenSelect}
                label="Opção de Resposta"
                onChange={e => handleChange(question, identifierCondition, e)}
              >
                {question?.question?.answerOptions.map((answer, index) => {
                  return (
                    <MenuItem key={index} value={answer.label}>
                      {answer?.value}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {openDropdown && (
          <>
            {variables?.map((variable, index) => {
              return (
                <VariableCardCondition
                  key={index}
                  question={question}
                  identifierVariable={variable.identifierVariable}
                  identifierCondition={identifierCondition}
                />
              );
            })}

            <Box display={'flex'} width={'100%'} mt={5} justifyContent={'center'}>
              <Box display={'flex'} justifyContent={'space-around'} width={'80%'}>
                <ButtonContained
                  label="Adicionar opção de variável"
                  onClick={() => handleAddVariable(question, identifierCondition)}
                />

                <ButtonContained
                  label="Adicionar opção de false"
                  disabled={showOptionFalse}
                  onClick={() =>
                    handleShowOptionFalse(question, identifierCondition)
                  }
                />
              </Box>
            </Box>
          </>
        )}
        {showOptionFalse && (
          <Box mt={5}>
            <Box
              style={{ display: 'flex', float: 'right' }}
              onClick={() => handleDeleteOptionFalse(question, identifierCondition)}
              width={40}
            >
              <Icon style={{ cursor: 'pointer' }} fontSize="medium">
                delete
              </Icon>
            </Box>
            <Typography>Opção Se False</Typography>
            <Typography>Próxima pergunta Se False</Typography>
            <QuestionsAutocomplete
              question={question}
              setSearch={setSearch}
              value={falseInternLabel}
              identifierCondition={identifierCondition}
              form={formFalseQuestionConditionAutocomplete}
              allQuestions={allQuestions}
              isOptionEqualToValue={isOptionEqualToValue}
              handleChange={handleChangeAutocomplete}
              disabled={String(questionCondition?.condition).length === 0}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
