import { Icon } from 'components';

import { SearchBarWrapper, SearchBarInput } from './styles';

interface SearchBarProps {
  placeholder: string;
  onSearchChange: (value: string) => void;
}

function SearchBar({ placeholder, onSearchChange }: SearchBarProps) {
  return (
    <SearchBarWrapper>
      <Icon color="gray-500">search</Icon>

      <SearchBarInput
        name={placeholder}
        placeholder={placeholder}
        disableUnderline
        onChange={e => onSearchChange(e.target.value)}
      />
    </SearchBarWrapper>
  );
}

export default SearchBar;
