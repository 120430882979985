import { create } from 'zustand';

import { IQuestion, IQuestionAttachmentList } from 'models';

import { DEFAULT_PAGINATION } from 'utils/helper';

import { IQuestionFilter, IQuestionFilterBy, IQuestionPayload } from '../services';

interface Store {
  // list filters
  search: string;
  setSearch: (search: string) => void;

  // pagination
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
  limit: boolean;
  setLimit: (limit: boolean) => void;
  list: IQuestion[];
  setList: (list: IQuestion[]) => void;

  // filter Dialog
  openFilterDialog: boolean;
  setOpenFilterDialog: (open: boolean) => void;
  searchOption: string;
  setSearchOption: (searchCountry: string) => void;
  currentFilter: IQuestionFilter[];
  setCurrentFilter: (filters: IQuestionFilter[]) => void;
  filterBy: IQuestionFilterBy;
  setFilterBy: (filterBy: IQuestionFilterBy) => void;

  // question form dialog
  openFormDialog: boolean;
  setOpenFormDialog: (open: boolean) => void;
  currentQuestion: IQuestion;
  setCurrentQuestion: (question?: IQuestion | undefined) => void;

  // Dialog Confirm Inactives (armazenando ids' pra inativar)
  listSetInativeQuestions: string[];
  setListSetInativeQuestions: (listSetInativeQuestions: string[]) => void;
  openConfirmInactivesDialog: boolean;
  setOpenConfirmInactivesDialog: (open: boolean) => void;

  // Stepper Form
  dataQuestion: IQuestionPayload;
  setDataQuestion: (dataQuestion: IQuestionPayload) => void;
  searchVariable: string;
  setSearchVariable: (searchVariable: string) => void;

  // Question attachment
  questionAttachment: IQuestionAttachmentList[];
  setQuestionAttachments: (questionAttachment: IQuestionAttachmentList[]) => void;
}

export const useQuestionStore = create<Store>(set => ({
  page: DEFAULT_PAGINATION.page,
  setPage: page => set({ page }),
  perPage: DEFAULT_PAGINATION.perPage,
  setPerPage: perPage => set({ perPage }),
  limit: false,
  setLimit: limit => set({ limit }),
  list: [] as IQuestion[],
  setList: list => set({ list }),

  search: '',
  setSearch: search => set({ search }),

  openFilterDialog: false,
  setOpenFilterDialog: openFilterDialog => set({ openFilterDialog }),
  searchOption: '',
  setSearchOption: searchOption => set({ searchOption }),
  currentFilter: [],
  setCurrentFilter: currentFilter => set({ currentFilter }),
  filterBy: 'Ativo/Inativo',
  setFilterBy: filterBy => set({ filterBy }),

  openFormDialog: false,
  setOpenFormDialog: openFormDialog => set({ openFormDialog }),
  currentQuestion: {} as IQuestion,
  setCurrentQuestion: currentQuestion => set({ currentQuestion }),

  listSetInativeQuestions: [],
  setListSetInativeQuestions: (listSetInativeQuestions: string[]) =>
    set({ listSetInativeQuestions }),
  openConfirmInactivesDialog: false,
  setOpenConfirmInactivesDialog: openConfirmInactivesDialog =>
    set({ openConfirmInactivesDialog }),

  dataQuestion: {} as IQuestionPayload,
  setDataQuestion: dataQuestion => set({ dataQuestion }),
  searchVariable: '',
  setSearchVariable: searchVariable => set({ searchVariable }),

  questionAttachment: [],
  setQuestionAttachments: questionAttachment => set({ questionAttachment }),
}));
