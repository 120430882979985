import { useEffect } from 'react';
import { useQuery } from 'react-query';

import { IQuestion } from 'models';
import { IPagination } from 'models/pagination/types';

import { QuestionService } from '../services/services';
import { useQuestionStore } from '../store/store';

export const useFindQuestions = () => {
  const {
    page,
    perPage,
    setPage,
    setList,
    setLimit,
    limit,
    search,
    currentFilter,
  } = useQuestionStore();

  const { data, isLoading, refetch } = useQuery(
    ['questions', page, search, currentFilter],
    () =>
      QuestionService.list({
        page,
        perPage,
        search: search,
      }) as Promise<IPagination<IQuestion>>,
    { keepPreviousData: true },
  );
  const noData = !data?.data || data.data.length === 0;
  const showPagination = !noData && data?.totalPages && data.totalPages > 1;
  useEffect(() => {
    if (data?.nextPage && data?.totalPages) {
      setLimit(data.nextPage >= data.totalPages);
      setList(data?.data);
    }
  }, [data]);

  return {
    data: data?.data,
    isLoading,
    page,
    perPage,
    setPage,
    limit,
    refetch,
    noData,
    showPagination,
  };
};
