import { Box } from '@mui/material';
import { useEffect } from 'react';

import { useDashboardEnterprise } from '../hooks';
import { useDashboardStore } from '../store/store';
import { EmptyDashboard } from './EmptyDashboard';
import {
  DebtsChart,
  DebtsVCTOChart,
  DreChart,
  MonthlyBillingChart,
} from './charts';
import {
  AbcCurveTable,
  CurrentAssetsTable,
  CurrentPassiveTable,
  MonthlyBillingTable,
  DebtsTable,
} from './tables';
import { DreTable } from './tables/DreTable';
import { IndexesAndIndicatorsTable } from './tables/IndexesAndIndicatorsTable';

const RenderBoxDatas: React.FC = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={6}
      p={1}
      width={'99%'}
    >
      {children}
    </Box>
  );
};

const DashboardEnterprise = () => {
  const {
    dreNoData,
    debtsNoData,
    indexesAndIndicatorsNoData,
    billingStatementsNoData,
    fiscalNotesNoData,
    activesNoData,
    passivesNoData,
    data,
    numbersWillRender,
    isLoading,
  } = useDashboardEnterprise();

  const { setNumbersWillRender } = useDashboardStore();

  useEffect(() => {
    setNumbersWillRender(numbersWillRender);
  }, [numbersWillRender]);

  const noData =
    debtsNoData &&
    indexesAndIndicatorsNoData &&
    //vetoesAndAlertsNoData &&
    fiscalNotesNoData &&
    activesNoData;

  if (noData) {
    return <EmptyDashboard />;
  }

  return (
    <Box>
      <RenderBoxDatas>
        {/* <VetoesAndAlertsTable
          data={vetoesAndAlertsData}
          noData={noData}
          isLoading={isLoading}
        /> */}
      </RenderBoxDatas>

      {!activesNoData && (
        <Box marginTop={5}>
          <RenderBoxDatas>
            <CurrentAssetsTable
              data={data?.actives}
              noData={activesNoData}
              isLoading={isLoading}
            />
          </RenderBoxDatas>
        </Box>
      )}

      {!passivesNoData && (
        <Box marginTop={5}>
          <RenderBoxDatas>
            <CurrentPassiveTable
              data={data?.passives}
              noData={passivesNoData}
              isLoading={isLoading}
            />
          </RenderBoxDatas>
        </Box>
      )}

      {!dreNoData && (
        <Box marginTop={8}>
          <RenderBoxDatas>
            <DreTable
              data={data?.demonstrations}
              noData={dreNoData}
              isLoading={isLoading}
            />
            <DreChart demonstrations={data?.demonstrations} />
          </RenderBoxDatas>
        </Box>
      )}

      {!indexesAndIndicatorsNoData && (
        <Box marginTop={8}>
          <RenderBoxDatas>
            <IndexesAndIndicatorsTable
              data={data?.indexesAndIndicators}
              noData={indexesAndIndicatorsNoData}
              isLoading={isLoading}
            />
          </RenderBoxDatas>
        </Box>
      )}

      {!billingStatementsNoData && (
        <Box marginTop={8}>
          <RenderBoxDatas>
            <MonthlyBillingTable
              data={data?.billingMonthly ?? []}
              noData={billingStatementsNoData}
              isLoading={isLoading}
            />
            <MonthlyBillingChart dataStatements={data?.billingMonthly ?? []} />
          </RenderBoxDatas>
        </Box>
      )}

      {!debtsNoData && (
        <Box marginTop={8}>
          <RenderBoxDatas>
            <DebtsTable
              data={data?.debts ?? []}
              noData={debtsNoData}
              isLoading={isLoading}
            />
            <Box
              display={'flex'}
              flexDirection={'row'}
              width={'100%'}
              maxHeight={'75vh'}
              gap={3}
            >
              <DebtsChart data={data?.debts ?? []} />
              <DebtsVCTOChart data={data?.debts ?? []} />
            </Box>
          </RenderBoxDatas>
        </Box>
      )}

      {!fiscalNotesNoData && (
        <Box marginTop={8}>
          <RenderBoxDatas>
            <AbcCurveTable
              dataClients={data?.fiscalNotes?.verticalAnalysis?.billings ?? []}
              dataSuppliers={data?.fiscalNotes?.verticalAnalysis?.expenses ?? []}
              noData={fiscalNotesNoData}
              isLoading={isLoading}
            />
          </RenderBoxDatas>
        </Box>
      )}
    </Box>
  );
};

export { DashboardEnterprise };
