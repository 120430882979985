import { Input, Paper } from '@mui/material';
import styled from 'styled-components';

const SearchBarWrapper = styled(Paper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.space[3]}px !important;
  padding: ${({ theme }) => theme.space[4]}px;
`;

const SearchBarInput = styled(Input)`
  width: 100%;
  margin-left: ${({ theme }) => theme.space[5]}px;
`;

export { SearchBarWrapper, SearchBarInput };
