import { useMutation, UseMutationOptions } from 'react-query';

import { IFormQuestion } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { IFormQuestionCreateRequest } from '../services';
import { FormService } from '../services/services';

const useCreateFormQuestionRequest = (
  options?: UseMutationOptions<
    IFormQuestion,
    IApiAxiosError,
    IFormQuestionCreateRequest
  >,
) => {
  return useMutation('createFormQuestion', FormService.createFormQuestion, options);
};

export { useCreateFormQuestionRequest };
