import { IUser } from 'models';

import api from 'config/api';

import { IDefinePasswordRequestPayload } from './types';

export class DefinePasswordService {
  static async setPassword({ id, payload }: IDefinePasswordRequestPayload) {
    const { data } = await api.put<IUser>(
      `/configs-user/set-password/${id}`,
      payload,
    );
    return data;
  }
}
