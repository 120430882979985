import { useQuery } from 'react-query';

import { SerasaService } from '../services';
import { useSerasaStore } from '../store/store';
import { useSerasaFilter } from './useSerasaFilter';

interface SerasaRequestProps {
  limitPagination?: boolean;
}

export const useSerasaRequest = ({ limitPagination }: SerasaRequestProps) => {
  const { page, perPage, search } = useSerasaStore();

  const { filterParams, filterBy, currentFilter } = useSerasaFilter();

  const unlimited = 10000000;

  const { data, isLoading, refetch } = useQuery(
    ['serasa', page, search, filterBy, currentFilter],
    () =>
      SerasaService.getSerasaData({
        page,
        perPage: limitPagination ? unlimited : perPage,
        search,
        ...filterParams,
      }),
    { keepPreviousData: true },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
