import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { IApiError } from 'config/api/types';
import { useAuth } from 'config/auth/hooks';

import { useSignInRequest } from 'modules/signIn/hooks/useSignInRequest';

import { IDefinePasswordRequestPayload } from '../services';
import { useDefinePasswordStore } from '../store/store';
import { useDefinePasswordForm } from './useDefinePasswordForm';
import { useDefinePasswordRequest } from './useDefinePasswordRequest';
import { useValidateCodeRequest } from './useValidationCodeRequest';

export const useDefinePassword = () => {
  const form = useDefinePasswordForm();
  const { signIn } = useAuth();

  const { userId, code, clearDefinePasswordStore } = useDefinePasswordStore();

  const request = useDefinePasswordRequest({
    onSuccess: async (data, payload) => {
      await requestCode.mutate({ code });
      toast.success('Senha cadastrada com sucesso!');

      signInRequest.mutate({
        email: data.email,
        password: payload.payload.password,
      });
    },
    onError: (error: AxiosError<IApiError>) => {
      if (error.response?.data.title === 'Invalid credentials') {
        toast.error('Email já utilizado!');
      } else {
        toast.error('Houve um erro ao tentar finalizar o cadastro');
      }
    },
  });

  const signInRequest = useSignInRequest({
    onSuccess: data => {
      signIn(data);
      clearDefinePasswordStore();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar logar');
    },
  });

  const requestCode = useValidateCodeRequest({
    onError: () => {
      toast.error('Erro ao validar código.');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const payload: IDefinePasswordRequestPayload = {
      id: userId,
      payload: {
        password: data.password,
      },
    };

    await request.mutate(payload);
  });

  return {
    requestPassword: request,
    setPasswordForm: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseDefinePassword = ReturnType<typeof useDefinePassword>;
