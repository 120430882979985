import { Grid, Typography } from '@mui/material';

import { KleverAnpFuelStation } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  anpFuelStation: KleverAnpFuelStation | null;
}

export const BigDataAnpFuelStation = ({ anpFuelStation }: Props) => {
  if (!anpFuelStation) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography fontWeight={700} fontSize={20}>
          Postos de Combustíveis
        </Typography>
      </Grid>

      <BigDataItem title="Bandeira Atual" value={anpFuelStation.currentFlag} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>Equipamentos</Typography>
        </Grid>
        {anpFuelStation.equipments?.map((equipment, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <BigDataItem
              title="Quantidade de Bicos"
              value={equipment.beaks?.toString()}
            />
            <BigDataItem title="Tipo de Combustível" value={equipment.fuel} />
            <BigDataItem
              title="Abastecimento por Bico"
              value={equipment.tanking?.toString()}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography fontWeight={700}>Bandeiras</Typography>
        </Grid>
        {anpFuelStation.flags?.map((flag, index) => (
          <Grid item xs={12} lg={6} key={index}>
            <BigDataItem title="Bandeira" value={flag.flag} />
            <BigDataItem
              title="Data de Início"
              value={
                flag.startFlagDate &&
                new Date(flag.startFlagDate).toLocaleDateString('pt-BR')
              }
            />
          </Grid>
        ))}
      </Grid>
      <BigDataItem title="Tipo de Posto" value={anpFuelStation.postType} />
      <BigDataItem
        title="Autorização do Posto"
        value={anpFuelStation.stationAuthorization}
      />
      <BigDataItem
        title="Total de Bicos"
        value={anpFuelStation.totalSpikes?.toString()}
      />
      <BigDataItem
        title="Total de Tanques"
        value={anpFuelStation.totalTank?.toString()}
      />
    </Grid>
  );
};
