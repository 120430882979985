import { IconButton, TableCell, TableRow } from '@mui/material';
import themes from 'themes';

import { Box, ChipOutlined, Icon, Typography } from 'components';
import { FormStatus, FormStatusPtBr, IFormRecord } from 'models';

import { limitSomeDescription } from 'utils/limitSomeDescription';
import { renderCnpjMask } from 'utils/renderCNPJMask';

import { useDynamicFormStorage } from '../hooks';

const DynamicFormStorageItem = (formRecord: IFormRecord) => {
  const { handleOpenFormResume } = useDynamicFormStorage();

  const status = formRecord?.enterprise?.status;
  const isCompletedForm = formRecord.status === FormStatus.COMPLETED;
  const emptyString = '---';
  const companyName =
    formRecord?.enterprise?.bigData?.fantasyName ||
    formRecord?.enterprise?.bigData?.socialReason;

  const renderChipStatus = (status: string) => {
    return (
      <ChipOutlined
        label={FormStatusPtBr[status]}
        color={status === FormStatus.COMPLETED ? 'blue' : 'gray-300'}
      />
    );
  };

  return (
    <TableRow key={formRecord.id}>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {formRecord?.enterprise?.cnpj
            ? renderCnpjMask(formRecord?.enterprise?.cnpj)
            : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {companyName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {formRecord.form.title}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {renderChipStatus(formRecord.status)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {limitSomeDescription(status, 45)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{
            fontSize: `${themes.fontSizes.s}px`,
          }}
          textAlign="center"
        >
          {isCompletedForm
            ? new Date(formRecord?.updatedAt ?? '').toLocaleDateString('pt-BR')
            : emptyString}
        </Typography>
      </TableCell>
      <TableCell>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '100%',
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpenFormResume(formRecord)}
          >
            <Icon color="gray-300">open_in_new_icon</Icon>
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export { DynamicFormStorageItem };
