import { stringify } from 'qs';

import { IFilterDatabasePayload } from 'components/FilterDatabase/filterDatabase.types';
import { ICompanyBigData, IPagination } from 'models';

import api from 'config/api';

import { IStoragePayload, IStorageResponse } from './types';

export class StorageService {
  static async getStorageData({ page, perPage, search }: IStoragePayload) {
    const params = stringify({ page, perPage, search });

    //TODO Verificar rota e dados retornados
    const { data } = await api.get<
      IPagination<IStorageResponse> | IStorageResponse[]
    >(`/enterprise?${params}`);

    return data;
  }

  static async getBigData({
    page,
    perPage,
    search,
    type,
    active,
    status,
    manager,
    product,
  }: IFilterDatabasePayload) {
    const params = stringify({
      page,
      perPage,
      search,
      type,
      active,
      status,
      manager,
      product,
    });

    const { data } = await api.get<
      IPagination<ICompanyBigData> | ICompanyBigData[]
    >(`/enterprise/big-data?${params}`);

    return data;
  }
}
