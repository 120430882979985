import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { validateDate } from 'utils/validateDate';

import { IActForm } from '../services';

const validateAutocomplete = async (value: string | string[]): Promise<boolean> => {
  if (value.length === 0) {
    return false;
  }
  return true;
};

const permissionUserArrayTypes = z.string();

export const defaultValuesActs = {
  active: true,
  name: '',
  description: '',
  type: '',
  clientViewAndAnswer: ['Consultor'],
  clientEmit: ['Consultor'],
  userViewAndAnswer: [],
  userEmit: [],

  triggerForm: false,
  formId: '',
  showInfo: false,
  info: '',

  showDbInfo: false,
  clientTypeInfo: '',
  dbInfo: [],

  textInputAnswer: false,
  generateAct: false,
  actId: '',

  defineProductClient: false,
  productClient: [],

  createOp: false,
  defineOp: false,
  operation: '',
  defineValueOp: false,

  showScheduleLink: false,
  scheduleLink: '',

  showDeadline: false,
  deadline: '',
  triggerNotification: false,
  notifyBefore: [],
  notifyAfter: [],

  changeStatusClient: false,
  newStatusClient: '',
  changeStatusOp: false,
  newStatusOp: '',

  allowCharge: false,
  triggerDocSign: false,
  allowAttach: false,
  allowAnswerAttach: false,

  sendEmail: false,
  emailTitle: '',
  emailBody: '',

  negotiationEnd: false,
  closureAct: false,
};

export const useConfigActForm = () => {
  const conficActSchema = z
    .object({
      active: z.boolean(),
      name: z.string().trim().nonempty('Nome do ato é obrigatório'),
      description: z.string().trim().optional(),
      type: z
        .string()
        .trim()
        .nonempty('Escolha um tipo de ato')
        .refine(
          async value => await validateAutocomplete(value),
          'Escolha o tipo de ato é obrigatória',
        ),
      clientViewAndAnswer: z
        .array(permissionUserArrayTypes)
        .refine(
          async value => await validateAutocomplete(value),
          'Escolha o tipo de cliente que pode ver/responder o ato',
        ),
      clientEmit: z
        .array(permissionUserArrayTypes)
        .refine(
          async value => await validateAutocomplete(value),
          'Escolha o tipo de cliente que pode emitir o ato',
        ),
      userViewAndAnswer: z.array(permissionUserArrayTypes).optional(),
      userEmit: z.array(permissionUserArrayTypes).optional(),
      triggerForm: z.boolean(),
      formId: z.string().trim().optional(),
      showInfo: z.boolean(),
      info: z.string().trim().optional(),
      showDbInfo: z.boolean(),
      clientTypeInfo: z.string().optional(),
      dbInfo: z.array(permissionUserArrayTypes),
      textInputAnswer: z.boolean(),
      generateAct: z.boolean(),
      actId: z.string().trim().optional(),

      defineProductClient: z.boolean(),
      productClient: z.array(permissionUserArrayTypes),

      createOp: z.boolean(),
      defineOp: z.boolean(),
      operation: z.string().trim().optional(),
      defineValueOp: z.boolean(),

      showScheduleLink: z.boolean(),
      scheduleLink: z.string().trim().optional(),

      showDeadline: z.boolean(),
      deadline: z
        .string()
        .trim()
        .optional()
        .refine(deadline => {
          if (deadline) return validateDate(deadline);
          return true;
        }, 'Data inválida'),
      triggerNotification: z.boolean(),
      notifyBefore: z.array(permissionUserArrayTypes),
      notifyAfter: z.array(permissionUserArrayTypes),

      changeStatusClient: z.boolean(),
      newStatusClient: z.string().trim().optional(),
      changeStatusOp: z.boolean(),
      newStatusOp: z.string().trim().optional(),

      allowCharge: z.boolean(),
      triggerDocSign: z.boolean(),
      allowAttach: z.boolean(),
      allowAnswerAttach: z.boolean(),

      sendEmail: z.boolean(),
      emailTitle: z.string().trim().optional(),
      emailBody: z.string().trim().optional(),

      negotiationEnd: z.boolean(),
      closureAct: z.boolean(),
    })
    .superRefine((data, ctx) => {
      const { clientViewAndAnswer, clientEmit, userViewAndAnswer, userEmit } = data;

      const invalidUserViewAndAnswer =
        (clientViewAndAnswer.length !== 1 ||
          clientViewAndAnswer[0] !== 'Consultor') &&
        (!userViewAndAnswer || userViewAndAnswer.length === 0);

      if (invalidUserViewAndAnswer) {
        ctx.addIssue({
          path: ['userViewAndAnswer'],
          code: z.ZodIssueCode.custom,
          message: 'Escolha o tipo de usuário que pode ver/responder o ato',
        });
      }

      const invalidUserEmit =
        (clientEmit.length !== 1 || clientEmit[0] !== 'Consultor') &&
        (!userEmit || userEmit.length === 0);

      if (invalidUserEmit) {
        ctx.addIssue({
          path: ['userEmit'],
          code: z.ZodIssueCode.custom,
          message: 'Escolha o tipo de usuário que pode emitir o ato',
        });
      }
    });

  return useForm<IActForm>({
    defaultValues: defaultValuesActs,
    mode: 'onBlur',
    resolver: zodResolver(conficActSchema),
  });
};
