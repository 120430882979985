import { DocumentsCustomerTable } from 'modules/boards';

import { useDashboardEnterprise } from '../hooks';

const DashboardDocumentsCustomers = () => {
  const { data } = useDashboardEnterprise();

  return <DocumentsCustomerTable enterpriseId={data?.enterprise.id ?? ''} />;
};

export { DashboardDocumentsCustomers };
