import { useMutation, UseMutationOptions } from 'react-query';

import { IProcessAct } from 'models';

import { IApiAxiosError } from 'config/api/types';

import { ICreateProcessAct } from '../service';
import TabActServices from '../service/service';

const useCreateProcessActRequest = (
  options?: UseMutationOptions<IProcessAct, IApiAxiosError, ICreateProcessAct>,
) => {
  return useMutation('createProcessAct', TabActServices.createProcessAct, options);
};

export { useCreateProcessActRequest };
