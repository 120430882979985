import Box from '@mui/material/Box';
import { ReactNode } from 'react';

interface RootProps {
  children: ReactNode;
}

const ContainerFilters = ({ children }: RootProps) => {
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={'100%'}
    >
      {children}
    </Box>
  );
};

export default ContainerFilters;
