import { useMutation, UseMutationOptions } from 'react-query';

import { IQuestionAttachment } from 'models';

import { IApiAxiosError } from 'config/api/types';

import {
  PayloadFindQuestionAttachments,
  QuestionAttachmentService,
} from 'modules/dynamicForm/questionAttachment';

const useFindQuestionAttachmentRequest = (
  options?: UseMutationOptions<
    IQuestionAttachment[],
    IApiAxiosError,
    PayloadFindQuestionAttachments
  >,
) => {
  return useMutation(
    'questionAttachentByQuestion',
    QuestionAttachmentService.findByQuestion,
    options,
  );
};

export { useFindQuestionAttachmentRequest };
