import { Icon as MuiIcon } from '@mui/material';
import { ReactNode } from 'react';
import themes from 'themes';

import { IconWrapper } from './styles';

interface IconProps {
  children: ReactNode;
  color?: keyof typeof themes.colors;
  fontSize?: keyof typeof themes.fontSizes;
}

function Icon({ children, color, fontSize }: IconProps) {
  return (
    <IconWrapper color={color} fontSize={fontSize}>
      <MuiIcon>{children}</MuiIcon>
    </IconWrapper>
  );
}
export default Icon;
