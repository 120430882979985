import { Table, TableCell } from '@mui/material';
import themes from 'themes';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useScr } from '../hooks';
import { ScrItem } from './ScrItem';

const ScrTable = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useScr();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={180}>
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                CNPJ
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Nome Fantasia
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Endividamento bancário (saldo)
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Status
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Ativo/Inativo
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                style={{
                  fontSize: `${themes.fontSizes.s}px`,
                }}
                variant="subtitle3"
              >
                Mais Detalhes
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          columns={6}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Base de dados vazia"
        >
          {data?.map(scr => (
            <ScrItem key={scr.document} {...scr} />
          ))}
        </TableBody>
      </Table>
      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};

export { ScrTable };
