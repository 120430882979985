import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IQuestionEdit } from '../services';
import { useQuestionStore } from '../store/store';
import { useUpdateQuestionRequest } from './useUpdateQuestion';

export const useQuestionItem = () => {
  const [valueCheckBox, setValueCheckBox] = useState(false);
  const {
    list,
    listSetInativeQuestions,
    openConfirmInactivesDialog,
    setListSetInativeQuestions,
    setOpenConfirmInactivesDialog,
  } = useQuestionStore();
  const queryClient = useQueryClient();

  const requestUpdateQuestion = useUpdateQuestionRequest({
    onSuccess: () => {
      queryClient.invalidateQueries('questions');
    },
    onError: () => {
      toast.error('Houve um erro ao desativar a pergunta');
    },
  });

  const handleInactiveQuestion = (id: string) => {
    const questionAlredySetted = listSetInativeQuestions.find(
      idQuestion => idQuestion === id,
    );

    if (questionAlredySetted) {
      const newList = listSetInativeQuestions.filter(
        id => id !== questionAlredySetted,
      );
      setListSetInativeQuestions(newList);
    } else {
      setListSetInativeQuestions([...listSetInativeQuestions, id]);
    }

    setValueCheckBox(!valueCheckBox);
  };

  const handleUpdateQuestions = () => {
    listSetInativeQuestions.map(id => {
      const questionInactive = list.find(question => question.id === id);

      if (questionInactive) {
        const question: IQuestionEdit = {
          title: questionInactive.title,
          description: questionInactive.description,
          hint: questionInactive.hint,
          type: questionInactive.type,
          acceptAttachment: questionInactive.acceptAttachment,
          attachments: questionInactive.attachments,
          answerOptions: questionInactive.answerOptions,
          active: false,
          user: questionInactive.user.id,
          systemValueMapping: questionInactive?.systemValueMapping?.id,
        };

        requestUpdateQuestion.mutate({
          id: questionInactive.id,
          question,
        });
      }
    });

    setOpenConfirmInactivesDialog(false);
  };

  return {
    valueCheckBox,
    openConfirmInactivesDialog,
    handleInactiveQuestion,
    handleUpdateQuestions,
    setValueCheckBox,
    setOpenConfirmInactivesDialog,
  };
};

export type UseQuestionItem = ReturnType<typeof useQuestionItem>;
