import { useQuery } from 'react-query';

import { UserQueryKey, UserService } from 'modules/user';

import { OptionsValuesString } from 'utils/helper';

export const useListAllConsultants = (enabled: boolean) => {
  const { data, isLoading, refetch } = useQuery(
    [UserQueryKey.LIST_ALL_CONSULTANTS],
    () => UserService.listAllConsultants(),
    { enabled, keepPreviousData: true },
  );

  const consultantsOptions: OptionsValuesString[] =
    data?.map(consultant => ({
      value: consultant?.id,
      label: `${consultant?.name}`,
    })) || [];

  return {
    consultantsOptions,
    data,
    isLoading,
    refetch,
  };
};
