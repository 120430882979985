import { IconButton } from '@mui/material';
import { useContext } from 'react';

import { Icon } from 'components';

import { PrivateLayoutContext } from 'layouts/PrivateLayout/Context';

export function MenuButton() {
  const { setSidebarToggled } = useContext(PrivateLayoutContext);

  return (
    <IconButton onClick={() => setSidebarToggled(prev => !prev)}>
      <Icon color="gray-500">menu</Icon>
    </IconButton>
  );
}
