import api from 'config/api';

import { IRetrieveMeResponse } from './types';

export class API {
  static async retrieveMe() {
    const { data } = await api.get<IRetrieveMeResponse>('/me');
    return data;
  }
}
