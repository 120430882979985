import { Add } from '@mui/icons-material';

import { Icon, ButtonOutlined } from 'components';
import { IQuestion } from 'models';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useQuestionSearch, useQuestionDialog } from '../hooks';
import { useQuestionStore } from '../store/store';

export function HeaderQuestion() {
  const { setSearch } = useQuestionStore();
  const { handleOpenFormDialog } = useQuestionDialog();
  const { handleChangeSearch } = useQuestionSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            icon={<Icon fontSize="h4">quiz</Icon>}
            title="Perguntas"
          />

          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={value => handleChangeSearch(value)}
          />
          <PrivateLayout.Content.Header.Actions>
            <ButtonOutlined
              color="white"
              label={'Criar Pergunta'}
              icon={<Add />}
              loading={false}
              onClick={() => handleOpenFormDialog({} as IQuestion)}
            />
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
}
