import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  SxProps,
  Theme,
} from '@mui/material';
import { useState } from 'react';
import {
  Controller,
  FieldError,
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { Icon } from 'components';

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
> = UseControllerProps<TFieldValues, TName> & {
  error?: FieldError;
  label: string;
  sx?: SxProps<Theme>;
};

export function PasswordTextField<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>({
  error,
  name,
  label,
  control,
  defaultValue,
  rules,
  sx,
  ...rest
}: Props<TFieldValues, TName>) {
  const {
    field: { onChange, value, ref, ...props },
  } = useController({ control, name, defaultValue });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => (
        <MuiTextField
          sx={sx || {}}
          id={field.name}
          label={label}
          onChange={onChange}
          value={value}
          fullWidth
          error={Boolean(!!error)}
          ref={ref}
          helperText={error && <>{error.message}</>}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  zIndex: 1,
                }}
              >
                <IconButton
                  onClick={() => setShowPassword(prev => !prev)}
                  style={{ marginRight: '-8px' }}
                >
                  <Icon color="gray-700">
                    {showPassword ? 'visibility_off' : 'visibility'}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...rest}
          {...props}
        />
      )}
    />
  );
}
