import { toast } from 'react-toastify';

import { useValidateCodeRequest } from '../hooks/useValidationCodeRequest';
import { useDefinePasswordStore } from '../store/store';
import { useValidationCodeForm } from './useValidationCodeForm';

export const useValidationCode = () => {
  const form = useValidationCodeForm();
  const { setStep, setUserId, setCode } = useDefinePasswordStore();

  const request = useValidateCodeRequest({
    onSuccess: data => {
      setUserId(data.userId);
      setStep();
    },
    onError: () => {
      toast.error('Código inválido ou já utilizado');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    setCode(data.code);
    await request.mutate({ code: data.code, saveValidationCode: false });
  });

  return {
    requestCodeForm: request,
    codeForm: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseValidationCode = ReturnType<typeof useValidationCode>;
