import { IconButton } from '@mui/material';

import { Box, ClearFilters, Icon } from 'components';

import { PrivateLayout } from 'layouts/PrivateLayout';

import { useOperationFilterForm, useOperationSearch } from '../hooks';
import { useOperationStore } from '../store/store';

const OperationSearchContainer = () => {
  const { setSearch, setOpenFilterDialog } = useOperationStore();
  const {
    isFiltering,
    resetFilters,
    changeInitialFilter,
  } = useOperationFilterForm();

  const { handleChangeSearch } = useOperationSearch(500, setSearch);

  return (
    <>
      <PrivateLayout.Content.Header.Background />
      <PrivateLayout.Content.Header.Root>
        <PrivateLayout.Content.Header.Content>
          <PrivateLayout.Content.Header.Title
            title="Operações"
            icon={<Icon>view_list</Icon>}
          />
          <PrivateLayout.Content.Header.SearchBar
            placeholder="Pesquisar"
            onSearchChange={v => handleChangeSearch(v)}
          />
          <PrivateLayout.Content.Header.Actions>
            <Box position="relative">
              <IconButton
                onClick={() => {
                  setOpenFilterDialog(true);
                  changeInitialFilter();
                }}
              >
                <Icon>filter_list</Icon>
              </IconButton>

              {isFiltering && <ClearFilters onClear={resetFilters} />}
            </Box>
          </PrivateLayout.Content.Header.Actions>
        </PrivateLayout.Content.Header.Content>
      </PrivateLayout.Content.Header.Root>
    </>
  );
};

export { OperationSearchContainer };
