import { Grid } from '@mui/material';

import { CompanyResponseCheckTO, PersonCheckInnerResponseTO } from 'models';

import { SerasaModalItem } from '../../SerasaModalItem';

interface Props {
  check: CompanyResponseCheckTO | PersonCheckInnerResponseTO;
}

const Check = ({ check }: Props) => {
  return (
    <Grid
      item
      xs={12}
      lg={6}
      key={`${check.alinea}-${check.bankId}-${check.bankAgencyId}-${check.checkNumber}`}
    >
      <SerasaModalItem title="Cidade" value={check.city} />
      <SerasaModalItem title="Unidade Federativa" value={check.federalUnit} />
      <SerasaModalItem
        title="Identificador do motivo da devolução do cheque"
        value={check.alinea?.toString()}
      />
      <SerasaModalItem
        title="Número da Agência"
        value={check.bankAgencyId?.toString()}
      />
      <SerasaModalItem title="Id do Banco" value={check.bankId?.toString()} />
      <SerasaModalItem title="Nome do Banco" value={check.bankName} />
      <SerasaModalItem
        title="Quantidade de cheques"
        value={check.checkCount?.toString()}
      />
      <SerasaModalItem
        title="Número do cheque"
        value={check.checkNumber?.toString()}
      />
      <SerasaModalItem
        title="Data de ocorrência"
        value={
          check.occurrenceDate &&
          new Date(check.occurrenceDate).toLocaleDateString('pt-br')
        }
      />
      <SerasaModalItem
        title="Praça Legal"
        value={(check as PersonCheckInnerResponseTO).legalSquare ?? null}
      />
    </Grid>
  );
};

export { Check };
