import { AppBar, Toolbar } from '@mui/material';
import styled from 'styled-components';

const RootWrapper = styled(AppBar)`
  background-color: ${({ theme }) => theme.colors.white} !important;
  z-index: 99;
`;

const RootToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 0px !important;
`;

export { RootWrapper, RootToolbar };
