import { Box, Logo } from 'components';

import { FormPasswordSetting } from 'modules/signUp/components';

import { FormRegister } from '../components';
import { usePasswordSetting, useSignUpEnterprise } from '../hooks';
import { useSignUpEnterpriseStore } from '../store/store';

export function SignUpCreditorPage() {
  const { form, isSigningUp } = useSignUpEnterprise({ isFinancing: true });
  const { formPassword, requestPassword } = usePasswordSetting();
  const { step } = useSignUpEnterpriseStore();

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{
        gap: '2em',
      }}
    >
      <Logo />
      {step === 1 && <FormRegister form={form} isSigningUp={isSigningUp} />}
      {step === 2 && (
        <FormPasswordSetting
          form={formPassword}
          isSigningUp={requestPassword.isLoading}
        />
      )}
    </Box>
  );
}
