import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import { Colors } from 'themes';

interface LoaderProps {
  customColor: Colors;
}

const LoaderWrapper = styled(CircularProgress)<LoaderProps>`
  && {
    color: ${props => props.theme.colors[props.customColor]};
  }
`;

export { LoaderWrapper };
