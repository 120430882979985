import { Chip } from '@mui/material';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Colors } from 'themes';

interface ChipContainedProps {
  customBackgroundColor: Colors;
  customColor: Colors;
}

const ChipContainedWrapper = styled(Chip)<ChipContainedProps>`
  &.MuiChip-filled {
    color: ${props => props.theme.colors[props.customColor]};
    background-color: ${props => props.theme.colors[props.customBackgroundColor]};
    border-radius: ${props => props.theme.space[2]}px;

    & .MuiChip-deleteIcon {
      margin-right: 5px;
      color: ${props => rgba(props.theme.colors[props.customColor], 0.8)};

      :hover {
        color: ${props => props.theme.colors[props.customColor]};
      }
    }
  }

  &.MuiChip-clickable {
    :hover {
      background-color: ${props =>
        rgba(props.theme.colors[props.customBackgroundColor], 0.8)};
    }
  }

  &.Mui-disabled {
    color: ${props => props.theme.colors['gray-300']};
    background-color: ${props => props.theme.colors['gray-100']};
    opacity: 1 !important;

    & .MuiChip-deleteIcon {
      margin-right: 5px;
      color: ${props => props.theme.colors['gray-300']};
    }
  }
`;

export { ChipContainedWrapper };
