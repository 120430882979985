import { Grid, MenuItem, InputBaseComponentProps } from '@mui/material';
import { ElementType } from 'react';

import {
  Dialog,
  Box,
  TextField,
  ButtonContained,
  ButtonOutlined,
  Switch,
  Select,
  TextMask,
  SectionButtons,
} from 'components';
import { useMaskInputs } from 'hooks';
import { RolesTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { UseDialog, useDialog } from 'modules/user/hooks';
import { useUserStore } from 'modules/user/store/store';

export interface DialogFormProps {
  form: UseDialog['form'];
  isCreatingUser?: boolean;
}

export const DialogForm = ({ form, isCreatingUser }: DialogFormProps) => {
  const {
    openFormDialog,
    USER_ROLES,
    USER_TYPES,
    ROLES_TYPES_USER,
    handleCloseFormDialog,
  } = useDialog();
  const { currentUser } = useUserStore();
  const { user } = useAuth();

  const {
    control,
    formState: { errors, isValid },
    handleSubmitForm,
  } = form;

  const isEditing = !!(currentUser?.id && currentUser.id.length > 0);
  const roleUserLogged = user?.role.name;
  const roleUserEditing = currentUser?.role;

  const disableChanges =
    roleUserLogged === RolesTypes.COLABORATOR &&
    isEditing &&
    roleUserEditing === RolesTypes.ADMIN;

  const allowedProfiles = () => {
    const allRoles =
      user?.role.name === RolesTypes.ADMIN ||
      (isEditing && roleUserEditing === RolesTypes.ADMIN);

    if (allRoles) return ROLES_TYPES_USER;

    const allowed: string[] = [RolesTypes.COLABORATOR];

    return ROLES_TYPES_USER?.filter(role => allowed.includes(role?.name));
  };

  const { CPF_MASK, DATE_MASK } = useMaskInputs();

  return (
    <Dialog.Root open={openFormDialog} onClose={handleCloseFormDialog}>
      <Box as="form">
        <Dialog.Header title={currentUser.id ? 'Editar Usuário' : 'Novo Usuário'} />
        <Grid container width={700} p={4} spacing={2}>
          <Switch
            name="active"
            label="Ativo"
            control={control}
            disabled={disableChanges}
          />
          <Grid item xs={6}>
            <TextField
              name="name"
              label="Nome"
              control={control}
              type="text"
              error={errors.name}
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="surname"
              label="Sobrenome"
              control={control}
              type="text"
              error={errors.surname}
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="cpf"
              type="text"
              control={control}
              error={errors.cpf}
              label="CPF"
              inputProps={{
                mask: CPF_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="birthDate"
              type="text"
              control={control}
              error={errors.birthDate}
              label="Data de nascimento"
              inputProps={{
                mask: DATE_MASK.DEFAULT,
              }}
              InputProps={{
                inputComponent: (TextMask as unknown) as ElementType<InputBaseComponentProps>,
              }}
              fullWidth
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="email"
              label="E-mail"
              control={control}
              type="text"
              error={errors.email}
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              name="position"
              control={control}
              label="Cargo"
              disabled={disableChanges}
            >
              {USER_ROLES.map(role => (
                <MenuItem key={role.value} value={role.label}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="linkedin"
              label="Linkedin"
              control={control}
              type="text"
              error={errors.linkedin}
              disabled={disableChanges}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              name="userType"
              control={control}
              label="Tipo de Usuário"
              disabled={true}
            >
              {USER_TYPES.map(user => (
                <MenuItem key={user.value} value={user.label}>
                  {user.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              name="role"
              control={control}
              label="Perfil"
              disabled={disableChanges}
            >
              {allowedProfiles()?.map(role => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseFormDialog} />
        <ButtonContained
          label="Confirmar"
          onClick={() => handleSubmitForm()}
          disabled={!isValid || disableChanges}
          loading={isCreatingUser}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};
