import { useMutation, UseMutationOptions } from 'react-query';

import { IFormAnswerAttachment } from 'models';

import { IApiAxiosError } from 'config/api/types';

import FormAnswerServices from '../services/services';

const useGetFormAnswerAttachmentsRequest = (
  options?: UseMutationOptions<IFormAnswerAttachment[], IApiAxiosError, string>,
) => {
  return useMutation(
    'getFormAnswersAttachments',
    FormAnswerServices.getFormAnswerAttachments,
    options,
  );
};

export { useGetFormAnswerAttachmentsRequest };
