import ExcelJS from 'exceljs';

import { QuestionType } from 'models';

import { DynamicFormStorageService } from '../services';

export const useHandleExcel = () => {
  const getData = async () => {
    const response = await DynamicFormStorageService.getExcelFormAnswers();

    return response;
  };

  const getDatePtBr = (date: string | Date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('pt-BR');
  };

  const headerNames = [
    'CNPJ',
    'NOME FANTASIA',
    'NOME DO FORMULÁRIO',
    'STATUS DO FORMULÁRIO',
    'STATUS DO CLIENTE',
    'DATA DA RESPOSTA',
    'PERGUNTA',
    'DESCRIÇÃO - PERGUNTA',
    'RESPOSTA',
    'TIPO - PERGUNTA',
    'ACEITA ANEXOS',
    'USUÁRIO',
    'EMAIL',
    'POSITION',
    'CARGO',
  ];

  const handleDownloadExcel = async () => {
    // Criando uma nova planilha Excel
    const response = await getData();

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Dados');

    // Adicionando cabeçalhos de coluna
    const headerRow = worksheet.addRow(headerNames);

    const titleCell: ExcelJS.FillPattern = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0070C0' }, // Azul
    };

    headerRow.eachCell(cell => {
      cell.fill = titleCell;
      cell.font = { bold: true, color: { argb: 'FFFFFF' } };
    });

    //Preenchendo os dados
    response?.forEach(formAnswer => {
      const { user, formRecord } = formAnswer;
      const questionForm = formAnswer.formQuestion?.question;

      const cnpj = formRecord.enterprise.cnpj;
      const fantasyName = formRecord.enterprise.fantasyName;
      const nameForm = formRecord.form.title;
      const statusForm =
        formRecord.status === 'Completed' ? 'Completo' : 'Incompleto';
      const statusClient = formAnswer?.formRecord?.enterprise?.status;
      const answerDate = getDatePtBr(formAnswer.answeredAt);
      const question = questionForm.title;
      const descriptionQuestion = questionForm.description;
      const answer = formAnswer.answerValue as string;
      const typeQuestion = QuestionType[questionForm.type];
      const acceptAttach = questionForm.acceptAttachment ? 'Sim' : 'Não';
      const userForm = `${user.name} ${user.surname}`;
      const email = user.email;
      const position = user.position;
      const role = user?.role?.name;

      worksheet.addRow([
        cnpj,
        fantasyName,
        nameForm,
        statusForm,
        statusClient,
        answerDate,
        question,
        descriptionQuestion,
        answer,
        typeQuestion,
        acceptAttach,
        userForm,
        email,
        position,
        role,
      ]);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const anchor = document.createElement('a');

    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    anchor.href = URL.createObjectURL(blob);
    anchor.download = `planilha_formulario_dinamico.xlsx`;
    anchor.click();

    URL.revokeObjectURL(anchor.href);
  };

  return {
    handleDownloadExcel,
  };
};
