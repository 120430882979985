import styled from 'styled-components';

import { Box } from 'components';

const ToolbarWrapper = styled(Box)`
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: ${({ theme }) => theme.space[4]}px;
  border-top-right-radius: ${({ theme }) => theme.space[4]}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['gray-100']};
`;

export { ToolbarWrapper };
