import { IFileStorage } from 'models';

import api from 'config/api';

import {
  IFileResponse,
  IFileStoragePayload,
  IFindOneFileStoragePayload,
} from './types';

export class FileStorageService {
  static async findOne({ idFileStorage }: IFindOneFileStoragePayload) {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/fileStorage/${idFileStorage}`,
      {
        method: 'GET',
      },
    );

    if (!response.body) return {} as IFileResponse;

    const data: IFileResponse = {
      data: response.body,
      key: response.url,
    };

    return data;
  }

  static async create(file: IFileStoragePayload) {
    const { data } = await api.post<IFileStorage>(`/fileStorage`, file);
    return data;
  }

  static async downloadDocument(fileId: string) {
    const response = await api.get<Buffer>(`/fileStorage/${fileId}`, {
      transformRequest: data => {
        return data;
      },
      responseType: 'arraybuffer',
    });

    return response;
  }
}
