import { AxiosError } from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { RolesTypes, UserTypes } from 'models';

import { IApiError } from 'config/api/types';
import { useAuth } from 'config/auth/hooks';

import { useLogout } from 'modules/SignOut';

import { signInErrorFeedback } from 'utils/errorFeedback';
import { OptionsValuesString } from 'utils/helper';

import { ISelectedEnterprise, ISignInResponse } from '../services';
import { useSignInStore } from '../store/store';
import { useSignInForm } from './useSignInForm';
import { useSignInRequest } from './useSignInRequest';

export const useSignIn = () => {
  const { signIn } = useAuth();
  const history = useHistory();
  const {
    step,
    signInData,
    listEnterprises,
    setSignInData,
    setStep,
    setListEnterprises,
    clearSignInData,
  } = useSignInStore();
  const { handleSignOut } = useLogout();
  const form = useSignInForm();
  const [enterprise, setEnterprise] = useState<ISelectedEnterprise>();

  const checkNecessarySecondStep = (data: ISignInResponse) => {
    const { user, enterprisesUser } = data;

    // logica pra qdo a unica opção for a empresa do proprio user
    const onlyOneEnterpriseOption = enterprisesUser?.length === 1;
    const sameEnterprise = user?.enterprise.id === enterprisesUser[0]?.id;

    if (onlyOneEnterpriseOption && sameEnterprise) return false;

    // logica pra q checa se deve ser exibido o step 2 (selecionar empresas)
    const isEnterprise = !user?.enterprise?.isFinancing;
    const isAdmin = user?.role.name === RolesTypes.ADMIN;
    const isStepOne = step === 1;

    if (isEnterprise && isAdmin && isStepOne) {
      const options: OptionsValuesString[] =
        enterprisesUser?.map(main => ({
          value: main.id,
          label: `${main.bigData?.socialReason}`,
        })) || [];

      setListEnterprises(options);
      setStep();
      return true;
    }

    return false;
  };

  const request = useSignInRequest({
    onSuccess: data => {
      if (checkNecessarySecondStep(data)) return;

      setStep();
      clearSignInData();
      signIn(data);
    },
    onError: (error: AxiosError<IApiError>) => {
      signInErrorFeedback(error);
    },
  });

  const handleConsultantAccess = () => {
    history.push('/consultant');

    return;
  };

  const handleCancel = () => {
    history.replace('/');
    handleSignOut();
    clearSignInData();
  };

  const handleSubmitForm = (origin: UserTypes) => {
    return form.handleSubmit(data => {
      setSignInData(data);
      request.mutate({
        email: data.email,
        password: data.password,
        origin,
      });
    });
  };

  const handleSignInEnterpriseSecondaryCnpj = () => {
    request.mutate({
      ...signInData,
      enterpriseId: enterprise?.value,
    });
  };

  return {
    enterprise,
    enterprises: {
      data: listEnterprises,
      isLoading: false,
    },
    handleCancel,
    handleConsultantAccess,
    handleSignInEnterpriseSecondaryCnpj,
    setEnterprise,
    request,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseSignIn = ReturnType<typeof useSignIn>;
