import { IQuestionAttachment } from 'models';

import api from 'config/api';

import {
  PayloadFindQuestionAttachments,
  IQuestionAttachmentPayload,
  IUpdateQuestionAttachmentPayload,
} from './types';

export class QuestionAttachmentService {
  static async findByQuestion({ idQuestion }: PayloadFindQuestionAttachments) {
    const { data } = await api.get<IQuestionAttachment[]>(
      `/form/question-attachment/find-by-question/${idQuestion}`,
    );
    return data;
  }

  static async create(attachment: IQuestionAttachmentPayload) {
    const { data } = await api.post<IQuestionAttachment>(
      `/form/question-attachment`,
      attachment,
    );
    return data;
  }

  static async update({ id, attachment }: IUpdateQuestionAttachmentPayload) {
    const { data } = await api.put<IQuestionAttachment[]>(
      `/form/question-attachment/${id}`,
      attachment,
    );
    return data;
  }
}
