//import DialogFilter from '../components/DialogFilter';
import { HeaderFormList, ListForms } from '../components';

//import { List } from '../components/List';
//import { useQuestionDialog } from '../hooks';

const ListFormsPage = () => {
  //const { form } = useQuestionDialog();

  return (
    <>
      <HeaderFormList />
      <ListForms />
      {/* // // <List />
      {/* <DialogFilter /> } */}
    </>
  );
};

export { ListFormsPage };
