import { IQuestionAttachment, ISystemValueMapping, IUser } from 'models';

export enum QuestionType {
  MULTIPLE_CHOICE = 'Múltipla Escolha',
  SHORT_TEXT = 'Texto Curto',
  LONG_TEXT = 'Texto Longo',
  NUMBER = 'Número',
  UPLOAD = 'Upload',
}

export enum QuestionTypeSendApi {
  'Múltipla Escolha' = 'MULTIPLE_CHOICE',
  'Texto Curto' = 'SHORT_TEXT',
  'Texto Longo' = 'LONG_TEXT',
  'Número' = 'NUMBER',
  'Upload' = 'UPLOAD',
}

export interface IAnswerOption {
  label: string;
  value: string | number | boolean;
}

export interface IQuestion {
  id: string;
  title: string;
  description?: string;
  hint?: string;
  active: boolean;
  type: QuestionType;
  acceptAttachment: boolean;
  attachments: IQuestionAttachment[];
  answerOptions: IAnswerOption[];
  user: IUser;
  systemValueMapping: ISystemValueMapping;
  createdAt?: Date;
  deletedAt?: Date;
  updatedAt?: Date;
}

export interface IQuestionInput {
  title: string | null;
}
