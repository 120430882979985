import { create } from 'zustand';

type State = {
  openPendingIssuesDialog: boolean;
  openTabPendingIssuesDialog: boolean;
};

interface Actions {
  setOpenPendingIssuesDialog: (open: boolean) => void;
  setOpenTabPendingIssuesDialog: (open: boolean) => void;
}

const initialState: State = {
  openPendingIssuesDialog: false,
  openTabPendingIssuesDialog: false,
};

const usePendingIssuesStore = create<State & Actions>()(set => ({
  ...initialState,
  setOpenPendingIssuesDialog: openPendingIssuesDialog =>
    set({ openPendingIssuesDialog }),
  setOpenTabPendingIssuesDialog: openTabPendingIssuesDialog =>
    set({ openTabPendingIssuesDialog }),
  clearOperationStore: () => set(initialState),
}));

export { usePendingIssuesStore };
