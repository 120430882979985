import { PDFDownloadLink } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Scrollbar } from 'components';
import OptionsButtonNavBar from 'components/NavButtons';

import { useAuth } from 'config/auth/hooks';

import {
  DashboardCreditor,
  DashboardEnterprise,
  getNumbersWithUrl,
} from 'modules/dashboard';
import { DashboardGeneral } from 'modules/dashboard/components/DashboardGeneral';
import DashboardPDF from 'modules/dashboard/components/DashboardPDF';
import { useDashboardStore } from 'modules/dashboard/store/store';
import { usePendingIssues } from 'modules/pendingIssues/hooks';

export const NavContainer = () => {
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const { handleOpenDialog } = usePendingIssues({});
  const history = useHistory();
  const { user } = useAuth();
  const isEnterprise = !user?.enterprise?.isFinancing;
  const {
    chartsImagesUrl,
    tablesImagesUrl,
    numbersWillRender,
  } = useDashboardStore();

  const alturaDaJanela = window.innerHeight;
  const highDashboard = alturaDaJanela > 900 ? '77vh' : '72vh';
  const creditMaterial = {
    title: 'Material de crédito',
    subTitle: 'Visualize o material com seus principais indicadores',
    icon: 'summarize',
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      const chartsWithUrl = Number(getNumbersWithUrl(chartsImagesUrl));
      const tablesWithUrl = Number(getNumbersWithUrl(tablesImagesUrl));

      const newIsDownloadEnabled =
        numbersWillRender === chartsWithUrl + tablesWithUrl;

      if (newIsDownloadEnabled !== isDownloadEnabled) {
        setIsDownloadEnabled(newIsDownloadEnabled);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [isDownloadEnabled, chartsImagesUrl, tablesImagesUrl]);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={'center'}
      mb={70}
      mt={20}
    >
      <Box
        width={'100%'}
        display="flex"
        flexDirection={'row'}
        style={{ gap: '1em' }}
        alignItems="center"
        justifyContent={'center'}
      >
        <OptionsButtonNavBar
          title={'Operações'}
          subTitle={'Acompanhe as suas operações'}
          icon={'compare_arrows'}
          onClick={() => history.push('/operations')}
        />

        {isEnterprise && (
          <Box style={{ display: 'block', width: '100%' }}>
            {isDownloadEnabled ? (
              <PDFDownloadLink
                document={<DashboardPDF />}
                fileName="material_de_credito.pdf"
                style={{ width: '100%', textDecoration: 'none' }}
              >
                <OptionsButtonNavBar
                  title={creditMaterial.title}
                  subTitle={creditMaterial.subTitle}
                  icon={creditMaterial.icon}
                  loading={false}
                />
              </PDFDownloadLink>
            ) : (
              <OptionsButtonNavBar
                title={creditMaterial.title}
                subTitle={creditMaterial.subTitle}
                icon={creditMaterial.icon}
                loading={true}
              />
            )}
          </Box>
        )}

        <OptionsButtonNavBar
          title="Pendências"
          subTitle="Acesse as suas pendências"
          icon={'notifications_active'}
          onClick={() => handleOpenDialog({ isTab: false })}
        />
      </Box>

      <Box position={'absolute'} width={'90%'} top={-5000}>
        <Scrollbar
          display="flex"
          flexDirection="column"
          overflow={'auto'}
          height={highDashboard}
          maxHeight={highDashboard}
        >
          <DashboardGeneral />
          {isEnterprise ? <DashboardEnterprise /> : <DashboardCreditor />}
        </Scrollbar>
      </Box>
    </Box>
  );
};
