import { Box, Typography } from '@mui/material';

import { KleverPhysicalProductionVariable } from 'models';

import { BigDataItem } from './BigDataItem';

interface Props {
  title: string;
  physicalProductionVariable: KleverPhysicalProductionVariable;
}

export const BigDataPhysicalProductionVariable = ({
  physicalProductionVariable,
  title,
}: Props) => {
  return (
    <Box>
      <Typography fontWeight={700}>{title}</Typography>
      <BigDataItem
        title="Unidade de Medida"
        value={physicalProductionVariable.measureUnit}
      />
      <BigDataItem title="Nome" value={physicalProductionVariable.name} />
      <BigDataItem title="Valor" value={physicalProductionVariable.value} />
    </Box>
  );
};
