import { createGlobalStyle } from 'styled-components';

import theme from '.';

type Theme = typeof theme;

const GlobalStyle = createGlobalStyle<{ theme: Theme }>`
  * {
    font-family: ${({ theme }) => theme.fonts.body};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: ${({ theme }) => theme.colors.white};
  }

  .ql-mention-list-container {
    width: 500px;
  }
`;

export default GlobalStyle;
