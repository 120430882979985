import { Paper } from '@mui/material';
import themes from 'themes';

import { Typography } from 'components';

function EmptyDashboard() {
  return (
    <Paper
      elevation={2}
      sx={{
        marginTop: 6,
        height: '55vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color={themes.colors.dashboard.table.body.secondary}>
        Nenhuma informação para ser exibida
      </Typography>
    </Paper>
  );
}

export { EmptyDashboard };
