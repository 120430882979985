import { Grid, MenuItem, Checkbox } from '@mui/material';

import {
  Box,
  TextField,
  Switch,
  Select,
  Typography,
  ButtonOutlined,
  ButtonContained,
  SectionButtons,
} from 'components';
import { Attachments } from 'components/Attachments';
import { NoAttachment } from 'components/NoAttachment';
import { Dropzone } from 'components/Upload/Dialog';

import { UseQuestionStep, useQuestionDialog, useQuestionStep } from '../../hooks';

export interface QuestionStepFormProps {
  form: UseQuestionStep['form'];
  handleComplete: (submitForm: () => void) => void;
}

export const QuestionStepForm = ({
  form,
  handleComplete,
}: QuestionStepFormProps) => {
  const {
    control,
    formState: { errors, isValid },
    handleSubmitQuestionStepForm,
  } = form;

  const { handleCloseFormDialog } = useQuestionDialog();

  const {
    QUESTIONS_TYPES,
    checkDescription,
    openDropzone,
    questionAttachment,
    setOpenDropzone,
    handleSaveAttachments,
    handleRemoveAttachment,
    handleChangeCheckDescription,
  } = useQuestionStep();

  return (
    <>
      <Box as="form" minHeight={350} padding={5}>
        <Dropzone
          open={openDropzone}
          onClose={() => setOpenDropzone(false)}
          onSave={handleSaveAttachments}
        />
        <Grid container pt={4} pl={2} pr={2} spacing={2}>
          <Grid item xs={6}>
            <Select name="type" control={control} label="Tipos de Pergunta">
              {QUESTIONS_TYPES.map(role => (
                <MenuItem key={role.value} value={role.label}>
                  {role.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={3} display={'flex'} alignItems={'center'}>
            <Checkbox
              checked={checkDescription}
              onChange={() => handleChangeCheckDescription()}
            />
            <Typography>Descrição?</Typography>
          </Grid>
          <Grid item xs={3} display={'flex'} alignItems={'center'}>
            <Switch
              name="active"
              label="Ativo"
              control={control}
              checked={form.watch('active')}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="title"
              label="Pergunta"
              control={control}
              type="text"
              error={errors.title}
            />
          </Grid>
          {!!checkDescription && (
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descrição"
                control={control}
                type="text"
                error={errors.description}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ButtonContained
              color={'gray-700'}
              label="Anexar Arquivos"
              onClick={() => setOpenDropzone(true)}
            />
            {questionAttachment.length === 0 ? (
              <NoAttachment customColors={'white'} colorText={'gray-500'} />
            ) : (
              questionAttachment?.map((attachment, index) => (
                <Attachments
                  key={index}
                  file={attachment.file}
                  removeFile={() => handleRemoveAttachment(attachment.file.id)}
                />
              ))
            )}
          </Grid>
        </Grid>
      </Box>
      <SectionButtons>
        <ButtonOutlined label="Cancelar" onClick={handleCloseFormDialog} />
        <ButtonContained
          label="Confirmar"
          onClick={() => handleComplete(handleSubmitQuestionStepForm)}
          disabled={!isValid}
        />
      </SectionButtons>
    </>
  );
};
