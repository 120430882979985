import { stringify } from 'qs';

import { IFilterDatabasePayload } from 'components/FilterDatabase/filterDatabase.types';
import { IFormAnswer, IFormRecord, IPagination } from 'models';

import api from 'config/api';

export class DynamicFormStorageService {
  static async getDynamicFormStorageData({
    page,
    perPage,
    search,
    type,
    active,
    status,
    manager,
    product,
    question,
  }: IFilterDatabasePayload) {
    const params = stringify({
      page,
      perPage,
      search,
      type,
      active,
      status,
      manager,
      product,
      question,
    });

    const { data } = await api.get<IPagination<IFormRecord>>(
      `/form/form-answer?${params}`,
    );

    return data;
  }

  static async getExcelFormAnswers() {
    const { data } = await api.get<IFormAnswer[]>(
      `/form/form-answer/download-excel-form-answers`,
    );

    return data;
  }
}
