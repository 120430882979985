import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { IFormRecord } from 'models';

import { EFormAnswerType } from 'modules/dynamicForm';
import useFormAnswerStore from 'modules/dynamicForm/formAnswer/store/store';
import { useGetUrlDownloadRequest } from 'modules/dynamicForm/question/hooks';

import { DynamicFormStorageService } from '../services';
import { useDynamicFormStorageStore } from '../store/store';
import { useDynamicFormStorageFilter } from './useDynamicFormStorageFilter';

export const useDynamicFormStorage = () => {
  const {
    limit,
    page,
    perPage,
    search,
    setLimit,
    setPage,
  } = useDynamicFormStorageStore();

  const { filterParams, filterBy, currentFilter } = useDynamicFormStorageFilter();

  const { setAllowEdit, setFormRecordId } = useFormAnswerStore();
  const history = useHistory();

  const { data: response, isLoading, refetch } = useQuery(
    ['dynamicFormStorage', page, search, filterBy, currentFilter],
    () =>
      DynamicFormStorageService.getDynamicFormStorageData({
        page,
        perPage,
        search,
        ...filterParams,
      }),
    { keepPreviousData: true },
  );

  const noData = !response?.data || response.data.length === 0;
  const showPagination = !noData && response?.totalPages && response.totalPages > 1;

  const requestDownloadFile = useGetUrlDownloadRequest({
    onError: () => {
      toast.error('Houve um erro ao tentar baixar o arquivo');
    },
  });

  const handleDownloadFile = (id: string) => {
    requestDownloadFile.mutate({ idFileStorage: id });
  };

  const handleOpenFormResume = (formRecord: IFormRecord) => {
    setAllowEdit(false);
    setFormRecordId(formRecord.id);

    const routeResume = `/form-resume/${formRecord.form.id}/${EFormAnswerType.answer}`;
    history.push(`${routeResume}`);
  };

  useEffect(() => {
    if (response?.nextPage && response?.totalPages) {
      setLimit(response.nextPage >= response.totalPages);
    }
  }, [response]);

  return {
    data: response?.data,
    isLoading,
    refetch,
    page,
    perPage,
    limit,
    noData,
    showPagination,
    setPage,
    handleDownloadFile,
    handleOpenFormResume,
  };
};
