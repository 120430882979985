import FormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import themes from 'themes';

interface CheckboxProps {
  color?: keyof typeof themes.colors;
  fontSize?: keyof typeof themes.fontSizes;
  fontWeights?: keyof typeof themes.fontWeights;
}

const CheckboxWrapper = styled(FormControlLabel)<CheckboxProps>`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    font-size: ${({ theme }) => theme.fontSizes.h5}px;
    color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.white};
  }

  .MuiFormControlLabel-label {
    color: ${({ theme, color }) =>
      color ? theme.colors[color] : theme.colors.white};
    font-size: ${({ theme, fontSize }) =>
      fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.l}px;
    font-weight: ${({ theme, fontWeights }) =>
      fontWeights ? theme.fontWeights[fontWeights] : theme.fontWeights.bold};
  }
`;

export { CheckboxWrapper };
