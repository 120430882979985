import { Box, Typography } from '@mui/material';

import { KleverPhysicalProduction } from 'models';

import { BigDataItem } from './BigDataItem';
import { BigDataPhysicalProductionVariable } from './BigDataPhysicalProductionVariable';

interface Props {
  physicalProductions: KleverPhysicalProduction[];
}

export const BigDataPhysicalProduction = ({ physicalProductions }: Props) => {
  if (!physicalProductions || physicalProductions.length === 0) return null;

  return (
    <Box>
      {physicalProductions.map((production, index) => (
        <Box key={index}>
          <Typography fontWeight={700}>Produção Física</Typography>
          <BigDataItem title="Código" value={production.codeDescription} />
          <BigDataItem
            title="Data"
            value={new Date(production.date).toLocaleDateString('pt-BR')}
          />
          <BigDataItem
            title="Nível territorial das produções físicas do CNAE principal"
            value={production.territorialLevel}
          />
          <BigDataPhysicalProductionVariable
            title=" Índice de base fixa sem ajuste sazonal"
            physicalProductionVariable={
              production.fixedBaseIndexWithoutSeasonalAdjustment
            }
          />
          <BigDataPhysicalProductionVariable
            title="Índice acumulado nos últimos 12 meses"
            physicalProductionVariable={production.indexAccumulatedLast12Months}
          />
          <BigDataPhysicalProductionVariable
            title="Índice mensal"
            physicalProductionVariable={production.monthlyIndex}
          />
          <BigDataPhysicalProductionVariable
            title="Variação percentual mensal"
            physicalProductionVariable={production.monthlyPercentageChange}
          />
          <BigDataPhysicalProductionVariable
            title="Variação percentual acumulada nos últimos 12 meses"
            physicalProductionVariable={
              production.percentageChangeAccumulatedLast12Months
            }
          />
          <BigDataPhysicalProductionVariable
            title="Variação percentual acumulada no ano"
            physicalProductionVariable={production.percentageChangeAccumulatedYear}
          />
          <BigDataPhysicalProductionVariable
            title="Índice acumulado do ano até a data"
            physicalProductionVariable={production.yearToDateIndex}
          />
        </Box>
      ))}
    </Box>
  );
};
