import { useCallback, useMemo } from 'react';

import { ActStatus } from 'models';

import { useAuth } from 'config/auth/hooks';

import { UserTypeHandleActPayload } from 'modules/backoffice/acts';
import { UserTypesPayload } from 'modules/user';
import { useListAllConsultants } from 'modules/user/hooks';

import { enumToArray } from 'utils/enumToArray';
import { OptionsValues } from 'utils/helper';

import {
  IActTimelineFilter,
  IActTimelineFilterBy,
  actTimelineFilterByOptions,
} from '../services';
import { useTabsStore } from '../store/store';
import { useProcessActsOptions } from './useProcessActsOptions';

export const useFilterActs = () => {
  const { user } = useAuth();
  const userType = user?.userType;
  const userRole = user?.role.name;
  const {
    filterBy,
    currentFilter,
    openFilterModal,
    currentTabInfos,
    setFilterBy,
    setSearchFilterOption,
    setCurrentFilter,
    setOpenFilterModal,
  } = useTabsStore();

  const { consultantsOptions } = useListAllConsultants(openFilterModal);
  const { processActsOptions } = useProcessActsOptions({
    type: currentTabInfos?.typeOp,
    enterpriseId: currentTabInfos?.enterpriseId,
    operationId: currentTabInfos?.operationId,
    userType: UserTypesPayload[userType ?? ''],
    userRole: UserTypeHandleActPayload[userRole ?? ''],
    enabled: openFilterModal,
  });

  const statusOptions = enumToArray(ActStatus);
  const isFiltering = currentFilter.length > 0;

  const handleChangesFilter = (value: OptionsValues[]) => {
    const addedFilter = value[value.length - 1];
    const newValues = [
      ...currentFilter,
      {
        filterBy: filterBy.value,
        ...addedFilter,
      },
    ];

    setCurrentFilter(newValues);
  };

  const handleRemoveFilter = (option: IActTimelineFilter) => {
    const newFilters = currentFilter.filter(item => item !== option);

    setCurrentFilter(newFilters);
  };

  const clearFilter = useCallback(() => {
    setCurrentFilter([]);
  }, []);

  const clearSearchOption = useCallback(() => setSearchFilterOption(''), []);

  const getOptions = () => {
    switch (filterBy?.value) {
      case IActTimelineFilterBy.NAME_ACT:
        return processActsOptions;
      case IActTimelineFilterBy.OPERATOR:
        return consultantsOptions;
      case IActTimelineFilterBy.RESPONSIBLE:
        return consultantsOptions;
      case IActTimelineFilterBy.STATUS:
        return statusOptions;
      default:
        return [];
    }
  };

  const optionsFilter = getOptions();

  const isOptionEqualToValue = (option, filter) => {
    return option.value === filter.value && option.label === filter.label;
  };

  const generateFilterParams = useMemo(() => {
    const filterParams = currentFilter.reduce((acc, item) => {
      if (!acc[item.filterBy]) {
        acc[item.filterBy] = [];
      }

      if (item.filterBy === IActTimelineFilterBy.STATUS) {
        acc[item.filterBy].push(item?.value);
      } else {
        acc[item.filterBy].push(item?.label);
      }

      return acc;
    }, {});

    return filterParams;
  }, [currentFilter]);

  return {
    filterBy,
    optionsFilter,
    isFiltering,
    filterActsOptions: actTimelineFilterByOptions,
    filterParams: generateFilterParams,
    currentFilter,
    openFilterModal,
    clearSearchOption,
    clearFilter,
    handleChangesFilter,
    handleRemoveFilter,
    isOptionEqualToValue,
    setFilterBy,
    setSearchFilterOption,
    setOpenFilterModal,
  };
};
