import { WhatsApp, Today } from '@mui/icons-material';

import { Box, Dialog, ButtonOutlined, SectionButtons } from 'components';

import { useTabs } from '../../hooks';

const ModalSuportExFin = () => {
  const { openSupportExFinModal, setOpenSupportExFinModal } = useTabs();

  return (
    <Dialog.Root
      open={openSupportExFinModal}
      onClose={() => setOpenSupportExFinModal(false)}
      backdropColor="rgba(0, 0, 0, 0.3)"
    >
      <Dialog.Header title={'Suporte ExFin'} />

      <Box minWidth={500} display={'flex'} justifyContent={'center'} marginY={9}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          width="90%"
          justifyContent={'space-around'}
        >
          <Box maxWidth={200}>
            <ButtonOutlined
              label="Entrar em contato"
              onClick={() =>
                window.open(`${process.env.REACT_APP_LINK_WHATS}`, '_blank')
              }
              icon={<WhatsApp fontSize={'large'} />}
            />
          </Box>
          <Box maxWidth={200}>
            <ButtonOutlined
              label="Agendar reunião"
              onClick={() =>
                window.open(`${process.env.REACT_APP_LINK_WHATS}`, '_blank')
              }
              icon={<Today fontSize={'large'} />}
            />
          </Box>
        </Box>
      </Box>

      <SectionButtons>
        <ButtonOutlined
          color="black"
          label="Cancelar"
          onClick={() => setOpenSupportExFinModal(false)}
        />
      </SectionButtons>
    </Dialog.Root>
  );
};

export { ModalSuportExFin };
