import { IOperationResponse } from 'modules';

import {
  UserTypes,
  IAct,
  Operation,
  IEnterprise,
  IProcessActAttachment,
  IForm,
  IUser,
  IFormRecord,
  ProductClient,
} from 'models';

export enum TypeProcessAct {
  CLIENT = 'CLIENT',
  OPERATION = 'OPERATION',
}

export interface IProcessAct {
  id?: string;

  active: boolean;
  status: string;
  typeProcessAct: TypeProcessAct;
  form: IForm;
  info: string;
  clientTypeInfo: UserTypes;
  dbInfo: string[];
  productClient: ProductClient[];
  createOpValues: string[];
  operationType: Operation;
  valueOp: string;
  scheduleLink: string;
  deadline: string;
  chargeLink: string;
  docSignLink: string;
  emailTitle: string;
  emailBody: string;
  motiveEnd: string;
  attachments: IProcessActAttachment[];
  attachmentsAnswer: IProcessActAttachment[];
  answer: string;
  formRecord: IFormRecord;

  act: IAct;
  enterprise: IEnterprise;
  responsible: IUser;
  operator: IUser;
  executionDate: Date;
  operation: IOperationResponse;

  isChild: boolean;
  isConfigured: boolean;

  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
