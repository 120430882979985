import { Toolbar } from '@mui/material';
import { ReactNode } from 'react';

import { Box, Typography } from 'components';

import { HeaderWrapper } from './styles';

interface HeaderProps {
  title: string;
  children?: ReactNode;
}

export function Header({ title, children }: HeaderProps) {
  return (
    <HeaderWrapper position="static" elevation={1}>
      <Toolbar>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'space-around'}
          alignItems={'center'}
        >
          <Box width={'85%'} display={'flex'} justifyContent={'flex-start'}>
            <Typography color="white" fontSize="h6">
              {title}
            </Typography>
          </Box>
          <Box width={150}>{children}</Box>
        </Box>
      </Toolbar>
    </HeaderWrapper>
  );
}
