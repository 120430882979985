import { useMutation, UseMutationOptions } from 'react-query';

import { IApiAxiosError } from 'config/api/types';

import {
  IFormAnswerResponse,
  IFormAnswerUpdatePayload,
} from 'modules/dynamicForm/formAnswer';
import FormAnswerServices from 'modules/dynamicForm/formAnswer/services/services';

const useUpdateRevisionQuestionRequest = (
  options?: UseMutationOptions<
    IFormAnswerResponse,
    IApiAxiosError,
    IFormAnswerUpdatePayload
  >,
) => {
  return useMutation(
    'updateRevisionQuestion',
    FormAnswerServices.updateRevisionQuestion,
    options,
  );
};

export { useUpdateRevisionQuestionRequest };
