import { Box, BoxProps } from '@mui/material';

interface ContentProps extends BoxProps {
  children: React.ReactNode;
  height?: number | string;
  maxHeight?: number | string;
  width?: number | string;
}

function ScrollBar({ children, height, maxHeight, width }: ContentProps) {
  return (
    <Box
      padding={0}
      overflow={'auto'}
      height={height}
      maxHeight={maxHeight}
      width={width}
      sx={{
        '::-webkit-scrollbar': {
          width: '10px',
          height: '10px',
          backgroundColor: '#e0e0e0',
          borderRadius: `12px`,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: '#bdbdbd',
          borderRadius: '12px',
        },
      }}
    >
      {children}
    </Box>
  );
}

export default ScrollBar;
