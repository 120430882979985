import { Table, TableCell } from '@mui/material';

import {
  Pagination,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components';

import { useDocument } from '../hooks';
import { DocumentItem } from './DocumentItem';

const DocumentTable = () => {
  const {
    data,
    page,
    setPage,
    limit,
    isLoading,
    showPagination,
    noData,
  } = useDocument();

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Typography variant="subtitle3">Cliente</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">NDA</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">DRE</Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="subtitle3">Balanço Patrimonial</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          columns={4}
          isLoading={isLoading}
          empty={noData}
          emptyMessage="Base de dados vazia"
        >
          {data?.map(document => (
            <DocumentItem key={document.id} {...document} />
          ))}
        </TableBody>
      </Table>
      {showPagination && (
        <Pagination step={page} onSetStep={setPage} limit={limit} />
      )}
    </TableContainer>
  );
};

export { DocumentTable };
