import moment from 'moment';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { IUser, Languages, UserPositions, UserTypes } from 'models';

import { useAuth } from 'config/auth/hooks';

import { emailUserType } from 'modules/email';

import { enumToArray } from 'utils/enumToArray';

import { IUserEdit, UserQueryKey } from '../services';
import { useUserStore } from '../store/store';
import {
  useCreateInternalUserRequest,
  useSendEmailDefinePasswordRequest,
  useUpdateInternalUserRequest,
} from './';
import { useUserDialogForm } from './useDialogForm';
import { useFindRoles } from './useFindRoles';

export const useDialog = () => {
  const {
    openFormDialog,
    currentUser,
    setCurrentUser,
    setOpenFormDialog,
  } = useUserStore();
  const form = useUserDialogForm();
  const USER_ROLES = enumToArray(UserPositions);
  const USER_TYPES = enumToArray(UserTypes);
  const ROLES_TYPES = useFindRoles();
  const ROLES_TYPES_USER = ROLES_TYPES.data?.filter(
    role => role.name !== UserTypes.CONSULTANT,
  );
  const auth = useAuth();
  const queryClient = useQueryClient();

  useEffect(() => {
    form.reset(currentUser);
  }, [currentUser]);

  const handleOpenFormDialog = async (data: IUser) => {
    if (data.id) {
      setCurrentUser({
        id: data.id,
        name: data.name,
        surname: data.surname,
        cpf: data.cpf,
        linkedin: data?.linkedin ?? '',
        email: data.email,
        active: data.active,
        birthDate: new Date(data.birthDate).toLocaleDateString('pt-br'),
        language: Languages['PORTUGUESE'],
        role: data.role.name,
        position: data.position,
        userType: data.userType,
      });
    } else {
      // sempre mostrar o tipo de usuário de acordo com user logado.
      setCurrentUser({ active: true, userType: auth.user?.userType } as IUserEdit);
    }

    setOpenFormDialog(true);
  };

  const handleCloseFormDialog = () => {
    setCurrentUser({} as IUserEdit);
    setOpenFormDialog(false);
  };

  const requestCreateUser = useCreateInternalUserRequest({
    onSuccess: data => {
      toast.success('Usuário criado com sucesso');
      queryClient.invalidateQueries(UserQueryKey.LIST_ALL);
      requestSendEmail.mutate({
        emailUserType: emailUserType.USER,
        name: data.name,
        email: data.email,
      });

      handleCloseFormDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar criar um usuário internamente');
    },
  });

  const requestUpdateUser = useUpdateInternalUserRequest({
    onSuccess: () => {
      toast.success('Usuário editado com sucesso');
      queryClient.invalidateQueries(UserQueryKey.LIST_ALL);
      handleCloseFormDialog();
    },
    onError: () => {
      toast.error('Houve um erro ao tentar editar o usário.');
    },
  });

  const requestSendEmail = useSendEmailDefinePasswordRequest({
    onSuccess: () => {
      toast.success('Email para definição de senha enviado com sucesso');
    },
    onError: () => {
      toast.error('Houve um erro ao tentar enviar o email de confirmação');
    },
  });

  const handleSubmitForm = form.handleSubmit(async data => {
    const birthDate = moment(data.birthDate, 'DD/MM/YYYY HH:mm');

    const role = ROLES_TYPES.data?.find(role => role.name === data.role);

    const userPayload: IUserEdit = {
      ...data,
      birthDate: birthDate.toDate(),
      language: Languages.PORTUGUESE,
      linkedin: !!data?.linkedin ? data.linkedin : undefined,
      role: role?.id ?? '',
      enterprise: auth?.user?.enterprise?.id ?? '',
    };

    if (currentUser.id) {
      requestUpdateUser.mutate({ id: currentUser.id, user: userPayload });
      return;
    }

    requestCreateUser.mutate(userPayload);
  });

  return {
    USER_ROLES,
    USER_TYPES,
    ROLES_TYPES_USER,
    openFormDialog,
    handleOpenFormDialog,
    handleCloseFormDialog,
    form: {
      handleSubmitForm,
      ...form,
    },
  };
};

export type UseDialog = ReturnType<typeof useDialog>;
