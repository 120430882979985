import { Box } from '@mui/material';

import { useDashboardActions } from '../hooks';

interface Props {
  children: React.ReactNode;
  index: number;
}

const DashboardTabPanel = ({ index, children }: Props) => {
  const { valueTab } = useDashboardActions();
  return (
    <Box
      role="tabpanel"
      hidden={valueTab !== index}
      id={`dashoard-tabpanel-${index}`}
      aria-labelledby={`dashoard-tab-${index}`}
    >
      {valueTab === index && <Box sx={{ pr: 3, pt: 3, pb: 3 }}>{children}</Box>}
    </Box>
  );
};

export { DashboardTabPanel };
