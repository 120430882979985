import {
  TableBodyProps as MuiTableBodyProps,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
  Skeleton,
} from '@mui/material';

import { Typography } from 'components';

const SKELETON_ROWS = 10;

interface TableBodyProps extends MuiTableBodyProps {
  columns: number;
  isLoading: boolean;
  empty?: boolean;
  emptyMessage?: string;
}

export function TableBody({
  children,
  columns, // Necessário para centralizar mensagem de nenhum dado encontrado e definir layout do skeleton
  isLoading = false,
  empty = false,
  emptyMessage = 'Nenhum dado encontrado',
  ...rest
}: TableBodyProps) {
  if (isLoading) {
    return (
      <MuiTableBody>
        {Array.from({ length: SKELETON_ROWS }, (_, i) => (
          <TableRow key={i}>
            {Array.from({ length: columns }, (_, j) => (
              <TableCell key={j}>
                <Skeleton />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </MuiTableBody>
    );
  }

  if (empty) {
    return (
      <MuiTableBody>
        <TableRow>
          <TableCell
            colSpan={columns}
            align="center"
            style={{ borderBottom: 'none' }}
          >
            <Typography variant="h6" color="gray-300">
              {emptyMessage}
            </Typography>
          </TableCell>
        </TableRow>
      </MuiTableBody>
    );
  }

  return <MuiTableBody {...rest}>{children}</MuiTableBody>;
}
