import { useCustomerManagementStore } from '../store/store';
import { useSelectedCustomers } from './useSelectedCustomers';

export const useCustomerConfirm = () => {
  const {
    setOpenChangeStatsDialog,
    setOpenConfirmationDialog,
    openConfirmationDialog,
  } = useCustomerManagementStore();
  const { numberOfSelectedCustomers } = useSelectedCustomers();

  const handleOpenConfirmationDialog = async () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleConfirmCustomers = () => {
    setOpenConfirmationDialog(false);
    setOpenChangeStatsDialog(true);
  };

  const handleCancelCustomers = () => {
    setOpenConfirmationDialog(false);
  };

  return {
    openConfirmationDialog,
    numberOfSelectedCustomers,
    handleOpenConfirmationDialog,
    handleCloseConfirmationDialog,
    handleConfirmCustomers,
    handleCancelCustomers,
  };
};
