import { IRolesTypes, ProductClient, RolesTypes } from 'models';

import { IUserFilter } from './services';

export const customerTypesOptions = [
  { label: 'Empresa', value: 'Empresa' },
  { label: 'Instituição Financeira', value: 'Credor' },
];

export const useCustomerTypeOptions = () => ({
  customerTypesOptions,
  isLoading: false,
});

export const usersTypesOptions = [
  { label: 'Empresa', value: false },
  { label: 'Instituição Financeira', value: true },
];

export const useFindUsersTypesOptions = () => ({
  usersTypesOptions,
  isLoading: false,
});

export const useCustomerProductOptions = () => ({
  customerProductOptions: Object.values(ProductClient).map(i => ({
    label: i,
    value: i,
  })),
  isLoading: false,
});

export const rolesTypesOptions = [
  { label: 'Administrador', value: 'ADMIN' },
  { label: 'Colaborador', value: 'COLABORATOR' },
];

export const useFindRolesTypesOptions = () => ({
  rolesTypesOptions,
  isLoading: false,
});

export const activeOptions = [
  { label: 'Ativo', value: true },
  { label: 'Inativo', value: false },
];

export const useFindActiveOptions = () => ({
  activeOptions,
  isLoading: false,
});

export const filterByOptions = [
  { label: 'Tipo de Usuário', value: 1 },
  { label: 'Perfil', value: 2 },
  { label: 'Ativo/Inativo', value: 3 },
];

type QueryFilter = {
  usersTypes: boolean[];
  rolesTypes: IRolesTypes[];
  active: boolean[];
  users: number[];
};

export const formatCurrentFilters = (filter: IUserFilter[]): QueryFilter => {
  const queryFilter: QueryFilter = {
    usersTypes: [],
    rolesTypes: [],
    active: [],
    users: [],
  };

  filter.forEach(item => {
    switch (item.filterBy) {
      case 'usersTypes':
        queryFilter.usersTypes.push(Boolean(item.value));
        break;
      case 'rolesTypes':
        const role = RolesTypes[item.value];
        queryFilter.rolesTypes.push(role as IRolesTypes);
        break;
      case 'active':
        queryFilter.active.push(Boolean(item.value));
        break;
      default:
        queryFilter.users.push(item.value);
        break;
    }
  });

  return queryFilter;
};
