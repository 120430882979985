import { useQuestionForm } from './useQuestionForm';

export const useQuestion = () => {
  const formQuestion = useQuestionForm();

  return {
    formQuestion: {
      ...formQuestion,
    },
  };
};
