import { useQuery } from 'react-query';

import { ScrService } from '../services';
import { useScrStore } from '../store/store';
import { useScrFilter } from './useScrFilter';

interface SrcRequestProps {
  limitPagination?: boolean;
}

export const useScrRequest = ({ limitPagination }: SrcRequestProps) => {
  const { page, perPage, search } = useScrStore();
  const { filterParams, filterBy, currentFilter } = useScrFilter();

  const unlimited = 100000000;

  const { data, isLoading, refetch } = useQuery(
    ['scr', page, search, filterBy, currentFilter],
    () =>
      ScrService.getScrData({
        page,
        perPage: limitPagination ? unlimited : perPage,
        search,
        ...filterParams,
      }),
    { keepPreviousData: true },
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
