enum ESystemValueType {
  RELATIONSHIP = 'Relationship',
  NUMBER = 'Number',
  STRING = 'String',
  BOOLEAN = 'Boolean',
}

enum QuestionType {
  OBJECTIVE = 'Objective',
  MULTIPLE_CHOICE = 'Multiple Choice',
  TEXT = 'Text',
  LONG_TEXT = 'Long Text',
  NUMBER = 'Number',
  BOOLEAN = 'Boolean',
  FILE = 'File',
}

export interface SystemValueMappingResponse {
  id: string;
  title: string;
  table: string;
  column: string;
  valueType: ESystemValueType;
  value: string;
  type: 'SYS_VALUE' | 'QUESTION';
  description: string;
  hint: string;
  acceptAttachment: boolean;
  questionType: QuestionType;
}
