import { routes } from 'routes';
import { RoutesProps } from 'routes/types';

import { UserTypes } from 'models';
import { IPermissions } from 'models/roles';

import { useAuth } from 'config/auth/hooks';

import { useParsePermissions } from './useParsePermissions';

export const useValidateRouteAccess = () => {
  const { userPermissions, user } = useAuth();
  const userType = user?.userType;

  const permissionsParsed = useParsePermissions(userPermissions);

  const findPermissionsByPath = (
    routes: RoutesProps[],
    path: string,
  ): IPermissions[] | null => {
    for (const route of routes) {
      if (route.path === path) {
        return route.permissions || null;
      }

      if (route.subs) {
        const subRoutePermissions = findPermissionsByPath(route.subs, path);

        if (subRoutePermissions) {
          return subRoutePermissions;
        }
      }
    }

    return null;
  };

  // PERMISSIONS TO SIDEBAR ROUTES
  const hasPermission = (path: string): boolean => {
    const permissions = findPermissionsByPath(routes, path);

    if (!permissions) {
      return true;
    }

    const allowed = permissions.every(permission => {
      const { name } = permission;

      const hasPermission = permissionsParsed.some(up => up.name === name);

      return hasPermission;
    });

    return allowed;
  };

  // PERMISSIONS TO INTERN DATA BY USER TYPE
  const getSpecificPermissionUserType = (permission: UserTypes[]) => {
    if (!userType) return false;
    const isValid = permission.includes(userType);
    return isValid;
  };

  // PERMISSIONS TO INTERN DATA BY ROLE
  const getSpecificPermissionRole = (permission: IPermissions) => {
    try {
      const permissionsRole = user?.role?.permissions;

      if (!permissionsRole) return false;

      const permissions: IPermissions[] = JSON.parse(permissionsRole) ?? [];

      const hasPermission = permissions.find(tf => tf.name === permission.name);

      return !!hasPermission;
    } catch (err) {
      return false;
    }
  };

  return {
    hasPermission,
    getSpecificPermissionUserType,
    getSpecificPermissionRole,
  };
};
