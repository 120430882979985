import { Icon } from '@mui/material';
import moment from 'moment';
import { ReactNode } from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';

import { ActStatus } from 'models';

import useVerticalTimeLineStyles from './styles';

interface VerticalTimeLineElementProps {
  children: ReactNode;
  dateCreation?: Date;
  status?: ActStatus;
}

export const VerticalTimeLineElement = ({
  dateCreation,
  status,
  children,
}: VerticalTimeLineElementProps) => {
  const classes = useVerticalTimeLineStyles();
  const colorNew = 'rgb(241,241,241)';
  const colorTextNew = 'rgb(0,0,0)';
  const colorTextEdit = 'rgb(255,255,255)';
  const colorIconBGBlue = 'rgb(4,118,191)';
  const colorIconBGRed = 'rgb(232, 77, 77)';
  const nameIcon = <Icon>flag</Icon>;

  return (
    <VerticalTimelineElement
      date={moment(dateCreation).format('DD/MM/YYYY [às] HH:mm')}
      contentStyle={{
        background: `${colorNew}`,
        color: `${colorTextNew}`,
      }}
      contentArrowStyle={{
        borderRight: `7px solid  ${colorNew}`,
      }}
      iconStyle={{
        background: `${
          status === ActStatus.OPEN ? colorIconBGRed : colorIconBGBlue
        }`,
        color: colorTextEdit,
      }}
      iconClassName={classes.icon}
      icon={nameIcon}
    >
      {children}
    </VerticalTimelineElement>
  );
};
